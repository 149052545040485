import React from 'react';
import ReactModal from 'react-modal';
import './SimpleModal.scss';


const SimpleModal = ({ className, isOpen, onRequestClose, children }) => {
  // const { className, ...props } = this.props;
  const contentBase = `${className}__content`;
  const overlayBase = `${className}__overlay`;
  const overlayClassName = {
    base: overlayBase,
    afterOpen: `${overlayBase}--after-open`,
    beforeClose: `${overlayBase}--before-close`,
  };
  const contentClassName = {
    base: contentBase,
    afterOpen: `${contentBase}--after-open`,
    beforeClose: `${contentBase}--before-close`,
  };

  return (
    <ReactModal
      className={contentClassName}
      overlayClassName={overlayClassName}
      portalClassName={className}
      closeTimeoutMS={150}
      isOpen={isOpen}
      onRequestClose={onRequestClose}

    >
      {children}
    </ReactModal>
  );
};
SimpleModal.defaultProps = {

  className: 'ReactModal',
};

export default SimpleModal;

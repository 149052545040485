import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { InfoLabel, Button } from '../../../../../../common/components';
import { InternalButtonBar } from '../../../../../layout/components/InternalButtonBar/InternalButtonBar';
import { Link } from 'react-router';
import { get, capitalize } from 'lodash';
const NOT_APPLICABLE = 'not applicable';
const NOT_USER = 'No Account';

export default class CustomerDetails extends Component {
  constructor(props) {
    super(props);
    this.renderCustomerStatus = this.renderCustomerStatus.bind(this);
  }

  renderCustomerStatus() {
    const customer = this.props.customer;
    const customerStatus = get(customer, 'data.attributes.status');
    if (customerStatus !== NOT_APPLICABLE) {
      return (
        <div>
          <InfoLabel
            label="Account status"
            value={capitalize(customerStatus)}
            labelSize={4}
          />
        </div>
      );
    } else {
      return (
        <div>
          <InfoLabel
            label="Account status"
            value={NOT_USER}
            labelSize={4}
            wrapperClass={'margin-label-fix'}
          />
        </div>
      );
    }
  }

  createAccountButton() {
    const customer = this.props.customer;
    const customerStatus = get(customer, 'data.attributes.status');
    const customerId = get(customer, 'data.id');
    if (customerStatus === NOT_APPLICABLE) {
      return (
        <Button
          label={'Create An Account'}
          bsStyle="primary"
          className="pull-right"
          handleClick={() => this.props.handleCreateUser(customerId)}
        />
      );
    } else {
      return null;
    }
  }

  render() {
    const { customer, address, productCode } = this.props;
    return (
      <div>
        <Row>
          <Col sm={12} className="section-title">
            <h4>Customer Details</h4>
          </Col>
          <Col sm={6}>
            <div className="form-horizontal">
              <InfoLabel
                label="Customer name"
                value={
                  <Link to={'/customers/' + customer.data.id}>
                    {customer.data.attributes.customer_name}
                  </Link>
                }
                labelSize={4}
              />
              <InfoLabel label="Address" value={address || 'TBA'} labelSize={4} />
            </div>
          </Col>
          {productCode !== 'CSP' && (
            <Col sm={6}>
              <div className="form-horizontal">
                <InfoLabel
                  label="Email"
                  value={customer.data.attributes.email || 'TBA'}
                  labelSize={4}
                />
                <InfoLabel
                  label="Phone"
                  value={customer.data.attributes.phone1 || 'TBA'}
                  labelSize={4}
                />
                {this.renderCustomerStatus()}
              </div>
            </Col>
          )}
        </Row>
        {productCode !== 'CSP' && (
          <Row>
            <Col sm={12}>
              <InternalButtonBar
                buttons={[
                  {
                    permissions: ['customer.edit'],
                    button: this.createAccountButton(),
                  },
                ]}
              />
            </Col>
          </Row>
        )}
        <div className="hr" />
      </div>
    );
  }
}

import { Col } from 'react-bootstrap';
import React from 'react';

export const SidebarSection = ({ value, label }) => {
  if (!value) {
    return null;
  }
  return (

    <Col sm={12} className=" quote-divider">
      <div className="quote-title">{label}</div>
      <div className="quote-info">
        {value}
      </div>
    </Col>
  );
};

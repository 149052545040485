import React, { Component } from 'react';

export default class Error500Container extends Component {
  render() {
    return (
      <div>
        <div className="full-width-section background-waves">
          <div className="container">
            <div className="row">
              <div className="col-sm-offset-2 col-sm-6 hero">
                <h2 className="faded">500 - internal server error</h2>
                <h1>Whoops, an error has occurred...</h1>
                <h4>Try again later or contact us.</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import { Field } from 'redux-form';
import { DatePicker } from '../../../../../../common/components';
import React from 'react';

export const AnnualDatePicker = ({ isExpired, minDate, maxDate, validate }) => {
  if (isExpired) {
    return (
      <Field
        name="data.attributes.metadata.start_date"
        id="start_date"
        component={DatePicker}
        validate={validate}
      />
    );
  }
  return (
    <Field
      name="data.attributes.metadata.start_date"
      id="start_date"
      minDate={minDate}
      maxDate={maxDate}
      component={DatePicker}
      validate={validate}
    />
  );
};

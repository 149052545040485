import { Field } from 'redux-form';

import { FormGroup } from 'react-bootstrap';
import TextArea from '../../../../../content/components/TextArea';
import { SelectEndorsement } from '../../../../../../common/components';
import React from 'react';
import {
  AdjustmentFieldTitle
} from '../../../LeisureTravelForm/Components/Fields/AdjustmentFieldTitle';

const Endorsements = ({ show, handleEndorsementChange, currentProduct }) => {
  if (!show) {
    return null;
  }
  return (
    <div>

      <h3>Endorsements</h3>
      <div className="endorsements-mta-box">
        <div className="endorsement-row clearfix endorsement">
          <div className="endorsement-form">
            <Field
              name={'data.attributes.metadata.endorsements[0].title'}
              label="Title"
              labelSize={1}
              component={AdjustmentFieldTitle}
            />
            <FormGroup>
              <Field
                name={'data.attributes.metadata.endorsements[0].content'}
                label="Content"
                labelSize={1}
                component={TextArea}
              />
            </FormGroup>
          </div>
        </div>
        <div>
          <Field
            isObject
            name="meta.endorsement_template"
            onChange={handleEndorsementChange}
            label="Template"
            placeholder="Select template or leave blank to keep automatic change list..."
            filters={currentProduct && currentProduct.data.attributes.product_code === 'CSP' ? [{
              filter: 'filter[product]',
              value: currentProduct.data.id
            }, { filter: 'filter[status]', value: 'active' }] : []}
            labelSize={2}
            component={SelectEndorsement}
          />
        </div>
      </div>
    </div>
  );
};
export default Endorsements;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCampaigns } from '../redux/campaignActions';
import { Button, Loader } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import CampaignFormContainer from './CampaignFormContainer';

class OrganisationEditContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    campaign: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
  };

  componentWillMount() {
    //this.props.dispatch(getCampaigns(this.props.params.id), include='insurer,product,x')
    this.props.dispatch(getCampaigns(this.props.params.id));
  }

  render() {
    const resource = this.props.campaign.resources[this.props.params.id];
    const buttons = (
      <Button bsStyle="default" label="Back" isBackButton leftIcon="caret-left" />
    );

    return (
      <InternalContainer title="Campaign" buttons={buttons}>
        {resource ? (
          <CampaignFormContainer {...this.props} resource={resource} />
        ) : (
          <Loader />
        )}
      </InternalContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    campaign: state.campaign,
  };
}

export default connect(mapStateToProps)(OrganisationEditContainer);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, DataTable, SelectOrganisationType } from '../../../../common/components';
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { FormControl, ControlLabel, Row, Col } from 'react-bootstrap';
import { Button } from '../../../../common/components';
import { push } from 'react-router-redux';
import formatDateTime from '../../../../helpers/formatDateTime';
import { flow } from 'lodash';
import { connect } from 'react-redux';
import Select from 'react-select';
import selectTheme, { styles } from '.././../../../helpers/selectTheme';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';

class OrganisationList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      organisationTypeId: null,
      organisationTypeName: '',
      search: '',
      status: '',
    };
  }

  componentWillMount() {
    const {
      location: {
        query: { filter, display },
      },
      organisationTypes,
    } = this.props;

    if (filter) {
      const orgType = organisationTypes.find((orgType) => {
        return orgType.id === filter;
      });
      if (orgType) {
        this.setState({
          organisationTypeId: orgType.id,
          organisationTypeName: display,
        });
      }
      this.handleTypeFilterUpdate(filter);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { organisationTypes } = this.props;
    if (nextProps.location.query.filter !== this.props.location.query.filter) {
      const orgType = organisationTypes.find((orgType) => {
        return orgType.id === nextProps.location.query.filter;
      });
      if (orgType) {
        this.setState({
          organisationTypeId: orgType.id,
        });
      } else {
        this.setState({
          organisationTypeId: null,
        });
      }
      this.handleTypeFilterUpdate(nextProps.location.query.filter);
    }

    if (nextProps.location.query.display !== this.props.location.query.display) {
      this.setState({
        organisationTypeName: nextProps.location.query.display,
      });
    }
  }

  handleRowClick(row) {
    this.props.dispatch(push('/admin/organisations/' + row.id));
  }

  handleFilterUpdate(option) {
    this.props.dispatch(
      applyFilter('organisations', 'filter[status]', option ? option.value : ''),
    );
    this.setState({
      status: option ? option.value : '',
    });
  }

  handleSearchUpdate(event) {
    this.props.dispatch(applyFilter('organisations', 'filter[name]', event.target.value));
    this.setState({
      search: event.target.value,
    });
  }

  handleTypeFilterUpdate(option) {
    if (option.value) {
      this.setState({
        organisationTypeId: option.value || option,
        organisationTypeName: option.label,
      });
    }

    this.props.dispatch(
      applyFilter('organisations', 'filter[organisation.type]', option.value || option),
    );
  }

  render() {
    const { organisationTypeName } = this.state;
    const { organisationTypeId } = this.state;
    let redirect = '/admin/organisations/new';
    if (organisationTypeId) {
      redirect += '?organisationType=' + organisationTypeId;
      if (organisationTypeName) {
        redirect += '&organisationTypeName=' + organisationTypeName;
      }
    }

    const createButton = (
      <Button
        link={true}
        to={redirect}
        className="pull-right"
        bsStyle="primary"
        label={'New ' + (organisationTypeName || 'Organisation')}
      />
    );

    const filters = [
      { type: 'filter[organisation.type]', value: organisationTypeId },
    ];

    if (organisationTypeName === 'Broker') {
      filters.push({ type: 'filter[brokers.type]', value: 'broker' });
    }

    return (
      <div>
        <Box>
          <Row>
            <Col sm={5}>
              <ControlLabel>Search</ControlLabel>
              <FormControl onChange={::this.handleSearchUpdate} />
            </Col>
            <Col sm={5}>
              <ControlLabel>Type</ControlLabel>
              <SelectOrganisationType
                onChange={(option) => this.handleTypeFilterUpdate(option)}
                input={{
                  value: this.state.organisationTypeId,
                }}
              />
            </Col>
            <Col sm={2}>
              <ControlLabel className="filter-label">Status</ControlLabel>
              <Select
                theme={(theme) => selectTheme(theme)}
                styles={styles}
                options={[
                  { label: 'Active', value: 'active' },
                  { label: 'Inactive', value: 'inactive' },
                ]}
                onChange={(option) => this.handleFilterUpdate(option)}
              />
            </Col>
          </Row>
        </Box>

        <Box>
          <DataTable
            source="/organisations"
            name="organisations"
            showTotal
            autoFilters={filters}
            onRowSelect={this.handleRowClick.bind(this)}
          >
            <TableHeaderColumn dataField="id" isKey hidden />

            <TableHeaderColumn dataField="name" dataSort>
              Name
            </TableHeaderColumn>

            <TableHeaderColumn dataField="organisation_type_name" width={'180px'}>
              Type
            </TableHeaderColumn>

            <TableHeaderColumn dataField="phone1" width={'180px'}>
              Phone
            </TableHeaderColumn>

            <TableHeaderColumn dataField="status" dataSort width={'180px'}>
              Status
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="created_at"
              dataFormat={formatDateTime}
              width={'180px'}
              dataSort
            >
              Created
            </TableHeaderColumn>
          </DataTable>

          <Row>
            <Col sm={12}>
              <InternalButtonBar
                buttons={[
                  {
                    permissions: ['organisation.create'],
                    button: createButton,
                  },
                ]}
              />
            </Col>
          </Row>
        </Box>
      </div>
    );
  }
}

export default flow([
  connect((state) => {
    return {
      organisationTypes: state.layout.organisationTypes,
    };
  }),
])(OrganisationList);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  InfoLabel,
  DataTable,
  Alert,
  Box,
  Button,
} from '../../../../common/components';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { FormattedDate } from 'react-intl';
import { Col, Row } from 'react-bootstrap';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';

export default class RoleDetails extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    role: PropTypes.object.isRequired,
    resource: PropTypes.object.isRequired,
  };

  formatDate(cell) {
    return <FormattedDate value={new Date(cell)} />;
  }

  render() {
    const resource = this.props.resource.data.attributes;

    return (
      <Box>
        <h2 className="resource-name">{resource.name}</h2>

        <Row>
          <Col xs={12}>
            <Alert />
          </Col>

          <Col xs={12}>
            <div className="form-horizontal">
              <InfoLabel label="Name" value={resource.name} labelSize={2} />
            </div>
          </Col>

          <Col xs={12}>
            <div className="form-horizontal">
              <InfoLabel label="Description" value={resource.description} labelSize={2} />
            </div>
          </Col>

          <Col xs={12}>
            <div className="form-horizontal">
              <InfoLabel
                label="Organisation Type"
                value={resource.organisation_type ? resource.organisation_type : ''}
                labelSize={2}
              />
            </div>
          </Col>
        </Row>

        <div className="hr transparent" />

        <Row>
          <Col xs={12}>
            <div className="form-horizontal">
              <h3>Role Permissions</h3>
              <DataTable
                source="/permissions"
                name="permissions"
                autoFilterType="filter[role]"
                autoFilter={this.props.resource.data.id}
                showTotal
              >
                <TableHeaderColumn dataField="id" isKey width={'250px'}>
                  Name
                </TableHeaderColumn>
                <TableHeaderColumn dataField="description" dataSort width={'300px'}>
                  Description
                </TableHeaderColumn>
              </DataTable>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <InternalButtonBar buttons={[
              {
                permissions: ['role.edit'],
                button: (
                  <Button
                    link
                    to={'/admin/roles/' + this.props.resource.data.id + '/edit'}
                    className="pull-right"
                    bsStyle="primary"
                    rightIcon="pencil-square-o"
                    label="Edit Role"
                  />
                )
              }
            ]} />
          </Col>
        </Row>
      </Box>
    );
  }
}

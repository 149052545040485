import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createCustomer, updateCustomer } from '../redux/customerActions';
import CustomerForm from '../components/CustomerForm/CustomerForm';
import mapFields from '../../../helpers/mapFields';
import customerSchema from '../../../schemas/customer';

class CustomerFormContainer extends Component {
  handleSubmit(resource) {
    const { dispatch } = this.props;
    if (resource.data.id) {
      return dispatch(updateCustomer(resource));
    }

    return dispatch(createCustomer(resource));
  }

  render() {
    const fields = mapFields(customerSchema, this.props.resource);
    return (
      <CustomerForm
        {...this.props}
        fields={fields}
        onSubmit={this.handleSubmit.bind(this)}
        submitting={this.props.customer.isSubmitting}
      />
    );
  }
}

CustomerForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
};

export default CustomerFormContainer;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';
import MultiSelectMenu from '../SelectMenu/MultiSelectMenu';
import SelectMenu from '../SelectMenu/SelectMenu';

export const SELECT_IDENTIFIER = 'roles';

class SelectRole extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    label: PropTypes.string,
    labelSize: PropTypes.number,
    infoButton: PropTypes.element,
    name: PropTypes.string,
    meta: PropTypes.object,
    filters: PropTypes.array,
    orgType: PropTypes.string,
    single: PropTypes.bool
  };

  renderSelectMenu() {
    const { single } = this.props;

    if (single) {
      return (
        <SelectMenu
          source={SELECT_IDENTIFIER}
          {...this.props}
        />
      );
    }

    return (
      <MultiSelectMenu
        source={SELECT_IDENTIFIER}
        sourceKey={'MultiRoles'}
        isClearable
        isObject
        {...this.props}
      />
    );

    // return <SelectMenu source={source} filterUrl={filterUrl} {...this.props} optionValuePath={'id'} />;
  }

  render() {
    const { infoButton, meta, label, labelSize } = this.props;

    if (label) {
      return (
        <FormGroup
          controlId={name}
          className={meta && meta.touched && meta.error ? ' has-error' : ''}
        >
          <Col componentClass={ControlLabel} sm={labelSize}>
            {label} {infoButton}
          </Col>
          <Col sm={12 - labelSize}>{this.renderSelectMenu()}</Col>
        </FormGroup>
      );
    }

    return this.renderSelectMenu();
  }
}

export default connect()(SelectRole);

import React, { Component } from 'react';
import { FormGroup, Col, FormControl, InputGroup, ControlLabel } from 'react-bootstrap';
import './HorizontalFormCurrency.scss';
const InputGroupAddon = InputGroup.Addon;
import { FaPoundSign } from 'react-icons/fa';

export default class HorizontalFormCurrency extends Component {
  render() {
    const {
      input,
      meta,
      type,
      labelSize = 4,
      fieldSizes,
      label,
      infoButton,
      placeholder,
      rows,
      disabled,
      currency,
      readOnly,
      className,
      noLabel,
      shouldValidate,
      required,
    } = this.props;
    const filterCurrency = (value) => {
      if (!value) {
        return '';
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const fieldSizeValue = (breakpoint) => fieldSizes ? fieldSizes[breakpoint] : label ? 12 - labelSize : 12;

    const control = (
      <div>
        <InputGroup>
        {currency && (
          <InputGroupAddon>
            <FaPoundSign/>
          </InputGroupAddon>
        )}
        <FormControl
          {...input}
          type={type}
          className={className}
          placeholder={currency ? filterCurrency(placeholder) : placeholder}
          value={currency ? filterCurrency(input.value) : input.value}
          rows={rows}
          disabled={disabled}
          readOnly={readOnly}
        />
      </InputGroup>
      {meta && meta.submitFailed && meta.error && (
        <span className="input-error">{meta.error}</span>
      )}
      </div>
    );

    return (
      <div>
        {!noLabel ? (
          <FormGroup
            controlId={input ? input.name : label}
            className={
              currency
                ? (meta && meta.submitFailed && meta.error) || shouldValidate
                ? 'has-error-currency has-error'
                : ''
                : (meta && meta.submitFailed && meta.error) || shouldValidate
                ? 'has-error'
                : ''
            }
          >
            <Col sm={labelSize} componentClass={ControlLabel}>
              {' '}
              {infoButton} {label}{' '}
            </Col>
            <Col
              xs={fieldSizeValue(0)}
              sm={fieldSizeValue(1)}
              md={fieldSizeValue(2)}
              lg={fieldSizeValue(3)}
            >
              {control}
            </Col>
          </FormGroup>
        ) : (
          <div>
            {control}
          </div>
        )}
      </div>
    );
  }
}

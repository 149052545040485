import { Alert } from 'react-bootstrap';
import React from 'react';

const Warning = ({ isDirty }) => {
  if (!isDirty) {
    return null;
  }

  return (
    <Alert bsStyle="warning" className="text-center">
      YOU HAVE UNSAVED STYLE CHANGES
    </Alert>
  );
};

export default Warning;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MySettingsForm from '../components/MySettingsForm/MySettingsForm';
import { hasPermission } from '../../auth/redux/authActions';
import { saveMySettings, resetPassword2 } from '../redux/authActions';
import { reset } from 'redux-form';

export default class MySettingsContainer extends Component {
  handleSubmit(resource) {
    const { dispatch } = this.props;
    if (hasPermission('user.edit_own')) {
      Promise.resolve(dispatch(saveMySettings(resource))).then(() => {
        return dispatch(reset('mySettings'));
      });
    } else {
      Promise.resolve(dispatch(resetPassword2(resource))).then(() => {
        return dispatch(reset('mySettings'));
      });
    }
  }

  render() {
    return (
      <MySettingsForm
        resource={this.props.auth.user}
        onSubmit={this.handleSubmit.bind(this)}
      />
    );
  }

  static propTypes = {
    auth: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };
}

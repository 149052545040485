import React, {  useState } from 'react';
import { Field, FieldArray } from 'redux-form';
import { Col, ControlLabel, FormGroup, Row } from 'react-bootstrap';
import TextArea from '../../../content/components/TextArea/TextArea';
import { HorizontalFormControl, Button } from '../../../../common/components';
import CollapsableBox from '../CollapsableBox/CollapsableBox';
import './Faqs.scss';

const QuestionSectionHeader = ({ input }) => (<div
  className={'faqs--section-title'}>{input.value}</div>);

const QuestionField = ({ input }) => (
  <div className={'faqs--question-field'}>{input.value}</div>
);

const Delete = ({ onRemove, index }) => {
  const remove = () => {
    onRemove(index);
  };
  return (
    <i className="fa fa-trash" onClick={remove}/>

  );
};

const QuestionAndAnswer = ({ fields }) => {
  const [showQuestionAndAnswer, toggleShowQuestionAndAnswer] = useState('');
  const remove = (index) => {
    fields.remove(index);
  };
  const add = () => {
    fields.push({
      question: ''
    });
  };

  return (
    <>
      {fields.map((fieldName, index) => {
        return (
          <div className={'faq--question-and-answer-section'}>
            <FormGroup>
              <Col sm={12}>
                <div className={'faqs--question-section-title'}>
                  <Delete onRemove={remove} index={index}/>
                  <Field name={`${fieldName}.question`} component={QuestionField}/>


                  <QuestionSectionTitle
                    show={showQuestionAndAnswer === fieldName}
                    onToggle={toggleShowQuestionAndAnswer}
                    fieldName={fieldName}
                  />
                </div>
              </Col>
            </FormGroup>
            {showQuestionAndAnswer === fieldName && (
              <>
                <ControlLabel>Question</ControlLabel>
                <Field name={`${fieldName}.question`} component={TextArea}/>
                <ControlLabel>Answer</ControlLabel>
                <Field name={`${fieldName}.answer`} component={TextArea}/>
              </>
            )}
          </div>
        );
      })}
      <FormGroup>
        <Col sm={12}>
          <Button
            handleClick={add}
            bsStyle={'primary'}
            className={'pull-right'}
          >
            Add
          </Button>
        </Col>
      </FormGroup>
    </>
  );
};
const QuestionSectionTitle = ({ show, onToggle, fieldName }) => {
  const toggleSection = () => {
    onToggle(show ? '' : fieldName);
  };
  return (
    <div className={'faqs--question-section-title'}>
      {show ? (
        <i className="fa fa-minus" onClick={toggleSection}/>
      ) : (
        <i className="fa fa-plus" onClick={toggleSection}/>
      )}
    </div>
  );
};

const QuestionSection = ({ fields }) => {
  const [showSection, toggleShowSection] = useState('');

  const remove = (index) => {
    fields.remove(index);
  };
  const add = () => {
    fields.push({
      header:''
    });
  };
  return (
    <>
      {fields.map((fieldName, index) => {
        return (
          <div className={'faqs--question-section'}>
            <FormGroup>
              <Col sm={12}>


                  <div className={'faqs--question-section-title'}>
                    <Delete onRemove={remove} index={index}/>
                    <Field
                      name={`${fieldName}.header`}
                      component={QuestionSectionHeader}
                      key={index}
                    />

                  <QuestionSectionTitle
                    show={showSection === fieldName}
                    onToggle={toggleShowSection}
                    fieldName={fieldName}
                  />
                  </div>
              </Col>
            </FormGroup>
            {showSection === fieldName && (
              <div className={'faqs--sub-section'}>
                <Field
                  name={`${fieldName}.header`}
                  component={HorizontalFormControl}
                  key={index}
                  label={'Section Header'}
                />

                <FieldArray
                  name={`${fieldName}.questions_and_answers`}
                  component={QuestionAndAnswer}
                />
              </div>
            )}
          </div>
        );
      })}
      <Button
        handleClick={add}
        bsStyle={'primary'}
        className={'pull-right'}
      >
        Add
      </Button>
    </>
  );
};

export const Questions = ({ input }) => {
  return (
    <>
      <FieldArray name={input.name} component={QuestionSection}/>
    </>
  );
};

const ComponentMap = {
  textarea: TextArea,
  text: HorizontalFormControl,
  textarea_array: Questions,
};
export const FaqSections = ({ template, input }) => {
  const sections = template.attributes.sections;
  const sectionKeys = Object.keys(sections);


  return (
    <>
      {sectionKeys.map((templateSectionKey) => {
        //loop through top level sections
        const fieldType = sections[templateSectionKey].fieldtype;
        return (
          <div key={templateSectionKey}>
            <Row>
              <Col sm={12}>
                <Field
                  key={templateSectionKey}
                  name={`${input.name}.${templateSectionKey}`}
                  type={templateSectionKey}
                  label={templateSectionKey}
                  labelSize={2}
                  colSize={12}
                  component={ComponentMap[fieldType]}
                />
              </Col>
            </Row>
          </div>
        );
      })}
    </>
  );
};

const Faqs = ({ template }) => {

  return (
    <CollapsableBox label={'FAQ Content'}>

          <Field
            name={'meta.faq.content.attributes.sections'}
            template={template}
            component={FaqSections}
          />

    </CollapsableBox>
  );

};

export default Faqs;

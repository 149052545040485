import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, FieldArray, formValueSelector, Form } from 'redux-form';
import { Box, Button, SelectCashAccount } from '../../../../common/components';
import { PaymentReceipt } from '../PaymentReceipt';
import { savePaymentsAndReceipts } from '../../redux/accountActions';
import { get, set } from 'lodash';
import './styles.scss';

class PaymentReceiptsForm extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(paymentsAndReceipts) {
    return this.props.dispatch(savePaymentsAndReceipts(paymentsAndReceipts));
  }

  render() {
    const { formValues, handleSubmit, submitting } = this.props;

    return (
      <Box>
        <h2 className="resource-name">Add a payment/receipt</h2>
        <div className="payments-and-receipts-form">
          <Form onSubmit={handleSubmit(this.handleSubmit)}>
            <div className="payments-and-receipts-form-header">
              <div className="row form-horizontal">
                <div className="col-sm-6">
                  <Field
                    name="meta.cash_account"
                    label="Cash Account"
                    id={'cashAccount'}
                    labelSize={4}
                    component={SelectCashAccount}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <FieldArray
                  name="meta.ledger_entries"
                  component={PaymentReceipt}
                  formValues={get(formValues, 'meta.ledger_entries', [])}
                />
              </div>
            </div>

            <div className="hr transparent" />

            <div className="row actions">
              <div className="col-xs-12 clearfix">
                <Button
                  label="Cancel"
                  bsStyle="primary"
                  className="pull-left"
                  link
                  to="/accounts/bookkeeping"
                />

                <Button
                  label="Save Transactions"
                  bsStyle="primary"
                  className="pull-right"
                  type="submit"
                  isLoading={submitting}
                />
              </div>
            </div>
          </Form>
        </div>
      </Box>
    );
  }
}

const validate = (values) => {
  let errors = {};

  if (!get(values, 'meta.cash_account')) {
    set(errors, 'meta.cash_account', 'Cash account is required');
  }

  get(values, 'meta.ledger_entries', []).map((entry, i) => {
    if (!entry.transaction_type) {
      set(
        errors,
        'meta.ledger_entries[' + i + '].transaction_type',
        'Transaction type is required',
      );
    }

    if (!entry.description) {
      set(
        errors,
        'meta.ledger_entries[' + i + '].description',
        'Description is required',
      );
    }

    if (!entry.date) {
      set(errors, 'meta.ledger_entries[' + i + '].date', 'Date is required');
    }

    if (!entry.payment_type) {
      set(errors, 'meta.ledger_entries[' + i + '].payment_type', 'Type is required');
    }

    if (!entry.account) {
      set(errors, 'meta.ledger_entries[' + i + '].account', 'Account is required');
    }

    if (!entry.amount) {
      set(errors, 'meta.ledger_entries[' + i + '].amount', 'Amount is required');
    }
  });

  return errors;
};

const FORM_NAME = 'PaymentReceiptsForm';

PaymentReceipt.propTypes = {
  dispatch: PropTypes.func,
  formValues: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

const form = reduxForm({ form: FORM_NAME, validate })(PaymentReceiptsForm);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state) => {
  const values = selector(state, 'meta.cash_account', 'meta.ledger_entries');

  return {
    formValues: values,
  };
};

export default connect(mapStateToProps)(form);

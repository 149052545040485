import React, { Component } from 'react';
import { includes, get } from 'lodash';
import './styles.scss';

export default class CheckBoxLabelRight extends Component {
  render() {
    const {
      infoButton,
      input,
      label,
      options,
      inline,
      objectKey,
      checked,
      meta: { touched, error },
    } = this.props;

    function isChecked(checked, inputValue, trueValue, trueValues) {
      inputValue = inputValue === true ? 1 : -1;

      if (trueValues) {
        return checked ? true : includes(trueValues, inputValue);
      }

      return checked ? true : inputValue === trueValue;
    }

    return (
      <div className={touched && error ? 'form-group has-error' : 'options-container'}>
        <div className="row">
          <div className="col-sm-12 checkbox-container">
            {options.map((option, key) => {
              const value = objectKey ? get(option.value, objectKey) : option.value;
              return (
                <div
                  className={'options-container' + (inline ? ' inline' : '')}
                  key={key}
                >
                  <div className="label-container">
                    <div
                      className="radio-label"
                      onClick={() =>
                        input.onChange(
                          input.value.toString() === option.value.toString()
                            ? option.value
                            : '',
                        )
                      }
                    >
                      {infoButton}
                      {option.label}
                    </div>
                    <div className={'form-radio'}>
                      <input
                        {...input}
                        type="checkbox"
                        id={input.name + option.value}
                        value={option.value}
                        checked={isChecked(checked, input.value, value, option.valid)}
                      />
                      <label htmlFor={input.name + option.value} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {touched && error && <span className="input-error">{error}</span>}
      </div>
    );
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormGroup, Col, ControlLabel } from 'react-bootstrap';
import SelectMenu from '../SelectMenu/SelectMenu';

export const SELECT_IDENTIFIER = 'brokerRanking';

class SelectMenuBrokerRanking extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    label: PropTypes.string,
    labelSize: PropTypes.number,
    input: PropTypes.object.isRequired,
    infoButton: PropTypes.element,
    name: PropTypes.string,
    meta: PropTypes.object,
    object: PropTypes.bool,
    multi: PropTypes.bool,
    filters: PropTypes.array,
  };

  render() {
    const {
      input,
      infoButton,
      name,
      meta,
      label,
      labelSize,
      filters,
      object,
      isMulti,
    } = this.props;

    let source = 'brokers/rankings';

    if (Array.isArray(filters)) {
      filters.map((filter) => {
        source += (source.includes('?') ? '&' : '?') + filter.filter + '=' + filter.value;
      });
    }

    return (
      <FormGroup
        controlId={name}
        className={meta && meta.touched && meta.error ? ' has-error' : ''}
      >
        <Col componentClass={ControlLabel} sm={labelSize}>
          {label} {infoButton}
        </Col>
        <Col sm={12 - labelSize}>
          <SelectMenu
            source={source}
            object={object}
            isMulti={isMulti}
            name={SELECT_IDENTIFIER}
            field={input}
          />

          {meta && meta.touched && meta.error && (
            <span className="input-error">{meta.error}</span>
          )}
        </Col>
      </FormGroup>
    );
  }
}

export default connect()(SelectMenuBrokerRanking);

import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import {
  Button,
  DatePicker,
  HorizontalFormControl,
} from '../../../../common/components';
import React from 'react';
import { Col, ControlLabel, Modal, Row } from 'react-bootstrap';
import { required } from 'redux-form-validators';
import { openModal } from '../../../../common/components/Modal/ModalRedux';
import { clearFiles } from '../../../../common/components/Upload/UploadRedux';
import DocumentFile from '../DocumentFile/DocumentFile';
import UploadModalComponent from '../../../../common/components/Upload/UploadModal';
import SchemeCheckboxTree from '../SchemeCheckboxTree/SchemeCheckboxTree';
import { formatISO } from 'date-fns';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import AsyncSelectMenu from "../../../../common/components/SelectMenu/AsyncSelectMenu";

import './EditDocumentModal.scss';

const isRequired = required({ message: 'Required' });
const UPLOAD_MODAL = 'uploadEditDocumentModal';

const EditDocumentRow = ({ label, content }) => {
  return (
    <Row className="document-modal-row">
      <Col sm={3}>
        <ControlLabel>
          <strong>{label}:</strong>
        </ControlLabel>
      </Col>
      <Col sm={9}>{content}</Col>
    </Row>
  );
};

const EditDocumentForm = ({
  document,
  schemes,
  isSuperseding,
  isAddingSuperseding,
  isAncillarySchemeDocument,
  hideSchemes,
  onSaveDocument,
  openModal,
  clearFiles,
  closeModal,
  files,
  formValues,
  dispatch,
  change,
  handleSubmit,
  submitting,
}) => {
  const handleEditDocumentUploadComplete = () => {
    if (files[0]) {
      dispatch(change('data.relationships.file.data', files[0]));
      dispatch(change('data.attributes.file_name', files[0].attributes.name));
      dispatch(change('data.attributes.file_url', files[0].attributes.url));
    }
  };

  const resetState = () => {
    clearFiles();
  };

  const handleUpload = () => {
    openModal(UPLOAD_MODAL);
    resetState();
  };

  const handleItemChanged = (selected) => {
    dispatch(
      change(
        'data.relationships.schemes.data',
        selected.map((selected) => ({ id: selected })),
      ),
    );
  };

  const formFilePath = formValues?.data.attributes.file_path;
  const formFileName = formValues?.data.attributes.file_name;
  const schemeFieldName = isAncillarySchemeDocument
    ? 'data.relationships.ancillary_schemes.data'
    : 'data.relationships.schemes.data';
  const existingChannel = getIncludedResource(
    document.data,
    document.included,
    'channel',
  );
  const isProductDocument = !!formValues?.data.relationships?.products?.data?.length;
  return (
    <div>
      <Form onSubmit={handleSubmit(onSaveDocument)}>
        <Modal.Body>
          <div className="edit-document-modal-form">
            {isSuperseding && (
              <EditDocumentRow
                label={'Channel:'}
                content={
                  <Field
                    component={AsyncSelectMenu}
                    isObject
                    url={'/channels?'}
                    name="data.relationships.channel.data"
                    validate={[isRequired]}
                    preSelectedOption={existingChannel ? existingChannel.data : null}
                    disabled={!isAddingSuperseding}
                  />
                }
              />
            )}

            <Field
              label="Display Name"
              labelSize={3}
              fieldSize={9}
              object
              component={HorizontalFormControl}
              name="data.attributes.display_name"
              validate={[isRequired]}
            />

            <EditDocumentRow
              label={'File'}
              content={
                <>
                  <Button
                    bsSize="xs"
                    type="button"
                    bsStyle="primary"
                    className="pad-right pull-left btn-xs"
                    handleClick={handleUpload}
                  >
                    <i className="fa fa-file"/> upload document
                  </Button>
                  <Field
                    name={
                      formFilePath
                        ? 'data.attributes.file_path'
                        : 'data.relationships.file.data.id'
                    }
                    component={DocumentFile}
                    displayName={formFileName}
                    validate={[isRequired]}
                    showButton
                    url={
                      formFilePath ? formFilePath : formValues?.data.attributes.file_url
                    }
                  />
                </>
              }
            />

            {!isSuperseding && (
              <EditDocumentRow
                label={'Active date'}
                content={
                  <Field
                    showTime
                    dateFormat={'dd/MM/yyyy HH:mm'}
                    labelSize={4}
                    label="Start date"
                    timeFormat="HH:mm"
                    component={DatePicker}
                    shouldCloseOnSelect={false}
                    name="data.attributes.from_date"
                  />
                }
              />
            )}
            {!hideSchemes && !isProductDocument && (
              <Row>
                <Col sm={12}>
                  <EditDocumentRow
                    label={'Schemes'}
                    content={
                      <ul className={'schemes-checkbox-container'}>
                        <Field
                          name={schemeFieldName}
                          nodes={schemes}
                          onHandleSchemesChanged={handleItemChanged}
                          component={SchemeCheckboxTree}
                          validate={[isRequired]}
                        />
                      </ul>
                    }
                  />
                </Col>
              </Row>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" className="btn-blue" isLoading={submitting}>
            Save
          </Button>

          <Button className="pull-left" handleClick={closeModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
      <UploadModalComponent
        modalIdentifier={UPLOAD_MODAL}
        maxFiles={1}
        accept="application/pdf"
        onComplete={handleEditDocumentUploadComplete}
      />
    </div>
  );
};

const FORM_NAME = 'EditDocumentForm';
export default compose(
  connect(
    (state, props) => {
      const channel = props.isSuperseding
        ? props.document.data.relationships.channel
        : { data: { id: null } };
      return {
        formValues: getFormValues(FORM_NAME)(state),
        files: state.upload.files,
        initialValues: {
          data: {
            ...props.document.data,
            attributes: {
              ...props.document.data.attributes,
              file_path: null,
              from_date: formatISO(new Date()),
            },

            relationships: {
              ...props.document.data.relationships,
              channel: channel,
              file: {
                data: {
                  id: null,
                },
              },
              supersedes: {
                data: {
                  id: props.isSuperseding ? props.document.data.id : null,
                },
              },
            },
          },
          // meta: {
          //   queue: true,
          // },
        },
      };
    },
    {
      openModal,
      clearFiles,
    },
  ),
  reduxForm({
    form: FORM_NAME,
  }),
)(EditDocumentForm);

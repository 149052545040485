import React from 'react';
import InternalContainer from '../../layout/containers/Internal';
import TrackingLinks from '../components/TrackingLinks';

class TrackingLinksList extends React.Component {
  render() {
    return (
      <InternalContainer title="Tracking Links">
        <TrackingLinks {...this.props} />
      </InternalContainer>
    );
  }
}

export default TrackingLinksList;

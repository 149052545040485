import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Button } from '../../../../../../common/components';
import axios from 'axios';
import { upperCase, isEmpty, set, get } from 'lodash';
import displayCurrency from '../../../../../../helpers/displayCurrency';
import getIncludedResource from '../../../../../../helpers/getIncludedResource';
import * as productActions from '../../../../redux/productActions';
import {
  issuePolicy,
  issuePolicyMta,
  processUploadedEmployees,
  isProcessingUploadedEmployees,
  processingUploadedEmployeesFailed,
  processingUploadedEmployeesSucceded
} from '../../../../redux/productActions';
import { isBroker } from '../../../../../auth/redux/authActions';
import Marketing from '../../../Marketing';
import Communications from '../../../Communications';
import './styles/styles.scss';
import HorizontalFormControl from '../../../../../../common/components/HorizontalFormControl/HorizontalFormControl';
import * as luhn from 'luhn';
import lessThan from '../../../../../../helpers/lessThan';
import InfoButton from '../../../../../../common/components/InfoButton/InfoButton';
import ExpiryDateFields from '../../../../../../common/components/ReduxFormField/ExpiryDateFields';
import Declaration from '../Fields/Declaration/Declaration';
import CovidStatement from '../../../CovidStatement/CovidStatement';
import {
  clearFiles,
  setUploadAccept,
} from '../../../../../../common/components/Upload/UploadRedux';
import { openModal } from '../../../../../../common/components/Modal/ModalRedux';
import UploadModalComponent, { UploadModal } from '../../../../../../common/components/Upload/UploadModal';
import { bindActionCreators } from 'redux';

const isLuhnHappy = (value) => {
  const val = value && value.replace(/\s/g, '');
  return val && luhn.validate(val) ? null : 'This is not a valid card number';
};

class PaymentTab06 extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleWindowMessage = this.handleWindowMessage.bind(this);
    this.renderPaymentOptions = this.renderPaymentOptions.bind(this);
    this.openPaymentMethod = this.openPaymentMethod.bind(this);
    this.renderButton = this.renderButton.bind(this);
    this.renderCommOptions = this.renderCommOptions.bind(this);
    this.handleUploadEmployees = this.handleUploadEmployees.bind(this);
    this.handleUploadEmployeesComplete = this.handleUploadEmployeesComplete.bind(this);

    this.state = {
      applicationErrors: [],
      premium: null,
      currency: null,
      loading: false,
    };
  }

  renderButton(field) {
    const { meta, type, className, label } = field;
    const style = [className, meta.error && meta.submitFailed && 'error'];
    return (
      <Button
        className={style.join(' ')}
        label={<div className="payment-type-label"> {upperCase(label)} </div>}
        handleClick={() => this.openPaymentMethod(type)}
      />
    );
  }

  openPaymentMethod(value) {
    this.props.dispatch(this.props.change('meta.payment_type', value));
  }

  renderPaymentOptions() {
    const { formValues, submitFailed, product, application } = this.props;
    const isBrokerBySource = get(product, 'source.attributes.is_broker');

    const brokerIncluded = getIncludedResource(
      application.data,
      application.included,
      'broker',
    );
    const hasBrokerRelationship = !isEmpty(brokerIncluded.data);
    const isBmc = this.props.product.productCode === 'BMC';
    const isCsp = this.props.product.productCode === 'CSP';

    const broker = (isBrokerBySource || isBroker() || hasBrokerRelationship || isCsp) && !isBmc;

    const paymentTitleStyle = ['pay-method__title', submitFailed && 'pay-method--error'];
    const classButton = (value) =>
      value === get(formValues, 'meta.payment_type') ? 'buttonSelected' : '';
    const finalPremium = get(formValues, 'meta.amount');

    if (finalPremium && finalPremium > 0) {
      return (
        <div>
          <div className="pay-method">
            <div className={paymentTitleStyle.join(' ')}>
              Please select a payment method:
            </div>
          </div>
          <div className="payment-buttons">
            {!isCsp && (
              <div>
                <Field
                  name={'meta.card-button'}
                  type={'card'}
                  label={'card'}
                  component={this.renderButton}
                  className={`btn-payment ${classButton('card')}`}
                />
              </div>
            )}
            {broker && (
              <div>
                <Field
                  name={'meta.account-button'}
                  type={'account'}
                  label={'Broker Account'}
                  component={this.renderButton}
                  className={`btn-payment ${classButton('Broker Account')}`}
                />
              </div>
            )}
          </div>
          {get(formValues, 'meta.payment_type') === 'card' && this.renderCard()}
        </div>
      );
    }
  }

  componentDidMount() {
    const { dispatch, parentChange } = this.props;
    dispatch(parentChange('data.current_tab', 6));

    if (process.env.BROWSER) {
      window.addEventListener('message', this.handleWindowMessage);
    }
  }

  componentWillUnmount() {
    const { dispatch, product } = this.props;

    if (process.env.BROWSER) {
      window.removeEventListener('message', this.handleWindowMessage);
    }

    if (product.isCompletingTransaction) {
      dispatch(productActions.transactionEmergencyBrakes());
    }
  }

  handleWindowMessage(event) {
    const { dispatch } = this.props;

    // For Chrome, the origin property is in the event.originalEvent object.
    const origin = event.origin || event.originalEvent.origin;

    if (origin !== axios.defaults.baseURL) {
      return;
    }

    const data = event.data;

    if (data.status !== 'complete') {
      dispatch(productActions.failTransaction(data.message));
      return;
    }

    dispatch(productActions.completeTransaction(data.policies[0].policy_id));
  }

  componentWillMount() {
    const { dispatch, change, selectedSchemes, application, product, selectedPremium } = this.props;

    const selectPremium = get(selectedPremium, 'meta.premium', {});
    const selectedScheme =
      selectedSchemes && selectedSchemes.length === 1 && selectedSchemes[0].id
        ? product.quotes[selectedSchemes[0].id]
        : false;
    const schemeId = selectedScheme && selectedScheme.meta.scheme.id;

    dispatch(isProcessingUploadedEmployees(null));
    dispatch(processingUploadedEmployeesSucceded( null));
    dispatch(processingUploadedEmployeesFailed(null));

    const errors = [];
    if (application) {
      const premiums = getIncludedResource(
        application.data,
        application.included,
        'premiums',
      );

      const customer = getIncludedResource(
        application.data,
        application.included,
        'customer',
      );
      const validationErrors = get(application, 'data.attributes.validation', false);
      let selectedPremium = null;
      const advSport = ['ADV', 'PST'].includes(get(product, 'productCode', ''));

      if (selectPremium && advSport) {
        const premiumId = get(application, 'data.relationships.premiums.data[0].id', '');
        dispatch(change('data.relationships.premium.data.id', premiumId));
        dispatch(change('meta.amount', selectPremium.gross));
        dispatch(change('meta.product_code', get(product, 'productCode', '')));
        // remove the meta.payment_type for MTA and Premium = 0
        if (selectPremium.gross === 0 && this.props.mta) {
          dispatch(change('meta.payment_type', ''));
        }
        // If mta and it is a refund (negative value), set as recharge
        if (selectPremium.gross < 0 && this.props.mta) {
          dispatch(change('meta.payment_type', 'recharge'));
        }
      } else if (premiums.length) {
        premiums.map((premium) => {
          if (
            get(premium, 'attributes.scheme.id') === schemeId ||
            get(premium, 'attributes.premium_type') === 'return premium'
          ) {
            selectedPremium = premium;
            dispatch(change('data.relationships.premium.data.id', premium.id));
            dispatch(change('meta.amount', premium.attributes.gross));
            // remove the meta.payment_type for MTA and Premium = 0
            if (premium.attributes.gross === 0 && this.props.mta) {
              dispatch(change('meta.payment_type', ''));
            }
            // If mta and it is a refund (negative value), set as recharge
            if (premium.attributes.gross < 0 && this.props.mta) {
              dispatch(change('meta.payment_type', 'recharge'));
            }
          }
        });
      }

      if (customer) {
        dispatch(change('meta.sms_number', get(customer, 'data.attributes.phone1', '')));
      }

      if (validationErrors) {
        Object.keys(validationErrors).map((errorKey) => {
          if (!validationErrors[errorKey]) {
            errors.push(errorKey + ' required');
          }
        });
      }

      this.setState({
        premium: selectedPremium,
        /* currency: selectedPremium.attributes.currency, */
      });
    }

    this.setState({ applicationErrors: errors });
  }

  handleSubmit(values) {
    const { dispatch, application, currentProduct } = this.props;
    const product = get(currentProduct, 'data.attributes.product_code', false);

    if (!application || !application.data.id) {
      console.error('Error: Invalid quote.');
      return false;
    }
    if (get(values, 'meta.amount', 0) === 0 && this.props.mta) {
      return dispatch(issuePolicyMta(application.data.id, values));
    }
    if (get(values, 'meta.amount', 0) !== 0 || product === 'BMC') {
      return dispatch(issuePolicy(application.data.id, values));
    }
  }

  renderCompleteTransactionFrame() {
    const { product } = this.props;
    const url =
      axios.defaults.baseURL + '/public/transactions/' + product.transactionId + ':pay';
    return (
      <div className="payment__container">
        <iframe src={url} className="payment__iframe"/>
      </div>
    );
  }

  renderCommOptions() {
    return (
      <div>
        <Communications name={'data.attributes.metadata.communications'}/>
      </div>
    );
  }

  renderMarketingOptions() {
    return (
      <div>
        <div className="marketing-title">
          <h4>Marketing</h4>
        </div>
        <Marketing
          label="Marketing"
          name="data.attributes.metadata.adults[0].marketing_options"
          formValues={this.props.formValues}
          dispatch={this.props.dispatch}
          change={this.props.change}
          showText
        />
      </div>
    );
  }

  renderCard() {
    const { formValues } = this.props;
    const isPreviousCard = get(formValues, 'meta.previous_card', false);

    return (
      <div className="fadein-fields form-horizontal">
        {!isPreviousCard && (
          <div className="card-fields-container">
            <Field
              className="text-left"
              name={'meta.card_number'}
              label="Card number"
              id={'meta.card_number'}
              type="cleave"
              cleaveOptions={{ creditCard: true }}
              labelSize={2}
              mdFieldSize={4}
              component={HorizontalFormControl}
              validate={isLuhnHappy}
            />
            <Field
              className="text-left"
              name={'meta.cvv'}
              mdFieldSize={4}
              label="CVC/CV2"
              normalize={lessThan(3)}
              labelSize={2}
              infoButton={<InfoButton content={definitions.cvc}/>}
              component={HorizontalFormControl}
            />
            <ExpiryDateFields
              baseName={'meta'}
              label="Expiry date"
              labelSizes={{ sm: 2, md: 2, lg: 2 }}
              fieldSizes={{ sm: 4, md: 4, lg: 4 }}
            />
          </div>
        )}
      </div>
    );
  }

  handleUploadEmployees() {
    const { dispatch } = this.props;
    dispatch(setUploadAccept('*'));
    dispatch(openModal(UploadModal.MODAL_IDENTIFIER));
  }

  handleUploadEmployeesComplete() {
    const { dispatch, files, application, actions } = this.props;
    const fileId = files[0].id;
    actions.clearFiles();
    return dispatch(processUploadedEmployees(application.data.id, fileId));
  }

  renderEmployeesUploadForm() {
    const { product } = this.props;
    const submitting = false;
    return (
      <div>
        <div>
          <h4>Employees</h4>
        </div>
        <Button
          type="button"
          bsStyle="primary"
          handleClick={this.handleUploadEmployees}
          disabled={submitting}
        >
          {submitting ? (
            <i className="fa fa-cog fa-spin"/>
          ) : (
            <i className="fa fa-upload"/>
          )}{' '}
          Upload
        </Button>
        <UploadModalComponent
          accept={'text/csv'}
          onComplete={this.handleUploadEmployeesComplete}
        />
        {product.isProcessingUploadedEmployees && (
          <div>Processing uploaded employees...</div>
        )}
        {product.processingUplodedEmployeesSucceded && (
          <div>
            Processed successfully <i className="fa fa-check"/>
          </div>
        )}
        {product.processingUplodedEmployeesFailed && (
          <div>
            Failed to process <i className="fa fa-exclamation"/>
          </div>
        )}
      </div>
    );
  }

  render() {
    const {
      getPremium,
      premium,
      handleSubmit,
      submitting,
      product,
      formValues,
      mta,
      currentProduct,
      declaration,
      hasSignpostedPremiums,
      selectedPremium,
      application
    } = this.props;
    const agreed = get(formValues, 'meta.purchase_declaration', false) === true;
    const bmc = get(currentProduct, 'data.attributes.product_code', false);
    // const customer = getIncludedResource(application.data, application.included, 'customer')
    const { applicationErrors } = this.state;
    const covidMessage = get(product, 'resources.' + product.productId + '.data.attributes.metadata.covid_message.payment_bo', '');

    const showEmployeesUploadForm = product.productCode === 'CSP'
      && (!mta || (mta && this.props.formState.totalEmployeesPreMTA !== parseInt(get(application, 'data.attributes.metadata.total_employees'))));

    if (applicationErrors.length > 0) {
      return (
        <div className="row">
          <div className="col-xs-12">
            <h4>Not ready for issuing</h4>
            {applicationErrors.length > 0 && (
              <div>
                <p>Please resolve the following issues:</p>
                <ul>
                  {applicationErrors.map((errorItem, i) => (
                    <li key={i}>{errorItem}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      );
    }

    if (product.isCompletingTransaction) {
      return this.renderCompleteTransactionFrame();
    }

    const showPaymentOptions = (agreed || mta)
      && (!showEmployeesUploadForm || (showEmployeesUploadForm && product.processingUplodedEmployeesSucceded));

    return (
      <div>
        {this.renderCommOptions()}
        <hr/>
        {showEmployeesUploadForm && (
          <div>
            {this.renderEmployeesUploadForm()}
            <hr/>
          </div>
        )}
        {this.renderMarketingOptions()}
        <hr/>
        <Declaration
          show={!mta}
          declaration={declaration}
          productCode={product.productCode}
          hasSignpostedPremiums={hasSignpostedPremiums}
          selectedPremium={selectedPremium}
        />
        <div>
          <CovidStatement covidMessage={covidMessage} showAgreement={false}/>
        </div>
        {(showPaymentOptions) && (
          <div>
            <hr/>
            <h4>Payment Details</h4>
            {this.renderPaymentOptions()}
            <div className="divider"/>
            {(getPremium && getPremium !== 0) || (bmc && getPremium) ? (
              <div className="total-premium">
                <span>Total to pay:</span>
                <span className="total-premium red">
                  &nbsp;
                  {displayCurrency(premium)}
                  <span style={{ fontSize: '0.8em' }}>
                    &nbsp;including {this.props.taxRate}% Insurance Premium Tax (IPT)
                  </span>
                </span>
              </div>
            ) : (
              <div/>
            )}
            <div className="payment-options-container">
              <Button
                type="submit"
                bsStyle="primary"
                isLoading={submitting}
                handleClick={handleSubmit(this.handleSubmit)}
                label="Issue Policy"
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  const skipValidation = parseFloat(get(values, 'meta.amount')) <= 0;
  const isPaymentSelected = get(values, 'meta.payment_type', false);
  if (!isPaymentSelected && !skipValidation) {
    set(errors, 'meta.card-button', 'Please select a payment method.');
    set(errors, 'meta.account-button', 'Please select a payment method.');
  }
  errors._error = errors.metadata;
  return errors;
};

const definitions = {
  cvc: 'content required',
};

const FORM_NAME = 'IssuePolicyDialog';
const form = reduxForm({ form: FORM_NAME, validate })(PaymentTab06);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  const values = selector(state, 'meta', 'data');

  return {
    initialValues: {
      relationships: {
        premium: {
          data: {
            type: 'premium',
            id: null,
          },
        },
      },
      meta: {
        product_code: get(props.product, 'data.attributes.product_code', ''),
        sms_send: false,
        purchase_declaration: false,
        payment_type: null,
      },
    },
    formValues: values,
    files: state.upload.files,
  };
};

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({ clearFiles }, dispatch), dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(form);

import React from 'react';
import { Field } from 'redux-form';
import { Col, Row } from 'react-bootstrap';
import { HorizontalFormControl } from '../../../../common/components';
import { format, length } from 'redux-form-validators';
import CollapsableBox from '../CollapsableBox/CollapsableBox';

const SenderConfig = () => {
  return (
    <CollapsableBox label={'Sender Information'}>
      <>
        <Row>
          <Col sm={12}>
            <Field
              name="meta.whitelabel.sender.email.address"
              label="Email Sender Address"
              component={HorizontalFormControl}
              validate={format({
                with: /^[^\s@]+@[^\s@]+$/,
                message: 'Please enter a valid email address',
              })}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Field
              name="meta.whitelabel.sender.email.name"
              label="Email Sender Name"
              component={HorizontalFormControl}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Field
              name="meta.whitelabel.sms_sender_name"
              label="SMS Sender Name"
              component={HorizontalFormControl}
              validate={[
                format({
                  with: /^[A-Za-z0-9 ]+$/,
                  message: 'Only alphanumeric characters allowed.',
                }),
                length({
                  maximum: 11,
                  message: 'Maximum 11 characters allowed.'
                }),
              ]}
            />
          </Col>
        </Row>
      </>

    </CollapsableBox>
  );
};

export default SenderConfig;

import React, { Component } from 'react';
import './GuestFooter.scss';

export default class GuestFooter extends Component {
  render() {
    return (
      <footer className="footer guest">
        <div className="container">
          <div className="row footer-content">
            <p>
              P J Hayman & Company Limited. Registered in England No 2534965. Registered
              Office: Stansted House, Rowlands Castle, Hampshire PO9 6DX.
              <br />P J Hayman & Company Limited is authorised and regulated by the
              Financial Conduct Authority.
              <br />
              Our FS register number is 497103. You can check this by visiting
              www.fca.org.uk or calling <strong>0800 111 6768</strong> or{' '}
              <strong>0300 500 8082</strong>.
            </p>
          </div>
        </div>
      </footer>
    );
  }
}

import React from 'react';
import { grantPermission } from '../../../auth/redux/authActions';
import { ButtonBar }  from '../../../../common/components';

const InternalButtonBar = ({ buttons }) => {


  const filterMatched = (filters) => {
    return filters.reduce((carry, filter) => {
      const matched = filter.filterValues.includes(filter.value);
      return carry && matched;
    }, true);
  };

  const filterButtons = (buttons) => {
    return buttons.filter((button) => {
      if (!button.button) {
        return false;
      }
      const showButton = button.filter ? filterMatched(button.filter, button) : true;
      return showButton && grantPermission(button);
    });
  };

  return <ButtonBar children={filterButtons(buttons).map(button => button.button)}/>;

};


export { InternalButtonBar };

import React from 'react';
import cx from 'classnames';
import displayCurrency from '../../../../../../../../helpers/displayCurrency';
import { Icon } from '../../../../../../../../common/components';
import './PremiumSelect.scss';

const singleAnnualDiff = (single, annual) => Math.abs(single - annual);
const Discount = (isMta, premiumBeforeAdjustment, gross, currency) => {
  if (isMta) {
    return null;
  }
  if (premiumBeforeAdjustment !== gross) {
    return (
      <span className="previousPremium">
                                            {' '}{displayCurrency(
        premiumBeforeAdjustment,
        currency,
      )}{' '}
      </span>
    );
  }
};
const Price = ({ gross, currency, isSchemeSelected, errors, declines }) => {


  if (gross === undefined || !!errors?.length || !!declines?.length) {
    return (
      <>
        N/A
        <span className="icon-padding">
          <Icon name="circle-thin"/>
        </span>
      </>
    );
  }


  return (
    <>
      {displayCurrency(gross, currency)}
      <span className="icon-padding">
        <Icon name={isSchemeSelected ? 'check-circle' : 'circle-thin'}/>
      </span>

    </>
  );
};

const Premium = ({
  gross,
  currency,
  premiumBeforeAdjustments,
  errors,
  declines,
  rates,
  isMta,
  schemeId,
  onSchemeSelect,
  isSchemeSelected
}) => {


  const handleSchemeSelect = () => {

    if (errors?.length) {
      return;
    }
    if (gross) {

      onSchemeSelect(schemeId);
    }
    if (!gross && isMta) {
      onSchemeSelect(schemeId);
    }

  };
  return (
    <div
      className={
        cx({
          'premium-select': gross && !isSchemeSelected,
          'premium-select selected': gross && isSchemeSelected,
          'premium-select unquoted': !gross,
        })}

      onClick={handleSchemeSelect}
    >
      <div
        className={
          cx({
            'premium-select--quote-value-selected': isSchemeSelected,
            'premium-select--quote-value': !isSchemeSelected
          })
        }>
        <Discount isMta={isMta} gross={gross}
                  premiumBeforeAdjustment={premiumBeforeAdjustments}
                  currency={currency}/>
        <Price
          gross={gross}
          isSchemeSelected={isSchemeSelected}
          errors={errors}
          declines={declines}
        />

      </div>

      {!!gross && !!rates?.tax_rate && (
        <span className="incl-ipt-mssg">inc. IPT</span>
      )}
    </div>
  );
};
const UpgradeBar = ({ show, singleGross, annualGross }) => {
  if (!show) {
    return null;
  }

  return (
    <div className={'upgrade-bar'}>

      <div className="upgrade-bar-info">
        <strong>
          {displayCurrency(
            singleAnnualDiff(
              singleGross,
              annualGross,
            ),
          )}{' '}
        </strong>
        extra to upgrade to Annual
      </div>

    </div>
  );
};
export const PremiumSelect = ({
  isAnnualSelected,
  selectedSchemes,
  isMta,
  onSchemeSelect,
  annualQuote,
  singleQuote
}) => {

  const {
    scheme: annualScheme,
    premium: annualPremium,
    errors: annualErrors,
    rates: annualRates,
    declines: annualDeclines
  } = annualQuote ?? {};

  const {
    scheme: singleScheme,
    premium: singlePremium,
    errors: singleErrors,
    rates: singleRates,
    declines: singleDeclines,
  } = singleQuote ?? {};

  const {
    gross: annualGross,
    premium_before_adjustments: annualPremiumBeforeAdjustments,
  } = annualPremium ?? {};
  const {
    gross: singleGross,
    premium_before_adjustments: singlePremiumBeforeAdjustments,
  } = singlePremium ?? {};

  return (
    <>
      <>
        {!isAnnualSelected && (
          <div className={'premium-select--single-container'}>
            <div className={'premium-select--premiums'}>
              <div className="flex2 quote-value-container">
                <Premium
                  onSchemeSelect={onSchemeSelect}
                  errors={singleErrors}
                  declines={singleDeclines}
                  taxRate={singleRates?.tax_rate}
                  gross={singleGross}
                  premiumBeforAdjustments={singlePremiumBeforeAdjustments}
                  rates={singleRates}
                  isMta={isMta}
                  schemeId={singleScheme?.id}
                  isSchemeSelected={selectedSchemes.includes(singleScheme?.id)}
                />

              </div>
              <div className="flex2 quote-value-container">
                <Premium
                  onSchemeSelect={onSchemeSelect}
                  errors={annualErrors}
                  declines={annualDeclines}
                  taxRate={annualRates?.tax_rate}
                  gross={annualGross}
                  premiumBeforAdjustments={annualPremiumBeforeAdjustments}
                  rates={annualRates}
                  isMta={isMta}
                  schemeId={annualScheme?.id}
                  isSchemeSelected={selectedSchemes.includes(annualScheme?.id)}
                />
              </div>
            </div>
            <UpgradeBar
              show={singleGross && annualGross && singleGross < annualGross}
              singleGross={singleGross}
              annualGross={annualGross}
            />


          </div>
        )}
        {isAnnualSelected && (
          <div className="flex3 quote-value-container">
            <Premium
              onSchemeSelect={onSchemeSelect}
              errors={annualErrors}
              declines={annualDeclines}
              taxRate={annualRates?.tax_rate}
              gross={annualGross}
              premiumBeforAdjustments={annualPremiumBeforeAdjustments}
              rates={annualRates}
              isMta={isMta}
              schemeId={annualScheme?.id}
              isSchemeSelected={selectedSchemes.includes(annualScheme?.id)}
            />
          </div>

        )}

      </>

    </>
  );

};

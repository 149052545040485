import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import TemplateSelector from '../components/TemplateSelector/TemplateSelector';
import ContentFormContainer from './ContentForm';
import { getTemplate } from '../redux/contentActions';
import { get } from 'lodash';

const templateDefaults = {
  id: null,
  type: 'templates',
  attributes: {
    name: '',
    slug: '',
    sections: {},
  },
};

class ContentNewContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    content: PropTypes.object.isRequired,
    template: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      template: templateDefaults,
    };
    this.handleTemplateChange = this.handleTemplateChange.bind(this);
  }

  componentWillMount() {
    if (this.props.location.query.template) {
      getTemplate('announcement').then((template) => {
        return this.setState({ template });
      });
    }
  }

  handleTemplateChange(template) {
    if (!template) {
      return this.setState({
        template: templateDefaults,
      });
    }

    return this.setState({ template });
  }

  render() {
    const templateId = get(this.state, 'this.state.template.id', '');
    const templateType = get(this.state, 'this.state.template.id', '');

    const buttons = (
      <Button bsStyle="default" label="back" isBackButton leftIcon="caret-left" />
    );

    const resource = {
      data: {
        type: 'content',
        relationships: {
          template: {
            data: {
              id: templateId,
              type: templateType,
            },
          },
          files: {
            data: [],
          },
        },
      },
    };

    return (
      <InternalContainer title="Content Management" buttons={buttons}>
        <TemplateSelector
          {...this.props}
          onChange={this.handleTemplateChange}
          template={this.state.template}
        />
        <ContentFormContainer
          {...this.props}
          resource={resource}
          template={this.state.template}
        />
      </InternalContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    content: state.content,
  };
}

export default connect(mapStateToProps)(ContentNewContainer);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { Button, Modal } from '../../../../common/components';
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux';
import { updateRenewalStatus } from '../../redux/policyActions';
import './styles.scss';
import { SelectField, TextField } from '../../../../common/components/ReduxFormField';
import { required } from 'redux-form-validators';

const FORM_NAME = 'nonAutoRenewalForm';

class NonAutoRenewalButton extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    callback: PropTypes.func,
    label: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      showOtherReason: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded, callback } = this.props;
    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('');
      }
      dispatch(closeModal(FORM_NAME));
    }
  }

  handleSubmit(values) {
    return this.props.dispatch(updateRenewalStatus(this.props.resource, values, 'manual'));
  }

  handleOpen() {
    const { dispatch, reset } = this.props;

    this.setState(() => ({
      showOtherReason: false,
    }));
    dispatch(reset());
    dispatch(openModal(FORM_NAME));
  }

  handleChange(value) {
    const valueArr = Object.values(value);
    valueArr.pop();
    const valueString = valueArr.join("");
    if ('other' === valueString.toLowerCase()) {
      this.setState(() => ({
        showOtherReason: true,
      }));
    } else {
      this.setState(() => ({
        showOtherReason: false,
      }));
    }
  }

  render() {
    const { label, handleSubmit, submitting } = this.props;
    const title = label || 'Non Auto Renewal';
    const submitButton = (<Button
      type="submit"
      bsStyle="primary"
      isLoading={submitting}
      handleClick={handleSubmit(this.handleSubmit)}
      label={title}
    />);

    const reasons = [
      { label: 'I no longer require cover', value: 'I no longer require cover' },
      { label: 'I have cover elsewhere', value: 'I have cover elsewhere' },
      { label: 'Too expensive', value: 'Too expensive' },
      { label: 'Other', value: 'Other' },
    ];

    return (
      <div className="prevent-renewal-btn-wrapper pull-right">
        <Button
          bsStyle="primary"
          label={title}
          handleClick={::this.handleOpen}
          isLoading={submitting}
        />

        <Modal
          name={FORM_NAME}
          title={title}
          close={true}
          footer={submitButton}
        >
          <Field
            validate={[required({ msg: 'Reason is required' })]}
            name="meta.reason"
            options={reasons}
            component={SelectField}
            label={'Reason'}
            onChange={this.handleChange}
            isMulti={false}
          />
          {this.state.showOtherReason && (
            <Field
              name="meta.note.content"
              type="textarea"
              component={TextField}
              validate={[required({ msg: 'This is required' })]}
            />
          )}
        </Modal>
      </div>
    );
  }
}

const form = reduxForm({ form: FORM_NAME })(NonAutoRenewalButton);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  const values = selector(state,
    'meta.note.content',
    'meta.reason'
  );
  return {
    policy: state.policy,
    formValues: values,
    initialValues: {
      meta: {
        policy_id: props.resource.data.id
      }
    }
  };
};

export default connect(mapStateToProps)(form);

import React from 'react';

export const users = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95 95">
    <title>Icons</title>
    <path d="M87.5,71.25a6.27,6.27,0,0,1-6.25,6.25H13.75A6.27,6.27,0,0,1,7.5,71.25V23.75a6.27,6.27,0,0,1,6.25-6.25h67.5a6.27,6.27,0,0,1,6.25,6.25Zm-5-47.5a1.29,1.29,0,0,0-1.25-1.25H13.75a1.29,1.29,0,0,0-1.25,1.25v47.5a1.29,1.29,0,0,0,1.25,1.25H27.5V68.75a1.23,1.23,0,0,1,1.25-1.25h2.5a1.23,1.23,0,0,1,1.25,1.25V72.5h30V68.75a1.23,1.23,0,0,1,1.25-1.25h2.5a1.23,1.23,0,0,1,1.25,1.25V72.5H81.25a1.29,1.29,0,0,0,1.25-1.25ZM42.5,62.5h-20c-2.77,0-5-2.42-5-5.82,0-6.05,1.48-12.77,7.66-12.77,1.91,1.09,4.49,3,7.34,3s5.43-1.87,7.34-3c6.17,0,7.66,6.72,7.66,12.77C47.5,60.08,45.27,62.5,42.5,62.5Zm-10-17.27a8.87,8.87,0,1,1,8.87-8.87A8.86,8.86,0,0,1,32.5,45.23Zm45-9a1.23,1.23,0,0,1-1.25,1.25H53.75a1.23,1.23,0,0,1-1.25-1.25v-2.5a1.23,1.23,0,0,1,1.25-1.25h22.5a1.23,1.23,0,0,1,1.25,1.25Zm0,9.84a1.4,1.4,0,0,1-1.41,1.41H53.91a1.4,1.4,0,0,1-1.41-1.41V43.91a1.4,1.4,0,0,1,1.41-1.41H76.09a1.4,1.4,0,0,1,1.41,1.41Zm0,10.16a1.23,1.23,0,0,1-1.25,1.25H53.75a1.23,1.23,0,0,1-1.25-1.25v-2.5a1.23,1.23,0,0,1,1.25-1.25h22.5a1.23,1.23,0,0,1,1.25,1.25Z" />
  </svg>
);

export default users;

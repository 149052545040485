export const bySchemeType = (schemes) => {
  if (!schemes || !Array.isArray(schemes)) {
    return {}
  }

  return schemes?.reduce((carry, scheme) => {
    const schemeType = scheme.attributes.group_key;
    if (!carry[schemeType]) {
      carry[schemeType] = [scheme];
      return carry;
    }
    carry[schemeType].push(scheme);
    return carry;
  }, {});
};

//
// export const schemeGroupsByGroupey = (schemes) => {
//
//
//   return schemes?.reduce((carry, scheme) => {
//
//     const schemeType = scheme.attributes.group_key;
//
//
//     if (!carry[schemeType]) {
//
//       carry[schemeType] = [scheme]
//
//       return carry;
//     }
//     const existing = carry[schemeType];
//     existing.push(scheme);
//
//     carry[schemeType] = existing;
//     return carry;
//
//   }, {});
// };

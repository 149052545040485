import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InternalContainer from '../../layout/containers/Internal';
import BordereauxReports from '../components/BordereauxReports';
import PerformanceReports from '../components/PerformanceReports';
import RenewalReports from '../components/RenewalReports';
import LedgerReports from '../components/LedgerReports';
import BrokerReports from '../components/BrokerReports';
import InternalTabs from '../../layout/components/InternalTabs/InternalTabs';

const TAB_NAME = 'reportTabs';

class AccountReportsContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  render() {
    return (
      <InternalContainer title="Reporting">
        <InternalTabs
          id={TAB_NAME}
          tabs={[
            {
              permissions: ['report.bdx'],
              orgTypes: ['owner'],
              title: 'Bordereaux/Statements',
              content: <BordereauxReports {...this.props} />,
            },
            {
              permissions: ['report.predefined', 'policy.renew'],
              orgTypes: ['owner'],
              title: 'Renewals',
              content: <RenewalReports {...this.props} />,
            },
            {
              permissions: ['report.predefined'],
              orgTypes: ['owner'],
              title: 'Performance',
              content: <PerformanceReports {...this.props} />,
            },
            {
              permissions: ['report.predefined', 'account.view'],
              orgTypes: ['owner'],
              title: 'Ledger',
              content: <LedgerReports {...this.props} />,
            },
            {
              permissions: ['report.ad_hoc'],
              orgTypes: ['broker'],
              title: 'Reports',
              content: <BrokerReports {...this.props} />,
            },
          ]}
        />
      </InternalContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    account: state.account,
  };
}

export default connect(mapStateToProps)(AccountReportsContainer);

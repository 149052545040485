import React from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import './DocumentVersionSelector.scss';

const DocumentVersionSelector = ({ onSelectDocument, documentList, activeDocument }) => {
  const handleSelectDocument = (document) => () => {
    onSelectDocument(document);
  };
  return (
    <DropdownButton
      pullRight
      bsStyle={'default'}
      bsSize={'xs'}
      id={'split-button-basic'}
      title={'v' + activeDocument.version_number}
      className={`document-version-dropdown-btn ${activeDocument.status}`}
    >
      {documentList.map((document, index) => {
        return (
          <MenuItem
            key={document.id}
            eventKey={index + 1}
            active={activeDocument.id === document.id}
            onClick={handleSelectDocument(document)}
          >
            <div className="dropdown-item">
              <span>
                {document.name} - v{document.version_number}
              </span>
            </div>
          </MenuItem>
        );
      })}
    </DropdownButton>
  );
};

export default DocumentVersionSelector;

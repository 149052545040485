import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { Col, ControlLabel, ModalFooter, Row } from 'react-bootstrap';
import {
  CheckboxGroup,
  TextField
} from '../../../../../common/components/ReduxFormField';

import { DatePicker } from '../../../../../common/components';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  removeFile,
  setUploadAccept
} from '../../../../../common/components/Upload/UploadRedux';
import { Button } from '../../../../../common/components/Button/Button';
import { openModal } from '../../../../../common/components/Modal/ModalRedux';
import UploadModalComponent, {
  UploadModal
} from '../../../../../common/components/Upload/UploadModal';
import { isBroker } from '../../../../auth/redux/authActions';
import { required } from 'redux-form-validators';


const File = ({ index, onRemove, name }) => {
  const remove = () => {
    onRemove(index);
  };

  return (
    <li>
      <div className="upload-progress" style={{ width: '100%' }}/>
      <a onClick={remove}>
        <i className="fa fa-times"/>
      </a>
      <div className="content">
        <p className="pull-right">&nbsp;</p> {name}
      </div>
    </li>
  );
};

const CreateNoteForm = ({
  onSave,
  onSetUploadAccept,
  onOpenUploadModal,
  handleSubmit,
  showReminder,
  formValues,
  onRemove,
  submitting,
  files
}) => {

  const isReminderSet = formValues?.meta?.set_reminder;

  const handleUpload = () => {
    onSetUploadAccept('*');
    onOpenUploadModal(UploadModal.MODAL_IDENTIFIER);
  };


  return (
    <>
      <UploadModalComponent/>

      <Form onSubmit={handleSubmit(onSave)}>
        <div className="create-note-btn">
          <Row>
            <Col xs={12} componentClass={ControlLabel}>
              Add your notes
            </Col>
          </Row>
          <Field
            name={'data.attributes.content'}
            labelSize={3}
            type="textarea"
            component={TextField}
            validate={required()}
          />
          {showReminder && (
            <Row>
              <Col sm={5}>
                <Field
                  name={'meta.set_reminder'}
                  inline
                  options={[{ label: 'Set a reminder', value: true }]}
                  component={CheckboxGroup}
                />
              </Col>
              <Col sm={5}>
                {isReminderSet && (
                  <div className="row timepicker">
                    <Field
                      name={'data.attributes.reminder'}
                      label="Remind me on"
                      block
                      labelSize={5}
                      component={DatePicker}
                    />
                  </div>
                )}
              </Col>
            </Row>
          )}
          {!!files.length && (
            <div>
              <Row>
                <Col xs={12} componentClass={ControlLabel}>
                  Attachments:
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <ul className="upload-list">
                    {files.map((file, i) => {
                      return (
                        <File
                          index={i}
                          onRemove={onRemove}
                          name={file?.attributes?.name}
                        />

                      );
                    })}
                  </ul>
                </Col>
              </Row>
            </div>
          )}
        </div>
        <ModalFooter>
          <div>
            <Button
              type="submit"
              bsStyle="primary"
              className="pull-right"
              isLoading={submitting}
              label="Create Note"
            />
            <Button
              type="button"
              bsStyle="primary"
              className="pull-right pad-right"
              handleClick={handleUpload}
              disabled={submitting}
            >
              {submitting ? (
                <i className="fa fa-cog fa-spin"/>
              ) : (
                <i className="fa fa-upload"/>
              )}{' '}
              upload attachment
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </>
  );

};

const FORM_NAME = 'CreateNoteForm';
export default compose(
  connect(
    (state, ownProps,) => ({
      formValues: getFormValues(FORM_NAME)(state),
      initialValues: {
        data: {
          attributes: {
            forward_url: ownProps.currentLocation,
            reminder: '',
            share: !isBroker() ? 'organisation' : undefined
          }
        },
        meta: {
          set_reminder: '',

        }

      }
    }), {
      onRemoveFile: removeFile,
      onSetUploadAccept: setUploadAccept,
      onOpenUploadModal: openModal,
    }
  ),
  reduxForm({ form: FORM_NAME }),
)(CreateNoteForm);



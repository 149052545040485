import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Button, Modal } from '../../../../common/components';
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux';
import { printDocuments } from '../../redux/policyActions';
import { Checkbox } from '../../../../common/components/ReduxFormField';
import './styles.scss';

const FORM_NAME = 'emailDocumentsForm';

class PrintDocumentsButton extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    resourceType: PropTypes.string.isRequired,
    customer: PropTypes.object,
    callback: PropTypes.func,
    label: PropTypes.string,
  };

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded, callback } = this.props;
    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('');
      }
      dispatch(closeModal(FORM_NAME));
    }
  }

  handleSubmit(values) {
    const payload = {
      meta: {
        documents: values.selectedDocs.filter((value) => {
          return value !== null;
        }),
      },
    };

    if (payload.meta.documents.count === 0) {
      return;
    }

    return this.props.dispatch(
      printDocuments(payload, this.props.resource, this.props.resourceType),
    );
  }

  handleOpen() {
    const { dispatch, change, reset } = this.props;
    dispatch(reset());
    dispatch(openModal(FORM_NAME));
    this.getDocuments().map((document, documentIndex) => {
      dispatch(change('selectedDocs[' + documentIndex + ']', document.id));
    });
  }

  getDocuments() {
    const { resource } = this.props;
    return resource.included.filter((relation) => {
      return relation.type === 'documents' && relation.id;
    });
  }

  render() {
    const { label, handleSubmit, submitting } = this.props;
    const submitButton = (
      <Button type="submit" bsStyle="primary" isLoading={submitting} label="Download" />
    );

    return (
      <div>
        <Button
          className="pull-right"
          bsStyle="primary"
          label={label || 'Download Documents'}
          rightIcon="file"
          handleClick={::this.handleOpen}
        />

        <Modal
          name={FORM_NAME}
          title="Select Documents"
          close
          handleSubmit={handleSubmit(::this.handleSubmit)}
          footer={submitButton}
        >
          <div className="form-horizontal">
            <div className="col-xs-12">
              <div className="row document-selection-list">
                {this.getDocuments().map((relation, documentIndex) => {
                  return (
                    <div className="col-sm-12 document-list" key={documentIndex}>
                      <Field
                        label={relation.attributes.display_name}
                        checkboxValue={relation.id}
                        name={'selectedDocs[' + documentIndex + ']'}
                        component={Checkbox}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const form = reduxForm({ form: FORM_NAME })(PrintDocumentsButton);

export default form;

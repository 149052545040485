import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createBroker, updateBroker } from '../redux/brokerActions';
import BrokerForm from '../components/BrokerForm/BrokerForm';

export default class BrokerFormContainer extends Component {
  static propTypes = {
    resource: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    broker: PropTypes.object.isRequired,
  };

  handleSubmit(resource) {
    if (resource.data.id) {
      return this.props.dispatch(updateBroker(resource));
    }

    return this.props.dispatch(createBroker(resource));
  }

  render() {
    return (
      <BrokerForm
        {...this.props}
        onSubmit={::this.handleSubmit}
        submitting={this.props.broker.isSubmitting}
      />
    );
  }
}

import React, { Component } from 'react';
import MaskedInput from 'react-maskedinput';
import 'moment/locale/en-gb';
import './styles.scss';

class DatePicker extends Component {
  onChange(date) {
    if (this.props.input && this.props.input.onChange) {
      this.props.input.onChange(date);
    }
  }

  render() {
    const { meta } = this.props;

    return (
      <div>
        <MaskedInput
          name="card"
          mask="11/11/1111"
          placeholder="dd/mm/yyyy"
          className="form-control"
          size="10"
          {...this.props.input}
        />
        {meta && meta.touched && meta.error && (
          <div>
            <span className="input-error">{meta.error}</span>
          </div>
        )}
      </div>
    );
  }
}

export default DatePicker;

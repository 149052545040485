import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HeaderContainer from './Header';
import FooterContainer from './Footer';
import BodyContainer from './BodyContainer';
import '../../../styles/app.scss';
import '../../../styles/vendor.scss';

export default class AppContainer extends Component {
  static propTypes = {
    children: PropTypes.object,
    location: PropTypes.object,
  };

  render() {
    return (
      <div className="app-body background-fade">
        <HeaderContainer {...this.props} />
        <div className="wrap-internal-page">
          <div className="body-section">
            <BodyContainer {...this.props} />
          </div>
          <div className="footer-section">
            <FooterContainer {...this.props} />
          </div>
        </div>
      </div>
    );
  }
}

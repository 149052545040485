import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';
import MultiSelectMenu from '../SelectMenu/MultiSelectMenu';

class SelectOrganisations extends Component {
  static propTypes = {
    label: PropTypes.string,
    labelSize: PropTypes.number,
    infoButton: PropTypes.element,
    name: PropTypes.string,
    meta: PropTypes.object,
  };

  renderSelectMenu() {
    let source = 'organisations';

    return (
      <MultiSelectMenu
        source={source}
        sourceKey={'Multi'}
        isClearable={true}
        isObject
        {...this.props}
      />
    );
  }

  render() {
    const { infoButton, meta, label, labelSize, smFieldSize } = this.props;

    if (label) {
      const labelSmSize = labelSize || 2;
      const menuSize = smFieldSize || 12 - labelSmSize;

      return (
        <FormGroup className={meta && meta.touched && meta.error ? ' has-error' : ''}>
          {label && (
            <Col sm={labelSmSize}>
              <ControlLabel>
                {label} {infoButton}
              </ControlLabel>
            </Col>
          )}

          <Col sm={menuSize}>
            {this.renderSelectMenu()}
            {meta && meta.touched && meta.error && (
              <span className="input-error">{meta.error}</span>
            )}
          </Col>
        </FormGroup>
      );
    }

    return this.renderSelectMenu();
  }
}

export default connect()(SelectOrganisations);

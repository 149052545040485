import { compose } from 'redux';
import React from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import SchemeManagementSectionContainer
  from '../SchemeManagementSectionContainer/SchemeManagementSectionContainer';
import { Col, Form, Row } from 'react-bootstrap';
import { HorizontalFormControl, InfoLabel } from '../../../../common/components';

const SignpostThresholdForm = ({ handleSubmit, isViewOnly }) => {
  return (
    <SchemeManagementSectionContainer
      title={'Signposting'}
      subTitle={'Medical Signpost Threshold'}
      isViewOnly={isViewOnly}
    >
      <Form horizontal onSubmit={handleSubmit}>
        <Row>
          <Col sm={12}>
            <Field
              name="meta.patch.medical_signpost_threshold"
              labelSize={2}
              mdFieldSize={4}
              label={'Threshold:'}
              currency
              component={isViewOnly ? InfoLabel : HorizontalFormControl}
            />
          </Col>
        </Row>
      </Form>
    </SchemeManagementSectionContainer>
  );
};

export default compose(
  connect((state, props) => {
    return {
      formValues: getFormValues(props.formName)(state),
      destroyOnUnmount: false,
      enableReinitialize: true,

      form: props.formName,
    };
  }),
  reduxForm({}),
)(SignpostThresholdForm);

import React from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import { Row, Icon } from '../';
import { Field } from 'redux-form';

const Input = ({
                 input,
                 meta,
                 addOnBefore,
                 addOnAfter,
                 isCalc,
                 currency,
                 percent,
                 type,
                 placeholder,
                 as,
                 handleOnChange,
                 handleOnBlur,
                 lpignore,
                 showValidationError,
                 className,
                 disabled
               }) => {
  const onChange = (event) => {
    if (handleOnChange) {
      handleOnChange(event.target.value);
    }
    if (input) {
      input.onChange(event.target.value);
    }
  };
  const onBlur = (value) => {
    if (handleOnBlur){
      handleOnBlur(value);
    }
  };
  const currencyPrepend = (<Icon
    name={isCalc ? 'spinner' : 'gbp'}
  />);
  const percentAppend = (<Icon
    name={isCalc ? 'spinner' : 'percent'}
  />);
  const before = currency ? currencyPrepend : addOnBefore;
  const after = percent ? percentAppend : addOnAfter;
  const hasError = meta && meta.touched && meta.error;
  return (
    <React.Fragment>
      <InputGroup>
        {before && (
          <InputGroup.Addon>
            <div className={className}>
              {before}
            </div>
          </InputGroup.Addon>
        )}
        <FormControl
          {...input}
          as={as}
          type={type}
          placeholder={placeholder}
          onChange={(e) => onChange(e)}
          onBlur={onBlur}
          lpignore={lpignore}
          disabled={disabled}
        />
        {after && (
          <InputGroup.Addon>
            <div className={className}>
              {after}
            </div>
          </InputGroup.Addon>
        )}
      </InputGroup>
      {showValidationError && hasError && (
        <span className="input-error">{meta.error}</span>
      )}

    </React.Fragment>);
};
const InputField = ({
                      name,
                      type,
                      addOnBefore,
                      addOnAfter,
                      isCalc,
                      currency,
                      percent,
                      placeholder,
                      validate,
                      handleOnChange,
                      handleOnBlur,
                      showValidationError,
                      disabled,
                      className,
                      parse,
                      format
                    }) => (

  <Field
    name={name}
    component={Input}
    type={type}
    addOnBefore={addOnBefore}
    addOnAfter={addOnAfter}
    isCalc={isCalc}
    currency={currency}
    percent={percent}
    placeholder={placeholder}
    validate={validate}
    handleOnChange={handleOnChange}
    handleOnBlur={handleOnBlur}
    showValidationError={showValidationError}
    disabled={disabled}
    className={className}
    parse={parse}
    format={format}

  />
);


const InputRow = ({ name, type, addOnBefore, addOnAfter, isCalc, currency, percent, labelSize = 0, label, fieldSize, fieldOffset, placeholder, validate, handleOnChange, handleOnBlur, showValidationError, disabled, parse, format }) => (
  <Row
    labelSize={labelSize}
    label={label}
    fieldSize={fieldSize}
    fieldOffset={fieldOffset}
  >
    <InputField
      name={name}
      type={type}
      addOnBefore={addOnBefore}
      addOnAfter={addOnAfter}
      isCalc={isCalc}
      currency={currency}
      percent={percent}
      placeholder={placeholder}
      validate={validate}
      handleOnChange={handleOnChange}
      handleOnBlur={handleOnBlur}
      showValidationError={showValidationError}
      disabled={disabled}
      parse={parse}
      format={format}

    />
  </Row>
);

export {
  Input,
  InputField,
  InputRow,
};

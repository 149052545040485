import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from '../../../../common/components';
import { openModal } from '../../../../common/components/Modal/ModalRedux';
import displayCurrency from '../../../../helpers/displayCurrency';
import displayName from '../../../../helpers/displayName';
import { isOwner, isBroker } from '../../../auth/redux/authActions';
import { get, isArray } from 'lodash';
import './styles.scss';

class PremiumBreakdown extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    premium: PropTypes.object.isRequired,
    savedPremium: PropTypes.object,
    id: PropTypes.string,
    className: PropTypes.string,
    estimate: PropTypes.bool,
    block: PropTypes.bool,
    showIpt: PropTypes.bool,
    taxType: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      showCommissions: false,
      showCommissions2: false,
    };
  }

  brokerCommission(commissions) {
    let bc = {
      rate: 0,
      amount: 0,
      adjusted: false,
    };

    if (get(commissions, 'broker')) {
      bc.rate = get(commissions, 'broker.rate', 0) * 100;
      bc.amount = get(commissions, 'broker.amount');
    } else {
      if (isArray(commissions)) {
        commissions.forEach((commission) => {
          if (commission.commission_type === 'broker') {
            bc.rate = get(commission, 'effective_rate', commission.rate) * 100;
            bc.amount = commission.amount;
            bc.adjusted =
              get(commission, 'effective_rate', commission.rate) !== commission.rate;
          }
        });
      }
    }

    return bc;
  }

  render() {
    const { dispatch, premium, savedPremium, className } = this.props;

    if (!premium) {
      return;
    }

    const currency = savedPremium ? savedPremium.currency_code : get(premium, 'premium.currency', 'GBP');
    const premiumClassName = className ? className : 'premium';
    const modalName = this.props.id ? this.props.id : premium.scheme.id;

    let totalCommission = premium.rates.commission_rate
      ? premium.rates.commission_rate
      : 0;
    totalCommission = parseFloat(
      get(premium, 'rates.effective_commission_rate', totalCommission),
    ).toFixed(2);

    const commissions = (premium) => {
      let c = get(premium, 'commissions', []);
      if (Array.isArray(c)) {
        return c;
      }

      let d = [];
      Object.keys(c).map((key) => {
        d.push(c[key]);
      });
      return d;
    };

    // const commissions2 = (premium) => {
    //   let c = get(premium, 'secondary_commissions', []);
    //   if (Array.isArray(c)) {
    //     return c;
    //   }
    //
    //   let d = [];
    //   Object.keys(c).map((key) => {
    //     d.push(c[key]);
    //   });
    //   return d;
    // };

    let breakdown = get(premium, 'breakdown', {});
    if (
      savedPremium &&
      savedPremium.breakdown !== undefined &&
      savedPremium.breakdown !== null
    ) {
      breakdown = savedPremium.breakdown;
    }
    return (
      <div>
        <span
          className={
            premiumClassName +
            ' premium-breakdown-button' +
            (this.props.block ? ' block-premium' : '')
          }
          onClick={() => dispatch(openModal(modalName))}
        >
          {displayCurrency(
            savedPremium ? savedPremium.gross : get(premium, 'premium.gross', 0),
            currency,
          )}
        </span>
        {this.props.estimate && (
          <span className={'small' + (this.props.block ? ' block-premium' : '')}>
            * Estimated premium including IPT
          </span>
        )}
        {!this.props.estimate && this.props.showIpt && (
          <span className={'ipt-rate' + (this.props.block ? ' block-premium' : '')}>
            &nbsp;Including IPT at {premium.rates.tax_rate}&#37;
          </span>
        )}
        <Modal {...this.props} name={modalName} title="Premium Breakdown" close>
          {isOwner() && (
            <div className="breakdown">
              <div className="row">
                <div className="col-xs-12 premium-summary-container">
                  <h4>{premium.scheme.name}</h4>
                  <div className="row">
                    <div className="col-xs-7">Net</div>
                    <div className="col-xs-4 text-right">
                      {displayCurrency(
                        savedPremium ? savedPremium.net : get(premium, 'premium.net', 0),
                        currency,
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-7">Additional</div>
                    <div className="col-xs-4 text-right">
                      {displayCurrency(
                        savedPremium
                          ? savedPremium.additional
                          : premium.premium.additional,
                        currency,
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-7">Medical</div>
                    <div className="col-xs-4 text-right">
                      {displayCurrency(
                        savedPremium ? savedPremium.medical : get(premium, 'premium.medical', 0),
                        currency,
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-7">Discount</div>
                    <div className="col-xs-4 text-right">
                      {displayCurrency(
                        savedPremium ? savedPremium.discount : get(premium, 'premium.discount', 0),
                        currency,
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-7">Fees</div>
                    <div className="col-xs-4 text-right">
                      {displayCurrency(
                        savedPremium ? savedPremium.fees : get(premium, 'premium.fees', 0),
                        currency,
                      )}
                    </div>
                  </div>

                  {this.state.showCommissions && get(premium, 'commissions') && (
                    <div className="commission-breakdown">
                      {commissions(savedPremium ? savedPremium : premium).map(
                        (commission, index) => {
                          let type = commission.commission_type;

                          if (type === 'owner') {
                            type = 'PJ Hayman';
                          }

                          if (type === 'parent') {
                            type = 'CSP';
                          }

                          const orgCommission = get(commission, 'effective_rate')
                            ? get(commission, 'effective_rate')
                            : commission.rate;
                          return (
                            <div key={index} className="row">
                              <div className="col-xs-7 commission-type">
                                {type} Commission @ {(orgCommission * 100).toFixed(1)}
                                &#37;{' '}
                                {orgCommission !== commission.rate ? '(adjusted)' : ''}
                              </div>
                              <div
                                className={
                                  'col-xs-2 text-right' +
                                  (index === premium.commissions.length - 1
                                    ? ' commission-amount-last'
                                    : '')
                                }
                              >
                                {displayCurrency(commission.amount, currency)}
                              </div>
                            </div>
                          );
                        },
                      )}
                    </div>
                  )}

                  <div className="row">
                    <div className="col-xs-7">Commission @ {totalCommission}&#37;</div>
                    <div className="col-xs-4 text-right">
                      {displayCurrency(
                        savedPremium
                          ? savedPremium.commission
                          : premium.premium.commission,
                        currency,
                      )}
                    </div>
                    <div className="col-xs-1">
                      <i
                        className={
                          'expand-commissions fa fa-' +
                          (this.state.showCommissions ? 'minus-square' : 'plus-square')
                        }
                        onClick={() =>
                          this.setState({ showCommissions: !this.state.showCommissions })
                        }
                      />
                    </div>
                  </div>

                  {/* UNCOMMENT TO SHOW SECONDARY COMMISSION */}
                  {/*{this.state.showCommissions2 && get(premium, 'secondary_commissions') && (*/}
                  {/*<div className="commission-breakdown">*/}
                  {/*{ commissions2(savedPremium ? savedPremium : premium).map((commission2, index) => {*/}
                  {/*const type = commission2.commission_type === 'owner' ? 'PJ Hayman' : commission2.commission_type*/}
                  {/*return (*/}
                  {/*<div key={index} className="row">*/}
                  {/*<div className="col-xs-7 commission-type">*/}
                  {/*{type}*/}
                  {/*</div>*/}
                  {/*<div className={'col-xs-2 text-right' + (index === premium.secondary_commissions.length -1 ? ' commission-amount-last' : '')}>*/}
                  {/*{displayCurrency(commission2.amount, currency)}*/}
                  {/*</div>*/}
                  {/*</div>*/}
                  {/*)}*/}
                  {/*)}*/}
                  {/*</div>*/}
                  {/*)}*/}

                  {/*<div className="row">*/}
                  {/*<div className="col-xs-7">*/}
                  {/*Secondary Commission*/}
                  {/*</div>*/}
                  {/*<div className="col-xs-4 text-right">*/}
                  {/*{displayCurrency(savedPremium ? savedPremium.secondary_commission : premium.premium.secondary_commission, currency)}*/}
                  {/*</div>*/}
                  {/*<div className="col-xs-1">*/}
                  {/*<i className={'expand-commissions fa fa-' + (this.state.showCommissions2 ? 'minus-square' : 'plus-square')} onClick={()=> this.setState({ showCommissions2: !this.state.showCommissions2 })}/>*/}
                  {/*</div>*/}
                  {/*</div>*/}

                  <div className="row">
                    <div className="col-xs-7 sub-total">Principal</div>
                    <div className="col-xs-4 sub-total text-right">
                      {displayCurrency(
                        savedPremium ? savedPremium.principal : get(premium, 'premium.principal', 0),
                        currency,
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-7">IPT @ {get(premium, 'rates.tax_rate', 0)}&#37;</div>
                    <div className="col-xs-4 text-right">
                      {displayCurrency(
                        savedPremium ? savedPremium.tax : get(premium, 'premium.tax', 0),
                        currency,
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-7">Items Excluding Tax</div>
                    <div className="col-xs-4 text-right">
                      {displayCurrency(
                        savedPremium ? savedPremium.ex_tax : get(premium, 'premium.ex_tax', 0),
                        currency,
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-7 sub-total">Gross Premium</div>
                    <div className="col-xs-4 sub-total text-right">
                      {displayCurrency(
                        savedPremium ? savedPremium.gross : get(premium, 'premium.gross', 0),
                        currency,
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 calculation-container">
                  <div className="row">
                    <div className="col-xs-2">Formula</div>
                    <div className="col-xs-9">
                      {savedPremium ? savedPremium.formula : premium.formula}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-2">Calculation</div>
                    <div className="col-xs-9">
                      {savedPremium ? savedPremium.calculation : premium.calculation}
                    </div>
                  </div>

                  {premium.errors.length > 0 && (
                    <div className="row">
                      <div className="col-xs-2">Errors</div>
                      <div className="col-xs-9">
                        <ul className="option-list">
                          {premium.errors.map((error, key) => {
                            return <li key={'error' + key}>{error}</li>;
                          })}
                        </ul>
                      </div>
                    </div>
                  )}
                  {get(breakdown, 'categories') && (
                    <div className="row">
                      <div className="col-xs-2">Categories</div>
                      <div className="col-xs-9">
                        {breakdown.categories.map((category, key) => {
                          return (
                            <div key={'category' + key}>
                              <div className="col-xs-2 no-gutter">Calculation:</div>
                              <div className="col-xs-10">{category.calc}</div>
                              <div className="col-xs-2 no-gutter">Rates:</div>
                              <div className="col-xs-10">
                                {category.rates.map((rate, rateKey) => {
                                  return (
                                    <span key={'rate' + rateKey}>
                                      {rate}
                                      <br />
                                    </span>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  {breakdown &&
                    Object.keys(breakdown).map((key) => {
                      const keysToIgnore = [
                        'categories',
                        'rate_base',
                        'rate_screening',
                        'options',
                      ];
                      const breakdownItem = get(premium, 'breakdown', {})[key];
                      if (keysToIgnore.indexOf(key) === -1) {
                        return (
                          <div key={'breakdown-row ' + key} className="row">
                            <div className="col-xs-2">{displayName(key)}</div>
                            <div className="col-xs-9">
                              <ul className="option-list">
                                {breakdownItem &&
                                  Object.keys(breakdownItem).map((breakdownItemKey) => (
                                    <li key={'breakdown-item ' + breakdownItemKey}>
                                      {`${displayName(breakdownItemKey)} : ${
                                        breakdownItem[breakdownItemKey]
                                      }`}
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                        );
                      }
                    })}
                  {get(breakdown, 'rate_base') && (
                    <div className="row">
                      <div className="col-xs-2">Base Rate</div>
                      <div className="col-xs-9">
                        <ul className="option-list">
                          {breakdown.rate_base.map((rate, key) => {
                            return <li key={'baseRate' + key}>{rate}</li>;
                          })}
                        </ul>
                      </div>
                    </div>
                  )}
                  {get(breakdown, 'rate_screening') && (
                    <div className="row">
                      <div className="col-xs-2">Screening Rate</div>
                      <div className="col-xs-9">
                        <ul className="option-list">
                          {breakdown.rate_screening.map((rate, key) => {
                            return <li key={'screeningRate' + key}>{rate}</li>;
                          })}
                        </ul>
                      </div>
                    </div>
                  )}

                  {get(breakdown, 'options') && (
                    <div className="row">
                      <div className="col-xs-2">Options</div>
                      <div className="col-xs-9">
                        <ul className="option-list">
                          {breakdown.options.map((option, key) => {
                            return <li key={'option' + key}>{option}</li>;
                          })}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {isBroker() && (
            <div className="row">
              <div className="col-xs-12 premium-summary-container">
                <h4>{premium.scheme.name}</h4>
                <div className="row">
                  <div className="col-xs-7 sub-total">Principal</div>
                  <div className="col-xs-4 sub-total text-right">
                    {displayCurrency(get(premium, 'premium.principal', 0), currency)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-7">Including Medical</div>
                  <div className="col-xs-4 text-right">
                    {displayCurrency(get(premium, 'premium.medical', 0), currency)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-7">IPT @ {premium.rates.tax_rate}&#37;</div>
                  <div className="col-xs-4 text-right">
                    {displayCurrency(get(premium, 'premium.tax', 0), currency)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-7 sub-total">Gross Premium</div>
                  <div className="col-xs-4 sub-total text-right">
                    {displayCurrency(get(premium, 'premium.gross', 0), currency)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-7 sub-total">
                    Commission @ {this.brokerCommission(premium.commissions).rate}&#37;{' '}
                    {this.brokerCommission(premium.commissions).adjusted
                      ? '(adjusted)'
                      : ''}
                  </div>
                  <div className="col-xs-4 sub-total text-right">
                    {displayCurrency(
                      this.brokerCommission(premium.commissions).amount,
                      currency,
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal>
      </div>
    );
  }
}

export default connect()(PremiumBreakdown);

import React, { useMemo } from 'react';
import { Pie } from 'react-chartjs-2';
import './styles.scss';

const DEFAULT_STATS = {
  issued: 0,
  renewals: 0,
  unconverted: 0,
}
const getStats = (stats, range) => {

  if (range === 'Today') {
    return stats?.quotes?.day ?? DEFAULT_STATS;
  }
  if (range === 'This week') {
    return stats?.quotes?.week ?? DEFAULT_STATS;
  }
  if (range === 'This month') {
    return stats?.quotes?.month ?? DEFAULT_STATS;
  }
  if (range === 'Yesterday') {
    return stats?.quotes?.yesterday ?? DEFAULT_STATS;
  }
  return stats?.quotes?.day ?? DEFAULT_STATS


}
const PieChart = ({ range, stats }) => {
  const displayStats = useMemo(() => getStats(stats, range), [stats, range]);


  const chartData = {
    labels: ['Issued', 'Renewals', 'Unconverted'],
    datasets: [
      {
        backgroundColor: ['#243b79', '#41a5f5', '#6d6e70', '#f2f2f2'],
        hoverBackgroundColor: ['#243b79', '#41a5f9', '#949597', '#f8f8f8'],
        data: [
          displayStats.issued,
          displayStats.renewals,
          displayStats.unconverted,
        ],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    cutoutPercentage: -1,
    legend: {
      labels: {
        boxWidth: 10,
        fontSize: 10,
      },
    },
  };

  return (
    <div className="chart-wrapper pie">
      <Pie data={chartData} options={options}/>
    </div>
  );

}

export default PieChart;


import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '../../components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UploadArea from './UploadArea';
import './Upload.scss';
import axios from 'axios';
import { throttle } from 'lodash';
import { closeModal } from '../Modal/ModalRedux';
import { addFiles, removeFile, clearFiles } from './UploadRedux';

export class UploadModal extends Component {
  static MODAL_IDENTIFIER = 'upload-modal';
  static UPLOAD_NOT_READY = 'not-ready';
  static UPLOAD_READY = 'ready';
  static UPLOAD_IN_PROGRESS = 'in-progress';
  static UPLOAD_FINISHED = 'finished';

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
    maxFiles: PropTypes.number,
    note: PropTypes.string,
    accept: PropTypes.string,
  };

  static initialState = {
    files: [],
    errors: {},
    uploadState: 'not-ready',
  };

  timers = {};

  constructor(props) {
    super(props);
    this.state = UploadModal.initialState;

    this.handleDrop = this.handleDrop.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.startUpload = this.startUpload.bind(this);
    this.handleRemoveFile = this.handleRemoveFile.bind(this);
    this.handleOnHide = this.handleOnHide.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);
  }

  addFilesToState(files) {
    if (this.state.uploadState === UploadModal.UPLOAD_IN_PROGRESS) {
      return;
    }

    const filesToAdd = Array.from(files).filter((file) => {
      if (this.props.maxFiles && this.state.files.length >= this.props.maxFiles) {
        return false;
      }

      return !this.state.files.some((existingFile) => existingFile.name === file.name);
    });

    this.setState((prevState) => ({
      files: [...prevState.files, ...filesToAdd],
      uploadState: filesToAdd.length > 0 ? UploadModal.UPLOAD_READY : UploadModal.UPLOAD_NOT_READY,
    }));
  }

  handleDrop(event) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    this.addFilesToState(files);
  }

  handleDragOver(event) {
    event.preventDefault();
  }

  handleFileChange(event) {
    const files = event.target.files;
    this.addFilesToState(files);
  }

  handleRemoveFile(key) {
    return () => {
      const files = this.state.files.slice();
      files.splice(key, 1);
      this.setState({ files });
    };
  }

  startUpload() {
    if (this.state.uploadState === UploadModal.UPLOAD_IN_PROGRESS) {
      return;
    }

    this.setState({ uploadState: UploadModal.UPLOAD_IN_PROGRESS });
    const promise = [];
    const errors = [];

    this.state.files.forEach((file, key) => {
      const config = {
        onUploadProgress: throttle((progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          this.setState((prevState) => {
            const updatedFiles = [...prevState.files];
            updatedFiles[key].progress = percentCompleted;
            return { files: updatedFiles };
          });
        }, 200),
      };

      const data = new FormData();
      data.append('file', file);

      const request = axios.post('/files', data, config)
        .then((response) => {
          this.setState((prevState) => {
            const updatedFiles = [...prevState.files];
            updatedFiles[key].progress = 100;
            return { files: updatedFiles };
          });
          this.props.dispatch(addFiles([response.data.data]));
        })
        .catch((error) => {
          errors[file.name] = error.response.status;
          this.setState((prevState) => {
            const updatedFiles = [...prevState.files];
            updatedFiles[key].progress = 0;
            return { files: updatedFiles, errors };
          });
          throw error;
        });

      promise.push(request);
    });

    Promise.all(promise)
      .then(() => {
        this.setState({ uploadState: UploadModal.UPLOAD_FINISHED });
        this.props.dispatch(closeModal(this.props.modalIdentifier));
        this.handleOnHide();
      })
      .catch(() => {
        this.setState({ uploadState: UploadModal.UPLOAD_FINISHED, errors });
      });
  }

  handleOnHide() {
    this.setState(UploadModal.initialState);
    if (this.timers.upload) {
      this.timers.upload();
    }
    this.props.onComplete();
  }

  renderUploadButton() {
    const label =
      this.state.uploadState === UploadModal.UPLOAD_IN_PROGRESS
        ? 'Uploading...'
        : 'Start Upload';
    const isDisabled = this.state.uploadState === UploadModal.UPLOAD_NOT_READY;

    return (
      <button
        type="button"
        className="btn btn-primary pull-right"
        disabled={isDisabled}
        onClick={this.startUpload}
      >
        {label}
      </button>
    );
  }

  render() {
    const { files, uploadState } = this.state;
    const { accept, note, modalIdentifier } = this.props;

    return (
      <Modal
        name={modalIdentifier}
        title="Upload Files"
        close
        footer={this.renderUploadButton()}
        onHide={this.handleOnHide}
      >
        <ul className="upload-list" style={files.length > 0 ? {} : { display: 'none' }}>
          {files.map((file, key) => (
            <li key={key}>
              <div className="upload-progress" style={{ width: `${file.progress || 0}%` }} />
              <a onClick={this.handleRemoveFile(key)} disabled={uploadState === UploadModal.UPLOAD_IN_PROGRESS}>
                <i className="fa fa-times" />
              </a>
              <div className="content">
                <p className="pull-right">{file.progress || 0}%</p> {file.name}
              </div>
            </li>
          ))}
        </ul>

        <UploadArea
          onDrop={this.handleDrop}
          onDragOver={this.handleDragOver}
          onClick={() => document.getElementById('fileInput').click()}
          isUploading={uploadState === UploadModal.UPLOAD_IN_PROGRESS}
          accept={accept}
        />

        <input
          type="file"
          id="fileInput"
          style={{ display: 'none' }}
          onChange={this.handleFileChange}
          accept={accept}
          multiple
        />

        {note && <p className="small text-center">{note}</p>}
      </Modal>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    upload: state.upload,
    modalIdentifier: props.modalIdentifier || UploadModal.MODAL_IDENTIFIER,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ addFiles, clearFiles, removeFile }, dispatch),
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadModal);

import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { HorizontalFormControl, InfoLabel } from '../../../../common/components';
import SchemeManagementSectionContainer
  from '../SchemeManagementSectionContainer/SchemeManagementSectionContainer';


const CommissionForm = ({ handleSubmit, isViewOnly, formValues }) => {
  if (!formValues) {
    return null;
  }
  return (
    <SchemeManagementSectionContainer
      title={'Commission'}
      subTitle={formValues.data.attributes.name}
      isViewOnly={isViewOnly}
    >
      <Form horizontal onSubmit={handleSubmit}>
        <Row>
          <Col sm={12}>
            <Field
              name={'meta.patch.commission_rate'}
              labelSize={4}
              mdFieldSize={2}
              addonAfter={'%'}
              label={'Commission Rate:'}
              component={isViewOnly ? InfoLabel : HorizontalFormControl}
            />
          </Col>
        </Row>
      </Form>
    </SchemeManagementSectionContainer>
  );
};

export default compose(
  connect((state, props) => {
    return {
      formValues: getFormValues(props.formName)(state),
      destroyOnUnmount: false,
      enableReinitialize: true,

      form: props.formName,
    };
  }),
  reduxForm({}),
)(CommissionForm);

import React from 'react';
import PropTypes from 'prop-types';

const CurrencyFormat = ({ data, defaultValue }) => {
  const currencyString = '\u00A3' + data;
  return (
    <span>
      {data
        ? currencyString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        : defaultValue
        ? defaultValue
        : ''}
    </span>
  );
};

CurrencyFormat.propTypes = {
  data: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default CurrencyFormat;

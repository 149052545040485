import React, { Component } from 'react';
import { get, has, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import displayCurrency from '../../../../helpers/displayCurrency';
import { HorizontalFormControl } from '../../../../common/components';
import { Row, Col, ControlLabel } from 'react-bootstrap';
import { getMTAPremiumOverrideCalculation, clearPremiumOverrideCalculation } from '../../redux/productActions';
import PremiumBreakdown from '../PremiumBreakdown';
import './styles.scss';

class AdjustmentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      override: 0,
    };
  }

  calculateAdjustment(e, oldGross) {
    console.log('Calculating MTA adjustments...');
    const { dispatch, change, policyId, formName } = this.props;
    const override = e.target.value;

    let overridePremium = 0;

    if (Math.abs(parseFloat(override)) > 0) {
      overridePremium = parseFloat(override);
    }

    if (overridePremium === this.state.override) {
      return;
    }

    this.setState({ override: override });

    dispatch(change(
      'data.attributes.metadata.mta_overrides.gross_premium',
      overridePremium === 0 ? null : oldGross + overridePremium,
    ));

    if (Math.abs(overridePremium) > 0) {
      Promise.resolve(
        dispatch(getMTAPremiumOverrideCalculation(policyId, formName)),
      ).then(() => {
        this.props.reCalculateForAdjustments();
      });
    } else {
      dispatch(clearPremiumOverrideCalculation());
    }
  }

  render() {
    const { selectedSchemes, product, currentProduct } = this.props;
    const { override } = this.state;

    let selectedScheme = [];
    if (selectedSchemes.length === 1) {
      selectedScheme = product.quotes[selectedSchemes[0].id || selectedSchemes[0].data.id];
    }

    const taxType = currentProduct.data.attributes.metadata.tax_type;

    if (!has(selectedScheme, 'meta')) {
      return <div/>;
    }

    let premium = get(selectedScheme, 'meta');
    if (!isEmpty(product.premiumOverrideCalculation)) {
      premium = product.premiumOverrideCalculation.meta;
    }

    return (
      <Row className="adjustment-form">
        <Col lgPush={3} lg={9} md={12} className="scheme-for-purchase">
          <Row>
            <Col xs={4}>
              <h4>Original Premium</h4>
              {product.isPremiumAdjusting ? (
                <span className="loader-dots">
                  <i className="fa fa-circle loader-dot"/>
                  <i className="fa fa-circle loader-dot"/>
                  <i className="fa fa-circle loader-dot"/>
                </span>
              ) : (
                <span className="premium block-premium">
                  {displayCurrency(
                    get(premium, 'mta.old_premium.gross', 0),
                    get(premium, 'premium.currency', 'GBP'),
                  )}
                </span>
              )}
            </Col>
            <Col xs={4}>
              <h4>New Premium</h4>
              {product.isPremiumAdjusting ? (
                <span className="loader-dots">
                  <i className="fa fa-circle loader-dot"/>
                  <i className="fa fa-circle loader-dot"/>
                  <i className="fa fa-circle loader-dot"/>
                </span>
              ) : (
                <span className="premium block-premium">
                  {displayCurrency(
                    get(premium, 'mta.new_premium.gross', 0),
                    get(premium, 'premium.currency', 'GBP'),
                  )}
                </span>
              )}
            </Col>
            <Col xs={4}>
              <h4>Total amount due</h4>
              {product.isPremiumAdjusting ? (
                <span className="loader-dots">
                  <i className="fa fa-circle loader-dot"/>
                  <i className="fa fa-circle loader-dot"/>
                  <i className="fa fa-circle loader-dot"/>
                </span>
              ) : (
                <PremiumBreakdown
                  premium={premium}
                  block
                  showIpt
                  taxType={taxType}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <p className="scheme-name">
                {get(selectedScheme, 'meta.scheme.name')}
              </p>
            </Col>
            <Col sm={12} componentClass={ControlLabel} className="adj-label">
              Override (total due)
            </Col>
            <Col sm={12} lgPush={7} lg={5} mdPush={5} md={7}>
              <HorizontalFormControl
                currency
                input={{
                  onChange: e => this.calculateAdjustment(e, premium.mta.old_premium.gross),
                  value: override,
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default connect()(AdjustmentForm);

import React from 'react';
import DeclineInfoField from './DeclineInfoField';
import { Field } from 'redux-form';
import { Row } from 'react-bootstrap';
import NewDeclineModal from './NewDeclineModal';

const DeclineInfoFieldArray = ({
  fields,
  isViewOnly,
  regions,
  options,
  medicalQuestionKeys,
  coverLevels,
  productType,
}) => {
  const handleRemoveDecline = (index) => {
    fields.remove(index);
  };

  const handleAddDecline = (values) => {
    fields.push(values);
  };
  return (
    <>
      {fields.map((field, index) => (
        <Row>
          <Field
            name={field}
            component={DeclineInfoField}
            isViewOnly={isViewOnly}
            index={index}
            onRemove={handleRemoveDecline}
            regions={regions}
            options={options}
            medicalQuestionKeys={medicalQuestionKeys}
            coverLevels={coverLevels}
            productType={productType}
          />
        </Row>
      ))}

      <NewDeclineModal
        regions={regions}
        options={options}
        coverLevels={coverLevels}
        medicalQuestionKeys={medicalQuestionKeys}
        onAddDecline={handleAddDecline}
        productType={productType}
      />
    </>
  );
};
export default DeclineInfoFieldArray;

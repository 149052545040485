import { ButtonGroup, Col, ControlLabel, Row } from 'react-bootstrap';
import {
  Button,
  DatePickerDob,
  HorizontalFormControl,
  SelectAccount,
  SelectBroker
} from '../../../../common/components';
import React from 'react';

const DateFilter = ({ name, onChange, value, minDate }) => {
  const handleChange = (v) => {
    onChange(name, v);
  };
  return (
    <DatePickerDob
      minDate={minDate}
      input={{
        value,
        onChange: handleChange,
      }}
    />

  );
};
const AmountFilter = ({ name, onChange, value, }) => {
  const handleChange = (v) => {
    onChange(name, v.currentTarget.value);
  };
  return (
    <HorizontalFormControl
      input={{
        value,
        onChange: handleChange,
      }}
      currency
    />

  );
};

const PremiumTypeButton = ({ isSelected, label, premiumType, onSetPremiumType }) => {
  const onClick = () => {
    onSetPremiumType(premiumType);
  };

  return (
    <Button
      bsStyle={isSelected ? 'default' : 'primary'}
      handleClick={onClick}
      label
    >
      {label}
    </Button>
  );
};
const Filters = ({
  onSetAccount,
  accountId,
  onSetBroker,
  brokerId,
  onSetPremiumType,
  premiumType,
  onSetTransactionFilter,
  transactionFilters,
  onSetPremiumFilter,
  premiumFilters
}) => {
  console.log(accountId);
  return (
    <>
      <Row className="pre-matching-form">
        <Col md={3}>
          <ControlLabel className="filter-label">Account</ControlLabel>
          <SelectAccount
            async
            key={'select-account'}
            sort={'name'}
            onChange={onSetAccount}
            input={{ value: accountId }}
            optionValuePath={'id'}
          />
        </Col>
        <Col md={3}>
          <ControlLabel className="filter-label">Broker</ControlLabel>
          <SelectBroker
            async
            key={'select-broker' + accountId}
            filters={accountId ? [{ type: 'account', match: accountId }] : undefined }
            onChange={onSetBroker}
            input={{ value: brokerId }}
            isClearable
          />
        </Col>
        <Col md={3} />
        <Col md={3}>
          <ControlLabel>Premium Type</ControlLabel>
          <br/>
          <ButtonGroup className="net-gross-filter" bsSize="md">
            <PremiumTypeButton
              isSelected={premiumType === 'net-premiums'}

              premiumType={'net-premiums'}
              onSetPremiumType={onSetPremiumType}
              label="Netted"
            />
            <PremiumTypeButton
              isSelected={premiumType === 'premiums'}
              premiumType={'premiums'}
              onSetPremiumType={onSetPremiumType}
              label="Split"
              />
          </ButtonGroup>
        </Col>
      </Row>

      <Row className="pre-matching-form">
        <Col sm={6}>
          <Col md={4}>
            <h4>Receipt Filters:</h4>
          </Col>
          <Col md={4}>
            <ControlLabel>Date From</ControlLabel>
            <DateFilter
              name={'date:from'}
              onChange={onSetTransactionFilter}
              value={transactionFilters?.['date:from']}
            />

            <ControlLabel>Date To</ControlLabel>

            <DateFilter
              name={'date:to'}
              onChange={onSetTransactionFilter}
              value={transactionFilters?.['date:to']}
              minDate={transactionFilters?.['date:from']}
            />
          </Col>
          <Col md={4}>
            <ControlLabel>Amount From</ControlLabel>
            <AmountFilter
              name={'amount:from'}
              onChange={onSetTransactionFilter}
              value={transactionFilters?.['amount:from']}
            />

            <ControlLabel>Amount To</ControlLabel>

            <AmountFilter
              name={'amount:to'}
              onChange={onSetTransactionFilter}
              value={transactionFilters?.['amount:to']}
            />
          </Col>
        </Col>
        <Col sm={6}>
          <Col md={4}>
            <h4>Premium Filters:</h4>
          </Col>
          <Col md={4}>
            <ControlLabel>Date From</ControlLabel>
            <DateFilter
              name={'date:from'}
              onChange={onSetPremiumFilter}
              value={premiumFilters?.['date:from']}

            />
            <ControlLabel>Date To</ControlLabel>
            <DateFilter
              name={'date:to'}
              onChange={onSetPremiumFilter}
              value={premiumFilters?.['date:to']}
              minDate={premiumFilters?.['date:from']}
            />

          </Col>
          <Col md={4}>
            <ControlLabel>Amount From</ControlLabel>
            <AmountFilter
              name={'amount:from'}
              onChange={onSetPremiumFilter}
              value={premiumFilters?.['amount:from']}
            />

            <ControlLabel>Amount To</ControlLabel>
            <AmountFilter
              name={'amount:to'}
              onChange={onSetPremiumFilter}
              value={premiumFilters?.['amount:to']}
            />

          </Col>
        </Col>
      </Row>
    </>
  );
};
export default Filters;

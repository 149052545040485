import React from 'react';
import { Box, Button, HorizontalFormControl } from '../../../common/components';
import { Form, reduxForm, Field } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import { flow } from 'lodash';
import { connect } from 'react-redux';

class TrackingLinksNewForm extends React.Component {
  componentWillMount() {
    const { dispatch, change } = this.props;

    dispatch(change('meta.organisation', 'owner'));
    dispatch(change('data.attributes.broker_type', 'tracking'));
  }

  render() {
    const { handleSubmit, trackingLinks } = this.props;

    return (
      <Box>
        <h4 className="title-campaign">New Tracking Link</h4>
        <hr id="first-row" />
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col sm={6}>
              <Row>
                <Field
                  name="data.attributes.trading_name"
                  mdFieldSize={8}
                  component={HorizontalFormControl}
                  labelSize={4}
                  label="Name"
                />
              </Row>
            </Col>
            <Col sm={6}>
              <Row>
                <Field
                  name="data.attributes.metadata.display_phone_number"
                  mdFieldSize={8}
                  component={HorizontalFormControl}
                  labelSize={4}
                  label="Display Phone"
                />
              </Row>
            </Col>
            <Row className="actions">
              <div className="clearfix">
                <Button
                  size="large"
                  type="submit"
                  className="pull-right pad-right"
                  label="Create Tracking Link"
                  bsStyle="primary"
                  disabled={trackingLinks.isSubmitting}
                />
              </div>
            </Row>
          </Row>
        </Form>
      </Box>
    );
  }
}

export default flow(
  reduxForm({
    form: 'TrackingLinksNewForm',
  }),
  connect((state) => ({
    trackingLinks: state.trackingLinks,
  })),
)(TrackingLinksNewForm);

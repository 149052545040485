import React from 'react';

import FormGroupRow from '../../../../../../common/components/FormGroupRow/FormGroupRow';
import { Field } from 'redux-form';
import SelectField from '../../../../../../common/components/ReduxFormField/SelectField';
import { InfoLabel } from '../../../../../../common/components';

export const ViewAutoInclude = ({ input }) => {
  if (Array.isArray(input?.value)) {
    return (
      <InfoLabel
        label={'Auto Include On: ' + input.value?.join(', ')}
        value={''}
        labelSize={12}
      />
    );
  }
  return (
    <InfoLabel
      label={`Auto Include: ${input.value ? 'Yes' : 'No'}`}
      value={''}
      labelSize={12}
    />
  );
};

export const AutoIncludeField = ({ label, input, coverLevels, labelSize, fieldSize }) => {

  const coverLevelOptions = coverLevels?.map((coverLevel) => ({
    label: coverLevel,
    value: coverLevel,
  }));
  return (
    <FormGroupRow
      label={label}
      labelSize={labelSize}
      fieldSize={fieldSize}
    >
      <Field
        name={`${input.name}`}
        component={SelectField}
        isMulti
        options={coverLevelOptions}
      />
    </FormGroupRow>
  );

};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clearUser } from '../redux/userActions';
import { Button } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import UserFormContainer from './UserFormContainer';
import userSchema from '../../../schemas/user';
import { get, set } from 'lodash';

class UserCreateContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
  };

  componentWillMount() {
    this.props.dispatch(clearUser());
  }

  render() {
    const contact = get(this.props, 'location.query.contact', false) == true;
    const buttons = (
      <Button bsStyle="default" label="Back" isBackButton leftIcon="caret-left" />
    );

    set(userSchema, 'data.relationships.organisations.data', [
      { id: this.props.params.organisationId },
    ]);
    if (this.props.params.brokerId) {
      set(userSchema, 'data.relationships.current_broker.data', {
        id: this.props.params.brokerId,
      });
      set(userSchema, 'data.relationships.brokers.data', [
        { id: this.props.params.brokerId },
      ]);
    }

    return (
      <InternalContainer title={contact ? 'Contact' : 'User'} buttons={buttons}>
        <UserFormContainer
          {...this.props}
          resource={userSchema}
          isInOwnerOrganisation={get(this.props, 'location.query.type', '') == 'Owner'}
          contact={contact}
        />
      </InternalContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(UserCreateContainer);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InternalContainer from '../../layout/containers/Internal';
import RenewalScrutinyList from '../components/RenewalScrutinyList/RenewalScrutinyList';

class RenewalScrutinyListContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
  }

  render() {

    return (
      <InternalContainer title="Renewals Scrutiny">
        <RenewalScrutinyList {...this.props}/>
      </InternalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProps)(RenewalScrutinyListContainer);

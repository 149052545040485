import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import InternalContainer from '../../layout/containers/Internal';
import { hasPermission } from '../../auth/redux/authActions';
import * as Icons from '../../../common/components/SvgIcons';

export class AccountListContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  render() {
    return (
      <InternalContainer title="Accounts">
        <div className="row image-links">
          {hasPermission('account.view') && (
            <div className="col-xs-6 col-sm-3 image-item">
              <Link to="/accounts/summary">
                {Icons.accountSummary}
                <label className="white">Accounts Summary</label>
              </Link>
            </div>
          )}
          {hasPermission('account.view') && (
            <div className="col-xs-6 col-sm-3 image-item">
              <Link to="/accounts/matching">
                {Icons.matching}
                <label className="white">Premium Matching</label>
              </Link>
            </div>
          )}
          {hasPermission('account.view') && (
            <div className="col-xs-6 col-sm-3 image-item">
              <Link to="/accounts/ledger">
                {Icons.ledger}
                <label className="white">Accounts Ledger</label>
              </Link>
            </div>
          )}
          {hasPermission('account.view') && (
            <div className="col-xs-6 col-sm-3 image-item">
              <Link to="/accounts/bookkeeping">
                {Icons.books}
                <label className="white">Bookkeeping</label>
              </Link>
            </div>
          )}
        </div>
      </InternalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(AccountListContainer);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { Button, Box } from '../../../../common/components';
import Personnel from './Personnel';
import { updateKeyPersonnel } from '../../redux/contentActions';
import { get } from 'lodash';
import './styles.scss';

const FORM_NAME = 'keyPersonnelForm';

class KeyPersonnelForm extends Component {
  static propTypes = {
    content: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired,
  };

  saveKeyPersonnel(resource) {
    this.props.dispatch(updateKeyPersonnel(resource));
  }

  render() {
    const { formValues, handleSubmit } = this.props;

    return (
      <Box className="no-top-padding">
        <form onSubmit={handleSubmit(::this.saveKeyPersonnel)}>
          <h2 className="resource-name">Key Personnel</h2>

          <div className="row">
            <div className="col-xs-12">
              <FieldArray
                name="data.key_personnel.data"
                items={get(formValues, 'data.key_personnel.data', [])}
                component={Personnel}
              />
            </div>
          </div>

          <div className="row actions">
            <div className="col-xs-12">
              <Button
                type="submit"
                label="Save Changes"
                bsStyle="primary"
                className="pull-right"
                isLoading={this.props.content.isUpdatingKeyPersonnel}
              />
            </div>
          </div>
        </form>
      </Box>
    );
  }
}

const form = reduxForm({ form: FORM_NAME })(KeyPersonnelForm);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  const values = selector(state, 'data', 'data.key_personnel');

  return {
    initialValues: {
      data: {
        key_personnel: {
          data: props.content.keyPersonnel,
        },
      },
    },
    formValues: values,
  };
};
export default connect(mapStateToProps)(form);

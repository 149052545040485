import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InternalContainer from '../../layout/containers/Internal';
import AccountSummary from '../components/AccountSummary';
import AccountErrors from '../components/AccountErrors';
import { getSummaries } from '../redux/accountActions';
import Loader from '../../../common/components/Loader/Loader';
import { keys } from 'lodash';

class AccountLedgerContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  componentWillMount() {
    this.props.dispatch(getSummaries());
  }

  render() {
    const { dispatch, account: { summaries: { ledger_summary, errors } } } = this.props;

    if (ledger_summary) {
      const currencies = [];
      const accountTypes = [];

      keys(ledger_summary).map((accountType) => {
        if (!accountTypes.includes(accountType)) {
          accountTypes.push(accountType);
        }
        keys(ledger_summary[accountType]).map((currency) => {
          if (!currencies.includes(currency)) {
            currencies.push(currency);
          }
        });
      });

      return (
        <InternalContainer title="Accounts Summary">
          <AccountSummary
            dispatch={dispatch}
            summaries={ledger_summary}
            currencies={currencies}
            accountTypes={accountTypes}
          />
          <AccountErrors
            dispatch={dispatch}
            errors={errors}
          />
        </InternalContainer>
      );
    }

    return (
      <InternalContainer title="Accounts Summary">
        <Loader/>
      </InternalContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    account: state.account,
  };
}

export default connect(mapStateToProps)(AccountLedgerContainer);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, ButtonBar, Icon } from '../../../../common/components';
import { Row, Col, InputGroup, FormControl, FormGroup } from 'react-bootstrap';
import { Form, Field, reduxForm } from 'redux-form';
import '../../../brochure/brochure.scss';
import './LoginForm.scss';

const InputGroupAddon = InputGroup.Addon;

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Required';
  }

  if (!values.password) {
    errors.password = 'Required';
  }

  return errors;
};

class LoginForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
  };

  renderEmailField(field) {
    const { input } = field;
    return (
      <FormGroup>
        <Col sm={12}>
          <InputGroup>
            <InputGroupAddon>
              <Icon name="envelope" />
            </InputGroupAddon>
            <FormControl {...input} type="email" placeholder="Email address" />
          </InputGroup>
        </Col>
      </FormGroup>
    );
  }

  renderPasswordField(field) {
    const { input } = field;
    return (
      <FormGroup>
        <Col sm={12}>
          <InputGroup>
            <InputGroupAddon>
              <Icon name="lock" />
            </InputGroupAddon>
            <FormControl {...input} type="password" placeholder="Password" />
          </InputGroup>
        </Col>
      </FormGroup>
    );
  }

  render() {
    const { handleSubmit, submitting, error } = this.props;

    return (
      <div className="login-box">
        <div className="head">
          <h2>Agent Login</h2>
        </div>
        <Box className="container-fluid">
          <Form onSubmit={handleSubmit} className="login-form" method="post">
            <Field id="email" name="email" component={this.renderEmailField} />
            <Field id="password" name="password" component={this.renderPasswordField} />
            <Row>
              <Col sm={12}>
                <ButtonBar>
                  <Button
                    id="login-button"
                    type="submit"
                    className="pull-right"
                    bsStyle="primary"
                    disabled={submitting}
                  >
                    {submitting ? (
                      <i className="fa fa-cog fa-spin" />
                    ) : (
                      <i className="fa fa-sign-in" />
                    )}{' '}
                    login
                  </Button>
                  <Button
                    link
                    className="pull-right"
                    bsStyle="default"
                    label="forgotten password?"
                    to="/password/remind"
                  />
                </ButtonBar>
              </Col>
            </Row>
          </Form>
        </Box>
        {error && (
          <Row>
            <Col sm={12}>
              <div className="invalid-error">
                <strong>{error}</strong>
              </div>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default reduxForm({
  form: 'login',
  validate,
})(LoginForm);

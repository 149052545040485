import React, { Component } from 'react';
import { connect } from 'react-redux';
import InternalContainer from '../../layout/containers/Internal';
import AccountJournalList from '../components/AccountJournalList';
import AccountPaymentList from '../components/AccountPaymentList';
import AccountPeriodList from '../components/AccountPeriodList';
import AccountBatchList from '../components/AccountBatchList';
import AccountPremiumList from '../components/AccountPremiumList/';
import InternalTabs from '../../layout/components/InternalTabs/InternalTabs';

const TAB_NAME = 'bookkeepingTabs';

class AccountBookkeepingContainer extends Component {
  render() {
    return (
      <InternalContainer title="Bookkeeping">
        <InternalTabs
          id={TAB_NAME}
          tabs={[
            {
              title: 'Payments & Receipts',
              content: <AccountPaymentList {...this.props} />,
            },
            {
              title: 'Premiums',
              content: <AccountPremiumList {...this.props} />,
            },
            {
              title: 'Journals',
              content: <AccountJournalList {...this.props} />,
            },
            {
              title: 'Batches',
              content: <AccountBatchList {...this.props} />,
            },
            {
              title: 'Accounting Periods',
              content: <AccountPeriodList {...this.props} />,
            },
          ]}
        />
      </InternalContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    account: state.account,
  };
}

export default connect(mapStateToProps)(AccountBookkeepingContainer);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'moment/locale/en-gb';
import MaskedTextInput from 'react-text-mask';

// TODO: INTL SUPPORT
const DATE_FORMAT = 'dd/MM/yyyy';

export default class SimpleDatePicker extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  getMoment(string) {
    moment.locale('en-gb');
    return string && moment(string, [moment.ISO_8601, DATE_FORMAT]).toDate();
  }

  onChange(date) {
    if (date) {
      const changedDate = moment(date).format();
      this.props.onChange(changedDate && changedDate);
    }
  }

  render() {
    const { id, monthsShown, dateFormat, shouldCloseOnSelect, showTime } = this.props;
    return (
      <DatePicker
        id={id}
        disabled={this.props.dobReadOnly}
        monthsShown={monthsShown ? monthsShown : 2}
        className="form-control"
        placeholderText={DATE_FORMAT.toLowerCase()}
        dateFormat={dateFormat ? 'dd/MM/yyyy HH:mm' : DATE_FORMAT}
        showYearDropdown={this.props.showYear}
        showMonthDropdown={this.props.showYear}
        dropdownMode="select"
        selected={this.getMoment(this.props.selected)}
        startDate={this.getMoment(this.props.startDate)}
        endDate={this.getMoment(this.props.endDate)}
        minDate={this.getMoment(this.props.minDate)}
        maxDate={this.getMoment(this.props.maxDate)}
        onSelect={this.props.onSelect}
        onChange={this.onChange}
        showTimeSelect={showTime}
        timeFormat="HH:mm"
        shouldCloseOnSelect={shouldCloseOnSelect}
        customInput={
          <MaskedTextInput
            autoComplete={'off'}
            type="text"
            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
          />
        }
      />
    );
  }
}

SimpleDatePicker.propTypes = {
  selected: PropTypes.string,
  startDate: PropTypes.string,
  id: PropTypes.string,
  endDate: PropTypes.string,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  maxDate: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  showYear: PropTypes.bool,
  showTime: PropTypes.bool,
};

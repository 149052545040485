import React, { Component } from 'react';
import 'moment/locale/en-gb';
import { connect } from 'react-redux';
import { Tab, Tabs, Form } from 'react-bootstrap';
import { formValueSelector, reduxForm } from 'redux-form';
import InternalContainer from '../../../layout/containers/Internal';
import { Button, ButtonBar, Loader } from '../../../../common/components';
import { get, set, find, isEmpty } from 'lodash';
import {
  saveAndApproveBrokerApplication,
  createBrokerApplication,
  updateBrokerApplication,
  declineBrokerApplication,
  resendBrokerApplication,
  getProducts,
} from '../../redux/brokerApplicationActions';
import { getBrokerApplicationQuestions } from '../../../brochure/redux/brochureActions';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import General from './components/tabsContent/General';
import Company from './components/tabsContent/Company';
import Management from './components/tabsContent/Management';
import Regulation from './components/tabsContent/Regulation';
import Products from './components/tabsContent/Products';
import Users from './components/tabsContent/Users';
import Declaration from './components/tabsContent/Declaration';
import Notes from './components/tabsContent/Notes';
import './styles.scss';

const FORM_NAME = 'BrokerApplication';

class BrokersApNewForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      activeTab: '1',
    };
    this.handleApprove = this.handleApprove.bind(this);
    this.handleDecline = this.handleDecline.bind(this);
    this.handleResendApproval = this.handleResendApproval.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getBrokerApplicationQuestions());
    dispatch(getProducts());
  }

  handleTab(key) {
    this.setState({ activeTab: key });
  }

  goNextTab() {
    this.setState((prevState) => ({
      activeTab: '' + (parseInt(prevState.activeTab) + 1),
    }));
  }

  goPreviousTab() {
    this.setState((prevState) => ({
      activeTab: '' + (parseInt(prevState.activeTab) - 1),
    }));
  }

  processApplication(formValues) {
    let commissions = get(formValues, 'data.attributes.broker_commission', []);
    if (isEmpty(commissions)) {
      return formValues;
    }

    commissions.forEach((commission, key) => {
      if (!get(commission, 'enabled', false) === true) {
        commissions[key]['enabled'] = false;
      }
    });

    set(formValues, 'data.attributes.broker_commission', commissions);
    return formValues;
  }

  handleSave() {
    const { formValues, dispatch } = this.props;
    if (!get(formValues, 'data.id')) {
      dispatch(createBrokerApplication(this.processApplication(formValues)));
      return;
    }

    dispatch(updateBrokerApplication(this.processApplication(formValues)));
  }

  handleApprove() {
    const { formValues, dispatch } = this.props;
    dispatch(saveAndApproveBrokerApplication(this.processApplication(formValues)));
  }

  handleDecline() {
    const { formValues, dispatch } = this.props;
    dispatch(declineBrokerApplication(formValues));
  }

  handleResendApproval() {
    const { formValues, dispatch } = this.props;
    dispatch(resendBrokerApplication(formValues));
  }

  renderActionButtons() {
    const { brokerApplication, formValues } = this.props;
    const getStatus = get(formValues, 'data.attributes.status');
    const hasId = get(formValues, 'data.id');
    if (!hasId) {
      return;
    }

    let buttonSet = [];
    const decline = (
      <Button
        size="large"
        key={'decline'}
        handleClick={this.handleDecline}
        className='pull-left'
        label={'Decline'}
        bsStyle="primary"
        isLoading={brokerApplication.isSubmitting}
      />
    );

    const approve = (
      <Button
        size="large"
        key={'approve'}
        handleClick={this.handleApprove}
        className='pull-left'
        label={'Approve'}
        bsStyle="primary"
        isLoading={brokerApplication.isSubmitting}
      />
    );

    const resend = (
      <Button
        size="large"
        key={'resend'}
        handleClick={this.handleResendApproval}
        className='pull-left'
        label={'Resend Approval'}
        bsStyle="primary"
        isLoading={brokerApplication.isSubmitting}
      />
    );

    if (getStatus === 'declined') {
      buttonSet.push(approve);
    }

    if (getStatus === 'applied') {
      buttonSet.push(approve, decline);
    }

    if (getStatus === 'approved') {
      buttonSet.push(resend);
    }

    return buttonSet.length ? <div>{buttonSet}</div> : null;
  }

  render() {
    const {
      handleSubmit,
      formValues,
      organisation,
      brochure,
      brokerApplication,
      initialValues,
    } = this.props;
    const { activeTab } = this.state;
    const hasId = get(formValues, 'data.id');

    return (
      <InternalContainer title={'Broker Application'}>
        {initialValues ? (
          <Form onSubmit={handleSubmit}>
            <div className="tab-container">
              <Tabs
                activeKey={activeTab}
                id="broker-application"
                justified
                unmountOnExit
                onSelect={::this.handleTab}
              >
                <Tab eventKey={'1'} title={'General'}>
                  <General
                    organisation={organisation}
                    brokerType={'broker'}
                    form={FORM_NAME}
                  />
                </Tab>
                <Tab eventKey={'2'} title={'Company'}>
                  <Company organisation={organisation} form={FORM_NAME}/>
                </Tab>
                <Tab eventKey={'3'} title={'Management'}>
                  <Management form={FORM_NAME} {...this.props} />
                </Tab>
                <Tab eventKey={'4'} title={'Regulation'}>
                  <Regulation content={brochure} form={FORM_NAME}/>
                </Tab>
                {hasId && (
                  <Tab eventKey={'5'} title={'Documents'}>
                    <Notes formValues={formValues} id={hasId} {...this.props} />
                  </Tab>
                )}
                <Tab eventKey={hasId ? '6' : '5'} title={'Products'}>
                  <Products
                    products={brokerApplication.products}
                    form={FORM_NAME}
                    {...this.props}
                  />
                </Tab>
                <Tab eventKey={hasId ? '7' : '6'} title={'Users'}>
                  <Users isLoggedIn form={FORM_NAME}/>
                </Tab>
                <Tab eventKey={hasId ? '8' : '7'} title={'Declaration'}>
                  <Declaration formValues={formValues}/>
                </Tab>
              </Tabs>
            </div>

            <div className="btn-bar-container">
              <ButtonBar>
                {activeTab > 1 && (
                  <Button
                    className="btn-action-back pull-left"
                    bsStyle="default"
                    handleClick={::this.goPreviousTab}
                    label="Back"
                  />
                )}
                {activeTab < (hasId ? 8 : 7) && (
                  <Button
                    className="btn-action pull-right"
                    bsStyle="primary"
                    handleClick={::this.goNextTab}
                    label="Next"
                  />
                )}

                <Button
                  size="large"
                  handleClick={::this.handleSave}
                  className="pull-right"
                  label={'Save'}
                  bsStyle="primary"
                  isLoading={brokerApplication.isSubmitting}
                />

                {hasId && <div>{this.renderActionButtons()}</div>}

              </ButtonBar>
            </div>
          </Form>
        ) : (
          <Loader/>
        )}
      </InternalContainer>
    );
  }
}

const validate = () => {
  const errors = {};

  // if (!values.trading_name) {
  //   errors.trading_name = 'Required'
  // }
  return errors;
};

const form = reduxForm({ form: FORM_NAME, validate })(BrokersApNewForm);
const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (state, props) => {
  const values = selector(state, 'data', 'data.attributes', 'data.relationships', 'metadata', 'meta');

  const products = getIncludedResource(
    state.auth.user.data,
    state.auth.user.included,
    'available_products',
  );
  const product = find(products, (product) => {
    return get(product, 'attributes.product_code') === 'TRAV';
  });

  return {
    enableReinitialize: true,
    organisation: state.organisation,
    brochure: state.brochure,
    auth: state.auth,
    dashboard: state.dashboard,
    initialValues: props.resource,
    formValues: values,
    // productId: product.id,
    brokerApplication: state.brokerApplication,
    broker: state.broker,
    products,
    product,
  };
};

export default connect(mapStateToProps)(form);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  DataTable,
  CreateNoteButton,
  NotesList,
} from '../../../../common/components';
import { FormattedDate } from 'react-intl';
import { refreshData } from '../../../../common/components/DataTable/redux/dataTableActions';
import { Col, Row } from 'react-bootstrap';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';

export default class EnquiryNotes extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
  };

  formatDate(cell) {
    return <FormattedDate value={new Date(cell)} />;
  }

  handleRowClick() {
    //this.props.dispatch(push('/admin/users/' + row.id));
    return false;
  }

  refreshList() {
    this.props.dispatch(refreshData('notes'));
  }

  render() {
    return (
        <Box>
          <h2 className="resource-name">Enquiry Notes</h2>
          <DataTable
            source={'/notes'}
            autoFilter={this.props.resource.data.id}
            autoFilterType="filter[resource]"
            name="notes"
            showTotal
            onRowSelect={this.handleRowClick.bind(this)}
            component={NotesList}
          />
          <Row>
            <Col sm={12}>
              <InternalButtonBar
                buttons={[
                  {
                    permissions: ['marketing.notes_create'],
                    button: (
                      <CreateNoteButton
                        label="Add a Note"
                        className="pull-right"
                        notableResource={this.props.resource}
                        notableResourceType="enquiry"
                        callback={::this.refreshList}
                      />
                    ),
                  },
                ]}
              />
            </Col>
          </Row>
        </Box>
    );
  }
}

import React from 'react';
import { Col } from 'react-bootstrap';
import { Field } from 'redux-form';
import { InfoLabel } from '../../../../../common/components';
import CheckboxGroup from '../../../../../common/components/ReduxFormField/CheckboxGroup';
import FormGroupRow from "../../../../../common/components/FormGroupRow/FormGroupRow";
import { AutoIncludeField, ViewAutoInclude } from './AutoIncludeField/AutoIncludeField';


const EnableBoltonField = ({ input, label, labelSize, fieldSize }) => {
  return (
    <FormGroupRow
      label={label}
      labelSize={labelSize}
      fieldSize={fieldSize}
    >
      <Field
        name={`${input.name}`}
        component={CheckboxGroup}
        options={[{ label: '', value: true }]}
      />
    </FormGroupRow>
  );
};

const ViewEnabled = ({ input, label }) => {
  return (
    <InfoLabel
      label={`${label} ${input.value ? 'enabled' : 'disabled'}`}
      labelSize={12}
      value={''}
    />
  );
};


const OptionAncillary = ({ input, isViewOnly, coverLevels }) => {
  const name = input.name;
  return (
    <>
      <Col sm={6}>
        <Field
          isViewOnly={isViewOnly}
          name={`${name}.bolt_on`}
          label={isViewOnly ? 'Bolt On:' : 'Enable Bolt On'}
          labelSize={4}
          fieldSize={2}
          component={isViewOnly ? ViewEnabled : EnableBoltonField}
          coverLevels={coverLevels}
        />
      </Col>

      <Col sm={6}>
        <Field
          isViewOnly={isViewOnly}
          name={`${name}.auto_include`}
          label={'Auto Include:'}
          labelSize={4}
          fieldSize={4}
          component={isViewOnly ? ViewAutoInclude : AutoIncludeField}
          coverLevels={coverLevels}
        />
      </Col>

    </>
  );
};

export default OptionAncillary;

import React from 'react';

export const quote = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95 95">
    <title>Icons</title>
    <path d="M46.59,58.73a9,9,0,0,1-9,9H36.52a1.13,1.13,0,0,1-1.12-1.12V64.33a1.13,1.13,0,0,1,1.12-1.12h1.12a4.48,4.48,0,0,0,4.48-4.48v-.56a1.68,1.68,0,0,0-1.68-1.68H36.52a3.36,3.36,0,0,1-3.36-3.36V46.42a3.36,3.36,0,0,1,3.36-3.36h6.71a3.36,3.36,0,0,1,3.36,3.36Zm15.67,0a9,9,0,0,1-9,9H52.18a1.13,1.13,0,0,1-1.12-1.12V64.33a1.13,1.13,0,0,1,1.12-1.12H53.3a4.48,4.48,0,0,0,4.48-4.48v-.56a1.68,1.68,0,0,0-1.68-1.68H52.18a3.36,3.36,0,0,1-3.36-3.36V46.42a3.36,3.36,0,0,1,3.36-3.36H58.9a3.36,3.36,0,0,1,3.36,3.36Z" />
    <path d="M69.74,18.92a6.2,6.2,0,0,1,4.55,1.87,6,6,0,0,1,1.87,4.4v51a6,6,0,0,1-1.87,4.4,6.2,6.2,0,0,1-4.55,1.87H25.26a6.2,6.2,0,0,1-4.55-1.87,6,6,0,0,1-1.87-4.4v-51a6,6,0,0,1,1.87-4.4,6.2,6.2,0,0,1,4.55-1.87H38.54a9.79,9.79,0,0,1,3.51-4.63,9.18,9.18,0,0,1,10.9,0,9.8,9.8,0,0,1,3.51,4.63Zm0,57.31v-51H63.47v9.55H31.53V25.19H25.26v51Zm-20-56.42a3.25,3.25,0,1,0,.9,2.24A3,3,0,0,0,49.74,19.81Z" />
  </svg>
);

export default quote;

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FieldArray, getFormValues, reduxForm } from 'redux-form';
import { Form } from 'react-bootstrap';
import DeclineInfoFieldArray from './components/DeclineInfoFieldArray';

const DeclinesForm = ({
  isViewOnly,
  handleSubmit,
  formValues,
  coverLevels,
  medicalQuestionKeys,
  productType
}) => {
  const regions = formValues?.meta?.config?.parameters?.region?.enum ?? [];
  const options = formValues?.meta?.config?.options ?? [];
  return (
    <div className="product-container">
      <Form horizontal onSubmit={handleSubmit}>
        <h2>Additional Rules</h2>

        <FieldArray
          name={'meta.config.referrals'}
          component={DeclineInfoFieldArray}
          isViewOnly={isViewOnly}
          regions={regions}
          options={options}
          medicalQuestionKeys={medicalQuestionKeys}
          coverLevels={coverLevels}
          productType={productType}
        />
      </Form>
    </div>
  );
};

export default compose(
  connect((state, props) => {
    return {
      formValues: getFormValues(props.formName)(state),
      destroyOnUnmount: false,
      enableReinitialize: true,
      form: props.formName,
    };
  }),
  reduxForm({}),
)(DeclinesForm);

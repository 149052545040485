export const IS_REINDEXING = 'socrates-online/tool/IS_REINDEXING';
export const FETCH_JOB_STATUS = 'socrates-online/tool/FETCH_JOB_STATUS';
export const STOP_JOB_POLLING = 'socrates-online/tool/STOP_JOB_POLLING';
export const FETCH_JOB_STATUS_ERROR = 'socrates-online/tool/FETCH_JOB_STATUS_ERROR';

export const initialState = {
  isReindexing: false,
  jobs: [], // Holds the list of jobs
  pollingStopped: false, // Tracks if polling is stopped
  error: null, // Tracks errors
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case IS_REINDEXING:
      return {
        ...state,
        isReindexing: action.status,
      };

    case FETCH_JOB_STATUS:
      return {
        ...state,
        jobs: action.payload,
        error: null,
      };

    case STOP_JOB_POLLING:
      return {
        ...state,
        pollingStopped: true,
      };

    case FETCH_JOB_STATUS_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
}

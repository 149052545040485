import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field, formValueSelector, Form } from 'redux-form';
import { Row, Col, ControlLabel } from 'react-bootstrap';
import { TextField, CheckboxGroup } from '../ReduxFormField';
import { Button, Modal, DatePicker } from '../';
import { openModal, closeModal } from '../Modal/ModalRedux';
import { isBroker } from '../../../modules/auth/redux/authActions';
import { createNote } from './noteActions';
import UploadModalComponent, { UploadModal } from '../Upload/UploadModal';
import { removeFile, clearFiles, setUploadAccept } from '../Upload/UploadRedux';
import { get, set } from 'lodash';
import './styles.scss';

const FORM_NAME = 'createNoteForm';

const validate = (values) => {
  const errors = {};

  if (!get(values, 'data.attributes.content', false)) {
    set(errors, 'data.attributes.content', 'Required');
  }
  return errors;
};

class CreateNoteButton extends Component {
  constructor(props) {
    super(props);
    this.resetState = this.resetState.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleUploadComplete = this.handleUploadComplete.bind(this);
  }

  resetState() {
    const { actions } = this.props;

    actions.clearFiles();
  }

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded, callback } = this.props;
    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('');
      }
      dispatch(closeModal(FORM_NAME));
      this.resetState();
    }
  }

  handleSubmit(values) {
    const { files, notableResource, scheme } = this.props;
    if (files.length) {
      values.data.relationships = {
        attachments: {
          data: files.map((file) => {
            return {
              id: file.id,
              type: file.type,
            };
          }),
        },
      };
    }

    // Fix for the undefined error on dispatch:
    let resourceData = get(notableResource, 'data');

    if (scheme) {
      resourceData = scheme;
    }

    return this.props.dispatch(
      createNote(values, resourceData, this.props.notableResourceType),
    );
  }

  handleOpen() {
    const { dispatch, reset, change } = this.props;
    dispatch(reset());
    dispatch(change('data.attributes.forward_url', get(location, 'pathname', null)));
    dispatch(openModal(FORM_NAME));
  }

  handleUpload() {
    const { dispatch } = this.props;
    dispatch(setUploadAccept('*'));
    dispatch(openModal(UploadModal.MODAL_IDENTIFIER));
  }

  handleUploadComplete() {
    //
  }

  render() {
    const { label, handleSubmit, submitting, formValues, hideReminder } = this.props;
    const title = label || 'Create Note';

    const modalFooter = (
      <div>
        <Button
          type="submit"
          bsStyle="primary"
          className="pull-right"
          isLoading={submitting}
          handleClick={handleSubmit(this.handleSubmit)}
          label="Create Note"
        />
        <Button
          type="button"
          bsStyle="primary"
          className="pull-right pad-right"
          handleClick={this.handleUpload}
          disabled={submitting}
        >
          {submitting ? (
            <i className="fa fa-cog fa-spin"/>
          ) : (
            <i className="fa fa-upload"/>
          )}{' '}
          upload attachment
        </Button>
      </div>
    );

    return (
      <div className="note-btn-wrapper">
        <Button
          //bsStyle='primary'
          {...this.props}
          label={title}
          handleClick={this.handleOpen}
        />

        <Modal
          name={FORM_NAME}
          title={title}
          close
          onHide={this.resetState}
          footer={modalFooter}
        >
          <Form onSubmit={handleSubmit(this.handleSubmit)}>
            <div className="create-note-btn">
              <Row>
                <Col xs={12} componentClass={ControlLabel}>
                  Add your notes
                </Col>
              </Row>
              <Field
                name={'data.attributes.content'}
                labelSize={3}
                type="textarea"
                component={TextField}
              />
              {!hideReminder && (
                <Row>
                  <Col sm={5}>
                    <Field
                      name={'meta.set_reminder'}
                      inline
                      options={[{ label: 'Set a reminder', value: true }]}
                      component={CheckboxGroup}
                    />
                  </Col>
                  <Col sm={5} style={{ marginTop: '10px' }}>
                    {get(formValues, 'meta.set_reminder') && (
                      <div className="row timepicker">
                        <Field
                          name={'data.attributes.reminder'}
                          label="Remind me on"
                          block
                          labelSize={5}
                          component={DatePicker}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              )}
              {this.props.files.length ? this.renderUploads() : null}
            </div>
          </Form>
        </Modal>
        <UploadModalComponent onComplete={this.handleUploadComplete}/>
      </div>
    );
  }

  renderUploads() {
    const { files, actions } = this.props;

    return (
      <div>
        <Row>
          <Col xs={12} componentClass={ControlLabel}>
            Attachments:
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <ul className="upload-list">
              {files.map((file, i) => {
                return (
                  <li>
                    <div className="upload-progress" style={{ width: '100%' }}/>
                    <a onClick={() => actions.removeFile(i)}>
                      <i className="fa fa-times"/>
                    </a>
                    <div className="content">
                      <p className="pull-right">&nbsp;</p> {file.attributes.name}
                    </div>
                  </li>
                );
              })}
            </ul>
          </Col>
        </Row>
      </div>
    );
  }
}

CreateNoteButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  // notableResource: PropTypes.object.isRequired,
  notableResourceType: PropTypes.string.isRequired,
  formValues: PropTypes.object.isRequired,
  callback: PropTypes.func,
  label: PropTypes.string,
};

const form = reduxForm({ form: FORM_NAME, validate })(CreateNoteButton);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state) => {
  const values = selector(
    state,
    'meta.set_reminder',
    'data.attributes.reminder',
    'data.attributes.share',
  );

  if (!isBroker()) {
    set(values, 'data.attributes.share', 'organisation');
  }

  return {
    formValues: values,
    files: state.upload.files,
  };
};

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({ clearFiles, removeFile }, dispatch), dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(form);

import React from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import {
  Button,
  HorizontalFormControl,
  InfoButton
} from '../../../../../../common/components';
import { MarketingField } from '../Marketing/Marketing';
import Communications from '../../../Communications';
import CovidStatement from '../../../CovidStatement/CovidStatement';
import cx from 'classnames';
import displayCurrency from '../../../../../../helpers/displayCurrency';
import * as luhn from 'luhn';
import lessThan from '../../../../../../helpers/lessThan';
import { ExpiryDateFields } from '../../../../../../common/components/ReduxFormField';
import Declaration
  from '../../../LeisureTravelForm/Components/Fields/Declaration/Declaration';
import { required } from "redux-form-validators";

const getErrors = (validationErrors) => {
  if (!validationErrors) {
    return [];
  }
  return Object.keys(validationErrors).reduce((carry, errorKey) => {
    if (!validationErrors[errorKey]) {
      carry.push(errorKey + ' required');
    }
    return carry;
  }, []);
};

const isLuhnHappy = (value) => {
  const val = value && value.replace(/\s/g, '');
  return val && luhn.validate(val) ? null : 'This is not a valid card number';
};

const Card = ({ definitions, isPreviousCard }) => {
  // const { formValues } = this.props;
  // const isPreviousCard = get(formValues, 'meta.previous_card', false);

  return (
    <div className="fadein-fields form-horizontal">
      {!isPreviousCard && (
        <div className="card-fields-container">
          <Field
            className="text-left"
            name={'meta.card_number'}
            label="Card number"
            id={'meta.card_number'}
            type="cleave"
            cleaveOptions={{ creditCard: true }}
            labelSize={2}
            mdFieldSize={4}
            component={HorizontalFormControl}
            validate={isLuhnHappy}
          />
          <Field
            className="text-left"
            name={'meta.cvv'}
            mdFieldSize={4}
            label="CVC/CV2"
            normalize={lessThan(3)}
            labelSize={2}
            infoButton={<InfoButton content={definitions?.cvc}/>}
            component={HorizontalFormControl}
            validate={required({ msg: 'CVC is required' })}
          />
          <ExpiryDateFields
            baseName={'meta'}
            label="Expiry date"
            labelSizes={{ sm: 2, md: 2, lg: 2 }}
            fieldSizes={{ sm: 4, md: 4, lg: 4 }}

          />
        </div>
      )}
    </div>
  );
};

const PaymentButton = ({ input, meta, type },) => {

  const handleSelect = () => {
    input.onChange(type);
  };
  return (
    <Button
      className={
        cx({
          'error': meta.error && meta.submitFailed

        })
      }
      label={<div className="payment-type-label capitalise"> {type} </div>}
      handleClick={handleSelect}
    />
  );
};
const PaymentTypes = ({ isBroker, show, meta }) => {
    if (!show) {
      return null;
    }

    return (
      <>


        <div className={'payment-buttons'}>
          <div>
            <Field
              name={'meta.payment_type'}
              type={'card'}
              label={'card'}
              component={PaymentButton}
              className={'btn-payment pad-right'}

            />
          </div>

          {isBroker && (
            <div>
              <Field
                name={'meta.payment_type'}
                type={'account'}
                label={'Broker Account'}
                component={PaymentButton}
                className={'btn-payment pad-right'}
                // className={`btn-payment ${classButton('Broker Account')}`}
              />
            </div>
          )}


        </div>
        {meta && meta.touched && meta.error && (
          <span className="input-error">{meta.error}</span>
        )}
      </>

    );


  };

const PaymentTab = ({
  isBroker,
  covidMessage,
  definitions,
  applicationErrors,
  formValues,
  isMta,
  purchaseDeclaration,
  hasSignpostedPremiums,
  purchasePremium,
  onIssue,
  isIssuing
}) => {

  const errors = getErrors(applicationErrors);

  const taxRate = purchasePremium?.rates?.tax_rate ?? 0;

  const issue = (values) => {
    onIssue(values);
  };

  const handleIssue = () => {
    issue(formValues);
  };


  const isPurchaseDeclarationAgreed = formValues?.meta?.purchase_declaration;


  if (errors.length > 0) {
    return (
      <div className="row">
        <div className="col-xs-12">
          <h4>Not ready for issuing</h4>
          {errors.length > 0 && (
            <div>
              <p>Please resolve the following issues:</p>
              <ul>
                {errors.map((errorItem, i) => (
                  <li key={i}>{errorItem}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  }


  const showPaymentOptions = isPurchaseDeclarationAgreed || isMta;
  const amountDue = formValues?.meta?.amount;

  return (
    <div>
      <Communications name={'data.attributes.metadata.communications'}/>
      <hr/>

      <>
        <div className="marketing-title">
          <h4>Marketing</h4>
        </div>
        <Field
          name={'data.attributes.metadata.adults[0].marketing_options'}
          showText
          component={MarketingField}
        />

      </>
      <hr/>
      <Declaration
        show={!isMta}
        declaration={purchaseDeclaration}
        hasSignpostedPremiums={hasSignpostedPremiums}
        selectedPremium={purchasePremium}
      />
      <div>
        <CovidStatement covidMessage={covidMessage} showAgreement={false}/>
      </div>
      {showPaymentOptions && (
        <div>
          <hr/>
          <h4>Payment Details</h4>
          <Field
            name={'meta.payment_type'}
            component={PaymentTypes}
            isBroker={isBroker}
            show={!isMta || (isMta && amountDue > 0)}
            validate={required({ msg: 'Payment type is required' })}
          />

          <div className="divider"/>
          {amountDue !== undefined && (
            <div className="total-premium">
              <span>Total to pay:</span>
              <span className="total-premium red">
                &nbsp;
                {displayCurrency(amountDue)}
                <span style={{ fontSize: '0.8em' }}>
                  &nbsp;including {taxRate}% Insurance Premium Tax (IPT)
                </span>
              </span>
            </div>
          )}
          {formValues?.meta?.payment_type === 'card' && (
            <Card definitions={definitions}/>
          )}
          <div className="payment-options-container">
            <Button
              bsStyle="primary"
              isLoading={isIssuing}
              handleClick={handleIssue}

              label="Issue Policy"
            />
          </div>
        </div>
      )}
    </div>
  );

};

const FORM_NAME = 'IssuePolicyDialog';


export default connect(
  (state, ownProps) => ({
    initialValues: {
      data: {
        attributes: {
          metadata: {
            communications: {},
            adults: {
              ...ownProps.application?.data?.attributes?.metadata?.adults


            }
          }
        },
        relationships: {
          premium: {
            data: {
              type: 'premium',
              id: ownProps?.purchasePremiumId,
            },
          },
        },
      },

      meta: {
        // product_code: get(ownProps.product, 'data.attributes.product_code', ''),
        sms_send: false,
        purchase_declaration: false,
        payment_type: null,
        amount: ownProps?.purchasePremium?.gross
      },
    },
    formValues: getFormValues(FORM_NAME)(state),
  }))(reduxForm({ form: FORM_NAME })(PaymentTab));

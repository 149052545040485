import React, { Component } from 'react';
import Box from '../../../../../../common/components/Box/Box';
import { Col, Row } from 'react-bootstrap';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import RadioGroup from '../../../../../../common/components/ReduxFormField/RadioGroup';
import HorizontalFormControl from '../../../../../../common/components/HorizontalFormControl/HorizontalFormControl';
import Checkbox from '../../../../../../common/components/ReduxFormField/Checkbox';
import { get, set, isEmpty } from 'lodash';
import { connect } from 'react-redux';

export class Declaration extends Component {
  render() {
    const { formValues } = this.props;
    const paymentMethod = get(formValues, 'data.attributes.metadata.payment_method');
    return (
      <Box>
        <Row>
          <Col sm={12}>
            <h4 className="page-title">Payments</h4>
            <hr />
            <div className="margin-bottom">
              Please confirm that you hold separate bank accounts i.e. confirm you have
            </div>
            <div className="form-horizontal">
              <Field
                name="data.attributes.metadata.hold_separate_premium_account"
                label="A separate premium account"
                component={RadioGroup}
                inline
                labelSize={4}
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                  { label: 'N/A', value: 'N/A' },
                ]}
              />
            </div>
            <div className="form-horizontal">
              <Field
                name="data.attributes.metadata.hold_private_finances_separate_account"
                label="A separate account for private finances of the company"
                component={RadioGroup}
                inline
                labelSize={4}
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                  { label: 'N/A', value: 'N/A' },
                ]}
              />
            </div>
            <hr />
            <div className="form-horizontal">
              <Field
                name="data.attributes.metadata.payment_method"
                label="Please confirm your payment method:"
                component={RadioGroup}
                inline
                labelSize={4}
                options={[
                  { label: 'BACS payment', value: 'bacs' },
                  { label: 'Cheque', value: 'cheque' },
                ]}
              />
            </div>
            {paymentMethod === 'bacs' && (
              <div>
                <p>Bank account details</p>
                <div className="form-horizontal">
                  <Field
                    name="data.attributes.metadata.account_details.bank_name"
                    label="Bank name"
                    component={HorizontalFormControl}
                    labelSize={4}
                    mdFieldSize={4}
                  />
                </div>
                <div className="form-horizontal">
                  <Field
                    name="data.attributes.metadata.account_details.bank_address"
                    label="Bank address"
                    component={HorizontalFormControl}
                    labelSize={4}
                    mdFieldSize={4}
                  />
                </div>
                <div className="form-horizontal">
                  <Field
                    name="data.attributes.metadata.account_details.sort_code"
                    label="Sort code"
                    component={HorizontalFormControl}
                    labelSize={4}
                    mdFieldSize={4}
                  />
                </div>
                <div className="form-horizontal">
                  <Field
                    name="data.attributes.metadata.account_details.account_number"
                    label="Account number"
                    component={HorizontalFormControl}
                    labelSize={4}
                    mdFieldSize={4}
                  />
                </div>
              </div>
            )}
            {paymentMethod === 'cheque' && (
              <div>
                <p>Cheque</p>
                <div className="form-horizontal">
                  <Field
                    name="data.attributes.metadata.account_details.cheques_payable_to"
                    label="Cheques payable to"
                    component={HorizontalFormControl}
                    labelSize={4}
                    mdFieldSize={8}
                  />
                </div>
              </div>
            )}
          </Col>
          <Col sm={12} className="margin-top">
            <h4 className="page-title">Declaration</h4>
            <hr />
            <div className="margin-bottom">
              I/We apply for an agency with P J Hayman & Company Limited and declare that
              the information given above is correct and that nothing has been withheld
              which might influence the acceptance of this application.
            </div>

            <Field
              name={'data.attributes.metadata.declaration'}
              label="I/We agree that:"
              component={Checkbox}
              positionTop
              inline
              labelSize={0}
              checkboxValue
            />
            <ul>
              <li>
                A client's account will be maintained for premiums payable to the Company
                and such premiums are held in trust for the Company.
              </li>
              <li>
                I/We will advise the Company of any alterations in the above information.
              </li>
            </ul>
          </Col>
        </Row>
      </Box>
    );
  }
}

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    { field: 'data.attributes.metadata.hold_separate_premium_account', name: 'Field' },
    {
      field: 'data.attributes.metadata.hold_private_finances_separate_account',
      name: 'Field',
    },
    { field: 'data.attributes.metadata.payment_method', name: 'Field' },
  ];

  const agreeCheckBox = get(values, 'data.attributes.metadata.declaration');

  if (!agreeCheckBox) {
    set(errors, 'data.attributes.metadata.declaration', 'Required');
  }

  requiredFields.map((required) => {
    const isFieldEmpty = isEmpty(get(values, required.field));
    if (isFieldEmpty) {
      set(errors, required.field, required.name + ' is required');
    }
  });

  errors._error = errors.data;
  return errors;
};

const FORM_NAME = 'BrokerApplication';
const form = reduxForm({ form: FORM_NAME, validate })(Declaration);
const mapStateToProps = (state) => {
  const values = formValueSelector(FORM_NAME)(state, 'data', 'metadata', 'meta');

  return {
    auth: state.auth,
    form: 'BrokerApplication',
    formValues: values,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    product: state.product,
  };
};

export default connect(mapStateToProps)(form);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormGroup, Col, ControlLabel } from 'react-bootstrap';
import SelectMenu from '../SelectMenu/SelectMenu';

export const SELECT_IDENTIFIER = 'accounts/currencies';

class SelectCurrency extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    label: PropTypes.string,
    labelSize: PropTypes.number,
    input: PropTypes.object.isRequired,
    infoButton: PropTypes.element,
    name: PropTypes.string,
    meta: PropTypes.object,
    object: PropTypes.bool,
    multi: PropTypes.bool,
    filters: PropTypes.array,
  };

  renderSelectMenu() {
    return (
      <SelectMenu
        source={SELECT_IDENTIFIER}
        optionValuePath={'id'}
        {...this.props}
      />
    );
  }

  render() {
    const {
      infoButton,
      name,
      meta,
      label,
      labelSize,
    } = this.props;

    if (label) {
      return (
        <FormGroup
          controlId={name}
          className={meta && meta.touched && meta.error ? ' has-error' : ''}
        >
          <Col componentClass={ControlLabel} sm={labelSize}>
            {label} {infoButton}
          </Col>
          <Col sm={12 - labelSize}>
            {this.renderSelectMenu()}
          </Col>
        </FormGroup>
      );
    }

    return this.renderSelectMenu();
  }
}

export default connect()(SelectCurrency);

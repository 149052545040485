import React, { useState } from 'react';
import { ButtonToolbar, Col, DropdownButton, MenuItem, Row } from 'react-bootstrap';
import { PieChart } from '../../../../../../common/components';
import {
  StatsSectionConversion
} from '../../../../../dashboard/components/StatsSectionConversionLabel/StatsSectionConversionLabel';
import {
  StatsSectionTable
} from '../../../../../dashboard/components/StatsSectionTable/StatsSectionTable';
import './ProductStats.scss';

const KEY_MAP = {
  today: 'TODAY',
  yesterday: 'Yesterday',
  week: 'This week',
  month: 'This month'
};

const ProductStats = ({ display, stats }) => {
  const [range, setRange] = useState('Today');

  const handleMenuItem = (e) => {
    setRange(KEY_MAP[e]);
  };


  return (
    <div className={'product-stats'}>
      <h5 className="section-title join-cover">
        Activity - {range}
        <ButtonToolbar>
          <DropdownButton
            bsSize="small"
            title="Range"
            className="pull-right"
            id="dropdown-size-large"
            pullRight
            bsStyle="default"
          >
            <MenuItem
              eventKey="today"
              active={range === 'Today'}
              onSelect={handleMenuItem}
            >
              Today
            </MenuItem>
            <MenuItem
              eventKey="yesterday"
              active={range === 'Yesterday'}
              onSelect={handleMenuItem}
            >
              Yesterday
            </MenuItem>
            <MenuItem
              eventKey="week"
              active={range === 'This week'}
              onSelect={handleMenuItem}
            >
              This week
            </MenuItem>
            <MenuItem
              eventKey="month"
              active={range === 'This month'}
              onSelect={handleMenuItem}
            >
              This month
            </MenuItem>
          </DropdownButton>
        </ButtonToolbar>
      </h5>
      <div className="product-stats--container">
        <Row>
          <Col sm={display === 'vertical' ? 12 : 6}>
            <div className="section-item">
              <div className="section-title join">Quotes</div>
              <div className="product-chart-container ">
                <PieChart range={range} stats={stats}/>
              </div>
            </div>
          </Col>
          <Col sm={display === 'vertical' ? 12 : 6}>
            <div className="section-item">
              <div className="section-title join">Policies</div>
              <div>
                <div className="section-item">
                  <StatsSectionTable range={range} stats={stats}/>
                  <StatsSectionConversion range={range} stats={stats}/>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );

};

export default ProductStats;

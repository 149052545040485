import React, { useEffect } from 'react';

import { connect, useSelector } from 'react-redux';
import { getOrganisation, updateOrganisation } from '../redux/organisationActions';
import { Button, Loader } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';

import OrganisationForm from '../components/OrganisationForm/OrganisationForm';

const OrganisationEditContainer = ({
  location,
  params,
  onGetOrganisation,
  onUpdateOrganisation
}) => {
  const organisationId = params.id;

  useEffect(() => {
    onGetOrganisation(organisationId);
  }, [organisationId, onGetOrganisation]);

  const resource = useSelector(((state) => state.organisation?.resources?.[organisationId]));
  const organisationType = location?.query?.organisationType;
  const organisationTypeName = location?.query?.organisationTypeName;
  const update = (values) => {
    return onUpdateOrganisation(values);
  };
  if (!resource) {
    return (
      <InternalContainer title="Organisation"
                         buttons={<Button bsStyle="default" label="Back" isBackButton
                                          leftIcon="caret-left"/>}>
        <Loader/>
      </InternalContainer>

    );
  }

  return (
    <InternalContainer title="Organisation"
                       buttons={<Button bsStyle="default" label="Back" isBackButton
                                        leftIcon="caret-left"/>}>
      <OrganisationForm
        organisationType={organisationType}
        organisationTypeName={organisationTypeName}
        onSave={update}
        resource={resource}
      />
    </InternalContainer>
  );
};


export default connect(null,
  {
  onGetOrganisation: getOrganisation,
  onUpdateOrganisation: updateOrganisation,
})(OrganisationEditContainer);

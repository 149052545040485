import { Col, Row } from "react-bootstrap";
import { Field } from "redux-form";
import { TextField } from "../../../../../../common/components/ReduxFormField";
import { Button } from "../../../../../../common/components";
import React from "react";

const Referral = ({ show, referrals, isSaving, onSaveReferral }) => {
  if (!show) {
    return null;
  }
  return (
    <Col xs={12}>
      <div>
        <h3>This quote needs to be referred to the underwriter for approval</h3>
        <ul>
          {referrals?.reasons?.map((referral, i) => (
            <li key={i}>{referral}</li>
          ))}
        </ul>
        <Row>
          <Col xs={12}>
            Please add any notes for the underwriter
            <Field
              name={'meta.note.content'}
              type="textarea"
              component={TextField}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={3} className="pull-right">
            <Button
              label="Save and request approval"
              bsStyle="primary"
              className="pull-right"
              isLoading={isSaving}
              handleClick={onSaveReferral}
            />
          </Col>
          <Col sm={3}>
            {/*<QuoteSaveExitPolicy*/}
            {/*  application={formValues}*/}
            {/*  className={'pull-left'}*/}
            {/*  product={productId}*/}
            {/*  isReferral={requiresReferral}*/}
            {/*/>*/}
          </Col>
        </Row>
      </div>
    </Col>
  )

}
export default Referral

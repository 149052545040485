import React from 'react';

export const customer = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95 95">
    <title>Icons</title>
    <path d="M64.16,49.93a10,10,0,0,0,10-10,10,10,0,0,0-2.92-7.17,9.8,9.8,0,0,0-14.15,0,10,10,0,0,0-2.92,7.17,10,10,0,0,0,10,10Z" />
    <path d="M36.35,46.26a12.28,12.28,0,0,0,9.14-3.86,12.78,12.78,0,0,0,3.76-9.24,12.83,12.83,0,0,0-12.9-12.9A12.78,12.78,0,0,0,27.1,24a12.28,12.28,0,0,0-3.86,9.14,13.18,13.18,0,0,0,13.1,13.1Z" />
    <path d="M77.69,56.22a36.58,36.58,0,0,0-13.44-2.79,36.58,36.58,0,0,0-13.44,2.79Q43.75,59,43.75,63.55v8.38h41V63.55Q84.76,59,77.69,56.22Z" />
    <path d="M36.25,49.59A45.88,45.88,0,0,0,25.69,51,33.45,33.45,0,0,0,15,55.52q-4.8,3.14-4.8,7.15v9.25h30V63.55c0-5.35,5.25-9.05,12.25-11.05C47.5,50.5,38.8,49.59,36.25,49.59Z" />
  </svg>
);

export default customer;

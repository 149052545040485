import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Col, ControlLabel } from 'react-bootstrap';
import MultiSelectMenu from './SelectProducts';

export const SELECT_IDENTIFIER = 'teams';

class SelectTeam extends Component {
  renderSelectMenu() {
    const source = 'teams';
    return (
      <MultiSelectMenu
        source={source}
        sourceKey={'MultiProducts'}
        isClearable
        isObject
        {...this.props}
      />
    );
  }
  render() {
    const { infoButton, meta, label, labelSize, smFieldSize } = this.props;

    if (label) {
      const labelSmSize = labelSize || 2;
      const menuSize = smFieldSize || 12 - labelSmSize;

      return (
        <FormGroup className={meta && meta.touched && meta.error ? ' has-error' : ''}>
          {label && (
            <Col sm={labelSmSize}>
              <ControlLabel>
                {label} {infoButton}
              </ControlLabel>
            </Col>
          )}

          <Col sm={menuSize}>
            {this.renderSelectMenu()}
            {meta && meta.touched && meta.error && (
              <span className="input-error">{meta.error}</span>
            )}
          </Col>
        </FormGroup>
      );
    }

    return this.renderSelectMenu();
  }
}

export default connect()(SelectTeam);

import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';
import getIncludedResource from '../../../helpers/getIncludedResource';
import { get } from 'lodash';
import './Avatar.scss';

const Avatar = ({ user, url }) => {
  let image = require('images/avatars/user_default.svg');

  if (url) {
    image = url;
  } else {
    const avatar = getIncludedResource(user.data, user.included, 'avatar');
    const avatarUrl = get(avatar, 'data.attributes.url');
    if (avatarUrl) {
      image = avatar.data.attributes.url;
    }
  }

  return (
    <div>
      <Image src={image} className="img-fluid avatar" circle />
    </div>
  );
};

Avatar.propTypes = {
  user: PropTypes.object,
};

export default Avatar;

import React from 'react';
import { Field, FormSection } from 'redux-form';
import { SelectField } from '../../../../common/components/ReduxFormField';
import {
  Icon,
  Button,
  SelectAccount,
  SelectBroker,
  SelectProduct,
  SelectCurrency,
  HorizontalFormControl,
} from '../../../../common/components';
import { Row, Col } from 'react-bootstrap';
import { JOURNAL_DEFAULTS, PREMIUM_ELEMENT_TYPES } from '../JournalForm/helpers';
import { required,addValidator } from 'redux-form-validators';
import './styles.scss';


const amountValidator = addValidator({
  validator(options, value) {
    if (value <=0 && options.alternative <=0){
      return {
        id: 'data.attributes.metadata.ledger_entries',
        defaultMessage: 'Either debit or credit must be set',
      };
    }
    if (value > 0 && options.alternative >0){
      return {
        id: 'data.attributes.metadata.ledger_entries',
        defaultMessage: 'Both debit and credit cant be set',
      };
    }

  }
});
const Remove = ({ show, index, onRemove }) => {
  if (!show) {
    return null;
  }
  const remove = () => {
    onRemove(index);
  };


  return (
    <span className="remove-entry"
          onClick={remove}>
      <Icon name="times"/>
    </span>
  );
};
const Journal = ({ fields, entryType, values }) => {

  const remove = (index) => {
    fields.remove(index);
  };
  const add = () => {
    fields.push(JOURNAL_DEFAULTS);
  };


  return (
    <div>
      {fields.map((entry, index) => {
        return (
          <div key={index} className="journal-ledger-entry">
            <FormSection name={entry}>
              <div className="journal-ledger-entry-header">

                <Remove
                  show={index > 1}
                  onRemove={remove}
                  index={index}
                />


                <Row>
                  <Col xs={4}>
                    <Field
                      async
                      key={'select-account' + index}
                      name={'account'}
                      sort={'name'}
                      label="Account"
                      id={index}
                      labelSize={4}
                      component={SelectAccount}
                      validate={required({ msg: 'Account is required' })}
                    />
                  </Col>
                  <Col xs={4}>
                    <Field
                      async
                      key={'select-broker' + index}
                      name={'broker'}
                      label="Broker"
                      labelSize={3}
                      isClearable
                      component={SelectBroker}
                    />
                  </Col>
                  <Col xs={4}>
                    <Field
                      name={'product'}
                      label="Product"
                      labelSize={3}
                      filters={[
                        { filter: 'sort', value: 'name' },
                      ]}
                      isClearable
                      component={SelectProduct}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8}>
                    <Field
                      name={'description'}
                      label="Description"
                      labelSize={2}
                      component={HorizontalFormControl}
                      validate={required({ msg: 'Description is required' })}
                    />
                  </Col>
                  {entryType === 'premium' && (
                    <Col xs={4}>
                      <Field
                        name={'premium_element_type'}
                        label="Premium Element"
                        labelSize={3}
                        options={PREMIUM_ELEMENT_TYPES}
                        component={SelectField}
                      />
                    </Col>
                  )}
                </Row>
              </div>
              <div className="journal-ledger-entry-amounts">
                <Row>
                  <Col xs={4}>
                    <Field
                      name={'currency'}
                      label="Currency"
                      labelSize={3}
                      defaultValue={{ value: 'GBP', label: 'Pound sterling' }}
                      component={SelectCurrency}
                    />
                  </Col>
                  <Col xs={4}>
                    <Field
                      name={'debit'}
                      label="Debit"
                      labelSize={3}
                      currency
                      component={HorizontalFormControl}
                      validate={amountValidator({ alternative: values?.[index]?.credit ?? 0 })}
                    />
                  </Col>
                  <Col xs={4}>
                    <Field
                      name={'credit'}
                      label="Credit"
                      labelSize={3}
                      currency
                      component={HorizontalFormControl}
                      validate={amountValidator({ alternative: values?.[index]?.debit ?? 0 })}
                    />
                  </Col>
                </Row>
              </div>
            </FormSection>

          </div>
        );
      })}
      <div className="actions">
        <Button
          bsStyle="primary"
          size="xs"
          className="pull-right"
          label="Add a new line"
          handleClick={add}
        />
      </div>
    </div>
  );
};

export default Journal;

import React from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import SchemeDropdownActions from '../SchemeDropdownActions/SchemeDropdownActions';

import './SchemeHeader.scss';

const SchemeHeader = ({
  defaultActiveScheme,
  activeScheme,
  schemes,
  onSelectScheme,
  //onNewVersion,
  onRefreshPage,
  baseUrl,
}) => {
  const handleSelectScheme = (schemeId) => {
    const newActive = schemes.find((scheme) => scheme.id === schemeId);
    onSelectScheme(newActive);
  };

  const currentActiveScheme = activeScheme ? activeScheme : defaultActiveScheme;
  const scheme = schemes.find((scheme) => {
    return scheme.id === activeScheme.id;
  });

  const displayActiveScheme = scheme ? scheme : currentActiveScheme;

  const schemeVersion = activeScheme.attributes.version;
  const schemeStatus = activeScheme.attributes.status;
  const schemeName = activeScheme.attributes.name;


  return (
    <div className="scheme-header">
      <div className="product-title">
        <h3>{displayActiveScheme.attributes.name}</h3>
      </div>
      <div className={'scheme-header--dropdowns'}>
        <div className="scheme-header--version-dropdown">
          <DropdownButton
            pullRight
            bsStyle={'default'}
            id={'split-button-basic'}
            title={`v${schemeVersion} - ${schemeStatus}`}
            className={`status-${schemeStatus}`}
          >
            {schemes?.map((scheme) => {
              return (
                <MenuItem
                  key={scheme.id}
                  eventKey={scheme.id}
                  active={displayActiveScheme.id === scheme.id}
                  onSelect={handleSelectScheme}
                >
                  <div className="scheme-header--dropdown-item">
                    <div className={'scheme-header--dropdown-item-title'}>
                      {`${scheme.attributes.name} - v${scheme.attributes.version}`}
                    </div>
                    <div className={`dropdown-item-status ${scheme.attributes.status}`}>
                      {scheme.attributes.status}
                    </div>
                  </div>
                </MenuItem>
              );
            })}
          </DropdownButton>
        </div>
        <div className="scheme-actions-dropdown">
          <SchemeDropdownActions
            schemeId={scheme.id}
            status={schemeStatus}
            name={schemeName}
            version={schemeVersion}
            activeScheme={scheme}
            onRefreshPage={onRefreshPage}
            baseUrl={baseUrl}
            // onNewVersion={onNewVersion}
          />
        </div>
      </div>
    </div>
  );
};

export default SchemeHeader;

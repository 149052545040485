import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { TextField } from '../../../../common/components/ReduxFormField';
import { HorizontalFormControl, Icon } from '../../../../common/components';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import { getPremiumOverrideCalculation } from '../../redux/productActions';
import { Row, Col } from 'react-bootstrap';
import { formValueSelector } from 'redux-form';
import { get, isEqual, debounce, isEmpty } from 'lodash';
import './styles.scss';

class PremiumAdjust extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { formValuesParent, scheme } = this.props;
    const oldOptions = get(formValuesParent, 'data.attributes.metadata.options');
    const newOptions = get(
      nextProps.formValuesParent,
      'data.attributes.metadata.options',
    );

    if (!isEqual(oldOptions, newOptions)) {
      this.props.dispatch(getPremiumOverrideCalculation(scheme, 'LeisureTravelForm'));
    }
  }

  componentWillMount() {
    const { open } = this.props;
    if (open) {
      this.setState({ open: open });
    }
    this.premiumWillUpdate = debounce(this.premiumWillUpdate, 2000);
  }

  componentDidMount() {
    const { product } = this.props;
    const productCode = get(product, 'productCode', 'FST');

    if (!['ADV', 'PST'].includes(productCode)) {
      this.updatePremium(this.props);
    }
  }

  componentWillUpdate(nextProps) {
    if (!isEqual(nextProps.formValues, this.props.formValues) && !isEmpty(get(nextProps, 'formValues.data.attributes.metadata.overrides', {}))) {
      this.premiumWillUpdate(nextProps);
    }
  }

  /**
   * De-bounced premium update method
   * @param nextProps
   */
  premiumWillUpdate(nextProps) {
    this.updatePremium(nextProps);
  }

  updatePremium(nextProps) {
    const { scheme, product } = this.props;
    const productCode = get(product, 'productCode', '');
    const overrideScheme = get(product, 'schemes[0]', '');

    if (nextProps.formName && scheme) {
      this.props.dispatch(getPremiumOverrideCalculation(scheme, nextProps.formName));
    }

    if (['TUMC', 'ADV', 'FPS', 'NPO', 'CSPA', 'CSS'].includes(productCode) && nextProps.formName && overrideScheme) {
      this.props.dispatch(getPremiumOverrideCalculation(overrideScheme, nextProps.formName));
    }
    this.props.reCalculateForAdjustments(); // Call back to Update the Parent (SideBar)
  }

  getCommission(commissions, type) {
    let values = { rate: 0, amount: 0 };
    commissions.forEach((commission) => {
      if (commission.commission_type === type) {
        values.amount = commission.amount.toFixed(2);
        values.rate = (get(commission, 'effective_rate', commission.rate) * 100).toFixed(
          2,
        );
      }
    });
    return values;
  }

  render() {
    const {
      baseName,
      premiumOverrideCalculation,
      user,
      product,
      showDiscount,
      taxType,
    } = this.props;
    const broker = getIncludedResource(user.data, user.included, 'current_broker');
    const adminFee = get(premiumOverrideCalculation, 'meta.premium.fees', 0)
      .toFixed(2)
      .toString();
    // Return empty if no current broker is set
    if (broker.data.id || (Array.isArray(broker.data) && broker.data.length > 0)) {
      return <div/>;
    }
    const productCode = get(product, 'productCode', '');
    const sportProduct = ['ADV', 'PST'].includes(productCode);
    let gross = 0;
    let ipt = 0;

    if (sportProduct) {
      const schemeId = get(product, 'schemes[0].id', '');
      const sportQuotes = get(product, `quotes.${schemeId}`, '');

      gross = get(sportQuotes, 'meta.premium.gross', 0);
      ipt = get(sportQuotes, 'meta.premium.tax', 0);
    }

    return (
      <div className="premium-adjust">
        <h4
          className="toggle-display"
          onClick={() => this.setState((prevState) => ({ open: !prevState.open }))}
        >
          Premium Adjustment
          <div className={this.state.open ? 'chevron-icon rotate ' : 'chevron-icon'}>
            <Icon name="chevron-down"/>
          </div>
        </h4>

        {this.state.open && (
          <Row className="premium-adjust-form fadein-fields-premium">
            <Col sm={6}>
              <Field
                name={baseName + '.commission_broker'}
                label="Broker %"
                labelSize={4}
                percentage
                placeholder={this.getCommission(
                  get(premiumOverrideCalculation, 'meta.commissions', []),
                  'broker',
                ).rate.toString()}
                monetaryValue={this.getCommission(
                  get(premiumOverrideCalculation, 'meta.commissions', []),
                  'broker',
                ).amount.toString()}
                component={HorizontalFormControl}
                isCalc={product.isPremiumAdjusting}
              />
              <Field
                name={baseName + '.commission_rate'}
                label="Total %"
                labelSize={4}
                percentage
                placeholder={get(
                  premiumOverrideCalculation,
                  'meta.rates.effective_commission_rate',
                  get(premiumOverrideCalculation, 'meta.rates.commission_rate', 0),
                )
                  .toFixed(2)
                  .toString()}
                monetaryValue={get(
                  premiumOverrideCalculation,
                  'meta.premium.commission',
                  0,
                ).toString()}
                component={HorizontalFormControl}
                isCalc={product.isPremiumAdjusting}
              />
              <Field
                name={baseName + '.net'}
                label="Net"
                labelSize={4}
                currency
                placeholder={get(premiumOverrideCalculation, 'meta.premium.net', 0)
                  .toFixed(2)
                  .toString()}
                component={HorizontalFormControl}
                isCalc={product.isPremiumAdjusting}
                className="text-right"
              />
              <Field
                name={'data.attributes.metadata.fees.admin_fee'}
                label="Admin Fee"
                labelSize={4}
                currency
                placeholder={adminFee}
                component={HorizontalFormControl}
                isCalc={product.isPremiumAdjusting}
                className="text-right"
              />
            </Col>

            <Col sm={6}>
              <Field
                name={baseName + '.commission_owner'}
                label="PJH %"
                labelSize={4}
                percentage
                placeholder={this.getCommission(
                  get(premiumOverrideCalculation, 'meta.commissions', []),
                  'owner',
                ).rate.toString()}
                monetaryValue={this.getCommission(
                  get(premiumOverrideCalculation, 'meta.commissions', []),
                  'owner',
                ).amount.toString()}
                component={HorizontalFormControl}
                isCalc={product.isPremiumAdjusting}
              />

              <HorizontalFormControl
                disabled
                label={taxType}
                labelSize={4}
                input={{ value: '' }}
                percentage
                placeholder={get(premiumOverrideCalculation, 'meta.rates.tax_rate', 0)
                  .toFixed(2)
                  .toString()}
                monetaryValue={!sportProduct ? get(
                  premiumOverrideCalculation,
                  'meta.premium.tax',
                  0,
                ).toString() : ipt.toFixed(2).toString()}
                isCalc={product.isPremiumAdjusting}
              />
              <div className="total-gross-override">
                <Field
                  name={baseName + '.gross_premium'}
                  label={<span>Gross</span>}
                  labelSize={4}
                  currency
                  placeholder={!sportProduct ? get(premiumOverrideCalculation, 'meta.premium.gross', 0)
                    .toFixed(2)
                      .toString()
                    : gross.toFixed(2).toString()}
                  component={HorizontalFormControl}
                  isCalc={product.isPremiumAdjusting}
                  className="text-right"
                />
              </div>
              <div className="fix-label-width">
                <Field
                  name={'data.attributes.metadata.fees.agency_fee'}
                  label="Broker Admin Fee"
                  labelSize={4}
                  // mdFieldSize={3}
                  currency
                  placeholder={'0'}
                  //placeholder={get(premiumOverrideCalculation, 'meta.premium.net', 0).toFixed(2).toString()}
                  component={HorizontalFormControl}
                  isCalc={product.isPremiumAdjusting}
                  className="text-right crazy"
                />
              </div>
            </Col>
            <Col sm={12}>
              {showDiscount && (
                <div className="discount">
                  <Field
                    name={baseName + '.discount_rate'}
                    label={<span>Discount</span>}
                    labelSize={6}
                    percentage
                    placeholder={get(
                      premiumOverrideCalculation,
                      'meta.premium.discount_rate',
                      0,
                    )
                      .toFixed(2)
                      .toString()}
                    component={HorizontalFormControl}
                    isCalc={product.isPremiumAdjusting}
                    className="text-right"
                  />
                </div>
              )}

              <Field
                className="premium-textarea"
                name={baseName + '.note'}
                placeholder="Reason for override..."
                type="textarea"
                component={TextField}
              />
            </Col>
            {/*<span className="please-note-mssg"><strong>Please note:</strong> the reason given above is for internal purposes only, please
          add an endorsement to the policy if required.</span>*/}
          </Row>
        )}
      </div>
    );
  }
}

PremiumAdjust.propTypes = {
  baseName: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  formValues: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  scheme: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  showDiscount: PropTypes.bool,
  taxType: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    product: state.product,
    user: state.auth.user,
    premiumOverrideCalculation: state.product.premiumOverrideCalculation,
    formValues: formValueSelector(props.formName)(
      state,
      'data.attributes.metadata.overrides',
      {},
    ),
  };
};
export default connect(mapStateToProps)(PremiumAdjust);

import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Button } from '../../../../common/components';

import NewDocumentForm from './NewDocumentForm';

const NewDocumentModal = ({
  schemes,
  onAddDocument,
  isSuperseding,
  documentTypes,
  documentTypeId,
  isAncillarySchemeDocument,
  getAncillaryFormInfo,
}) => {
  const [showModal, setShowModal] = useState(false);

  const showDocumentModal = () => {
    if (isAncillarySchemeDocument) {
      return Promise.resolve(getAncillaryFormInfo()).then(() => {
        setShowModal(true);
      });
    } else {
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleAddDocument = (formValues) => {
    return Promise.resolve(onAddDocument(formValues)).then(() => {
      closeModal();
    });
  };

  const buttonText = isAncillarySchemeDocument
    ? 'Add a new document'
    : 'Add a new scheme document';
  return (
    <div className={'new-document-modal'}>

      <Button bsStyle="primary" className="pull-right" handleClick={showDocumentModal}>
        {buttonText}
      </Button>

      <Modal
        show={showModal}
        bsSize="large"
        onHide={closeModal}
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title">New Scheme Document</Modal.Title>
        </Modal.Header>
        {showDocumentModal && (
          <NewDocumentForm
            schemes={schemes}
            onAddDocument={handleAddDocument}
            isSuperseding={isSuperseding}
            documentTypesList={documentTypes}
            isAncillarySchemeDocument={isAncillarySchemeDocument}
            documentTypeId={documentTypeId}
          />
        )}
      </Modal>
    </div>
  );
};

export default connect(
  (state, props) => {
    return {
      documentTypes: props.documentTypesList?.filter((documentType) =>
        [
          'Fact Find',
          'Information',
          'COVID-19',
          'Insurance Product Information Document',
          'Policy Summary',
          'Policy Wording',
          'Statement of Fact',
          'Terms of Business Customer Agreement',
          'Virtual Medical Care',
        ].includes(documentType.attributes?.name),
      ),
    };
  },
  null,
)(NewDocumentModal);

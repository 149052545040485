import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field } from 'redux-form';
import confirm from 'react-confirm2';
import { Button } from '../../../../../common/components';
import SubBenefitField from './SubBenefitField';

const SubBenefitsFieldArray = ({ isViewOnly, fields, options, coverLevels }) => {
  const handleRemoveBenefit = (index) => {
    confirm('Warning', {
      description: 'Are you sure you want to remove this benefit?',
      done: () => {
        fields.remove(index);
      },
    });
  };

  const handleAddSubBenefit = () => {
    if (coverLevels) {
      const coverLevelMap = coverLevels.reduce((carry, coverLevel) => {
        carry[coverLevel] = '';
        return carry;
      }, {});
      fields.push({
        name: '',
        cover_level: coverLevelMap,
        text: '',
        excess: '',
      });
    } else {
      fields.push({
        name: '',
        text: '',
        option: '',
        excess: '',
      });
    }
  };
  return (
    <>
      {!fields.length && <div>No Sub-benefits</div>}

      {fields.map((field, index) => {
        return (
          <Row className={'benefit-row'}>
            <Field
              name={field}
              index={index}
              component={SubBenefitField}
              isViewOnly={isViewOnly}
              onRemove={handleRemoveBenefit}
              options={options}
            />
          </Row>
        );
      })}
      <Row>
        <Col sm={12}>
          {!isViewOnly && (
            <Button
              size="xs"
              bsStyle="primary"
              label={fields.length > 0 ? 'Add another Sub Benefit' : 'Add a Sub Benefit'}
              className={fields.length > 0 ? 'pull-right ' : 'margin-top'}
              handleClick={handleAddSubBenefit}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default SubBenefitsFieldArray;

import * as actions from './contentReducer';
import Axios from 'axios';
import queryString from '../../../helpers/queryString';
import { push } from 'react-router-redux';
import handleErrorResponse from '../../../helpers/handleErrorResponse';
import { toastr } from 'react-redux-toastr';
import { ADD_RESOURCE } from '../../layout/redux/breadcrumb';
import capitalize from 'react-bootstrap/lib/utils/capitalize';

export function isUpdatingKeyPersonnel(status) {
  return {
    type: actions.IS_UPDATING_KEY_PERSONNEL,
    status,
  };
}

export function isUpdatingPartners(status) {
  return {
    type: actions.IS_UPDATING_PARTNERS,
    status,
  };
}

export function isFetchingSignPosts(status) {
  return {
    type: actions.IS_FETCHING_SIGNPOSTS,
    status,
  };
}

/**
 * Creates a new content resource
 * @param resource
 * @param includes
 * @returns {function(*, *)}
 */
export function createContent(resource, includes = ['template', 'files']) {
  return (dispatch) => {
    const endpoint = 'contents/' + queryString(includes);

    return Axios.post(endpoint, resource)
      .then((response) => {
        dispatch({
          type: actions.SET_CONTENT,
          data: response.data,
        });

        dispatch(push('/admin/content/' + response.data.data.id));
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'There was an error creating your content');
      });
  };
}

/**
 * Loads the content for the requested id
 * @param id
 * @param includes
 * @returns {function(*, *)}
 */
export function getContent(id, includes = ['template', 'files', 'products']) {
  return (dispatch) => {
    const endpoint = 'contents/' + id + queryString(includes);

    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: actions.SET_CONTENT,
          data: response.data,
        });
        dispatch({
          type: ADD_RESOURCE,
          id: response.data.data.id,
          name: response.data.data.attributes.name,
        });
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

/**
 * Updates a content resource
 * @param resource
 * @param includes
 * @returns {{type, data: *}}
 */
export function updateContent(resource, includes = ['template', 'files']) {
  return (dispatch) => {
    const endpoint = 'contents/' + resource.data.id + queryString(includes);

    return Axios.patch(endpoint, resource)
      .then((response) => {
        dispatch({
          type: actions.SET_CONTENT,
          data: response.data,
        });

        dispatch(push('/admin/content/' + response.data.data.id));
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'There was an error updating your content');
      });
  };
}

/**
 * Loads the template for the requested type
 * @param type
 * @returns {{type, data: *}}
 */
export function getTemplate(type) {
  const endpoint = 'templates?filter[template_type]=' + type;
  return Axios.get(endpoint)
    .then((response) => {
      return response.data.data[0];
    })
    .catch((error) => {
      console.error(error);
    });
}

/**
 * Loads the content for the key personnel
 * @returns {{type, data: *}}
 */
export function getKeyPersonnel() {
  return (dispatch) => {
    const endpoint = 'key-personnel?page[limit]=500&include=user.avatar';

    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: actions.SET_KEY_PERSONNEL,
          data: response.data.data,
        });
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

/**
 * Updates the key personnel
 * @param resource
 * @returns {{type, data: *}}
 */
export function updateKeyPersonnel(resource) {
  return (dispatch) => {
    const endpoint = 'key-personnel?page[limit]=500&include=user.avatar';
    dispatch(isUpdatingKeyPersonnel(true));

    return Axios.post(endpoint, resource)
      .then((response) => {
        dispatch({
          type: actions.SET_KEY_PERSONNEL,
          data: response.data.data,
        });
        dispatch(isUpdatingKeyPersonnel(false));
        toastr.success(
          'Key Personnel Updated',
          'Key personnel has been successfully updated',
        );
        return true;
      })
      .catch((error) => {
        dispatch(isUpdatingKeyPersonnel(false));
        handleErrorResponse(error, 'There was an error updating your content');
      });
  };
}

/**
 * Loads the content for the partners
 * @returns {{type, data: *}}
 */
export function getPartners() {
  return (dispatch) => {
    const endpoint = 'partners?page[limit]=500';

    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: actions.SET_PARTNERS,
          data: response.data.data,
        });
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

/**
 * Updates the partners
 * @param resource
 * @returns {{type, data: *}}
 */
export function updatePartners(resource) {
  return (dispatch) => {
    const endpoint = 'partners?page[limit]=500';
    dispatch(isUpdatingPartners(true));

    return Axios.post(endpoint, resource)
      .then((response) => {
        dispatch({
          type: actions.SET_PARTNERS,
          data: response.data.data,
        });
        dispatch(isUpdatingPartners(false));
        toastr.success('Partners Updated', 'Partners has been successfully updated');
        return true;
      })
      .catch((error) => {
        dispatch(isUpdatingPartners(false));
        handleErrorResponse(error, 'There was an error updating your content');
      });
  };
}

export function getSignposts() {
  return (dispatch) => {
    dispatch(isFetchingSignPosts(true));
    const endpoint = 'signposts?filter[status]=active';
    return Axios.get(endpoint)
      .then((response) => {
        return response.data.data;
      })
      .finally(() => {
        dispatch(isFetchingSignPosts(false));
      });
  };
}

export function getSignpost(id) {
  return (dispatch) => {
    dispatch(isFetchingSignPosts(true));
    const endpoint = `signposts/${id}`;
    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: ADD_RESOURCE,
          id: response.data.data.id,
          name: capitalize(response.data.data.attributes.name.replaceAll('_', ' ')),
        });
        return response.data;
      })
      .finally(() => {
        dispatch(isFetchingSignPosts(false));
      });
  };
}

export function updateSignpost(id, values) {
  return (dispatch) => {
    dispatch(isFetchingSignPosts(true));
    const endpoint = `signposts/${id}`;
    return Axios.patch(endpoint, values)
      .then((response) => {
        dispatch(push(`/admin/content/signpost/${response.data.data.id}/edit`));
      })
      .finally(() => {
        dispatch(isFetchingSignPosts(false));
      });
  };
}

import AccountMatching from '../AccountMatching/AccountMatching';
import React from 'react';
import { Loader } from '../../../../common/components';

const MatchingContainer = ({
  accountId,
  resource,
  transactions,
  matchedTransactions,
  premiums,
  matchedPremiums,
  transactionFilter,
  premiumFilter,
  onReset,
  onRefresh }) => {
  if (!!resource && !!transactions && !!premiums) {
    return (
      <AccountMatching
        key={'matching' + accountId}
        resource={resource}
        transactions={transactions}
        matchedTransactions={matchedTransactions}
        premiums={premiums}
        matchedPremiums={matchedPremiums}
        transactionFilter={transactionFilter}
        premiumFilter={premiumFilter}
        onReset={onReset}
        onRefresh={onRefresh}
      />
    );
  }

  if (!accountId) {
    return (
      <h1 className="text-center">Select an account to get started</h1>
    );

  }
  return (<Loader noShadow />);



};
export default MatchingContainer;

export const JOURNAL_DEFAULTS = {
  account: '',
  description: '',
  currency: 'GBP',
  debit: 0,
  credit: 0,
  broker: '',
  product: '',
  premium_element_type: '',
};

export const PREMIUM_ELEMENT_TYPES = [
  { label: 'Net', value: 'net' },
  { label: 'Additional', value: 'additional' },
  { label: 'Medical', value: 'medical' },
  { label: 'Fees', value: 'fees' },
  { label: 'Discount', value: 'discount' },
  { label: 'Commission', value: 'commission' },
  { label: 'Tax', value: 'tax' },
  { label: 'Tax Exclusive', value: 'ex_tax' },
];

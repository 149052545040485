import React from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import {
  Button,
  HorizontalFormControl, InfoButton,
  InfoLabel
} from '../../../../../common/components';
import UploadCropComponent from '../../../../../common/components/Upload/UploadCrop';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { triggerFileUpload } from '../../../../../common/components/Upload/UploadRedux';

import { addInsurer, updateInsurer } from '../../../redux/organisationActions';
const infoButtonMessages = {
  insurerCode:
    'Unique Identifier applied behind the scenes, required for Brand set up (cannot be edited)',
  brandContact: 'Reference for Brand contract (optional)',
  maxCommission:
    'Set Maximum brokerage allowed - will apply to new schemes; note this is only max limit, you can adjust the commission per scheme level accordingly, permitting it is under this amount',
  carryBroker:
    " Determines whether Brokers can hold client money (make payment on account, by cash or cheque), also needs to be agreed on an individual Broker basis (within the broker set up 'Hold client money')",
};
const Logo = ({ logo }) => (
  <Row>
    <Col xs={4}>
      <label className="control-label">Insurer logo</label>
      &nbsp;
      <InfoButton
        content={
          ' Upload Brand logo, which will be applied to any schemes associated with this Brand imminently (impacting Scheme selection'
        }
      />
    </Col>
    <Col xs={8}>
      {!logo? (<>No logo set</>):( <img src={logo} alt={'logo'}/>)}

    </Col>
  </Row>
);
const EditBrandDetailsForm = ({
  handleSubmit,
  submitting,
  onTriggerFileUpload,
  onAddInsurer,
  onUpdateInsurer,
  onUpdateComplete,
  formValues,
  dispatch,
  change

}) => {
  // getFieldName = () => {
  //   const { isNew, context } = this.props;
  //   if (context === 'brandDetails') {
  //     return 'data.id';
  //   }
  //   if (context === 'organisation') {
  //     return isNew ? 'data.attributes.code' : 'data.id';
  //   }
  // };

  const handleUpload = () => {

    onTriggerFileUpload();

  };

  const handleUploadComplete = (data) => {



    dispatch(change('data.relationships.logo_file.data', data.data));
    dispatch(change('data.attributes.logo', data.data.attributes.url));
  };
  const isNew = !formValues?.data?.id;


  const save = (values) => {

    if (!!values?.data?.id) {

      return Promise.resolve(onUpdateInsurer(values)).then(() => {
        onUpdateComplete(null);
      });
    }

    return Promise.resolve(onAddInsurer(values)).then(() => {
      onUpdateComplete(null);
    });
  };


  return (
    <Form onSubmit={handleSubmit(save)}>
      <Row>
        <Col xs={12}>
          <Field
            labelSize={4}
            label="Insurer Code"
            name={'data.attributes.code'}
            infoButton={<InfoButton content={infoButtonMessages.insurerCode}/>}
            component={isNew ? HorizontalFormControl : InfoLabel}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Field
            labelSize={4}
            label="Name"
            name="data.attributes.name"
            component={HorizontalFormControl}
          />
        </Col>
      </Row>

      <Logo logo={formValues?.data?.attributes?.logo}/>
      <UploadCropComponent
        onComplete={handleUploadComplete}
        width={355}
        height={238}
        bsSize="lg"
        circle={false}
        minScale={30}
      />
      <Modal.Footer>

        <div>
          <Button
            type="button"
            bsStyle="primary"
            className="pad-right bottom-margin-0"
            disabled={submitting}
            handleClick={handleUpload}
          >
            {submitting ? (
              <i className="fa fa-cog fa-spin"/>
            ) : (
              <i className="fa fa-image"/>
            )}{' '}
            upload logo
          </Button>
          <Button
            type="submit"
            bsStyle="primary"
            disabled={submitting}
            isLoading={submitting}
            label="Save"
          />
        </div>
      </Modal.Footer>
    </Form>
  );
};

const FORM_NAME = 'EditBrandDetailsForm';


export default compose(
  connect(
    (state, ownProps) => ({

      formValues: getFormValues(FORM_NAME)(state),
      initialValues: ownProps.resource
    }),
    {
      onTriggerFileUpload: triggerFileUpload,
      onAddInsurer: addInsurer,
      onUpdateInsurer: updateInsurer,
    }
  ),
  reduxForm({ form: FORM_NAME })
)(EditBrandDetailsForm);


import React from 'react';
import { get } from 'lodash';
import './SimpleCheckboxControl.scss';

const CheckboxControl = ({
                             input,
                             positionTop,
                             positionMid,
                             label,
                             disabled,
                             options,
                             objectKey,
                             inline,
                             width,
                             meta: { touched, error },
                           }) => {


  let inputClass = [
    'form-radio',
    positionTop && 'positionTop',
    disabled && 'checkbox-disabled',
    positionMid && 'positionMid',
  ];

  return (
    <div className={touched && error ? 'has-error' : ''}>
      <div className={'radio-group'}>
        <div>
          {options.map((option, key) => {
            const value = objectKey ? get(option.value, objectKey) : option.value;
            return (
              <div
                className={'simple-radio-container' + (inline ? ' inline' : '')}
                key={key}
                style={width ? { width: width } : {}}
              >
                <div className={inputClass.join(' ')}>
                  <input
                    disabled={disabled || false}
                    {...input}
                    type="checkbox"
                    id={input.name + option.label}
                    value={option.value}
                    checked={input.value.toString() === value.toString()}
                  />
                  <label
                    htmlFor={input.name + option.label}
                    className={disabled ? 'cursor-disabled' : ''}
                  />
                </div>
                <div
                  className="radio-label"
                  onClick={() => input.onChange(option.value)}
                >
                  {label}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CheckboxControl;

import React from 'react';
import { Field } from 'redux-form';
import {
  ProductSchemeGroupField
} from '../ProductSchemeGroupField/ProductSchemeGroupField';

export const ProductSchemeGroupFieldArray = ({ fields }) => {
  return (
    <>
      {fields.map((field) => {
        return (
          <>
            <Field
              name={field}
              field={field}
              component={ProductSchemeGroupField}
            />
            <div className="hr"/>
          </>
        );
      })}

    </>
  );
};

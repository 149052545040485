import * as actions from './accountReducer';
import Axios from 'axios';
import { push } from 'react-router-redux';
import { toastr } from 'react-redux-toastr';
import queryString from '../../../helpers/queryString';
import handleErrorResponse from '../../../helpers/handleErrorResponse';
import { ADD_RESOURCE } from '../../layout/redux/breadcrumb';
import moment from 'moment';

const defaultIncludes = ['organisation', 'organisation.accounts_contacts'];
const CancelToken = Axios.CancelToken;
let cancelGetPremiums;
let cancelGetTransactions;

export function clearAccount() {
  return {
    type: actions.CLEAR_ACCOUNT,
  };
}

export function clearPremiums() {
  return {
    type: actions.CLEAR_PREMIUMS,
  };
}

export function clearTransactions() {
  return {
    type: actions.CLEAR_TRANSACTIONS,
  };
}

export function isSubmitting(status) {
  return {
    type: actions.IS_SUBMITTING,
    status,
  };
}

export function isFetchingPremiums(status) {
  return {
    type: actions.IS_FETCHING_PREMIUMS,
    status,
  };
}

export function isFetchingTransactions(status) {
  return {
    type: actions.IS_FETCHING_TRANSACTIONS,
    status,
  };
}

export function isFetchingAdjustmentsAndBalancing(status) {
  return {
    type: actions.IS_FETCHING_ADJUSTMENTS_AND_BALANCING,
    status,
  };
}


export function isGeneratingReport(status) {
  return {
    type: actions.IS_GENERATING_REPORT,
    status,
  };
}

export function isSuspending(status) {
  return {
    type: actions.IS_SUSPENDING,
    status,
  };
}

export function isReinstating(status) {
  return {
    type: actions.IS_REINSTATING,
    status,
  };
}

export function isCommissionRecalculating(status) {
  return {
    type: actions.IS_COMMISSION_RECALCULATING,
    status,
  };
}

export function setCommissionReallocation(data) {
  return {
    type: actions.SET_COMMISSION_REALLOCATION,
    data,
  };
}

export function clearCommissionReallocation() {
  return {
    type: actions.CLEAR_COMMISSION_REALLOCATION,
  };
}

export function getSummaries(includes = '') {
  return (dispatch) => {
    let endpoint = 'accounts/ledger-entries/summary';
    endpoint += queryString(includes);

    dispatch(isSuspending(true));
    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: actions.SET_SUMMARIES,
          data: response.data.meta,
        });
        dispatch(isSuspending(false));
        return true;
      })
      .catch((error) => {
        console.error(error);
        dispatch(isSuspending(false));
      });
  };
}

export function getAccount(id, includes = defaultIncludes) {
  return (dispatch) => {
    let endpoint = 'accounts/' + id;
    endpoint += queryString(includes);

    dispatch(isSuspending(true));
    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: actions.SET_ACCOUNT,
          data: response.data,
        });
        dispatch({
          type: ADD_RESOURCE,
          id: response.data.data.id,
          name: response.data.data.attributes.name,
        });
        dispatch(isSuspending(false));
        return true;
      })
      .catch((error) => {
        dispatch(isSuspending(false));
        console.error(error);
      });
  };
}

export function getTransactions(accountId, filters = '', cachedTransactionIds) {
  if (cancelGetTransactions) {
    cancelGetTransactions();
  }

  return (dispatch) => {
    if (!filters.match(/(page\[limit]=0)/g)) {
      filters += 'page[limit]=0';
    }
    const endpoint =
      'accounts/' + accountId + '/payments-and-receipts' + (filters ? '?' + filters : '');

    dispatch(isFetchingTransactions(true));
    dispatch(clearTransactions());
    return Axios.get(
      endpoint,
      {
        cancelToken: new CancelToken(function executor(c) {
          cancelGetTransactions = c;
        })
      }
    ).then((response) => {
      if (response) {

        const transactions = cachedTransactionIds?.length ? response.data.data?.filter((transaction) => !cachedTransactionIds.includes(transaction.id) ) : response.data.data;

        dispatch({
          type: actions.SET_TRANSACTIONS,
          data: { accountId: accountId, data: transactions },
        });
      }
      dispatch(isFetchingTransactions(false));
      return true;
    }).catch((error) => {
      dispatch(isFetchingTransactions(false));
      handleErrorResponse(error, 'Error getting transactions');
      // console.error(error);
    });
  };
}

export function cacheTransactions(transactions) {
  return (dispatch) => {
    dispatch({
      type: actions.CACHE_MATCHED_TRANSACTIONS,
      data: transactions,
    });
  };
}

export function getPremiums(accountId, premiumType = 'net-premiums', filters = '') {
  if (cancelGetPremiums) {
    cancelGetPremiums();
  }

  return (dispatch) => {
    dispatch({
      type: actions.SET_PREMIUM_TYPE,
      premiumType: premiumType,
    });

    if (!filters.match(/(page\[limit]=0)/g)) {
      filters += 'page[limit]=0';
    }
    const endpoint = `accounts/${accountId}/${premiumType}?${filters}`;

    dispatch(isFetchingPremiums(true));
    dispatch(clearPremiums());
    return Axios.get(
      endpoint,
      {
        cancelToken: new CancelToken(function executor(c) {
          cancelGetPremiums = c;
        }),
      },
    ).then((response) => {
      if (response) {
        dispatch({
          type: actions.SET_PREMIUMS,
          premiumType: premiumType,
          data: { accountId: accountId, data: response.data.data },
          filters,
        });
      }
      dispatch(isFetchingPremiums(false));
      return true;
    }).catch((error) => {
      dispatch(isFetchingPremiums(false));
      handleErrorResponse(error, 'Error getting premiums');
      // console.error(error);
    });
  };
}


export function cachePremiums(premiums) {
  return (dispatch) => {
    dispatch({
      type: actions.CACHE_MATCHED_PREMIUMS,
      data: premiums,
    });
  };
}
export function cacheMatchedEntries(transactions, premiums) {
  return (dispatch) => {
    dispatch({
      type: actions.CACHE_MATCHED_ENTRIES,
      data: {
        premiums,
        transactions
      },
    });
  };
}

export function createAccount(resource) {
  return (dispatch) => {
    dispatch(isSubmitting(true));
    return Axios.post('accounts', resource)
      .then((response) => {
        dispatch(isSubmitting(false));
        dispatch(push('/accounts/ledger/' + response.data.data.id));
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'Account could not be created');
      });
  };
}

export function updateAccount(resource, includes = defaultIncludes) {
  return (dispatch) => {
    dispatch(isSubmitting(true));
    const id = resource.data.id;
    let endpoint = 'accounts/' + id;
    endpoint += queryString(includes);

    return Axios.patch(endpoint, resource)
      .then(() => {
        dispatch(isSubmitting(false));
        dispatch(push('/accounts/ledger/' + id));
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        console.error(error);
      });
  };
}

export function updateAccountingPeriod(resource, cb) {
  return (dispatch) => {
    const id = resource.data.id;
    let endpoint =
      'accounts/accounting-periods/' +
      id +
      ':' +
      (resource.data.attributes.open === 'true' ? 'unlock' : 'lock');
    return Axios.post(endpoint, resource)
      .then(() => {
        if (cb) {
          dispatch(cb());
        }
        toastr.success('Success', 'Accounting period updated');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Accounting period could not be updated');
      });
  };
}

export function suspendAccount(id) {
  return (dispatch) => {
    dispatch(isSuspending(true));
    return Axios.post('accounts/' + id + ':suspend', {
      data: {
        metadata: {
          send_email: false,
        },
      },
    })
      .then(() => {
        dispatch(getAccount(id)).then(() => {
          dispatch(isSuspending(false));
          toastr.success('Suspended', 'The account has now been suspended');
          return true;
        });
      })
      .catch((error) => {
        dispatch(isSuspending(false));
        handleErrorResponse(error, 'There was an error suspending this account');
      });
  };
}

export function reinstateAccount(id) {
  return (dispatch) => {
    dispatch(isReinstating(true));
    return Axios.post('accounts/' + id + ':reinstate', {
      data: {
        metadata: {
          send_email: false,
        },
      },
    })
      .then(() => {
        dispatch(getAccount(id)).then(() => {
          dispatch(isReinstating(false));
          toastr.success('Suspended', 'The account has now been re-instated');
          return true;
        });
        return true;
      })
      .catch((error) => {
        dispatch(isReinstating(false));
        handleErrorResponse(error, 'There was an error re-instating this account');
      });
  };
}

export function saveAccountMatching(accountId, matchedValues) {
  return (dispatch) => {
    dispatch(isSubmitting(true));
    const endpoint = `accounts/${accountId}:match`;
    return Axios.post(endpoint, matchedValues)
      .then(() => {
        toastr.success('Success', 'The premiums have been matched');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Account could not be updated');
      }).finally(() => {
        dispatch(isSubmitting(false));
      });
  };
}

export function savePaymentsAndReceipts(paymentsAndReceipts, accountId = '', filters) {
  return (dispatch) => {
    dispatch(isSubmitting(true));
    let endpoint = 'accounts/payments-and-receipts';
    return Axios.post(endpoint, paymentsAndReceipts)
      .then((resp) => {
        dispatch(isSubmitting(false));
        if (accountId === '') {
          dispatch(push('/accounts/bookkeeping'));
        } else {
          dispatch(getAccount(accountId));
          dispatch(getTransactions(accountId, filters));
        }
        return resp?.data?.meta;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'Error saving transactions');
      });
  };
}

export function postJournal(journal) {
  return (dispatch) => {
    let endpoint = 'accounts/journals?approve=true';
    return Axios.post(endpoint, journal)
      .then(() => {
        dispatch(push('/accounts/bookkeeping'));
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Error saving transactions');
      });
  };
}

export function postAdjustment(accountId, values, filters, cachedTransactions) {
  return (dispatch) => {

    dispatch(isSubmitting(true));
    let endpoint = 'accounts/' + accountId + '/transaction-adjustment';
    return Axios.post(endpoint, values)
      .then(() => {
        dispatch(isSubmitting(false));
        dispatch(getAccount(accountId));
        dispatch(getTransactions(accountId, filters, cachedTransactions));
        toastr.success('Success', 'Your adjustment has been saved');
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'Error saving transactions');
      });
  };
}

export function makePayment(values, accountId) {
  return (dispatch, getState) => {
    dispatch(isSubmitting(true));
    let endpoint = 'accounts/' + accountId + ':payment';

    return Axios.post(endpoint, values)
      .then(() => {
        dispatch(isSubmitting(false));
        dispatch({
          type: actions.SET_ACCOUNTING_REFERENCE,
          accountingReference: '',
        });
        toastr.success('Success', 'Your payment has been saved');
        return true;
      })
      .then(() => {
        const { premiumType, filters } = getState().account;

        return Promise.all([
          dispatch(getPremiums(accountId, premiumType, filters)),
          dispatch(getTransactions(accountId)),
        ]);
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'Error saving payment');
      });
  };
}

export function generateReport(values) {
  const _values = {
    ...values,
    from_date: moment(values.from_date).format('YYYY-MM-DD'),
    to_date: moment(values.to_date).format('YYYY-MM-DD'),
  };

  let filters = '';
  const keys = Object.keys(values);

  keys.map((key) => {
    filters ? (filters += '&') : (filters += '?');
    if (key === 'generator' && _values[key]) {
      filters += key + '=' + _values[key];
    } else {
      filters += 'params[' + key + ']=' + encodeURIComponent(_values[key]);
    }
  });

  return (dispatch) => {
    dispatch(isGeneratingReport(true));
    return Axios.get('reports' + filters)
      .then((response) => {
        dispatch(isGeneratingReport(false));
        if (response.status === 202) {
          toastr.success('The report request has been submitted and you will be notified once it has completed');
        } else if (window && response.data.meta.file_name) {
          window.open(response.data.meta.file_name, '_blank');
        }
        return true;
      })
      .catch((error) => {
        dispatch(isGeneratingReport(false));
        handleErrorResponse(error, 'Error generating report');
      });
  };
}

export function viewStatement(id) {
  return () => {
    return Axios.get('accounts/' + id + '/statement')
      .then((response) => {
        if (window && response.data.meta.file_name) {
          window.open(response.data.meta.file_name, '_blank');
        }
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Error generating statement');
      });
  };
}

export function getLedgerEntry(id, includes = ['account']) {
  return (dispatch) => {
    let endpoint = 'accounts/ledger-entries/' + id;
    endpoint += queryString(includes);

    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: actions.SET_LEDGER_ENTRY,
          data: response.data,
        });
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function unmatchBatch(id, reason) {
  return (dispatch) => {
    dispatch(isSubmitting(true));
    return Axios.delete('accounts/batches/' + id)
      .then(() => {
        dispatch(isSubmitting(false));
        toastr.success('Success', 'Batch has been un-matched');

        if (!!reason?.data) {
          return Axios.post('accounts/batches/' + id + '/notes', reason)
            .then(() => {
              return true;
            })
            .catch((error) => {
              handleErrorResponse(error, 'Error saving note');
            });
        }

        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'Error un-matching');
      });
  };
}

export function recalculateCommissions(id, commissions) {
  return (dispatch) => {
    dispatch(isCommissionRecalculating(true));
    return Axios.post('accounts/commissions/premiums/' + id + ':recalculate', commissions)
      .then((res) => {
        dispatch(setCommissionReallocation(res.data));
        dispatch(isCommissionRecalculating(false));
        return res.data.meta;
      })

      .catch((error) => {
        dispatch(isCommissionRecalculating(false));
        console.error(error);
      });
  };
}

export function reallocateCommissions(id, commissions) {
  return (dispatch) => {
    dispatch(isSubmitting(true));
    return Axios.post('accounts/commissions/premiums/' + id + ':reallocate', commissions)
      .then(() => {
        dispatch(isSubmitting(false));
        toastr.success('Success', 'Commission reallocation has been posted');
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'Error reallocating commission');
      });
  };
}

export function manageBulkMatching(id, date = null, reset = false, openWindow = true, queue = false) {
  return (dispatch) => {
    dispatch(isSubmitting(true));
    return Axios.post('accounts/' + id + (queue ? ':queue-bulk-matching' : ':bulk-matching'), {
      meta: {
        date: date,
        reset: reset,
      },
    })
      .then((response) => {
        dispatch(isSubmitting(false));
        if (response.status === 202) {
          toastr.success('Bulk matching sheet is being generated and a link will be emailed to you');
        } else if (openWindow && window && response.data.meta.url) {
          window.open(response.data.meta.url, '_blank');
        }
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'Failed to get bulk matching URL');
      });
  };
}

export function runBulkMatching(id, date = null, paymentDetails = {}) {
  return (dispatch, getState) => {
    dispatch(isSubmitting(true));
    return Axios.post('accounts/' + id + ':bulk-match', {
      meta: {
        ...paymentDetails,
        date: date,
      },
    })
      .then(() => {
        const { premiumType, filters } = getState().account;

        return Promise.all([
          dispatch(getPremiums(id, premiumType, filters)),
          dispatch(getTransactions(id)),
          dispatch(generateAccountingReference(true)),
        ]);
      })
      .then(() => {
        dispatch(isSubmitting(false));
        dispatch(manageBulkMatching(id, date, false, false));

        toastr.success('Success', 'Successfully ran bulk matching operation');
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'Unable to complete bulk matching operation');
      });
  };
}

export function generateAccountingReference(payment = false) {
  return (dispatch) => {
    return Axios.get('accounts/accounting-reference:generate?payment=' + payment).then(
      (response) => {
        dispatch({
          type: actions.SET_ACCOUNTING_REFERENCE,
          accountingReference: response.data.meta.accounting_reference,
        });
        return response.data.meta.accounting_reference;
      },
    );
  };
}

export function getPremiumCommissions(premiumId) {
  return () => {
    return Axios.get('/premium/commissions/' + premiumId).then((resp) => {
      return resp.data.meta;
    });

  };
}

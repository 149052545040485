import React from 'react';
import {
  DataTable,
  CreateNoteButton,
  NotesList,
} from '../../../../common/components';
import {
  refreshData
} from '../../../../common/components/DataTable/redux/dataTableActions';
import { Col, Row } from 'react-bootstrap';
import {
  InternalButtonBar
} from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import { connect } from 'react-redux';

const BrokerNotes = ({ resource, refreshData }) => {

  const refreshList = () => {
    refreshData('notes');
  };
  const brokerId = resource.data.id;

  return (
    <>

      <DataTable
        source={'/notes'}
        autoFilter={brokerId}
        autoFilterType="filter[resource]"
        name="notes"
        showTotal
        component={NotesList}
      />
      <Row>
        <Col sm={12}>
          <InternalButtonBar
            buttons={[
              {
                permissions: ['broker.notes_create'],
                button: (
                  <CreateNoteButton
                    label="Add a Note"
                    className="pull-right"
                    notableResource={resource}
                    notableResourceType="brokers"
                    callback={refreshList}
                  />
                ),
              },
            ]}
          />
        </Col>
      </Row>
    </>
  );
};

export default connect(null,
  {
    refreshData: refreshData
  })(BrokerNotes);

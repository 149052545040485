import React, { Component } from 'react';
import PropTypes from 'prop-types';

const transformUrl = true; // dev Flag

import { map, get, some } from 'lodash';

class UrlLabel extends Component {
  constructor(props) {
    super(props);
    this.renderTrackingLinks = this.renderTrackingLinks.bind(this);
    this.replaceQuestionMark = this.replaceQuestionMark.bind(this);
  }

  replaceQuestionMark(url) {
    let replaceQuestionMark = url.split('?');
    replaceQuestionMark[0] = '&';
    return replaceQuestionMark.join('');
  }

  renderTrackingLinks(brokers, urls) {
    return (
      <div>
        {map(urls, (attr) => {
          const getUrl = new URL(attr.url);

          const baseUrl = getUrl.origin;
          let restUrl = attr.url.split(baseUrl).join('');
          const replaced = this.replaceQuestionMark(restUrl);

          return map(brokers, (broker, key) => {
            // appending brokerId to the URL
            const newURL = `${baseUrl}?tracking=${broker.id}${replaced}`;
            return <li key={key} style={{ listStyleType: 'none' }}>{`${newURL}`}</li>;
          });
        })}
      </div>
    );
  }

  render() {
    const { urls, brokers } = this.props;
    const hasTrackingLinks = some(brokers, get('relationships.tracking_links.meta'));
    return (
      <div>
        <div>
          <label>Url(s): </label>
        </div>
        <div>
          <ul className="list-group">
            {hasTrackingLinks && transformUrl ? (
              <div>{this.renderTrackingLinks(brokers, urls)}</div>
            ) : (
              <div>
                {map(urls, (attr, key) => {
                  return (
                    <li key={key} style={{ listStyleType: 'none' }}>
                      {attr.url}
                    </li>
                  );
                })}
              </div>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

UrlLabel.propTypes = {
  urls: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default UrlLabel;

import React, { Component } from 'react';
import { Row, Col, FormGroup } from 'react-bootstrap';
import { HorizontalFormControl } from '../../../../common/components';
import { Field } from 'redux-form';
import { connect } from 'react-redux';

const min = (minimumNumber) => (value) => {
  const val = parseInt(value && value.replace(/\s/g, ''));
  return val && val >= minimumNumber ? null : 'Unable to remove employees';
};

class GroupTravellerNumbers extends Component {
  render() {
    const { formState } = this.props;

    return (
      <div className="travellers">
        <FormGroup>
          <Row>
            <Col sm={4}>Business Only</Col>
            <Col sm={8}>
              <Field
                name="business_only_num"
                type="input"
                component={HorizontalFormControl}
                validate={formState.businessOnlyNumPreMta ? [min(formState.businessOnlyNumPreMta)] : []}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col sm={4}>Business and Leisure</Col>
            <Col sm={8}>
              <Field
                name="business_leisure_num"
                type="input"
                component={HorizontalFormControl}
                validate={formState.businessLeisureNumPreMta ? [min(formState.businessLeisureNumPreMta)] : []}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col sm={4}>Single Parent</Col>
            <Col sm={8}>
              <Field
                name="single_parent_num"
                type="input"
                component={HorizontalFormControl}
                validate={formState.singleParentNumPreMta ? [min(formState.singleParentNumPreMta)] : []}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col sm={4}>Couple</Col>
            <Col sm={8}>
              <Field
                name="couple_num"
                type="input"
                component={HorizontalFormControl}
                validate={formState.coupleNumPreMta ? [min(formState.coupleNumPreMta)] : []}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col sm={4}>Family</Col>
            <Col sm={8}>
              <Field
                name="family_num"
                type="input"
                component={HorizontalFormControl}
                validate={formState.familyNumPreMta ? [min(formState.familyNumPreMta)] : []}
              />
            </Col>
          </Row>
        </FormGroup>
      </div>
    );
  }
}

export default connect()(GroupTravellerNumbers);


import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { Button, Modal } from '../../../../common/components';
import { TextField, SelectField } from '../../../../common/components/ReduxFormField';
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux';
import { lapseQuote } from '../../redux/policyActions';
import { ControlLabel } from 'react-bootstrap';
import { get, set } from 'lodash';
import './styles.scss';

const FORM_NAME = 'lapseForm';

const validate = (values) => {
  const errors = {};

  if (!get(values, 'meta.reason', false) && !get(values, 'meta.note.content', false)) {
    set(errors, 'meta.reason', 'Reason for lapse is required');
  }
  return errors;
};

class LapseButton extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    resource: PropTypes.object.isRequired,
    policy: PropTypes.object.isRequired,
    callback: PropTypes.func,
    label: PropTypes.string,
  };

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded, callback } = this.props;
    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('');
      }
      dispatch(closeModal(FORM_NAME));
    }
  }

  handleSubmit(values) {
    return this.props.dispatch(lapseQuote(values, this.props.resource));
  }

  handleOpen() {
    const { dispatch, reset } = this.props;
    dispatch(reset());
    dispatch(openModal(FORM_NAME));
  }

  render() {
    const reasons = [
      { label: 'Age of policy holder', value: 'Age of policy holder' },
      { label: 'Alternative provider', value: 'Alternative provider' },
      { label: 'Cancelled and replaced', value: 'Cancelled and replaced' },
      { label: 'Cancelled mid-term', value: 'Cancelled mid-term' },
      { label: 'Enhanced cover elsewhere', value: 'Enhanced cover elsewhere' },
      { label: 'No response from client', value: 'No response from client' },
      { label: 'One off short period policy', value: 'One off short period policy' },
      { label: 'Self-insuring', value: 'Self-insuring' },
      { label: 'Other', value: false },
    ];
    const { handleSubmit, submitting, formValues } = this.props;

    const submitButton = (
      <Button
        type="submit"
        bsStyle="primary"
        isLoading={submitting}
        rightIcon="ban"
        handleClick={handleSubmit(::this.handleSubmit)}
        label="Lapse Quote"
      />
    );

    return (
      <div>
        <Button
          className="pull-right"
          type="button"
          bsStyle="primary"
          label="Lapse"
          rightIcon="ban"
          isLoading={submitting}
          handleClick={::this.handleOpen}
        />

        <Modal name={FORM_NAME} title="Lapse Quote" close footer={submitButton}>
          <form onSubmit={handleSubmit(::this.handleSubmit)}>
            <div className="col-xs-12">
              <ControlLabel className="filter-label">
                Lapse reason (for internal records)
              </ControlLabel>
              <Field name="meta.reason" options={reasons} component={SelectField} />

              {!get(formValues, 'meta.reason') && (
                <div>
                  <ControlLabel className="filter-label">
                    Other reason not listed
                  </ControlLabel>
                  <Field name="meta.note.content" type="textarea" component={TextField} />
                </div>
              )}
            </div>
          </form>
        </Modal>
      </div>
    );
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(LapseButton);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state) => {
  const values = selector(state, 'meta.note.content', 'meta.reason');
  return {
    policy: state.policy,
    formValues: values,
  };
};

export default connect(mapStateToProps)(form);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ScreeningSession from '../components/ScreeningSession';
import {
  deleteTravellerMedicalCondition,
  finishScreening,
  recalculateScore,
  startScreening,
} from '../redux/screeningActions';
import { toastr } from 'react-redux-toastr';
import * as _ from 'lodash';

const QUOTES_TAB = 4;

export class ScreeningContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    screening: PropTypes.object.isRequired,
    callback: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    traveller: PropTypes.object.isRequired,
    // region: PropTypes.string.isRequired,
    // destinations: PropTypes.array,
    changeConditionsCovered: PropTypes.func,
    conditionsCovered: PropTypes.bool,
    mta: PropTypes.bool,
    applicationId: PropTypes.string,
    travellerType: PropTypes.string,
    childIndex: PropTypes.number,
    adultIndex: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      recalculated: false,
    };
  }

  componentWillMount() {
    this.recalculateScore = _.debounce(this.recalculateScore, 1000);
  }

  componentWillReceiveProps(nextProps) {
    const { currentTab, traveller } = nextProps;
    const { recalculated } = this.state;
    const winterSportsChanged =
      !_.isEmpty(nextProps.options) &&
      !_.isEmpty(this.props.options) &&
      nextProps.options.winter_sports !== this.props.options.winter_sports;
    const isRRT3 = traveller?.rrt3 || false;

    if (
      nextProps.region !== this.props.region ||
      nextProps.destinations !== this.props.destinations ||
      nextProps.tripType !== this.props.tripType ||
      winterSportsChanged ||
      (!isRRT3 && !recalculated)
    ) {
      if (
        !_.isEmpty(nextProps.destinations) ||
        !_.isEmpty(nextProps.region) ||
        nextProps.options.winter_sports !== ''
      ) {
        // re-screen if region, destinations or trip type have changed and are not empty
        // Do not trigger recalculate when user changes regions in the QUOTE TAB for upSell
        if (currentTab !== QUOTES_TAB) {
          this.recalculateScore(nextProps);
        }
      }
    }
  }

  startScreeningSession() {
    const { index, traveller, dispatch, region, destinations } = this.props;

    if (!region && destinations.length === 0) {
      return toastr.error(
        'Trip Details Required',
        'Please select a travel destination or region before adding medical conditions.',
      );
    }

    dispatch(
      startScreening(
        index,
        traveller.screening_id,
        region,
        destinations,
        'verisk',
        traveller.dob,
      ),
    );
  }

  finishScreeningSession() {
    const { callback, dispatch, path } = this.props;
    dispatch(finishScreening(callback, path));
  }

  deleteMedicalConditions() {
    const {
      index,
      dispatch,
      callback,
      applicationId,
      travellerType,
      mta,
      traveller,
      childIndex,
      adultIndex,
      path
    } = this.props;
    dispatch(
      deleteTravellerMedicalCondition(
        callback,
        index,
        applicationId,
        travellerType,
        mta,
        childIndex,
        adultIndex,
        traveller,
        path
      ),
    );
  }

  recalculateScore(nextProps) {
    const { traveller, callback, dispatch, region, destinations, path } = nextProps;

    if (traveller.screening_id && (region || (destinations && destinations.length > 0))) {
      this.setState({ recalculated: true });
      dispatch(
        recalculateScore(
          callback,
          traveller.screening_id,
          region,
          destinations,
          true,
          traveller,
          path,
        ),
      );
    }
  }

  render() {
    const {
      index,
      traveller,
      screening,
      dispatch,
      changeConditionsCovered,
      conditionsCovered,
      applicationId,
    } = this.props;

    return (
      <ScreeningSession
        index={index}
        traveller={traveller}
        screening={screening}
        startScreening={::this.startScreeningSession}
        finishScreening={::this.finishScreeningSession}
        deleteConditions={::this.deleteMedicalConditions}
        changeConditionsCovered={changeConditionsCovered}
        conditionsCovered={conditionsCovered}
        dispatch={dispatch}
        applicationId={applicationId}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    screening: state.screening,
  };
};

export default connect(mapStateToProps)(ScreeningContainer);

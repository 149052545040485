import { Col, ControlLabel, Row } from 'react-bootstrap';
import InfoButton from '../../../../../../common/components/InfoButton';
import { Field } from 'redux-form';
import { HorizontalFormControl, InfoLabel } from '../../../../../../common/components';
import parseFloatValue from '../../../../../../helpers/parseFloatValue';
import React from 'react';

const AgeRangeRules = ({ isViewOnly }) => (
  <>
    <Col sm={3}>
      <ControlLabel>
        <h4>
          Age Range: <InfoButton content={'tbc'}/>
        </h4>
      </ControlLabel>
    </Col>
    <Col sm={9}>
      <Row>
        <Col xs={6}>
          <Field
            name="meta.config.parameters.max_age.min"
            label="Min:"
            labelSize={4}
            component={isViewOnly ? InfoLabel : HorizontalFormControl}
            parse={parseFloatValue}
          />
        </Col>
        <Col xs={6}>
          <Field
            name="meta.config.parameters.max_age.max"
            label="Max:"
            labelSize={4}
            component={isViewOnly ? InfoLabel : HorizontalFormControl}
            parse={parseFloatValue}
          />
        </Col>
      </Row>
    </Col>
  </>
);
export default AgeRangeRules;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { setAnswer } from '../../redux/screeningActions';
import getIncludedResponse from '../../../../helpers/getIncludedResource';
import { Button } from '../../../../common/components';
import _ from 'lodash';
import { ConditionQuestionEdit } from '../ConditionQuestionEdit/ConditionQuestionEdit';

export default class MultipleChoiceQuestion extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    screening: PropTypes.object.isRequired,
    condition: PropTypes.object.isRequired,
    question: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      answers: [],
      radio: [], // Elements that will cause the question to submit automatically and clear other choices
    };
  }

  componentWillReceiveProps({ condition, question, dispatch, screening }) {
    const answers = [];
    const radio = [];
    const options = getIncludedResponse(
      question,
      screening.declaration.included,
      'options',
    );

    if (screening.isSubmitting) {
      return;
    }

    options.map((option) => {
      // const option = options[id];

      if (option.attributes.is_selected) {
        answers.push(option.attributes.number);
      }

      if (option.attributes.is_forward === false) {
        radio.push(option.attributes.number);
      }

      if (option.attributes.should_be_checked === true) {
        answers.push(option.attributes.number);
      }

      if (option.attributes.is_disabled && option.attributes.is_forward) {
        answers.push(option.attributes.number);
      }
    });

    this.setState({
      answers,
      radio,
    });
  }

  componentDidUpdate() {
    const element = document.getElementById('active-multi-question');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  handleCheckboxTick(e) {
    const { answers, radio } = this.state;
    const answer = Number.parseInt(e.target.value);

    const position = answers.indexOf(answer);

    if (position > -1) {
      this.setState({
        answers: answers.filter((val) => {
          return val != answer;
        }),
      });
    } else {
      if (radio.indexOf(Number.parseInt(answer)) > -1) {
        this.setState(
          {
            answers: [answer],
          },
          ::this.handleSubmit,
        );

        return;
      }

      let newAnswers = [...answers, answer];

      if (_.intersection(answers, radio).length > 0) {
        newAnswers = [answer];
      }

      this.setState({
        answers: newAnswers,
      });
    }
  }

  handleSubmit() {
    const { dispatch, condition, question } = this.props;
    const { answers } = this.state;

    dispatch(
      setAnswer(
        condition,
        question,
        {
          id: answers.join(','),
        },
        true,
      ),
    );
  }

  render() {
    const { answers, radio } = this.state;
    const { question, screening, dispatch, condition } = this.props;
    const optionsResource = getIncludedResponse(
      question,
      screening.declaration.included,
      'options',
    );
    const selectedAnswers = getIncludedResponse(
      question,
      screening.declaration.included,
      'selected',
    );
    const isActiveQuestion = question?.attributes?.is_active || false;
    let disableRadios = false;

    return (
      <div className="row question">
        <div className="col-xs-12">
          <label className="control-label">{question.attributes.text}</label>
          {question.info ? (
            <p className="text-info text-justify" style={{ fontSize: '14px' }}>
              {question.info}
              <br />
            </p>
          ) : (
            ''
          )}
          {question.attributes.warning ? (
            <p className="text-danger text-justify" style={{ fontSize: '14px' }}>
              {question.attributes.warning}
              <br />
            </p>
          ) : (
            ''
          )}

          {!isActiveQuestion && (
            <ConditionQuestionEdit
              selectedAnswers={selectedAnswers}
              screening={screening}
              dispatch={dispatch}
              condition={condition}
              question={question}
            />
          )}

          <div className={'screening-border-bottom'}>
            {isActiveQuestion && optionsResource.length > 0 && (
              <>
                <div className="multi-select-container" id={'active-multi-question'}>
                  {optionsResource.map((option, i) => {
                    const inputId = option.id;
                    const isForwardConditionSelected =
                      option.attributes.is_disabled && option.attributes.is_forward;
                    let disabled = option.attributes.is_disabled;

                    if (isForwardConditionSelected) {
                      disableRadios = true;
                    }

                    if (
                      disableRadios &&
                      radio.indexOf(Number.parseInt(option.attributes.number)) > -1
                    ) {
                      disabled = true;
                    }

                    return (
                      <div
                        key={i}
                        className={
                          'radio-container' +
                          (disabled ? ' radio-container--disabled' : '')
                        }
                      >
                        <div className={'form-radio'}>
                          <input
                            type="checkbox"
                            id={inputId}
                            value={option.attributes.number}
                            checked={
                              answers.indexOf(option.attributes.number) > -1 ||
                              isForwardConditionSelected
                            }
                            onChange={disabled ? () => {} : ::this.handleCheckboxTick}
                            disabled={disabled}
                          />
                          <label htmlFor={inputId} />
                        </div>
                        <label className="radio-label" htmlFor={inputId}>
                          {option.attributes.text}
                        </label>
                      </div>
                    );
                  })}
                </div>
                <Button
                  bsStyle="primary"
                  isLoading={screening.isSubmitting}
                  label="Next"
                  handleClick={::this.handleSubmit}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import RoleFormContainer from './RoleFormContainer';
import roleSchema from '../../../schemas/role';

export class RoleCreateContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    role: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
  };

  render() {
    const buttons = (
      <Button bsStyle="default" label="Back" isBackButton leftIcon="caret-left" />
    );

    return (
      <InternalContainer title="Role" buttons={buttons}>
        <RoleFormContainer {...this.props} resource={roleSchema} />
      </InternalContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    role: state.role,
  };
}

export default connect(mapStateToProps)(RoleCreateContainer);

import React, { Component } from 'react';
import { Box } from '../';
import './Loader.scss';
//
export default class Loader extends Component {
  render() {
    const { message, noShadow, fullPage } = this.props;

    const loader = () => {
      if (message) {
        return (
          <div className="loading__container">
            <div className="loading__center-container">
              <h2 className="loading__message">
                {message} <br />
                <span className="loader-dots">
                  <i className="fa fa-circle loader-dot" />
                  <i className="fa fa-circle loader-dot" />
                  <i className="fa fa-circle loader-dot" />
                </span>
              </h2>
            </div>
          </div>
        );
      }

      return (
        <div className="loading__container">
          <div className="loading__center-container">
            <div className="loading__circle" />
          </div>
          <div className="loading__center-container">
            <div className="loading__logo" />
          </div>
        </div>
      );
    };

    if (fullPage) {
      return (
        <div className="page-overlay">
          {loader()}
        </div>
      );
    }

    if (noShadow) {
      return loader();
    }

    return (
      <Box>
        {loader()}
      </Box>
    );
  }
}

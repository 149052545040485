import { Col } from 'react-bootstrap';
import { Field } from 'redux-form';
import { HorizontalFormControl, InfoLabel } from '../../../../../../common/components';
import parseFloatValue from '../../../../../../helpers/parseFloatValue';
import React from 'react';

const LABEL_SIZE = 6;
const FIELD_SIZE = 6;

const AgeLimitRules = ({ isViewOnly }) => (
  <>
    <Col sm={6}>
      <Field
        name="meta.config.parameters.adults.min-age"
        label="Adult Min Age:"
        labelSize={LABEL_SIZE}
        fieldSize={FIELD_SIZE}
        component={isViewOnly ? InfoLabel : HorizontalFormControl}
        parse={parseFloatValue}
      />
    </Col>
    <Col sm={6}>
      <Field
        name="meta.config.parameters.children.max-age"
        label="Child Max Age:"
        labelSize={LABEL_SIZE}
        fieldSize={FIELD_SIZE}
        component={isViewOnly ? InfoLabel : HorizontalFormControl}
        parse={parseFloatValue}
      />
    </Col>
  </>
);
export default AgeLimitRules;

import React, { Component } from 'react';
import './styles.scss';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { Box, HorizontalFormControl } from '../../../../../../common/components';
import SelectLegalEntityType from '../../../../../../common/components/Selects/SelectLegalEntityType';
import DatePickerDob from '../../../../../../common/components/DatePicker/DatePickerDob';
import SelectBrokerCategory from '../../../../../../common/components/Selects/SelectBrokerCategory';
import CheckboxUnlabeled from '../../../../../../common/components/ReduxFormField/CheckboxUnlabeled';

const ATTRIBUTES = 'data.attributes.organisation';
const RELATIONSHIPS = 'data.relationships';
const METADATA = 'data.attributes.organisation.metadata';

class Company extends Component {
  constructor(props) {
    super(props);

    this.state = {
      legalEntity: null,
      disableFields: false,
    };
  }

  componentDidMount() {
    const { currentOrganisation } = this.props;

    let legalEntity = null;
    let disableFields = false;

    const organisationId = get(currentOrganisation, 'id');
    if (organisationId) {
      disableFields = true;
    }

    this.setState({
      legalEntity: legalEntity,
      disableFields: disableFields,
    });
  }

  render() {
    const { formValues } = this.props;
    const selectOrganisation = get(
      formValues,
      'data.attributes.metadata.select_organisation',
    );
    const hasParentCompany = get(
      formValues,
      'data.attributes.organisation.has_parent_company',
    );

    return (
      <Box>
        <Row>
          <Col sm={12}>
            <h4 className="page-title">Company Information</h4>
            <hr/>
          </Col>
          <Col sm={6}>
            <Field
              name={`${RELATIONSHIPS}.legal_entity_type.data`}
              label="Legal Entity Type"
              labelSize={4}
              mdFieldSize={8}
              isObject
              component={SelectLegalEntityType}
              defaultSelected={
                this.state.legalEntity ? { value: this.state.legalEntity } : false
              }
              placeholder={'please select'}
              disabled={selectOrganisation}
            />
            <Field
              name={`${RELATIONSHIPS}.broker_category.data`}
              label="Classification of Firm"
              labelSize={4}
              mdFieldSize={8}
              isObject
              component={SelectBrokerCategory}
              placeholder={'please select'}
            />
            <Field
              name={`${ATTRIBUTES}.regulatory_authority_number`}
              labelSize={4}
              mdFieldSize={4}
              component={HorizontalFormControl}
              label="FCA Number"
              disabled={selectOrganisation}
            />
          </Col>
          <Col sm={6}>
            <Field
              name={`${METADATA}.date_established`}
              label="Date Established"
              labelSize={4}
              component={DatePickerDob}
            />
            <Field
              name={`${METADATA}.number_of_staff`}
              labelSize={4}
              mdFieldSize={4}
              component={HorizontalFormControl}
              label="Number of Staff"
            />
            <Field
              name={`${ATTRIBUTES}.legal_entity_number`}
              labelSize={4}
              mdFieldSize={4}
              component={HorizontalFormControl}
              label="Company Number"
              disabled={selectOrganisation}
            />
          </Col>
          <Col sm={12}>
            <Field
              name={`${ATTRIBUTES}.has_parent_company`}
              labelSize={5}
              component={CheckboxUnlabeled}
              positionTop
              inline
              label="Do you belong to a parent company?"
              options={[{ label: '', value: true }]}
            />
          </Col>
          {hasParentCompany && (
            <Col sm={6}>
              <Field
                name={`${ATTRIBUTES}.parent_company.name`}
                labelSize={4}
                component={HorizontalFormControl}
                label="Parent company"
              />
              <Field
                name={`${ATTRIBUTES}.parent_company.regulatory_authority_number`}
                labelSize={4}
                mdFieldSize={4}
                component={HorizontalFormControl}
                label="FCA Number"
                disabled={selectOrganisation}
              />
            </Col>
          )}
        </Row>
      </Box>
    );
  }
}

const form = reduxForm()(Company);
const mapStateToProps = (state, props) => {
  const values = formValueSelector(props.form)(
    state,
    'data',
    ATTRIBUTES,
    METADATA,
    'data.relationships.organisation.data.id',
  );

  return {
    auth: state.auth,
    initialValues: props.resource,
    form: props.form,
    formValues: values,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    product: state.product,
    currentOrganisation: state.organisation.currentOrganisation,
  };
};

export default connect(mapStateToProps)(form);

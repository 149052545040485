import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import './BarChart.scss';
import { isEqual } from 'lodash';

export default class BarChart extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
  };

  shouldComponentUpdate(nextProps) {
    return isEqual(nextProps.data, this.props.data) !== false;
  }
  chartData(data) {
    let dataset = {};

    if (Array.isArray(data)) {
      dataset = {
        labels: data.map((range) => {
          return range.step;
        }),
        datasets: [
          {
            type: 'line',
            yAxisID: 'y-axis-1',
            spanGaps: true,
            borderColor: '#FE86C1',
            backgroundColor: '#fff',
            pointBackgroundColor: '#FE86C1',
            label: 'Premium issued      ',
            fill: false,
            data: data.map((range) => {
              return range.premiums_gross;
            }),
          },
          {
            type: 'bar',
            yAxisID: 'y-axis-2',
            label: 'Quotes              ',
            backgroundColor: data.map(() => {
              return '#0DD9EB';
            }),
            data: data.map((range) => {
              return range.no_quoted;
            }),
          },
          {
            type: 'bar',
            yAxisID: 'y-axis-2',
            label: 'Policies',
            backgroundColor: data.map(() => {
              return '#0A45DB';
            }),
            data: data.map((range) => {
              return range.no_issued;
            }),
          },
        ],
      };
    }
    return dataset;
  }

  render() {
    const { data } = this.props;
    return (
      <div className="chart-dashboard">
        <Bar data={this.chartData(data)} options={options} />
      </div>
    );
  }
}

const options = {
  maintainAspectRatio: false,
  responsive: true,
  display: false,
  barThickness: 2,
  defaultFontColor: '#6d6e70',
  scales: {
    xAxes: [
      {
        gridLines: {
          color: '#6d6e70',
          drawOnChartArea: false,
        },
        ticks: {
          fontColor: '#6d6e70',
        },
      },
    ],
    yAxes: [
      {
        position: 'left',
        id: 'y-axis-1',
        type: 'linear',
        gridLines: {
          color: '#6d6e70',
          drawOnChartArea: false,
        },
        ticks: {
          beginAtZero: true,
          padding: 0,
          fontColor: '#6d6e70',
        },
      },
      {
        position: 'right',
        id: 'y-axis-2',
        gridLines: {
          color: '#6d6e70',
          drawOnChartArea: false,
        },
        ticks: {
          fontColor: '#6d6e70',
        },
      },
    ],
    ticks: {
      fontColor: '#6d6e70',
    },
  },

  legend: {
    labels: {
      fontColor: '#6d6e70',
      boxWidth: 12,
    },
  },
};

import React, { Component } from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Sticky, StickyContainer } from 'react-sticky';
import {
  canCommissionAdjust,
  hasPermission,
} from '../../../../../auth/redux/authActions';
import { FormattedDate } from 'react-intl';
import flatten from 'flat';
import displayName from '../../../../../../helpers/displayName';
import pluralize from 'pluralize';
import QuoteSaveAndExit from '../../../QuoteSaveAndExit/index';
import {
  Icon,
  Button,
  DatePickerDob,
  InfoButton,
} from '../../../../../../common/components/';
import { SelectField } from '../../../../../../common/components/ReduxFormField';
import { get, set, size, isEmpty, map, head } from 'lodash';
import displayCurrency from '../../../../../../helpers/displayCurrency';
import mapReactSelect from '../../../../../../helpers/mapReactSelect';
import moment from 'moment';
import CommissionAdjust from '../../../CommissionAdjust/CommissionAdjust';
import TravellerInformationModal
  from '../../../TravellerInformationModal/TravellerInformationModal';
import Box from '../../../../../../common/components/Box/Box';
import BenefitsModal from '../../../BenefitsModal/BenefitsModal';
import { SelectCampaign } from '../../../../../../common/components';
import getIncludedResource from '../../../../../../helpers/getIncludedResource';
import { isOwner } from '../../../../../auth/redux/authActions';

const META = 'data.attributes.metadata';
const OPTIONS_TAB = 5;
// const QUOTES_TAB = 4;

export class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.renderQuoteSavePolicy = this.renderQuoteSavePolicy.bind(this);
    this.renderSavePolicyOnly = this.renderSavePolicyOnly.bind(this);
    this.confirmUpsell = this.confirmUpsell.bind(this);
    this.formatRegion = this.formatRegion.bind(this);
    this.originalTripType = get(props.formValues, 'data.attributes.metadata.scheme_type');
  }

  renderQuoteSavePolicy() {
    const {
      product,
      currentProduct,
      productIcon,
      formValues,
      handleSubmit,
      auth,
      hasReferrals,
    } = this.props;
    return (
      <QuoteSaveAndExit
        auth={auth}
        hasReferrals={hasReferrals}
        product={product}
        currentProduct={currentProduct}
        image={productIcon}
        selectedSchemes={get(formValues, 'data.relationships.schemes.data', [])}
        handleSubmit={handleSubmit}
      />
    );
  }

  renderSavePolicyOnly() {
    const {
      product,
      handleSubmit,
      saveQuoteOnly,
      referQuoteOnly,
      hasReferrals,
    } = this.props;
    return (
      <div className="save-quote-btn-sidebar">
        <Button
          label={'Save'}
          bsStyle={'primary'}
          block
          isLoading={product.isSaving}
          handleClick={handleSubmit(hasReferrals ? referQuoteOnly : saveQuoteOnly)}
        />
      </div>
    );
  }

  formatDestinations() {
    const destinations =
      get(this.props.formValues, 'data.attributes.metadata.destinations', []) || [];

    return destinations
      .map((destination) => {
        return destination && destination.attributes
          ? destination.attributes.name
          : destination;
      })
      .filter((destination) => {
        return destination !== null;
      })
      .join(',');
  }

  formatRegion() {
    const { formValues } = this.props;
    const region = get(formValues, 'data.attributes.metadata.region', '');

    if (!region) {
      return '';
    }

    const regions = {
      uk: 'United Kingdom',
      europe: 'Europe',
      europe_excluding: 'Europe',
      worldwide_excluding: 'Worldwide Exc USA/Canada/Caribbean',
      worldwide: 'Worldwide Inc USA/Canada/Caribbean',
    };

    return regions[region];
  }

  confirmUpsell() {
    const {
      formValues,
      dispatch,
      change,
      // handleSubmit,
      // saveQuoteOnly,
      currentProduct,
    } = this.props;

    const maxTripDurationOptions = mapReactSelect(
      get(currentProduct, 'data.attributes.metadata.annual_trip_durations'),
    );
    const hasDefaultMaxTrip = size(maxTripDurationOptions) === 1;
    const schemeType = get(formValues, `${META}.scheme_type`);
    const region = get(formValues, `${META}.region`);
    if (!isEmpty(region) && schemeType === 'single') {
      // Promise.resolve(dispatch(handleSubmit(saveQuoteOnly)))
      Promise.resolve(dispatch(change(`${META}.scheme_type`, 'annual')))
        .then(hasDefaultMaxTrip && dispatch(change(`${META}.max_trip_duration`, maxTripDurationOptions[0].value)))
        .then(dispatch(change(`${META}.end_date`, '')))
        .then(() => this.props.reCalculate());
    }
  }

  getRegion() {
    const { currentProduct, formValues } = this.props;
    const region = get(formValues, `${META}.region`, '');
    return get(currentProduct, `${META}.definitions.region_` + region, '');
  }

  getMaxTripDuration() {
    const { formValues, product } = this.props;
    const schemes = get(formValues, 'data.relationships.schemes.data', []);
    if (schemes.length !== 1) {
      return get(formValues, 'data.attributes.metadata.max_trip_duration', '');
    }
    const schemeId = schemes[0].id;
    return get(
      product,
      `quotes.${schemeId}.meta.information.max_trip_duration`,
      get(formValues, 'data.attributes.metadata.max_trip_duration', ''),
    );
  }

  renderSchemeSummary(selection, i) {
    const { product, mta, showBlocker } = this.props;
    const originalFee = get(selection, 'meta.premium.fees', 0);
    if (mta) {
      return;
    }
    if (product.isSaving) {
      return;
    }
    if (showBlocker) {
      return;
    }
    const premium = !isEmpty(product.premiumIndication) ? product.premiumIndication.meta.premium :
      get(selection.meta, 'premium', '');

    return (
      <div className="col-xs-12 schemeSelected" key={i}>
        <Box>
          <div className="row">
            <div className="col-md-12">
              Chosen Scheme{' '}
              <BenefitsModal
                scheme={selection.meta.scheme}
                benefits={selection.meta.benefits}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">{selection.meta.scheme.name} &nbsp;</div>
          </div>
          <div className="row">
            <div className="col-md-8">Base Premium</div>
            <div className="col-md-4 sidebar-summary-premium">
              {' '}
              {displayCurrency(get(premium, 'principal', 0))}
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              IPT ({get(selection, 'meta.rates.tax_rate', 0)}%)
            </div>
            <div className="col-md-4 sidebar-summary-premium">
              {' '}
              {displayCurrency(get(premium, 'tax', 0))}
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">Admin Fees</div>
            <div className="col-md-4 sidebar-summary-premium">
              {' '}
              {displayCurrency(originalFee)}{' '}
            </div>
            {/*<div className='col-md-8'>Override Fees</div>*/}
            {/*<div className='col-md-4 sidebar-summary-premium'> {displayCurrency(overridefee)} </div>*/}
          </div>
          <div className="row">
            <div className="col-md-8">
              Total Payable
            </div>
            <div className="col-md-4 sidebar-summary-premium">
              {' '}
              {displayCurrency(get(premium, 'gross', 0))}
            </div>
          </div>

          {get(selection.meta.premium, 'discount', false) ? (
            <div className="row">
              <div className="col-md-8">Discount</div>
              <div className="col-md-4 sidebar-summary-premium">
                (
                {displayCurrency(
                  get(selection.meta.premium_before_adjustments, 'gross', 0) -
                  get(selection.meta.premium, 'gross', 0),
                )}
                )
              </div>
            </div>
          ) : null}
        </Box>
      </div>
    );
  }

  render() {
    const {
      availableQuotes,
      currentProduct,
      formValues,
      product,
      resource,
      error,
      mta,
      submitFailed,
      auth,
      brokerId,
      showBlocker,
    } = this.props;

    const regionsInfo = this.getRegion();
    let regions = mapReactSelect(currentProduct.data.attributes.metadata.regions || {});
    const destinations = get(formValues, 'data.attributes.metadata.destinations', []);
    const goingOnCruise = get(formValues, 'data.attributes.metadata.going_on_cruise');
    const tripType = () => {
      let tripType = get(formValues, 'data.attributes.metadata.scheme_type');
      if (currentProduct.data.attributes.product_code === 'LSB') {
        return 'longstay';
      }
      return tripType;
    };
    const isFstUkSingleNonCruiseTrip =
      product?.productCode === 'FST' &&
      Array.isArray(destinations) && destinations.length === 1 &&
      destinations[0] === 'United Kingdom' &&
      tripType() === 'single' &&
      !goingOnCruise;

    if (isFstUkSingleNonCruiseTrip) {
      regions = regions.filter(region => region.value === 'uk');
    }
    const isIsleChannelResident = () =>
      get(formValues, 'data.attributes.metadata.residency_declaration') === true;
    const formErrors = error && error instanceof Object ? flatten(error) : false;
    const displayedErrors = [];
    let selectedOptions = 0;
    const currentTab = get(formValues, 'data.current_tab');
    let productName = get(currentProduct, 'data.attributes.name');
    if (product.productCode === 'FST' && goingOnCruise) {
      productName = 'Free Spirit Cruise Travel';
    }
    const isBmc = product.productCode === 'BMC';
    const showRegion = ['DIA', 'PST', 'ADV'].includes(product.productCode);

    // Get the premium
    const premium = get(formValues, 'data.relationships.schemes.data', []);
    const premiumId = head(premium);
    const schemeObject = getIncludedResource(
      get(premiumId, 'id'),
      product.schemes,
      false,
      'products/schemes',
    );
    const isUpSell = get(schemeObject, 'data.attributes.quote_type') === 'upsell';
    const schemeType = get(formValues, `${META}.scheme_type`);

    const backDateCover = () => {
      if (hasPermission('policy.backdate_cover')) {
        const _getProductBackdate = get(
          currentProduct,
          'data.attributes.metadata.backdate_cover',
          0,
        );
        return moment()
          .subtract(_getProductBackdate, 'days')
          .format();
      }
      return moment().format();
    };
    let scheme = get(formValues, 'data.relationships.schemes.data', []);
    const coverDateInfo =
      "IMPORTANT - you will not be covered for holiday cancellation until your policy start date. Choose today's date if you want cover as soon as possible.";
    const enableCampaign = !mta;

    const loggedInBrokerId = get(
      getIncludedResource(auth.user.data, auth.user.included, 'current_broker'),
      'data.id',
      null,
    );

    //const channelFromSource = isOwner() ? get(formValues, 'meta.channel_key', channelKey) : channelKey
    const brokerFromSource = isOwner()
      ? get(product, 'source.relationships.broker.data.id', brokerId)
      : loggedInBrokerId;

    // Campaigns: Set the channel key filter according to the Source Selected
    let channelKey = '';

    if (!mta) {
      if (brokerFromSource) {
        channelKey = 'pjh-b2b';
      } else if (get(product, 'source.attributes.source_type') === 'Broker') {
        channelKey = 'pjh-b2b';
      } else if (get(product, 'source.attributes.source_type') === 'Direct') {
        channelKey = 'call-centre';
      }
    }

    let campaignFilters = {
      'channel.key': channelKey,
      product: get(currentProduct, 'data.id'),
    };

    if (brokerFromSource) {
      campaignFilters['broker'] = brokerFromSource;
    }

    const getPremium = premium && premium.length === 1 ? premium : 0; // 0 = no scheme selected
    const maxTripDurationOptions = mapReactSelect(
      get(currentProduct, 'data.attributes.metadata.annual_trip_durations'),
    );

    let travellerGroup = get(formValues, 'data.attributes.metadata.traveller_group', '');

    if (get(formValues, 'data.attributes.metadata.traveller_group', '') === 'individual' && currentProduct.data.attributes.product_code === 'CPI') {
      travellerGroup = 'individual booking';
    }

    if (isUpSell && 'single' === schemeType) {
      let startDate = '';

      availableQuotes.map((quote) => {
        if ('annual' === get(quote, 'meta.information.scheme_type', '')) {
          startDate = get(quote, 'meta.information.start_date', '');
        }
      });

      if ('' !== startDate && startDate !== get(formValues, 'data.attributes.metadata.start_date', '')) {
        set(formValues, 'data.attributes.metadata.start_date', startDate);
      }
    }

    const displayMaxTripOption = size(maxTripDurationOptions) > 1;
    return (
      <div>
        <StickyContainer className="calculate-quote-sidebar box">
          <Sticky>
            {() => (
              <>
                <div className="col-xs-12">
                  <h4>Quote Summary</h4>
                </div>
                <div className="row sticky-inner-container">
                  {isUpSell && schemeType === 'single' && (
                    <div className="col-xs-12 quote-divider-top confirm-fields-upsell">
                      <div className="alert alert-danger">
                        <div className="confirm-text">Please confirm the following:</div>
                        <div className="form-horizontal">
                          <Field
                            label="Region"
                            labelSize={4}
                            infoButton={<InfoButton content={regionsInfo}/>}
                            name="data.attributes.metadata.region"
                            component={SelectField}
                            options={regions}
                          />
                          <Field
                            label={'Start Date'}
                            labelSize={4}
                            infoButton={<InfoButton content={coverDateInfo}/>}
                            name="data.attributes.metadata.start_date"
                            minDate={backDateCover()}
                            maxDate={moment()
                              .add(31, 'days')
                              .format()}
                            component={DatePickerDob}
                          />
                          {displayMaxTripOption && (
                            <Field
                              label="Max Trip Duration"
                              name="data.attributes.metadata.max_trip_duration"
                              component={SelectField}
                              labelSize={4}
                              options={maxTripDurationOptions}
                            />
                          )}
                          <div className="apply-button-upsell">
                            <Button
                              label="Apply"
                              type="button"
                              bsStyle="primary"
                              handleClick={this.confirmUpsell}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {resource.data.attributes.policy_number ? (
                    <div className="col-xs-12 quote-divider">
                      <div className="quote-title">Policy number</div>
                      <div className="quote-info">
                        {resource.data.attributes.policy_number}
                      </div>
                    </div>
                  ) : (
                    resource.data.attributes.quote_reference && (
                      <div className="col-xs-12 quote-divider">
                        <div className="quote-title">Reference:</div>
                        <div className="quote-info">
                          {resource.data.attributes.quote_reference}
                        </div>
                      </div>
                    )
                  )}
                  {this.props.isMultipleSchemeSelected && !getPremium && !getPremium !== 0 && (
                    <div className="col-xs-12 quote-divider">
                      <div className="error-box text-center">
                        <span>Please select 1 scheme to proceed</span>
                      </div>
                    </div>
                  )}
                  {submitFailed && error && formErrors ? (
                    <div className="col-xs-12 quote-divider">
                      <div className="error-box">
                        <h4>Attention required</h4>
                        <ul>
                          {Object.keys(formErrors).map((key, i) => {
                            if (displayedErrors.indexOf(formErrors[key]) === -1) {
                              displayedErrors.push(formErrors[key]);
                              return <li key={i}>{formErrors[key]}</li>;
                            }
                          })}
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <div className="">
                      {hasPermission('policy.handle_all_referrals') &&
                      get(resource, 'data.attributes.metadata.referrals', []).length >
                      0 && (
                        <div className="col-xs-12 quote-divider">
                          <div className="quote-title">Referral reasons:</div>
                          <div className="quote-info">
                            <ul>
                              {get(resource, 'data.attributes.metadata.referrals', []).map(
                                (key, i) => (
                                  <li key={i}>{key.reason}</li>
                                ),
                              )}
                            </ul>
                          </div>
                        </div>
                      )}

                      <div className="col-xs-12 quote-divider">
                        <div className="quote-title">Product:</div>
                        <div className="quote-info">{productName}</div>
                      </div>

                      {enableCampaign && (
                        <div className="col-xs-12 campaign-sidebar-container">
                          <div className="quote-title">Campaign:</div>
                          <div className="campaign-info">
                            <Field
                              key={'select-campaign' + get(product, 'source.id', '')}
                              sourceKey={brokerFromSource}
                              labelSize={0}
                              name="data.attributes.metadata.campaign"
                              filters={campaignFilters}
                              component={SelectCampaign}
                              isClearable
                              {...this.props}
                            />
                          </div>
                        </div>
                      )}

                      {get(formValues, 'data.attributes.metadata.scheme_type') && (
                        <div className="col-xs-12 quote-divider">
                          <div className="quote-title">Type of cover:</div>
                          <div className="quote-info">
                            {tripType() === 'single' && 'Single Trip Travel'}
                            {tripType() === 'annual' && product.productCode !== 'CPS' && 'Annual Multi-trip Travel'}
                            {tripType() === 'longstay' && 'Longstay'}
                            {tripType() === 'annual' && product.productCode === 'CPS' && 'Frequent Traveller'}
                          </div>
                        </div>
                      )}

                      {get(formValues, 'data.attributes.metadata.scheme_type') === 'annual' &&
                      get(formValues, 'data.attributes.metadata.max_trip_duration') && (
                        <div className="col-xs-12 quote-divider">
                          <div className="quote-title">Max trip duration:</div>
                          <div className="quote-info">{this.getMaxTripDuration()} Days
                          </div>
                        </div>
                      )}

                      {get(formValues, 'data.attributes.metadata.start_date') && (
                        <div className="col-xs-12 quote-divider">
                          <div className="quote-title">Dates of cover:</div>
                          <div className="quote-info">
                            <FormattedDate
                              value={new Date(formValues.data.attributes.metadata.start_date)}
                            />
                            {formValues.data.attributes.metadata.end_date && (
                              <span>
                            {' '}
                                to{' '}
                                <FormattedDate
                                  value={
                                    new Date(formValues.data.attributes.metadata.end_date)
                                  }
                                />
                          </span>
                            )}
                          </div>
                        </div>
                      )}

                      {(get(formValues, 'data.attributes.metadata.destinations', []) ||
                        get(formValues, 'data.attributes.metadata.region') && currentProduct.data.attributes.product_code !== 'TUMC') && (
                        <div className="col-xs-12 quote-divider">
                          <div className="quote-title">Destinations:</div>
                          {tripType() === 'single' && !showRegion ? (
                            <div className="quote-info">
                              {!isEmpty(this.formatDestinations()) ? (
                                <span>{this.formatDestinations()}</span>
                              ) : (
                                <span>{this.formatRegion()}</span>
                              )}
                            </div>
                          ) : (
                            <div className="quote-info">
                          <span>
                            {displayName(
                              get(formValues, 'data.attributes.metadata.region', ''),
                            )}
                          </span>
                            </div>
                          )}
                        </div>
                      )}

                      {(get(formValues, 'data.attributes.metadata.options.winter_sports') ===
                        true ||
                        get(formValues, 'data.attributes.metadata.options.golf_cover') ===
                        true ||
                        get(formValues, 'data.attributes.metadata.options.gadget_cover') ===
                        true) && (
                        <div className="col-xs-12 quote-divider">
                          <div className="quote-title">Additional cover:</div>
                          <div className="quote-info">
                            <div>
                              {Object.keys(
                                get(formValues, 'data.attributes.metadata.options', {}),
                              ).map((option) => {
                                if (
                                  option !== 'max_trip_duration' &&
                                  option !== 'cruise_cover' &&
                                  formValues.data.attributes.metadata.options[option] === true
                                ) {
                                  let detail = selectedOptions ? ', ' : '';
                                  selectedOptions++;
                                  return detail + displayName(option);
                                }
                              })}
                            </div>
                          </div>
                        </div>
                      )}

                      {get(formValues, 'data.attributes.metadata.traveller_group') && !isBmc && (
                        <div className="col-xs-12 quote-divider">
                          <div className="quote-title">Travellers:</div>
                          <div className="quote-info">
                            {displayName(travellerGroup)}
                            <br/>
                            {formValues.data.attributes.metadata.adults.length} x{' '}
                            {pluralize(
                              'adult',
                              formValues.data.attributes.metadata.adults.length,
                            )}
                            {get(formValues, 'data.attributes.metadata.children', [])
                              .length !== 0
                              ? ', ' +
                              formValues.data.attributes.metadata.children.length +
                              ' x ' +
                              pluralize(
                                'children',
                                formValues.data.attributes.metadata.children.length,
                              )
                              : ''}
                            <TravellerInformationModal
                              adults={get(formValues, 'data.attributes.metadata.adults')}
                              children={get(formValues, 'data.attributes.metadata.children')}
                            />
                            {map(
                              get(formValues, 'data.attributes.metadata.adults'),
                              (index, i) => {
                                return (
                                  <div key={i}>
                                    <div>
                                      {displayName(
                                        get(
                                          formValues,
                                          `data.attributes.metadata.adults[${i}].title`,
                                        ),
                                      )}{' '}
                                      {displayName(
                                        get(
                                          formValues,
                                          `data.attributes.metadata.adults[${i}].first_name`,
                                        ),
                                      )}{' '}
                                      {displayName(
                                        get(
                                          formValues,
                                          `data.attributes.metadata.adults[${i}].last_name`,
                                        ),
                                      )}{' '}
                                      {!isEmpty(
                                        get(
                                          formValues,
                                          `data.attributes.metadata.adults[${i}].dob`,
                                        ),
                                      ) && (
                                        <span>
                                      (
                                      <FormattedDate
                                        value={
                                          new Date(
                                            get(
                                              formValues,
                                              `data.attributes.metadata.adults[${i}].dob`,
                                            ),
                                          )
                                        }
                                      />
                                      )
                                    </span>
                                      )}
                                      <br/>
                                    </div>
                                  </div>
                                );
                              },
                            )}
                            {map(
                              get(formValues, 'data.attributes.metadata.children'),
                              (index, i) => {
                                return (
                                  <div key={i}>
                                    <div>
                                      {displayName(
                                        get(
                                          formValues,
                                          `data.attributes.metadata.children[${i}].title`,
                                        ),
                                      )}{' '}
                                      {displayName(
                                        get(
                                          formValues,
                                          `data.attributes.metadata.children[${i}].first_name`,
                                        ),
                                      )}{' '}
                                      {displayName(
                                        get(
                                          formValues,
                                          `data.attributes.metadata.children[${i}].last_name`,
                                        ),
                                      )}{' '}
                                      {!isEmpty(
                                        get(
                                          formValues,
                                          `data.attributes.metadata.children[${i}].dob`,
                                        ),
                                      ) && (
                                        <span>
                                      (
                                      <FormattedDate
                                        value={
                                          new Date(
                                            get(
                                              formValues,
                                              `data.attributes.metadata.children[${i}].dob`,
                                            ),
                                          )
                                        }
                                      />
                                      )
                                    </span>
                                      )}
                                      <br/>
                                    </div>
                                  </div>
                                );
                              },
                            )}
                          </div>
                        </div>
                      )}
                      {get(formValues, 'data.attributes.metadata.traveller_group') && isBmc && (
                        <div className="col-xs-12 quote-divider">
                          <div className="quote-title">Traveller:</div>
                          <div className="quote-info">
                            {map(
                              get(formValues, 'data.attributes.metadata.adults'),
                              (index, i) => {
                                return (
                                  <div key={i}>
                                    <div>
                                      {displayName(
                                        get(
                                          formValues,
                                          `data.attributes.metadata.adults[${i}].title`,
                                        ),
                                      )}{' '}
                                      {displayName(
                                        get(
                                          formValues,
                                          `data.attributes.metadata.adults[${i}].first_name`,
                                        ),
                                      )}{' '}
                                      {displayName(
                                        get(
                                          formValues,
                                          `data.attributes.metadata.adults[${i}].last_name`,
                                        ),
                                      )}{' '}
                                      <TravellerInformationModal
                                        adults={get(formValues, 'data.attributes.metadata.adults')}
                                      />
                                    </div>
                                  </div>
                                );
                              },
                            )}
                          </div>
                        </div>
                      )}
                      {/* Add a Spinner logic only if the Tab is not the Option, to avoid display when Premium Inc === 0 */}
                      {isEmpty(this.props.premium) &&
                      this.props.premium !== 0 &&
                      this.props.currentTab !== OPTIONS_TAB &&
                      !mta && !showBlocker ? (
                        <div className="col-xs-12 quote-divider">
                          <div className="quote-title">
                            Premium
                            {isIsleChannelResident() && !product.isCalculating === true && (
                              <span> (inc. {this.props.taxRate}% IPT) </span>
                            )}
                            {!isIsleChannelResident() && !product.isCalculating === true && (
                              <span> (inc. {this.props.taxRate}% IPT)</span>
                            )}
                          </div>
                          <div className="quote-info quote-info--pink premium">
                            {product.isCalculating === true ? (
                              <div>
                                {' '}
                                <Icon name="refresh" spin/>
                              </div>
                            ) : (
                              displayCurrency(this.props.premium)
                            )}
                          </div>
                        </div>
                      ) : (
                        <div/>
                      )}
                      {isEmpty(this.props.premium) &&
                      this.props.currentTab === OPTIONS_TAB &&
                      !mta && !showBlocker ? (
                        <div className="col-xs-12 quote-divider">
                          <div className="quote-title">
                            Premium
                            {isIsleChannelResident() &&
                            !product.isUpdatingPremiumIndication === true && (
                              <span> (inc. {this.props.taxRate}% IPT) </span>
                            )}
                            {!isIsleChannelResident() &&
                            !product.isUpdatingPremiumIndication === true && (
                              <span> (inc. {this.props.taxRate}% IPT)</span>
                            )}
                          </div>
                          <div className="quote-info quote-info--pink premium">
                            {product.isUpdatingPremiumIndication === true ? (
                              <div>
                                {' '}
                                <Icon name="refresh" spin/>
                              </div>
                            ) : (
                              displayCurrency(this.props.premium)
                            )}
                          </div>
                        </div>
                      ) : (
                        <div/>
                      )}
                      {size(scheme) === 1 &&
                      get(product, 'premiumIndication.meta.scheme.scheme_type', false) ? ( // use premium indication
                        <div>
                          {this.renderSchemeSummary(get(product, 'premiumIndication'), 0, scheme)}
                        </div>
                      ) : (
                        <div>
                          {scheme.map((scheme, i) => {
                            const selection = scheme.id ? product.quotes[scheme.id] : null;
                            if (selection) {
                              return this.renderSchemeSummary(selection, i);
                            }
                          })}
                        </div>
                      )}
                    </div>
                  )}

                  {!mta && !!getPremium && canCommissionAdjust() && (
                    <Field
                      name="data.attributes.metadata.overrides.commission_broker"
                      product={product}
                      reCalculate={this.props.reCalculate}
                      currentProduct={currentProduct}
                      component={CommissionAdjust}
                    />
                  )}

                  {/* Also enable the Saving  */}
                  {
                    !this.props.requiresReferral &&
                    currentTab !== 1 && (
                      <div className="col-xs-12 bottom-bar">
                        <div>
                          {this.renderQuoteSavePolicy()}
                          {this.renderSavePolicyOnly()}
                        </div>
                      </div>
                    )}
                </div>
              </>
            )}
          </Sticky>
        </StickyContainer>
      </div>
    );
  }
}

const FORM_NAME = 'LeisureTravelForm';

const form = reduxForm({ form: FORM_NAME })(Sidebar);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  const values = selector(
    state,
    'data.id',
    'data.current_tab',
    'data.relationships',
    'data.attributes.metadata',
    'meta',
  );

  return {
    auth: state.auth,
    initialValues: props.resource,
    form: 'LeisureTravelForm',
    formValues: values,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    product: state.product,
  };
};

export default connect(mapStateToProps)(form);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Icon } from '../../../../common/components/index';
import { openModal } from '../../../../common/components/Modal/ModalRedux';
import { get, map, capitalize, replace } from 'lodash';
import { FormattedDate } from 'react-intl';
import './styles.scss';

class TravellerInformationModal extends Component {
  conditions(traveller) {
    return (
      <div>
        {get(traveller, 'screening.meta.conditions') ? (
          <div>
            <h5>Declared Conditions</h5>
            <ul>
              {map(get(traveller, 'screening.meta.conditions', []), (condition, i) => {
                return (
                  <li key={i}>
                    <span>{condition}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  }

  options(traveller) {
    return (
      <div>
        {get(traveller, 'metadata.options') && (
          <div>
            <h5> Additional Options</h5>
            <ul>
              {map(get(traveller, 'metadata.options', []), (option, i) => {
                return (
                  option && (
                    <li key={i}>
                      <span>{capitalize(replace(i, '_', ' '))}</span>
                    </li>
                  )
                );
              })}
            </ul>
          </div>
        )}
      </div>
    );
  }

  render() {
    const { dispatch, label, adults, children } = this.props;

    return (
      <div style={{ display: 'inline-block' }}>
        <span className="info" onClick={() => dispatch(openModal('traveller'))}>
          <Icon name="info-circle" size="fa-lg" /> {label}
        </span>

        <Modal
          {...this.props}
          name={'traveller'}
          title="Traveller Information"
          close
          bsSize="small"
          //footer={modalButtons}
        >
          <div className="traveller-modal">
            <div className="row">
              {map(adults, (adult, i) => {
                return (
                  <div key={i} className="col-xs-12">
                    <h4>
                      {capitalize(get(adult, 'first_name'))}{' '}
                      {capitalize(get(adult, 'last_name'))}{' '}
                      {capitalize(get(adult, 'dob')) && (
                        <div className="display-date">
                          <span>
                            <FormattedDate value={get(adult, 'dob')} />
                          </span>
                        </div>
                      )}
                    </h4>
                    {this.conditions(adult)}
                    {this.options(adult)}
                  </div>
                );
              })}
              {map(children, (child, i) => {
                return (
                  <div key={i} className="col-xs-12">
                    <h4>
                      {capitalize(get(child, 'first_name'))}
                      {capitalize(get(child, 'last_name'))}
                      {capitalize(get(child, 'dob')) && (
                        <div className="display-date">
                          <span>
                            <FormattedDate value={get(child, 'dob')} />
                          </span>
                        </div>
                      )}
                    </h4>
                    {this.conditions(child)}
                    {this.options(child)}
                  </div>
                );
              })}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

TravellerInformationModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default connect()(TravellerInformationModal);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAccount } from '../redux/accountActions';
import { Button, Loader } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import AccountFormContainer from './AccountForm';

class AccountEditContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    account: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
  };

  componentWillMount() {
    this.props.dispatch(getAccount(this.props.params.id));
  }

  render() {
    const resource = this.props.account.resources[this.props.params.id];
    const buttons = (
      <Button bsStyle="default" label="back" isBackButton leftIcon="caret-left" />
    );

    return (
      <InternalContainer title="Account" buttons={buttons}>
        {resource ? (
          <AccountFormContainer {...this.props} resource={resource} />
        ) : (
          <Loader />
        )}
      </InternalContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    account: state.account,
  };
}

export default connect(mapStateToProps)(AccountEditContainer);

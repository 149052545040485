import { Box, DataTable } from '../../../../common/components';
import React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import formatDateTime from '../../../../helpers/formatDateTime';

const SchemeAudit = ({ schemeId }) => {
  return (
    <div>
      <Box>
        <h2 className="resource-name">Audit</h2>

        <DataTable
          name="audit"
          showTotal
          source={'/events'}
          autoFilter={schemeId}
          autoFilterType="filter[resource]"
        >
          <TableHeaderColumn dataField="id" isKey hidden/>
          <TableHeaderColumn
            dataField="occurredAt"
            dataFormat={formatDateTime}
            width={'10%'}
            dataSort
          >
            Occurred at
          </TableHeaderColumn>
          <TableHeaderColumn dataField="action" width={'35%'} dataSort>
            Action
          </TableHeaderColumn>
          <TableHeaderColumn dataField="description" width={'35'} dataSort>
            Description
          </TableHeaderColumn>
          <TableHeaderColumn dataField="user_name" width={'20%'}>
            User
          </TableHeaderColumn>
        </DataTable>
      </Box>
    </div>
  );
};

export default SchemeAudit;

import { combineReducers } from 'redux';
import content from '../modules/content/redux/contentReducer';
import auth from '../modules/auth/redux/authReducer';
import layout from '../modules/layout/redux/layoutReducer';
import account from '../modules/account/redux/accountReducer';
import dashboard from '../modules/dashboard/redux/dashboardReducer';
import brochure from '../modules/brochure/redux/brochureReducer';
import postcode from '../common/components/Postcode/redux/PostcodeReducer';
import company from '../common/components/CompanyNumber/redux/CompanyNumberReducer';
import dataTables from '../common/components/DataTable/redux/dataTableReducer';
import broker from '../modules/broker/redux/brokerReducer';

import policy from '../modules/policy/redux/policyReducer';
import printJobs from '../modules/printJobs/redux/printJobsReducer';
import organisation from '../modules/organisation/redux/organisationReducer';
import campaign from '../modules/campaigns/redux/campaignReducer';
import searchResult from '../modules/dashboard/containers/redux/searchActions';
import brokerApplication from '../modules/brokerApplication/redux/brokerApplicationReducer';
import customer from '../modules/customer/redux/customerReducer';
import user from '../modules/user/redux/userReducer';
import role from '../modules/role/redux/roleReducer';
import product from '../modules/product/redux/productReducer';
import alert from '../common/components/Alert/AlertRedux';
import modal from '../common/components/Modal/ModalRedux';
import tabs from '../modules/layout/redux/tabs';
import faq from '../common/components/Faq/FaqRedux';
import screening from '../modules/screening/redux/screeningReducer';
import schemeManagement from '../modules/schemeManagement/redux/schemeManagementReducer';
import document from '../modules/document/redux/documentReducer';
import enquiry from '../modules/enquiry/redux/enquiryReducer';
import sideNav from '../modules/layout/redux/sideNavAction';
import breadcrumb from '../modules/layout/redux/breadcrumb';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as form } from 'redux-form';
import { reducer as toastr } from 'react-redux-toastr';
import { reducer as upload } from '../common/components/Upload/UploadRedux';
import { reducer as trackingLinks } from '../modules/trackingLinks/redux/TrackingLinkReducer';
import { reducer as productManagement } from '../modules/productManagement/redux/ProductReducer';
import documentManagement from '../modules/documentManagement/redux/documentManagementReducer';
import { reducer as selects } from '../common/components/SelectMenu/SelectMenuRedux';
//import selectMenus from '../common/components/SelectMenu/SelectMenuRedux'
// Combine reducers with routeReducer which keeps track of
// router state
const rootReducer = combineReducers({
  account,
  alert,
  auth,
  breadcrumb,
  brochure,
  broker,
  brokerApplication,
  campaign,
  company,
  content,
  customer,
  dashboard,
  dataTables,
  document,
  documentManagement,
  enquiry,
  faq,
  form,
  layout,
  modal,
  organisation,
  policy,
  postcode,
  printJobs,
  product,
  productManagement,
  role,
  routing,
  schemeManagement,
  screening,
  searchResult,
  selects,
  sideNav,
  tabs,
  toastr,
  trackingLinks,
  user,
  upload,
});

export default rootReducer;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { SelectField } from '../../../../common/components/ReduxFormField';
import {
  InfoLabel,
  Alert,
  Box,
  Button,
  HorizontalFormControl,
  SelectOrganisation,
} from '../../../../common/components';
import { Col, Row, Form } from 'react-bootstrap';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';

const FORM_NAME = 'accountForm';

// const validate = (values) => {
//   const errors = {
//     data: {
//       attributes: {},
//     },
//   };
//
//   if (!get(values, 'data.attributes.name')) {
//     errors.data.attributes.name = 'The account name is required';
//   }
//   if (!get(values, 'data.attributes.account_type')) {
//     errors.data.attributes.name = 'The account type is required';
//   }
//   return errors;
// };

class AccountForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    resource: PropTypes.object.isRequired,
    formValues: PropTypes.object,
  };

  render() {
    const { handleSubmit, submitting } = this.props;
    const resource = this.props.resource.data.attributes;

    const accountTypes = [
      { label: 'Accrual', value: 'accrual' },
      { label: 'Asset', value: 'asset' },
      { label: 'Capital', value: 'capital' },
      { label: 'Cash', value: 'cash' },
      { label: 'Control', value: 'control' },
      { label: 'Creditor', value: 'creditor' },
      { label: 'Debtor', value: 'debtor' },
      { label: 'Expenses', value: 'expenses' },
      { label: 'Income', value: 'income' },
      { label: 'Liability', value: 'liability' },
      { label: 'Prepayment', value: 'prepayment' },
    ];

    return (
      <Box>
        <h2 className="resource-name">{resource.name}</h2>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <Alert />
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <div className="form-horizontal">
                <Field
                  name="data.attributes.name"
                  label="Name"
                  labelSize={4}
                  component={resource.locked ? InfoLabel : HorizontalFormControl}
                />

                <Field
                  name="data.attributes.account_type"
                  label="Account Type"
                  labelSize={4}
                  component={SelectField}
                  options={accountTypes}
                />
              </div>
            </Col>

            <Col sm={6}>
              <div className="form-horizontal">
                <Field
                  name="data.attributes.accounting_system_reference"
                  label="Accounting System Reference"
                  labelSize={6}
                  component={HorizontalFormControl}
                />

                <Field
                  name="data.attributes.limit"
                  label="Limit"
                  labelSize={6}
                  component={resource.locked ? InfoLabel : HorizontalFormControl}
                />
              </div>
            </Col>
          </Row>

          <div className="hr" />

          <Row>
            {!this.props.resource.data.id && (
              <Col xs={12}>
                <div className="form-horizontal">
                  <Field
                    name="data.relationships.organisation.data"
                    label="Organisation"
                    labelSize={2}
                    object
                    component={SelectOrganisation}
                  />
                </div>
              </Col>
            )}
          </Row>

          <Row>
            <Col sm={12}>
              <InternalButtonBar buttons={[
                {
                  permissions: ['account.edit'],
                  button: (
                    <Button
                      className="pull-right"
                      type="submit"
                      bsStyle="primary"
                      label="Save Account"
                      isLoading={submitting}
                    />
                  )
                }
              ]}/>
            </Col>
          </Row>

        </Form>
      </Box>
    );
  }
}

const form = reduxForm({ form: FORM_NAME })(AccountForm);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  const values = selector(state, 'data.id', 'data.relationships.organisation.data.id');

  return {
    initialValues: props.resource,
    formValues: values,
  };
};

export default connect(mapStateToProps)(form);

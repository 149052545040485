import React from 'react';
import { Field } from 'redux-form';
import { HorizontalFormControl } from '../../../../common/components';
import CheckboxUnlabeled
  from '../../../../common/components/ReduxFormField/CheckboxUnlabeled';

import { required } from 'redux-form-validators';
import AsyncSelectMenu from '../../../../common/components/SelectMenu/AsyncSelectMenu';
import { Col, ControlLabel, FormGroup } from "react-bootstrap";

const isRequired = required({ message: 'This field is required' });

const isAlphaNumeric = value => value && /[^a-zA-Z0-9]/i.test(value)
  ? 'Only alphanumeric characters allowed'
  : undefined;

const WhiteLabelSummary = ({ hasWhiteLabel, customDomain, brokerId }) => {
  return (
    <>
    {!hasWhiteLabel && (
      <>
        {customDomain ? (
          <Field
            name="meta.url"
            type="url"
            label="URL"
            labelSize={4}
            component={HorizontalFormControl}
            validate={[isRequired, isAlphaNumeric]}
          />
        ) : (
          <Field
            name="meta.url"
            label="Sub domain"
            labelSize={4}
            colSize={9}
            component={HorizontalFormControl}
            validate={[isRequired, isAlphaNumeric]}
            cleaveOptions={{ lowercase: true }}
            addonAfter={'.pjhaymanonline.com'}
          />
        )}
        <Field
          name="meta.custom_domain"
          label="Set custom domain"
          labelSize={4}
          component={CheckboxUnlabeled}
          options={[{ label: '', value: true }]}
        />
        <FormGroup>
          <Col sm={4}>
            <ControlLabel>
              Product
            </ControlLabel>
          </Col>
          <Col>

            <Field
              name="data.relationships.product.data.id"
              url={`/whitelabel-management/broker/${brokerId}/whitelabel-products?`}
              optionValuePath={'id'}
              component={AsyncSelectMenu}
              validate={required({msg: 'A product is required'})}
            />
          </Col>
        </FormGroup>


        </>
      )}
    </>
  );
};
export default WhiteLabelSummary;

import crypto from 'crypto';

export function encrypt(text) {

  const key =  process.env.APP_KEY;
  const iv = crypto.randomBytes(16);
  const cipher = crypto.createCipheriv('aes-256-gcm',key, iv);

  let value = cipher.update(text);
  value = Buffer.concat([value, cipher.final()]);
  const mac = crypto
    .createHmac('sha256', key)
    .update(Buffer.concat([iv, value, cipher.getAuthTag()]))
    .digest();
  const json = JSON.stringify({
    iv: iv.toString('base64'),
    authTag: cipher.getAuthTag().toString('base64'),
    value: value.toString('base64'),
    mac: mac.toString('hex'),
  });

  return Buffer.from(json).toString('base64');

}

export function decrypt(text) {

  const key =process.env.APP_KEY;
  const json = JSON.parse(Buffer.from(text, 'base64').toString());

  const iv = Buffer.from(json.iv, 'base64');
  const encryptedValue = Buffer.from(json.value, 'base64');
  const authTag = Buffer.from(json.authTag, 'base64');
  const mac = crypto
    .createHmac('sha256', key)
    .update(Buffer.concat([iv, encryptedValue, authTag]))
    .digest();

  if (mac.toString('hex') !== json.mac) {
    throw new Error("Error: Generated mac doesn't match the given mac");
  }



  const decipher = crypto.createDecipheriv('aes-256-gcm', key, iv);
  decipher.setAuthTag(authTag);
  let value = decipher.update(encryptedValue);
  value = Buffer.concat([value, decipher.final()]);

  return value.toString();

}

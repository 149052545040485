import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getIncludedResponse from '../../../../helpers/getIncludedResource';
import { ConditionQuestionEdit } from '../ConditionQuestionEdit/ConditionQuestionEdit';
import { setAnswer } from '../../redux/screeningActions';

export default class BooleanQuestion extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    screening: PropTypes.object.isRequired,
    condition: PropTypes.object.isRequired,
    question: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      answer: '',
    };
  }

  componentWillReceiveProps(props) {
    const options = getIncludedResponse(
      props.question,
      props.screening.declaration.included,
      'options',
    );

    if (props.screening.isSubmitting) {
      return;
    }

    const selectedAnswers = getIncludedResponse(
      props.question,
      props.screening.declaration.included,
      'selected',
    );

    Object.keys(options).map((id) => {
      const option = options[id];

      if (option.attributes.is_selected && this.state.answer !== option.attributes.number) {
        this.setState({ answer: option.attributes.number });
      }

      if (!selectedAnswers.length && option.attributes.should_be_checked === true) {
        this.setState({ answer: option.attributes.number }, ::this.handleSubmit);
      }
    });
  }

  componentDidUpdate() {
    const element = document.getElementById('active-boolean-question');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  handleSubmit() {
    const { condition, question, dispatch } = this.props;
    const { answer } = this.state;
    dispatch(setAnswer(condition, question, { value: answer }));
  }

  handleRadioChange(e) {
    if (e.target === undefined) {
      return;
    }
    const answer = e.target.value;
    this.setState({ answer }, ::this.handleSubmit);
  }

  render() {
    const { question, screening, dispatch, condition } = this.props;
    const { answer } = this.state;
    const optionsResource = getIncludedResponse(
      question,
      screening.declaration.included,
      'options',
    );
    const selectedAnswers = getIncludedResponse(
      question,
      screening.declaration.included,
      'selected',
    );
    const isActiveQuestion = question?.attributes?.is_active || false;

    return (
      <div className="row question">
        <div className="col-xs-12">
          <label className="control-label">{question.attributes.text}</label>
          {question.attributes.info ? (
            <p className="text-info text-justify" style={{ fontSize: '14px' }}>
              {question.attributes.info}
              <br />
            </p>
          ) : (
            ''
          )}
          {question.attributes.warning ? (
            <p className="text-danger text-justify" style={{ fontSize: '14px' }}>
              {question.attributes.warning}
              <br />
            </p>
          ) : (
            ''
          )}

          {!isActiveQuestion && (
            <ConditionQuestionEdit
              selectedAnswers={selectedAnswers}
              screening={screening}
              dispatch={dispatch}
              condition={condition}
              question={question}
            />
          )}

          <div className={'screening-border-bottom'}>
            {isActiveQuestion && optionsResource.length > 0 && (
              <div className="radio-container" id={'active-boolean-question'}>
                {optionsResource.map((option, i) => {
                  const inputId = option.id;
                  const optionAnswer = question.attributes.warning ? '' : answer;
                  return (
                    <div
                      key={i}
                      className={'radio-container radio-inline'}
                      style={{ marginTop: '10px' }}
                    >
                      <div
                        className={'form-radio ' + option.attributes.text.toLowerCase()}
                      >
                        <input
                          type="radio"
                          id={inputId}
                          value={option.attributes.number}
                          onChange={::this.handleRadioChange}
                          checked={optionAnswer == option.attributes.number}
                        />
                        <label htmlFor={inputId} />
                      </div>
                      <label
                        className="radio-label radio-label-condition-question"
                        htmlFor={inputId}
                      >
                        {option.attributes.text}
                      </label>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

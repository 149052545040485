import { get, isObject, map, startCase } from "lodash";
import React from "react";
import InfoButton from "../../../../common/components/InfoButton";

const DefinitionSection = ({ definition, name }) => (
  <div className="section-item">
    <h5><InfoButton content={definition}/> {startCase(name)}</h5>
  </div>
);
export const Definition = ({ definition, name }) => {
  if (!definition) {
    return null;
  }
  if (!isObject(definition)) {
    return (
      <DefinitionSection definition={definition} name={name}/>
    );
  }
  if (name === 'hazardous_activities') {
    return (<div>
      <DefinitionSection definition={definition} name={'Activity Pack'}/>
      {map(definition, (subDefinition, subKey) => {
        if (!['content', 'title'].includes(subKey)) {
          return (<DefinitionSection
            definition={subDefinition}
            name={subKey}
          />);
        }
      })}
    </div>);
  }
  if (null !== get(definition, 'content', null)) {
    return (
      <DefinitionSection definition={definition} name={name}/>
    );
  }
  return null;
};

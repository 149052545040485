import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { Box, DataTable, Button, Modal } from '../../../../common/components';
import {
  applyFilter,
  refreshData,
} from '../../../../common/components/DataTable/redux/dataTableActions';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { Col, FormGroup, ControlLabel } from 'react-bootstrap';
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux';
import TextField from '../../../../common/components/ReduxFormField';
import TextArea from '../../../content/components/TextArea/TextArea';
import { updateEndorsement } from '../../redux/policyActions';
import { FormattedDate } from 'react-intl';
import { get, set } from 'lodash';
import Select from 'react-select';
import selectTheme, { styles } from '../../../../helpers/selectTheme';

const FORM_NAME = 'policyEndorsementsForm';

const validate = (values) => {
  const errors = {};

  if (!get(values, 'data.attributes.title')) {
    set(errors, 'data.attributes.title', 'Endorsement title is required');
  }

  if (!get(values, 'data.attributes.content')) {
    set(errors, 'data.attributes.content', 'Endorsement content is required');
  }

  return errors;
};

class PolicyEndorsements extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    reference: PropTypes.string.isRequired,
  };

  formatDate(cell) {
    return <FormattedDate value={new Date(cell)} />;
  }

  handleFilterUpdate(option) {
    this.props.dispatch(
      applyFilter('endorsements', 'filter[version]', option.value),
    );
  }

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded } = this.props;

    if (newProps.submitSucceeded && !submitSucceeded) {
      dispatch(refreshData('endorsements'));
      dispatch(closeModal(FORM_NAME));
    }
  }

  handleSubmit(values) {
    return this.props.dispatch(updateEndorsement(values));
  }

  handleRowClick(row) {
    const { dispatch, reset, change } = this.props;

    dispatch(reset());
    dispatch(change('data.id', row.id));
    dispatch(change('data.attributes.title', row.title));
    dispatch(change('data.attributes.content', row.content));
    dispatch(openModal(FORM_NAME));
  }

  render() {
    const { reference, submitting, handleSubmit, resource } = this.props;

    if (!resource.data.id) {
      return null;
    }

    const title = 'Edit Endorsement';
    const submitButton = (
      <Button
        type="submit"
        bsStyle="primary"
        disabled={submitting}
        isLoading={submitting}
        label="Save Changes"
      />
    );

    return (
      <div>
        <Box>
          <h2 className="resource-name">{reference}</h2>

          <div className="row">
            <div className="col-sm-4 col-sm-offset-8">
              <FormGroup controlId="select-status">
                <Col componentClass={ControlLabel} sm={4}>
                  Versions
                </Col>
                <Col sm={8}>
                  <Select
                    theme={(theme) => selectTheme(theme)}
                    styles={styles}
                    onChange={(option) => this.handleFilterUpdate(option)}
                    options={[
                      { label: 'Current', value: '' },
                      { label: 'All', value: 'all' },
                    ]}
                    defaultValue={{ label: 'Current', value: '' }}
                  />
                </Col>
              </FormGroup>
            </div>
          </div>

          <div className="hr transparent" />

          <DataTable
            source="policies/endorsements"
            autoFilters={[{ type: 'filter[policy]', value: this.props.resource.data.id }]}
            name="endorsements"
            showTotal
            onRowSelect={this.handleRowClick.bind(this)}
          >
            <TableHeaderColumn dataField="id" isKey hidden />
            <TableHeaderColumn dataField="title" dataSort>
              Name
            </TableHeaderColumn>
            <TableHeaderColumn dataField="version_number" dataSort width={'100px'}>
              Version
            </TableHeaderColumn>
            <TableHeaderColumn dataField="amount" dataSort width={'100px'}>
              Amount
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="issue_date"
              dataFormat={this.formatDate}
              dataSort
              width={'100px'}
            >
              Issued Date
            </TableHeaderColumn>
          </DataTable>

          <Modal
            name={FORM_NAME}
            title={title}
            close
            handleSubmit={handleSubmit(::this.handleSubmit)}
            footer={submitButton}
          >
            <div className="form-horizontal">
              <div className="col-xs-12">
                <div className="col-xs-12">
                  <Field
                    name={'data.attributes.title'}
                    label="Endorsement Title"
                    component={TextField}
                  />
                </div>

                <label className="control-label">Endorsement Content</label>
                <Field name={'data.attributes.content'} component={TextArea} />
              </div>
            </div>
          </Modal>
        </Box>
      </div>
    );
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(PolicyEndorsements);

const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state) => {
  const values = selector(state, 'data.id', 'data.attributes.title');

  return {
    formValues: values,
  };
};

export default connect(mapStateToProps)(form);

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getSignpost, updateSignpost } from '../redux/contentActions';
import { Box, Button, Loader } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import { bindActionCreators } from 'redux';
import SignpostForm from './SignpostForm';

const SignpostEditContainer = ({ params, isFetching, getSignpost, updateSignpost }) => {
  const id = params.id;
  const [signpost, setSignpost] = useState(null);

  useEffect(() => {
    Promise.resolve(getSignpost(id)).then((resp) => {
      setSignpost(resp);
    });

  }, [id, getSignpost]);

  const handleUpdateSignpost = (values) => {
    updateSignpost(id, values);
  };

  if (isFetching && !signpost) {
    return <Loader/>;
  }
  if (!isFetching && !signpost) {
    return (
      <Box>
        No Signposts found
      </Box>
    );
  }


  return (
    <InternalContainer title="Content Management"
                       buttons={<Button bsStyle="default" label="back" isBackButton={true} leftIcon="caret-left"/>}>
      <SignpostForm
        signpost={signpost}
        updateSignpost={handleUpdateSignpost}
      />
    </InternalContainer>
  );

};
export default connect(
  (state) => ({
    isFetching: state.content.isFetchingSignposts,
  }),
  (dispatch) => ({
    getSignpost: bindActionCreators(getSignpost, dispatch),
    updateSignpost: bindActionCreators(updateSignpost, dispatch),
  }),
)(SignpostEditContainer);


import React, { useRef, useState } from 'react';
import SimpleModal from '../../../../../common/components/SimpleModal/SimpleModal';

const WhiteLabelEditor = ({ isEditing, onCloseEditor, url, onUpdateTheme }) => {

  const [isReady, setIsReady] = useState(false);

  const form = useRef();
  const targetOriginRef = React.useRef(null);

  const handleEditorSave = (event) => {
    if (event.origin !== targetOriginRef.current) {
      return;
    }

    const { type, data } = event.data;
    switch (type) {
      case 'SAVE_THEME':
        targetOriginRef.current = null;
        //save
        window.removeEventListener('message', handleEditorSave);
        onUpdateTheme(data.theme, data.metadata);
        closeEditor();

        break;
      case 'CLOSE_EDITOR':
       closeEditor();
        break;
      default:
        // tslint:disable-next-line:no-console
        console.error(`Unknown postMessage event type of ${event.data.type}`);
    }
  };



  const handleOnLoad = () => {
    const parser = document.createElement('a');
    parser.href = url;

    const targetOrigin = parser.protocol + '//' + parser.host;
    targetOriginRef.current = targetOrigin;

    form.current.contentWindow.postMessage(
      {
        type: 'OPEN_EDITOR',
      },
      targetOrigin,
    );

    // if (this.state.metadata) {
    //   this.form.contentWindow.postMessage(
    //     {
    //       type: 'SET_FORM',
    //       metadata: this.state.metadata,
    //     },
    //     targetOrigin,
    //   );
    // }

    // this.listener =
    window.addEventListener('message', handleEditorSave);

    setIsReady(true);
    // this.setState({
    //   ready: true,
    // });
  };



  const closeEditor = () => {
    onCloseEditor(false);
  };

  return (
    <SimpleModal
      className="whitelabel-modal"
      isOpen={isEditing}
      onRequestClose={closeEditor}
    >
      <div className={'whitelabel__editor'}>
        <h1>Whitelabel Editor</h1>

        <div className={'whitelabel__editor__viewport'}>
          <div className={isReady ? 'hidden' : 'whitelabel__editor__viewport__loader'}>
            <h3>Loading Editor</h3>

            <span className="loader-dots">
                <i className="fa fa-circle loader-dot" />
                <i className="fa fa-circle loader-dot" />
                <i className="fa fa-circle loader-dot" />
              </span>
          </div>

          <iframe
            // ref={(ref) => (this.form = ref)}
            ref={form}
             src={url}
            className={'whitelabel__editor__iframe' + (isReady ? '' : ' hidden')}
            onLoad={handleOnLoad}
          />
        </div>
      </div>
    </SimpleModal>
  );
};
export default WhiteLabelEditor;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import {
  Box,
  DataTable,
  CreateNoteButton,
  NotesList,
} from '../../../../common/components';
import { FormattedDate, FormattedTime } from 'react-intl';
import { refreshData } from '../../../../common/components/DataTable/redux/dataTableActions';

export default class PolicyNotes extends Component {
  formatDate(cell) {
    return (
      <span>
        <FormattedDate value={new Date(cell)} /> <FormattedTime value={new Date(cell)} />
      </span>
    );
  }

  refreshList() {
    this.props.dispatch(refreshData('notes'));
  }

  render() {
    const { reference } = this.props;
    const filters = () => {
      const filter = this.props.resource.data.attributes.policy_number
            ? this.props.resource.data.id
            : this.props.resource.data.attributes.quote_reference;
      const type = this.props.resource.data.attributes.policy_number
          ? 'filter[resource]'
          : 'filter[quote_reference]';
      return { type: type, value: filter };
    };

    return (
      <div>
        <Box>
          <h2 className="resource-name">{reference}</h2>

          <DataTable
            source={'/notes'}
            autoFilters={[
              { type: 'include', value: 'attachments' },
              filters()
            ]}
            name="notes"
            showTotal
            component={NotesList}
          />
          <Row>
            <Col sm={12}>
              <InternalButtonBar buttons={[
                {
                  permissions: ['policy.notes_create'],
                  button: (
                    <CreateNoteButton
                      label="Add a Note"
                      className="pull-right"
                      notableResource={this.props.resource}
                      notableResourceType={
                        this.props.resource.data.attributes.policy_number
                          ? 'policies'
                          : 'policy-applications'
                      }
                      callback={::this.refreshList}
                    />
                  ),
                },
              ]}/>
            </Col>
          </Row>
        </Box>
      </div>
    );
  }
}

PolicyNotes.propTypes = {
  dispatch: PropTypes.func.isRequired,
  resource: PropTypes.object.isRequired,
  reference: PropTypes.string.isRequired,
};

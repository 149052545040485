export default function selectTheme(theme) {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      neutral0: '#efefef',
      primary: 'hsl(0,0%,80%)',
      primary75: 'hsl(0,0%,90%)',
      primary50: 'hsl(0,0%,90%)',
      primary25: 'hsl(0,0%,90%)',
      neutral20: 'hsl(0,0%,80%)',
      neutral30: 'hsl(0,0%,90%)',
      neutral50: 'hsl(0,0%,70%)',
      neutral90: 'teal',
    },
  };
}

const colors = {
  selected: '#f6f6f6',
  focused: '#efefef',
};

export const styles = {
  control: (base) => ({
    ...base,
    minHeight: '30px',
    borderStyle: 'none',
    boxShadow: 'inset 0px 1px 6px rgba(0, 0, 0, 0.1) !important;',
    '&:hover': {
      boxShadow: 'none',
    },
  }),
  option: (base, { isDisabled, isFocused, isSelected }) => ({
    ...base,
    backgroundColor: isDisabled
      ? null
      : isSelected
      ? colors.selected
      : isFocused
      ? colors.focused
      : null,
    color: '#333',
  }),
  menu: (base) => ({
    ...base,
    background: 'white',
    zIndex: 10,
  }),
  multiValue: (base) => ({
    ...base,
    border: '1px solid #243b79',
    borderRadius: '5px',
  }),
  multiValueLabel: (base) => ({
    ...base,
    borderRight: '1px solid #243b79',
    borderRadius: 0,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  clearIndicator: (provided) => {
    const padding = '8px 0px';
    return { ...provided, padding };
  },
  dropdownIndicator: (provided) => {
    const padding = '8px 7px 8px 0px';
    return { ...provided, padding };
  },
  multiValueRemove: (styles, {}) => ({
    ...styles,
    ':hover': {
      color: '#2f4d9f',
    },
  }),
};

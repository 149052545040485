import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap';
import {
  cancelPrintJob,
  getPrintJob,
  runPrintJob,
  unloadResource,
  downloadDocument,
  postPrintJob,
} from '../redux/printJobsActions';
import { Button, Loader, Box } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import PrintJobsDetails from '../components/PrintJobsDetails';
import ReactTimeout from 'react-timeout';
import PrintJobsAudit from '../components/PrintJobsAudit';

class PrintJobsDetailsContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      activeTab: 1,
      timerId: null,
      openModal: false,
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleRun = this.handleRun.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.handlePost = this.handlePost.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  startTimer() {
    const { dispatch } = this.props;
    const timerId = this.props.setInterval(() => dispatch(this.handleLoad), 5000);
    this.setState({ timerId });
  }

  stopTimer() {
    this.props.clearInterval(this.state.timerId);
    this.setState({ timerId: null });
  }

  componentWillReceiveProps({ printJobs: { resource } }) {
    if (!resource) {
      return;
    }
    const newState = resource.data.attributes.state;

    if (
      (newState === 'processing' || newState === 'submitted') &&
      this.state.timerId === null
    ) {
      this.startTimer();
    } else if (this.state.timerId !== null) {
      this.stopTimer();
    }
  }

  componentWillMount() {
    this.handleLoad();
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(unloadResource());
  }

  handleTab(key) {
    this.setState({ activeTab: key });
  }

  handleCancel() {
    const { dispatch, params } = this.props;
    dispatch(cancelPrintJob(params.id));
  }

  handleRun() {
    const { dispatch, params } = this.props;
    this.setState({
      openModal: true,
    });
    dispatch(runPrintJob(params.id));
  }

  closeModal() {
    this.setState({
      openModal: false,
    });
  }

  handlePost() {
    const { dispatch, params } = this.props;
    dispatch(postPrintJob(params.id));
  }

  handleLoad() {
    const { dispatch, params } = this.props;

    dispatch(getPrintJob(params.id));
  }

  handleDownload(row) {
    const { dispatch, params } = this.props;

    dispatch(downloadDocument(params.id, row.id));
  }

  render() {
    const { isLoading, resource } = this.props.printJobs;
    const buttons = (
      <Button bsStyle="default" label="Back" isBackButton leftIcon="caret-left" />
    );

    return (
      <InternalContainer title={'Print Jobs'} buttons={buttons}>
        {resource ? (
          <Tabs
            activeKey={this.state.activeTab}
            id="organisation-tabs"
            unmountOnExit
            onSelect={this.handleTab.bind(this)}
          >
            <Tab eventKey={1} title={'Details'}>
              <Box className={isLoading && resource ? 'currently-updating' : ''}>
                <PrintJobsDetails
                  openModal={this.state.openModal}
                  closeModal={this.closeModal}
                  {...this.props}
                  resource={resource}
                  onRun={this.handleRun}
                  onCancel={this.handleCancel}
                  onRefresh={this.handleLoad}
                  onDownload={this.handleDownload}
                  onPost={this.handlePost}
                />
              </Box>
            </Tab>
            <Tab eventKey={2} title="Audit">
              <Box className={isLoading && resource ? 'currently-updating' : ''}>
                <PrintJobsAudit {...this.props} resource={resource} />
              </Box>
            </Tab>
          </Tabs>
        ) : (
          <Loader />
        )}
      </InternalContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    printJobs: state.printJobs,
  };
}

export default connect(mapStateToProps)(ReactTimeout(PrintJobsDetailsContainer));

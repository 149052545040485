import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getUser,
  getUserOrganisationRoles,
  removeUserOrganisation,
  isLoading,
} from '../redux/userActions';
import { bindActionCreators } from 'redux';
import getIncludedResource from '../../../helpers/getIncludedResource';
import { Button, Loader } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import UserDetails from '../components/UserDetails/UserDetails';
import UserRoles from '../components/UserRoles';
import UserNotes from '../components/UserNotes/UserNotes';
import UserAudit from '../components/UserAudit';
import InternalTabs from '../../layout/components/InternalTabs/InternalTabs';
import { SET_ACTIVE_TAB } from '../../layout/redux/tabs';
import { UserContext } from '../context';

const TAB_NAME = 'userTabs';

class UserShowContainer extends Component {
  constructor(props) {
    super(props);
    this.isInOwnerOrganisation = this.isInOwnerOrganisation.bind(this);
    this.handleRemoveUserFromOrganisation = this.handleRemoveUserFromOrganisation.bind(
      this,
    );
  }

  componentWillMount() {
    const { actions } = this.props;
    actions.getUser(this.props.params.id);
    actions.getUserOrganisationRoles(this.props.params.id);
    this.props.dispatch({
      type: SET_ACTIVE_TAB,
      name: TAB_NAME,
      number: 1,
    });
  }

  handleRemoveUserFromOrganisation({ userId, orgId }) {
    const { actions, params } = this.props;

    Promise.resolve(actions.isLoading(true))
      .then(() => actions.removeUserOrganisation(userId, orgId))
      .then(() => actions.getUser(params.id))
      .then(() => actions.getUserOrganisationRoles(params.id))
      .then(() => actions.isLoading(false));
  }

  isInOwnerOrganisation(resource) {
    const organisations = getIncludedResource(
      resource.data,
      resource.included,
      'organisations',
    );
    let isOwner = false;

    if (organisations) {
      organisations.forEach((organisation) => {
        if (organisation.attributes.organisation_type_name === 'Owner') {
          isOwner = true;
        }
      });
    }

    return isOwner;
  }

  tabs() {
    const resource = this.props.user.resources[this.props.params.id];
    return [
      {
        permissions: ['user.view'],
        title: 'Details',
        content: (
          <UserDetails
            {...this.props}
            resource={resource}
            isInOwnerOrganisation={this.isInOwnerOrganisation(resource)}
          />
        ),
      },
      {
        permissions: ['user.view'],
        title: 'Organisation Roles',
        content: (
          <UserContext.Provider
            value={{ removeUserFromOrganisation: this.handleRemoveUserFromOrganisation }}
          >
            <UserRoles
              user={this.props.user}
              dispatch={this.props.dispatch}
              resource={resource}
            />
          </UserContext.Provider>
        ),
      },
      {
        permissions: ['user.notes_view'],
        orgTypes: ['owner'],
        title: 'Notes',
        content: <UserNotes {...this.props} resource={resource} />,
      },
      {
        permissions: ['system.audit_logs_view'],
        orgTypes: ['owner'],
        title: 'Audit',
        content: <UserAudit {...this.props} resource={resource} />,
      },
    ];
  }

  render() {
    const { user } = this.props;
    const resource = user.resources[this.props.params.id];
    const contact = resource ? resource.data.attributes.status === 'contact' : false;
    const buttons = (
      <Button bsStyle="default" label="Back" isBackButton leftIcon="caret-left" />
    );

    return (
      <InternalContainer title={contact ? 'Contact' : 'User'} buttons={buttons}>
        {resource && !user.isLoading ? (
          <InternalTabs id={TAB_NAME} tabs={this.tabs()} />
        ) : (
          <Loader />
        )}
      </InternalContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(
      { removeUserOrganisation, getUser, getUserOrganisationRoles, isLoading },
      dispatch,
    ),
    dispatch,
  };
};

UserShowContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatch,
)(UserShowContainer);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import {
  Modal,
} from '../../../../common/components';
import {
  switchBroker,
  switchOrganisation,
} from '../../redux/authActions';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import { closeModal } from '../../../../common/components/Modal/ModalRedux';
import { Row, Col } from 'react-bootstrap';
import { push } from 'react-router-redux';
import './SettingsForm.scss';
import Loader from '../../../../common/components/Loader/Loader';

const FORM_NAME = 'organisation-settings';

export class OrganisationSettingsForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isSwitchingOrganisation: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
  }

  renderOrganisations() {
    const { resource, dispatch } = this.props;
    const includedOrganisations = getIncludedResource(
      resource.data,
      resource.included,
      'organisations',
    );

    const organisations = includedOrganisations.filter((organisation) => {
      return organisation.attributes.organisation_type_name !== 'Broker';
    });

    return (
      <Row>
        <Col xs={12}>
          <div className="hr" />
          {organisations.map((organisation) => {
            return (
              <h3
                key={organisation.id}
                className="organisation-selection"
                onClick={() => {
                  Promise.resolve(
                    this.props.dispatch(
                      switchOrganisation(organisation.id),
                    ),
                  ).then(() => {
                    dispatch(push('/dashboard'));
                    dispatch(closeModal(FORM_NAME));
                  });
                }}
              >
                {organisation.attributes.name}
              </h3>
            );
          })}
        </Col>
      </Row>
    );
  }


  renderBrokers() {
    const { resource, dispatch } = this.props;
    const brokers = getIncludedResource(
      resource.data,
      resource.included,
      'brokers',
    );

    return (
      <Row>
        <Col xs={12}>
          <div className="hr" />
          {brokers.map((broker) => {
            return (
              <h3
                key={broker.id}
                className="organisation-selection"
                onClick={() => {
                  Promise.resolve(
                    this.props.dispatch(
                      switchBroker(broker.id),
                    ),
                  ).then(() => {
                    dispatch(push('/dashboard'));
                    dispatch(closeModal(FORM_NAME));
                  });
                }}
              >
                {broker.attributes.name}
              </h3>
            );
          })}
        </Col>
      </Row>
    );
  }

  render() {
    const { isSwitchingOrganisation } = this.props;

    return (
      <Modal
        {...this.props}
        name={FORM_NAME}
        backdropClassName="extra-dark"
        title="Organisation Settings"
        closeButton={false}
        enforceFocus={true}
      >
        {isSwitchingOrganisation ? (
          <Loader noShadow />
        ) : (
          <div>
            {this.renderOrganisations()}
            {this.renderBrokers()}
          </div>
        )}
      </Modal>
    );
  }
}

const form = reduxForm({
  form: FORM_NAME,
})(OrganisationSettingsForm);

const selector = formValueSelector(FORM_NAME);
export default connect((state, props) => {
  const values = selector(
    state,
    'data.id',
    'data.relationships.current_organisation.data.id',
    'data.relationships.current_broker.data.id',
  );
  return {
    initialValues: props.resource,
    formValues: values,
    files: state.upload.files,
    isSwitchingOrganisation: state.auth.isSwitchingOrganisation,
  };
})(form);

import React from 'react';
import { Field, FieldArray } from 'redux-form';
import {
  ButtonBar,
  CheckboxControl,
  HorizontalFormControl,
  Icon,
} from '../../../../../common/components';
import TextArea from '../../../../content/components/TextArea';
import { Col, ControlLabel, FormGroup, Row } from 'react-bootstrap';
import { SelectField } from '../../../../../common/components/ReduxFormField';
import { Button } from '../../../../../common/components/Button/Button';
import { addValidator, required } from 'redux-form-validators';

import './EditProductMedical.scss';


const keyValidator = addValidator({
  validator(options, value, all) {
    if (!value) {
      return {
        id: options.field,
        defaultMessage: 'Key is required',
      };
    }
    const medical = all?.meta?.medical;
    const keys = medical?.filter((m) => m.key === value);

    if (keys?.length > 1) {
      return {
        id: options.field,
        defaultMessage: 'Duplicate key',
      };
    }

    const keyRegex = /^([a-z]|-)+$/;


    if (value && !keyRegex.test(value)) {
      return {
        id: options.field,
        defaultMessage: 'Incorrect key format',
      };
    }
  }
});

const questionNumberValidator = addValidator({
  validator(options, value, all) {
    if (!value) {
      return {
        id: options.field,
        defaultMessage: 'Question number is required',
      };
    }
    const parsedValue = Number.parseInt(value);
    const medical = all?.meta?.medical;
    const questionNumbers = medical?.reduce((carry, m) => {
      const questionNumber = m.question.question_number;
      if (questionNumber === parsedValue) {
        carry.push(questionNumber);
      }
      return carry;
    }, []);


    if (questionNumbers?.length > 1) {
      return {
        id: options.field,
        defaultMessage: 'Duplicate question number',
      };
    }
  }
});

const DEFAULT_QUESTION = {
  header: '',
  content: '',
  show_medical: true,
  category: '',
  type: 'boolean',
};


const CATEGORY_OPTIONS = [
  {
    label: 'Health',
    value: 'health'
  },
  {
    label: 'Medical',
    value: 'medical'

  }
];

const TextAreaInput = ({ meta, input }) => {
  return (
    <>
      <Field
        name={input.name}
        component={TextArea}
      />
      {meta?.touched && meta?.error && (
        <span className={'input-error'}>{meta.error}</span>
      )}
    </>
  );

};

const LABEL_SIZE = 2;
const QuestionField = ({ input }) => {

  return (
    <>
      <Field
        name={`${input.name}.question_number`}
        label={'Question Number'}
        labelSize={LABEL_SIZE}
        type={'number'}
        mdFieldSize={2}
        validate={questionNumberValidator({ field: `${input.name}.question_number` })}
        parse={Number.parseInt}
        component={HorizontalFormControl}
      />
      <FormGroup>
        <Col sm={12}>
          <ControlLabel>Header</ControlLabel>
        </Col>
      </FormGroup>
      <Field
        name={`${input.name}.header`}
        component={TextArea}
        label={'text'}
      />
      <FormGroup>
        <Col sm={12}>
          <ControlLabel>Content</ControlLabel>
        </Col>
      </FormGroup>
      <Field
        name={`${input.name}.content`}
        component={TextAreaInput}
        validate={required({ msg: 'Content is required' })}
        //     validate={required({ msg: 'Content is required' })}

      />

      <FormGroup>
        <Col sm={8}>
          <Field
            name={`${input.name}.show_medical`}
            label={'Show Screening on YES'}
            options={[{ label: ' ', value: true }]}
            component={CheckboxControl}
          />
        </Col>
      </FormGroup>
      {/*<Field*/}
      {/*  name={`${input.name}.type`}*/}
      {/*  label={'Type'}*/}
      {/*  component={HorizontalFormControl}*/}
      {/*/>*/}
      <Field
        name={`${input.name}.category`}
        labelSize={LABEL_SIZE}
        label={'Category'}
        options={CATEGORY_OPTIONS}
        component={SelectField}
      />

    </>

  );
};

const Question = ({ field, onRemove, index }) => {
  const remove = () => {
    onRemove(index);
  };
  return (
    <>
      <Row>
        <Col sm={12}>
          <div className={'edit-product-medical--remove'} onClick={remove}>
            <Icon name={'trash'}/>
          </div>
        </Col>
      </Row>
      <Field
        name={`${field}.key`}
        label={'Question Key'}
        validate={keyValidator({ field: field })}
        component={HorizontalFormControl}
      />
      <Field
        name={`${field}.question`}
        component={QuestionField}
      />

    </>
  );
};
const EditProductMedicalFieldArray = ({ fields }) => {

  const removeQuestion = (index) => {
    fields.remove(index);
  };

  const addQuestion = () => {
    const newQuestion = {
      key: '',
      question: {
        ...DEFAULT_QUESTION,
        question_number: fields.length + 1,
      }
    };

    fields.push(newQuestion);
  };
  return (
    <>
      {fields.map((field, index) => (
        <>
          <Question
            field={field}
            onRemove={removeQuestion}
            index={index}
          />
          <hr/>
        </>
      ))}

      <ButtonBar>
        <Button
          handleClick={addQuestion}
          className={'pull-right'}
        >
          Add another question
        </Button>

      </ButtonBar>
    </>

  );

};
export const EditProductMedical = ({}) => {
  return (
    <>
      <FormGroup>
        <Col sm={12}>
          <ControlLabel>No Screening Warning Message</ControlLabel>
        </Col>
      </FormGroup>
      <Field
        name={'data.attributes.metadata.medical_content.medical_no_screening_warning_message'}
        component={TextArea}
      />

      <h2>Medical Questions</h2>
      <FieldArray
        name={'meta.medical'}
        component={EditProductMedicalFieldArray}
      />
    </>
  );

};



import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import React, { useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import changesToContent from '../../../../helpers/changesToContent';
import moment from 'moment';
import {
  Field,
  getFormError,
  getFormSubmitErrors,
  getFormValues,
  reduxForm
} from 'redux-form';

import {
  calculateWlAdjustment,
  calculateWlAdjustmentAndSignpost,
  clearPremiumOverrideCalculation,
  createApplication,
  getMTAPremiumOverrideCalculation,
  getPremiumIndication,
  getPremiumOverrideIndication,
  getQuotesAndSignposting,
  isSaving,
  issuePolicy,
  issuePolicyMta,
  saveQuotesOnly,
  saveReferral,
  setPremiumIndication,
  setSource,
  submitSucceeded,
  updateApplication,
  updateQuoteMetadata,
} from '../../redux/productActions';
import { toastr } from 'react-redux-toastr';
import { canCommissionAdjust, isBroker, isOwner } from '../../../auth/redux/authActions';

import {
  Box,
  Button,
  ButtonBar,
  InfoLabel,
  SelectSource,
  SelectUser
} from '../../../../common/components';

import SelectedQuotes from './Components/SelectedQuotes/SelectedQuotes';
import TravellersTab from './Components/TabContent/TravellersTab';
import MedicalTab from './Components/TabContent/MedicalTab';
import PaymentTab from './Components/TabContent/PaymentTab';
import SideBar from './Components/SideBar/SideBar';
import { openMenu } from '../../../layout/redux/sideNavAction';
import Signpost from '../../../product/components/Signpost/Signpost';
import Endorsements from './Components/Endorsements/Endorsements';
import TripDetailsTab from './Components/TabContent/TripDetailsTab';
import {
  doTravellersHaveConditions,
  getHasWintersSportsExclusion,
  getReferralRequired,
  productReferrals,
  requiresScreening
} from '../../helpers';
import confirm from 'react-confirm2';
import ReactMarkdown from 'react-markdown';
import AvailableQuotes from './Components/AvailableQuotes/AvailableQuotes';
import OptionsTab from './Components/TabContent/OptionsTab';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import Referral from './Components/Referral/Referral';

import MtaAdjustmentForm from './Components/MtaAdjustmentForm/MtaAdjustmentForm';
import OwnerAdjustPremiumForm
  from './Components/OwnerAdjustPremiumForm/OwnerAdjustPremiumForm';

import './WhitelabelTravelForm.scss';

export const FORM_NAME = 'WhitelabelTravelForm';

const QUOTE_JOURNEY_A = {
  TRIP_DETAILS: 1,
  TRAVELLERS: 2,
  MEDICAL: 3,
  QUOTES: 4,
  OPTIONS: 5,
  PAY: 6
};
const QUOTE_JOURNEY_A_MTA = {
  TRIP_DETAILS: 1,
  TRAVELLERS: 2,
  MEDICAL: 3,
  OPTIONS: 4,
  QUOTES: 5,
  PAY: 6
};

const TABS = {
  NB: QUOTE_JOURNEY_A,
  MTA: QUOTE_JOURNEY_A_MTA,
};

const getFormValuesThatNeedDefaultSet = (availableOptions, formOptionValues) => {

  if (availableOptions) {
    const optionsWithDefaults = Object.values(availableOptions)?.filter((opt) => {
      return !!opt.default;
    });

    if (optionsWithDefaults?.length) {
      return optionsWithDefaults.reduce((carry, option) => {
        const existingValue = formOptionValues?.[option?.key];

        if (!existingValue) {

          carry[option?.key] = option.default;
        }

        return carry;
      }, {});

    }
  }
  return {};

};


const WhitelabelTravelForm = ({
  productId,
  policyId,
  channelId,
  quoteType,
  channelName,
  sourceName,
  selectOptions,
  productOptions,
  rules,
  covidMessage,
  medicalContent,
  purchaseDeclaration,
  definitions,
  isMta,
  isEditingSavedQuote,
  isExpired,
  onIssueMta,
  onIssue,
  onUpdateApplication,
  onCreateApplication,
  onOpenMenu,
  onGetPremiumIndication,
  onSubmitSucceeded,
  onIsSaving,
  onSaveReferralAndExit,
  //onSaveQuotesOnly,
  onGetQuotesAndSignposting,
  onCalculateAdjustmentAndSignpost,
  onSetPremiumIndication,
  onGetPremiumOverrideIndication,
  onUpdateQuoteMetadata,
  onSetSource,
  formValues,
  array,
  dispatch,
  change,
  handleSubmit,
  formErrors,
  // isScreening

}) => {


  const formHasErrors = formErrors ? !!Object.keys(formErrors).length : false;

  const [activeTab, setActiveTab] = useState(TABS[quoteType].TRIP_DETAILS);
  const [purchasePremium, setPurchasePremium] = useState(undefined);
  const [applicationErrors, setApplicationErrors] = useState(undefined);
  const [isMultipleSchemeSelected, setisMultipleSchemeSelected] = useState(false);
  const [savedQuotesFlag, setisavedQuotesFlagd] = useState(false);
  const [isQuoteTabEnabled, setIsQuoteTabEnabled] = useState(false);

  useEffect(() => {
    onOpenMenu(false);

  }, [onOpenMenu]);

  const {
    quotes,
    premiumIndication,
    premiumOverrideCalculation,
    source,
    isSaving,
    isCalculating,
    isIssuing,
    isPremiumAdjusting,
    signpost
  } = useSelector((state) => state.product);
  const { user } = useSelector((state) => state.auth);
  const { index: screeningIndex, isScreening } = useSelector((state) => state.screening);
  //const channel = getIncludedResource(resource.data, resource.included, 'channel');
  const hasSingleSelectedScheme = formValues?.data?.relationships?.schemes?.data?.length === 1;
  const selectedSchemeId = hasSingleSelectedScheme ? formValues?.data?.relationships?.schemes?.data?.[0]?.id : undefined;
  const singleSelectedPremium = premiumIndication?.meta?.scheme.id === selectedSchemeId ? premiumIndication : quotes?.[selectedSchemeId];

  const mtaInfo = isMta ? singleSelectedPremium?.meta?.mta : undefined;
  const changes = mtaInfo?.changes;
  const schemesSelected = formValues?.data?.relationships?.schemes?.data;
  const effectiveDate = formValues?.data?.attributes?.effective_date;

  const metadata = formValues?.data?.attributes?.metadata ?? {};
  const {
    commission_broker: brokerCommissionOverride,
    commission_owner: ownerCommissionOverride,
    commission_rate: commissionRateOverride
  } = metadata?.overrides ?? {};

  const { gross_premium: mtaPremiumOverride } = metadata?.mta_overrides ?? {};

  const userMaxRefLimit = user?.data?.attributes?.max_referral_limit;

  const { residency_declaration: residency, scheme_type: tripType } = metadata;
  const formOptions = formValues?.data?.attributes?.metadata?.options;
  const referrals = useMemo(() => productReferrals(quotes), [quotes]);
  const requiresReferral = getReferralRequired(referrals, userMaxRefLimit);
  const availableOptions = singleSelectedPremium?.meta?.information?.available_options;
  const availableOptionsWithDefaults = useMemo(() => getFormValuesThatNeedDefaultSet(availableOptions, formOptions), [availableOptions, formOptions]);

  useEffect(() => {
    const optionsToUpdate = Object.keys(availableOptionsWithDefaults);
    if (selectedSchemeId && optionsToUpdate?.length) {
      optionsToUpdate.forEach((optionToUpdate) => {
        dispatch(change(`data.attributes.metadata.options.${optionToUpdate}`, availableOptionsWithDefaults[optionToUpdate]));
      });
    }
  }, [selectedSchemeId, availableOptionsWithDefaults, dispatch, change]);

  useEffect(() => {
    if (activeTab > TABS[quoteType].QUOTES) {
      reCalculate();
    }
  }, [formOptions]);

  useEffect(() => {
    if (residency) {
      reCalculate();
    }
  }, [residency]);

  useEffect(() => {
    if (effectiveDate) {

      reCalculate();
    }
  }, [effectiveDate]);

  useEffect(() => {
    if (activeTab >= TABS[quoteType].QUOTES) {
      reCalculate();
    }
  }, [brokerCommissionOverride, ownerCommissionOverride, commissionRateOverride]);

  useEffect(() => {
    if (activeTab >= TABS[quoteType].QUOTES) {
      reCalculate();
    }
  }, [mtaPremiumOverride]);


  useEffect(() => {
    if (isMta && changes) {
      dispatch(
        change('data.attributes.metadata.endorsements[0].title', 'Mid-term Adjustment'),
      );
      dispatch(
        change(
          'data.attributes.metadata.endorsements[0].content',
          changesToContent(changes),
        ),
      );
    }

  }, [isMta, changes, dispatch, change]);

  useEffect(() => {
    // Clear overrides in case no Quote is selected
    if (!schemesSelected?.length) {
      dispatch(change('data.attributes.metadata.overrides', {}));
    }
  }, [schemesSelected, isMta, dispatch, change]);

  useEffect(() => {
    if (isMta) {
      dispatch(change('data.attributes.metadata.endorsements[0].title', 'Mid-term Adjustment'));
      dispatch(change('data.attributes.metadata.endorsements[0].content', changesToContent(mtaInfo?.changes ?? [])));
    }
  }, [mtaInfo, isMta, dispatch, change]);

  const confirmUpsell = (values) => {
    dispatch(change('data.attributes.metadata.scheme_type', 'annual'));
    dispatch(change('data.attributes.metadata.end_date', ''));
    dispatch(change('data.attributes.metadata.destinations', []));
    dispatch(change('data.attributes.metadata.region', values?.region));
    dispatch(change('data.attributes.metadata.max_trip_duration', values?.max_trip_duration));
    dispatch(change('data.attributes.metadata.start_date', values?.start_date));
    reCalculate();

  };

  const getPremiumIndication = (type, value) => {
    if (!type) {
      return onGetPremiumOverrideIndication(selectedSchemeId, formValues);
    }

    const addOverrides = {
      data: {
        ...formValues?.data,
        attributes: {
          ...formValues?.data?.attributes,
          metadata: {
            ...formValues?.data?.attributes.metadata,
            overrides: {
              [type]: value

            }
          }
        }
      }
    };

    return onGetPremiumOverrideIndication(selectedSchemeId, addOverrides);
  };


  const applyOverride = (values) => {
    dispatch(change('data.attributes.metadata.overrides', values));
  };


  const applyMtaOverride = (values) => {
    dispatch(change('data.attributes.metadata.mta_overrides', values));
  };
  const clearMtaOverride = () => {
    dispatch(change('data.attributes.metadata.mta_overrides', ''));
  };

  // Get the Premium selected to display in the SideBar
  const selectedSchemes = formValues?.data?.relationships?.schemes?.data ?? [];
  const selectedSchemeIds = selectedSchemes?.map((scheme) => scheme.id);

  const todayDate = moment();
  const endDate = moment(formValues?.data?.attributes?.metadata?.end_date);
  const durationFromToday = endDate.diff(todayDate, 'days');
  const brokerIdBasedOnSource = source?.relationships?.broker?.data?.id;

  const isSourceTypeBroker = source?.data?.attributes?.source_type?.is_broker;
  const questions = formValues?.data?.attributes?.metadata?.medical_declaration_questions;
  const adults = formValues?.data?.attributes?.metadata?.adults;
  const children = formValues?.data?.attributes?.metadata?.children;

  const isScreeningRequired = useMemo(() => requiresScreening(questions), [questions]);
  const hasWinterSportExclusion = useMemo(() => getHasWintersSportsExclusion(adults, children), [adults, children]);

  const showOwnerPremiumAdjust = singleSelectedPremium?.meta && canCommissionAdjust() && isOwner() && !formHasErrors;
  const showBrokerPremiumAdjust = singleSelectedPremium?.meta && canCommissionAdjust() && isBroker() && !formHasErrors && !isMta && activeTab > TABS[quoteType].QUOTES;


  const singleCalculation = (scheme) => {

    if (isMta) {
      return;
    }

    const schemeForCalc = scheme?.meta?.scheme?.id ? scheme?.meta?.scheme : scheme;

    // End Point will trigger upgrade information
    if (!isMta) {
      if (schemeForCalc) {
        return Promise.resolve(onGetPremiumIndication(schemeForCalc, formValues)).catch(() => {
          array.removeAll('data.relationships.schemes.data');
        });
      }
    }
  };

  const getSelectedScheme = () => {
    const schemes = formValues?.data?.relationships?.schemes?.data;
    if (schemes?.length === 1) {
      return quotes?.premium?.[0]?.id;
    }
    return undefined;
  };

  const addScheme = (scheme) => {
    array.push('data.relationships.schemes.data', { id: scheme });
  };

  const removeScheme = (index) => {
    array.remove('data.relationships.schemes.data', index);
  };

  const issue = (values) => {
    // if (!application || !application.data.id) {
    //   console.error('Error: Invalid quote.');
    //   return false;
    // }
    if (values?.meta?.amount === 0 && isMta) {
      return onIssueMta(formValues.data.id, values);
    }
    if (values?.meta?.amount !== 0) {
      return Promise.resolve(onUpdateQuoteMetadata(formValues)).then(() => {
        return onIssue(formValues?.data?.id, values);
      });
    }
  };

  const saveApplication = (values) => {

    onSubmitSucceeded(false);
    onIsSaving(true);
    return Promise.resolve(
      onCreateApplication(values))
      .then((respValues) => {
        dispatch(change('data.id', respValues.data.id));
        // setApplication(respValues);
        return respValues;
      }).finally(() => {
        onSubmitSucceeded(true);
        onIsSaving(false);
      });
  };

  const handleNonSubmitSave = () => {
    if (formValues?.data?.id) {
      updateApplication(formValues);
    } else {
      createApplication(formValues);
    }
  };


  const getReferralValues = () => {
    const note = formValues?.meta?.note?.content ?? '';
    const referralList = referrals?.reasons?.join(', ');
    const referralNote = `${note} ${referralList}`;

    return {
      data: {
        ...formValues?.data,
        attributes: {
          ...formValues?.data?.attributes,
          metadata: {
            referrals: referrals?.reasons,
            ...formValues?.data?.attributes?.metadata,
          }
        },
        relationships: {
          ...formValues?.data?.relationships,
        }
      },
      meta: {
        ...formValues?.meta,
        note: {
          content: referralNote
        }
      }

    };
  };
  const saveReferralAndExit = () => {
    return onSaveReferralAndExit(getReferralValues(), productId);
  };


  const updateApplication = (values) => {

    onSubmitSucceeded(false);
    onIsSaving(true);
    return Promise.resolve(onUpdateApplication(values.data.id, values))
      .then((respValues) => {
        dispatch(change('data.id', respValues.data.id));
        //  setApplication(respValues);
        return respValues;
      })
      .finally(() => {
        onSubmitSucceeded(true);
        onIsSaving(false);
      });

  };

  const goToNextTab = () => {

    return openSpecificTab(activeTab + 1);
  };
  const goToPreviousTab = () => {
    openSpecificTab(activeTab - 1);
  };

  const submitAndGoToNextTab = () => {
    return dispatch(handleSubmit(goToNextTab));
  };
  const exitMedicalTabNext = () => {

    const hasConditions = doTravellersHaveConditions(adults, children);
    const confirmcOnConditions = isScreeningRequired && !hasConditions;

    if (confirmcOnConditions) {
      confirm('Warning', {
        description: (
          <div className="medical-modal">

            <ReactMarkdown
              source={medicalContent?.medical_no_screening_warning_message}
              renderers={{
                link: (props) => (
                  <a href={props.href} target="_blank">
                    {props.children}
                  </a>
                ),
              }}
            />
          </div>
        ),
        done: () => {
          return submitAndGoToNextTab();
        },
        close: () => {
          return true;
        },
      });
    } else {
      return submitAndGoToNextTab();
    }

  };

  const handleTab = (key) => {
    openSpecificTab(key);
  };

  const goToPaymentTab = (values) => {
    if (!isMta && formValues?.data?.id) {
      return Promise.resolve(updateApplication(values).then((resp) => {
        setApplicationErrors(resp?.data?.attributes?.validation);
        const premiums = getIncludedResource(resp.data, resp.included, 'premiums');
        if (Array.isArray(premiums) && premiums?.length === 1) {
          const selectedPremium = premiums?.[0];
          const errors = selectedPremium?.attributes?.errors;
          const declines = selectedPremium?.attributes?.declines;
          if (!!errors?.length) {
            return toastr.error(errors);
          }
          if (!!declines?.length) {
            return toastr.error(declines?.[0]?.reason);
          }

          setPurchasePremium(selectedPremium);
          setActiveTab(TABS[quoteType].PAY);
        }
      }));
    } else {

      return Promise.resolve(saveApplication(values).then((resp) => {
        setApplicationErrors(resp?.data?.attributes?.validation);
        const premiums = getIncludedResource(resp.data, resp.included, 'premiums');
        if (Array.isArray(premiums) && premiums?.length === 1) {
          const selectedPremium = premiums?.[0];
          const errors = selectedPremium?.attributes?.errors;
          const declines = selectedPremium?.attributes?.declines;
          if (!!errors?.length) {
            return toastr.error(errors);
          }
          if (!!declines?.length) {
            return toastr.error(declines?.[0]?.reason);
          }

          setPurchasePremium(selectedPremium);
          setActiveTab(TABS[quoteType].PAY);
        }

      }));
    }
  };

  const goToQuotesTab = (key) => {
    return Promise.resolve(reCalculate()).then(() => {
      dispatch(change('data.current_tab', key));
      setIsQuoteTabEnabled(true);
      setActiveTab(key);
    });
  };

  const openSpecificTab = (key) => {
    //Don't go to quotes if recalculating score
    if (screeningIndex > 0) {
      return toastr.info(
        'Screening session active',
        'Please finish your current screening session before continuing',
      );
    }
    const isEnteringQuotesTab = key === TABS[quoteType].QUOTES;
    const isEnteringOptionsTab = key === TABS[quoteType].OPTIONS;
    const isExitingOptionsTab = activeTab === TABS[quoteType].OPTIONS;

    if (!isMta && isExitingOptionsTab && isEnteringOptionsTab) {
      // clear overrides and fees
      setActiveTab(key);
      return Promise.resolve(dispatch(change('data.attributes.metadata.fees', {})))
        .then(dispatch(change('data.attributes.metadata.overrides', {})))
        .then(() => reCalculate(isEnteringQuotesTab));
    }
    if (key <= activeTab && key !== TABS[quoteType].QUOTES) { // going back is fine as long as it's not to the quotes tab
      setActiveTab(key);
      return;
    }


    // Don't change the tab if the flag is false
    if (key === TABS[quoteType].QUOTES) {
      if (isScreening) {
        return;
      }
      return goToQuotesTab(key);
    }

    // check scheme is selected
    if (key > TABS[quoteType].QUOTES) {


      const selectedSchemeId = formValues?.data?.relationships?.schemes?.data?.[0].id;
      if (!selectedSchemeId) {
        setisMultipleSchemeSelected(true);
        return;
      }
      // Check for Upsell Logic to validate the Upsell SideBar Field

      const {
        quote_type: quoteType,
        scheme_type: schemeType
      } = quotes?.[selectedSchemeId].meta?.scheme;
      const isUpsell = quoteType === 'upsell';
      if (isUpsell && formValues.data.attributes.metadata.scheme_type !== schemeType) {

        return;
      }
    }
    if (key === TABS[quoteType].PAY) {

      if (!singleSelectedPremium) {
        return toastr.error('No scheme');
      }


      return dispatch(handleSubmit(goToPaymentTab));


    }
    dispatch(change('data.current_tab', key));
    setActiveTab(key);
  }; // end

  const calculatePanel = (values, id, target) => {
    //const { dispatch, change, doSignposting } = this.props;
    const schemeType = values.data.attributes.metadata.scheme_type;
    //const coverLevel = values.data.attributes.metadata.cover_level || null;
    return Promise.resolve(onGetQuotesAndSignposting(id, values, target, schemeType)).then(
      (resp) => {
        dispatch(change('data.attributes.metadata.signposted', resp));
        dispatch(change('data.attributes.metadata.signpost_acknowledged', ''));
        return resp;
      });
  };

  const reCalculate = () => {
    const schemes = formValues?.data?.relationships?.schemes?.data ?? [];
    const numSchemes = schemes?.length;
    if (isMta) {
      return dispatch(handleSubmit(getPremiumAdjustment));
    } else {
      return Promise.resolve(dispatch(handleSubmit(calculateQuotes))).then(() => {

        if (numSchemes === 1) {
          return singleCalculation(schemes[0], formValues);
        }
      });
    }
  };

  const getPremiumAdjustment = (values) => {
    const schemeType = values.data.attributes.metadata.scheme_type;
    const coverLevel = values.data.attributes.metadata.cover_level || null;
    return onCalculateAdjustmentAndSignpost(policyId, values, schemeType, coverLevel);
  };

  const calculateQuotes = (application) => {
    if (isMta) {
      getPremiumAdjustment();

    } else {
      const calculationSource = getCalculationSource(application, channelId, productId);

      onSetPremiumIndication({});
      // Save the quote for the first time the QUOTES_TAB is accessed.
      if (!savedQuotesFlag && !isEditingSavedQuote) {

        return Promise.resolve(saveApplication(application))
          .then((resp) => {
            setisavedQuotesFlagd(true);
            return calculatePanel(resp, calculationSource.id, calculationSource.source);
          });
      } else {
        return calculatePanel(application, calculationSource.id, calculationSource.source);

      }
    }
  };

  const clearPremiumIndication = () => {
    onSetPremiumIndication([]);
  };

  const getCalculationSource = (application, channelId, productId) => {
    if (isBroker()) {
      return {
        source: 'products',
        id: productId
      };
    }
    const sourceId = application?.data?.relationships?.source?.data?.id ?? application?.data?.attributes?.metadata?.source?.id;
    if (sourceId) {
      return {
        source: 'sources',
        id: sourceId
      };
    } else {
      return {
        source: 'channels',
        id: channelId
      };

    }
  };

  const handleEndorsementChange = (val) => {

    const selectedScheme = formValues?.data?.relationships?.schemes?.data ?? [];
    if (val.attributes) {
      dispatch(change(
        'data.attributes.metadata.endorsements[0].title',
        val.attributes.sections.title,
      ));
      dispatch(change(
        'data.attributes.metadata.endorsements[0].content',
        val.attributes.sections.content,
      ));
    } else {
      dispatch(change(
        'data.attributes.metadata.endorsements[0].title',
        'Mid-term Adjustment',
      ));
      dispatch(change(
        'data.attributes.metadata.endorsements[0].content',
        changesToContent(selectedScheme?.meta?.mta?.changes, [])),
      );
    }
  };

  const getSourceChannelKey = (source) => {

    const sourceAttributes = source.value.attributes;
    if (sourceAttributes.source_type === 'Aggregator') {
      return sourceAttributes.channel_key;
    }

    if (sourceAttributes.source_type === 'Broker') {
      return 'pjh-b2b';
    }

    return 'call-centre';

  };
  const handleSourceChange = (changedSource) => {
    if (changedSource) {
      onSetSource(changedSource?.value);
      dispatch(change('data.relationships.user.data.id', ''));
      dispatch(change('data.attributes.metadata.sales_channel', getSourceChannelKey(changedSource)));
      if (changedSource.attributes.is_broker) {
        const broker = changedSource.relationships.broker.data.id;
        dispatch(change('data.relationships.broker.data.id', broker));
      }

    } else {
      dispatch(
        change(
          'data.attributes.metadata.sales_channel',
          isBroker() ? 'pjh-b2b' : 'call-centre',
        ),
      );
    }
  };

  const handleScreeningComplete = (screening, screeningId, path) => {
    dispatch(
      change(
        `${path}.screening`,
        screening,
      ),
    );
    dispatch(
      change(
        `${path}.screening_id`,
        screeningId,
      ),
    );
  };

  const isValidToRender =
    isBroker() ||
    isMta ||
    isEditingSavedQuote ||
    !!formValues?.data?.relationships?.source?.data?.id;

  return (
    <div>
      <form autoComplete="off">
        {isOwner() && (
          <Box>
            <Row>
              <Col sm={6}>
                {!sourceName ? (
                  <Field
                    name={'data.relationships.source.data'}
                    isObject
                    isClearable={!isMta}
                    filter={'?include=broker,channel'}
                    id={'select-source'}
                    component={SelectSource}
                    disabled={
                      isMta || isEditingSavedQuote || activeTab === TABS[quoteType].PAY
                    }
                    label={'Source'}
                    labelSize={4}
                    product={productId}
                    onChangeCallback={handleSourceChange}
                  />
                ) : (
                  <InfoLabel
                    label="Source"
                    value={sourceName ? sourceName : channelName}
                    labelSize={4}
                  />
                )}
              </Col>
              {isSourceTypeBroker && (
                <Col sm={6}>
                  <Field
                    key={sourceName}
                    label="User"
                    labelKeys={['attributes.first_name', 'attributes.last_name']}
                    labelSize={3}
                    name={'data.relationships.user.data.id'}
                    component={SelectUser}
                    byBroker={brokerIdBasedOnSource}

                    disabled={
                      isMta || activeTab === TABS[quoteType].PAY
                    }
                  />
                </Col>
              )}
            </Row>
          </Box>
        )}
        {isValidToRender && (
          <div className={'quotes-tabs' + (isSaving ? ' loading' : '')}>
            <div className="quote-tabs-content">
              <Tabs
                activeKey={activeTab}
                id="wl-travel"
                unmountOnExit
                onSelect={handleTab}
              >
                <Tab eventKey={QUOTE_JOURNEY_A.TRIP_DETAILS} title="Trip&#160;Details"
                     autoComplete="off">
                  <div className="wl-tab-content">
                    <TripDetailsTab
                      selectedScheme={getSelectedScheme()}
                      isMta={isMta}
                      selectOptions={selectOptions}
                      rules={rules}
                      covidMessage={covidMessage}
                      definitions={definitions ?? {}}
                      isExpired={isExpired}
                    />
                    <Row>
                      <Col sm={12}>
                        <ButtonBar>
                          <Button
                            id="next"
                            label="Next"
                            className="pull-right btn-action-blue"
                            bsStyle="primary"
                            handleClick={submitAndGoToNextTab}
                          />
                        </ButtonBar>
                      </Col>
                    </Row>
                  </div>
                </Tab>
                <Tab eventKey={QUOTE_JOURNEY_A.TRAVELLERS} title="Travellers"
                     autoComplete="off">
                  <div className="wl-tab-content">
                    <TravellersTab
                      travellerGroupOptions={selectOptions?.[`${tripType}GroupTypes`]}
                      ageLimits={rules?.['age_limit']}
                      definitions={definitions ?? {}}
                    />
                    <Row>
                      <Col sm={12}>
                        <ButtonBar>
                          <Button
                            id="next"
                            label="Next"
                            className="pull-right btn-action-blue"
                            bsStyle="primary"
                            handleClick={submitAndGoToNextTab}
                          />
                          <Button
                            id="back"
                            label="Back"
                            className="pull-right btn-action-blue"
                            bsStyle="primary"
                            handleClick={goToPreviousTab}
                          />
                        </ButtonBar>
                      </Col>
                    </Row>
                  </div>
                </Tab>
                <Tab
                  eventKey={TABS[quoteType].MEDICAL} title="Medical"
                >
                  <div className="wl-tab-content">
                    <MedicalTab
                      onScreeningComplete={handleScreeningComplete}
                      onGoToNextTab={submitAndGoToNextTab}
                      isScreeningRequired={isScreeningRequired}
                      isMta={isMta}
                    />
                    <Row>
                      <Col sm={12}>
                        <ButtonBar>

                          <Button
                            id="next"
                            label="Next"
                            className="pull-right btn-action-blue"
                            bsStyle="primary"
                            disabled={screeningIndex !== 0 && isScreeningRequired}
                            handleClick={exitMedicalTabNext}

                          />
                          <Button
                            id="back"
                            label="Back"
                            className="pull-right btn-action-blue"
                            bsStyle="primary"
                            handleClick={goToPreviousTab}

                          />
                        </ButtonBar>
                      </Col>
                    </Row>
                  </div>
                </Tab>
                {isMta && (
                  <Tab eventKey={TABS[quoteType].OPTIONS} title={'Options'}>
                    <div className="wl-tab-content">
                      <OptionsTab
                        productOptions={productOptions}
                        isCalculating={isCalculating}
                        hasWinterSportExclusion={hasWinterSportExclusion}
                        isMta={isMta}
                        selectedScheme={quotes?.[selectedSchemeId]}
                      />

                      <Row>
                        <Col sm={12}>
                          <ButtonBar>
                            <Button
                              className="pull-left btn-action-back"
                              label="Back"
                              bsStyle="secondary"
                              handleClick={goToPreviousTab}
                            />
                            <Button
                              className="pull-right btn-action"
                              label="Next"
                              id="next-payment-mta"
                              bsStyle="primary"
                              // Quote to Options
                              handleClick={submitAndGoToNextTab}
                            />
                          </ButtonBar>
                        </Col>
                      </Row>
                    </div>

                  </Tab>
                )}

                <Tab
                  eventKey={TABS[quoteType].QUOTES}
                  title={isMta ? 'Calculate Adjustment' : 'Quotes'}
                  disabled={!isQuoteTabEnabled}
                >
                  <div className="wl-tab-content">
                    <AvailableQuotes
                      isMta={isMta}
                      availableQuotes={quotes}
                      isCalculating={isCalculating}
                      productId={productId}
                      isReferralRequired={requiresReferral}
                      onClearPremiumIndication={clearPremiumIndication}
                      onAddScheme={addScheme}
                      onRemoveScheme={removeScheme}
                      selectedSchemes={selectedSchemeIds}

                    />
                    <Referral
                      show={requiresReferral}
                      onSaveReferral={saveReferralAndExit}
                      referrals={referrals}
                    />
                    <Signpost
                      signpost={signpost}
                    />

                    {isMta && showOwnerPremiumAdjust && (
                      <div>
                        <MtaAdjustmentForm
                          selectedScheme={singleSelectedPremium}
                          onApplyOverride={applyMtaOverride}
                          onClearOverride={clearMtaOverride}
                        />

                      </div>
                    )}
                    <Endorsements
                      show={isMta && isOwner()}
                      handleEndorsementChange={handleEndorsementChange}
                    />
                    <SelectedQuotes
                      show={!isMta}
                      selectedSchemes={selectedSchemeIds}
                      isReferralRequired={requiresReferral}
                      quotes={quotes}
                    />
                    <Row>
                      <Col sm={12}>
                        <ButtonBar>
                          <Button
                            className="pull-left btn-action-back"
                            label="Back"
                            bsStyle="secondary"
                            handleClick={goToPreviousTab}
                          />
                          <Button
                            className="pull-right btn-action"
                            label="Next"
                            id="next-payment-mta"
                            bsStyle="primary"
                            handleClick={submitAndGoToNextTab}
                          />
                        </ButtonBar>
                      </Col>
                    </Row>
                  </div>

                </Tab>

                {!isMta && (
                  <Tab eventKey={TABS[quoteType].OPTIONS} title="Options">
                    <div className="wl-tab-content">
                      <OptionsTab
                        productOptions={productOptions}
                        isCalculating={isCalculating}
                        hasWinterSportExclusion={hasWinterSportExclusion}
                        isMta={isMta}
                        selectedScheme={quotes?.[selectedSchemeId]}

                      />
                      {isOwner() && !isMta && (
                        <div>
                          <h4 className="title-options">Endorsements</h4>
                          <div
                            className="container-fluid"
                            style={{ marginBottom: '35px', paddingLeft: '0px' }}
                          >
                            <Endorsements
                              handleEndorsementChange={handleEndorsementChange}
                            />
                          </div>
                        </div>
                      )}

                      {showOwnerPremiumAdjust && !isMta && (
                        <OwnerAdjustPremiumForm
                          onApplyOverride={applyOverride}
                          selectedScheme={singleSelectedPremium}
                          onGetPremiumOverrideIndication={getPremiumIndication}
                        />
                      )}

                      <Row>
                        <Col xs={12}>
                          <ButtonBar>
                            <Button
                              className="pull-left btn-action-back"
                              label="Back"
                              bsStyle="secondary"
                              handleClick={goToPreviousTab}
                            />
                            <Button
                              className={isSaving
                                ? 'pull-right'
                                : 'pull-right btn-action'
                              }
                              label="Next"
                              id="next-payment"
                              bsStyle="primary"
                              disabled={isCalculating}
                              isLoading={isSaving}
                              handleClick={submitAndGoToNextTab}
                            />
                          </ButtonBar>
                        </Col>
                      </Row>
                    </div>

                  </Tab>
                )}

                <Tab eventKey={TABS[quoteType].PAY}
                     title={requiresReferral ? 'Refer' : 'Payment'}>
                  <div className="wl-tab-content">
                    {!isCalculating && !requiresReferral && !!purchasePremium && (
                      <PaymentTab
                        isBroker={isSourceTypeBroker || isBroker()}
                        key={purchasePremium?.data?.id}
                        covidMessage={covidMessage}
                        // premiumIndicationGross={premiumIndicationGross} // after singleCalculation
                        declaration={purchaseDeclaration}
                        durationFromToday={durationFromToday}
                        purchasePremium={purchasePremium?.attributes}
                        purchasePremiumId={purchasePremium?.id}
                        //formValues={formValues}
                        applicationErrors={applicationErrors}
                        application={formValues}
                        hasSignpostedPremiums={formValues?.data.attributes.metadata.signposted &&
                          formValues?.data.attributes.metadata.signposted?.includes('medical_premiums')}
                        isMta={isMta}
                        onIssue={issue}
                        isIssuing={isIssuing}
                        definitions={definitions}
                      />
                    )}

                    {!isCalculating && !!requiresReferral && (<>
                        <AvailableQuotes
                          isMta={isMta}
                          availableQuotes={quotes}
                          isCalculating={isCalculating}
                          productId={productId}
                          isReferralRequired={requiresReferral}
                          onClearPremiumIndication={clearPremiumIndication}
                          onAddScheme={addScheme}
                          onRemoveScheme={removeScheme}
                          selectedSchemes={selectedSchemeIds}
                        />
                        <Signpost
                          signpost={signpost}
                        />
                      </>
                    )}
                  </div>
                </Tab>

              </Tabs>
            </div>
          </div>
        )}
        {isValidToRender && (
          <SideBar
            productId={productId}
            isMultipleSchemeSelected={isMultipleSchemeSelected}
            isMta={isMta}
            selectOptions={selectOptions}
            selectedScheme={singleSelectedPremium}
            premiumOverrideCalculation={premiumOverrideCalculation?.meta}
            productOptions={productOptions}
            premium={singleSelectedPremium?.meta}
            isCalculating={isCalculating}
            isPremiumAdjusting={isPremiumAdjusting}
            isSaving={isSaving}
            quotes={quotes}
            selectedSchemeIds={selectedSchemeIds}
            referrals={referrals}
            isReferralRequired={requiresReferral}
            onSave={handleNonSubmitSave}
            onApplyOverride={applyOverride}
            showSaveButtons={activeTab > TABS[quoteType].TRIP_DETAILS}
            definitions={definitions}
            rules={rules}
            isExpired={isExpired}
            showUpsell={activeTab === TABS[quoteType].QUOTES}
            onConfirmUpsell={confirmUpsell}
            showBrokerPremiumAdjust={showBrokerPremiumAdjust}
          />
        )}
      </form>
    </div>
  );
};

export default connect(
  (state, ownProps) => (
    {
      initialValues: ownProps.initialValues,
      formValues: getFormValues(FORM_NAME)(state),
      submitErrors: getFormSubmitErrors(FORM_NAME)(state),
      formErrors: getFormError(FORM_NAME)(state),

    }
  ),
  {
    onSubmitSucceeded: submitSucceeded,
    onIsSaving: isSaving,
    onSaveReferralAndExit: saveReferral,
    onSaveQuotesOnly: saveQuotesOnly,
    onGetQuotesAndSignposting: getQuotesAndSignposting,
    onUpdateApplication: updateApplication,
    onGetMtaPremiumOverrideCalculation: getMTAPremiumOverrideCalculation,
    onClearPremiumOverrideCalculation: clearPremiumOverrideCalculation,
    onGetPremiumOverrideIndication: getPremiumOverrideIndication,
    onCreateApplication: createApplication,
    onCalculateAdjustment: calculateWlAdjustment,
    onCalculateAdjustmentAndSignpost: calculateWlAdjustmentAndSignpost,
    onSetPremiumIndication: setPremiumIndication,
    onUpdateQuoteMetadata: updateQuoteMetadata,
    onSetSource: setSource,
    onIssueMta: issuePolicyMta,
    onIssue: issuePolicy,
    onGetPremiumIndication: getPremiumIndication,
    onOpenMenu: openMenu,

  },
)(reduxForm({ form: FORM_NAME })(WhitelabelTravelForm));

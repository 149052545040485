import React, { useMemo } from 'react';
import { compose } from 'redux';
import { Col, FormControl, Modal, Row } from 'react-bootstrap';
import connect from 'react-redux/es/connect/connect';
import { Button, DatePicker } from '../../../../common/components';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { openModal } from '../../../../common/components/Modal/ModalRedux';
import { clearFiles } from '../../../../common/components/Upload/UploadRedux';
import TextField from '../../../../common/components/ReduxFormField/TextField';
import SelectField from '../../../../common/components/ReduxFormField/SelectField';
import UploadModalComponent from '../../../../common/components/Upload/UploadModal';
import DocumentFile from '../DocumentFile/DocumentFile';
import { required } from 'redux-form-validators';
import './NewProductDocumentModal.scss';

const isRequired = required({ message: 'Required' });

const UPLOAD_MODAL = 'UploadNewProductDocumentModal';

const NewProductDocumentFormRow = ({ label, content }) => {
  return (
    <Row className="product-document-modal-row">
      <Col sm={3} xs={5}>
        <strong>{label}:</strong>
      </Col>
      <Col sm={9} xs={7}>
        {content}
      </Col>
    </Row>
  );
};

const NewProductDocumentForm = ({
  dispatch,
  change,
  handleSubmit,
  submitting,
  files,
  documentTypes,
  formValues,
  onAddDocument,
  onClearFiles,
  onOpenModal,
}) => {
  const getDocumentTypes = useMemo(
    () => () => {
      return documentTypes
        .filter((documentType) =>
          [
            'Fact Find',
            'Information',
            'COVID-19',
            'Insurance Product Information Document',
            'Policy Summary',
            'Policy Wording',
            'Statement of Fact',
            'Terms of Business Customer Agreement',
            'Virtual Medical Care',
          ].includes(documentType.attributes.name),
        )
        .map((item) => ({ label: item.attributes.name, value: item.id }));
    },
    [documentTypes],
  );

  const resetState = () => {
    onClearFiles();
  };

  const handleUpload = () => {
    onOpenModal(UPLOAD_MODAL);
    resetState();
  };

  const handleUploadComplete = () => {
    if (files[0]) {
      dispatch(change('data.relationships.file.data', files[0]));
      dispatch(change('data.attributes.file_name', files[0].attributes.name));
      dispatch(change('data.attributes.file_url', files[0].attributes.url));
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onAddDocument)}>
        <Modal.Body>
          <div className="alert-warning">
            <p className="doc-warning">
              <strong>Please Note:</strong>
              <br/>
              Use "New Version" on a document if this document is a replacement of a
              current active document.
            </p>
          </div>
          <div className="product-document-modal-form">
            <NewProductDocumentFormRow
              label={'Type'}
              content={
                <Field
                  name={'data.relationships.document_type.data.id'}
                  component={SelectField}
                  options={getDocumentTypes()}
                  validate={[isRequired]}
                />
              }
            />

            <NewProductDocumentFormRow
              label={'Display Name'}
              content={
                <Field
                  name={'data.attributes.display_name'}
                  component={TextField}
                  validate={[isRequired]}
                />
              }
            />

            <NewProductDocumentFormRow
              label={'Internal Name'}
              content={<Field name={'data.attributes.name'} component={TextField}/>}
            />

            <NewProductDocumentFormRow
              label={'Short Name'}
              content={
                <Field name={'data.attributes.short_name'} component={TextField}/>
              }
            />
            <NewProductDocumentFormRow
              label={'File'}
              content={
                <FormControl.Static>
                  <Button
                    bsSize="xs"
                    type="button"
                    bsStyle="primary"
                    className="pad-right pull-left btn-xs"
                    handleClick={handleUpload}
                  >
                    <i className="fa fa-file"/> upload document
                  </Button>

                  <Field
                    name={'data.relationships.file.data.id'}
                    component={DocumentFile}
                    url={formValues?.data.relationships.file?.data?.attributes?.url}
                    displayName={formValues?.data.attributes.file_name}
                    validate={[isRequired]}
                  />
                </FormControl.Static>
              }
            />

            <NewProductDocumentFormRow
              label={'Active date'}
              content={
                <Field
                  showTime
                  dateFormat={'dd/MM/yyyy HH:mm'}
                  name="data.attributes.from_date"
                  label="Start date"
                  timeFormat="HH:mm"
                  component={DatePicker}
                  shouldCloseOnSelect={false}
                  validate={[isRequired]}
                />
              }
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" className="btn-blue" isLoading={submitting}>
            Save
          </Button>
        </Modal.Footer>
      </Form>
      <UploadModalComponent
        modalIdentifier={UPLOAD_MODAL}
        maxFiles={1}
        accept="application/pdf"
        onComplete={handleUploadComplete}
      />
    </>
  );
};

const FORM_NAME = 'NewProductDocumentForm';
export default compose(
  connect(
    (state, ownProps) => {
      return {
        formValues: getFormValues(FORM_NAME)(state),
        files: state.upload.files,
        initialValues: {
          data: {
            attributes: {
              name: '',
              display_name: '',
              short_name: '',
            },
            relationships: {
              products: {
                data: [
                  {
                    id: ownProps.productId,
                  },
                ],
              },
              document_type: {
                data: {
                  id: '',
                },
              },
            },
          },
        },
      };
    },
    {
      onOpenModal: openModal,
      onClearFiles: clearFiles,
    },
  ),
  reduxForm({
    form: FORM_NAME,
  }),
)(NewProductDocumentForm);

import React from "react";
import { SchemeSummary } from "./SchemeSummary";

export const Premiums = ({ show, selectedSchemeIds, quotes, isCalculating }) => {
  if (!show) {
    return null;
  }

  return (
    <>
      {selectedSchemeIds?.map((schemeId) => {
        const quote = quotes?.[schemeId];
        return (
          <SchemeSummary premium={quote?.meta} isCalculating={isCalculating}/>
        )
      })}
    </>
  );
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import InternalContainer from '../../layout/containers/Internal';
import NonRenewalList from '../components/NonRenewalList/NonRenewalList';
import _ from 'lodash';

class NonRenewalListContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
  }

  render() {
    const currentBroker = _.get(this.props.auth, 'user.data.relationships.current_broker.data.id', null);

    return (
        <InternalContainer title="Non Renewals">
          <NonRenewalList {...this.props} currentBroker={currentBroker}/>
        </InternalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProps)(NonRenewalListContainer);

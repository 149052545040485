import React, { Component } from 'react';
import { get } from 'lodash';
import './PayMethodButtons.scss';

class PayMethodButtons extends Component {
  render() {
    const {
      input,
      label,
      options,
      objectKey,
      inline,
      width,
      meta: { touched, error },
    } = this.props;
    return (
      <div className={touched && error ? 'has-error' : ''}>
        {options.map((option, key) => {
          const value = objectKey ? get(option.value, objectKey) : option.value;
          return (
            <div
              className={'radio-container-payment' + (inline ? ' inline' : '')}
              key={key}
              style={width ? { width: width } : {}}
            >
              <div className={'form-radio'}>
                <input
                  {...input}
                  type="radio"
                  id={input.name + option.label}
                  value={option.value}
                  checked={input.value.toString() === value.toString()}
                />
                <label htmlFor={input.name + option.label}>
                  <div
                    className="radio-label"
                    onClick={() => input.onChange(option.value)}
                  >
                    {option.label}
                  </div>
                </label>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default PayMethodButtons;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import {
  AddressFields,
  CheckboxUnlabeled,
  RadioGroup,
  SelectField,
} from '../../../../common/components/ReduxFormField';
import PostcodeList from '../../../../common/components/Postcode/PostcodeList';
import {
  Box,
  Button,
  HorizontalFormControl,
  SelectBrokerRegion,
  SelectNetwork,
  SelectOrganisation,
  SelectUser,
} from '../../../../common/components';
import { get, set } from 'lodash';
import { Row, Col, Form, FormGroup, ControlLabel } from 'react-bootstrap';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import './styles.scss';

const FORM_NAME = 'brokerForm';

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    { field: 'data.relationships.organisation.data.id', name: 'Organisation' },
    { field: 'data.relationships.organisation.data', name: 'Organisation' },
  ];

  requiredFields.map((required) => {
    if (!get(values, required.field)) {
      set(errors, required.field, required.name + ' is required');
    }
  });

  return errors;
};

class BrokerForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    resource: PropTypes.object.isRequired,
    formValues: PropTypes.object,
    brokerLabel: PropTypes.object,
  };

  render() {
    const { formValues, handleSubmit, submitting } = this.props;
    const resource = this.props.resource.data.attributes;
    const organisationId = get(formValues, 'data.relationships.organisation.data.id');

    return (
      <div className="broker-form">
        <Box>
          <h2 className="resource-name">
            {resource.name} {this.props.brokerLabel || ''}
          </h2>
          <Form onSubmit={handleSubmit} className="form-horizontal">
            <Row>
              <Col sm={6}>
                <Field
                  name="data.attributes.trading_name"
                  label="Trading Name"
                  labelSize={4}
                  component={HorizontalFormControl}
                />

                <Field
                  name="data.attributes.branch_name"
                  label="Branch Name"
                  labelSize={4}
                  component={HorizontalFormControl}
                />

                <Field
                  name="data.relationships.broker_region.data"
                  label="Region"
                  labelSize={4}
                  object
                  component={SelectBrokerRegion}
                />

                <PostcodeList
                  name="data.attributes.address.postcode"
                  targetField={'data.attributes.address'}
                  formName={FORM_NAME}
                  label="Postcode"
                  labelSizes={{ sm: 4, md: 4, lg: 4 }}
                  fieldSizes={{ sm: 8, md: 8, lg: 8 }}
                  searchBtnSizes={{ sm: 4 , md: 4, lg: 4 }}
                  postcodeFieldSizes={{ sm: 4, md: 4, lg: 4 }}
                  change={this.props.change}
                  value={get(formValues, 'data.attributes.address.postcode', '')}
                />

                <AddressFields
                  baseName="data.attributes.address"
                  label="Address"
                  labelSizes={{ sm: 4, md: 4, lg: 4 }}
                  fieldSizes={{ sm: 8, md: 8, lg: 8 }}
                />
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Col sm={4} componentClass={ControlLabel}>
                    Broker Type
                  </Col>
                  <Col sm={8}>
                    <Field
                      name="data.attributes.broker_type"
                      component={SelectField}
                      options={[
                        { label: 'Broker', value: 'broker' },
                        { label: 'Introducer', value: 'introducer' },
                      ]}
                    />
                  </Col>
                </FormGroup>
                <Field
                  name="data.attributes.phone_number"
                  label="Phone Number"
                  labelSize={4}
                  object
                  component={HorizontalFormControl}
                />
                <Field
                  name="data.attributes.metadata.display_phone_number"
                  label="Phone Number"
                  labelSize={4}
                  object
                  component={HorizontalFormControl}
                />

                {!get(resource, 'url', false) && (
                  <Field
                    name={'meta.generate_url'}
                    label={'Generate Url?'}
                    component={CheckboxUnlabeled}
                    inline
                    labelSize={4}
                    options={[{ label: '', value: true }]}
                  />
                )}

                <Field
                  name="data.attributes.reference"
                  label="Reference"
                  labelSize={4}
                  component={HorizontalFormControl}
                />

                <Field
                  name="data.attributes.metadata.hold_client_money"
                  label="Hold Client Money"
                  component={RadioGroup}
                  inline
                  labelSize={4}
                  options={[
                    { label: 'Yes', value: 'Yes' },
                    { label: 'No', value: 'No' },
                    { label: 'N/A', value: 'N/A' },
                  ]}
                />
              </Col>
            </Row>

            <div className="hr" />

            <Row>
              <Col sm={6}>
                <Field
                  name="data.relationships.networks.data"
                  label="Networks"
                  labelSize={4}
                  object
                  isMulti
                  component={SelectNetwork}
                />

                <Field
                  name="data.attributes.metadata.network_reference"
                  label="Network Reference"
                  labelSize={4}
                  component={HorizontalFormControl}
                />
              </Col>
              <Col sm={6}>
                <Field
                  name="data.attributes.metadata.other_networks"
                  label="Other Networks"
                  labelSize={4}
                  component={HorizontalFormControl}
                />
              </Col>
            </Row>

            <div className="hr" />

            <Row>
              {!this.props.resource.data.id && (
                <Col xs={12}>
                  <Field
                    async
                    name="data.relationships.organisation.data.id"
                    filters={{ type: 'Broker' }}
                    sort={'name'}
                    label="Organisation"
                    labelSize={2}
                    component={SelectOrganisation}
                  />
                </Col>
              )}

              {organisationId && (
                <Col xs={12}>
                  <Field
                    key={'select-users-' + organisationId}
                    sourceKey={'select-users-' + organisationId}
                    name="data.relationships.users.data"
                    label="Users"
                    labelSize={2}
                    byOrganisation={organisationId}
                    isMulti
                    isObject
                    component={SelectUser}
                  />
                </Col>
              )}
            </Row>

            <div className="hr" />

            {organisationId && (
              <div>
                <Row>
                  <Col xs={6}>
                    <Field
                      key={'select-primary-contacts-' + organisationId}
                      sourceKey={'select-primary-contacts-' + organisationId}
                      name="data.relationships.primary_contacts.data"
                      label="Primary Contacts"
                      labelSize={4}
                      byOrganisation={organisationId}
                      isMulti
                      isObject
                      component={SelectUser}
                    />
                  </Col>
                  <Col xs={6}>
                    <Field
                      key={'select-accounts-contacts-' + organisationId}
                      sourceKey={'select-accounts-contacts-' + organisationId}
                      name="data.relationships.accounts_contacts.data"
                      label="Accounts Contacts"
                      labelSize={4}
                      byOrganisation={organisationId}
                      isMulti
                      isObject
                      component={SelectUser}
                    />
                  </Col>
                </Row>
                <div className="hr" />
              </div>
            )}
            <Row>
              <Col sm={12}>
                <InternalButtonBar buttons={[
                  {
                    permissions: ['broker.edit'],
                    button: (
                      <Button
                        className="pull-right"
                        type="submit"
                        bsStyle="primary"
                        label="Save Broker"
                        isLoading={submitting}
                      />
                    )
                  }
                ]} />
              </Col>
            </Row>
          </Form>
        </Box>
      </div>
    );
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(BrokerForm);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  const values = selector(
    state,
    'data.id',
    'data.attributes.address.postcode',
    'data.relationships.organisation.data.id',
    'data.attributes.metadata.hold_client_money',
    'data.attributes.metadata.software_house',
  );

  if (
    Array.isArray(props.resource.data.attributes.metadata) &&
    !props.resource.data.attributes.metadata.length
  ) {
    props.resource.data.attributes.metadata = {};
  }

  return {
    initialValues: props.resource,
    formValues: values,
  };
};

export default connect(mapStateToProps)(form);

import Axios from 'axios';
import { toastr } from 'react-redux-toastr';

export function createNote(note, application, resourceType) {
  return () => {
    return Axios.post(resourceType + '/' + application.id + '/notes', note)
      .then(() => {
        toastr.success('Success', 'Your note has been saved');
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

import React from 'react';
import { Field, FormSection } from 'redux-form';
import CollapsableBox from '../CollapsableBox/CollapsableBox';
import TextArea from "../../../content/components/TextArea";
import {
  CheckboxControl
} from "../../../../common/components";


const InformationProcessingConsent = ({}) => {
  return (
    <CollapsableBox label={'Information Processing Consent'}>
      <FormSection  name={'meta.whitelabel.content.information_processing_consent'}>
        <Field
          name={'enabled'}
          component={CheckboxControl}
          label={'Enabled'}
          options={[{ label: ' ', value: true }]}

        />
        <Field
          name={'content'}
          component={TextArea}


        />

      </FormSection>
    </CollapsableBox>
  );

};

export default InformationProcessingConsent;

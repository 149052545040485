import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { Col, Row, FormControl, ControlLabel } from 'react-bootstrap';
import { FormattedDate } from 'react-intl';
import {
  Box,
  DataTable,
  SelectBroker,
  SelectAccount,
  DatePickerDob,
} from '../../../../common/components';
import {
  applyFilter,
  refreshData,
} from '../../../../common/components/DataTable/redux/dataTableActions';
import { openModal } from '../../../../common/components/Modal/ModalRedux';
import PremiumModal from '../PremiumModal';
import selectTheme, { styles } from '../../../../helpers/selectTheme';
import Select from 'react-select';
import moment from 'moment';
import './styles.scss';

export const TABLE_NAME = 'premiums';

export default class AccountPremiumList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      account: '',
      broker: '',
      matched: 'matched',
      fromDate: '',
      toDate: '',
      ledgerPremium: {},
    };
  }

  formatDate(cell) {
    return (
      <span>
        <FormattedDate value={new Date(cell)} />
      </span>
    );
  }

  refreshList() {
    this.props.dispatch(refreshData('users'));
  }

  handleRowClick(row) {
    this.setState({ ledgerPremium: row });
    this.props.dispatch(openModal(PremiumModal.MODAL_IDENTIFIER));
  }

  handleSearchUpdate(event) {
    this.props.dispatch(
      applyFilter(TABLE_NAME, 'filter[description]', event.target.value),
    );
  }

  handleAccountUpdate(option) {
    const { dispatch } = this.props;
    const value = option ? option.value : '';

    this.setState({ account: value });
    dispatch(applyFilter(TABLE_NAME, 'filter[account]', value));
  }

  handleBrokerUpdate(option) {
    const { dispatch } = this.props;
    const value = option ? option.value : '';

    this.setState({ broker: value });
    dispatch(applyFilter(TABLE_NAME, 'filter[broker]', value));
  }

  handleMatchedUpdate(option) {
    this.props.dispatch(applyFilter(TABLE_NAME, 'filter[batched]', option.value));
  }

  handleElementUpdate(option) {
    this.props.dispatch(applyFilter(TABLE_NAME, 'filter[element]', option.value));
  }

  handleFromUpdate(value) {
    const { dispatch } = this.props;
    this.setState({ fromDate: value });
    dispatch(
      applyFilter(TABLE_NAME, 'filter[date:from]', value ? moment(value).format('YYYY-MM-DD') : ''),
    );
  }

  handleToUpdate(value) {
    const { dispatch } = this.props;
    this.setState({ toDate: value });
    dispatch(
      applyFilter(TABLE_NAME, 'filter[date:to]', value ? moment(value).format('YYYY-MM-DD') : ''),
    );
  }

  render() {
    const { account, broker, fromDate, toDate } = this.state;

    return (
      <Box>
        <h2>Premiums</h2>
        <Row>
          <Col sm={2}>
            <ControlLabel className="filter-label">Search</ControlLabel>
            <FormControl onChange={::this.handleSearchUpdate} />
          </Col>
          <Col sm={4}>
            <ControlLabel className="filter-label">Account</ControlLabel>
            <SelectAccount
              async
              isClearable
              filters={[{ type: 'type' , match: 'debtor,creditor' }]}

              key={'select-account'}
              onChange={::this.handleAccountUpdate}
              input={{ value: account }}
              optionValuePath={'id'}
            />
          </Col>
          <Col sm={4}>
            <ControlLabel className="filter-label">Sub Account (Broker)</ControlLabel>
            <SelectBroker
              async
              isClearable
              key={'select-broker' + account}
              filters={[{ type: 'account' , match: account }]}
              onChange={::this.handleBrokerUpdate}
              input={{ value: broker }}
              optionValuePath={'id'}
            />
          </Col>
          <Col sm={2}>
            <ControlLabel className="filter-label">Matched</ControlLabel>
            <Select
              theme={(theme) => selectTheme(theme)}
              styles={styles}
              onChange={(option) => this.handleMatchedUpdate(option)}
              options={[
                { label: 'Unmatched', value: 'unmatched' },
                { label: 'Matched', value: 'matched' },
                { label: 'Paid', value: 'paid' },
                { label: 'All', value: 'all' },
              ]}
              defaultValue={{ label: 'Unmatched', value: 'unmatched' }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={2}>
            <ControlLabel className="filter-label">Element</ControlLabel>
            <Select
              theme={(theme) => selectTheme(theme)}
              styles={styles}
              onChange={(option) => this.handleElementUpdate(option)}
              options={[
                { label: 'All', value: '' },
                { label: 'Gross', value: 'gross' },
                { label: 'Commission', value: 'commission' },
              ]}
              defaultValue={{ label: 'All', value: '' }}
            />
          </Col>

          <Col sm={2}>
            <ControlLabel className="filter-label">Date From</ControlLabel>
            <DatePickerDob
              input={{
                value: fromDate,
                onChange: (value) => this.handleFromUpdate(value),
              }}
            />
          </Col>

          <Col sm={2}>
            <ControlLabel className="filter-label">Date To</ControlLabel>
            <DatePickerDob
              input={{
                value: toDate,
                onChange: (value) => this.handleToUpdate(value),
              }}
            />
          </Col>
        </Row>

        <Row className="data-table-row">
          <Col xs={12}>
            <DataTable
              source="/accounts/premiums"
              name={TABLE_NAME}
              showTotal
              onRowSelect={this.handleRowClick.bind(this)}
            >
              <TableHeaderColumn dataField="id" isKey hidden />

              <TableHeaderColumn dataField="created_at" dataFormat={this.formatDate} width={'12.5%'}>
                Date
              </TableHeaderColumn>

              <TableHeaderColumn dataField="policy_number" width={'12.5%'}>
                Policy Number
              </TableHeaderColumn>

              <TableHeaderColumn dataField="account_name" width={'12.5%'}>
                Account
              </TableHeaderColumn>

              <TableHeaderColumn dataField="broker_name" width={'12.5%'}>
                Broker/Branch
              </TableHeaderColumn>

              <TableHeaderColumn dataField="description" width={'12.5%'} dataSort>
                Description
              </TableHeaderColumn>

              <TableHeaderColumn dataField="currency" width={'12.5%'}>
                Currency
              </TableHeaderColumn>

              <TableHeaderColumn dataField="formatted_amount" width={'12.5%'}>
                Amount
              </TableHeaderColumn>

              <TableHeaderColumn dataField="matched" width={'12.5%'}>
                Matched
              </TableHeaderColumn>
            </DataTable>
          </Col>
          <PremiumModal
            ispatch={this.props.dispatch}
            ledgerPremium={this.state.ledgerPremium}
            callback={::this.refreshList}
          />
        </Row>
      </Box>
    );
  }
}

import React, { Component } from 'react';
import { Field, formValueSelector, reduxForm, reset } from 'redux-form';
import { connect } from 'react-redux';
import { Col, Form, Row, ControlLabel, FormGroup } from 'react-bootstrap';
import { filter, get, set, isEmpty } from 'lodash';
import { Button, ButtonBar, SelectOrganisation, SelectBroker, SelectRole } from '../../../../../common/components';
import { assignUserToOrganisation } from '../../../../organisation/redux/organisationActions';
import { assignUserToBroker } from '../../../../broker/redux/brokerActions';
import { getUser, getUserOrganisationRoles } from '../../../redux/userActions';
import { toastr } from 'react-redux-toastr';

class AddUserToOrganisation extends Component {
  constructor(props) {
    super(props);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.renderAddOrganisationButton = this.renderAddOrganisationButton.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderSelectOrganisation = this.renderSelectOrganisation.bind(this);
    this.renderBroker = this.renderBroker.bind(this);
    this.renderRoles = this.renderRoles.bind(this);
    this.state = {
      open: false,
      org: false,
      broker: false,
      role: false,
      orgId: '',
      brokerId: '',
      orgType: '',
    };
  }

  handleSubmit(formValues) {
    const { dispatch, userId } = this.props;
    const { orgId, orgType, brokerId } = this.state;

    const roles = get(formValues, 'roles', []);
    if (isEmpty(roles)) {
      toastr.error('Assign roles to continue');
      return;
    }

    const request = {
      data: {
        relationships: {
          user: {
            data: {
              id: userId,
            },
          },
          roles: {
            data: get(formValues, 'roles'),
          },
        },
      },
    };

    if (orgType === 'Broker') {
      return Promise.resolve(dispatch(assignUserToBroker(brokerId, request)))
        .then(this.close)
        .then(() => dispatch(getUser(userId)))
        .then(() => dispatch(getUserOrganisationRoles(userId)));
    }

    Promise.resolve(dispatch(assignUserToOrganisation(orgId, request)))
      .then(this.close)
      .then(() => dispatch(getUser(userId)))
      .then(() => dispatch(getUserOrganisationRoles(userId)));
  }

  open(section) {
    this.setState({
      open: true,
      [section]: true,
    });
  }

  close() {
    const { dispatch } = this.props;
    dispatch(reset('AddUserToOrganisation'));
    this.setState({
      open: false,
      org: false,
      broker: false,
      role: false,
      orgId: '',
      brokerId: '',
      orgType: '',
    });
  }

  renderCancelButton() {
    return (
      <Button className="pull-right" bsStyle="primary" handleClick={this.close}>
        Cancel
      </Button>
    );
  }

  renderAddOrganisationButton() {
    return (
      <Row>
        <Col xs={12}>
          <ButtonBar>
            <Button
              className={'pull-right'}
              handleClick={() => this.open('org')}
              label="Add Organisation"
            />
          </ButtonBar>
        </Col>
      </Row>
    );
  }

  renderSelectOrganisation() {
    return (
      <FormGroup>
        <Col md={2} componentClass={ControlLabel}>
          Organisation:
        </Col>
        <Col md={6}>
          <Field
            name="organisation"
            isObject
            async
            key="addUserToOrganisation"
            id="addUserToOrganisation"
            component={SelectOrganisation}
            input={{
              onChange: ::this.handleSelectOrganisation,
            }}
          />
        </Col>
        {!this.state.broker && !this.state.role && (
          <Col md={4}>
            <ButtonBar>
              {this.renderCancelButton()}
            </ButtonBar>
          </Col>
        )}
      </FormGroup>
    );
  }

  handleSelectOrganisation(option) {
    const { selects } = this.props;

    if (!option) {
      return;
    }

    const dataFetched = get(selects, 'addUserToOrganisation', []);
    const object = filter(dataFetched.data, (o) => {
      return o.id === option;
    });

    const orgType = get(object, '0.attributes.organisation_type_name');
    const broker = orgType === 'Broker';
    this.setState({
      broker: broker,
      role: !broker,
      orgId: option,
      brokerId: '',
      orgType: orgType,
    });
  }

  renderBroker() {
    const { orgId } = this.state;

    return (
      <FormGroup>
        <Col md={2} componentClass={ControlLabel}>
          Broker:
        </Col>
        <Col md={6}>
          <Field
            name="broker"
            isObject
            async
            byOrganisation={orgId}
            key={'addBroker' + orgId}
            id="addBroker"
            component={SelectBroker}
            input={{
              onChange: ::this.handleSelectBroker,
            }}
          />
        </Col>
        {!this.state.role && (
          <Col md={4}>
            <ButtonBar>
              {this.renderCancelButton()}
            </ButtonBar>
          </Col>
        )}
      </FormGroup>
    );
  }

  handleSelectBroker(option) {
    this.setState({ role: true, brokerId: option });
  }

  renderRoles() {
    const { dispatch } = this.props;
    return (
      <FormGroup>
        <Col md={2} componentClass={ControlLabel}>
          Assign Roles:
        </Col>
        <Col md={6}>
          <Field
            key={'addRoles' + this.state.orgId}
            name="roles"
            autoFilters={[{ type: 'organisation_type', match: this.state.orgType }]}
            component={SelectRole}
          />
        </Col>
        <Col md={4}>
          <ButtonBar>
            <Button
              className="pull-right"
              bsStyle="primary"
              handleClick={() => dispatch(this.props.handleSubmit(this.handleSubmit))}
              label="Confirm"
            />
            {this.renderCancelButton()}
          </ButtonBar>
        </Col>
      </FormGroup>
    );
  }

  render() {
    return (
      <Form>
        {!this.state.open && this.renderAddOrganisationButton()}
        {this.state.org && this.renderSelectOrganisation()}
        {this.state.broker && this.renderBroker()}
        {this.state.role && this.renderRoles()}
      </Form>
    );
  }
}

const validate = (values) => {
  let errors = {};

  if (isEmpty(get(values, 'roles', []))) {
    set(errors, 'roles', 'Select at least one role');
  }

  return errors;
};

const FORM_NAME = 'AddUserToOrganisation';
const form = reduxForm({ form: FORM_NAME, validate })(AddUserToOrganisation);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state) => {
  const values = selector(state, 'data');

  return {
    form: 'AddUserToOrganisation',
    formValues: values,
    selects: state.selects,
  };
};

export default connect(mapStateToProps)(form);

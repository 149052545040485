export const broker = {
  data: {
    type: 'broker',
    attributes: {
      reference: '',
      trading_name: '',
      branch_name: '',
      hold_client_money: '',
      network: '',
      metadata: {
        agency_number: '',
        transact_general_insurance: '',
        due_diligence_date: '',
        network_member: '',
        network_member_other: '',
        network_reference: '',
        website: '',
        no_of_staff: '',
        annual_gross_written_premium: '',
        income_overall: '',
        income_travel: '',
        income_pa: '',
        income_gadget: '',
        income_termlife: '',
        commercial_income_perc: '',
        retail_income_perc: '',
        overall_income: '',
        specialist_areas: '',
        main_products_sold: '',
        travel_providers: '',
        pa_providers: '',
        other_providers: '',
        use_software_house: '',
        software_house: '',
        software_house_other: '',
        last_meeting: '',
        other_networks: '',
      },
    },
    relationships: {
      users: {
        data: [],
      },
      organisation: {
        data: {
          id: null,
        },
      },
      broker_region: {
        data: {
          id: null,
        },
      },
      networks: {
        data: [],
      },
    },
  },
};

export const BROKER_STATUSES = {
  pending: 'Pending',
  active: 'Live',
  inactive: 'Cancelled',
};

export const brokerRules = {};

export default broker;

import React from 'react';

const TextField = ({
  input,
  label,
  placeholder,
  type,
  addonLeft,
  addonRight,
  infoButton,
  meta: { touched, error },
}) => {
  return (
    <div className={touched && error ? 'has-error' : ''}>
      {label && (
        <div>
          <label className="control-label">{label}</label> {infoButton}
        </div>
      )}

      <div>
        {type === 'textarea' ? (
          <textarea
            className="form-control textarea"
            placeholder={placeholder || ''}
            {...input}
          />
        ) : addonLeft || addonRight ? (
          <div className="input-group">
            {addonLeft && <div className="input-group-addon">{addonLeft}</div>}
            <input
              className="form-control"
              {...input}
              placeholder={placeholder || ''}
              type={type}
            />
            {addonRight && <div className="input-group-addon">{addonRight}</div>}
          </div>
        ) : (
          <input
            className="form-control"
            {...input}
            placeholder={placeholder || ''}
            type={type}
          />
        )}

        {touched && error && <span className="input-error">{error}</span>}
      </div>
    </div>
  );
}

export default TextField;

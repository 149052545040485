export const IS_SUBMITTING = 'socrates-online/policy/IS_SUBMITTING';
export const SET_POLICY = 'socrates-online/policy/SET_POLICY';
export const CLEAR_DATA = 'socrates-online/policy/CLEAR_DATA';
export const IS_CHECKING_CANCEL = 'socrates-online/policy/IS_CHECKING_CANCEL';
export const QUOTES_HISTORY = 'socrates-online/policy/QUOTES_HISTORY';
export const IS_GENERATING_DOCS = 'socrates-online/policy/IS_GENERATING_DOCS';
export const SET_DOCTORS_FORM = 'socrates-online/policy/SET_DOCTORS_FORM';
export const IS_SAVING = 'socrates-online/policy/IS_SAVING';
export const IS_PROCESSING_PRINT_JOB_REQUEST =
  'socrates-online/policy/IS_PROCESSING_PRINT_JOB_REQUEST';
export const IS_INVITING_RENEWALS = 'socrates-online/policy/IS_INVITING_RENEWALS';
export const SET_ACCOUNTS = 'socrates-online/policy/SET_ACCOUNTS';

export const initialState = {
  isSubmitting: false,
  isCheckingCancel: false,
  isGeneratingDocs: false,
  isProcessingPrintJobRequest: false,
  isSaving: false,
  isInvitingRenewals: false,
  resources: {},
  accounts: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case QUOTES_HISTORY:
      return {
        ...state,
        quotesHistory: action.data,
      };
    case IS_CHECKING_CANCEL:
      return {
        ...state,
        isCheckingCancel: action.status,
      };
    case IS_GENERATING_DOCS:
      return {
        ...state,
        isGeneratingDocs: action.status,
      };
    case SET_DOCTORS_FORM:
      return {
        ...state,
        doctorFormResponse: action.data,
      };
    case IS_SAVING:
      return {
        ...state,
        isSaving: action.status,
      };
    case IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.status,
      };
    case SET_POLICY:
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.data.data.id]: {
            ...action.data,
          },
        },
      };
    case IS_INVITING_RENEWALS:
      return {
        ...state,
        isInvitingRenewals: action.status,
      };
    case IS_PROCESSING_PRINT_JOB_REQUEST:
      return {
        ...state,
        isProcessingPrintJobRequest: action.status,
      };
    case SET_ACCOUNTS:
      return {
        ...state,
        accounts: action.accounts
      };
    case CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
}

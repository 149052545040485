import ReactMarkdown from 'react-markdown';
import { Field } from 'redux-form';


import ButtonGroup from '../../../LeisureTravelForm/ReduxForm/ButtonGroup';
import React from 'react';

import './MedicalQuestionRow.scss';
import { required } from "redux-form-validators";


const ContentParagraph = ({ children }) => {
  return (
    <p className={'medical-question-row--content-text'}>{children}</p>
  );
};
const HeaderParagraph = ({ children }) => {
  return (
    <p className={'medical-question-row--header-text'}>{children}</p>
  );
};

const HeaderHeading = ({ children }) => {
  return (
    <p className={'medical-question-row--header-heading'}>{children}</p>
  );
};
export const MedicalQuestionRow = ({ question, questionKey }) => {
  return (

    <>
      {question.header && (
        <div className={'medical-question-row--header'}>
          <ReactMarkdown
            source={question.header || ''}
            renderers={{
              link: (props) => (
                <a href={props.href} target="_blank">
                  {props.children}
                </a>
              ),
              paragraph: HeaderParagraph,
              heading: HeaderHeading
            }}
          />
        </div>
      )}
      <div className="medical-question-row" key={questionKey}>
        <div className="medical-question-row--question">
          <div className="medical-question-row--content">
            <ReactMarkdown
              source={question.content || ''}
              renderers={{
                link: (props) => (
                  <a href={props.href} target="_blank">
                    {props.children}
                  </a>
                ),
                paragraph: ContentParagraph,
                //heading: ContentHeading
              }}
            />
          </div>

          <div className="medical-question-row--buttons">
            {question.type === 'boolean' && (
              <Field
                name={`${questionKey}.answer`}
                component={ButtonGroup}
                inline
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]}
                showError
                validate={required({ msg: 'This question is required' })}
              />
            )}
          </div>
        </div>

      </div>
    </>
  );
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';
import SelectMenu from '../SelectMenu/SelectMenu';

export const SELECT_IDENTIFIER = 'organisationType';

class SelectMenuOrganisationType extends Component {
  static propTypes = {
    label: PropTypes.string,
    labelSize: PropTypes.number,
    infoButton: PropTypes.element,
    name: PropTypes.string,
    meta: PropTypes.object,
  };

  renderSelectMenu() {
    const { isObject } = this.props;
    let source = 'organisations/types';

    if (isObject) {
      return (
        <SelectMenu
          source={source}
          isObject
          sourceKey={'orgTypeObject'}
          {...this.props}
        />
      );
    }

    return (
      <SelectMenu source={source} optionValuePath={'id'} {...this.props} />
    );
  }

  render() {
    const { infoButton, meta, label, labelSize, smFieldSize } = this.props;

    const labelSmSize = labelSize || 2;
    const menuSize = smFieldSize || 12 - labelSize;

    if (label) {
      return (
        <FormGroup
          controlId={name}
          className={meta && meta.touched && meta.error ? ' has-error' : ''}
        >
          <Col sm={labelSmSize}>
            <ControlLabel>
              {label} {infoButton}
            </ControlLabel>
          </Col>
          <Col sm={menuSize}>
            {this.renderSelectMenu()}
            {meta && meta.touched && meta.error && (
              <span className="input-error">{meta.error}</span>
            )}
          </Col>
        </FormGroup>
      );
    }

    return this.renderSelectMenu();
  }
}

export default connect()(SelectMenuOrganisationType);

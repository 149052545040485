import React from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';

export default function formatDateTime(cell) {
  if (!cell) {
    return '';
  }
  return (
    <span>
      <FormattedDate value={new Date(cell)} /> <FormattedTime value={new Date(cell)} />
    </span>
  );
}

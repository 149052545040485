import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import AutoCapsInput from '../AutoCapsInput/AutoCapsInput';
import { getAddresses, getAddress } from './redux/PostcodeActions';
import './Postcode.scss';

class Postcode extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    labelSize: PropTypes.number,
    line1Field: PropTypes.object.isRequired,
    line2Field: PropTypes.object.isRequired,
    line3Field: PropTypes.object.isRequired,
    townField: PropTypes.object.isRequired,
    countyField: PropTypes.object.isRequired,
    postcodeField: PropTypes.object.isRequired,
    addressOptions: PropTypes.array,
    selectedID: PropTypes.string,
  };

  handleSearch(e) {
    e.preventDefault();
    this.props.dispatch(getAddresses(this.props.postcodeField.value));
  }

  handleAddressSelect(e) {
    this.props.dispatch(getAddress(e.target.value));
  }

  handleConfirm(e) {
    e.preventDefault();
    // this.props.dispatch(
    //   searchAddress(
    //     this.props.selectedID,
    //     this.props.line1Field,
    //     this.props.line2Field,
    //     this.props.line3Field,
    //     this.props.townField,
    //     this.props.countyField,
    //     this.props.postcodeField,
    //   ),
    // );
  }

  componentWillUnmount() {
    // this.props.dispatch(clearAddress());
  }

  render() {
    const {
      labelSize,
      line1Field,
      line2Field,
      line3Field,
      townField,
      countyField,
      postcodeField,
      addressOptions,
    } = this.props;

    const labelClass = 'control-label col-xs-' + (labelSize ? labelSize : '3');
    const divClass = 'col-xs-' + (labelSize ? 12 - labelSize : '9');

    const footer = (
      <Button
        bsStyle="primary"
        label="use address"
        handleClick={this.handleConfirm.bind(this)}
      />
    );

    return (
      <div>
        <div className="form-group">
          <label className={labelClass}>Postcode</label>
          <div className={divClass}>
            <div className="row padded-row">
              <div className="col-xs-6">
                <AutoCapsInput
                  placeholder="Postcode"
                  field={postcodeField}
                  className="postcode"
                />
              </div>
              <div className="col-xs-6">
                <Button handleClick={this.handleSearch.bind(this)} label="search" />
              </div>
            </div>
          </div>
        </div>
        <AutoCapsInput
          label="Address"
          placeholder="Line 1"
          labelSize={labelSize ? labelSize : 3}
          field={line1Field}
        />
        <AutoCapsInput
          label=" "
          placeholder="Line 2"
          labelSize={labelSize ? labelSize : 3}
          field={line2Field}
        />
        <AutoCapsInput
          label=" "
          placeholder="Line 3"
          labelSize={labelSize ? labelSize : 3}
          field={line3Field}
        />
        <AutoCapsInput
          label=" "
          placeholder="Town"
          labelSize={labelSize ? labelSize : 3}
          field={townField}
        />
        <AutoCapsInput
          label=" "
          placeholder="County"
          labelSize={labelSize ? labelSize : 3}
          field={countyField}
        />
        <Modal name="postcode" title="postcode search" close footer={footer}>
          <FormGroup controlId="select_address">
            <ControlLabel>Select Address</ControlLabel>
            <FormControl
              componentClass="select"
              size="12"
              onChange={this.handleAddressSelect.bind(this)}
            >
              {addressOptions
                ? addressOptions.map(function(obj, index) {
                    return (
                      <option key={index} value={obj.id}>
                        {obj.name}
                      </option>
                    );
                  })
                : ''}
            </FormControl>
          </FormGroup>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    addressOptions: Array.isArray(state.postcode.options) ? state.postcode.options : [],
    selectedID: state.postcode.selectedID,
  };
}

export default connect(mapStateToProps)(Postcode);

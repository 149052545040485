import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { actions as productActions } from '../../productManagement/redux/ProductReducer';
import { actions as documentActions } from '../redux/documentManagementActions';
import { Box, Loader } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import getIncludedResource from '../../../helpers/getIncludedResource';
import DocumentPacksForm from '../components/DocumentPacksForm/DocumentPacksForm';
import ProductName
  from '../../productManagement/components/ProductFrontPage/components/ProductName/ProductName';
import ProductNavigation
  from '../../productManagement/components/ProductNavigation/ProductNavigation';

const DocumentPacksEditContainer = ({
  params,
  onUpdateDocumentPacks,
  onGetProduct,
  location
}) => {
  const { product, isLoading } = useSelector((state) => state.productManagement);

  const currentProductId = product?.data?.id;

  const { productId } = params;

  useEffect(
    () => {
      onGetProduct(productId, ['document_packs', 'document_packs.scheme_documents']);
    },
    [productId, onGetProduct],
  );

  const handleUpdateProduct = (formValues) => {
    const newValues = {
      data: formValues.filter((docPack) => !!docPack.relationships?.scheme_documents),
    };

    return onUpdateDocumentPacks(newValues, productId);
  };

  if (!currentProductId || currentProductId !== productId || isLoading) {
    return (
      <InternalContainer title={'Product Management'}>
        <Loader/>
      </InternalContainer>
    );
  }

  const documentPacks = getIncludedResource(
    product.data,
    product.included,
    'document_packs',
  );

  const isReady = Array.isArray(documentPacks?.data);

  const { icon, name, status, metadata } = product?.data?.attributes ?? {};
  const { is_wl_product: isWhitelabel } = metadata ?? {};

  return (
    <InternalContainer title={'Product Management'}>
      <Box>

        <ProductName
          icon={icon}
          name={name}
          status={status}
        >
          <ProductNavigation
            currentLocation={location.pathname}
            id={productId}
            isWhitelabel={isWhitelabel}
          />

        </ProductName>
        {!isReady && (
          <DocumentPacksForm
            product={product.data}
            onUpdateDocumentPack={handleUpdateProduct}
            documentPacks={documentPacks}
          />
        )}

      </Box>
    </InternalContainer>
  );
};

export default connect(
  null,
  {
    onUpdateDocumentPacks: documentActions.updateDocumentPacks,
    onGetProduct: productActions.getProduct,
  },
)(DocumentPacksEditContainer);

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Box, Button } from '../../../../../../common/components';
// import SchemeImportModal from '../SchemeImportModal/SchemeImportModal'
import './ProductListItem.scss';

const CloneButton = ({ id, isWhitelabelProduct }) => {
  if (!isWhitelabelProduct) {
    return null;
  }
  return (
    <Button
      className="btn-blue pad-right"
      link
      to={`/admin/products/${id}/clone`}
    >
      Clone
    </Button>
  );
};

const ProductListItem = ({ product }) => {


  const { id, attributes: { icon, name, product_code: code, metadata } } = product ?? {};

  const isWhiteLabel = metadata?.is_wl_product;

  return (
    <Box className="hoverable-section">
      <Row>
        <Col sm={12}>
          <h2 className="title-with-icon">
            <img src={icon} alt={code}/>
            {name}
          </h2>
        </Col>
      </Row>
      {/*<div className="divider" />*/}
      <div className="section-item">
        <Row>
          <Col sm={12}>

            <Button
              className="btn-blue pad-right"
              link
              to={`/admin/products/${id}/scheme-management`}
            >
              Scheme Management
            </Button>

            <Button
              className="btn-blue pad-right"
              link
              to={`/admin/products/${id}/product-management`}
            >
              Product Management
            </Button>

            {/*<Button*/}
            {/*className="btn-blue pad-right"*/}
            {/*link*/}
            {/*to={`/admin/products/${product.id}/definitions`}*/}
            {/*>*/}
            {/*Definitions*/}
            {/*</Button>*/}
            {/*<Button*/}
            {/*className="btn-blue pad-right"*/}
            {/*link*/}
            {/*to={`/admin/products/${product.id}/declaration`}*/}
            {/*>*/}
            {/*Declaration*/}
            {/*</Button>*/}
            <Button
              className="btn-blue pad-right"
              link
              to={`/admin/products/${id}/documents`}
            >
              Static Documents
            </Button>
            <Button
              className="btn-blue pad-right"
              link
              to={`/admin/products/${id}/dynamic-documents`}
            >
              Dynamic Documents
            </Button>
            <Button
              className="btn-blue pad-right"
              link
              to={`/admin/products/${id}/document-pack`}
            >
              Document Pack
            </Button>
            <Button
              className="btn-blue pad-right"
              link
              to={`/admin/products/${id}/brochure-content`}
            >
              Brochure Content
            </Button>
            {!isWhiteLabel && (
              <Button
                className="btn-blue pad-right"
                link
                to={`/admin/products/${id}/covid-message-content`}
              >
                Covid Message Content
              </Button>
            )}
            <CloneButton id={id} isWhitelabelProduct={isWhiteLabel}/>
            {/*<Button*/}
            {/*className="btn-blue pad-right"*/}
            {/*link*/}
            {/*to={`/admin/products/${product.id}/admin-fees`}*/}
            {/*>*/}
            {/*Admin Fees*/}
            {/*</Button>*/}
            {/*<SchemeImportModal />*/}
          </Col>
        </Row>
      </div>
    </Box>
  );

};

export default ProductListItem;

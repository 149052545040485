export default function displayAddress(address) {
  let addressString = '';

  delete address['country'];

  Object.keys(address).forEach((key) => {
    if (!address[key]) {
      return;
    }

    if (addressString) {
      addressString += ', ';
    }

    addressString += address[key];
  });

  return addressString != '' ? addressString + '.' : '';
}

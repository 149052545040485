import React, { Component } from 'react';
import Axios from 'axios';
import Markdown from 'react-remarkable';
import CenteredSection from '../components/CenteredSection/CenteredSection';
import Box from '../../../common/components/Box/Box';
import { replace } from 'react-router-redux';

export default class CookiesContainer extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      content: {},
    };
  }

  componentWillMount() {
    Axios.get('public/contents?filter[slug]=/cookies')
      .then((response) => {
        this.setState({ content: response.data.data[0] });
      })
      .catch(() => {
        this.props.dispatch(replace('/404'));
      });
  }

  render() {
    const content = this.state.content;
    return (
      <div className="guest-section">
        <div className="guest-title">Cookies</div>
        <CenteredSection className="page-content">
          {content && content.attributes && (
            <Box>
              <Markdown source={content.attributes.sections['body']} />
            </Box>
          )}
        </CenteredSection>
      </div>
    );
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Col, ControlLabel } from 'react-bootstrap';
import SelectMenu from '../SelectMenu/SelectMenu';
import AsyncSelectMenu from '../SelectMenu/AsyncSelectMenu';
import MultiSelectMenu from '../SelectMenu/MultiSelectMenu';

export const SELECT_IDENTIFIER = 'users';

export default class SelectUser extends Component {
  static propTypes = {
    label: PropTypes.string,
    labelSize: PropTypes.number,
    infoButton: PropTypes.element,
    name: PropTypes.string,
    meta: PropTypes.object,
    labelKeys: PropTypes.array,
    isObject: PropTypes.bool,
    isMulti: PropTypes.bool,
    filters: PropTypes.array,
  };

  renderSelectMenu() {
    const { isMulti, sourceKey, isObject, byBroker, byOrganisation, async, labelKeys, key } = this.props;

    const getFilter = () => {
      if (byBroker) {
        return '?filter[broker]=' + byBroker;
      }

      if (byOrganisation) {
        return '?filter[organisation]=' + byOrganisation;
      }
      return '';
    };

    const getFilterKey = () => {
      if (byBroker) {
        return 'byBroker' + byBroker;
      }
      if (byOrganisation) {
        return 'byOrganisation' + byOrganisation;
      }
      return '';
    };

    if (async) {
      return (
        <AsyncSelectMenu
          key={key || 'select-user'}
          optionValuePath={'id'}
          labelPath={'attributes.full_name'}
          url={SELECT_IDENTIFIER + getFilter()}
          sort={'first_name'}
          labelKeys={labelKeys || ['attributes.first_name', 'attributes.last_name']}
          {...this.props}
        />
      );
    }

    if (isMulti) {
      return (
        <MultiSelectMenu
          source={SELECT_IDENTIFIER}
          sourceKey={sourceKey || 'multi-select-user'}
          labelPath={'attributes.full_name'}
          filter={getFilter()}
          {...this.props}
        />
      );
    }

    if (isObject) {
      return (
        <SelectMenu
          source={SELECT_IDENTIFIER}
          isClearable
          isObject
          labelPath={'attributes.full_name'}
          filter={getFilter()}
          sourceKey={'Object' + getFilterKey()}
          {...this.props}
        />
      );
    }

    return (
      <SelectMenu
        source={SELECT_IDENTIFIER}
        isClearable
        labelPath={'attributes.full_name'}
        filter={getFilter()}
        sourceKey={getFilterKey()}
        optionValuePath={'id'}
        {...this.props}
      />
    );
  }

  render() {
    const { infoButton, name, meta, label, labelSize } = this.props;

    if (label) {
      const labelSmSize = labelSize || 2;
      const menuSize = 12 - labelSmSize;

      return (
        <FormGroup
          controlId={name}
          className={meta && meta.touched && meta.error ? ' has-error' : ''}
        >
          <Col sm={labelSmSize}>
            <ControlLabel>
              {label} {infoButton}
            </ControlLabel>
          </Col>
          <Col sm={menuSize}>
            {this.renderSelectMenu()}
          </Col>
        </FormGroup>
      );
    }

    return this.renderSelectMenu();
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Loader, Box } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import { getCampaigns } from '../redux/campaignActions';
import CampaignDetails from '../components/CampaignDetails/CampaignDetails';

class CampaignsShowContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      activeTab: 1,
    };
  }

  componentWillMount() {
    this.props.dispatch(getCampaigns(this.props.params.id));
  }

  handleTab(key) {
    this.setState({ activeTab: key });
  }

  render() {
    const resource = this.props.campaign.resources[this.props.params.id];
    const buttons = (
      <Button bsStyle="default" label="Back" isBackButton leftIcon="caret-left" />
    );
    return (
      <InternalContainer title={'Campaigns'} buttons={buttons}>
        {resource ? (
          <Box>
            <CampaignDetails {...this.props} resource={resource} />
          </Box>
        ) : (
          <Loader />
        )}
      </InternalContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    campaign: state.campaign,
  };
}

export default connect(mapStateToProps)(CampaignsShowContainer);

import React, { useState } from 'react';
import { Box, Button, DataTable } from '../../../../common/components';
import { connect } from 'react-redux';
import {
  refreshData
} from '../../../../common/components/DataTable/redux/dataTableActions';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { Col, Row } from 'react-bootstrap';
import { EditBrandModal } from './EditBrandModal/EditBrandModal';



const getInsurerSchema = (organisationId) => {
  return {
    data: {
      id: '',
      attributes: {
        code: '',
        name: ''
      },
      relationships: {
        organisation: {
          data: {
            id: organisationId
          }
        }

      }
    }
  };
};

const OrganisationInsurers = ({
  resource,
  onRefreshData,

}) => {
  const [brandForModal, setBrandForModal] = useState(null);

  const handleRowClick = (row) => {
    setBrandForModal({
      data: {
        id: row.id,
        attributes: {
          code: row.id,
          name: row.name,
          logo: row.logo,
        }
      }
    });
  };

  const handleAddInsurer = () => {
    setBrandForModal(getInsurerSchema(resource.data.id));
  };
  const clearBrand = () => {
    setBrandForModal(null);
    onRefreshData('insurers');
  };


  return (
    <Box>
      <DataTable
        source="/organisations/insurers"
        name="insurers"
        showTotal
        autoFilters={[{ type: 'filter[organisation]', value: resource.data.id }]}
        onRowSelect={handleRowClick}
      >
        <TableHeaderColumn dataField="name" isKey>
          Name
        </TableHeaderColumn>

        <TableHeaderColumn dataField="id">Code</TableHeaderColumn>
      </DataTable>

      <Row className="margin-top">
        <Col xs={12}>
          <Button
            label="Add a Brand"
            className="pull-right"
            handleClick={handleAddInsurer}
          />
        </Col>
      </Row>
      <EditBrandModal show={brandForModal} onHide={clearBrand}/>


    </Box>
  );

};


export default connect(null,
  {
    onRefreshData: refreshData
  }
)(OrganisationInsurers);

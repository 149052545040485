import React, { Component } from 'react';
import { push } from 'react-router-redux';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { ControlLabel, FormControl } from 'react-bootstrap';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Box, Button, DataTable } from '../../../../common/components';
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions';
import './styles.scss';

/*****************************
 BrokersApplication DataTable
 *****************************/

export default class BrokersApList extends Component {
  constructor(props) {
    super(props);
    this.handleSearchUpdate = this.handleSearchUpdate.bind(this);
  }

  formatDate(cell) {
    return cell ? (
      <span>
        <FormattedDate value={new Date(cell)} /> <FormattedTime value={new Date(cell)} />
      </span>
    ) : (
      <span />
    );
  }

  handleRowClick(row) {
    this.props.dispatch(push('/admin/broker-applications/' + row.id));
  }

  handleFilterUpdate(event) {
    this.setState({ selection: event.target.value });
    switch (event.target.value) {
      case 'partners':
        this.setState({
          partner: true,
          personnel: false,
        });
        break;
      case 'keyPersonnel':
        this.setState({
          personnel: true,
          partner: false,
        });
        break;
      default:
        this.props.dispatch(
          applyFilter('content', 'filter[template.type]', event.target.value),
        );
        this.setState({
          partner: false,
          personnel: false,
        });
    }
  }

  handleRender() {
    return (
      <div>
        <Box>
          <div className="row no-bottom-margin">
            <div className="col-sm-5">
              <ControlLabel className="filter-label">Search</ControlLabel>
              <FormControl onChange={this.handleSearchUpdate} disabled />
            </div>
            <div className="col-sm-3">
              <ControlLabel className="filter-label">Type</ControlLabel>
            </div>
          </div>
        </Box>
      </div>
    );
  }

  handleSearchUpdate(event) {
    this.props.dispatch(applyFilter('content', 'filter[name]', event.target.value));
  }

  render() {
    return (
      <div>
        <Box>
          <div className="row">
            <div className="col-sm-4">
              <ControlLabel className="filter-label">Search</ControlLabel>
              <FormControl onChange={this.handleSearchUpdate} />
            </div>
          </div>

          <DataTable
            source="/brokers/applications/"
            name="content"
            showTotal
            onRowSelect={this.handleRowClick.bind(this)}
          >
            <TableHeaderColumn dataField="id" isKey hidden />
            <TableHeaderColumn dataField="reference" dataSort width={'100px'}>
              Reference
            </TableHeaderColumn>
            <TableHeaderColumn dataField="name" dataSort width={'280px'}>
              Name
            </TableHeaderColumn>
            <TableHeaderColumn dataField="status" dataSort={false} width={'100px'}>
              Status
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="activated_at"
              dataSort
              dataFormat={this.formatDate}
              width={'180px'}
            >
              Activation Date
            </TableHeaderColumn>
          </DataTable>

          <div className="row actions">
            <Button
              link
              to="/admin/broker-applications/new"
              className="pull-right"
              bsStyle="primary"
              label="new Application"
            />
          </div>
        </Box>
      </div>
    );
  }
}

import React from 'react';
import { connect } from 'react-redux';
import { Form, Modal } from 'react-bootstrap';
import { Field, FieldArray, getFormValues, reduxForm, submit } from 'redux-form';
import { required } from 'redux-form-validators';
import { compose } from 'redux';
import { Box, Button, HorizontalFormControl, } from '../../../../../../common/components';
import EditDeclineFieldArray from './EditDeclineFieldArray';
import { SelectField } from '../../../../../../common/components/ReduxFormField';
import { EMPTY_RULE, formatOptionDeclines } from '../../../../helpers/declineHelpers';

const isRequired = required({ message: 'Required' });
const FORM_NAME = 'EditOptionDecline';

const EditOptionDeclineForm = ({
  reason,
  formValues,
  regions,
  options,
  coverLevels,
  medicalQuestionKeys,
  array,
  handleSubmit,
  onUpdateDecline,
  productType,
}) => {
  const optionList = options.map((option) => ({
    value: `options.${option.key}`,
    label: option.name,
  }));

  const declines = formValues?.declines ?? [];

  const handleAddReferral = (values) => {
    const formattedDecline = formatOptionDeclines(values);
    onUpdateDecline(formattedDecline);
  };

  const addRestriction = () => {
    array.push('declines', EMPTY_RULE);
  };
  const selectedOption = options.find((option) => `options.${option.key}` === formValues?.option);

  const optionOptions = selectedOption?.options?.map((opt) => ({
    label: opt.name,
    value: opt.key
  }));

  return (
    <Box>
      <Form horizontal>
        <>
          <Modal.Body>
            <h3>Add Rule</h3>
            <Field
              name={'reason'}
              smFieldSize={9}
              label={'Display Message:'}
              labelSize={3}
              component={HorizontalFormControl}
              validate={[isRequired]}
            />
            <Field
              name={'referral_type'}
              label={'Type:'}
              fieldSize={9}
              labelSize={3}
              isClearable
              component={SelectField}
              options={[{
                label: 'Decline',
                value: 'decline',
              },
                {
                  label: 'Referral',
                  value: 'check'
                }]}
              validate={required({ message: 'Required' })}
            />
            <Field
              name={'team_factor'}
              smFieldSize={9}
              label={'Team Factor:'}
              labelSize={3}
              type={'number'}
              component={HorizontalFormControl}
            />

            <Field
              name="option"
              smFieldSize={9}
              label={'Option:'}
              labelSize={3}
              component={SelectField}
              options={optionList}
              validate={[isRequired]}
            />
            {formValues?.option === 'options.hazardous_activities' || formValues?.option === 'options.acceptable_activities' && (
              <Field
                name="value"
                smFieldSize={9}
                label={'Value'}
                labelSize={3}
                component={SelectField}
                options={optionOptions}
                validate={[isRequired]}
              />
            )}

            <FieldArray
              name={'declines'}
              component={EditDeclineFieldArray}
              declines={declines}
              regions={regions}
              coverLevels={coverLevels}
              medicalQuestionKeys={medicalQuestionKeys}
              productType={productType}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-blue" handleClick={addRestriction}>
              Add Another Restriction
            </Button>
            <Button bsStyle="primary" handleClick={handleSubmit(handleAddReferral)}>
              {reason ? 'Update' : 'Add'} Decline
            </Button>
          </Modal.Footer>
        </>
      </Form>
    </Box>
  );
};

export default compose(
  connect(
    (state, props) => {
      // const optionName = props.optionName.startsWith('options.') ?  props.optionName : `options.${props.optionName}`;
      return {
        initialValues: {
          reason: props.reason,
          declines: props.declines,
          option: props.optionName,
          value: props.optionValue,
          team_factor: props.teamFactor,
          referral_type: props.referralType
        },
        formValues: getFormValues(FORM_NAME)(state),
      };
    },
    {
      submit: submit,
    },
  ),
  reduxForm({
    form: FORM_NAME,
  }),
)(EditOptionDeclineForm);

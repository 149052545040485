import React, { Component } from 'react';
import { push } from 'react-router-redux';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { ControlLabel, FormControl } from 'react-bootstrap';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Box, Button, DataTable } from '../../../../common/components';
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions';
import KeyPersonnelForm from '../KeyPersonnelForm';
import PartnersForm from '../PartnersForm';
import './styles.scss';

export default class Brochure extends Component {
  constructor(props) {
    super(props);
    this.handleRender = this.handleRender.bind(this);
    this.customSelection = this.customSelection.bind(this);
    this.state = {
      partner: false,
      personnel: false,
      selection: '',
    };
  }

  formatDate(cell) {
    return (
      <span>
        <FormattedDate value={new Date(cell)} /> <FormattedTime value={new Date(cell)} />
      </span>
    );
  }

  handleRowClick(row) {
    this.props.dispatch(push('/admin/content/' + row.id));
  }

  handleFilterUpdate(event) {
    this.setState({ selection: event.target.value });
    switch (event.target.value) {
      case 'partners':
        this.setState({
          partner: true,
          personnel: false,
        });
        break;
      case 'keyPersonnel':
        this.setState({
          personnel: true,
          partner: false,
        });
        break;
      default:
        this.props.dispatch(
          applyFilter('content', 'filter[template.type]', event.target.value),
        );
        this.setState({
          partner: false,
          personnel: false,
        });
    }
  }

  handlePartnersForm() {
    //Day
    return <PartnersForm {...this.props} />;
  }

  handleKeyPersonnelForm() {
    return <KeyPersonnelForm {...this.props} />;
  }

  customSelection() {
    return (
      <FormControl
        componentClass="select"
        type="select"
        //onChange={::this.handleFilterUpdate}
        value={this.state.selection}
        placeholder="select"
      >
        <option key="0" value={'article,page'}>
          All
        </option>
        <option key="1" value="article">
          New Articles
        </option>
        <option key="2" value="page">
          Page
        </option>
        <option key="3" value="keyPersonnel">
          Key Personnel
        </option>
        <option key="4" value="partners">
          Partners
        </option>
      </FormControl>
    );
  }

  handleRender() {
    return (
      <div>
        <Box>
          <div className="row no-bottom-margin">
            <div className="col-sm-5">
              <ControlLabel className="filter-label">Search</ControlLabel>
              <FormControl onChange={::this.handleSearchUpdate} disabled />
            </div>
            <div className="col-sm-3">
              {/*<ControlLabel className="filter-label">Type</ControlLabel>*/}
              {/*{this.customSelection()}*/}
            </div>
          </div>
        </Box>
        {this.state.partner ? this.handlePartnersForm() : this.handleKeyPersonnelForm()}
      </div>
    );
  }

  handleSearchUpdate(event) {
    this.props.dispatch(applyFilter('content', 'filter[name]', event.target.value));
  }

  render() {
    return (
      <div>
        <Box>
          <div className="row">
            <div className="col-sm-5">
              <ControlLabel className="filter-label">Search</ControlLabel>
              <FormControl onChange={::this.handleSearchUpdate} />
            </div>
            <div className="col-sm-3">
              {/*<ControlLabel className="filter-label">Type</ControlLabel>*/}
              {/*{this.customSelection()}*/}
            </div>
          </div>

          <DataTable
            source="/contents"
            name="content"
            showTotal
            onRowSelect={this.handleRowClick.bind(this)}
            autoFilters={[{ type: 'filter[template.type]', value: 'page,article' }]}
          >
            <TableHeaderColumn dataField="id" isKey hidden />
            <TableHeaderColumn dataField="name" dataSort>
              Name
            </TableHeaderColumn>
            <TableHeaderColumn dataField="content_type" dataSort width={'180px'}>
              Type
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="created_at"
              dataSort
              dataFormat={this.formatDate}
              width={'180px'}
            >
              Created
            </TableHeaderColumn>
          </DataTable>

          <div className="row actions">
            <Button
              link
              to="/admin/content/new"
              className="pull-right"
              bsStyle="primary"
              label="new content"
            />
          </div>
        </Box>
      </div>
    );
  }
}

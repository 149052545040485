import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, FormControl, FormGroup, ControlLabel } from 'react-bootstrap';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import Select from 'react-select';
import selectTheme, { styles } from '../../../../helpers/selectTheme';
import { sortBy } from 'lodash';

class ProductFilter extends Component {
  static propTypes = {
    label: PropTypes.string,
    labelSize: PropTypes.number,
    infoButton: PropTypes.element,
  };

  render() {
    const { auth, onChange, label, labelSize, infoButton } = this.props;
    const products = sortBy(
      getIncludedResource(auth.user.data, auth.user.included, 'available_products'),
      'attributes.name',
    );

    const optionsList = products.map((product, i) => {
      return (
        <option key={i} value={product.id}>
          {product.attributes.name}
        </option>
      );
    });

    const options = products.map((product) => {
      return {
        label: product.attributes.name,
        value: product.id,
      };
    });

    if (label) {
      const labelSmSize = labelSize || 2;
      const menuSize = 12 - labelSmSize;

      return (
        <FormGroup controlId="select-status">
          {label && (
            <Col componentClass={ControlLabel} sm={labelSize}>
              {label} {infoButton}
            </Col>
          )}
          <Col sm={menuSize}>
            <FormControl
              componentClass="select"
              type="select"
              onChange={onChange}
              placeholder="select"
              theme={(theme) => selectTheme(theme)}
            >
              <option value="">All</option>
              {optionsList}
            </FormControl>
          </Col>
        </FormGroup>
      );
    }

    return (
      <Select
        theme={(theme) => selectTheme(theme)}
        styles={styles}
        options={options}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(ProductFilter);

import React from 'react';

import { connect } from 'react-redux';
import { reduxForm, Field, getFormValues, Form } from 'redux-form';
import { required } from 'redux-form-validators';
import { Modal } from 'react-bootstrap';
import { RadioGroup, SelectField } from '../../../../common/components/ReduxFormField';
import {
  Button,

  DatePickerDob,
  HorizontalFormControl,
  SelectCurrency,
  SelectBroker,
  ButtonBar,
} from '../../../../common/components';

import moment from 'moment/moment';
import SelectCashAccount from '../../../../common/components/Selects/SelectCashAccount';
import {  compose } from 'redux';



const PAYMENT_TYPES = [
  { label: 'BACS', value: 'BACS' },
  { label: 'Cheque', value: 'Cheque' },
];

const MakeReceiptForm = ({
  accountId,
  onSave,
  handleSubmit,
  submitting
}) => {

  return (
    <div>
      <Form onSubmit={handleSubmit(onSave)}>

        <Field
          name="meta.cash_account"
          label="Cash Account"
          labelSize={3}
          component={SelectCashAccount}
          validate={required({ msg: 'Account is required' })}
        />
        <Field
          id="transactionType"
          label={'Transaction Type'}
          labelSize={3}
          name={'meta.ledger_entries[0].transaction_type'}
          component={RadioGroup}
          inline
          options={[
            { label: 'Receipt', value: 'receipt' },
            { label: 'Payment', value: 'payment' },
          ]}
          validate={required({ msg: 'Transaction type is required' })}
        />
        <Field
          name={'meta.ledger_entries[0].description'}
          label="Description"
          labelSize={3}
          component={HorizontalFormControl}
          validate={required({ msg: 'Description is required' })}
        />
        <Field
          async
          name={'meta.ledger_entries[0].broker'}
          key={'select-broker-' + accountId}
          label="Broker"
          labelSize={3}
          component={SelectBroker}
          filters={{ account: accountId }}
          isClearable
        />
        <hr/>
        <Field
          name={'meta.ledger_entries[0].date'}
          label="Date"
          labelSize={3}
          maxDate={moment().format()}
          component={DatePickerDob}
          validate={required({ msg: 'Date is required' })}
        />
        <Field
          name={'meta.ledger_entries[0].payment_type'}
          label="Type"
          labelSize={3}
          options={PAYMENT_TYPES}
          component={SelectField}
        />
        <Field
          name={'meta.ledger_entries[0].currency'}
          label="Currency"
          labelSize={3}
          component={SelectCurrency}
          defaultValue={{ value: 'GBP', label: 'Pound sterling' }}
        />
        <Field
          name={'meta.ledger_entries[0].amount'}
          label="Amount"
          labelSize={3}
          currency
          component={HorizontalFormControl}
          validate={required({ msg: 'Amount is required' })}
        />
        <Modal.Footer>
          <ButtonBar>
            <Button
              type="submit"
              bsStyle="primary"
              isLoading={submitting}
              label="Save"
            />
          </ButtonBar>
        </Modal.Footer>
      </Form>

    </div>
  );
};

const FORM_NAME = 'MakeReceiptForm';
export default compose(
  connect(
    (state, ownProps) => ({

      formValues: getFormValues(FORM_NAME)(state),
      initialValues: {
        data: {
          attributes: {
            currency: 'GBP'
          }
        },
        meta: {
          cash_account: '',
          ledger_entries: [
            {
              account: ownProps.accountId,
              description: '',
              date: '',
              payment_type: '',
              transaction_type: ownProps.transactionType,
              currency: 'GBP',
              amount: ownProps.amount,
              metadata: {
                match_item: true,
              },
            },
          ],
        },
      },


    })
  ),
  reduxForm({ form: FORM_NAME })
)(MakeReceiptForm);


// export default connect(mapStateToProps, mapDispatchToProps)( reduxForm({ form: FORM_NAME, validate })(MakeReceiptButton));
// const form = reduxForm({ form: FORM_NAME, validate })(MakeReceiptButton);

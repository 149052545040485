import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, FormSection } from 'redux-form';
import { Box, HorizontalFormControl } from '../../../../../../common/components';
import { FaTrash } from 'react-icons/lib/fa';

import './SchemeGroupDetailsField.scss';
import { format, required } from 'redux-form-validators';

const LABEL_SIZE = 2;
export const SchemeGroupDetailsField = ({ field, onRemove, index }) => {

  const remove = () => {
    onRemove(index);
  };
  return (
    <Box>

      <Row>
        <Col sm={8} smOffset={1}>

          <FormSection name={field}>
            <Field
              name={'name'}
              label={'Scheme Name'}
              labelSize={LABEL_SIZE}
              component={HorizontalFormControl}
            />
            <Field
              name={'cover_level'}
              label={'Cover Level'}
              labelSize={LABEL_SIZE}
              component={HorizontalFormControl}
              validate={[
                required({ msg: 'Cover level is required' }),
                format({ with: /^[a-z_]*$/, message: 'Cover level must be in snake case. Make sure all words are in lowercase and each space is replaced with an underscore.' })
              ]}
            />
          </FormSection>
        </Col>
        <Col sm={2}>
          <div className={'scheme-group-details-field--remove'}>
            <FaTrash onClick={remove}/>
          </div>
        </Col>
      </Row>
    </Box>
  );
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import InternalContainer from '../../layout/containers/Internal';
import Brochure from '../components/Brochure/Brochure';
import Underwriting from '../components/Underwriting/Underwriting';
import InternalTabs from '../../layout/components/InternalTabs/InternalTabs';
import SignpostList from '../components/Signpost/SignpostList';

const TAB_NAME = 'contentTabs';

class ContentListContainer extends Component {
  render() {
    return (
      <InternalContainer title="Content Management">
        <InternalTabs
          id={TAB_NAME}
          tabs={[
            {
              permissions: ['content_management.view'],
              title: 'Emails',
              content: <Underwriting filterBy="email" {...this.props} />,
            },
            {
              permissions: ['content_management.view'],
              title: 'Announcements',
              content: <Underwriting filterBy="announcement" {...this.props} />,
            },
            {
              permissions: ['content_management.view'],
              title: 'Endorsements',
              content: <Underwriting filterBy="endorsement" {...this.props} />,
            },
            {
              permissions: ['content_management.view'],
              title: 'Brochure',
              content: <Brochure {...this.props} />,
            },
            {
              permissions: ['content_management.view'],
              title: 'Signposts',
              content: <SignpostList/>,
            },
          ]}
        />
      </InternalContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    content: state.content,
  };
}

export default connect(mapStateToProps)(ContentListContainer);

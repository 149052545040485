import { map, get, isEmpty, set } from 'lodash';

const validate = (values) => {
  const errors = {};

  // Check to see if more than 3 fields are filled in
  if (map(get(values, 'metadata', {}), isEmpty).length < 1) {
    set(errors, 'metadata.name', '');
  }

  errors._error = errors.metadata;
  return errors;
};

export default validate;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, DatePickerDob } from '../../../../common/components';
import { Form, Col, Row, Checkbox } from 'react-bootstrap';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import './styles.scss';
import { FaExclamationTriangle, FaCheckCircle, FaTrash } from 'react-icons/fa';
import { reindexData, fetchJobStatus } from '../../redux/toolActions';
import moment from 'moment';

class ReindexDataTab extends Component {
  static propTypes = {
    isReindexing: PropTypes.bool.isReindexing,
  };

  constructor(props) {
    super(props);
    this.state = {
      indexType: '',
      dateSelection: 'dateRange',
      fromDate: '',
      toDate: '',
      timePeriod: '',
      policyNumbers: [],
      currentPolicyInput: '',
      policyError: '',
      jobs: [],
    };
  }

  handleIndexChange = (event) => {
    this.setState({
      indexType: event.target.value,
      dateSelection: 'dateRange',
      fromDate: '',
      toDate: '',
      timePeriod: '',
      policyNumbers: [],
      currentPolicyInput: '',
      policyError: '',
    });
  };

  handleDateSelectionChange = (event) => {
    this.setState({ dateSelection: event.target.value });
  };

  handlePolicyInputChange = (event) => {
    let input = event.target.value;
    if (!/^[a-zA-Z0-9,\s/]*$/.test(input)) return;
    this.setState({ currentPolicyInput: input });
  };

  handleAddPolicy = () => {
    const { currentPolicyInput } = this.state;
    this.addPolicyNumber(currentPolicyInput);
  };

  handleClearPolicyInput = () => {
    this.setState({ currentPolicyInput: '' });
  };

  addPolicyNumber = (policy) => {
    if (!policy) return;
    const { policyNumbers } = this.state;
    if (policyNumbers.includes(policy)) {
      this.setState({ policyError: 'You have already added this policy number' });
      return;
    }
    if (policyNumbers.length >= 5) {
      this.setState({ policyError: 'You have entered the maximum 5 policies' });
      return;
    }
    this.setState((prevState) => ({
      policyNumbers: [...prevState.policyNumbers, policy],
      currentPolicyInput: '',
      policyError: '',
    }));
  };

  removePolicyNumber = (policy) => {
    this.setState((prevState) => ({
      policyNumbers: prevState.policyNumbers.filter((p) => p !== policy),
      policyError: '',
    }));
  };

  clearPolicyInput = () => {
    this.setState({ currentPolicyInput: '' });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { indexType, fromDate, toDate, timePeriod, policyNumbers } = this.state;
    const basePayload = {
      indexType,
      fromDate: this.state.dateSelection === 'dateRange' ? fromDate : null,
      toDate: this.state.dateSelection === 'dateRange' ? toDate : null,
      timePeriod: this.state.dateSelection === 'timePeriod' ? timePeriod : null,
    };
    if (policyNumbers.length > 0) {
      for (let policyNumber of policyNumbers) {
        const payload = { ...basePayload, policyNumber };
        const response = await this.props.dispatch(reindexData(payload));
        if (response && response.id) {
          this.setState((prevState) => ({
            jobs: [
              ...prevState.jobs,
              { id: response.id, command: response.command, status: 'pending' },
            ],
          }));
          this.pollJobStatus(response.id);
          this.resetState();
        } else {
          this.setState({ policyError: response.error });
        }
      }
    } else {
      const payload = { ...basePayload, policyNumber: null };
      const response = await this.props.dispatch(reindexData(payload));
      if (response && response.id) {
        this.setState((prevState) => ({
          jobs: [
            ...prevState.jobs,
            { id: response.id, command: response.command, status: 'pending' },
          ],
        }));
        this.pollJobStatus(response.id);
        this.resetState();
      } else {
        this.setState({ policyError: response.error });
      }
    }
  };

  resetState = () => {
    this.setState({
      indexType: '',
      dateSelection: 'dateRange',
      fromDate: '',
      toDate: '',
      timePeriod: '',
      policyNumbers: [],
      currentPolicyInput: '',
      policyError: '',
    });
  }

  pollJobStatus = (jobId) => {
    const interval = setInterval(async () => {
      const status = await this.props.dispatch(fetchJobStatus(jobId));
      this.setState((prevState) => {
        const updatedJobs = prevState.jobs.map((job) =>
          job.id === jobId ? { ...job, status } : job
        );
        return { jobs: updatedJobs };
      });
      if (status === 'completed') clearInterval(interval);
    }, 5000);
  };

  render() {
    const { indexType, dateSelection, fromDate, toDate, timePeriod, policyNumbers, currentPolicyInput, policyError, jobs } = this.state;
    const indexes = [
      { label: 'Quotes', value: 'quote' },
      { label: 'Accounts', value: 'account' },
      { label: 'Policies', value: 'policy' },
    ];
    const timePeriods = ['30m', '1hr', '2hr', '3hr', '4hr', '5hr', '6hr'];

    return (
      <>
        <Box>
          <h2 className="resource-name">Reindex Data</h2>
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col sm={12} style={{ marginBottom: '20px' }}>To begin reindexing, please select an index</Col>
              <Col sm={6} style={{ padding: 0 }}>
                <Col sm={2}>
                  <label>Index</label>
                </Col>
                <Col sm={10} style={{ padding: 0 }}>
                  <select className="form-control" value={indexType} onChange={this.handleIndexChange}>
                    <option value="">Select an index</option>
                    {indexes.map((index) => (
                      <option key={index.value} value={index.value}>{index.label}</option>
                    ))}
                  </select>
                </Col>
              </Col>
            </Row>
            {indexType && (
              <>
                <Row style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                  <Col sm={12} style={{ marginBottom: '20px' }}>Select the date option</Col>
                </Row>
                <Row style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                  <Col sm={12}>
                    <div style={{ display: 'flex', gap: '40px', alignItems: 'center' }}>
                      <div className="form-check form-check-inline" style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}>
                        <div style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%',
                        }}>
                          <div>
                            <label style={{ marginBottom: '0px' }}>Date Range</label>
                          </div>
                          <Checkbox
                            type="radio"
                            value="dateRange"
                            checked={dateSelection === 'dateRange'}
                            onChange={this.handleDateSelectionChange}
                          />
                        </div>
                        <p style={{ fontSize: '12px', marginTop: '5px' }}>Select a to and
                          from date</p>
                      </div>

                      <div className="form-check form-check-inline" style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}>
                        <div style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%',
                        }}>
                          <div>
                            <label style={{ marginBottom: '0px' }}>Time Period</label>
                          </div>
                          <Checkbox
                            type="radio"
                            value="timePeriod"
                            checked={dateSelection === 'timePeriod'}
                            onChange={this.handleDateSelectionChange}
                          />
                        </div>
                        <p style={{ fontSize: '12px', marginTop: '5px' }}>Select a
                          specific time period</p>
                      </div>
                      {indexType === 'policy' && (
                        <div className="form-check form-check-inline" style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}>
                          <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                          }}>
                            <div>
                              <label style={{ marginBottom: '0px' }}>Individual
                                Policies</label>
                            </div>
                            <Checkbox
                              type="radio"
                              value="individualPolicies"
                              checked={dateSelection === 'individualPolicies'}
                              onChange={this.handleDateSelectionChange}
                            />
                          </div>
                          <p style={{ fontSize: '12px', marginTop: '5px' }}>Enter
                            individual policy numbers to reindex</p>
                        </div>
                      )}
                    </div>
                  </Col>

                </Row>
                {dateSelection === 'dateRange' && (
                  <Row style={{ marginTop: '10px' }}>
                    <Col sm={6}>
                      <Col sm={6} style={{ padding: 0 }}>
                        <label>From</label>
                        <DatePickerDob
                          minDate={moment().endOf('day').subtract(1, 'month').toDate().toISOString()}
                          maxDate={moment().endOf('day').toDate().toISOString()}
                          input={{
                            value: fromDate,
                            onChange: (date) => this.setState({ fromDate: date }),
                          }}
                        />
                      </Col>
                      <Col sm={6} style={{ padding: 0 }}>
                        <label>To</label>
                        <DatePickerDob
                          minDate={moment().endOf('day').subtract(1, 'month').toDate().toISOString()}
                          maxDate={moment().endOf('day').toDate().toISOString()}
                          input={{
                            value: toDate,
                            onChange: (date) => this.setState({ toDate: date }),
                          }}
                        />
                      </Col>
                    </Col>
                  </Row>
                )}

                {dateSelection === 'timePeriod' && (
                  <Row style={{ marginTop: '10px' }}>
                    <Col sm={6}>
                      <label>Select Time Period</label>
                      <select
                        className="form-control"
                        value={timePeriod}
                        onChange={(e) => this.setState({ timePeriod: e.target.value })}
                      >
                        <option value="">Select a time period</option>
                        {timePeriods.map((period) => (
                          <option key={period} value={period}>{period}</option>
                        ))}
                      </select>
                    </Col>
                  </Row>
                )}
                {indexType === 'policy' && dateSelection === 'individualPolicies' &&(
                  <Row style={{ marginTop: '15px' }}>
                    <Col sm={6}>
                      <label>Policy Numbers (Add up to 5)</label>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          type="text"
                          className="form-control"
                          value={currentPolicyInput}
                          onChange={this.handlePolicyInputChange}
                          placeholder="Enter policy numbers..."
                          disabled={policyNumbers.length >= 5}
                        />
                        <div style={{ marginLeft: '15px' }}>
                          <Button
                            variant="primary"
                            handleClick={this.handleAddPolicy}
                            disabled={!this.state.currentPolicyInput || this.state.policyNumbers.length >= 5}
                            type="button"
                          >
                            Add
                          </Button>
                        </div>
                        <div style={{ marginLeft: '15px' }}>
                          <Button
                            variant="secondary"
                            handleClick={this.handleClearPolicyInput}
                            type="button"
                          >
                            Clear
                          </Button>
                        </div>
                      </div>
                      {policyError && (
                        <small style={{
                          color: policyNumbers.length >= 5 ? 'orange' : 'red',
                          display: 'block',
                          marginTop: '5px',
                        }}>
                          {policyNumbers.length >= 5 ? (
                            <>
                              <FaExclamationTriangle
                                style={{ marginRight: '5px', color: 'orange' }} />
                              {policyError}
                            </>
                          ) : (
                            policyError
                          )}
                        </small>
                      )}
                      <div className="policy-tags" style={{ marginTop: '10px' }}>
                        {policyNumbers.map((policy, index) => (
                          <div key={index} className="policy-tag-container">
      <span className="policy-tag">
        {policy}
      </span>
                            <FaTrash onClick={() => this.removePolicyNumber(policy)}
                                     className="trash-icon" />
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col sm={12}>
                    <InternalButtonBar
                      buttons={[
                        {
                          button: (
                            <Button className="pull-right" type="submit" label="Reindex"
                                    key="a" isLoading={this.props.isReindexing} bsStyle="primary" />
                          ),
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Form>
        </Box>
        {/* Jobs Section */}
        <Box className="jobs-container">
          <h3>Jobs</h3>
          {jobs.length === 0 ? (
            <p>No jobs submitted yet.</p>
          ) : (
            <ul>
              {jobs.map((job) => (
                <li key={job.id} className="job-item">
                  <strong>Job ID:</strong> {job.id} <br />
                  <strong>Command:</strong> {job.command} <br />
                  <strong>Status:</strong> {job.status}
                  {job.status === 'completed' && <FaCheckCircle className="completed-icon" />}
                </li>
              ))}
            </ul>
          )}
        </Box>
      </>
    );
  }
}

export default ReindexDataTab;

import React, { useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import DynamicDocumentListItem from './components/DynamicDocumentListItem';
import './DynamicDocumentList.scss';

const DynamicDocumentList = ({ dynamicDocuments, productId, onUpdate }) => {
  const [activeItem, setActiveItem] = useState(null);

  // creates the versional-document-list
  const dynamicDocumentTypes = dynamicDocuments.reduce((carry, document) => {
    const documentName = document.attributes.name;
    if (
      documentName.match(
        /(Broker Application Form|Toba|Broker Statement|Global Document Content)/,
      )
    ) {
      return carry;
    }

    if (carry[documentName]) {
      carry[documentName].push(document);
      return carry;
    }
    carry[documentName] = [document];
    return carry;
  }, {});

  const handleSelectItem = (index) => {
    setActiveItem(index);
  };

  return (
    <Row>
      <Col sm={12}>
        {Object.keys(dynamicDocumentTypes).map((docType) => {
          const documents = dynamicDocumentTypes[docType];

          return (
            <div className="animated fadeIn" key={docType}>
              <DynamicDocumentListItem
                index={docType}
                insurerList={[]}
                documents={documents}
                activeItem={activeItem}
                productId={productId}
                onUpdate={onUpdate}
                onSelectItem={handleSelectItem}
              />
            </div>
          );
        })}
      </Col>
    </Row>
  );
};

export default DynamicDocumentList;

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { Form, Modal } from 'react-bootstrap';
import { Button, FormGroupRow } from '../../../../common/components';
import AsyncSelectMenu from '../../../../common/components/SelectMenu/AsyncSelectMenu';
import { required } from 'redux-form-validators';

const AssignHandler = ({
  input,
  handleSubmit,
  handlerType,
  isViewOnly,
  handlerPath
}) => {
  const [showModal, setShowModal] = useState(false);
  const update = (values) => {
    input.onChange(values?.[handlerPath]);
    setShowModal(false);
  };

  const handleOpen = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  if (isViewOnly) {
    return null;
  }

  return (
    <div>
      <Button
        type="button"
        label={`Assign ${handlerType} Handler`}
        handleClick={handleOpen}
      />

      <Modal
        show={showModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Assign {handlerType} Handler</Modal.Title>
        </Modal.Header>

        <Form>
          <Modal.Body>
            <FormGroupRow label={handlerType} labelSize={3}>
              <Field
                name={handlerPath}
                url={'/organisations?'}
                filters={[{ type: 'type', match: `${handlerType} Handler` }]}
                optionValuePath={''}
                component={AsyncSelectMenu}
                isObject
                required={required({ msg: `A ${handlerType} handler is required` })}
              />
            </FormGroupRow>
          </Modal.Body>

          <Modal.Footer>
            <Button
              type="submit"
              bsStyle="primary"
              handleClick={handleSubmit(update)}
              label="Assign"
            />
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

const FORM_NAME = 'AssignClaimsHandlerToScheme';
export default compose(
  connect(
    null,
    null
  ),
  reduxForm({
    form: FORM_NAME,
  }),
)(AssignHandler);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import './BrokerApplicationForm.scss';
import { createBrokerApplication } from '../redux/brokerApplicationActions';
import BrokersApNewForm from '../components/BrokerApNewForm/BrokerApNewForm';

/***********************************
 Container for the New Broker Application Form
 ************************************/

const resource = {
  data: {
    type: 'broker-application',
    attributes: {
      branch_name: '',
      organisation: {
        data: {},
      },
      metadata: {},
    },
    relationships: {},
  },
};

class BrokersApNewContainer extends Component {
  handleSubmit(resource) {
    return this.props.dispatch(createBrokerApplication(resource));
  }

  render() {
    return (
      <BrokersApNewForm
        resource={resource}
        {...this.props}
        onSubmit={this.handleSubmit.bind(this)}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    brokerApplication: state.brokerApplication,
  };
}

export default connect(mapStateToProps)(BrokersApNewContainer);

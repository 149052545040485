import React, { Component } from 'react';
import '../../ReduxForm/styles.scss';
import { Row, Col } from 'react-bootstrap';

export default class OptionsCheckboxes extends Component {
  render() {
    const {
      infoButton,
      disabledMta,
      disableWinter,
      options,
      inline,
      price,
      name,
      value,
      readOnly
    } = this.props;

    const winterSports = 'winter_sports';

    return (

        <Row>
          <Col sm={12} className="options-container">
            {options.map((option, key) => {
              return (
                <div
                  className={'options-container' + (inline ? ' inline' : '')}
                  key={key}
                >
                  <div className="options-label-container">
                    <div
                      className="radio-label"
                    >
                      {infoButton}
                      {option.label}
                      {price}
                    </div>
                    <div
                      className={
                        'form-radio' +
                        (disabledMta
                          ? ' disabledMta'
                          : disableWinter && name === winterSports
                          ? ' disabledMta'
                          : '')
                      }
                    >
                      <input
                        type="checkbox"
                        disabled={
                          disabledMta || (disableWinter && name === winterSports)
                        }
                        id={name + option.value}
                        value={option.value}
                        readOnly={readOnly}
                        checked={value.toString() === option.value.toString()}
                      />
                      <label
                        htmlFor={name + option.value}
                        className={
                          disabledMta
                            ? 'disabledMta'
                            : disableWinter && name === winterSports
                            ? 'disabledMta'
                            : ''
                        }
                        style={disabledMta ? { cursor: 'not-allowed' } : {}}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </Col>
        </Row>

    );
  }
}

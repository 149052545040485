import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, DataTable } from '../../../../common/components';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Row, Col, FormControl, ControlLabel } from 'react-bootstrap';
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions';
import { refreshData } from '../../../../common/components/DataTable/redux/dataTableActions';
import { openModal } from '../../../../common/components/Modal/ModalRedux';
import { LedgerEntriesModal } from '../LedgerEntriesModal';
import selectTheme, { styles } from '../../../../helpers/selectTheme';
import Select from 'react-select';

export default class AccountBatchList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      batch: {},
    };
  }

  formatDate(cell) {
    return (
      <span>
        <FormattedDate value={new Date(cell)} /> <FormattedTime value={new Date(cell)} />
      </span>
    );
  }

  refreshList() {
    this.props.dispatch(refreshData('batches'));
  }

  handleSearchUpdate(event) {
    this.props.dispatch(applyFilter('batches', 'filter[reference]', event.target.value));
  }

  handleBatchTypeUpdate(option) {
    this.props.dispatch(applyFilter('batches', 'filter[type]', option.value));
  }

  handleRowClick(row) {
    if (row.batch_size === 0) {
      return;
    }

    this.setState({ batch: row });
    this.props.dispatch(openModal(LedgerEntriesModal.MODAL_IDENTIFIER));
  }

  render() {
    return (
      <Box>
        <h2>Batches</h2>
        <Row>
          <Col sm={4}>
            <ControlLabel className="filter-label">Reference</ControlLabel>
            <FormControl onChange={::this.handleSearchUpdate} />
          </Col>
          <Col sm={2}>
            <ControlLabel className="filter-label">Type</ControlLabel>
            <Select
              theme={(theme) => selectTheme(theme)}
              styles={styles}
              onChange={(option) => this.handleBatchTypeUpdate(option)}
              options={[
                { label: 'All', value: 'all' },
                { label: 'Matched', value: 'matched' },
                { label: 'Paid', value: 'paid' },
              ]}
              defaultValue={{ label: 'All', value: 'all' }}
            />
          </Col>
        </Row>

        <Row className="data-table-row">
          <Col xs={12}>
            <DataTable
              source="/accounts/batches"
              name="batches"
              showTotal
              onRowSelect={this.handleRowClick.bind(this)}
            >
              <TableHeaderColumn dataField="id" isKey hidden />

              <TableHeaderColumn dataField="batch_type" width={'100px'} dataSort>
                Type
              </TableHeaderColumn>

              <TableHeaderColumn dataField="reference" width={'100'} dataSort>
                Payment Reference
              </TableHeaderColumn>

              <TableHeaderColumn dataField="batch_size" width={'100px'}>
                No. Entries
              </TableHeaderColumn>

              <TableHeaderColumn dataField="user_name" width={'100px'}>
                User
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="date"
                dataFormat={this.formatDate}
                width={'100px'}
                dataSort
              >
                Batch Date
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="created_at"
                dataFormat={this.formatDate}
                width={'100px'}
                dataSort
              >
                Created At
              </TableHeaderColumn>
            </DataTable>
            <LedgerEntriesModal
              dispatch={this.props.dispatch}
              batch={this.state.batch}
              callback={::this.refreshList}
            />
          </Col>
        </Row>
      </Box>
    );
  }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, Field, FormSection } from 'redux-form';
import { get } from 'lodash';
import { Button, ButtonBar, InfoButton } from '../../../../../../common/components';
import { Row, Col, ControlLabel } from 'react-bootstrap';
import {
  CheckboxGroup,
} from '../../../../../../common/components/ReduxFormField';
import Markdown from 'react-markdown';
const META = 'data.attributes.metadata';
export const COL_WIDTHS = {
  labels: { sm: 2, md: 2, lg: 3 },
  fields: { sm: 10, md: 10, lg: 8 },
};

export class DeclarationTab extends Component {

  renderTermsList(productTerms, definitions) {
    if (Object.keys(productTerms).length === 0) {
      return;
    }

    const productTermsValues = Object.values(productTerms);
    const sortedProductTerms = productTermsValues.sort((a, b) => a.questionOrder - b.questionOrder);

    return sortedProductTerms.map((productTerm) => {
      const hasDefinition = productTerm.hasDefinition;
      const isQuestion = productTerm.question;
      const contentCol = isQuestion ? 10 : 12;

      return (
        <div className={'declaration-tab'} key={productTerm.key}>
          <Row>
            <Col sm={contentCol}>
              <div className="declaration-container">
                <Markdown
                  className="declaration-content"
                  source={productTerm.content}
                />
                {hasDefinition && (
                  <div className="declaration-definition"><InfoButton content={definitions[productTerm.key]} /></div>
                )}
              </div>
            </Col>
            {isQuestion && (
              <Col sm={2}>
                <div className="declaration-question">
                  <Field
                    name={productTerm.key}
                    component={CheckboxGroup}
                    inline
                    options={[{ value: true }]}
                  >
                    <div />
                  </Field>
                </div>
              </Col>
            )}
          </Row>
        </div>
      );
    });
  }

  render() {
    const { currentProduct, openSpecificTab, nextTab } = this.props;
    const productTerms = get(currentProduct, `${META}.product_terms`);
    const definitions = get(currentProduct, `${META}.definitions`);

    return (
      <div>
        <FormSection name={META}>
          <Row>
            <Col sm={12} componentClass={ControlLabel}>
              <p>I declare (please tick to confirm):</p>
            </Col>
          </Row>
          {this.renderTermsList(productTerms, definitions)}
        </FormSection>
        <hr/>
        <Row>
          <Col sm={12}>
            <ButtonBar>
              <Button
                label="Back"
                id="back"
                className="btn-action-back pull-left"
                bsStyle="primary"
                handleClick={() => openSpecificTab(nextTab-2)}
              />
              <Button
                label="Next"
                id="next"
                className="pull-right btn-action-blue"
                bsStyle="primary"
                handleClick={() => openSpecificTab(nextTab)}
              />
            </ButtonBar>
          </Col>
        </Row>
      </div>
    );
  }
}

const FORM_NAME = 'LeisureTravelForm';
const form = reduxForm({ form: FORM_NAME })(DeclarationTab);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state) => {
  const values = selector(
    state,
    'data.id',
    'data.current_tab',
    'data.attributes.metadata',
  );

  return {
    auth: state.auth,
    form: 'LeisureTravelForm',
    formValues: values,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    product: state.product,
  };
};

export default connect(mapStateToProps)(form);

import React from 'react';
import { compose } from 'redux';
import { Col, FormControl, Modal, Row } from 'react-bootstrap';
import connect from 'react-redux/es/connect/connect';
import { Button, DatePicker } from '../../../../common/components';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import {
  actions as schemeActions
} from '../../../schemeManagement/redux/schemeManagementActions';
import TextField from '../../../../common/components/ReduxFormField/TextField';
import SelectField from '../../../../common/components/ReduxFormField/SelectField';
import UploadModalComponent from '../../../../common/components/Upload/UploadModal';
import SchemeCheckboxTree from '../SchemeCheckboxTree/SchemeCheckboxTree';
import DocumentFile from '../DocumentFile/DocumentFile';
import { required } from 'redux-form-validators';
import { openModal } from '../../../../common/components/Modal/ModalRedux';
import { clearFiles } from '../../../../common/components/Upload/UploadRedux';
import { formatISO } from 'date-fns';
import AsyncSelectMenu from '../../../../common/components/SelectMenu/AsyncSelectMenu';
import './NewDocumentModal.scss';

const isRequired = required({ message: 'Required' });

const UPLOAD_MODAL = 'UploadNewDocumentModal';

const NewDocumentFormRow = ({ label, content, noGutter }) => {
  return (
    <Row className="document-modal-row">
      <Col sm={3} xs={5}>
        <strong>{label}:</strong>
      </Col>
      <Col sm={9} xs={7} className={noGutter ? 'no-gutter' : ''}>
        {content}
      </Col>
    </Row>
  );
};

const NewDocumentFields = () => {
  return (
    <>
      <NewDocumentFormRow
        label={'Display Name'}
        content={
          <Field
            name={'data.attributes.display_name'}
            component={TextField}
            validate={[isRequired]}
          />
        }
      />
      <NewDocumentFormRow
        label={'Internal Name'}
        content={
          <Field
            name={'data.attributes.name'}
            component={TextField}
            validate={[isRequired]}
          />
        }
      />
      <NewDocumentFormRow
        label={'Short Name'}
        content={
          <Field
            name={'data.attributes.short_name'}
            component={TextField}
            validate={[isRequired]}
          />
        }
      />
    </>
  );
};

const NewDocumentForm = ({
  schemes,
  onAddDocument,
  clearFiles,
  openModal,
  dispatch,
  change,
  isSuperseding,
  handleSubmit,
  submitting,
  formValues,
  documentTypes,
  isAncillarySchemeDocument,
  files,
}) => {


  const schemesPath = isAncillarySchemeDocument
    ? 'data.relationships.ancillary_schemes.data'
    : 'data.relationships.schemes.data';

  const resetState = () => {
    clearFiles();
  };

  const handleUpload = () => {
    openModal(UPLOAD_MODAL);
    resetState();
  };

  const handleUploadComplete = () => {
    if (files[0]) {
      dispatch(change('data.relationships.file.data', files[0]));
      dispatch(change('data.attributes.file_name', files[0].attributes.name));
      dispatch(change('data.attributes.file_url', files[0].attributes.url));
    }
  };

  // const handleAddDocument = (formValues) => {
  //   return Promise.resolve(onAddDocument(formValues))
  //     .then(() => {
  //       destroy(FORM_NAME);
  //       closeModal();
  //     });
  // };


  return (
    <>
      <Form onSubmit={handleSubmit(onAddDocument)}>
        <Modal.Body>
          <div className="new-document-modal-form">
            <div className="alert-warning">
              <p className="doc-warning">
                <strong>Please Note:</strong>
                <br/>
                Use "New Version" on a document if this document is a replacement of a
                current active document.
              </p>
            </div>
            {!isAncillarySchemeDocument && (
              <NewDocumentFormRow
                label={'Type'}
                content={
                  <Field
                    name={'data.relationships.document_type.data.id'}
                    component={SelectField}
                    options={documentTypes?.map((item) => {
                      return { label: item.attributes.name, value: item.id };
                    })}
                    validate={[isRequired]}
                  />
                }
              />
            )}

            {isSuperseding && (
              <NewDocumentFormRow
                label={'Channel'}
                content={
                  <Field
                    component={AsyncSelectMenu}
                    url={'/channels?'}
                    name="data.relationships.channel.data"
                    validate={[isRequired]}
                  />
                }
              />
            )}
            <NewDocumentFields/>

            <NewDocumentFormRow
              label={'File'}
              content={
                <FormControl.Static>
                  <Button
                    bsSize="xs"
                    type="button"
                    bsStyle="primary"
                    className="pad-right pull-left btn-xs"
                    handleClick={handleUpload}
                  >
                    <i className="fa fa-file"/> upload document
                  </Button>

                  <Field
                    name={'data.relationships.file.data.id'}
                    component={DocumentFile}
                    url={formValues?.data.relationships.file?.data?.attributes?.url}
                    displayName={formValues?.data.attributes.file_name}
                    validate={[isRequired]}
                  />
                </FormControl.Static>
              }
            />
            {!isSuperseding && (
              <NewDocumentFormRow
                label={'Schemes'}
                content={
                  <ul className="schemes-checkbox-container">
                    <Field
                      name={schemesPath}
                      nodes={schemes}
                      component={SchemeCheckboxTree}
                      validate={[isRequired]}
                    />
                  </ul>
                }
              />
            )}
            <NewDocumentFormRow
              label={'Active date'}
              content={
                <Field
                  showTime
                  dateFormat={'dd/MM/yyyy HH:mm'}
                  name="data.attributes.from_date"
                  label="Start date"
                  timeFormat="HH:mm"
                  component={DatePicker}
                  shouldCloseOnSelect={false}
                  validate={[isRequired]}
                />
              }
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" className="btn-blue" isLoading={submitting}>
            Save
          </Button>
        </Modal.Footer>
      </Form>
      <UploadModalComponent
        modalIdentifier={UPLOAD_MODAL}
        maxFiles={1}
        accept="application/pdf"
        onComplete={handleUploadComplete}
      />
    </>
  );
};

const FORM_NAME = 'NewDocumentForm';
export default compose(
  connect(
    (state, props) => {
      return {
        formValues: getFormValues(FORM_NAME)(state),
        files: state.upload.files,
        initialValues: {
          data: {
            ...props.document?.data,
            attributes: {
              ...props.document?.data?.attributes,
              file_path: null,
              from_date: formatISO(new Date()),
            },

            relationships: {
              ...props.document?.data?.relationships,
              document_type: {
                data: {
                  id: props.documentTypeId ?? null,
                },
              },

              file: {
                data: {
                  id: null,
                },
              },
            },
          },
        },
        documentTypes: props.documentTypesList?.filter((documentType) =>
          [
            'Fact Find',
            'Information',
            'COVID-19',
            'Insurance Product Information Document',
            'Policy Summary',
            'Policy Wording',
            'Statement of Fact',
            'Terms of Business Customer Agreement',
            'Virtual Medical Care',
          ].includes(documentType.attributes?.name),
        ),
      };
    },
    {
      openModal,
      clearFiles,
      getAncillarySchemes: schemeActions.getAncillarySchemes,
    },
  ),
  reduxForm({
    form: FORM_NAME,
  }),
)(NewDocumentForm);

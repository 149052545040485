import React, { Component } from 'react';
import { Field } from 'redux-form';
import { CheckBoxLabelRight } from '../../../../common/components/ReduxFormField';
import { Col, Row } from 'react-bootstrap';
import './styles.scss';

export default class Communications extends Component {
  renderCheckBoxes = () => {
    const { name } = this.props;
    return (
      <Row>
        <Col md={2}>
          <Field
            name={name + '.post'}
            options={[{ label: 'Post', value: true }]}
            component={CheckBoxLabelRight}
          />
        </Col>
        <Col md={2}>
          <Field
            name={name + '.email'}
            options={[{ label: 'Email', value: true }]}
            component={CheckBoxLabelRight}
          />
        </Col>
        {/*<Col md={2}>*/}
          {/*<Field*/}
            {/*name={name + '.sms'}*/}
            {/*options={[{ label: 'SMS', value: true }]}*/}
            {/*component={CheckBoxLabelRight}*/}
          {/*/>*/}
        {/*</Col>*/}
      </Row>
    );
  };

  render() {
    return (
      <div>
        <p className="comm-title">How would you like to receive your details of cover?</p>
        {this.renderCheckBoxes()}
      </div>
    );
  }
}

import React, { useMemo } from 'react';
import './StatsSectionConversionLabel.scss';


const DEFAULT_STATS = {
  issued: 0,
  count: 0,

}
const getStats = (stats, range) => {
  if (range === 'Today') {
    return stats?.quotes?.day ?? DEFAULT_STATS;
  }
  if (range === 'This week') {
    return stats?.quotes?.week ?? DEFAULT_STATS;
  }
  if (range === 'This month') {
    return stats?.quotes?.month ?? DEFAULT_STATS;
  }
  if (range === 'Yesterday') {
    return stats?.quotes?.yesterday ?? DEFAULT_STATS;
  }
  return stats?.quotes?.day ?? DEFAULT_STATS
}
const getConversion = (stats) => {
  if(!stats.count) {
    return 0;
  }
  return Math.round((stats.issued / stats.count) * 100);
}
export const StatsSectionConversion = ({ stats, range }) => {

  const displayStats = useMemo(() => getStats(stats, range), [stats, range]);



  const conversion = getConversion(displayStats);

  return (
    <div className="stats-section-Conversion-Label">
      <div>
          <span className="conversion-label">
            Conversion rate:
            <span>{conversion} %</span>
          </span>
      </div>
    </div>
  );

};

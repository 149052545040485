import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { AddressFields, TextField } from '../../../../common/components/ReduxFormField';
import PostcodeList from '../../../../common/components/Postcode/PostcodeList';
import {
  Alert,
  Box,
  Button,
  HorizontalFormControl,
  NameFields,
  DatePickerDob,
} from '../../../../common/components';
import Marketing from '../../../product/components/Marketing/Marketing';
import { Form, Col, Row, ControlLabel, FormGroup } from 'react-bootstrap';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import { clearSelection as clearPostcodeSelection } from '../../../../common/components/Postcode/redux/PostcodeActions';

const FORM_NAME = 'customerForm';
const COL_WIDTHS = {
  labels: { sm: 2, md: 2, lg: 3 },
  fields: { sm: 10, md: 10, lg: 9 },
};

class CustomerForm extends Component {
  componentWillUnmount() {
    this.props.dispatch(clearPostcodeSelection());
  }

  componentDidMount() {
    const { searchResult, change, dispatch } = this.props;

    const dateDob = get(searchResult, 'metadata.dob');
    const formatDate = dateDob ? this.formatDate(dateDob) : '';
    // Fill out based on Search performed in Customer List
    if (searchResult) {
      dispatch(change('data.attributes.first_name', get(searchResult, 'metadata.name')));
      dispatch(
        change('data.attributes.last_name', get(searchResult, 'metadata.surname')),
      );
      dispatch(change('data.attributes.email', get(searchResult, 'metadata.email')));
      dispatch(change('data.attributes.dob', formatDate));
      dispatch(
        change(
          'data.attributes.address.postcode',
          get(searchResult, 'metadata.postcode'),
        ),
      );
    }
  }

  formatDate(date) {
    if (!date) {
      return '';
    }
    const formattedDate = moment(date, 'DD/MM/YYYY').utcOffset(0, true);
    return formattedDate !== 'Invalid Date' ? formattedDate.format() : '';
  }

  render() {
    const { formValues, handleSubmit, submitting } = this.props;
    const resource = this.props.resource ? this.props.resource.data.attributes : {};
    const renderName = `${resource.title} ${resource.first_name} ${resource.last_name}`;
    return (
      <Box>
        <h2 className="resource-name">
          {this.props.resource ? renderName : 'New customer'}
        </h2>

        <Form onSubmit={handleSubmit} autoComplete="off">
          <Row>
            <Col xs={12}>
              <Alert />
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <NameFields
                baseName="data.attributes"
                label="Name"
                labelSizes={{
                  sm: COL_WIDTHS.labels.sm,
                  md: COL_WIDTHS.labels.md,
                  lg: COL_WIDTHS.labels.lg,
                }}
                fieldSizes={{
                  sm: COL_WIDTHS.fields.sm,
                  md: COL_WIDTHS.fields.md,
                  lg: COL_WIDTHS.fields.lg,
                }}
                fieldComponent={Field}
              />
              <FormGroup>
                <Col
                  sm={COL_WIDTHS.labels.sm}
                  md={COL_WIDTHS.labels.md}
                  lg={COL_WIDTHS.labels.lg}
                  componentClass={ControlLabel}
                >
                  Date of Birth
                </Col>
                <Col
                  sm={COL_WIDTHS.fields.sm}
                  md={COL_WIDTHS.fields.md}
                  lg={COL_WIDTHS.fields.lg}
                >
                  <Field name="data.attributes.dob" showYear component={DatePickerDob} />
                </Col>
              </FormGroup>

              <FormGroup>
                <Col
                  sm={COL_WIDTHS.labels.sm}
                  md={COL_WIDTHS.labels.md}
                  lg={COL_WIDTHS.labels.lg}
                  componentClass={ControlLabel}
                >
                  Email
                </Col>
                <Col
                  sm={COL_WIDTHS.fields.sm}
                  md={COL_WIDTHS.fields.md}
                  lg={COL_WIDTHS.fields.lg}
                >
                  <Field name="data.attributes.email" component={HorizontalFormControl} />
                </Col>
              </FormGroup>

              <FormGroup>
                <Col
                  sm={COL_WIDTHS.labels.sm}
                  md={COL_WIDTHS.labels.md}
                  lg={COL_WIDTHS.labels.lg}
                  componentClass={ControlLabel}
                >
                  Phone 1
                </Col>
                <Col
                  sm={COL_WIDTHS.fields.sm}
                  md={COL_WIDTHS.fields.md}
                  lg={COL_WIDTHS.fields.lg}
                >
                  <Field
                    name="data.attributes.phone1"
                    component={HorizontalFormControl}
                  />
                </Col>
              </FormGroup>

              <FormGroup>
                <Col
                  sm={COL_WIDTHS.labels.sm}
                  md={COL_WIDTHS.labels.md}
                  lg={COL_WIDTHS.labels.lg}
                  componentClass={ControlLabel}
                >
                  Phone 2
                </Col>
                <Col
                  sm={COL_WIDTHS.fields.sm}
                  md={COL_WIDTHS.fields.md}
                  lg={COL_WIDTHS.fields.lg}
                >
                  <Field
                    name="data.attributes.phone2"
                    component={HorizontalFormControl}
                  />
                </Col>
              </FormGroup>
            </Col>

            <Col lg={6}>
              <PostcodeList
                formName={FORM_NAME}
                name="data.attributes.address.postcode"
                label="Postcode"
                context="customer"
                labelSizes={{
                  sm: COL_WIDTHS.labels.sm,
                  md: COL_WIDTHS.labels.md,
                  lg: COL_WIDTHS.labels.lg,
                }}
                fieldSizes={{
                  sm: COL_WIDTHS.fields.sm,
                  md: COL_WIDTHS.fields.md,
                  lg: COL_WIDTHS.fields.lg,
                }}
                postcodeFieldSizes={{ sm: 7, md: 5, lg: 5 }}
                searchBtnSizes={{ sm: 3, md: 5, lg: 4 }}
                change={this.props.change}
                value={get(formValues, 'data.attributes.address.postcode', '')}
                targetFields={{
                  organisation: 'data.attributes.name',
                  line1: 'data.attributes.address.line1',
                  line2: 'data.attributes.address.line2',
                  line3: 'data.attributes.address.line3',
                  town: 'data.attributes.address.town',
                  county: 'data.attributes.address.county',
                  postcode: 'data.attributes.address.postcode',
                }}
              />
              <AddressFields
                baseName="data.attributes.address"
                label="Address"
                labelSizes={{
                  sm: COL_WIDTHS.labels.sm,
                  md: COL_WIDTHS.labels.md,
                  lg: COL_WIDTHS.labels.lg,
                }}
                fieldSizes={{
                  sm: COL_WIDTHS.fields.sm,
                  md: COL_WIDTHS.fields.md,
                  lg: COL_WIDTHS.fields.lg,
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={6}>
              <FormGroup>
                <Col
                  sm={COL_WIDTHS.labels.sm}
                  md={COL_WIDTHS.labels.md}
                  lg={COL_WIDTHS.labels.lg}
                  componentClass={ControlLabel}
                >
                  Marketing
                </Col>
                <Col
                  sm={COL_WIDTHS.fields.sm}
                  md={COL_WIDTHS.fields.md}
                  lg={COL_WIDTHS.fields.lg}
                >
                  <Marketing
                    displayTitle={false}
                    name="data.attributes.marketing_options"
                    component={Marketing}
                    dispatch={this.props.dispatch}
                    formValues={formValues}
                    change={this.props.change}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={6}>
              <FormGroup>
                <Col
                  sm={COL_WIDTHS.labels.sm}
                  md={COL_WIDTHS.labels.md}
                  lg={COL_WIDTHS.labels.lg}
                  componentClass={ControlLabel}
                >
                  Marketing Referral
                </Col>
                <Col
                  sm={COL_WIDTHS.fields.sm}
                  md={COL_WIDTHS.fields.md}
                  lg={COL_WIDTHS.fields.lg}
                >
                  <Field
                    name="data.attributes.metadata.marketing_referral"
                    component={TextField}
                    type="textarea"
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <InternalButtonBar
                buttons={[
                  {
                    permissions: ['customer.edit'],
                    button: (
                      <Button
                        className="pull-right"
                        type="submit"
                        bsStyle="primary"
                        label="Save Customer"
                        isLoading={submitting}
                      />
                    ),
                  },
                ]}
              />
            </Col>
          </Row>
        </Form>
      </Box>
    );
  }
}

CustomerForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  resource: PropTypes.object,
};

const form = reduxForm({ form: FORM_NAME })(CustomerForm);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  const values = selector(state, 'data.id', 'data.attributes');

  return {
    initialValues: props.resource,
    formValues: values,
    searchResult: state.searchResult.storeCustomer,
  };
};

export default connect(mapStateToProps)(form);

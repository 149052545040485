import React from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';

import './DynamicDocumentVersionSelector.scss';

const DynamicDocumentVersionSelector = ({
  onSelectDocument,
  documentList,
  activeDocument,
}) => {
  const handleSelectDocument = (document) => () => {
    onSelectDocument(document);
  };

  return (
    <DropdownButton
      pullRight
      bsStyle={'default'}
      bsSize={'xs'}
      id={'split-button-basic'}
      title={'v' + activeDocument.attributes.version}
      className={`document-version-dropdown-btn ${activeDocument.attributes.status}`}
    >
      {documentList?.map((document, key) => {
        return (
          <MenuItem
            key={key}
            eventKey={key + 1}
            active={activeDocument.id === document.id}
            onClick={handleSelectDocument(document)}
          >
            <div className="dropdown-item">
              <span>
                {document.attributes.name} - v{document.attributes.version}
              </span>
            </div>
          </MenuItem>
        );
      })}
    </DropdownButton>
  );
};

export default DynamicDocumentVersionSelector;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormGroup } from 'react-bootstrap';
import SelectMenu from '../SelectMenu/SelectMenu';
import './styles.scss';

export const SELECT_IDENTIFIER = 'activity';

class SelectActivity extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    callback: PropTypes.func,
    label: PropTypes.string,
    labelSize: PropTypes.number,
    input: PropTypes.object.isRequired,
    infoButton: PropTypes.element,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    meta: PropTypes.object,
    object: PropTypes.bool,
    multi: PropTypes.bool,
    filters: PropTypes.array,
  };

  render() {
    const { input, name, meta, object, isMulti, ...props } = this.props;

    const source = 'products/activities';
    const classname = [
      'select-country-or-region',
      this.props.act ? 'activity-width' : '',
    ];

    return (
      <FormGroup
        controlId={name}
        className={meta && meta.touched && meta.error ? ' has-error' : ''}
      >
        <div className={classname.join(' ')}>
          <SelectMenu
            source={source}
            name={SELECT_IDENTIFIER}
            field={input}
            object={object}
            mapOptions={(option) => {
              return {
                label: option.attributes.name,
                value: option.attributes.name,
              };
            }}
            isMulti={isMulti}
            autoFilterType="include"
            autoFilter="region"
            {...props}
          />
          {meta && meta.touched && meta.error && (
            <span className="input-error">{meta.error}</span>
          )}
        </div>
      </FormGroup>
    );
  }
}

export default connect()(SelectActivity);

import Axios from 'axios/index';
import queryString from '../../../helpers/queryString';
import { ADD_RESOURCE } from '../../layout/redux/breadcrumb';
import handleErrorResponse from '../../../helpers/handleErrorResponse';
import { toastr } from 'react-redux-toastr';

const SET_PRODUCTS = 'socrates-online/product/SET_PRODUCTS';
const SET_DESTINATIONS = 'socrates-online/product/SET_DESTINATIONS';
const SET_PRODUCT = 'socrates-online/product/SET_PRODUCT';
const SET_PRODUCTS_STATS = 'socrates-online/product/SET_PRODUCTS_STATS';
const IS_LOADING = 'socrates-online/product/IS_LOADING';
const SET_PRODUCT_OPTIONS = 'socrates-online/scheme/SET_PRODUCT_OPTIONS';
const IS_SUBMITTING = 'socrates-online/scheme/IS_SUBMITTING';
const CLEAR_DATA = 'socrates-online/product/CLEAR_DATA';

export const initialState = {
  isLoading: false,
  isCopying: false,
  isEditing: false,
  isImporting: false,
  isExporting: false,
  isSubmitting: false,
  product: undefined,
  productOptions: undefined,
  products: [],
  scheme: null,
  schemeGroupList: null,
  activeSchemeGroup: {
    single: null,
    annual: null,
  },
  activeScheme: {},
  stats: {},
  destinations: {},
  documentManagement: {
    docTypeList: [],
    showDocumentModal: false,
    activeDocument: null,
  },
  dynamicDocuments: {
    list: [],
  },
};

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: action.status,
      };
    case IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.data,
      };
    case SET_PRODUCTS:
      return {
        ...state,
        products: action.data,
      };
    case SET_PRODUCTS_STATS:
      return {
        ...state,
        stats: action.data,
      };
    case SET_PRODUCT_OPTIONS:
      return {
        ...state,
        productOptions: action.data,
      };
    case SET_DESTINATIONS:
      return {
        ...state,
        destinations: action.data,
      };
    case SET_PRODUCT:
      return {
        ...state,
        product: action.data,
      };

    case CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
}

const getProductsStats = () => {
  return (dispatch) => {
    return Axios.get('products/statistics')
      .then((response) => {
        dispatch({
          type: SET_PRODUCTS_STATS,
          data: response.data?.meta,
        });
        return true;
      });
  };
};

const getProducts = (includes = [], filters = []) => {
  const endpoint = `products${queryString(includes, [], filters, 100)}`;
  return (dispatch) => {
    dispatch(isLoading(true));
    return Axios.get(endpoint + includes)
      .then((response) => {
        dispatch({ type: SET_PRODUCTS, data: response.data?.data ?? [] });
        return true;
      })
      .finally(() => {
        dispatch(isLoading(false));
      });
  };
};

const getProduct = (productId, includes) => {

  const endpoint = `products/${productId}${queryString(includes)}`;
  return (dispatch) => {
    dispatch(isLoading(true));
    return Axios.get(endpoint)
      .then((response) => {

        dispatch({ type: SET_PRODUCT, data: response.data });
        // dispatch(getProductsStats(response.data.data.attributes.product_code))
        dispatch({
          type: ADD_RESOURCE,
          id: response.data.data.id,
          name: response.data.data.attributes.name,
        });
        return true;
      })
      .finally(() => {
          dispatch(isLoading(false));
        }
      );
  };
};


const cloneProduct = (productId, values) => {
  const endpoint = `products/${productId}:clone`;
  return () => {

    return Axios.post(endpoint, values)
      .then((resp) => {
        return resp.data;
      });

  };
};

const getDestinations = () => {
  return (dispatch) => {
    // dispatch(isLoading(true));
    return Axios.get('products/destinations?page[limit]=500')
      .then((response) => {
        dispatch({
          type: SET_DESTINATIONS,
          data: response.data?.data ?? [],
        });
        // dispatch(isLoading(false));
        return true;
      });
  };
};
const clearData = () => {
  return {
    type: CLEAR_DATA,
  };
};
const updateProduct = (productId, values) => {

  return (dispatch) => {
    dispatch(isLoading(true));
    return Axios.patch(`products/${productId}`, values)
      .then((response) => {
        dispatch({
          type: SET_PRODUCT,
          data: response.data,
        });
        dispatch(isLoading(false));
        toastr.success('Success', 'Your changes have been saved');
        return true;
      })
      .catch((error) => {
        dispatch(isLoading(false));
        handleErrorResponse(error, 'There was an error updating the product');
      });
  };
};
const updateClonedProduct = (productId, values) => {

  return (dispatch) => {
    dispatch(isLoading(true));
    return Axios.patch(`product-management/${productId}`, values)
      .then((response) => {
        dispatch({
          type: SET_PRODUCT,
          data: response.data,
        });
        dispatch(isLoading(false));
        toastr.success('Success', 'Your changes have been saved');
        return true;
      })
      .catch((error) => {
        dispatch(isLoading(false));
        handleErrorResponse(error, 'There was an error updating the product');
      });
  };
};
const isLoading = (state) => ({ type: IS_LOADING, status: state });
const getDefinitions = (productId) => {
  return () => {
    return Axios.get(`/product-management/${productId}/definitions`)
      .then((response) => {
        return response.data.meta;
      }).catch((error) => {
        handleErrorResponse(
          error,
          'There was an error updating the product definitions',
        );
      });
  };
};
const updateDefinitions = (productId, values) => {
  return () => {
    return Axios.post(`/product-management/${productId}/definitions`, values)
      .then(() => {
        return true;
      }).catch((error) => {
        handleErrorResponse(
          error,
          'There was an error updating the product definitions',
        );
      });
  };
};
const getProductOptions = () => {
  return (dispatch) => {
    dispatch(isLoading(true));
    return Axios.get('products/options?fields[products/options]=name,code&page[limit]=30')
      .then((response) => {
        dispatch({ type: SET_PRODUCT_OPTIONS, data: response.data.data });
        dispatch(isLoading(false));
        return true;
      })
      .catch(() => {
        dispatch(isLoading(false));
      });
  };
};
export const actions = {
  getProduct,
  getProducts,
  getProductsStats,
  getDestinations,
  clearData,
  cloneProduct,
  getProductOptions,
  updateProduct,
  updateClonedProduct,
  getDefinitions,
  updateDefinitions,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InternalContainer from '../../layout/containers/Internal';
import PolicyList from '../components/PolicyList/PolicyList';
import { get } from 'lodash';

class PolicyListContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
  };

  render() {
    const currentBroker = get(
      this.props.auth,
      'user.data.relationships.current_broker.data.id',
      null,
    );

    return (
      <InternalContainer title="Policies">
        <PolicyList {...this.props} currentBroker={currentBroker} />
      </InternalContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(PolicyListContainer);

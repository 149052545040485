import React, { useMemo, useState } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import './SchemeCheckboxTree.scss';


const getChecked = (values) => {
  if (Array.isArray(values)) {
    return values?.map((input) => input.id);
  }
  return [];
};

const SchemeCheckboxTree = ({ input, meta, nodes }) => {

  const [expanded, setExpanded] = useState([]);
  // const [checked, setChecked] = useState(input?.value?.map((input) => input.id) ?? []);
  const value = input?.value;


  const checked = useMemo(() => getChecked(value), [value]);

  const handleCheckedChange = (newChecked) => {

    const checkedIds = newChecked?.map((id) => ({ id: id }));
    input.onChange(checkedIds);

  };
  const expand = (newExpanded) => {
    setExpanded(newExpanded);
  };


  return (
    <div>
      <CheckboxTree
        nodes={nodes}
        checked={checked}
        expanded={expanded}
        showNodeIcon={false}
        onCheck={handleCheckedChange}
        onExpand={expand}
        icons={{
          check: <span className="fas fa-check-square"/>,
          uncheck: <span className="far fa-square"/>,
          halfCheck: <span className="fas fa-check-square"/>,
          expandClose: <span className="fas  fa-chevron-right"/>,
          expandOpen: <span className="fas  fa-chevron-down"/>,
          expandAll: <span className="fas  fa-plus-square"/>,
          collapseAll: <span className="fas  fa-minus-square"/>,
          parentClose: <span className="fas  fa-folder"/>,
          parentOpen: <span className="fas  fa-folder-open"/>,
          leaf: <span className="fas  fs-file"/>,
        }}
      />
      {meta && meta.touched && meta.error && (
        <span className="input-error">{meta.error}</span>
      )}
    </div>
  );

};
export default SchemeCheckboxTree;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { Button, Modal, SelectBroker, SelectUser } from '../../../../common/components';
import { TextField } from '../../../../common/components/ReduxFormField';
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux';
import { transferPolicy } from '../../redux/policyActions';
import { Row, Col, ControlLabel } from 'react-bootstrap';
import { get, set, isEmpty } from 'lodash';
import './styles.scss';

const FORM_NAME = 'transferPolicyForm';

const validate = (values) => {
  const errors = {};

  if (!get(values, 'data.relationships.broker.data.id')) {
    set(errors, 'data.relationships.broker.data.id', 'Broker is required');
  }

  if (!get(values, 'data.relationships.user.data.id')) {
    set(errors, 'data.relationships.user.data.id', 'User is required');
  }

  return errors;
};

class TransferPolicyButton extends Component {
  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded, callback } = this.props;
    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('');
      }
      dispatch(closeModal(FORM_NAME));
    }
  }

  handleSubmit(values) {
    return this.props.dispatch(
      transferPolicy(values, this.props.resource, this.props.resourceType),
    );
  }

  handleOpen() {
    const { dispatch, reset } = this.props;
    dispatch(reset());
    dispatch(openModal(FORM_NAME));
  }

  render() {
    const { label, handleSubmit, submitting, formValues } = this.props;
    const brokerId = get(formValues, 'data.relationships.broker.data.id');

    const title = label || 'Transfer';
    const submitButton = (
      <Button type="submit" bsStyle="primary" isLoading={submitting} label="Transfer"/>
    );

    return (
      <div>
        <Button
          bsStyle="primary"
          className="pull-right"
          label={title}
          rightIcon="exchange"
          handleClick={::this.handleOpen}
        />

        <Modal
          name={FORM_NAME}
          title={title + ' Policy'}
          close
          handleSubmit={handleSubmit(::this.handleSubmit)}
          footer={submitButton}
        >
          <div className="form-horizontal">
            <Row>
              <Col xs={12}>
                <p>Please select a Broker to transfer this policy to.</p>
              </Col>
            </Row>

            <Field
              async
              name={'data.relationships.broker.data.id'}
              label="Broker"
              labelSize={3}
              component={SelectBroker}
            />

            {!isEmpty(brokerId) && (
              <Field
                name={'data.relationships.user.data.id'}
                label="User"
                byBroker={brokerId}
                labelSize={3}
                component={SelectUser}
              />
            )}
          </div>
          <Row>
            <Col xs={12} componentClass={ControlLabel}>
              Reason for transferring policy
            </Col>
            <Col xs={12}>
              <Field name="meta.note.content" type="textarea" component={TextField}/>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(TransferPolicyButton);

TransferPolicyButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  resource: PropTypes.object.isRequired,
  resourceType: PropTypes.string.isRequired,
  broker: PropTypes.object,
  user: PropTypes.object,
  callback: PropTypes.func,
  label: PropTypes.string,
};

const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  const values = selector(
    state,
    'data.relationships.broker.data.id',
    'data.relationships.user.data.id',
  );

  return {
    initialValues: {
      data: {
        relationships: {
          broker: {
            data: {
              id: get(props.broker, 'data.id', ''),
            },
          },
          user: {
            data: {
              id: get(props.user, 'data.id', ''),
            },
          },
        },
      },
    },
    formValues: values,
  };
};

export default connect(mapStateToProps)(form);

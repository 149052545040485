import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Button, Modal, SelectRole, SelectUser } from '../../../../common/components';
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux';
import { assignUserToOrganisation } from '../../redux/organisationActions';
import { Form } from 'react-bootstrap';
import { get, set, isEmpty } from 'lodash';

const FORM_NAME = 'assignUserToOrganisation';

const validate = (values) => {
  const errors = {};

  if (!get(values, 'data.relationships.user.data.id', false)) {
    set(errors, 'data.relationships.user.data.id', 'A user is required');
  }

  if (isEmpty(get(values, 'data.relationships.roles.data', []))) {
    set(errors, 'data.relationships.roles.data', 'A role is required');
  }

  return errors;
};

class OrganisationAssignUser extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    callback: PropTypes.func,
  };

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded, callback } = this.props;
    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('');
      }
      dispatch(closeModal(FORM_NAME));
    }
  }

  handleSubmit(values) {
    return this.props.dispatch(
      assignUserToOrganisation(this.props.resource.data.id, values),
    );
  }

  handleOpen() {
    const { dispatch, reset } = this.props;
    dispatch(reset());
    dispatch(openModal(FORM_NAME));
  }

  render() {
    const { handleSubmit, resource } = this.props;

    const submitButton = (
      <Button
        type="submit"
        bsStyle="primary"
        handleClick={handleSubmit(::this.handleSubmit)}
        label="Assign User"
      />
    );

    return (
      <div>
        <Button
          className="pull-right"
          type="button"
          bsStyle="primary"
          label="Assign Existing User"
          rightIcon="user"
          handleClick={::this.handleOpen}
        />

        <Modal
          name={FORM_NAME}
          title="Assign User to Organisation"
          close
          footer={submitButton}
        >
          <Form onSubmit={handleSubmit(::this.handleSubmit)} autoComplete="off" className={'form-horizontal'}>
            <Field
              async
              label="Assign User"
              labelSize={3}
              name={'data.relationships.user.data.id'}
              component={SelectUser}
            />
            <Field
              name={'data.relationships.roles.data'}
              label="Roles"
              labelSize={3}
              autoFilters={[{ type: 'organisation_type', match: resource.data.attributes.organisation_type_name }]}
              component={SelectRole}
            />
          </Form>
        </Modal>
      </div>
    );
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(OrganisationAssignUser);
const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps)(form);

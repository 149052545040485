import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, FormGroup, ControlLabel } from 'react-bootstrap';
import { HorizontalFormControl } from '../../../../common/components';
import { Field } from 'redux-form';
import PostcodeList from '../../../../common/components/Postcode/PostcodeList';
import { COL_WIDTHS } from '../LeisureTravelForm/Components/TabContent/TravellersTab02';
import { get } from 'lodash';
import { AddressFields } from '../../../../common/components/ReduxFormField';

class CompanyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFields: false,
    };
    this.handleCantFind = this.handleCantFind.bind(this);
  }

  handleCantFind() {
    this.setState((prevState) => ({
      showFields: !prevState.showFields,
    }));
  }

  render() {
    const {
      formValues,
      addressSelection,
    } = this.props;

    const addressSelected = () =>
      get(addressSelection, 'attributes.line1', false) ||
      get(formValues, 'customer.address.line1', false) ||
      this.state.showFields;
    const showCantFindLink = () =>
      get(addressSelection, 'attributes.line1', '') === '' &&
      get(formValues, 'customer.address.line1', '') === '';

    return (
      <div className="customer">
        <FormGroup>
          <Row>
            <Col md={4} lg={3} componentClass={ControlLabel}>
              Company Name
            </Col>
            <Col md={8} lg={9}>
              <Field
                name="customer.company_name"
                type="input"
                component={HorizontalFormControl}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col md={4} lg={3} componentClass={ControlLabel}>
              Nature of business
            </Col>
            <Col md={8} lg={9}>
              <Field
                name="customer.nature_of_business"
                type="input"
                component={HorizontalFormControl}
              />
            </Col>
          </Row>
        </FormGroup>
        <Row>
          <PostcodeList
            handleCantFind={this.handleCantFind}
            showFieldsState={this.state.showFields}
            name={'customer.address.postcode'}
            formName="LeisureTravelForm"
            targetField={'data.attributes.metadata.customer.address'}
            showCantFindLink={showCantFindLink}
            label="Postcode"
            change={this.props.change}
            labelSizes={{
              sm: COL_WIDTHS.labels.sm,
              md: COL_WIDTHS.labels.md,
              lg: COL_WIDTHS.labels.lg,
            }}
            postcodeFieldSizes={{
              sm: 6,
              md: 5,
              lg: 4,
            }}
            searchBtnSizes={{
              sm: 4,
              md: 3,
              lg: 4,
            }}
            fieldSizes={{
              sm: COL_WIDTHS.fields.sm,
              md: COL_WIDTHS.fields.md,
              lg: COL_WIDTHS.fields.lg,
            }}
            noWrap
            value={get(formValues, 'customer.address.postcode', '')}
          />
          {addressSelected() && (
            <AddressFields
              baseName={'customer.address'}
              label="Address"
              labelSizes={{
                sm: COL_WIDTHS.labels.sm,
                md: COL_WIDTHS.labels.md,
                lg: COL_WIDTHS.labels.lg,
              }}
              fieldSizes={{
                sm: COL_WIDTHS.fields.sm,
                md: COL_WIDTHS.fields.md,
                lg: COL_WIDTHS.fields.lg,
              }}
            />
          )}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ postcode: { selection } }) => {
  return { addressSelection: selection };
};

export default connect(mapStateToProps)(CompanyDetails);

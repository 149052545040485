import React, { useState } from 'react';
import SchemeManagementSectionContainer
  from '../../SchemeManagementSectionContainer/SchemeManagementSectionContainer';
import { Col, Row } from 'react-bootstrap';
import {
  Button,
  HorizontalFormControl,
  Icon,
  InfoLabel,
} from '../../../../../common/components';
import { Field, FieldArray } from 'redux-form';
import InfoButton from '../../../../../common/components/InfoButton';
import {
  CheckboxUnlabeled,
  CreatableSelectField,
  SelectField,
} from '../../../../../common/components/ReduxFormField';
import BenefitTextField from './BenefitTextField';
import SubBenefitsFieldArray from './SubBenefitsFieldArray';

const BenefitField = ({
  isViewOnly,
  options,
  hasExcessWaiver,
  onRemove,
  coverLevels,
  isLast,
  index,
  input,
}) => {
  const [showMoreInfo, setShowMoreInfo] = useState(isViewOnly || isLast);
  const [showOption, setShowOption] = useState(input?.value?.option !== undefined && input?.value?.option !== null);

  const toggleShowMoreInfo = () => {
    setShowMoreInfo(!showMoreInfo);
  };
  const toggleShowOptions = () => {
    if (showOption && input?.value?.option) {
      const newValue = {
        ...input.value,
        option: undefined

      };
      input.onChange(newValue);
    }
    setShowOption(!showOption);
  };
  const handleRemoveBenefit = () => {
    onRemove(index);
  };

  const benefit = input.value;

  return (
    <Col xs={12}>
      <SchemeManagementSectionContainer
        isViewOnly={isViewOnly}
        subTitle={
          <div onClick={toggleShowMoreInfo}>
            {benefit.name ? benefit.name : 'New Benefit'}
            <span className={'remove-benefit pull-right'} title={'remove benefit'}>
              <Icon name={showMoreInfo ? 'minus' : 'plus'}/>
            </span>
          </div>
        }
      >
        {showMoreInfo && (
          <>
            <Row className={'section-item'}>
              <Col sm={12}>
                <Row>
                  <Col sm={12}>
                    <h4>Benefit Details:</h4>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Field
                      label="Name:"
                      labelSize={4}
                      name={`${input.name}.name`}
                      component={isViewOnly ? InfoLabel : HorizontalFormControl}
                    />
                  </Col>
                  <Col sm={6}>
                    <Field
                      label="Display Name:"
                      labelSize={4}
                      infoButton={
                        <InfoButton
                          content={
                            'Either select a value from the drop down to assign this benefit to the quote table benefit display or type your own display name'
                          }
                        />
                      }
                      name={`${input.name}.display_name`}
                      component={isViewOnly ? InfoLabel : CreatableSelectField}
                      isClearable
                      options={[
                        { label: 'Medical', value: 'Medical' },
                        { label: 'Baggage', value: 'Baggage' },
                        { label: 'Cancellation', value: 'Cancellation' },
                      ]}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Field
                      label="Text:"
                      labelSize={4}
                      name={`${input.name}`}
                      isViewOnly={isViewOnly}
                      coverLevels={coverLevels}
                      component={BenefitTextField}
                    />
                  </Col>
                  <Col sm={6}>
                    <Field
                      label="Excess:"
                      isClearable
                      labelSize={4}
                      name={`${input.name}.excess`}
                      component={isViewOnly ? InfoLabel : CreatableSelectField}
                      options={[
                        { label: 'Nil', value: 'nil' },
                        { label: 'Standard', value: 'standard' },
                        { label: 'Other', value: 'other' },
                      ]}
                    />
                  </Col>
                </Row>
                {hasExcessWaiver && (
                  <Row>
                    <Col sm={12}>
                      <Field
                        label="Exclude this benefit from excess waiver"
                        labelSize={5}
                        name={`${input.name}.exclude_from_excess_waiver`}
                        component={CheckboxUnlabeled}
                        options={[{ label: '', value: true }]}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  {showOption && (
                    <>
                      <hr/>
                      <Col sm={12}>
                        <h4> Related Additional Options</h4>

                      </Col>
                      <Col sm={6}>
                        <Field
                          label="Option:"
                          labelSize={4}
                          name={`${input.name}.option`}
                          component={isViewOnly ? InfoLabel : SelectField}
                          options={options}
                        />
                      </Col>
                      <Col sm={4}>
                        <Field
                          label="Exclude"
                          labelSize={4}
                          infoButton={
                            <InfoButton
                              content={
                                'Show benefit as excluded on schedule is option was selected'
                              }
                            />
                          }
                          name={`${input.name}.inverse`}
                          component={isViewOnly ? InfoLabel : CheckboxUnlabeled}
                          options={[{ label: '', value: true }]}
                        />
                      </Col>
                    </>
                  )}
                  {!isViewOnly && !!options?.length && (
                    <Col sm={2}>
                      <Button handleClick={toggleShowOptions} className="pull-left">
                        {showOption ? 'Remove Option Info ' : 'Add Option Info'}
                      </Button>
                    </Col>
                  )}
                </Row>
                <hr/>
                <Row>
                  <Col sm={12}>
                    <h4 className="margin-top">Sub Benefits:</h4>
                  </Col>
                  <Col sm={10}>
                    <FieldArray
                      component={SubBenefitsFieldArray}
                      isViewOnly={isViewOnly}
                      name={`${input.name}.sub_benefits`}
                      coverLevels={coverLevels}
                    />
                  </Col>
                </Row>
                {!isViewOnly && (
                  <Row>
                    <Col sm={12}>
                      <>
                        <Button handleClick={handleRemoveBenefit} className="pull-right">
                          Remove <Icon name={'trash'}/>
                        </Button>
                      </>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </>
        )}
      </SchemeManagementSectionContainer>
    </Col>
  );
};

export default BenefitField;

import React from 'react';
import { connect } from 'react-redux';
import { Col, Modal, Row } from 'react-bootstrap';
import { actions as documentActions } from '../../redux/documentManagementActions';
import './ViewDocumentModal.scss';

import { Button } from '../../../../common/components';

const ViewDocumentRow = ({ label, content }) => {
  if (!content) {
    return null;
  }
  return (
    <Row className="document-modal-row">
      <Col sm={3}>
        <strong>{label}:</strong>
      </Col>
      <Col sm={9}>{content}</Col>
    </Row>
  );
};

const Schemes = ({ schemes }) => {
  return (
    <ul className={'schemes-list-container'}>
      {schemes?.map((scheme) => <li>{scheme}</li>)}
    </ul>
  );
};

const ViewDocumentModalBody = ({ isAncillaryDocument, document }) => {
  return (
    <div className="view-document-modal">
      <ViewDocumentRow label={'Channel'} content={document?.channel_name}/>
      <ViewDocumentRow label={'Display Name'} content={document.display_name}/>
      <ViewDocumentRow label={'Internal Name'} content={document.name}/>
      <ViewDocumentRow label={'Short Name'} content={document.short_name}/>
      <ViewDocumentRow
        label={'Status'}
        content={<span className={'capitalise'}>{document.status}</span>}
      />
      <ViewDocumentRow label={'Version'} content={document.version_number}/>

      <ViewDocumentRow label={'Active From'} content={document.from_date}/>
      <ViewDocumentRow label={'Active To'} content={document.to_date}/>
      {isAncillaryDocument && (
        <ViewDocumentRow
          label={'Scheme'}
          content={document.ancillary_schemes?.map((s) => s.name).join(',')}
        />
      )}
      <ViewDocumentRow
        label={'File'}
        content={
          <a target="_blank" className={'btn btn-primary btn-xs'} href={document.file}>
            <i className="fa fa-file"/>
            {document.short_name}
          </a>
        }
      />
      <ViewDocumentRow
        label={'Schemes'}
        content={
          document?.schemes.length ? <Schemes schemes={document.schemes}/> : undefined
        }
      />
    </div>
  );
};

const ViewDocumentModal = ({
  document,
  isAncillaryDocument,
  showDocumentModal,
  closeModal,
}) => {
  const handleCloseModal = () => {
    closeModal();
  };

  const modalTile = `View ${document.name}`;
  return (
    <div>
      <Modal show={showDocumentModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTile}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ViewDocumentModalBody
            document={document}
            isAncillaryDocument={isAncillaryDocument}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button className="pull-right" handleClick={handleCloseModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default connect(
  null,
  {
    closeModal: documentActions.closeViewDocumentModal,
  },
)(ViewDocumentModal);

import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { actions as documentActions } from '../redux/documentManagementActions';
import { actions as productActions } from '../../productManagement/redux/ProductReducer';
import { Loader } from '../../../common/components';
import DocumentList from '../components/DocumentList/DocumentList';
import InternalContainer from '../../layout/containers/Internal';
import ProductName
  from '../../productManagement/components/ProductFrontPage/components/ProductName/ProductName';
import ProductNavigation
  from '../../productManagement/components/ProductNavigation/ProductNavigation';

const DocumentListContainer = ({
  params,
  location,
  onCreateNewDocument,
  onUpdateDocument,
  onGetDocumentTypes,
  onGetProduct,
  onClearData,
}) => {
  const productId = params.productId;

  const { product } = useSelector((state) => state.productManagement);

  const { summary, documentTypeList } = useSelector((state) => state.documentManagement);

  const currentProductId = product?.data?.id;
  useEffect(
    () => {
      if (!currentProductId || currentProductId !== productId) {
        onGetProduct(productId, ['all_schemes', 'all_schemes.insurer']);
      }
    },
    [productId, currentProductId, onGetProduct],
  );

  useEffect(
    () => {
      onGetDocumentTypes(productId);
      return () => {
        //clearDocumentManagementData();
        onClearData();
      };
    },
    [onGetDocumentTypes, productId,
      //  getProduct, clearData, clearDocumentManagementData
    ],
  );

  const handleAddDocument = (values) => {
    return onCreateNewDocument(values, currentProductId);
  };

  const handleSaveDocument = (values) => {

    const documentId = values.data.id;

    return onUpdateDocument(values, documentId, currentProductId);
  };

  if (!documentTypeList?.length || !currentProductId) {
    return (
      <InternalContainer title={'Product Management'}>
        <Loader/>
      </InternalContainer>
    );
  }


  const { icon, name, status, metadata } = product?.data?.attributes ?? {};
  const { is_wl_product: isWhitelabel } = metadata ?? {};

  return (
    <InternalContainer title={'Product Management'}>
      <ProductName
        title={'Documents'}
        icon={icon}
        name={name}
        status={status}

      >
        <ProductNavigation
          currentLocation={location.pathname}
          id={productId}
          isWhitelabel={isWhitelabel}
        />

      </ProductName>
      <DocumentList
        productId={params.productId}
        product={product}
        onAddDocument={handleAddDocument}
        onSaveDocument={handleSaveDocument}
        summary={summary}
        documentTypes={documentTypeList}
      />

    </InternalContainer>
  );
};
export default connect(
  null,
  {
    onCreateNewDocument: documentActions.createNewDocument,
    onUpdateDocument: documentActions.updateDocument,
    onGetDocumentTypes: documentActions.getDocumentTypes,
    onGetProduct: productActions.getProduct,
    onClearData: productActions.clearData,
  },
)(DocumentListContainer);

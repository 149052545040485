import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormGroup, Col, ControlLabel } from 'react-bootstrap';
import SelectMenu from '../SelectMenu/SelectMenu';

export const SELECT_IDENTIFIER = 'occupation';

class SelectOccupation extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    label: PropTypes.string,
    labelSize: PropTypes.number,
    input: PropTypes.object.isRequired,
    infoButton: PropTypes.element,
    name: PropTypes.string,
    meta: PropTypes.object,
    object: PropTypes.bool,
    multi: PropTypes.bool,
    filters: PropTypes.array,
  };

  render() {
    const {
      input,
      infoButton,
      meta,
      label,
      labelSize,
      mdFieldSize,
      filters,
      object,
      isMulti,
      ...props
    } = this.props;

    let source = 'products/occupations';

    if (Array.isArray(filters)) {
      filters.map((filter) => {
        source += (source.includes('?') ? '&' : '?') + filter.filter + '=' + filter.value;
      });
    }

    let selectSize = mdFieldSize ? mdFieldSize : 12 - labelSize;

    return (
      <FormGroup
        controlId={input.name}
        className={meta && meta.touched && meta.error ? ' has-error' : ''}
      >
        <Col componentClass={ControlLabel} sm={labelSize}>
          {label} {infoButton}
        </Col>
        <Col sm={selectSize}>
          <SelectMenu
            source={source}
            name={SELECT_IDENTIFIER}
            field={input}
            object={object}
            isMulti={isMulti}
            mapOptions={(option) => {
              return {
                label: option.attributes.name,
                value: option.attributes.name, // the property should be unique
              };
            }}
            {...props}
          />

          {meta && meta.touched && meta.error && (
            <span className="input-error">{meta.error}</span>
          )}
        </Col>
      </FormGroup>
    );
  }
}

export default connect()(SelectOccupation);

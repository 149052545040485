import Axios from 'axios/index';
import { get, isEmpty } from 'lodash';
import moment from 'moment';

const SET_DATA = 'selects/SET_DATA';
const IS_FETCHING = 'selects/IS_FETCHING';
const SET_DISPLAY_OPTIONS = 'selects/SET_DISPLAY_OPTIONS';

export const initialState = {};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        [action.source]: {
          //...state.selects[action.select],
          data: action.data,
          included: action.included,
          options: action.options,
          lastFetch: action.lastFetch,
          isFetching: action.isFetching,
          //displayOptions: action.options
        },
      };
    case SET_DISPLAY_OPTIONS:
      return {
        ...state,
        [action.source]: {
          ...state[action.source],
          displayOptions: action.data,
        },
      };
    case IS_FETCHING:
      return {
        ...state,
        [action.source]: {
          isFetching: action.status,
        },
      };
    default:
      return state;
  }
}

const isFetching = (source, status) => ({
  type: IS_FETCHING,
  source: source,
  status: status,
});

const setDisplayOptions = (source, options) => ({
  type: SET_DISPLAY_OPTIONS,
  source: source,
  data: options,
});

const setData = (source, response, options) => {
  return {
    type: SET_DATA,
    source: source,
    data: response.data,
    included: response.included || [],
    options: options,
    lastFetch: moment(),
    isFetching: false,
  };
};

const fetchData = (
  source,
  sourceKey = '',
  filter = '',
  labelPath,
  valuePath,
  stringify,
  includes,
  filterUrl,
  override
) => {
  return (dispatch) => {
    const shouldFetchData = dispatch(shouldFetch(source + sourceKey, override));
    if (shouldFetchData) {
      let endpoint = source;

      if (!isEmpty(filter)) {
        // review this code as it seems never been used
        // All the filters seems to be passed by source
        endpoint = endpoint + filter;
      }

      // Appending a URL to the end of Endpoint
      if (filterUrl) {
        endpoint = endpoint + filterUrl;
      }

      // Get all data
      if (!endpoint.includes('page[limit]')) {
        let joinChar = endpoint.includes('?') ? '&' : '?';
        endpoint = endpoint + joinChar + 'page[limit]=0';
      }

      dispatch(isFetching(source, true));
      return Axios.get(endpoint)
        .then((response) => {
          const options = response.data.data.map((r) => {
            const optionValue = valuePath ? get(r, valuePath) : r;
            return {
              label: get(r, labelPath || 'attributes.name'),
              value: stringify ? JSON.stringify(optionValue) : optionValue,
            };
          });
          dispatch(isFetching(source, false));
          return dispatch(setData(source + sourceKey, response.data, options));
        })
        .catch((error) => {
          dispatch(isFetching(source, false));
          console.error(error);
        });
    }
  };
};

const shouldFetch = (key, override = false) => {
  return (dispatch, getState) => {
    if (override) {
      return true;
    }
    const lastFetch = get(getState(), `selects.${key}.lastFetch`);
    if (!lastFetch) {
      return true;
    }
    const now = moment();
    return now.diff(lastFetch, 'minutes') > 10;
  };
};

export const actions = {
  fetchData,
  setDisplayOptions,
  setData,
};

import React from 'react';
import { connect } from 'react-redux';
import {
  Field,
  FieldArray,
  getFormValues,
  reduxForm
} from 'redux-form';
import { Col, ControlLabel, FormGroup, Form, Row, Checkbox } from 'react-bootstrap';
import { AddressFields } from '../../../../common/components/ReduxFormField';
import PostcodeList from '../../../../common/components/Postcode/PostcodeList';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import LlpPartner from '../../../brochure/components/BrokerApplicationForm/LlpPartner';
import {
  Box,
  Button,
  HorizontalFormControl,
  SelectLegalEntityType,
  SelectOrganisationType,
} from '../../../../common/components';
import './OrganisationForm.scss';
import {
  InternalButtonBar
} from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import CheckboxUnlabeled
  from "../../../../common/components/ReduxFormField/CheckboxUnlabeled";
import { required, format } from "redux-form-validators";

const FORM_NAME = 'organisationForm';

const OrganisationForm = ({
  formValues,
  handleSubmit,
  submitting,
  resource,
  organisationTypeName,
  onSave
}) => {

  const organisationTypeInc = getIncludedResource(
    resource.data,
    resource.included,
    'organisation_type',
  );

  const insurers = getIncludedResource(
    resource.data,
    resource.included,
    'insurers',
  );

  if (insurers.length === 0) {
    insurers.push({
      attributes: {
        code: '',
      },
    });
  }


  const selectedOrganisationType = formValues?.data?.relationships?.organisation_type.data?.attributes?.name ?? organisationTypeInc?.data?.attributes?.name;


  const isInsurer = selectedOrganisationType === 'Insurer'
  const showPartners = formValues?.data.relationships?.legal_entity_type?.data?.attributes?.code === 'lp' || formValues?.data?.relationships?.legal_entity_type?.data?.attributes?.code === 'llp';
  const showProprietor = formValues?.data?.relationships?.legal_entity_type?.data?.attributes?.code === 'soletrader'
  const isBroker = selectedOrganisationType === 'Broker'
  const isWhitelabelInsurer = isInsurer && formValues?.data?.attributes?.metadata?.is_wl_insurer;



  return (
    <Box>
      <h2
        className="resource-name">{resource.data.attributes.name || 'New ' + organisationTypeName}</h2>
      <Form onSubmit={handleSubmit(onSave)}>
        <Row>
          <Col sm={6}>
            <Field
              name="data.attributes.name"
              label="Name"
              labelSize={4}
              component={HorizontalFormControl}
              validate={required()}
            />


            <Field
              name="data.relationships.organisation_type.data"
              label="Organisation Type"
              isObject
              labelSize={4}
              component={SelectOrganisationType}
              validate={required()}
            />

            <Field
              name="data.attributes.phone1"
              label="Phone 1"
              labelSize={4}
              component={HorizontalFormControl}
            />
            <Field
              name="data.attributes.regulatory_authority_number"
              label="FCA Number"
              labelSize={4}
              component={HorizontalFormControl}
              validate={required()}
            />
            <Field
              name="data.attributes.phone2"
              label="Phone 2"
              labelSize={4}
              component={HorizontalFormControl}
            />
            {isInsurer && (
              <Field
                name="data.attributes.metadata.is_wl_insurer"
                label="Whitelabel Insurer?"
                labelSize={4}
                component={CheckboxUnlabeled}
                options={[{ label: '', value: true }]}
              />
            )}
            {isWhitelabelInsurer && (
              <Field
                name={'data.relationships.insurers.data.0.attributes.code'}
                label="Insurer Code"
                labelSize={4}
                inline
                options={[{ label: '', value: true }]}
                component={HorizontalFormControl}
                validate={[required(), format({ with: /^[a-z]+$/i, message: 'Code must only contain letters' })]}
              />
            )}
          </Col>
          <Col sm={6}>
            <Field
              name="data.relationships.legal_entity_type.data"
              label="Legal Entity Type"
              labelSize={4}
              isObject
              component={SelectLegalEntityType}
            />
            <Field
              name="data.attributes.metadata.website"
              label="Website"
              labelSize={4}
              component={HorizontalFormControl}
            />
            {showPartners && (
              <FormGroup>
                <Col sm={4}>
                  <ControlLabel>Partners</ControlLabel>
                </Col>
                <Col sm={8}>
                  <FieldArray
                    name="data.attributes.metadata.partners"
                    component={LlpPartner}
                    fieldComponent={Field}
                  />
                </Col>
              </FormGroup>
            )}
            {showProprietor && (
              <Field
                name="data.attributes.metadata.proprietor"
                label="Proprietor"
                labelSize={4}
                component={HorizontalFormControl}
              />
            )}
            <Field
              name="data.attributes.metadata.parent_company"
              label="Parent company"
              labelSize={4}
              component={HorizontalFormControl}
            />
            <Field
              name="data.attributes.legal_entity_number"
              label="Company Number"
              labelSize={4}
              object
              component={HorizontalFormControl}
            />
            {isBroker && (
              <Field
                name="data.attributes.metadata.principal_company"
                label="Principal company"
                labelSize={4}
                component={HorizontalFormControl}
              />

            )}
          </Col>
        </Row>

        <div className="hr"/>
        <Row>
          <Col lg={6}>
            <PostcodeList
              name="data.attributes.address.postcode"
              label="Postcode"
              context="customer"
              formName={FORM_NAME}
              labelSizes={{ sm: 2, md: 2, lg: 4 }}
              fieldSizes={{ sm: 10, md: 10, lg: 8 }}
              postcodeFieldSizes={{ sm: 6, md: 5, lg: 4 }}
              searchBtnSizes={{ sm: 4, md: 5, lg: 4 }}
              value={formValues?.data?.attributes?.address?.postcode ?? ''}
            />
            <AddressFields
              baseName="data.attributes.address"
              label="Address"
              labelSizes={{ sm: 2, md: 2, lg: 4 }}
              fieldSizes={{ sm: 10, md: 10, lg: 8 }}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <InternalButtonBar
              buttons={[
                {
                  permissions: ['organisation.edit'],
                  button: (
                    <Button
                      className="pull-right"
                      type="submit"
                      bsStyle="primary"
                      label={
                        'Save ' +
                        (organisationTypeName || 'Organisation')
                      }
                      isLoading={submitting}
                    />
                  ),
                },
              ]}
            />
          </Col>
        </Row>
      </Form>
    </Box>
  );
}

const getInitialValues = (resource, organisationTypeFromLocation, organisationTypeNameFromLocation) => {

  const organisationType = !resource?.data?.id && organisationTypeFromLocation ? {
    organisation_type: {
      data: {
        id: organisationTypeFromLocation,
        attributes: {
          name: organisationTypeNameFromLocation
        }
      }
    }
  } : {}


  return {
    data: {
      ...resource?.data,
      attributes: {
        ...resource?.data?.attributes,
        metadata: {
          ...resource?.data?.attributes?.metadata
        }
      },
      relationships: {
        ...resource?.data?.relationships,
        ...organisationType,

      }
    }
  }
}

const form = reduxForm({ form: FORM_NAME })(OrganisationForm);


export default connect(
  (state, props) => {


    return {
      initialValues: getInitialValues(props.resource, props.organisationType, props.organisationTypeName),
      formValues: getFormValues(FORM_NAME)(state),
    };
  })(form);

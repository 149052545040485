import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormGroup } from 'react-bootstrap';
import './styles.scss';

const FormControlStatic = FormControl.Static;

export default class Endorsements extends Component {
  static propTypes = {
    endorsements: PropTypes.array.isRequired,
  };

  render() {
    const { endorsements } = this.props;

    return (
      <div>
        <h3>Endorsements</h3>
        {endorsements.map((endorsement, i) => (
          <FormGroup key={i} className="infolabel-wrapper">
            <FormControlStatic>{endorsement.title}</FormControlStatic>
          </FormGroup>
        ))}
        <div className="hr" />
      </div>
    );
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SideBar from '../components/SideBar/SideBar';

class SideBarContainer extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  render() {
    if (this.props.auth.authenticated) {
      return (
        <div className="sidebars">
          <SideBar {...this.props} />
        </div>
      );
    }
    return <div />;
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    layout: state.layout,
  };
}

export default connect(mapStateToProps)(SideBarContainer);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, set } from 'lodash';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { isOwner, currentOrganisationType } from '../../../auth/redux/authActions';
import {
  Box,
  Button,
  HorizontalFormControl,
  NameFields,
  SelectTeam,
  SelectRole,
} from '../../../../common/components';
import { Form, Col, Row, FormGroup, ControlLabel } from 'react-bootstrap';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import SelectProducts from '../../../../common/components/Selects/SelectProducts';
// import SelectOrganisations from '../../../../common/components/Selects/SelectOrganisations';

const FORM_NAME = 'userForm';

class UserForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    fields: PropTypes.object,
    submitting: PropTypes.bool.isRequired,
    resource: PropTypes.object,
    formValues: PropTypes.object,
    isInOwnerOrganisation: PropTypes.bool,
    contact: PropTypes.bool,
  };

  render() {
    const { handleSubmit, submitting, contact, isInOwnerOrganisation } = this.props;
    const resource = this.props.resource.data.attributes;
    const newType = contact ? 'Contact' : 'User';

    let autoFilters = [];
    if (!isOwner()) {
      autoFilters = [{ type: 'organisation_type', match: currentOrganisationType() }];
    } else if (this.props.location.query.organisationTypeName) {
      autoFilters = [
        {
          type: 'organisation_type',
          match: this.props.location.query.organisationTypeName,
        },
      ];
    }

    return (
      <Box>
        <h2>
          {resource.first_name ? resource.first_name + ' ' + resource.last_name : 'New ' + newType}
        </h2>
        <Form onSubmit={handleSubmit} autoComplete="off">
          <Row>
            <Col sm={12} md={12} lg={6}>
              <NameFields
                baseName="data.attributes"
                label="Name"
                hideTitle
                labelSizes={{ sm: 3, md: 4, lg: 4 }}
                fieldSizes={{ sm: 10, md: 8, lg: 8 }}
                fieldComponent={Field}
              />

              <Field
                name="data.attributes.email"
                label="Email"
                type="email"
                labelSize={4}
                component={HorizontalFormControl}
              />

              <Field
                name="data.attributes.phone1"
                label="Phone 1"
                labelSize={4}
                component={HorizontalFormControl}
              />

              <Field
                name="data.attributes.phone2"
                label="Phone 2"
                labelSize={4}
                component={HorizontalFormControl}
              />

              <Field
                name="data.attributes.position"
                label="Position"
                labelSize={4}
                component={HorizontalFormControl}
              />
            </Col>
          </Row>

          {isOwner() && this.props.resource.data.id && (
            <div>
              <div className="hr"/>
              {/*
             <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Col sm={2}>
                      <ControlLabel>Organisations</ControlLabel>
                    </Col>
                    <Col sm={8}>
                      <Field
                        name="data.relationships.organisations.data"
                        component={SelectOrganisations}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              */}
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Col sm={2}>
                      <ControlLabel>Teams</ControlLabel>
                    </Col>
                    <Col sm={8}>
                      <Field
                        name="data.relationships.teams.data"
                        component={SelectTeam}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          )}

          {!this.props.resource.data.id && (
            <div>
              <div className="hr"/>
              <Row>
                <Col xs={12}>
                  <div className="form-horizontal">
                    <Field
                      name="data.relationships.roles.data"
                      label="Roles"
                      labelSize={2}
                      autoFilters={autoFilters}
                      component={SelectRole}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          )}

          {isOwner() && !contact && isInOwnerOrganisation && (
            <div>
              <div className="hr"/>
              <FormGroup>
                <Col sm={2}>
                  <ControlLabel>Products</ControlLabel>
                </Col>
                <Col sm={8}>
                  <Field
                    name="data.relationships.available_products.data"
                    component={SelectProducts}
                  />
                </Col>
              </FormGroup>
            </div>
          )}
          <Row>
            <Col sm={12}>
              <InternalButtonBar
                buttons={[
                  {
                    permissions: ['user.edit'],
                    button: (
                      <Button
                        className="pull-right"
                        type="submit"
                        bsStyle="primary"
                        label={'Save ' + newType}
                        isLoading={submitting}
                      />
                    ),
                  },
                ]}
              />
            </Col>
          </Row>
        </Form>
      </Box>
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (!get(values, 'data.attributes.email')) {
    set(errors, 'data.attributes.email', 'Required');
  }

  if (!get(values, 'data.attributes.first_name')) {
    set(errors, 'data.attributes.first_name', 'Required');
  }

  if (!get(values, 'data.attributes.last_name')) {
    set(errors, 'data.attributes.last_name', 'Required');
  }

  if (isEmpty(get(values, 'data.relationships.roles.data', []))) {
    set(errors, 'data.relationships.roles.data', 'Required');
  }

  return errors;
};

const form = reduxForm({ form: FORM_NAME, validate })(UserForm);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  const values = selector(state, 'data.id', 'data.attributes', 'data.relationships');

  if (
    Array.isArray(props.resource.data.attributes.metadata) &&
    !props.resource.data.attributes.metadata.length
  ) {
    props.resource.data.attributes.metadata = {};
  }

  if (!get(props.resource.data.attributes.user_type)) {
    props.resource.data.attributes.user_type = props.contact ? 'contact' : 'regular';
  }

  return {
    initialValues: props.resource,
    formValues: values,
  };
};

export default connect(mapStateToProps)(form);

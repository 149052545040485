import { Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import EditDeclineForm from './EditDeclineForm/EditDeclineForm';
import EditOptionDeclineForm from './EditDeclineForm/EditOptionDeclineForm';
import { EMPTY_RULE } from '../../../helpers/declineHelpers';
import { Button, ButtonBar } from '../../../../../common/components';

const NewDeclineModal = ({
  regions,
  options,
  coverLevels,
  medicalQuestionKeys,
  onAddDecline,
  productType,
}) => {
  const [showAddDeclineModal, setShowAddDeclineModal] = useState('');

  const handleCloseModal = () => {
    setShowAddDeclineModal('');
  };
  const handleOpenOptionModal = () => {
    setShowAddDeclineModal('optionDecline');
  };
  const handleOpenModal = () => {
    setShowAddDeclineModal('decline');
  };

  const handleOnAddDecline = (values) => {
    onAddDecline(values);
    setShowAddDeclineModal('');
  };

  return (
    <>
      <ButtonBar>
        <Button bsStyle="primary" className="pull-right" handleClick={handleOpenModal}>
          Add Decline
        </Button>
        {!!options.length && (
          <Button
            bsStyle="primary"
            className="pull-right"
            handleClick={handleOpenOptionModal}
          >
            Add Option Decline
          </Button>
        )}
      </ButtonBar>

      <Modal show={showAddDeclineModal !== ''} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>New Decline</Modal.Title>
        </Modal.Header>
        {showAddDeclineModal === 'decline' && (
          <EditDeclineForm
            declines={[EMPTY_RULE]}
            reason={''}
            regions={regions}
            coverLevels={coverLevels}
            medicalQuestionKeys={medicalQuestionKeys}
            onUpdateDecline={handleOnAddDecline}
            productType={productType}
          />
        )}
        {showAddDeclineModal === 'optionDecline' && (
          <EditOptionDeclineForm
            declines={[EMPTY_RULE]}
            reason={''}
            regions={regions}
            options={options}
            coverLevels={coverLevels}
            medicalQuestionKeys={medicalQuestionKeys}
            optionName={''}
            onUpdateDecline={handleOnAddDecline}
            productType={productType}
          />
        )}
      </Modal>
    </>
  );
};

export default NewDeclineModal;

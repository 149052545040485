import { Col } from "react-bootstrap";
import React from "react";

export const Referrals = ({ show, referrals }) => {
  if (!show || !referrals?.length) {
    return null;
  }
  return (
    <Col sm={12} className="quote-divider">
      <div className="quote-title">Referral reasons:</div>
      <div className="quote-info">
        <ul>
          {referrals.map((referral, i) => (<li key={i}>{referrals.reason}</li>))},
        </ul>
      </div>
    </Col>
  );
}

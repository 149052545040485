export const SET_BROCHURE_PRODUCTS = 'socrates-online/layout/SET_BROCHURE_PRODUCTS';
export const SET_ORGANISATION_TYPES = 'socrates-online/layout/SET_ORGANISATION_TYPES';
export const SET_ACTIVE_BROCHURE_PRODUCT =
  'socrates-online/layout/SET_ACTIVE_BROCHURE_PRODUCT';

export const initialState = {
  organisationTypes: [],
  brochureProducts: [],
  activeBrochureProduct: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_ORGANISATION_TYPES:
      return {
        ...state,
        organisationTypes: action.data,
      };
    case SET_BROCHURE_PRODUCTS:
      return {
        ...state,
        brochureProducts: action.data,
      };
    case SET_ACTIVE_BROCHURE_PRODUCT:
      return {
        ...state,
        activeBrochureProduct: action.data,
      };
    default:
      return state;
  }
}

import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import { Field } from 'redux-form';
import CheckboxGroup from '../../../../common/components/ReduxFormField/CheckboxGroup';
import './Signpost.scss';


export default class Signpost extends Component {
  render() {
    const { signpost } = this.props;


    if (!signpost) {

      return null;
    }

    return (
      <div className={'signpost'}>
        <ReactMarkdown source={signpost}/>
        <Field
          name={'data.attributes.metadata.signpost_acknowledged'}
          inline
          options={[
            {
              label: '    I have read to the customer and it has been acknowledged',
              value: true,
            },
          ]}
          component={CheckboxGroup}
        />
      </div>
    );
  }
}

import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Col, Row } from 'react-bootstrap';
import { Field } from 'redux-form';
import confirm from 'react-confirm2';
import BenefitField from './BenefitField';
import { Button } from '../../../../../common/components';

const getItemStyle = (isDragging) => ({
  // change background colour if dragging
  boxShadow: isDragging && '0 4px 28px rgba(0,0,0,0.3)',
  margin: '0 0 15px 0',
  borderRadius: '4px',
});

const BenefitsFieldArray = ({
  fields,
  hasExcessWaiver,
  isViewOnly,
  options,
  coverLevels,
}) => {
  const [benefitAdded, setBenefitAdded] = useState(false);
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    fields.move(result.source.index, result.destination.index);
  };

  const handleRemoveBenefit = (index) => {
    confirm('Warning', {
      description: 'Are you sure you want to remove this benefit?',
      done: () => {
        fields.remove(index);
      },
    });
  };

  const handleAddBenefit = () => {
    if (coverLevels) {
      const coverLevelMap = coverLevels.reduce((carry, coverLevel) => {
        carry[coverLevel] = '';
        return carry;
      }, {});
      fields.push({
        name: '',
        display_name: '',
        cover_level: coverLevelMap,
        text: '',
        description: '',
        excess: '',
        sub_benefits: [],
      });
      setBenefitAdded(true);
    } else {
      fields.push({
        name: '',
        display_name: '',
        text: '',
        description: '',
        excess: '',
        sub_benefits: [],
      });
    }
    setBenefitAdded(true);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} style={getItemStyle(snapshot.isDragging)}>
              {fields.map((field, index) => {
                const isLast = index + 1 === fields.length;
                return (
                  <Draggable key={field} draggableId={`$id-${index}`} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Row className={'benefit-row'}>
                          <Field
                            name={field}
                            component={BenefitField}
                            isViewOnly={isViewOnly}
                            options={options}
                            hasExcessWaiver={hasExcessWaiver}
                            isLast={isLast && benefitAdded}
                            index={index}
                            onRemove={handleRemoveBenefit}
                            coverLevels={coverLevels}
                          />
                        </Row>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {!isViewOnly && (
        <Row>
          <Col sm={12}>
            <Button
              className="benefit-btn pull-right"
              bsStyle="primary"
              size="xs"
              handleClick={handleAddBenefit}
            >
              Add Benefit
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

export default BenefitsFieldArray;

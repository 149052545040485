export const IS_SUBMITTING = 'socrates-online/enquiry/IS_SUBMITTING';
export const SET_ENQUIRY = 'socrates-online/enquiries/SET_ENQUIRY';
export const CLEAR_ENQUIRY = 'socrates-online/enquiry/CLEAR_ENQUIRY';

export const initialState = {
  isSubmitting: false,
  resources: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.status,
      };
    case SET_ENQUIRY:
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.data.data.id]: {
            ...action.data,
          },
        },
      };
    case CLEAR_ENQUIRY:
      return initialState;
    default:
      return state;
  }
}

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { DatePicker } from '../../../../../common/components';
import TextArea from '../../../../content/components/TextArea';
import { Button } from '../../../../../common/components/Button/Button';
import { connect } from 'react-redux';

const isRequired = (value) => {
  if (value === false) {
    return null;
  }

  return !value || value.length === 0 ? 'This field is required.' : null;
};

const UpdateDynamicDocumentForm = ({
  formValues,
  handleSubmit,
  onSave,
  submitting,
  onCancel
}) => {
  const handleCancel = () => {

    onCancel();
  };

  const sections = formValues?.data?.attributes?.sections ?? {};

  return (
    <Form onSubmit={handleSubmit(onSave)}>
      <Row className="mt-15">
        <Col sm={6} className="mb-15">
          <strong>Effective date:</strong>
        </Col>
        <Col sm={6}>
          <div className="pull-right">
            <Field
              validate={isRequired}
              component={DatePicker}
              shouldCloseOnSelect={true}
              name={'data.attributes.from_date'}
            />
          </div>
        </Col>
      </Row>
      {Object.keys(sections)?.map((sectionKey) => {
        return (
          <Row className="mt-15" key={sectionKey}>
            <Col sm={12} className="mb-15">
              <strong>{sectionKey}:</strong>
            </Col>
            <Col sm={12}>
              <Field component={TextArea}
                     name={`data.attributes.sections.${sectionKey}`}/>
            </Col>
          </Row>
        );
      })}
      <Row>
        <Col sm={12}>
          <Button handleClick={handleCancel} bsStyle="default">
            cancel
          </Button>
          <Button
            type="submit"
            bsStyle="primary"
            className="pull-right"
            isLoading={submitting}
          >
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};


const FORM_NAME = 'UpdateDynamicDocument';
export default connect(
  (state, ownProps) => {
    return {
      formValues: getFormValues(FORM_NAME)(state),
      initialValues: {
        data: {
          ...ownProps.document,
          attributes: {
            ...ownProps.document.attributes,
            from_date: '',

          }
        }
      }
    };
  },
  {},
)
(reduxForm({
  form: FORM_NAME
})(UpdateDynamicDocumentForm));

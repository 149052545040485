import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, map, findIndex, isEmpty } from 'lodash';
import { reduxForm, Field, formValueSelector, change } from 'redux-form';
import { Form, FormGroup, Row, Col, ControlLabel } from 'react-bootstrap';
import { TextField } from '../../../../common/components/ReduxFormField';
import displayName from '../../../../helpers/displayName';
import {
  Box,
  Button,
  HorizontalFormControl,
  DatePicker,
} from '../../../../common/components';
import TextArea from '../TextArea/TextArea';
import { openModal } from '../../../../common/components/Modal/ModalRedux';
import UploadModalComponent, {
  UploadModal,
} from '../../../../common/components/Upload/UploadModal';
import {
  setUploadAccept,
  clearFiles,
} from '../../../../common/components/Upload/UploadRedux';
import CheckboxGroup from '../../../../common/components/ReduxFormField/CheckboxGroup';
import SelectProduct from '../../../../common/components/Selects/SelectProduct';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import './ContentForm.scss';

class ContentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadKey: null,
    };

    this.handleUpload = this.handleUpload.bind(this);
    this.handleUploadComplete = this.handleUploadComplete.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }

  resetState() {
    const { dispatch } = this.props;

    this.setState({ uploadKey: null });
    dispatch(clearFiles());
  }

  componentWillReceiveProps(newProps) {
    const { dispatch } = this.props;
    if (newProps.template.id !== this.props.template.id) {
      dispatch(
        change(FORM_NAME, 'data.relationships.template.data.id', newProps.template.id),
      );
    }
  }

  handleUploadComplete() {
    const { dispatch, resource, change } = this.props;
    const file = this.props.files[this.props.files.length - 1];

    if (!file) {
      return;
    }

    dispatch(change('data.attributes.sections.' + this.state.uploadKey, file.id));
    dispatch(
      change('data.relationships.files.data', [
        ...resource.data.relationships.files.data,
        file,
      ]),
    );

    this.resetState();
  }

  handleUpload(uploadKey) {
    return () => {
      const { dispatch } = this.props;
      dispatch(setUploadAccept('*'));
      dispatch(openModal(UploadModal.MODAL_IDENTIFIER));

      this.setState({ uploadKey });
    };
  }

  getFileName(path) {
    const { formValues } = this.props;
    const fileId = get(formValues, path);

    if (!fileId) {
      return null;
    }

    let files = [];

    if (formValues.data.relationships.files) {
      files = formValues.data.relationships.files.data.filter((file) => {
        // return file.id === fileId && file.attributes
        return file.attributes;
      });
    }

    if (files.length === 0 && formValues.included) {
      files = formValues.included.filter((file) => {
        // return file.id === fileId && file.type === 'files'
        return file.type === 'files';
      });
    }

    const formatFilesInfo = map(files, (data) => {
      return {
        name: data.attributes.name,
        ...data,
      };
    });

    // return files.length > 0 ? files[0].attributes.name : null
    return files.length > 0 ? formatFilesInfo : null;
  }

  removeFile(file) {
    const { formValues } = this.props;
    const relationshipsIndex = findIndex(
      formValues.data.relationships.files.data,
      (o) => {
        return o.id === file.id;
      },
    );
    const includedIndex = findIndex(formValues.included, (o) => {
      return o.id === file.id;
    });
    this.props.array.remove('data.relationships.files.data', relationshipsIndex);
    this.props.array.remove('included', includedIndex);
  }

  render() {
    const { template, handleSubmit, submitting, formValues, uploadAccept } = this.props;
    const includeSlug = ['article', 'page'];

    const getInputType = (key, options, name) => {
      switch (options.fieldtype) {
        case 'date':
          return (
            <div key={key} className="row form-group">
              <div className="col-sm-8 form-horizontal">
                <Field
                  name={name}
                  label={displayName(key)}
                  placeholder={displayName(key)}
                  labelSize={2}
                  component={DatePicker}
                />
              </div>
            </div>
          );

        case 'text':
        case 'event':
          return (
            <div key={key} className="row form-group">
              <div className="col-sm-8 form-horizontal">
                <Field
                  name={name}
                  label={displayName(key)}
                  placeholder={displayName(key)}
                  labelSize={2}
                  component={HorizontalFormControl}
                />
              </div>
            </div>
          );

        case 'file':
          return (
            <div key={key} className="row form-group">
              <div className="col-sm-8 form-horizontal">
                <FormGroup>
                  <Col componentClass={ControlLabel} sm={12}>
                    {displayName(key)}
                  </Col>
                  <Col sm={10}>
                    {!isEmpty(this.getFileName(name)) ? (
                      this.getFileName(name).map((file, key) => {
                        return (
                          <div key={key} className="list-group-item">
                            {file.name}
                            <i
                              className="times-icon fa fa-times pull-right"
                              onClick={() => this.removeFile(file)}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <div>There are currently no files attached.</div>
                    )}
                    &nbsp;
                    <div style={{ paddingTop: '2px' }}>
                      <Button
                        type="button"
                        bsStyle="primary"
                        className=""
                        handleClick={this.handleUpload(key)}
                        disabled={submitting}
                      >
                        {submitting ? (
                          <i className="fa fa-cog fa-spin" />
                        ) : (
                          <i className="fa fa-upload" />
                        )}{' '}
                        Upload File
                      </Button>
                    </div>
                  </Col>
                </FormGroup>
              </div>
            </div>
          );

        case 'textarea':
          return (
            <div key={key} className="row form-group">
              <div className="col-sm-8 form-horizontal">
                <Field
                  name={name}
                  label={displayName(key)}
                  placeholder={displayName(key)}
                  labelSize={2}
                  type="textarea"
                  component={TextField}
                />
              </div>
            </div>
          );

        case 'markdown':
        default:
          return (
            <div key={key}>
              <label htmlFor={key} className="control-label">
                {displayName(key)}
              </label>
              <Field
                name={name}
                label={displayName(key)}
                placeholder={displayName(key)}
                labelSize={2}
                component={TextArea}
              />
            </div>
          );
      }
    };

    return (
      <Box>
        <h2>Content Details</h2>
        {template.id ? (
          <Form className="content-form" onSubmit={handleSubmit} autoComplete="off">
            <div className="row form-group">
              <div className="col-sm-8 form-horizontal" style={{ paddingRight: '0px' }}>
                <Field
                  name="data.attributes.name"
                  label="Content"
                  placeholder="Add a unique name for this content..."
                  labelSize={2}
                  component={HorizontalFormControl}
                />
              </div>

              {/* PRIORITY OPTIONS */}
              <div className="row form-group">
                <div className="col-sm-9">
                  <div className="row form-group">
                    <div className="col-sm-10 adjust-form">
                      <div className="adjust-form-label">Priority</div>
                      <div className="adjust-form-field">
                        <Field
                          name="data.attributes.metadata.priority"
                          label=""
                          component={CheckboxGroup}
                          inline
                          labelSize={2}
                          options={[{ label: 'High Alert', value: true }]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {template.attributes.template_type === 'announcement' && (
                <div className="row form-group">
                  <div className="col-sm-9">
                    <div className="row form-group">
                      {/* <div className="col-sm-10 adjust-form">
                        <div className="adjust-form-label">
                          Type
                        </div>
                        <div className="adjust-form-field">
                          <Field
                            name="data.attributes.announcement_type"
                            label=""
                            component={RadioGroup}
                            inline
                            labelSize={3}
                            options={[
                              { label: 'Automatic', value: 'auto' },
                              { label: 'Temporary', value: 'temporary' }
                            ]}
                          />
                        </div>
                      </div> */}
                    </div>
                    <div className="row form-group">
                      <Col componentClass={ControlLabel} sm={2}>
                        <label className="date-control-label">Display Between</label>
                      </Col>
                      <Col sm={3}>
                        <Field
                          name="data.attributes.from_date"
                          label="Start date"
                          minDateToday
                          labelSize={4}
                          component={DatePicker}
                        />
                      </Col>
                      <Col sm={1}>
                        <p className="text-adjust">to</p>
                      </Col>
                      <Col sm={3}>
                        <Field
                          name="data.attributes.to_date"
                          label="End date"
                          minDate={get(formValues, 'data.attributes.from_date')}
                          labelSize={4}
                          component={DatePicker}
                        />
                      </Col>
                    </div>
                  </div>
                </div>
              )}

              {includeSlug.indexOf(template.attributes.template_type) !== -1 && (
                <div className="col-sm-8 form-horizontal">
                  <Field
                    name="data.attributes.slug"
                    label="Slug"
                    placeholder="my-article-url"
                    labelSize={2}
                    component={HorizontalFormControl}
                  />
                  <div className="help-block">
                    the Url slug can only contain letters, numbers and hyphens
                  </div>
                </div>
              )}
            </div>
            <div>
              {Object.keys(get(template, 'attributes.sections', [])).map((section) => {
                return getInputType(
                  section,
                  template.attributes.sections[section],
                  'data.attributes.sections.' + section,
                );
              })}
            </div>
            {template.attributes.template_type === 'endorsement' && (
              <div className="row form-group">
                <div className="col-sm-9">
                  <div className="row form-group">
                    <Field
                      name="data.relationships.products.data"
                      label="Products"
                      object
                      component={SelectProduct}
                      isMulti
                      labelSize={2}
                    />
                  </div>
                </div>
              </div>
            )}

            <Row>
              <Col sm={12}>
                <InternalButtonBar buttons={[
                  {
                    permissions: ['content_management.create'],
                    button: (
                      <Button
                        type="submit"
                        bsStyle="primary"
                        className="pull-right"
                        isLoading={submitting}
                        label="Save Content"
                      />
                    )
                  }
                ]}/>
              </Col>
            </Row>
          </Form>
        ) : (
          <p>Please select a template to continue...</p>
        )}

        <UploadModalComponent
          onComplete={this.handleUploadComplete}
          accept={uploadAccept}
        />
      </Box>
    );
  }
}

ContentForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  formValues: PropTypes.object,
  template: PropTypes.object.isRequired,
  resource: PropTypes.object,
};

const FORM_NAME = 'contentForm';
const form = reduxForm({ form: FORM_NAME })(ContentForm);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  const values = selector(state, 'data', 'included');

  return {
    initialValues: props.resource,
    formValues: values,
    files: state.upload.files,
    uploadAccept: state.upload.accept,
  };
};

export default connect(mapStateToProps)(form);

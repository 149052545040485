import React from 'react';
import { map } from 'lodash';
import displayName from '../../../helpers/displayName';

import { Definition } from './Definition/Definition';
import './style.scss';


const  DisplayListContent  = ({ title, contentToMap, customType }) =>{
   return (
      <div>
        {map(contentToMap, (item, key) => {
          if (customType && customType === 'cover-level') {
            return (
              <div key={key} className="section-item">
                <p>{displayName(item)}</p>
                <div className={`color-box ${item}`} />
              </div>
            );
          }

          if (customType && customType === 'country-list') {
            if (item.attributes.included) {
              return (
                <div key={key} className="section-item">
                  <p>{item.attributes.name}</p>
                </div>
              );
            }
          }

          if (customType && customType === 'medical-questions') {
            return (
              <div key={key} className="section-item">
                <p>
                  <strong>Content: </strong>
                  {item.content}
                </p>
                <p>
                  <strong>Display: </strong>
                  {item.display ? 'true' : 'false'}
                </p>
                <p>
                  <strong>Negotiable: </strong>
                  {item.negotiable ? 'true' : 'false'}
                </p>
              </div>
            );
          }

          if (customType && customType === 'definitions') {
            return (<Definition
              definition={item}
              name={key}
            />);
          }

          if (customType && customType === 'options') {
            return (
              <div key={key} className="section-item">
                <p>{item.name}</p>
              </div>
            );
          }

          if (!customType) {
            return (
              <div key={key} className="section-item">
                <p>{item}</p>
              </div>
            );
          }
        })}
      </div>
    );
  };

export default DisplayListContent;

import React, { useMemo } from 'react';
import './StatsSectionTable.scss';
import displayCurrency from '../../../../helpers/displayCurrency';

const DEFAULT_STATS  = {
  IP: 0,
  AP: 0,
  RP: 0,
}
const getStats = (stats, range) => {

  if (range === 'Today') {
    return stats?.premiums?.day ?? DEFAULT_STATS;
  }
  if (range === 'This week') {
    return stats?.premiums?.week ?? DEFAULT_STATS;
  }
  if (range === 'This month') {
    return stats?.premiums?.month ?? DEFAULT_STATS;
  }
  if (range === 'Yesterday') {
    return stats?.premiums?.yesterday ?? DEFAULT_STATS;
  }
  return stats?.premiums?.day ?? DEFAULT_STATS


}
export const StatsSectionTable = ({ stats, range }) => {


  const displayStats = useMemo(() => getStats(stats, range), [stats, range]);

  return (
    <div className="stats-section-table">
      <table className="table-dashboard">
        <tbody>
        <tr>
          <td className="caption">Initial Premium</td>
          <td>{displayCurrency(displayStats ? displayStats.IP : 0)}</td>
        </tr>
        <tr>
          <td className="caption">Additional Premium</td>
          <td>{displayCurrency(displayStats ? displayStats.AP : 0)}</td>
        </tr>
        <tr>
          <td className="caption">Return Premium</td>
          <td>{displayCurrency(displayStats ? displayStats.RP : 0)}</td>
        </tr>
        </tbody>
      </table>
    </div>
  );

}

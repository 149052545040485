import React, { useEffect } from 'react';
import { MenuItem, NavDropdown, NavItem } from 'react-bootstrap';
import { Link } from 'react-router';
import { Icon } from '../../../../common/components';
import { connect, useSelector } from 'react-redux';
import { layoutActions as actions } from '../../redux/layoutActions';
import { push } from 'react-router-redux';
const GuestNavigation = ({ onGetBrochureProducts, onSetActiveBrochureProduct, goTo }) =>  {
  useEffect(() => {
    onGetBrochureProducts();
  },[onGetBrochureProducts]);


  const brochureProducts = useSelector((state) => state.layout?.brochureProducts );
    const hasProduct = brochureProducts?.length > 0;
  const handleGoToProduct = (productId)  => {
    const product = brochureProducts?.find((product) => product.id === productId);
    onSetActiveBrochureProduct(product);
    goTo('/our-products');
  };

    return (
      <div>
        <ul className="guest-nav">
          <Link to="/" onlyActiveOnIndex>
            <li className="guest-nav--white">
              <Icon name="home" />
            </li>
          </Link>
          <Link to="/about" onlyActiveOnIndex>
            <li>About Us</li>
          </Link>
          <Link to="/get-online" onlyActiveOnIndex>
            <li>How to get Online</li>
          </Link>
          {hasProduct && (
            <NavDropdown
              eventKey={4}
              title="Products"
              id="nav-dropdown"
              className="nav-dropdown"
            >
              {brochureProducts.map((product) => {
                return (
                  <MenuItem
                    key={product.id}
                    eventKey={product.id}
                    onSelect={handleGoToProduct}
                  >
                    {product.attributes.name}
                  </MenuItem>
                );
              })}
            </NavDropdown>
          )}
          <Link to="/open-an-account" onlyActiveOnIndex>
            <NavItem eventKey={5}>Open an Account</NavItem>
          </Link>
          <Link to="/faqs" onlyActiveOnIndex>
            <NavItem eventKey={6}>FAQs</NavItem>
          </Link>
          <Link to="/contact-us" onlyActiveOnIndex>
            <li>Contact Us</li>
          </Link>{' '}
          <Link to="/privacy" onlyActiveOnIndex>
            <li>Privacy</li>
          </Link>{' '}
          <Link to="/cookies" onlyActiveOnIndex>
            <li className={'last'}>Cookies</li>
          </Link>
        </ul>
      </div>
    );
  };




export default connect(
  null,
  {
    onGetBrochureProducts: actions.getBrochureProducts,
    onSetActiveBrochureProduct: actions.setActiveBrochureProduct,
    goTo: push
  },
)(GuestNavigation);

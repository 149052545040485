import { Col } from "react-bootstrap";
import React from "react";

export const FormErrors = ({ show, formErrors }) => {
  if (!show || !formErrors) {
    return null;
  }
  const messages = Object.keys(formErrors).reduce((carry, key, i) => {
    const message = formErrors?.[key];
    if (!carry.includes(message)) {
      carry.push(message);
    }
    return carry;
  });


  return (
    <Col sm={12} className="quote-divider">
      <div className="error-box">
        <h4>Attention required</h4>
        <ul>
          {messages.map((m) => <li key={m}>{m}</li>)}
        </ul>
      </div>
    </Col>
  );

}

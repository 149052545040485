import React from 'react';
import Markdown from 'react-remarkable';
import { Col, Row } from 'react-bootstrap';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import TextArea from '../../../content/components/TextArea';
import Button from '../../../../common/components/Button/Button';
import { compose } from 'redux';
import { connect } from 'react-redux';

const DEFAULT_MESSAGE = 'No content found. Click "Edit" to add content.';

const Section = ({ showForm, label, value, name }) => {
  if (showForm) {

    return (
      <>
        <label className="control-label">{label}</label>
        <Field
          component={TextArea}
          name={name}
        />

      </>
    );
  }
  return (
    <>
      <label className="control-label">{label}</label>
      <Markdown
        source={value}
      />
    </>

  );
};

const CovidMessageContentForm = ({
  showForm,
  handleSubmit,
  isLoading,
  onSave,
  onToggleForm,
  covidMessage
}) => {

  const covidMessageContentTravelBo = covidMessage?.travel_detail_bo?.trim() ?? DEFAULT_MESSAGE;
  const covidMessageContentTravelB2c = covidMessage?.travel_detail_b2c?.trim() ?? DEFAULT_MESSAGE;
  const covidMessageContentPaymentBo = covidMessage?.payment_bo?.trim() ?? DEFAULT_MESSAGE;
  const covidMessageContentPaymentB2c = covidMessage?.payment_b2c?.trim() ?? DEFAULT_MESSAGE;


  return (
    <Form onSubmit={handleSubmit(onSave)}>


      <>
        <Section
          label={'Travel Details Back Office'}
          name={'data.attributes.metadata.covid_message.travel_detail_bo'}
          value={covidMessageContentTravelBo}
          showForm={showForm}
        />
        <Section
          label={'Travel Details B2C'}
          name={'data.attributes.metadata.covid_message.travel_detail_b2c'}
          value={covidMessageContentTravelB2c}
          showForm={showForm}
        />
        <Section
          label={'Payment Back Office'}
          name={'data.attributes.metadata.covid_message.payment_bo'}
          value={covidMessageContentPaymentBo}
          showForm={showForm}
        />

        <Section
          label={'Payment B2C'}
          name={'data.attributes.metadata.covid_message.payment_b2c'}
          value={covidMessageContentPaymentB2c}
          showForm={showForm}
        />
      </>


      <Row>
        <Col sm={12}>
          {showForm && (
            <>
              <Button type="button" bsStyle="default" handleClick={onToggleForm}>
                Cancel
              </Button>

              <Button
                type="submit"
                bsStyle="primary"
                className="pull-right"
                isLoading={isLoading}
              >
                Save
              </Button>
            </>
          )}
          {!showForm && (
            <Button
              type="button"
              bsStyle="primary"
              className="pull-right"
              handleClick={onToggleForm}
            >
              Edit
            </Button>
          )}
        </Col>
      </Row>
    </Form>
  );
};


const FORM_NAME = 'CovidMessageContentForm';
export default compose(
  connect(
    (state, ownProps) => ({
      formValues: getFormValues(FORM_NAME)(state),
      initialValues: {
        data: {
          attributes: {
            metadata: {
              covid_message: ownProps?.covidMessage ?? {
                travel_detail_b2c: '',
                payment_bo: '',
                payment_b2c: '',
                travel_detail_bo: ''

              }
            }
          }
        }
      }

    }),
  ),
  reduxForm({ form: FORM_NAME })
)(CovidMessageContentForm);


import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';
import MultiSelectMenu from '../SelectMenu/MultiSelectMenu';

export const SELECT_IDENTIFIER = 'document';

class SelectDocuments extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    label: PropTypes.string,
    labelSize: PropTypes.number,
    input: PropTypes.object.isRequired,
    infoButton: PropTypes.element,
    name: PropTypes.string,
    meta: PropTypes.object,
    filters: PropTypes.array,
  };

  renderSelectMenu() {
    let source = 'products/schemes/documents';

    return (
      <MultiSelectMenu
        source={source}
        sourceKey={'MultiDocuments'}
        isClearable
        isObject
        {...this.props}
      />
    );
  }
  render() {
    const { infoButton, meta, label, labelSize, smFieldSize } = this.props;

    if (label) {
      const labelSmSize = labelSize ? labelSize : 2;
      const menuSize = smFieldSize ? smFieldSize : !label ? 12 : 12 - labelSmSize;
      return (
        <FormGroup
          controlId={name}
          className={meta && meta.touched && meta.error ? ' has-error' : ''}
        >
          {label && (
            <Col sm={labelSmSize}>
              <ControlLabel>
                {label} {infoButton}
              </ControlLabel>
            </Col>
          )}
          <Col sm={menuSize}>
            {this.renderSelectMenu()}

            {meta && meta.touched && meta.error && (
              <span className="input-error">{meta.error}</span>
            )}
          </Col>
        </FormGroup>
      );
    }
    return this.renderSelectMenu();
  }
}

export default connect()(SelectDocuments);

import React from 'react';
import BenefitsModal from '../../../BenefitsModal/BenefitsModal';
import PremiumBreakdown from '../../../PremiumBreakdown/PremiumBreakdown';
import { Icon } from '../../../../../../common/components';


import './SelectedQuotes.scss';

// TODO refactor into smaller components
const SelectedQuotes = ({ show, quotes, selectedSchemes, isReferralRequired }) => {
  if (!show) {
    return null;
  }

  const POLICY_WORDING = 'Policy Wording';
  const TERMS_OF_BUSINESS = 'Terms of Business Customer Agreement';

  const singleSchemeId = selectedSchemes?.length === 1 ? selectedSchemes?.[0] : undefined;
  const selectedScheme = quotes?.[singleSchemeId];
  const PRIVACY_STATEMENT = 'Privacy Statement';

  return (
    <div className="wl-selected-quotes">
      {!!selectedSchemes?.length && !isReferralRequired && (
        <div className="quote-actions">
          <div className="row">
            <div className="col-xs-11 col-sm-12 scheme-for-purchase">
              <div className="row">
                <div className="col-xs-12">
                  {selectedScheme ? (
                    <div className="row">
                      <div className="col-md-7">
                        <h5>You have selected:</h5>
                        <p className="scheme-name">{selectedScheme.meta.scheme.name}</p>
                        <div className="wordings-for-purchase">
                            <span className="info">
                              <BenefitsModal
                                scheme={selectedScheme.meta.scheme}
                                benefits={selectedScheme.meta.benefits}
                                label="View Full Benefits"
                              />
                            </span>
                          {selectedScheme.meta.scheme.documents[TERMS_OF_BUSINESS] ? (
                            <span
                              className="info"
                              onClick={() => {
                                window.open(
                                  selectedScheme.meta.scheme.documents[TERMS_OF_BUSINESS],
                                );
                              }}
                            >
                                <Icon name="info-circle"/> Terms of Business
                              </span>
                          ) : (
                            <span
                              className="info"
                              onClick={() => {
                                window.open(
                                  selectedScheme.meta.scheme.documents[PRIVACY_STATEMENT],
                                );
                              }}
                            >
                                <Icon name="info-circle"/> Privacy Statement
                              </span>
                          )}
                          <span
                            className="info"
                            onClick={() => {
                              window.open(
                                selectedScheme.meta.scheme.documents[POLICY_WORDING],
                              );
                            }}
                          >
                              <Icon name="info-circle"/> View Policy wording
                            </span>
                        </div>
                      </div>
                      <div className="col-md-5 text-right">
                        <PremiumBreakdown
                          premium={selectedScheme.meta}
                          id={'selected-quotes' + selectedScheme.meta.scheme.id}
                          block
                          showIpt
                          taxType={'IPT'}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <h5>Your chosen premiums</h5>
                      <ul className="text-left selected-premiums-list">
                        {selectedSchemes.map((scheme, i) => {
                          const selection = quotes?.[scheme];
                          if (selection) {
                            return (
                              <li key={i}>
                                {selection.meta.scheme.name}
                                <span className="selected-quote-premium">
                                    <PremiumBreakdown
                                      premium={selection.meta}
                                      //id={selection.meta.scheme}
                                      className="small-premium"
                                      showIpt
                                      taxType={'IPT'}
                                    />
                                  </span>
                              </li>
                            );
                          }
                        })}
                      </ul>

                      <span className="selection-note">
                          <strong>Please note:</strong> you must select 1 scheme only from
                          the panel in order to issue a policy.
                        </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectedQuotes;

import pluralize from "pluralize";
import { formatDateString } from "../../../../../../helpers/formatDate";

export const getDisplayName = (options, value) => {
  const matchedOption = options?.find((v) => v.value === value);
  return matchedOption?.label ?? value;
};

export const getDestinations = (destinations, region, options) => {
  if (destinations?.length) {
    return destinations?.join(', ')
  }
  if (region) {
    return getDisplayName(options, region);
  }
  return ''

}

export const getOptions = (additionalOptions, options) => {

  if (!additionalOptions) {
    return '';
  }
  const optionNames = Object.keys(additionalOptions)?.reduce((carry, opt) => {
    const isSelected = additionalOptions[opt];

    if (!isSelected) {
      return carry;
    }
    const matched = options.find((o) => o.key === opt);

    carry.push(matched?.value?.name ?? opt)
    return carry;
  }, [])
  return optionNames?.join((', '))
}


export const getTravellerNumbers = (travellerSize, label) => {
  if (!travellerSize) {
    return '';
  }
  return ` ${travellerSize} x ${pluralize(label, travellerSize)}`;
}

export const getCoverDates = (startDate, endDate) => {

  if (!startDate) {
    return '';
  }

  if (!endDate) {
    return formatDateString(startDate)
  }
  return `${formatDateString(startDate)} to ${formatDateString(endDate)}`;
}


export const getMaxTripDuration = (formMaxTrip, schemeMaxTrip) => {
  if (schemeMaxTrip) {
    return schemeMaxTrip
  }
  return formMaxTrip
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get, map } from 'lodash';
import displayName from '../../../../helpers/displayName';
import { Button, InfoLabel } from '../../../../common/components';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import './CampaignDetails.scss';
import UrlLabel from './UrlLabel';

/******************************
 Campaign Details - Read Only
 *****************************/

class CampaignDetails extends Component {
  render() {
    const { resource: fullResource, initialValues } = this.props;
    const urls = get(fullResource, 'data.attributes.urls');
    const channels = getIncludedResource(
      initialValues.data,
      initialValues.included,
      'channels',
    );
    const product = getIncludedResource(
      initialValues.data,
      initialValues.included,
      'product',
    );
    const brokers = getIncludedResource(
      fullResource.data,
      fullResource.included,
      'brokers',
    );
    const resource = get(fullResource, 'data.attributes');

    return (
      <div>
        <div className="campaign-details-header">
          <h4>Campaign Details</h4>
        </div>
        <div className="campaign-details-container">
          <form autoComplete="off">
            <div className="row">
              <div className="col-sm-6">
                <div className="form-horizontal">
                  <Field
                    name="data.attributes.name"
                    label="Name"
                    labelSize={4}
                    component={InfoLabel}
                  />
                  <Field
                    name="data.attributes.code"
                    label="Voucher Code"
                    labelSize={4}
                    component={InfoLabel}
                  />
                  <InfoLabel
                    date
                    name="data.attributes.updated_at"
                    label="Created"
                    labelSize={4}
                    value={get(fullResource, 'data.attributes.updated_at')}
                  />
                  <InfoLabel
                    name="data.attributes.has_url"
                    label="Has url?"
                    labelSize={4}
                    value={get(resource, 'has_url', true) ? 'Yes' : 'No'}
                  />
                  <UrlLabel urls={urls} brokers={brokers} fullResource={fullResource} />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-horizontal">
                  <InfoLabel
                    name="data.attributes.discount_type"
                    label="Discount Type"
                    value={get(fullResource, 'data.attributes.discount_type')}
                    labelSize={4}
                  />
                  {get(fullResource, 'data.attributes.discount') > 0 && (
                    <Field
                      name="data.attributes.discount"
                      label="Discount"
                      labelSize={4}
                      object
                      mdFieldSize={4}
                      addonAfter={'%'}
                      component={InfoLabel}
                    />
                  )}
                  {get(
                    fullResource,
                    'data.attributes.premium_overrides.commissions.commission_rate',
                  ) > 0 && (
                    <Field
                      name="data.attributes.premium_overrides.commissions.commission_rate"
                      label="Total Commission Override"
                      labelSize={4}
                      object
                      mdFieldSize={4}
                      addonAfter={'%'}
                      component={InfoLabel}
                    />
                  )}
                  {get(
                    fullResource,
                    'data.attributes.premium_overrides.commissions.commission_broker',
                  ) > 0 && (
                    <Field
                      name="data.attributes.premium_overrides.commissions.commission_broker"
                      label="Broker Commission Override"
                      labelSize={4}
                      object
                      mdFieldSize={4}
                      addonAfter={'%'}
                      component={InfoLabel}
                    />
                  )}
                  {get(
                    fullResource,
                    'data.attributes.premium_overrides.commissions.commission_owner',
                  ) > 0 && (
                    <Field
                      name="data.attributes.premium_overrides.commissions.commission_owner"
                      label="PJH Commission Override"
                      labelSize={4}
                      object
                      mdFieldSize={4}
                      addonAfter={'%'}
                      component={InfoLabel}
                    />
                  )}
                  <InfoLabel
                    value={
                      get(fullResource, 'data.attributes.exclusive') === true
                        ? 'Yes'
                        : 'No'
                    }
                    label="Exclusive"
                    labelSize={4}
                    mdFieldSize={7}
                  />
                  <Field
                    name="data.attributes.from_date"
                    dateTime
                    label="Start Date"
                    labelSize={4}
                    mdFieldSize={7}
                    component={InfoLabel}
                  />
                  <Field
                    name="data.attributes.to_date"
                    dateTime
                    label="End Date"
                    labelSize={4}
                    mdFieldSize={7}
                    component={InfoLabel}
                  />
                </div>
              </div>
            </div>
            <hr id="second-row" />
            <div className="row">
              <div className="col-sm-6">
                <div className="form-horizontal">
                  <InfoLabel
                    label="Channels"
                    labelSize={4}
                    value={map(channels, (channel, i) => {
                      let detail = i ? ', ' : '';
                      return channel.attributes
                        ? detail + channel.attributes.name
                        : detail + channel;
                    })}
                  />
                  <InfoLabel
                    label="Product"
                    labelSize={4}
                    value={get(product, 'data.attributes.name')}
                  />
                  <InfoLabel
                    label="Brokers"
                    labelSize={4}
                    value={map(brokers, (broker, i) => {
                      let detail = i ? ', ' : '';
                      return detail + get(broker, 'attributes.name');
                    })}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-horizontal">
                  <InfoLabel
                    name="data.attributes.criterion.scheme_type.equal"
                    label="Scheme Type"
                    value={displayName(
                      get(fullResource, 'data.attributes.criterion.scheme_type.equal'),
                    )}
                    labelSize={4}
                  />
                  <InfoLabel
                    name="data.attributes.criterion.cover_level.in"
                    label="Cover Level"
                    labelSize={4}
                    value={map(
                      get(resource, 'criterion.cover_level.in', []),
                      (cover, i) => {
                        let detail = i ? ', ' : '';
                        return cover.attributes
                          ? detail + displayName(cover.attributes.name)
                          : detail + displayName(cover);
                      },
                    )}
                  />
                </div>
              </div>
            </div>
            <hr id="third-row" />
            <div className="row">
              <div className="col-sm-6">
                <div className="form-horizontal">
                  <InfoLabel
                    label="Family Group"
                    labelSize={4}
                    name="data.attributes.criterion.traveller_group.in"
                    value={map(
                      get(resource, 'criterion.traveller_group.in', []),
                      (traveller, i) => {
                        let detail = i ? ', ' : '';
                        return traveller.attributes
                          ? detail + displayName(traveller.attributes.name)
                          : detail + displayName(traveller);
                      },
                    )}
                  />
                  <InfoLabel
                    label="Destinations"
                    labelSize={4}
                    name="data.attributes.criterion.region.in"
                    value={map(
                      get(resource, 'criterion.region.in', []),
                      (destination, i) => {
                        let detail = i ? ', ' : '';
                        return destination.attributes
                          ? detail + displayName(destination.attributes.name)
                          : detail + displayName(destination);
                      },
                    )}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-horizontal">
                  <FormGroup controlId="age">
                    <Col componentClass={ControlLabel} sm={4}>
                      Target Ages
                    </Col>
                    <Col sm={3}>
                      <Field
                        name="data.attributes.criterion.max_age.min"
                        type="text"
                        id="ageStart"
                        component={InfoLabel}
                      />
                    </Col>
                    {get(
                      this.props.formValues,
                      'data.attributes.criterion.max_age.max',
                    ) && (
                      <Col sm={1}>
                        <p className="text-adjust">to</p>
                      </Col>
                    )}
                    <Col sm={3}>
                      <Field
                        name="data.attributes.criterion.max_age.max"
                        type="text"
                        id="ageEnd"
                        component={InfoLabel}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="range-fields">
                    <Col componentClass={ControlLabel} sm={4}>
                      Target Duration
                    </Col>
                    <Col sm={3}>
                      <Field
                        name="data.attributes.criterion.duration.min"
                        type="text"
                        component={InfoLabel}
                      />
                    </Col>
                    {get(
                      this.props.formValues,
                      'data.attributes.criterion.duration.max',
                    ) && (
                      <Col sm={1}>
                        <p className="text-adjust">to</p>
                      </Col>
                    )}
                    <Col sm={3}>
                      <Field
                        name="data.attributes.criterion.duration.max"
                        type="text"
                        component={InfoLabel}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="">
                    <Col componentClass={ControlLabel} sm={4}>
                      Target Healix Scores
                    </Col>
                    <Col sm={3}>
                      <Field
                        name="data.attributes.criterion.max_score.min"
                        type="text"
                        id="healixStart"
                        component={InfoLabel}
                      />
                    </Col>
                    {get(
                      this.props.formValues,
                      'data.attributes.criterion.max_score.max',
                    ) && (
                      <Col sm={1}>
                        <p className="text-adjust">to</p>
                      </Col>
                    )}
                    <Col sm={3}>
                      <Field
                        name="data.attributes.criterion.max_score.max"
                        type="text"
                        id="healixEnd"
                        component={InfoLabel}
                      />
                    </Col>
                  </FormGroup>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="row actions">
          <div className="clearfix">
            <Button
              link
              label="Create New Version"
              bsStyle="primary"
              to={'/admin/campaigns/' + fullResource.data.id + '/edit'}
              rightIcon="plus-circle"
              className="pull-right"
            />
          </div>
        </div>
      </div>
    );
  }
}

const FORM_NAME = 'CampaignDetails';
const form = reduxForm({ form: FORM_NAME })(CampaignDetails);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  if (
    Array.isArray(props.resource.data.attributes.metadata) &&
    !props.resource.data.attributes.metadata.length
  ) {
    props.resource.data.attributes.metadata = {};
  }

  const initialValues = props.resource;
  const values = selector(state, 'data', 'metadata');
  return {
    initialValues,
    formValues: values,
  };
};

export default connect(mapStateToProps)(form);

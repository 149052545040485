import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getContent } from '../redux/contentActions';
import { Button, Loader } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import ContentFormContainer from './ContentForm';
import { get, set } from 'lodash';

class ContentEditContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    content: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
  };

  componentWillMount() {
    this.props.dispatch(getContent(this.props.params.id));
  }

  render() {
    const resource = this.props.content.resources[this.props.params.id];
    const template = resource ? resource.included[0] : {};
    const buttons = (
      <Button bsStyle="default" label="back" isBackButton leftIcon="caret-left" />
    );

    if (
      get(resource, 'data.attributes.duration') &&
      get(resource, 'data.attributes.event')
    ) {
      set(resource, 'data.attributes.announcement_type', 'auto');
    } else if (
      get(resource, 'data.attributes.from_date') &&
      get(resource, 'data.attributes.to_date')
    ) {
      set(resource, 'data.attributes.announcement_type', 'temporary');
    }

    return (
      <InternalContainer title="Content Management" buttons={buttons}>
        {resource ? (
          <ContentFormContainer {...this.props} resource={resource} template={template} />
        ) : (
          <Loader />
        )}
      </InternalContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    content: state.content,
  };
}

export default connect(mapStateToProps)(ContentEditContainer);

import { Modal } from 'react-bootstrap';

import { Button } from '../../../../common/components';
import React from 'react';
import SupersedingDocumentListItemContainer
  from '../SupersedingDocumentListItemContainer/SupersedingDocumentListItemContainer';
import './ViewSupersedingDocumentModal.scss';

const ViewSupersedingDocumentModal = ({
  show,
  supersedingDocuments,
  close,
  isGenerating,
  documentsGenerated,
}) => {
  return (
    <>
      <Modal bsSize="large" show={show} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Superseding Documents</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="superseded-documents-modal modal-body-with-max-height">
            {supersedingDocuments.map((document, key) => (
              <div>
                <SupersedingDocumentListItemContainer
                  document={document}
                  key={key}
                  isGenerating={isGenerating}
                  documentsGenerated={documentsGenerated}
                />
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button handleClick={close}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ViewSupersedingDocumentModal;

import axios from 'axios';
import { encrypt, decrypt } from './encryption';

const oauthClient = {
  client_id: process.env.OAUTH_CLIENT_ID,
  client_secret: process.env.OAUTH_CLIENT_SECRET,
};

export function getAccessToken(data) {
  const requestData = {
    ...oauthClient,
    ...data,
    scope: 'back-office',
  };

  return axios.post('/oauth/access_token', requestData);
}

export function revokeAccessToken(data) {
  return axios.post('/oauth/revoke', data);
}

export function storeOAuthData(res, { access_token, refresh_token }) {
  const cookieValue = JSON.stringify({
    access_token,
    refresh_token,
  });

  // cookie expires in 90 minutes
  const cookieOptions = {
    maxAge: 90 * 60 * 1000,
    httpOnly: true,
  };
  if (process.env.NODE_ENV !== 'development') {
    cookieOptions['secure'] = true;
  }
  res.cookie('oauth-secrets', encrypt(cookieValue), cookieOptions);
}

export function getOAuthData(req) {
  try {
    const cookieValue = JSON.parse(decrypt(req.cookies['oauth-secrets']));

    return cookieValue;
  } catch (e) {
    console.log('Invalid cookie data');
    // Invalid cookie ciphertext
    // Ignore and handle below
    return {};
  }
}

import Axios from 'axios';
import * as documentManagementActions from './documentManagementReducer';
import { toastr } from 'react-redux-toastr';
import handleErrorResponse from '../../../helpers/handleErrorResponse';
import queryString from '../../../helpers/queryString';

const isLoading = (state) => ({
  type: documentManagementActions.IS_LOADING,
  status: state
});

const getDocumentsByType = (productId, documentTypeId = null, insurerId) => {
  return (dispatch) => {
    dispatch(isRetrievingDocuments(true));
    dispatch(setDocumentSummaryList(documentTypeId, {}));

    const filters = [];
    if (insurerId) {
      filters.push(`filter[schemes.insurer]=${insurerId}`);
    }
    if (productId) {
      filters.push(`filter[product]=${productId}`);
    }
    return Axios.get(`/documents/document-types/${documentTypeId}/?${filters.join('&')}`)
      .then((response) => {
        dispatch(setDocumentSummaryList(documentTypeId, response.data.meta));
        dispatch(isLoading(false));
        return true;
      })
      .catch(() => {
        dispatch(isLoading(false));
      })
      .finally(() => {
        dispatch(isRetrievingDocuments(false));
      });
  };
};

const isRetrievingDocuments = (state) => ({
  type: documentManagementActions.IS_RETRIEVING_DOCUMENTS,
  data: state
});
const isSubmitting = (state) => ({
  type: documentManagementActions.IS_SUBMITTING,
  data: state
});

const getDocumentTypes = (productId) => {
  return (dispatch) => {
    dispatch(isLoading(true));
    return Axios.get(`/documents/document-types?filter[product]=${productId}`)
      .then((response) => {
        dispatch({
          type: documentManagementActions.SET_PRODUCT_DOCUMENT_TYPE_LIST,
          data: response.data.data
        });
        return true;
      })
      .finally(() => {
        dispatch(isLoading(false));
      });
  };
};
const setDocumentSummaryList = (documentTypeId, summary) => {
  return {
    type: documentManagementActions.SET_DOCUMENT_SUMMARY_LIST,
    data: { documentTypeId: documentTypeId, summary: summary },
  };
};

const getDocument = (documentId) => {
  return (dispatch) => {
    return Axios.get(
      `/documents/${documentId}?include=schemes,channel,supersedes,ancillary_schemes,products`,
    )
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        dispatch(isLoading(false));
      });
  };
};
const createNewDocument = (values, productId) => {
  return (dispatch) => {
    dispatch(isSubmitting(true));
    return Axios.post('documents', values)
      .then(() => {
        toastr.success('Success', 'Document has been saved');

        dispatch(isSubmitting(false));
        dispatch(clearDocumentManagementData());
        dispatch(closeDocumentModal());
        dispatch(getDocumentTypes(productId));
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'There was an error saving this document');
      });
  };
};
// const viewOrEditDocument = (action, document) => ({
//   type: documentManagementActions.VIEW_OR_EDIT_DOCUMENT,
//   data: { action, document },
// });
const closeDocumentModal = () => ({ type: documentManagementActions.CLOSE_DOCUMENT_MODAL });
const clearDocumentManagementData = () => ({ type: documentManagementActions.CLEAR_DOCUMENT_MANAGEMENT_DATA });
const updateDocument = (values, documentId, productId) => {
  return (dispatch) => {
    dispatch(isSubmitting(true));
    return Axios.patch(`documents/${documentId}`, values)
      .then(() => {
        toastr.success('Success', 'A New version has been created');

        dispatch(isSubmitting(false));
        dispatch(clearDocumentManagementData());
        dispatch(closeDocumentModal());
        dispatch(getDocumentTypes(productId));
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'There was an error updating this document');
      });
  };
};
const clearDynamicDocuments = () => ({ type: documentManagementActions.CLEAR_DYNAMIC_DOCUMENT_DATA });
const updateDynamicDocument = (resource, includes = ['template', 'files']) => {
  return (dispatch) => {
    dispatch(isLoading(true));
    const endpoint = 'contents/' + resource.data.id + queryString(includes);

    return Axios.patch(endpoint, resource)
      .then(() => {
        dispatch(isLoading(false));
        toastr.success('Success', 'Document has been updated');
        dispatch(clearDynamicDocuments());
        //dispatch(getDynamicDocuments(productId));
        return true;
      })
      .catch((error) => {
        dispatch(isLoading(false));
        handleErrorResponse(error, 'There was an error updating your content');
      });
  };
};
const updateDocumentPacks = (packs, productId) => {
  return () => {
    return Axios.patch(
      'products/' +
      productId +
      '/document-packs' +
      queryString(['document_packs.scheme_documents']),
      packs,
    )
      .then(() => {
        toastr.success('Success', 'Your changes have been saved');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(
          error,
          'There was an error updating the product document packs',
        );
      });
  };
};

const getDynamicDocuments = (productId) => {
  return (dispatch) => {
    dispatch(isLoading(true));
    return Axios.get(
      `/contents/?page[limit]=100&filter[template.type]=document&filter[status]=active,pending,ended&filter[product]=${productId}&include=products`,
    ).then((response) => {

      dispatch({
        type: documentManagementActions.SET_DYNAMIC_DOCUMENTS_LIST,
        data: {
          data: response.data.data,
          productId: productId,
        },
      });
    }).finally(() => {
      dispatch(isLoading(false));
    });
  };
};
const viewDocument = (action, document) => ({
  type: documentManagementActions.VIEW_DOCUMENT,
  data: { action, document },
});

const editDocument = (action, document) => ({
  type: documentManagementActions.EDIT_DOCUMENT,
  data: { action, document },
});

const closeViewDocumentModal = () => ({ type: documentManagementActions.CLOSE_VIEW_DOCUMENT_MODAL });
const closeEditDocumentModal = () => ({ type: documentManagementActions.CLOSE_EDIT_DOCUMENT_MODAL });


export const actions = {

  closeViewDocumentModal,
  closeEditDocumentModal,
  updateDynamicDocument,
  getDynamicDocuments,
  updateDocumentPacks,
  getDocumentsByType,
  getDocument,
  viewDocument,
  editDocument,


  createNewDocument,
  getDocumentTypes,

  closeDocumentModal,
  updateDocument,


};

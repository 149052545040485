import React from 'react';
import PropTypes from 'prop-types';
import './icons.scss';

const Icon = ({ name, size, spin, align }) => {
  const iconClass =
    'fa fa-' +
    name +
    (size ? ' ' + size : '') +
    (spin ? (name === 'spinner' ? ' fa-pulse fa-fw' : ' fa-spin fa-fw') : '') +
    (align ? ' ' + align : '');

  return (
    <span className="icon">
      <i className={iconClass} />
    </span>
  );
};

Icon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
  spin: PropTypes.bool,
  align: PropTypes.string,
};

export default Icon;

import React from 'react';
import Select from 'react-select';

const PlainSelectField = ({
  input,
  id = 'plain_select_field',
  placeholder,
  options,
  meta: { touched, error },
}) => (
  <div className={touched && error ? ' has-error' : ''}>
    <Select
      {...input}
      id={id}
      value={options.find((opt) => {
        return opt.value === input.value.value;
      })}
      placeholder={placeholder || 'Select...'}
      onBlur={() => (input.onBlur ? input.onBlur(input.value) : '')}
      onChange={(data) => input.onChange(data ? data.value : '')}
      options={options}
    />

    {touched && error && <span className="input-error">{error}</span>}
  </div>
);

export default PlainSelectField;

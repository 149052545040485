import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { actions } from '../../redux/ProductReducer';
import { Loader } from '../../../../common/components';
import InternalContainer from '../../../layout/containers/Internal';
import ProductListItem from './components/ProductListItem/ProductListItem';

const ProductList = ({ onGetProducts, onClearData }) => {
  useEffect(() => {
    onGetProducts([], [{ attr: 'status', value: 'active' }]);

    return () => {
      onClearData();
    };
  }, [onGetProducts, onClearData]);


  const { products } = useSelector((state) => state?.productManagement);

  if (!products?.length) {
    return (
      <div className="admin-area product-container">
        <InternalContainer title="Product management">
          <Loader/>
        </InternalContainer>
      </div>
    );
  }

  return (
    <div className="admin-area product-container">
      <InternalContainer title="Product management">
        <div className="animated fadeIn">
          {
            products?.map((product) => (
              <ProductListItem key={product.id} product={product}/>
            ))
          }

        </div>
      </InternalContainer>
    </div>
  );
};

export default connect(
  null,
  {
    onGetProducts: actions.getProducts,
    onClearData: actions.clearData

  }
)(ProductList);

import React, { useEffect, useState } from 'react';
import { Box, Button, Loader } from '../../../../common/components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSignposts } from '../../redux/contentActions';
import ReactMarkdown from 'react-markdown';
import './SignpostList.scss';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';

const TITLES = {
  medical_declines_b2c: 'B2C Medical Declines Signpost',
  medical_premiums_b2c: 'B2C Medical Premiums Signpost',
  screening_errors_b2c: 'B2C Screening Error Signpost',
  medical_declines_bo: 'Back Office Medical Declines Signpost',
  medical_premiums_bo: 'Back Office Medical Premiums Signpost',
  screening_errors_bo: 'Back Office Screening Error Signpost',
};

const Signpost = ({ name, content, id }) => (
  <div className={'signpost-list-item'}>

    <div className={'signpost-list-item--title'}>
      {TITLES[name]}

    </div>
    <div className={'signpost-list-item--content'}>
      <ReactMarkdown
        source={content}
      />
    </div>

    <InternalButtonBar
      buttons={[
        {
          permissions: ['content_management.edit'],
          button: (
            <Button
              link={true}
              to={'/admin/content/signpost/' + id + '/edit'}
              className="pull-right"
              bsStyle="primary"
              rightIcon="pencil-square-o"
              label="edit signpost"
            />
          ),
        },
      ]}
    />
  </div>

);
const SignpostList = ({ getSignposts, isFetching }) => {

  const sortSignposts = (signposts) => {

    return signposts.reduce((carry, signpost) => {
      if (signpost.attributes.name.includes('bo')) {
        carry.bo.push(signpost);
      } else {
        carry.b2c.push(signpost);
      }
      return carry;
    }, {
      bo: [],
      b2c: []
    });

  };

  const [signposts, setSignposts] = useState(null);
  useEffect(() => {
    Promise.resolve(getSignposts()).then((resp) => {
      setSignposts(sortSignposts(resp));
    });
  }, [getSignposts]);

  if (isFetching && !signposts) {
    return <Loader/>;
  }
  if (!isFetching && !signposts) {
    return (
      <Box>
        No Signposts found
      </Box>
    );
  }


  return (
    <Box>
      {signposts.bo.map((signpost) => (
        <Signpost
          name={signpost.attributes.name}
          content={signpost.attributes.content}
          id={signpost.id}
        />

      ))}

      {signposts.b2c.map((signpost) => (
        <Signpost
          name={signpost.attributes.name}
          content={signpost.attributes.content}
          id={signpost.id}
        />
      ))}
    </Box>
  );

};


export default connect(
  (state) => ({
    isFetching: state.content.isFetchingSignposts,
  }),
  (dispatch) => ({
    getSignposts: bindActionCreators(getSignposts, dispatch),
  }),
)(SignpostList);


import React, { Component } from 'react';
import {
  Entity,
  Editor,
  EditorState,
  RichUtils,
  CompositeDecorator,
  SelectionState,
} from 'draft-js';
import { stateFromMarkdown } from 'draft-js-import-markdown';
import { stateToMarkdown } from 'draft-js-export-markdown';
import StyleButton from './StyleButton';
import './TextArea.scss';

// Helpful reading
// https://medium.com/@rajaraodv/how-draft-js-represents-rich-text-data-eeabb5f25cf2#.4bfyklhz5

const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one' },
  { label: 'H2', style: 'header-two' },
  { label: 'H3', style: 'header-three' },
  { label: 'H4', style: 'header-four' },
  { label: 'H5', style: 'header-five' },
  { label: 'H6', style: 'header-six' },
  { label: 'Blockquote', style: 'blockquote' },
  { label: 'UL', style: 'unordered-list-item' },
  { label: 'OL', style: 'ordered-list-item' },
];

const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
];

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    default:
      return null;
  }
}

const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls block">
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

const InlineStyleControls = (props) => {
  var currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

const findLinkEntities = (contentBlock, callback) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null && Entity.get(entityKey).getType() === 'LINK';
  }, callback);
};

export default class TextArea extends Component {
  constructor(props) {
    super(props);
    const decorator = new CompositeDecorator([
      {
        strategy: findLinkEntities,
        component: ::this.LinkElement,
      },
    ]);
    this.state = {
      editorState: EditorState.createWithContent(
        stateFromMarkdown(props.input.value || ''),
        decorator,
      ),
      showURLInput: false,
      urlEntity: null,
      urlValue: '',
    };
    this.onChange = (editorState) => {
      this.setState({ editorState }, () => {
        if (this.props.input.onChange) {
          this.props.input.onChange(stateToMarkdown(editorState.getCurrentContent()));
        }
      });
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.input.value != stateToMarkdown(this.state.editorState.getCurrentContent())
    ) {
      const newContentState = stateFromMarkdown(nextProps.input.value || '');
      const editorState = EditorState.push(this.state.editorState, newContentState);
      this.setState({ editorState });
    }
  }

  LinkElement(props) {
    const { url } = Entity.get(props.entityKey).getData();
    return (
      <a
        className="RichEditor-linkElement"
        onClick={() => {
          if (!this.state.showURLInput) {
            this.promptForLink(props.entityKey);
          }
        }}
        href={url}
      >
        {props.children}
      </a>
    );
  }

  handleKeyCommand(command) {
    const { editorState } = this.state;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }

  toggleBlockType(blockType) {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  }

  toggleInlineStyle(inlineStyle) {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle));
  }

  promptForLink(entityKey) {
    const { editorState } = this.state;
    const selection = editorState.getSelection();

    if (entityKey) {
      const { url } = Entity.get(entityKey).getData();
      this.setState(
        {
          showURLInput: true,
          urlEntity: entityKey,
          urlValue: url,
        },
        () => {
          setTimeout(() => this.refs.url.focus(), 0);
        },
      );
    } else if (!selection.isCollapsed()) {
      this.setState(
        {
          showURLInput: true,
          urlEntity: null,
          urlValue: '',
        },
        () => {
          setTimeout(() => this.refs.url.focus(), 0);
        },
      );
    } else {
      // Toggle link editor off
      this.setState({
        showURLInput: false,
        urlEntity: null,
        urlValue: '',
      });
    }
  }

  confirmLink(e) {
    e.preventDefault();
    const { editorState, urlEntity, urlValue } = this.state;
    let entityKey = urlEntity;

    if (entityKey) {
      Entity.mergeData(entityKey, { url: urlValue });
    } else {
      entityKey = Entity.create('LINK', 'MUTABLE', { url: urlValue });
    }

    this.setState(
      {
        editorState: RichUtils.toggleLink(
          editorState,
          editorState.getSelection(),
          entityKey,
        ),
        showURLInput: false,
        urlEntity: null,
        urlValue: '',
      },
      () => {
        setTimeout(() => this.refs.editor.focus(), 0);
      },
    );
  }

  onLinkInputKeyDown(e) {
    if (e.which === 13) {
      this.confirmLink(e);
    }
  }

  removeLink(e) {
    e.preventDefault();
    const { editorState, urlEntity } = this.state;
    const selection = editorState.getSelection();
    let entityKey = urlEntity;

    if (entityKey) {
      const currentKey = editorState.getSelection().getAnchorKey();
      const currentBlock = editorState.getCurrentContent().getBlockForKey(currentKey);
      const selectionState = SelectionState.createEmpty();
      const entireBlockSelectionState = selectionState.merge({
        anchorKey: currentKey,
        anchorOffset: 0,
        focusKey: currentKey,
        focusOffset: currentBlock.getText().length,
      });

      this.setState({
        editorState: RichUtils.toggleLink(editorState, entireBlockSelectionState, null),
        showURLInput: false,
        urlEntity: null,
        urlValue: '',
      });
    } else {
      if (!selection.isCollapsed()) {
        this.setState({
          editorState: RichUtils.toggleLink(editorState, selection, null),
          showURLInput: false,
          urlEntity: null,
          urlValue: '',
        });
      }
    }
  }

  render() {
    const { editorState } = this.state;
    const { readOnly } = this.props;

    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = 'RichEditor-editor';
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (
        contentState
          .getBlockMap()
          .first()
          .getType() !== 'unstyled'
      ) {
        className += ' RichEditor-hidePlaceholder';
      }
    }

    let urlInput;
    if (this.state.showURLInput) {
      urlInput = (
        <div className="RichEditor-controls link-controls">
          <label>Url: </label>
          <input
            className="RichEditor-input"
            ref="url"
            type="text"
            value={this.state.urlValue}
            onChange={(e) => {
              this.setState({ urlValue: e.target.value });
            }}
            onKeyDown={::this.onLinkInputKeyDown}
          />
          <button className="RichEditor-styleButton" onClick={::this.confirmLink}>
            Confirm
          </button>
          <button
            className="RichEditor-styleButton RichEditor-activeButton"
            onClick={::this.removeLink}
          >
            Remove
          </button>
        </div>
      );
    }

    return (
      <div className="RichEditor-root">
        <BlockStyleControls editorState={editorState} onToggle={::this.toggleBlockType} />
        <InlineStyleControls
          editorState={editorState}
          onToggle={::this.toggleInlineStyle}
        />
        <div className="RichEditor-controls">
          <StyleButton
            label="Link"
            active={this.state.showURLInput}
            onToggle={::this.promptForLink}
          />
        </div>
        {urlInput}
        <div className={className} onClick={this.focus}>
          <Editor
            readOnly={readOnly}
            blockStyleFn={getBlockStyle}
            editorState={editorState}
            handleKeyCommand={::this.handleKeyCommand}
            onChange={this.onChange}
            placeholder="Add your content..."
            ref="editor"
            spellCheck
          />
        </div>
      </div>
    );
  }
}

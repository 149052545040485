import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormGroup, Col, ControlLabel } from 'react-bootstrap';
import SelectMenu from '../SelectMenu/SelectMenu';

export const SELECT_IDENTIFIER = 'products';

class SelectProduct extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    label: PropTypes.string,
    labelSize: PropTypes.number,
    input: PropTypes.object.isRequired,
    infoButton: PropTypes.element,
    name: PropTypes.string,
    meta: PropTypes.object,
    labelKeys: PropTypes.array,
    isObject: PropTypes.bool,
    isMulti: PropTypes.bool,
    filters: PropTypes.array,
  };

  renderSelectMenu() {
    const { isObject } = this.props;

    if (isObject) {
      return (
        <SelectMenu
          source={SELECT_IDENTIFIER}
          sourceKey={'Object'}
          isObject
          {...this.props}
        />
      );
    }

    return (
      <SelectMenu
        source={SELECT_IDENTIFIER}
        optionValuePath={'id'}
        {...this.props}
      />
    );
  }

  render() {
    const {
      infoButton,
      meta,
      label,
      labelSize = 2,
    } = this.props;

    if (label) {
      return (
        <FormGroup
          controlId={name}
          className={meta && meta.touched && meta.error ? ' has-error' : ''}
        >
          <Col componentClass={ControlLabel} sm={labelSize}>
            {label} {infoButton}
          </Col>
          <Col sm={12 - labelSize}>
            {this.renderSelectMenu()}
          </Col>
        </FormGroup>
      );
    }

    return this.renderSelectMenu();
  }
}

export default connect()(SelectProduct);

import filterString from './filterString';

export default function queryString(includes = [], meta = [], filters = [], pageLimit) {
  const elements = [];

  const formattedIncludeString  = includes.length > 0 ?  `include=${includes.join(',')}` : '';
  if (formattedIncludeString) {
    elements.push(formattedIncludeString);
  }
  const formattedMetaString  = meta.length > 0 ?  `meta=${meta.join(',')}` : '';
  if (formattedIncludeString) {
    elements.push(formattedMetaString);
  }
  const formattedFilterString  = filters.length > 0 ? filterString(filters) : '';
  if (formattedFilterString) {
    elements.push(formattedFilterString);
  }
  const formattedPageLimit = pageLimit ? `page[limit]=${pageLimit}` : '';
  if (formattedPageLimit) {
    elements.push(formattedPageLimit);
  }


  if (elements.length) {
    return `?${elements.join('&')}`;
  }


  return '';
}

import { Field } from 'redux-form';
import { Checkbox, Col, FormGroup, } from 'react-bootstrap';
import React from 'react';
const SelectOptionsInput = ({
  input,
}) => {

  const {
    value,
    key,
    enabled
  } = input.value;

  const handleToggleOption = () => {
    input.onChange({
      value,
      key,
      enabled: !enabled
    });
  };


  const displayName = value?.name ? value.name : value;
  return (
    <FormGroup>
      <Col sm={6}>
        <div className={'max_trip_durations_field_array--row'}>

          <div className={'max_trip_durations_field_array--input'}>
            <Checkbox
              checked={enabled}
              onClick={handleToggleOption}
            ><span
              className={input.value.enabled ? 'emphasis' : ''}> {displayName}</span></Checkbox>

          </div>
        </div>

      </Col>
    </FormGroup>
  );
};


const ProductKeyedInfoFieldArray = ({ fields }) => {

    return (
      <>
        {fields?.map((field) => {
          return (
            <>
              <Field
                name={field}
                component={SelectOptionsInput}

              />

            </>

          );
        })}

      </>
    );
  }
;

export default ProductKeyedInfoFieldArray;

export const SET_REMINDERS = 'socrates-online/dashboard/SET_REMINDERS';
export const SET_NEW_BROKERS = 'socrates-online/dashboard/SET_NEW_BROKERS';
export const SET_NEW_USERS = 'socrates-online/dashboard/SET_NEW_USERS';
export const SET_ANNOUNCEMENTS = 'socrates-online/dashboard/SET_ANNOUNCEMENTS';
export const SET_DUE_DILIGENCE = 'socrates-online/dashboard/SET_DUE_DILIGENCE';
export const SET_STATS = 'socrates-online/dashboard/SET_STATS';
export const SET_BROKER_STATS = 'socrates-online/dashboard/SET_BROKER_STATS';
export const SET_SEARCH_RESULTS = 'socrates-online/dashboard/SET_SEARCH_RESULTS';
export const CLEAR_SEARCH_RESULTS = 'socrates-online/dashboard/CLEAR_SEARCH_RESULTS';
export const CLEAR_DATA = 'socrates-online/dashboard/CLEAR_DATA';

export const initialState = {
  reminders: [],
  new_brokers: [],
  new_users: [],
  announcements: [],
  due_diligence: [],
  stats: {},
  broker_stats: {},
  search_results: {
    meta: {
      policies: [],
      policy_applications: [],
    },
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_REMINDERS:
      return {
        ...state,
        reminders: action.data,
      };
    case SET_NEW_BROKERS:
      return {
        ...state,
        new_brokers: action.data,
      };
    case SET_NEW_USERS:
      return {
        ...state,
        new_users: action.data,
      };
    case SET_ANNOUNCEMENTS:
      return {
        ...state,
        announcements: action,
      };
    case SET_DUE_DILIGENCE:
      return {
        ...state,
        due_diligence: action.data,
      };
    case SET_STATS:
      return {
        ...state,
        stats: action.data,
      };
    case SET_BROKER_STATS:
      return {
        ...state,
        broker_stats: action.data,
      };
    case SET_SEARCH_RESULTS:
      return {
        ...state,
        search_results: action.data,
      };
    case CLEAR_SEARCH_RESULTS:
      return {
        ...state,
        search_results: initialState.search_results,
      };
    case CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
}

import { get, set, isEmpty, map, some } from 'lodash';
import moment from 'moment';

const validate = (values) => {
  const errors = {};
  const currentTab = get(values, 'data.current_tab');
  const mta = get(values, 'data.mta');
  const productCode = get(values, 'data.attributes.metadata.product_code');
  const medicalTab = 3;
  const travellersTab = 2;
  const requiredFields = [
    { field: 'data.attributes.metadata.scheme_type', name: 'Type of cover' },
    { field: 'data.attributes.metadata.start_date', name: 'Start date' },
  ];
  const travellerGroup = get(values, 'data.attributes.metadata.traveller_group');
  const existingCover = get(values, 'data.attributes.metadata.underlying_cover');
  const additionalCover = get(values, 'data.attributes.metadata.topup_cover');

  if (get(values, 'data.attributes.metadata.scheme_type', '') === 'single') {
    requiredFields.push({ field: 'data.attributes.metadata.end_date', name: 'End date' });
  }

  requiredFields.map((required) => {
    if (!get(values, required.field)) {
      set(errors, required.field, required.name + ' is required');
    }
  });

  // Group Type validation
  if ((currentTab === travellersTab) && productCode !== 'CSP') {
    if (travellerGroup === '') {
      set(errors, 'data.attributes.metadata.traveller_group', 'Group Type is required');
    }
  }

  // Effective Date for MTA
  if (mta) {
    if (!get(values, 'data.attributes.effective_date')) {
      set(errors, 'data.attributes.effective_date', 'Effective date is required');
    }
  }

  // Region check
  let checkRegion = get(values, 'data.attributes.metadata.scheme_type') === 'annual';
  if (['LSB'].includes(productCode)) {
    checkRegion = true;
  }

  if (checkRegion && productCode !== 'TUMC') {
    if (!get(values, 'data.attributes.metadata.region')) {
      set(errors, 'data.attributes.metadata.region', 'Please select a region');
    }
  } else {
    if (isEmpty(get(values, 'data.attributes.metadata.destinations')) && productCode !== 'TUMC' && productCode !== 'BMC' && productCode !== 'DIA' && productCode !== 'ADV' && productCode !== 'PST' && productCode !== 'CPI' && productCode !== 'EURP' && productCode !== 'CPS') {
      set(errors, 'data.attributes.metadata.destinations', 'Please select a destination');

      if (!get(values, 'data.attributes.metadata.end_date')) {
        set(errors, 'data.attributes.metadata.end_date', 'End date is required');
      }
    }
  }

  if (['FPS', 'FPR', 'NPO', 'NPOR', 'CSPA', 'CSPAR', 'CSS', 'CSSR'].includes(productCode)) {
    if (!get(values, 'data.attributes.metadata.traveller_group') || get(values, 'data.attributes.metadata.traveller_group') === '') {
      set(errors, 'data.attributes.metadata.traveller_group', 'Traveller group is required');
    }
    // if (!get(values, 'data.attributes.metadata.membership_number') || get(values, 'data.attributes.metadata.membership_number') === '') {
    //   set(errors, 'data.attributes.metadata.membership_number', 'Membership number is required');
    // }

    // Declaration tab
    if (currentTab === 2) {
      if (!get(values, 'data.attributes.metadata.uk_residents') || get(values, 'data.attributes.metadata.uk_residents') === '') {
        set(errors, 'data.attributes.metadata.uk_residents', 'UK resident is required');
      }
      if (!get(values, 'data.attributes.metadata.member_of_society') || get(values, 'data.attributes.metadata.member_of_society') === '') {
        set(errors, 'data.attributes.metadata.member_of_society', 'Member of Forces Pension Society is required');
      }
      if (!get(values, 'data.attributes.metadata.understood_eligibility') || get(values, 'data.attributes.metadata.understood_eligibility') === '') {
        set(errors, 'data.attributes.metadata.understood_eligibility', 'Accept eligibility is required');
      }
      if (!get(values, 'data.attributes.metadata.familiarised_with_terms') || get(values, 'data.attributes.metadata.familiarised_with_terms') === '') {
        set(errors, 'data.attributes.metadata.familiarised_with_terms', 'Terms and conditions is required');
      }
      if (!get(values, 'data.attributes.metadata.agree_and_confirm') || get(values, 'data.attributes.metadata.agree_and_confirm') === '') {
        set(errors, 'data.attributes.metadata.agree_and_confirm', 'Reasonable care is required');
      }
    }
  }
  if (productCode === 'EURP') {
    if (!get(values, 'data.attributes.metadata.region') || get(values, 'data.attributes.metadata.region') === '') {
      set(errors, 'data.attributes.metadata.region', 'Region is required');
    }
    if (!get(values, 'data.attributes.metadata.age_vehicle') || get(values, 'data.attributes.metadata.age_vehicle') === '') {
      set(errors, 'data.attributes.metadata.age_vehicle', 'Age of Vehicle is required');
    }
    if (!get(values, 'data.attributes.metadata.vehicle_reg') || get(values, 'data.attributes.metadata.vehicle_reg') === '') {
      set(errors, 'data.attributes.metadata.vehicle_reg', 'Vehicle Registration is required');
    }
    if (currentTab === 4) {
      if (!get(values, 'data.attributes.metadata.make') || get(values, 'data.attributes.metadata.make') === '') {
        set(errors, 'data.attributes.metadata.make', 'Vehicle make is required');
      }
      if (!get(values, 'data.attributes.metadata.model') || get(values, 'data.attributes.metadata.model') === '') {
        set(errors, 'data.attributes.metadata.model', 'Vehicle model is required');
      }
      if (!get(values, 'data.attributes.metadata.type') || get(values, 'data.attributes.metadata.type') === '') {
        set(errors, 'data.attributes.metadata.type', 'Vehicle type is required');
      }
      if (!get(values, 'data.attributes.metadata.year') ||
        get(values, 'data.attributes.metadata.year') === '' ||
        get(values, 'data.attributes.metadata.year').length !== 4) {
        set(errors, 'data.attributes.metadata.year', 'Vehicle year is required. Format YYYY');
      }
      if (!get(values, 'data.attributes.metadata.value') || get(values, 'data.attributes.metadata.value') === '') {
        set(errors, 'data.attributes.metadata.value', 'Vehicle value is required');
      }
      if (!get(values, 'data.attributes.metadata.colour') || get(values, 'data.attributes.metadata.colour') === '') {
        set(errors, 'data.attributes.metadata.colour', 'Vehicle colour is required');
      }
      if (!get(values, 'data.attributes.metadata.trans_type') || get(values, 'data.attributes.metadata.trans_type') === '') {
        set(errors, 'data.attributes.metadata.trans_type', 'Vehicle transmission type is required');
      }
      if (!get(values, 'data.attributes.metadata.fuel_type') || get(values, 'data.attributes.metadata.fuel_type') === '') {
        set(errors, 'data.attributes.metadata.fuel_type', 'Vehicle fuel type is required');
      }
      if (!get(values, 'data.attributes.metadata.four_by_four_drive') || get(values, 'data.attributes.metadata.four_by_four_drive') === '') {
        set(errors, 'data.attributes.metadata.four_by_four_drive', 'Is the vehicle a 4x4? - required');
      }
    }
  }

  const signposted = get(values, 'data.attributes.metadata.signposted', false);
  const isSignpostAcknowledged = get(values, 'data.attributes.metadata.signpost_acknowledged', false);

  if (signposted && !isSignpostAcknowledged && ((!mta && currentTab === 4) || (mta && currentTab === 5))) {
    set(errors, 'data.attributes.metadata.signpost_acknowledged', 'Please confirm the signpost text has been read and acknowledged');
  }

  if (['FST', 'LSB', 'DIA'].includes(productCode)) {
    if (!get(values, 'data.attributes.metadata.storage.covid_declaration_question')) {
      set(errors, 'data.attributes.metadata.storage.covid_declaration_question', 'Please Agree to the above statement');
    }
  }

  if (productCode === 'TUMC') {
    // const adults = get(values, 'data.attributes.metadata.adults', []).length;
    // const children = get(values, 'data.attributes.metadata.children', []).length;
    // const totalTravllers = adults + children;
    const total = 1000;
    if (existingCover < total) {
      set(
        errors,
        'data.attributes.metadata.underlying_cover',
        'This value cannot be less than ' + total,
      );
    }
    if (!existingCover) {
      set(errors, 'data.attributes.metadata.underlying_cover', 'Existing cover is required');
    }
    if (!additionalCover) {
      set(errors, 'data.attributes.metadata.topup_cover', 'Additional cover is required');
    }
  }

  // Validations only if it is in travellersTab
  let adultMaxAge = get(values, 'data.attributes.metadata.age_limit.adult.max_age', 75);
  const adultMinAge = get(values, 'data.attributes.metadata.age_limit.adult.min_age', 18);
  let childMaxAge = get(
    values,
    'data.attributes.metadata.age_limit.children.max_age',
    18,
  );
  if (travellerGroup === 'family' || travellerGroup === 'single_parent_family') {
     childMaxAge = get(
      values,
      'data.attributes.metadata.age_limit.family.children.max_age',
      18,
    );
  }

  if ((currentTab === travellersTab || (['FPS', 'FPR', 'NPO', 'NPOR', 'CSPA', 'CSPAR', 'CSS', 'CSSR'].includes(productCode) && currentTab === 1)) && productCode !== 'CSP') {
    if (travellerGroup !== 'independent_child') {
      get(values, 'data.attributes.metadata.adults', []).map((adult, i) => {
        if (!adult) {
          return;
        }

        if (i === 0 && !adult.title) {
          set(
            errors,
            'data.attributes.metadata.adults[' + i + '].title',
            'Title is required',
          );
        }
        // email validation for the lead traveller
        if (i === 0 && !isEmpty(adult.email)) {
          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,40}$/i.test(adult.email)) {
            set(
              errors,
              'data.attributes.metadata.adults[' + i + '].email',
              'Invalid email address',
            );
          }
        }

        if (i === 0 && !adult.first_name) {
          set(
            errors,
            'data.attributes.metadata.adults[' + i + '].first_name',
            'First name is required',
          );
        }

        if (i === 0 && !adult.last_name) {
          set(
            errors,
            'data.attributes.metadata.adults[' + i + '].last_name',
            'Last name is required',
          );
        }

        if (i === 0 && isEmpty(get(adult, 'phone1'))) {
          set(
            errors,
            'data.attributes.metadata.adults[' + i + '].phone1',
            'Phone number is required',
          );
        }

        if (i === 0 && !isEmpty(adult.phone1)) {
          if (adult.phone1.replace(/\s/g, '').length < 10) {
            set(
              errors,
              'data.attributes.metadata.adults[' + i + '].phone1',
              'Phone number is too short',
            );
          }
        }

        // Check for added Adults Date of birth
        if (isEmpty(adult.dob)) {
          set(
            errors,
            'data.attributes.metadata.adults[' + i + '].dob',
            'Please enter a valid date of birth',
          );
        }

        if (!isEmpty(adult.dob)) {
          let adultDob = moment(adult.dob);

          if (['BMC'].includes(productCode)) {
            adultMaxAge = adultMaxAge + 1;
          }

          adultMaxAge = 110;

          let checkDob = adultDob.isAfter(
            moment()
              .startOf('day')
              .subtract(adultMaxAge, 'years'),
          );

          const isAdult = adultDob.isSameOrBefore(
            moment()
              .startOf('day')
              .subtract(adultMinAge, 'years'),
          );
          if (!checkDob) {
            set(
              errors,
              'data.attributes.metadata.adults[' + i + '].dob',
              'Please enter a valid date of birth',
            );
          }
          if (!isAdult) {
            set(
              errors,
              'data.attributes.metadata.adults[' + i + '].dob',
              `Adult must be ${adultMinAge} or plus`,
            );
          }
          const dateRegex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)\d{2})$/;
          if (!dateRegex.test(adultDob.format('DD-MM-YYYY'))) {
            set(
              errors,
              'data.attributes.metadata.adults[' + i + '].dob',
              'Format must be dd/mm/yyyy',
            );
          }
        }

        if (isEmpty(adult.first_name)) {
          set(
            errors,
            'data.attributes.metadata.adults[' + i + '].first_name',
            'First name is required',
          );
        }

        if (isEmpty(adult.last_name)) {
          set(
            errors,
            'data.attributes.metadata.adults[' + i + '].last_name',
            'Last name is required',
          );
        }

        if (isEmpty(adult.title)) {
          set(
            errors,
            'data.attributes.metadata.adults[' + i + '].title',
            'Title is required',
          );
        }

        // Check for postcode
        if (i === 0 && isEmpty(get(adult, 'address.postcode'))) {
          set(
            errors,
            'data.attributes.metadata.adults[' + i + '].address.postcode',
            'Postcode is required',
          );
        }
      });

      // child validation
      get(values, 'data.attributes.metadata.children', []).map((child, i) => {
        if (!child) {
          return;
        }

        if (isEmpty(child.first_name)) {
          set(
            errors,
            'data.attributes.metadata.children[' + i + '].first_name',
            'First name is required',
          );
        }

        if (isEmpty(child.last_name)) {
          set(
            errors,
            'data.attributes.metadata.children[' + i + '].last_name',
            'Last name is required',
          );
        }

        if (isEmpty(child.title)) {
          set(
            errors,
            'data.attributes.metadata.children[' + i + '].title',
            'Title is required',
          );
        }

        if (isEmpty(child.dob)) {
          set(
            errors,
            'data.attributes.metadata.children[' + i + '].dob',
            'Please enter a valid date of birth',
          );
        }

        if (!isEmpty(child.dob)) {
          const childDob = moment(child.dob);
          const isChild = childDob.isAfter(
            moment()
              .startOf('day')
              .subtract(childMaxAge + 1, 'years'),
          );

          if (!['NPO', 'NPOR', 'CSPA', 'CSPAR', 'CSS', 'CSSR'].includes(productCode)) {
            childMaxAge = childMaxAge++;
          }

          if (!isChild) {
            set(
              errors,
              'data.attributes.metadata.children[' + i + '].dob',
              `Child must be under ${childMaxAge}`,
            );
          }
          const dateRegex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)\d{2})$/;
          if (!dateRegex.test(childDob.format('DD-MM-YYYY'))) {
            set(
              errors,
              'data.attributes.metadata.children[' + i + '].dob',
              'Format must be dd/mm/yyyy',
            );
          }
        }
      });
    }
  }

  // Group Type Single or Family: check for Children Fields
  if ((currentTab === travellersTab || (['FPS', 'FPR', 'NPO', 'NPOR', 'CSS', 'CSSR'].includes(productCode) && currentTab === 1)) && productCode !== 'CSP') {
    if (
      travellerGroup === 'single_parent_family' ||
      travellerGroup === 'family' ||
      travellerGroup === 'independent_child'
    ) {
      if (isEmpty(get(values, 'data.attributes.metadata.children'))) {
        const typeErrorMsg =
          travellerGroup === 'independent_child' ? 'an independent child' : ' a family';
        set(
          errors,
          'data.attributes.metadata.children[0].first_name',
          'Children are required for ' + typeErrorMsg + ' policy',
        );
      }
      get(values, 'data.attributes.metadata.children', []).map((child, i) => {
        if (child.title === '') {
          set(
            errors,
            'data.attributes.metadata.children[' + i + '].title',
            'Title is required',
          );
        }
        if (child.first_name === '') {
          set(
            errors,
            'data.attributes.metadata.children[' + i + '].first_name',
            'First name is required',
          );
        }
        if (child.last_name === '') {
          set(
            errors,
            'data.attributes.metadata.children[' + i + '].last_name',
            'Last name is required',
          );
        }
        // if (child.dob === '') {
        //   set(errors, 'data.attributes.metadata.children[' + i + '].dob', 'Date of birth is required')
        // }

        if (isEmpty(child.dob)) {
          set(
            errors,
            'data.attributes.metadata.children[' + i + '].dob',
            'Please enter a valid date of birth',
          );
        }

        if (travellerGroup === 'independent_child') {
          const leadChild = get(values, 'data.attributes.metadata.children[0]');
          if (isEmpty(get(leadChild, 'phone1'))) {
            set(
              errors,
              'data.attributes.metadata.children[' + i + '].phone1',
              'Phone number is required',
            );
          }

          if (!isEmpty(leadChild.phone1)) {
            if (leadChild.phone1.replace(/\s/g, '').length < 3) {
              set(
                errors,
                'data.attributes.metadata.children[' + i + '].phone1',
                'Phone number is too short',
              );
            }
          }

          if (isEmpty(get(leadChild, 'address.postcode'))) {
            set(
              errors,
              'data.attributes.metadata.children[' + i + '].address.postcode',
              'Postcode is required',
            );
          }
        }

        if (!isEmpty(child.dob)) {
          const childDob = moment(child.dob);
          const isChild = childDob.isAfter(
            moment()
              .startOf('day')
              .subtract(childMaxAge + 1, 'years'),
          );

          if (!['NPO', 'NPOR'].includes(productCode)) {
            childMaxAge = childMaxAge++;
          }

          if (!isChild) {
            set(
              errors,
              'data.attributes.metadata.children[' + i + '].dob',
              `Child must be under ${childMaxAge}`,
            );
          }
          const dateRegex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)\d{2})$/;
          if (!dateRegex.test(childDob.format('DD-MM-YYYY'))) {
            set(
              errors,
              'data.attributes.metadata.children[' + i + '].dob',
              'Format must be dd/mm/yyyy',
            );
          }
        }
      });
    }
  }

  const medicalMetadata = get(
    values,
    'data.attributes.metadata.medical_declaration_questions',
    {},
  );
  const medicalAnswersRelevant = map(medicalMetadata, (declaration) => {
    if (declaration.question_type === 'boolean') {
      return {
        questionType: declaration.question_type,
        answer: get(declaration, 'answer', 'noAnswer'),
      };
    }
  });

  const allQuestionsAnswered = () => {
    return !some(medicalAnswersRelevant, ['answer', 'noAnswer']);
  };

  /********************************************\
   Validation only if it is in the Medical Tab
  \********************************************/

  // check if there is at least one critical question answered as yes
  let isCriticalAnsweredYes = false;
  map(
    get(values, 'data.attributes.metadata.medical_declaration_questions', []),
    (declaration, key) => {
      if (
        declaration.question_type === 'boolean' &&
        declaration.negotiable === false &&
        get(
          values,
          'data.attributes.metadata.medical_declaration_questions[' + key + '].answer',
          false,
        ) === true
      ) {
        isCriticalAnsweredYes = true;
      }
    },
  );

  if (currentTab === medicalTab && productCode !== 'CPI' && productCode !== 'CPS') {
    map(
      get(values, 'data.attributes.metadata.medical_declaration_questions', {}),
      (declaration, key) => {
        const questionType = declaration.question_type;

        if (
          declaration.type === 'question' &&
          questionType === 'boolean' &&
          declaration.negotiable === true &&
          declaration.answer === undefined
        ) {
          set(
            errors,
            'data.attributes.metadata.medical_declaration_questions[' + key + '].answer',
            'Medical declaration is required',
          );
        }
        if (allQuestionsAnswered() === false) {
          set(
            errors,
            'data.attributes.metadata.medical_declaration_questions[' + key + '].answer',
            'Medical declaration is required',
          );
        }
      },
    );

    /****************************************
     * Check if there is Medical answered YES
     * and then if there is a condition added
     ****************************************/
    let requiresScreening = false;
    let isConditionAdded = false;
    // If any of the questions (boolean && negotiable) is 'YES':
    map(
      get(values, 'data.attributes.metadata.medical_declaration_questions', []),
      (declaration, key) => {
        if (
          declaration.question_type === 'boolean' &&
          declaration.negotiable === true &&
          get(
            values,
            'data.attributes.metadata.medical_declaration_questions[' + key + '].answer',
            false,
          ) === true
        ) {
          requiresScreening = true;
        }
      },
    );
    // Verify if a condition is added for Adult
    if (requiresScreening) {
      map(get(values, 'data.attributes.metadata.adults', []), (adult) => {
        if (!isEmpty(get(adult, 'screening.meta'))) {
          isConditionAdded = true;
        }
      });
    }
    // Verify if a condition is added for Children
    if (requiresScreening) {
      map(get(values, 'data.attributes.metadata.children', []), (children) => {
        if (!isEmpty(get(children, 'screening.meta'))) {
          isConditionAdded = true;
        }
      });
    }

    // Add the error in case of no condition is added. Ignore if a Critical question is answered as yes.
    if (
      requiresScreening === true &&
      isConditionAdded === false &&
      isCriticalAnsweredYes === false
    ) {
      set(errors, 'data.attributes.metadata.declaration', 'Please add a condition.');
    }
  }

  errors._error = errors.data;
  return errors;
};
export default validate;

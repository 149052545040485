import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InternalFooter from '../components/InternalFooter/InternalFooter';
import GuestFooter from '../components/GuestFooter/GuestFooter';

class FooterContainer extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  render() {
    if (this.props.auth.authenticated) {
      return <InternalFooter {...this.props} />;
    }
    return <GuestFooter {...this.props} />;
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(FooterContainer);

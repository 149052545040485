export const getInitialValuesForConfigForm = (scheme) => {
  if (!scheme) {
    return undefined;
  }

  return {
    meta: {
      config: {
        options: scheme.meta.options,
        benefits: scheme.meta.benefits,
        referrals: scheme.meta.referrals,
        rules: scheme.meta.rules,
        parameters: scheme.meta.parameters,
      },
    },
  };
};
export const getInitialValuesForDetailForm = (scheme, isTravel) => {
  if (!scheme) {
    return undefined;
  }

  const schemeType = scheme.data.attributes.scheme_type;
  const isSingle = schemeType === 'single';
  const patch = isTravel
    ? {
      commission_rate: scheme.meta.commission_rate,
      standard_excess: scheme.meta.standard_excess,
      medical_signpost_threshold: scheme.meta.medical_signpost_threshold,
      ...getSingleRates(scheme, isSingle),
    }
    : {
      commission_rate: scheme.meta.commission_rate,
      standard_excess: scheme.meta.standard_excess,
    };

  return {
    data: {
      attributes: {
        prefix: scheme.meta.prefix,
        ...scheme.data.attributes,
      },
      relationships: {
        ...scheme.data.relationships,
      },
    },
    meta: {
      patch: patch,
    },
  };
};

export const getSingleRates = (scheme, isSingle) => {
  if (isSingle) {
    const tripDurationKey = Object.keys(
      scheme?.meta?.rates?.base.conditions?.trip_duration ?? [],
    )?.[0];
    const formattedGteKey = tripDurationKey.match(/\d+$/)[0];

    return {
      rates: {
        base: {
          conditions: {
            trip_duration: {
              key: formattedGteKey,
              value: scheme.meta.rates.base.conditions.trip_duration[tripDurationKey],
            },
          },
        },
      },
    };
  } else {
    return {};
  }
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { setAnswer } from '../../redux/screeningActions';
import getIncludedResponse from '../../../../helpers/getIncludedResource';
import { find } from 'lodash';
import { ConditionQuestionEdit } from '../ConditionQuestionEdit/ConditionQuestionEdit';
import {Row} from 'react-bootstrap';

export default class SingleChoiceQuestion extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    screening: PropTypes.object.isRequired,
    condition: PropTypes.object.isRequired,
    question: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      answer: '',
    };
  }

  componentWillReceiveProps(props) {
    const options = getIncludedResponse(
      props.question,
      props.screening.declaration.included,
      'options',
    );
    const selectedOption = find(options, ['attributes.is_selected', true]);

    if (selectedOption) {
      if (this.state.answer !== selectedOption.attributes.number) {
        this.setState({ answer: selectedOption.attributes.number });
      }
    } else {
      this.setState({ answer: '' });
    }
  }

  componentDidUpdate() {
    const element = document.getElementById('active-single-question');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  render() {
    const { index, screening, condition, question, dispatch } = this.props;
    const { answer } = this.state;
    const optionsResource = getIncludedResponse(
      question,
      screening.declaration.included,
      'options',
    );
    const selectedAnswers = getIncludedResponse(
      question,
      screening.declaration.included,
      'selected',
    );
    const isActiveQuestion = question?.attributes?.is_active || false;

    const options = optionsResource.map((option) => {
      return {
        value: option.attributes.number,
        label: option.attributes.text,
      };
    });

    return (
      <div className="single-choice-question">
        <div className="row">
          <div className="col-xs-12">
            <div className="form-group screening-border-bottom">
              <label className="control-label">{question.attributes.text}</label>
              {question.attributes.info ? (
                <p className="text-info text-justify" style={{ fontSize: '14px' }}>
                  {question.attributes.info}
                  <br />
                </p>
              ) : (
                ''
              )}
              {question.attributes.warning ? (
                <p className="text-danger text-justify" style={{ fontSize: '14px' }}>
                  {question.attributes.warning}
                  <br />
                </p>
              ) : (
                ''
              )}

              {!isActiveQuestion && (
                <ConditionQuestionEdit
                  selectedAnswers={selectedAnswers}
                  screening={screening}
                  dispatch={dispatch}
                  condition={condition}
                  question={question}
                />
              )}

              {isActiveQuestion && (
                <Select
                  name={'single-choice-question-' + index}
                  id={'active-single-question'}
                  options={options}
                  value={answer}
                  clearable={false}
                  onChange={(answer) => {
                    dispatch(setAnswer(condition, question, answer));
                    this.setState({ answer });
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Box, DataTable, SelectUser } from '../../../../common/components';
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { FormControl, ControlLabel } from 'react-bootstrap';
import ProductFilter from '../ProductFilter';
import { FormattedDate } from 'react-intl';
import { push } from 'react-router-redux';

class ReferredRenewalList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      userId: '',
      items: [],
      render: false,
      checkAll: undefined,
      listDataFromChild: null,
      _dataTable: [],
      allRows: []
    };
    this.onClickProductSelected = this.onClickProductSelected.bind(this);
  }

  formatDate(cell) {
    return (
      <span><FormattedDate value={new Date(cell)}/></span>
    );
  }

  formatYesNo(cell) {
    return (
      <span>{cell ? 'YES' : 'no'}</span>
    );
  }

  formatPercentage(cell) {
    return (
      <span>{ cell }%</span>
    );
  }

  // Checkbox Event
  onClickProductSelected(cell, row) {
    let newArr = this.state.items;
    // If element is already on the array remove it, otherwise push to this.state
    if (Array.prototype.indexOf(this.state.items, row.id) !== -1) {
      _.pull(newArr, row.id);
      this.setState({
        items: newArr
      });
    } else {
      this.setState(prevState => ({
        items: [...prevState.items, row.id]
      }));
    }
  }

  handleFilterUpdate(event) {
    this.props.dispatch(applyFilter('invitedRenewals', 'filter[product]', event.target.value));
  }

  handleRowClick(row) {
    this.props.dispatch(push('/policies/applications/' + row.id));
  }

  handleSearchUpdate(event) {
    this.props.dispatch(applyFilter('invitedRenewals', 'filter[quote.reference]', event.target.value));
  }

  handlePolicyUpdate(event) {
    this.props.dispatch(applyFilter('invitedRenewals', 'filter[policy.number]', event.target.value));
  }

  handleUserUpdate(value) {
    this.setState({ userId: value });
    this.props.dispatch(applyFilter('invitedRenewals', 'filter[user]', value));
  }

  render() {
    return (
      <div>
        <Box>
          <div className="row">
            <div className="col-sm-3">
              <ControlLabel className="filter-label">Reference</ControlLabel>
              <FormControl onChange={ ::this.handleSearchUpdate } />
            </div>
            <div className="col-sm-3">
              <ControlLabel className="filter-label">Policy Number</ControlLabel>
              <FormControl onChange={ ::this.handlePolicyUpdate } />
            </div>
            <div className="col-sm-3">
              <ControlLabel className="filter-label">User</ControlLabel>
              <SelectUser
                labelKeys={['attributes.first_name', 'attributes.last_name']}
                input={{
                  onChange: ::this.handleUserUpdate,
                  value: this.state.userId
                }}/>
            </div>
            <div className="col-sm-3">
              <ControlLabel className="filter-label">Product</ControlLabel>
              <ProductFilter onChange={::this.handleFilterUpdate} />
            </div>
          </div>
        </Box>

        <Box>
          <DataTable
            source="/policies/applications"
            name="invitedRenewals"
            showTotal={true}
            hover={true}
            autoFilters={[
              { type: 'filter[status]', value: 'renewal invited' },
              { type: 'filter[quote.type]', value: 'renewal' },
              { type: 'filter[user]', value: this.state.userId },
              { type: 'filter[policy.renewal_status]', value: 'refer' },
              { type: 'sort', value: 'starts_at' },
            ]}
            onRowSelect={this.handleRowClick.bind(this)} // Highlighting function
          >
            <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
              ID
            </TableHeaderColumn>

            <TableHeaderColumn dataField="quote_reference" width={'150px'}>
              Reference
            </TableHeaderColumn>

            <TableHeaderColumn dataField="renewing_policy_number" width={'15%'}>
              Policy Number
            </TableHeaderColumn>

            <TableHeaderColumn dataField="insured" width={'10%'}>
              Insured
            </TableHeaderColumn>

            <TableHeaderColumn dataField="has_medical" dataFormat={this.formatYesNo} width={'10%'}>
              Screened
            </TableHeaderColumn>

            <TableHeaderColumn dataField="has_mta" dataFormat={this.formatYesNo} width={'10%'}>
              MTA
            </TableHeaderColumn>

            <TableHeaderColumn dataField="has_endorsements" dataFormat={this.formatYesNo} width={'10%'}>
              Endorsement
            </TableHeaderColumn>

            <TableHeaderColumn dataField="has_override" dataFormat={this.formatYesNo} width={'10%'}>
              Override
            </TableHeaderColumn>

            <TableHeaderColumn dataField="renewal_premium_change" dataFormat={this.formatPercentage} width={'10%'}>
              % Change
            </TableHeaderColumn>

            <TableHeaderColumn dataField="starts_at" dataFormat={this.formatDate} width={'10%'}>
              Renewal Date
            </TableHeaderColumn>
          </DataTable>

        </Box>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    dataTables: state.dataTables
  };
}

export default connect(mapStateToProps)(ReferredRenewalList);

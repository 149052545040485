import React, { useEffect, useState } from 'react';
import DocumentDropdownActions from '../DocumentDropdownActions/DocumentDropdownActions';
import DocumentIsGenerating from '../DocumentIsGenerating/DocumentIsGenerating';
import DocumentVersionSelector from '../DocumentVersionSelector/DocumentVersionSelector';
import SupersedingDocumentsCount
  from '../SupersedingDocumentsCount/SupersedingDocumentsCount';
import ViewSupersedingDocumentModal
  from '../ViewSupersedingDocumentsModal/ViewSupersedingDocumentModal';
import './DocumentListItemContainer.scss';


const Insurer = ({ logo, name }) => {
  if (!name) {
    return null;
  }

  if (!logo) {
    return (
      <div className="document-insurer-logo">
        <span>Insurer: {name}</span>
      </div>
    );
  }
  return (
    <div className="document-insurer-logo">
      <img src={logo} alt={name}/>
    </div>
  );
};

const Scheme = ({ name, isSchemeDocument }) => {
  if (isSchemeDocument) {
    return <div className="document-insurer-logo">Scheme Document</div>;
  }
  if (!name) {
    return null;
  }
  return <div className="document-insurer-logo">Scheme: {name}</div>;
};

const Product = ({ isProductDocument }) => {
  if (isProductDocument) {
    return <div className="document-insurer-logo">Product Document</div>;
  }
  return null;
};

const DocumentListItemContainer = ({ documents, documentsGenerated }) => {
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showSupersedingModal, setShowSupersedingModal] = useState(null);
  //
  const selectDocument = (document) => {
    setSelectedDocument(document);
    //onSelectDocument(document);
  };

  const openSupersedingModal = () => {
    setShowSupersedingModal(true);
  };

  const closeSupersedingModal = () => {
    setShowSupersedingModal(false);
  };

  const versionNumbers = Object.keys(documents).reverse();

  const numVersions = versionNumbers.length;
  const firstDoc = documents?.[versionNumbers?.[0]];

  useEffect(
    () => {
      setSelectedDocument(firstDoc);
    },
    [numVersions, firstDoc],
  );

  const firstDocument = documents[versionNumbers[0]];
  const displaySelectedDocument = selectedDocument ? selectedDocument : firstDocument;
  const documentIds = displaySelectedDocument.superseding.map((doc) => doc.id);

  documentIds.push(displaySelectedDocument.id);

  const documentsInGenerated = documentsGenerated.filter((x) => documentIds.includes(x));
  const isGenerating = !!documentsInGenerated.length;

  const isSchemeDocument = !!displaySelectedDocument.schemes?.length;
  const isProductDocument = !!displaySelectedDocument.products?.length;

  const supersedingDocuments = displaySelectedDocument?.superseding ?? [];
  const ancillarySchemeName = displaySelectedDocument.ancillary_schemes
    ?.map((scheme) => scheme.name)
    ?.join(',');

  return (
    <div className="document-list-item-container overflow-visible margin-bottom">
      <div className="document-content">
        <span className="pull-left">
          {displaySelectedDocument.name}

          <Scheme isSchemeDocument={isSchemeDocument} name={ancillarySchemeName}/>
          <Product isProductDocument={isProductDocument}/>
          <Insurer
            logo={displaySelectedDocument?.insurer_logo}
            name={displaySelectedDocument?.insurer_name}
          />
        </span>

        <div className="pull-right">
          <DocumentVersionSelector
            documentList={Object.values(documents).reverse()}
            // documents={Object.values(documents).reverse()}
            activeDocument={displaySelectedDocument}
            onSelectDocument={selectDocument}
          />
          <DocumentDropdownActions
            document={displaySelectedDocument}
            isGenerating={isGenerating}
          />
        </div>

        <div className="superseded-documents">
          <DocumentIsGenerating isGenerating={isGenerating}/>
          <SupersedingDocumentsCount
            supersedingDocumentCount={supersedingDocuments.length}
            onOpenSupersedingModal={openSupersedingModal}
          />
        </div>
        <ViewSupersedingDocumentModal
          show={showSupersedingModal}
          supersedingDocuments={supersedingDocuments}
          close={closeSupersedingModal}
          isGenerating={isGenerating}
          documentsGenerated={documentsGenerated}
        />
      </div>
    </div>
  );
};

export default DocumentListItemContainer;

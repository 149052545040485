import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createContent, updateContent } from '../redux/contentActions';
import ContentForm from '../components/ContentForm/ContentForm';

export default class ContentFormContainer extends Component {
  static propTypes = {
    template: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object,
  };

  handleSubmit(resource) {
    if (resource.data.id) {
      return this.props.dispatch(updateContent(resource));
    }
    return this.props.dispatch(createContent(resource));
  }

  render() {
    return <ContentForm {...this.props} onSubmit={this.handleSubmit.bind(this)} />;
  }
}

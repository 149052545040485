/**
 * Redux Form Field Normalize helper
 * Takes a value and return if the length is less than param
 * Usage example:
 * normalize={lessThan(3)}
 */

const lessThan = (minValue) => (value) => {
  if (!value) {
    return value;
  }

  if (value.length <= minValue) {
    return value;
  }
};

export default lessThan;

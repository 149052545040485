import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { map, isEmpty, isArray } from 'lodash';
import { FormattedDate } from 'react-intl';
import displayName from '../../../../helpers/displayName';
import { Box, Button } from '../../../../common/components';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import './styles.scss';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import MdInsertDriveFile from 'react-icons/lib/md/file-download';
import { Col, Row } from 'react-bootstrap';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';

export default class ContentShow extends Component {
  renderSection(section, key) {
    const { resource } = this.props;

    switch (true) {
      case key.indexOf('date') !== -1:
        return <FormattedDate value={new Date(section)} />;
      case key === 'hero' || key === 'attachments':
        if (!section) {
          return null;
        }
        const files = getIncludedResource(resource.data, resource.included, 'files');
        let formatData = [];
        if (!isEmpty(files)) {
          formatData = map(files, (data) => {
            return {
              name: data.attributes.name,
              url: data.attributes.url,
              id: data.id,
            };
          });
        }

        return (
          <div className="table-container-files table-container-files--cursor-on">
            <BootstrapTable
              data={formatData}
              bordered={false}
              hover
              options={{ onRowClick: onDownload }}
            >
              <TableHeaderColumn dataField="id" isKey hidden>
                ID
              </TableHeaderColumn>
              <TableHeaderColumn dataField="name" dataFormat={displayFileName}>
                {' '}
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        );
      default:
        return <ReactMarkdown source={section} />;
    }
  }

  render() {
    const resource = this.props.resource.data.attributes;
    const sections = resource.sections;
    const products = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'products',
    );
    return (
      <Box>
        <h2>{resource.name}</h2>
        {Object.keys(sections).map((section) => {
          const classname = [
            section !== 'attachments' ? 'RichEditor-root' : 'list-group',
          ];
          return (
            <div key={section} className="content-show-section">
              <label className="control-label">{displayName(section)}</label>
              <div className={classname.join(' ')}>
                {this.renderSection(sections[section], section)}
              </div>
            </div>
          );
        })}
        <div>
          <label className="control-label">Products</label>
          <div>
            {!isEmpty(products) &&
              isArray(products) &&
              products.reduce((carry, product) => {
                return carry + (carry ? ', ' : '') + product.attributes.name;
              }, '')}
          </div>
        </div>
        <Row>
          <Col sm={12}>
            <InternalButtonBar buttons={[
              {
                permissions: ['content_management.edit'],
                button: (
                  <Button
                    link
                    to={'/admin/content/' + this.props.resource.data.id + '/edit'}
                    className="pull-right"
                    bsStyle="primary"
                    rightIcon="pencil-square-o"
                    label="edit content"
                  />
                )
              }
            ]}/>
          </Col>
        </Row>
      </Box>
    );
  }
}

const onDownload = (row) => {
  window.open(row.url, '_blank');
};
const displayFileName = (cell, row) => {
  return (
    <div>
      <MdInsertDriveFile size={20} />
      <span> {row.name}</span>
    </div>
  );
};

ContentShow.propTypes = {
  resource: PropTypes.object.isRequired,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box, DataTable, SelectUser, SelectBroker } from '../../../../common/components';
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { Row, Col, FormControl, ControlLabel } from 'react-bootstrap';
import ProductFilter from '../ProductFilter';
import { push } from 'react-router-redux';
import { isOwner, isBroker, authUserId } from '../../../auth/redux/authActions';
import { FormattedDate, FormattedTime } from 'react-intl';
import getStatus from '../../../../schemas/policy';

export const TABLE_NAME = 'applications';

class ApplicationList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      brokerId: '',
      userId: isBroker() ? authUserId() : '',
    };
  }

  formatDate(cell) {
    return (
      <span>
        <FormattedDate value={new Date(cell)} />
      </span>
    );
  }

  formatDateTime(cell) {
    return (
      <span>
        <FormattedDate value={new Date(cell)} /> <FormattedTime value={new Date(cell)} />
      </span>
    );
  }

  handleFilterUpdate(event) {
    this.props.dispatch(applyFilter(TABLE_NAME, 'filter[product]', event.value));
  }

  handleRowClick(row) {
    this.props.dispatch(push('/policies/applications/' + row.id));
  }

  handleSearchUpdate(event) {
    this.props.dispatch(
      applyFilter(TABLE_NAME, 'filter[quote.reference]', event.target.value),
    );
  }

  handleBrokerUpdate(option) {
    const value = option && option.value;

    this.setState({ brokerId: value || '' });
    this.props.dispatch(applyFilter(TABLE_NAME, 'filter[broker]', value));
  }

  handleUserUpdate(option) {
    const value = option && option.value;

    this.setState({ userId: value || '' });
    this.props.dispatch(applyFilter(TABLE_NAME, 'filter[user]', value));
  }

  render() {
    const { brokerId } = this.state;

    return (
      <div>
        <Box>
          <Row>
            <Col sm={3}>
              <ControlLabel className="filter-label">Reference</ControlLabel>
              <FormControl onChange={::this.handleSearchUpdate} />
            </Col>
            <Col sm={3}>
              <ControlLabel className="filter-label">Product</ControlLabel>
              <ProductFilter onChange={::this.handleFilterUpdate} />
            </Col>
            {isOwner() && (
              <Col sm={3}>
                <ControlLabel className="filter-label">Broker</ControlLabel>
                <SelectBroker
                  key={'broker-select'}
                  async
                  isClearable
                  onChange={::this.handleBrokerUpdate}
                  input={{
                    value: this.state.brokerId,
                  }}
                />
              </Col>
            )}
            {(isOwner() || isBroker()) && (
              <Col sm={3}>
                <ControlLabel className="filter-label">User</ControlLabel>
                <SelectUser
                  id={'user-select'}
                  key={'user-select-' + brokerId}
                  async={isOwner()}
                  byBroker={brokerId}
                  isClearable
                  labelKeys={['attributes.first_name', 'attributes.last_name']}
                  onChange={::this.handleUserUpdate}
                  input={{
                    value: this.state.userId,
                  }}
                />
              </Col>
            )}
          </Row>
        </Box>

        <Box>
          <DataTable
            source="/policies/applications"
            name={TABLE_NAME}
            showTotal
            autoFilters={[
              { type: 'filter[status]', value: 'applied,quote,issued' },
              { type: 'filter[quote.type]', value: 'new business' }, // do not display MTAs
            ]}
            onRowSelect={this.handleRowClick.bind(this)}
          >
            <TableHeaderColumn dataField="id" isKey hidden />

            <TableHeaderColumn dataField="quote_reference" width={'150px'}>
              Reference
            </TableHeaderColumn>

            <TableHeaderColumn dataField="insured" width={'150px'}>
              Insured
            </TableHeaderColumn>

            <TableHeaderColumn dataField="product_name" width={'150px'}>
              Product
            </TableHeaderColumn>

            <TableHeaderColumn dataField="channel_name" width={'150px'}>
              Channel
            </TableHeaderColumn>

            <TableHeaderColumn dataField="broker_name" width={'150px'}>
              Broker
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="status"
              dataFormat={getStatus}
              dataSort
              width={'100px'}
            >
              Status
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="created_at"
              dataFormat={this.formatDateTime}
              dataSort
              width={'100px'}
            >
              Quote Date
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="starts_at"
              dataFormat={this.formatDate}
              dataSort
              width={'100px'}
            >
              Start Date
            </TableHeaderColumn>
          </DataTable>
        </Box>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(ApplicationList);

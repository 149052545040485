export const SET_ACTIVE_TAB = 'socrates-online/tabs/SET_ACTIVE_TAB';

const initialState = {
  activeTabs: {
    accountsTabs: 1,
    bookkeepingTabs: 1,
    brokerTabs: 1,
    brokerApplicationTabs: 1,
    campaignTabs: 1,
    contentTabs: 1,
    customerTabs: 1,
    organisationTabs: 1,
    policyTabs: 1,
    reportTabs: 1,
    userTabs: 1,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      let activeTabs = state.activeTabs;
      activeTabs[action.name] = action.number;
      return {
        ...state,
        activeTabs: activeTabs,
      };
    default:
      return state;
  }
}

export function setActiveTab(name, number) {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_TAB,
      name: name,
      number: number,
    });
  };
}

export function resetTabs(name) {
  return setActiveTab(name, 1);
}

import React from 'react';
import { get } from 'lodash';
import './styles.scss';

const CheckboxUnlabeled = ({
  input,
  positionTop,
  label,
  labelSize,
  mdFieldSize,
  infoButton,
  options,
  objectKey,
  inline,
  width,
  meta: { touched, error },
}) => {
  let labelClass = 'col-sm-12',
    wrapperClass = 'radio-group';
  if (labelSize) {
    labelClass = ' col-sm-' + labelSize;
    wrapperClass += ' col-sm-' + (12 - labelSize);

    if (mdFieldSize) {
      wrapperClass += ' col-sm-' + mdFieldSize;
    }
  }

  return (
    <div className={touched && error ? 'has-error' : 'form-group'}>
      {label && (
        <div className={labelClass}>
          <div className="label-no-wrap">
            <label className={'control-label' + inline ? ' inline-radio-label' : ''}>
              {label}
            </label>{' '}
            {infoButton}
          </div>
        </div>
      )}

      <div className={wrapperClass}>
        <div>
          {options.map((option, key) => {
            const value = objectKey ? get(option.value, objectKey) : option.value;
            return (
              <div
                className={'radio-container' + (inline ? ' inline' : '')}
                key={key}
                style={width ? { width: width } : {}}
              >
                <div className={'form-radio' + (positionTop ? ' positionTop' : '')}>
                  <input
                    {...input}
                    type="checkbox"
                    id={input.name + option.label}
                    value={option.value}
                    checked={input.value.toString() === value.toString()}
                  />
                  <label htmlFor={input.name + option.label} />
                </div>
                <div className="radio-label" onClick={() => input.onChange(option.value)}>
                  {/*{option.label}*/}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CheckboxUnlabeled;

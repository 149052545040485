import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap';
import { getEnquiry } from '../redux/enquiryActions';
import { Button, Loader } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import EnquiryDetails from '../components/EnquiryDetails';
import EnquiryNotes from '../components/EnquiryNotes';
const NOTES_TAB = 5;

class EnquiryShowContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      activeTab: 1,
    };
  }

  componentWillMount() {
    const { location } = this.props;
    if (location && location.state && location.state === 'tab04') {
      this.handleTab(NOTES_TAB);
    }
    this.props.dispatch(getEnquiry(this.props.params.id));
  }

  componentWillUpdate(newProps, newState) {
    const { location } = this.props;
    if (
      location &&
      location.state &&
      location.state === 'tab04' &&
      location !== newProps.location &&
      newState.activeTab !== NOTES_TAB
    ) {
      console.info('Notes');
      this.handleTab(NOTES_TAB);
    }
  }

  handleTab(key) {
    this.setState({ activeTab: key });
  }

  render() {
    const resource = this.props.enquiry.resources[this.props.params.id];
    const buttons = (
      <Button bsStyle="default" label="back" isBackButton leftIcon="caret-left" />
    );

    return (
      <InternalContainer title="Enquiry" buttons={buttons}>
        {resource ? (
          <Tabs
            activeKey={this.state.activeTab}
            id="enquiry-tabs"
            unmountOnExit
            onSelect={::this.handleTab}
          >
            <Tab eventKey={1} title="Details">
              <EnquiryDetails {...this.props} resource={resource} />
            </Tab>
            <Tab eventKey={5} title="Notes">
              <EnquiryNotes {...this.props} resource={resource} />
            </Tab>
          </Tabs>
        ) : (
          <Loader />
        )}
      </InternalContainer>
    );
  }
}

EnquiryShowContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  enquiry: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    enquiry: state.enquiry,
  };
}

export default connect(mapStateToProps)(EnquiryShowContainer);

import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import NewProductDocumentForm from './NewProductDocumentForm';
import { Button } from "../../../../common/components/Button/Button";

const NewProductDocumentModal = ({ productId, onAddDocument, documentTypesList }) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleAddDocument = (formValues) => {
    return Promise.resolve(onAddDocument(formValues)).then(() => {
      closeModal();
    });
  };

  return (
    <>
      <Button bsStyle="primary" className="pull-right" handleClick={openModal}>
        Add a new product document
      </Button>
      <Modal
        show={showModal}
        bsSize="large"
        onHide={closeModal}
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title">New Product Document</Modal.Title>
        </Modal.Header>
        {showModal && (
          <NewProductDocumentForm
            productId={productId}
            documentTypes={documentTypesList}
            onAddDocument={handleAddDocument}
          />
        )}
      </Modal>
    </>
  );
};
export default NewProductDocumentModal;

import React, { Component } from 'react';
import { FormControl } from 'react-bootstrap';
import './styles.scss';
export default class SimpleFormControl extends Component {
  render() {
    const { input, type, placeholder } = this.props;
    return (
      <div className="simple-form-container">
        <div className="simple-form-item">
          <FormControl
            {...input}
            type={type}
            placeholder={placeholder}
            value={input.value}
          />
        </div>
      </div>
    );
  }
}

import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Box, Loader } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import { actions } from '../redux/ProductReducer';

import { Col, Row } from 'react-bootstrap';
import SchemeContainer
  from '../../schemeManagement/components/SchemeContainer/SchemeContainer';
import getIncludedResource from '../../../helpers/getIncludedResource';
import { bySchemeType } from '../helpers/formatSchemes';
import ProductName
  from '../components/ProductFrontPage/components/ProductName/ProductName';
import ProductStats
  from '../components/ProductFrontPage/components/ProductStats/ProductStats';
import ProductNavigation from '../components/ProductNavigation/ProductNavigation';

const INCLUDES = ['all_scheme_groups'];
const SchemesShowContainer = ({
  params,
  onGetProduct,
  onGetProductsStats,
  onGetDestinations,
  location,
}) => {
  const { productId } = params;
  const {
    product,
    isLoading,
    stats
  } = useSelector((state) => state.productManagement);

  const currentProductId = product?.data?.id;

  useEffect(
    () => {
      if (!currentProductId || currentProductId !== productId) {
        onGetProduct(productId, INCLUDES);
        onGetProductsStats();
      }
    },
    [productId, currentProductId, onGetProduct, onGetProductsStats],
  );
  const refresh = () => {
    onGetProduct(productId, INCLUDES);
    onGetProductsStats();
    onGetDestinations();
  };

  if (isLoading) {
    return (
      <div className="admin-area">
        <InternalContainer title="Product management">
          <Loader/>
        </InternalContainer>
      </div>
    );
  }

  const schemeGroups = getIncludedResource(product?.data, product?.included, 'all_scheme_groups');
  const schemesByType = bySchemeType(schemeGroups);


  const schemeTypes = Object.keys(schemesByType);

  const {
    icon,
    name,
    status,
    product_code: productCode,
    metadata
  } = product?.data?.attributes ?? {};
  const { is_wl_product: isWhitelabel } = metadata ?? {};


  const productStats = stats?.[productCode];


  if (isLoading) {
    return (
      <div className="admin-area">
        <InternalContainer title="Scheme management">
          <Loader/>
        </InternalContainer>
      </div>
    );
  }

  return (
    <div className="admin-area">
      <InternalContainer title="Product management">
        <div>
          <Box className={'product-container'}>
            <ProductName
              icon={icon}
              name={name}
              status={status}
            >
              <ProductNavigation
                id={productId}
                currentLocation={location.pathname}
                isWhitelabel={isWhitelabel}
              />
            </ProductName>
            <div className="hr"/>

            <Row>
              {schemeTypes.map((schemeType) => {
                return (
                  <Col sm={6}>
                    <SchemeContainer
                      schemes={schemesByType[schemeType]}
                      onRefreshPage={refresh}
                    />
                  </Col>

                );
              })}
            </Row>
            <Row>
              {schemeTypes.map((schemeType) => {
                return (
                  <Col sm={6}>
                    <ProductStats
                      display="vertical"
                      stats={productStats?.[schemeType]}
                    />
                  </Col>
                );
              })}
            </Row>
          </Box>
        </div>
      </InternalContainer>
    </div>
  );

};

export default connect(
  (state) => ({
    productManagement: state.productManagement,
  }),
  {
    onGetProduct: actions.getProduct,
    onGetDestinations: actions.getDestinations,
    onGetProductsStats: actions.getProductsStats,
  }
)(SchemesShowContainer);

import { Field } from 'redux-form';
import { HorizontalFormControl, InfoLabel } from '../../../../../../common/components';
import parseFloatValue from '../../../../../../helpers/parseFloatValue';
import React from 'react';
import { Col } from 'react-bootstrap';

const MedicalRules = ({ isViewOnly }) => (
  <Col sm={6}>
    <Field
      name="meta.config.rules.max_score"
      label="Max Medical Score:"
      labelSize={6}
      fieldSize={6}
      component={isViewOnly ? InfoLabel : HorizontalFormControl}
      parse={parseFloatValue}
    />
  </Col>
);
export default MedicalRules;

import React from 'react';
import DocumentDropdownActions from '../DocumentDropdownActions/DocumentDropdownActions';
import DocumentIsGenerating from "../DocumentIsGenerating/DocumentIsGenerating";

import './SupersedingDocumentListItemContainer.scss';

const SupersedingDocumentListItemContainer = ({ document, isGenerating }) => {
  return (
    <div className="superseded-document-list-item-container">
      <div className="superseded-document-content">
        <div className="pull-left">
          <h5>{document.title}</h5>
          <h5>{document.channel_name}</h5>
        </div>
        <div className="pull-right">
          <DocumentDropdownActions
            document={document}
            isGenerating={isGenerating}
            isSuperseding={true}
          />
        </div>
        <DocumentIsGenerating isGenerating={isGenerating}/>
      </div>
    </div>
  );
};

export default SupersedingDocumentListItemContainer;

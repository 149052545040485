import React, { Component } from 'react';
import './styles.scss';

export default class OptionsCheckboxesRedux extends Component {
  render() {
    const {
      infoButton,
      disabledMta,
      disableWinter,
      input,
      options,
      inline,
      price,
      meta: { touched, error },
    } = this.props;

    const winterSports = 'data.attributes.metadata.options.winter_sports';
    return (
      <div className={touched && error ? 'form-group has-error' : 'options-container'}>
        <div className="row">
          <div className="col-sm-12 options-container">
            {options.map((option, key) => {
              return (
                <div
                  className={'options-container' + (inline ? ' inline' : '')}
                  key={key}
                >
                  <div className="options-label-container">
                    <div
                      className="radio-label"
                      onClick={() =>
                        input.onChange(
                          input.value.toString() === option.value.toString()
                            ? option.value
                            : '',
                        )
                      }
                    >
                      {infoButton}
                      {option.label}
                      {price}
                    </div>
                    <div
                      className={
                        'form-radio' +
                        (disabledMta
                          ? ' disabledMta'
                          : disableWinter && input.name === winterSports
                          ? ' disabledMta'
                          : '')
                      }
                    >
                      <input
                        {...input}
                        type="checkbox"
                        disabled={
                          disabledMta || (disableWinter && input.name === winterSports)
                        }
                        id={input.name + option.value}
                        value={option.value}
                        checked={input.value.toString() === option.value.toString()}
                      />
                      <label
                        htmlFor={input.name + option.value}
                        className={
                          disabledMta
                            ? 'disabledMta'
                            : disableWinter && input.name === winterSports
                            ? 'disabledMta'
                            : ''
                        }
                        style={disabledMta ? { cursor: 'not-allowed' } : {}}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {touched && error && <span className="input-error">{error}</span>}
      </div>
    );
  }
}

import React, { Component } from 'react';
import { get } from 'lodash';
import { Row, Col } from 'react-bootstrap';
import './styles.scss';
import InfoButton from '../../../../../common/components/InfoButton/InfoButton';

export default class HazRadioGroup extends Component {
  render() {
    const {
      input,
      definitions,
      options,
      objectKey,
      inline,
      width,
      meta: { touched, error },
    } = this.props;
    return (
      <div className={touched && error ? 'has-error' : ''}>
        <Row>
          <Col sm={12}>
            {options.map((option, key) => {
              const value = objectKey ? get(option.value, objectKey) : option.value;
              return (
                <div
                  className={'options-container haz-options' + (inline ? ' inline' : '')}
                  key={key}
                  style={width ? { width: width } : {}}
                >
                  <div className="haz-label-container">
                    <div className="radio-label">
                      <InfoButton
                        large
                        content={get(definitions, option.value) || definitions}
                        className={'underline-italics'}
                      />
                      <span onClick={() => input.onChange(option.value)}>
                        {option.label}
                      </span>
                    </div>
                    <div className={'form-radio'}>
                      <input
                        {...input}
                        type="radio"
                        id={input.name + option.label}
                        value={option.value}
                        checked={input.value.toString() === value.toString()}
                      />
                      <label htmlFor={input.name + option.label} />
                    </div>
                  </div>
                </div>
              );
            })}
          </Col>
        </Row>
      </div>
    );
  }
}

import React, { Component } from 'react';
import { get, set, map, isEmpty, filter, some } from 'lodash';
import { connect } from 'react-redux';
import Markdown from 'react-markdown';
import ButtonGroup from '../ReduxFormFields/ButtonGroup';
import { Box } from '../../../../../../common/components';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import TextField from '../../../../../../common/components/ReduxFormField';
import { Row, Col } from 'react-bootstrap';
import './styles.scss';

export class Regulation extends Component {
  componentDidMount() {
    this.formatQuestions(
      'data.attributes.organisation.metadata.compliance_details',
      'content.data.attributes.sections.compliance_details',
    );

    this.formatQuestions(
      'data.attributes.organisation.metadata.regulation',
      'content.data.attributes.sections.regulation',
    );
  }

  formatQuestions = (formValuesFields, contentFields) => {
    const { formValues, content, dispatch, change } = this.props;
    const _formValuesFields = get(formValues, formValuesFields);
    const _contentFields = get(content, contentFields);
    const _contentFieldsFormatted = map(_contentFields, (question, key) => {
      return {
        ...question,
        indexKey: key,
        answer: undefined,
      };
    });

    if (isEmpty(_formValuesFields)) {
      dispatch(change(formValuesFields, _contentFieldsFormatted));
    }
  };

  getTrueAnswers = (fieldName) => {
    const { formValues } = this.props;
    const compliance = get(formValues, fieldName);
    return some(compliance, ['answer', true]);
  };

  render() {
    const { content } = this.props;
    const hasComplianceTrueAnswer = this.getTrueAnswers(
      'data.attributes.organisation.metadata.compliance_details',
    );
    const hasProvideRegulationTrueAnswer = this.getTrueAnswers(
      'data.attributes.organisation.metadata.regulation',
    );

    const regulationQuestions = get(
      content,
      'content.data.attributes.sections.regulation',
    );
    const complianceDetailsQuestions = get(
      content,
      'content.data.attributes.sections.compliance_details',
    );

    return (
      <Box>
        <Row>
          <Col sm={12}>
            <h4 className="page-title">
              Have you or any director, principal, partner or senior employee
            </h4>
            <hr />
          </Col>
          <Col sm={12}>
            {map(regulationQuestions, (question, key) => {
              return (
                <div className="brokerap-internal-divider" key={key}>
                  <div className="brokerap-internal-container">
                    <div
                      className="brokerap-question-text"
                      style={{ padding: '15px 15px' }}
                    >
                      <Markdown source={question.question || ''} />
                    </div>
                    {
                      <div
                        className="brokerap-buttons-container"
                        style={{ paddingTop: '15px' }}
                      >
                        <Field
                          name={`data.attributes.organisation.metadata.regulation[${key}].answer`}
                          component={ButtonGroup}
                          inline
                          options={[
                            { label: 'Yes', value: true },
                            { label: 'No', value: false },
                          ]}
                        />
                      </div>
                    }
                  </div>
                </div>
              );
            })}
            {hasProvideRegulationTrueAnswer && (
              <div className="row">
                <div className="col-sm-12">
                  <div className="col-sm-12">
                    <Field
                      label="If you have answered YES to any question, please give full details:"
                      name={'data.attributes.organisation.metadata.regulation_textAnswer'}
                      component={TextField}
                      type="textarea"
                    />
                  </div>
                </div>
              </div>
            )}
            <h4 className="margin-top page-title">
              If you are applying to act as an Introducer Appointed Representative (IAR):
              (not applicable to introducer only applications)
            </h4>
            <hr />
            {map(complianceDetailsQuestions, (question, key) => {
              return (
                <div className="brokerap-internal-divider" key={key}>
                  <div className="brokerap-internal-container">
                    <div
                      className="brokerap-question-text"
                      style={{ padding: '15px 15px' }}
                    >
                      <Markdown source={question.question || ''} />
                    </div>
                    {
                      <div
                        className="brokerap-buttons-container"
                        style={{ paddingTop: '15px' }}
                      >
                        <Field
                          name={`data.attributes.organisation.metadata.compliance_details[${key}].answer`}
                          component={ButtonGroup}
                          inline
                          options={[
                            { label: 'Yes', value: true },
                            { label: 'No', value: false },
                          ]}
                        />
                      </div>
                    }
                  </div>
                </div>
              );
            })}
            <Col sm={12}>
              {hasComplianceTrueAnswer && (
                <Field
                  label="If you have answered YES to any question, please give full details:"
                  name={
                    'data.attributes.organisation.metadata.compliance_details_textAnswer'
                  }
                  component={TextField}
                  type="textarea"
                />
              )}
            </Col>
          </Col>
          <hr />
        </Row>
      </Box>
    );
  }
}

const validate = (value) => {
  const errors = {};
  const complianceQuestions = get(
    value,
    'data.attributes.organisation.metadata.compliance_details',
    [],
  );
  const _unansweredComplianceQuestions = filter(complianceQuestions, function(o) {
    return o.answer === undefined;
  });
  const regulationQuestions = get(
    value,
    'data.attributes.organisation.metadata.regulation',
  );
  const _unansweredRegulationQuestions = filter(regulationQuestions, function(o) {
    return o.answer === undefined;
  });

  const _hasRegulationQuestionsTrueAnswer = some(regulationQuestions, ['answer', true]);
  const _hasComplianceQuestionsTrueAnswer = some(complianceQuestions, ['answer', true]);

  const regulationTextAnswer = get(
    value,
    'data.attributes.organisation.metadata.regulation_textAnswer',
  );
  const complianceTextAnswer = get(
    value,
    'data.attributes.organisation.metadata.compliance_details_textAnswer',
  );

  if (_hasRegulationQuestionsTrueAnswer && isEmpty(regulationTextAnswer)) {
    set(
      errors,
      'data.attributes.organisation.metadata.regulation_textAnswer',
      'Field Required',
    );
  }

  if (_hasComplianceQuestionsTrueAnswer && isEmpty(complianceTextAnswer)) {
    set(
      errors,
      'data.attributes.organisation.metadata.compliance_details_textAnswer',
      'Field Required',
    );
  }

  map(_unansweredComplianceQuestions, (q) => {
    set(
      errors,
      `data.attributes.organisation.metadata.compliance_details[${q.indexKey}].answer`,
      'Answer is required',
    );
  });

  map(_unansweredRegulationQuestions, (q) => {
    set(
      errors,
      `data.attributes.organisation.metadata.regulation[${q.indexKey}].answer`,
      'Answer is required',
    );
  });

  return errors;
};

const FORM_NAME = 'BrokerApplication';
const form = reduxForm({ form: FORM_NAME, validate })(Regulation);

const mapStateToProps = (state, props) => {
  const values = formValueSelector(props.form)(state, 'data', 'attributes');

  return {
    auth: state.auth,
    form: 'BrokerApplication',
    formValues: values,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    product: state.product,
  };
};

export default connect(mapStateToProps)(form);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormGroup, Col, ControlLabel } from 'react-bootstrap';
import SelectMenu from '../SelectMenu/SelectMenu';
import AsyncSelectMenu from '../SelectMenu/AsyncSelectMenu';

export const SELECT_IDENTIFIER = 'organisations';

class SelectMenuOrganisation extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    label: PropTypes.string,
    labelSize: PropTypes.number,
    infoButton: PropTypes.element,
    name: PropTypes.string,
    meta: PropTypes.object,
    object: PropTypes.bool,
    isMulti: PropTypes.bool,
  };

  renderSelectMenu() {
    const { isObject, byUser, filters = [], async, key } = this.props;

    if (async) {

      return (
        <AsyncSelectMenu
          key={key || 'select-organisation'}
          url={SELECT_IDENTIFIER}
          optionValuePath={'id'}
          filters={filters}
          {...this.props}
        />
      );
    }

    if (isObject) {
      return (
        <SelectMenu
          source={SELECT_IDENTIFIER}
          sourceKey={'Object'}
          filter={byUser ? `?filter[users]=${byUser}` : ''}
          isObject
          {...this.props}
        />
      );
    }

    return (
      <SelectMenu
        source={SELECT_IDENTIFIER}
        filter={byUser ? `?filter[users]=${byUser}` : ''}
        optionValuePath={'id'}
        {...this.props}
      />
    );
  }

  render() {
    const { infoButton, meta, label, labelSize, smFieldSize } = this.props;

    if (label) {
      const labelSmSize = labelSize || 2;
      const menuSize = smFieldSize || 12 - labelSize;

      return (
        <FormGroup className={meta && meta.touched && meta.error ? ' has-error' : ''}>
          <Col sm={labelSmSize}>
            <ControlLabel>
              {label} {infoButton}
            </ControlLabel>
          </Col>
          <Col sm={menuSize}>
            {this.renderSelectMenu()}
          </Col>
        </FormGroup>
      );
    }

    return this.renderSelectMenu();
  }
}

export default connect()(SelectMenuOrganisation);

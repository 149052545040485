import React, { Component } from 'react';
import { Field } from 'redux-form';
import SelectBrokerCampaigns from './SelectBrokerCampaigns';
import { Button } from '../../../../common/components/Button/Button';
import './styles.scss';

class SelectBrokerFields extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    if (this.props.fields.length === 0) {
    }
  }

  removeBroker = (fields, index) => {
    fields.remove(index);
  };

  render() {
    const { fields, campaign, formValues, brokers, edit } = this.props;
    return (
      <div>
        {fields.map((member, index) => {
          return (
            <div key={index}>
              <Field
                broker={edit ? brokers[index] : null}
                label={index === 0 ? 'Broker' : ' '}
                name={'broker' + index}
                campaign={campaign}
                formValues={formValues}
                component={SelectBrokerCampaigns}
                change={this.props.change}
                removeBroker={this.removeBroker}
                placeholder="Search..."
                edit={edit}
                labelSize={4}
                fields={fields}
                index={index}
              />
            </div>
          );
        })}
        <div className="flex-right">
          <Button type="button" handleClick={() => fields.push({})}>
            Add Broker
          </Button>
        </div>
      </div>
    );
  }
}

export default SelectBrokerFields;

import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
} from '../../../../common/components';
import {
  cacheTransactions,
  cachePremiums,
  cacheMatchedEntries
} from '../../redux/accountActions';
import { Modal } from 'react-bootstrap';
import MakeAdjustmentForm from './MakeAdjustmentForm';
const MakeAdjustmentButton  = ({
  amount,
  label,
  block,
  accountId,
  matchedTransactions,
  matchedPremiums,
  onPostAdjustment,
  onCachePremiums,
  onCacheTransactions,
  onCacheMatchedEntries,

}) =>  {

  const [ show, setShow] = useState(false);


  const handleOpen = ()  => {

    const matchedTransactionIds = matchedTransactions?.map((transaction) => (transaction.id));
    const matchedPremiumIds = matchedPremiums?.map((premium) => (premium.id));

    onCacheTransactions(matchedTransactions);
    onCachePremiums(matchedPremiums);
    onCacheMatchedEntries(matchedTransactionIds, matchedPremiumIds);
    setShow(true);

  };

  const handleCancel = () => {
    onCacheTransactions([]);
    onCachePremiums([]);
    setShow(false);
  };

  const postAdjustment = (values) => {

    onPostAdjustment(values);
    setShow(false);
  };

    // const { label, handleSubmit, submitting, block, accountId } = this.props;
    const title = label || 'Post Adjustment';

    return (
      <>
        <Button
          className="matching-sidebar-btn"
          bsStyle="primary"
          label={title}
          handleClick={handleOpen}
          block={block}
        />
        <Modal
          show={show}
          onHide={handleCancel}
        >
          <Modal.Header closeButton>
          <span className={'title-case'}>
              {title}
          </span>

          </Modal.Header>
          <Modal.Body>
            <div>
              <MakeAdjustmentForm
                amount={amount}
                accountId={accountId}
                onPostAdjustment={postAdjustment}
              />
            </div>


          </Modal.Body>
        </Modal>
      </>
    );
};





export default connect(null, {
  onCacheTransactions: cacheTransactions,
  onCachePremiums: cachePremiums,
  onCacheMatchedEntries: cacheMatchedEntries
})(MakeAdjustmentButton);

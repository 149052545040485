import React, { Component } from 'react';
import { Box } from '../../../../../../common/components';
import { formValueSelector, FieldArray } from 'redux-form';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import './styles.scss';
import Directors from '../../../../../product/components/Directors';

const METADATA = 'data.attributes.organisation.metadata';

class Management extends Component {
  render() {
    const { formValues } = this.props;
    return (
      <Box>
        <Row>
          <Col sm={12}>
            <h4 className="page-title">Management Information</h4>
            <hr/>
            <FieldArray
              name={`${METADATA}.directors`}
              formValues={formValues}
              component={Directors}
              change={this.props.change}
              dispatch={this.props.dispatch}
            />
          </Col>
        </Row>
      </Box>
    );
  }
}

const mapStateToProps = (state, props) => {
  const values = formValueSelector(props.form)(state, 'data');

  return {
    auth: state.auth,
    initialValues: props.resource,
    form: props.form,
    formValues: values,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    product: state.product,
  };
};

export default connect(mapStateToProps)(Management);

export default function formatSchemesByStatus(schemes, insurerID) {
  return schemes.reduce((carry, scheme) => {
    const child = {
      value: scheme.id,
      label: `${scheme.attributes.name} v${scheme.attributes.version}`,
    };
    const nodeIndex = carry.findIndex((node) => {
      return node.value === scheme.attributes.status + '-' + insurerID;
    });

    switch (scheme.attributes.status) {
      case 'pending':
        if (nodeIndex < 0) {
          carry.push({
            value: 'pending-' + insurerID,
            label: 'pending',
            className: 'pending checkbox-toggle-container ',
            children: [child],
          });
        } else {
          carry[nodeIndex].children.push(child);
        }
        return carry;
      case 'active':
        if (nodeIndex < 0) {
          carry.push({
            value: 'active-' + insurerID,
            label: 'active',
            className: 'active checkbox-toggle-container ',
            children: [child],
          });
        } else {
          carry[nodeIndex].children.push(child);
        }
        return carry;

      case 'test':
        if (nodeIndex < 0) {
          carry.push({
            value: 'test-' + insurerID,
            label: 'test',
            className: 'test checkbox-toggle-container ',
            children: [child],
          });
        } else {
          carry[nodeIndex].children.push(child);
        }
        return carry;
    }

    return carry;
  }, []);
}

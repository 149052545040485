export default function listContains(item, list) {
  if (typeof list == undefined) {
    return false;
  }

  for (let i = 0; i < list.length; i++) {
    if (
      list.hasOwnProperty(i) &&
      ((list[i].id && list[i].id === item.id) ||
        (list[i].data && list[i].data.id === item.data.id))
    ) {
      return i;
    }
  }

  return false;
}

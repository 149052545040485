import React, { Component } from 'react';
import { capitalize, get } from 'lodash';
import { InfoLabel, Button, ButtonBar } from '../../../common/components';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import getIncludedResource2 from '../../../helpers/getIncludedResource2';
import MdInsertDriveFile from 'react-icons/lib/fa/file-archive-o';
import { Modal, ProgressBar } from 'react-bootstrap';
import './styles.scss';

class PrintJobsDetails extends Component {
  renderButtons() {
    const { resource, onCancel, onRun, onPost } = this.props;
    const attributes = resource.data.attributes;
    const state = attributes.state;

    const cancel = (
      <Button
        key={0}
        className="pull-right pad-right"
        bsStyle="primary"
        handleClick={onCancel}
      >
        Cancel Print Job
      </Button>
    );
    const run = (
      <Button
        key={1}
        className="pull-right pad-right"
        bsStyle="primary"
        handleClick={onRun}
      >
        {state === 'failed' ? 'Retry' : 'Run'} Print Job
      </Button>
    );
    const post = (
      <Button
        key={2}
        className="pull-right pad-right"
        bsStyle="primary"
        handleClick={onPost}
      >
        Post Print Job
      </Button>
    );

    let buttonSet = [];

    switch (state) {
      case 'failed':
      case 'pending':
        buttonSet.push(cancel, run);
        break;
      case 'success':
        if (!attributes.output.posted) {
          buttonSet.push(post);
        }

        break;
      case 'completed':
      case 'cancelled':
      case 'processing':
      default:
        // No Buttons to show for these
        break;
    }

    return buttonSet.length > 0 ? (
      <div>
        <br />
        <ButtonBar>{buttonSet}</ButtonBar>
      </div>
    ) : null;
  }

  displayFileName(cell, row) {
    return (
      <div>
        <MdInsertDriveFile size={40} />
        <span> {row.name}</span>
      </div>
    );
  }

  render() {
    const { resource, onDownload } = this.props;
    let progressBar = null;
    if (get(resource, 'data.attributes.state') === 'processing') {
      const { done = 0, total = 0, percentage = 0 } = resource.data.attributes.output;
      progressBar = (
        <ProgressBar
          bsStyle="info"
          now={percentage}
          label={`${done}/${total} (${percentage})%`}
          // active
        />
      );
    }

    const modal = (
      <div>
        <ModalHeader closeButton>
          <ModalTitle>Print Job Processing</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <h4>
            Your Print Job has been submitted and will be processed shortly - no need to
            wait, you can return back later.
          </h4>
        </ModalBody>
        <ModalFooter>
          <Button
            className="pull-right settings-button"
            bsStyle="default"
            leftIcon="times"
            label="Close"
            handleClick={this.props.closeModal}
          />
        </ModalFooter>
      </div>
    );

    const itemsInTable = getIncludedResource2(
      resource.data,
      resource.included,
      'documents',
      true,
    ).map((item) => ({
      id: item.id,
      item: item.attributes.detailedReference,
    }));
    let files = [];
    const state = resource.data.attributes.state;

    if (state === 'success') {
      files = getIncludedResource2(resource.data, resource.included, 'files', true).map(
        (item) => ({
          id: item.id,
          name: 'Print Job: ' + item.id + '.zip',
        }),
      );
    }

    return (
      <div className="print-job-detail">
        <Modal
          aria-labelledby="modal-label"
          style={{
            transform: 'translateY(300px)',
            opacity: '1',
          }}
          backdropStyle={backdropStyle}
          show={this.props.openModal}
          onHide={this.props.closeModal}
        >
          {modal}
        </Modal>

        <h4>Print Job - {resource.data.attributes.reference}</h4>

        <form>
          <div className="row center-block print-job-data-container">
            <div className="col-sm-6">
              <div className="form-horizontal">
                <InfoLabel
                  dateTime
                  label="Started at:"
                  labelSize={4}
                  mdFieldSize={7}
                  value={resource.data.attributes.started_at}
                />
                {resource.data.attributes.state === 'success' && (
                  <InfoLabel
                    label="Posted:"
                    labelSize={4}
                    mdFieldSize={7}
                    value={resource.data.attributes.output.posted ? 'Yes' : 'No'}
                  />
                )}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-horizontal">
                <InfoLabel
                  dateTime
                  label="Stopped at:"
                  labelSize={4}
                  mdFieldSize={7}
                  value={resource.data.attributes.stopped_at}
                />
                <InfoLabel
                  label="Status:"
                  labelSize={4}
                  mdFieldSize={7}
                  value={capitalize(resource.data.attributes.state)}
                />
                {progressBar}
              </div>
            </div>
          </div>

          <div className="table-container">
            <BootstrapTable data={itemsInTable} bordered={false} hover>
              <TableHeaderColumn dataField="id" isKey hidden>
                ID
              </TableHeaderColumn>
              <TableHeaderColumn dataField="item">Item(s)</TableHeaderColumn>
            </BootstrapTable>
          </div>

          {state === 'success' && (
            <div className="table-container-files table-container-files--cursor-on">
              <br />
              <BootstrapTable
                data={files}
                bordered={false}
                hover
                options={{ onRowClick: onDownload }}
              >
                <TableHeaderColumn dataField="id" isKey hidden>
                  ID
                </TableHeaderColumn>
                <TableHeaderColumn dataField="name" dataFormat={this.displayFileName}>
                  Print Job File(s)
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
          )}
          {this.renderButtons()}
        </form>
      </div>
    );
  }
}

const ModalHeader = Modal.Header;
const ModalTitle = Modal.Title;
const ModalBody = Modal.Body;
const ModalFooter = Modal.Footer;

const modalStyle = {
  position: 'fixed',
  zIndex: 1040,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};

const backdropStyle = {
  ...modalStyle,
  zIndex: 100,
  backgroundColor: '#000',
  opacity: 0.5,
};

export default PrintJobsDetails;

import React from 'react';
import { Field, FieldArray, FormSection, } from 'redux-form';
import { HorizontalFormControl } from '../../../../common/components';
import { Col, FormGroup } from 'react-bootstrap';
import TextArea from '../../../content/components/TextArea';

import ProductInfoFieldArray
  from './components/ProductInfoFieldArray/ProductInfoFieldArray';
import ProductKeyedInfoFieldArray
  from './components/ProductKeyedInfoFieldArray/ProductKeyedInfoFieldArray';
import { ProductInfoContainer } from "../ProductInfoContainer/ProductInfoContainer";
import LogoField from "./components/LogoField/LogoField";


const LABEL_SIZE = 7;


const DatePickerRules = () => {
  return (
    <ProductInfoContainer title={' Date picker rules:'}>
      <FormGroup>
        <Col sm={6}>
          <Field
            name={'rules.age_limit.adult.min_age'}
            labelSize={LABEL_SIZE}
            label={'Adult Min Age:'}
            component={HorizontalFormControl}
          />
        </Col>
        <Col sm={6}>
          <Field
            name={'rules.age_limit.adult.max_age'}
            labelSize={LABEL_SIZE}
            label={'Adult Max Age:'}
            component={HorizontalFormControl}
          />

        </Col>
      </FormGroup>
      <FormGroup>
        <Col sm={6}>
          <Field
            name={'rules.age_limit.children.min_age'}
            labelSize={LABEL_SIZE}
            label={'Child Min Age:'}
            component={HorizontalFormControl}
          />
        </Col>
        <Col sm={6}>
          <Field
            name={'rules.age_limit.children.max_age'}
            labelSize={LABEL_SIZE}
            label={'Child Max Age:'}
            component={HorizontalFormControl}
          />
        </Col>
      </FormGroup>

      <FormGroup>
        <Col sm={6}>

          <Field
            name={'rules.age_limit.family.adult.min_age'}
            labelSize={LABEL_SIZE}
            label={'Family Adult Min Age:'}
            component={HorizontalFormControl}
          />
        </Col>
        <Col sm={6}>
          <Field
            name={'rules.age_limit.family.adult.max_age'}
            labelSize={LABEL_SIZE}
            label={'Family Adult Max Age:'}
            component={HorizontalFormControl}
          />
        </Col>
      </FormGroup>
      <FormGroup>
        <Col sm={6}>

          <Field
            name={'rules.age_limit.family.children.min_age'}
            labelSize={LABEL_SIZE}
            label={'Family Child Min Age:'}
            component={HorizontalFormControl}
          />
        </Col>
        <Col sm={6}>


          <Field
            name={'rules.age_limit.family.children.max_age'}
            labelSize={LABEL_SIZE}
            label={'Family Child Max Age:'}
            component={HorizontalFormControl}
          />
        </Col>
      </FormGroup>
      <FormGroup>
        <Col sm={6}>

          <Field
            name={'rules.st_advance_months'}
            labelSize={LABEL_SIZE}
            label={'Single Trip Advance Months:'}
            component={HorizontalFormControl}
          />
        </Col>
        <Col sm={6}>


          <Field
            name={'rules.amt_advance_days'}
            labelSize={LABEL_SIZE}
            label={'Annual Trip Advance Days'}
            component={HorizontalFormControl}
          />
        </Col>
      </FormGroup>
      <FormGroup>
        <Col sm={6}>

          <Field
            name={'rules.max_trip_duration'}
            labelSize={LABEL_SIZE}
            label={'Max Trip Durations:'}
            component={HorizontalFormControl}
          />
        </Col>
        <Col sm={6}>


          <Field
            name={'rules.backdate_cover'}
            labelSize={LABEL_SIZE}
            label={'Backdate cover'}
            component={HorizontalFormControl}
          />
        </Col>
      </FormGroup>
    </ProductInfoContainer>
  );

};


export const EditProductDetails = ({ isSubmitting }) => {
  return (
    <>
      <FormSection name={'data.attributes'}>
        <>
          <ProductInfoContainer title={'Name'}>
            <Field
              name={'name'}
              component={HorizontalFormControl}
            />
          </ProductInfoContainer>
          <ProductInfoContainer title={'Icon'}>
            <Field
              name={'icon'}
              component={LogoField}
              isSubmitting={isSubmitting}
            />
          </ProductInfoContainer>


          <ProductInfoContainer title={'Summary'}>
            <Field
              name={'summary'}
              component={TextArea}
            />
          </ProductInfoContainer>
          <ProductInfoContainer title={'Description'}>
            <Field
              name={'description'}
              component={TextArea}
            />
          </ProductInfoContainer>

          <FormSection name={'metadata'}>
            <>

              <ProductInfoContainer title={'Residencies'}>
                <FieldArray
                  name={'residencies'}
                  component={ProductKeyedInfoFieldArray}
                />
              </ProductInfoContainer>
              <ProductInfoContainer title={'Annual Trip Durations'}>
                <FieldArray
                  name={'annual_trip_durations'}
                  component={ProductInfoFieldArray}
                />
              </ProductInfoContainer>
              <ProductInfoContainer title={'Regions'}>
                <FieldArray
                  name={'regions'}
                  component={ProductKeyedInfoFieldArray}
                />
              </ProductInfoContainer>

              <ProductInfoContainer title={'Single Trip Group Types'}>
                <FieldArray
                  name={'group_type_trip_single'}
                  component={ProductKeyedInfoFieldArray}
                />
              </ProductInfoContainer>

              <ProductInfoContainer title={'Annual Trip Group Types'}>
                <FieldArray
                  name={'group_type_trip_annual'}
                  component={ProductKeyedInfoFieldArray}
                />
              </ProductInfoContainer>
              <ProductInfoContainer title={'Options'}>
                <FieldArray
                  name={'options'}
                  component={ProductKeyedInfoFieldArray}
                />
              </ProductInfoContainer>

              <ProductInfoContainer title={'B2B Pre-quote declaration'}>
                <Field
                  name={'pre_quote_declaration.bo'}
                  component={TextArea}
                  labelSize={LABEL_SIZE}
                />
              </ProductInfoContainer>
              <ProductInfoContainer title={'B2C Pre-quote declaration'}>
                <Field
                  name={'pre_quote_declaration.b2c'}
                  component={TextArea}
                  labelSize={LABEL_SIZE}
                />
              </ProductInfoContainer>

              <ProductInfoContainer title={'B2B Purchase declaration'}>
                <Field
                  name={'purchase_declaration.bo'}
                  component={TextArea}
                  labelSize={LABEL_SIZE}
                />
              </ProductInfoContainer>
              <ProductInfoContainer title={'B2C Purchase declaration'}>
                <Field
                  name={'purchase_declaration.b2c'}
                  component={TextArea}
                  labelSize={LABEL_SIZE}
                />
              </ProductInfoContainer>

              <DatePickerRules/>
            </>
          </FormSection>
        </>


      </FormSection>


    </>
  );
};


import React, { useState, useMemo } from 'react';
import { reduxForm } from 'redux-form';
import { connect, useSelector } from 'react-redux';
import { Form, Row, Col } from 'react-bootstrap';
import Sidebar from './components/Sidebar/Sidebar';
import {
  getAccount,
  clearAccount,
  runBulkMatching,
  manageBulkMatching,
  saveAccountMatching,
  postAdjustment,
  savePaymentsAndReceipts,
  makePayment
} from '../../redux/accountActions';


import {
  LedgerEntryContainer
} from './components/LedgerEntriesContainer/LedgerEntriesContainer';
import AccountLabel from './components/AccountLabel/AccountLabel';
import { getBalance } from './helpers';

import './styles.scss';


const getDifference = (matchedPremiums, matchedTransactions) => {
  const matchedPremiumsAmount = getBalance(matchedPremiums, false);
  const matchedTransactionsAmount = getBalance(matchedTransactions, false);

  const difference = matchedPremiumsAmount + matchedTransactionsAmount;
  const balancesMatch =
    matchedTransactions.length + matchedPremiums.length > 0 &&
    Math.abs(difference).toFixed(2) === '0.00';

  return {
    difference: difference,
    balancesMatch: balancesMatch,
  };
};

const AccountMatching = ({
  handleSubmit,
  resource,
  transactions,
  matchedTransactions,
  premiums,
  matchedPremiums,
  transactionFilter,
  onReset,
  onRefresh,
  onManageBulkMatching,
  onRunBulkMatching,
  onSaveAccountMatching,
  onPostAdjustment,
  onSavePaymentsAndReceipts,
  onMakePayment
}) => {

  const accountId = resource.data.id;
  const [matching, setMatching] = useState({
    unmatchedTransactions: transactions,
    matchedTransactions: matchedTransactions || [],
    unmatchedPremiums: premiums,
    matchedPremiums: matchedPremiums || [],
  });

  const difference = useMemo(() => getDifference(matching.matchedPremiums, matching.matchedTransactions), [matching]);


  const {
    isFetchingTransactions,
    isFetchingPremiums,
    isSubmitting,
    cachedTransactions,
    // cachedPremiums,
  } = useSelector((state) => state.account);
  const reset = () => {

    setMatching({
      unmatchedTransactions: transactions,
      matchedTransactions: [],
      unmatchedPremiums: premiums,
      matchedPremiums: []
    });

    onReset(accountId);
  };

  const moveItem = (item, from, to) => {

    const fromArray = matching?.[from];
    const toArray = matching?.[to];
    const newFrom = fromArray?.filter((fromItem) => fromItem.id !== item.id);
    toArray?.push(item);

    setMatching({
      ...matching,
      [from]: newFrom,
      [to]: toArray,

    });
  };

  if (!resource) {
    return null;
  }

  const manageBulkMatching = (matchingDate, reset, openWindow, queue) => {
    onManageBulkMatching(accountId, matchingDate, reset, openWindow, queue);
  };

  const runBulkMatching = (matchingDate) => {
    onRunBulkMatching(accountId, matchingDate);
  };

  const postAdjustment = (values) => {

    const cachedTransactionIds = cachedTransactions?.map((transaction) => transaction.id);
    onPostAdjustment(accountId, values, transactionFilter, cachedTransactionIds);
  };
  const update = () => {
    if (!difference.balancesMatch) {
      return false;
    }

    return Promise.resolve(onSaveAccountMatching(
      accountId,
      {
        meta: {
          date: form.date,
          matched_premiums: matching.matchedPremiums,
          matched_transactions: matching.matchedTransactions,
        }
      },
    )).then(() => {
      setMatching({
        matchedPremiums: [],
        unmatchedPremiums: matching.unmatchedPremiums,
        matchedTransactions: [],
        unmatchedTransactions: matching.unmatchedTransactions,

      });

      onRefresh(accountId);
    });

  };

  const makePayment = (values) => {
    return Promise.resolve(onMakePayment(
      values,
      accountId,
    )).then(() => {
      setMatching({
        matchedPremiums: [],
        unmatchedPremiums: matching.unmatchedPremiums,
        matchedTransactions: [],
        unmatchedTransactions: matching.unmatchedTransactions,

      });

      onRefresh(accountId);
    });

  };

  const selectAll = () => {
    const matchedPremiums = matching.matchedPremiums?.concat(matching.unmatchedPremiums);
    const matchedTransactions = matching.matchedTransactions?.concat(matching.unmatchedTransactions);

    setMatching({
      matchedPremiums,
      unmatchedPremiums: [],
      matchedTransactions,
      unmatchedTransactions: []

    });
  };
  //
  const unselectAll = () => {
    const unmatchedPremiums = matching.matchedPremiums?.concat(matching.unmatchedPremiums);
    const unmatchedTransactions = matching.matchedTransactions?.concat(matching.unmatchedTransactions);

    setMatching({
      matchedPremiums: [],
      unmatchedPremiums,
      matchedTransactions: [],
      unmatchedTransactions

    });

  };


  return (
    <Form onSubmit={handleSubmit(update)}>
      <h2 className="resource-name">
        {resource.data.attributes.name}
        <AccountLabel account={resource}/>
      </h2>
      <Row className="accounts-matching">
        <Col sm={9} md={10}>
          <Row className="transaction-container">
            <Col sm={6}>
              <LedgerEntryContainer
                type={'unmatchedTransactions'}
                loader={isFetchingTransactions}
                entries={matching?.unmatchedTransactions ?? []}
                balance={getBalance(matching.unmatchedTransactions)}
                onMoveEntry={moveItem}
              />
            </Col>
            <Col sm={6}>

              <LedgerEntryContainer
                type={'matchedTransactions'}
                loader={isFetchingTransactions}
                entries={matching?.matchedTransactions ?? []}
                balance={getBalance(matching?.matchedTransactions ?? [])}
                onMoveEntry={moveItem}
              />

              {/*{renderLedgerEntriesContainer('matchedTransactions')}*/}
            </Col>
          </Row>
          <Row className="premium-container">
            <Col sm={6}>

              <LedgerEntryContainer
                type={'unmatchedPremiums'}
                isLoading={isFetchingPremiums}
                entries={matching?.unmatchedPremiums ?? []}
                onMoveEntry={moveItem}
                balance={getBalance(matching?.unmatchedPremiums ?? [])}
              />

              {/*{renderLedgerEntriesContainer('unmatchedPremiums')}*/}
            </Col>
            <Col sm={6}>
              <LedgerEntryContainer
                type={'matchedPremiums'}
                loader={isFetchingPremiums}
                entries={matching.matchedPremiums}
                onMoveEntry={moveItem}
                balance={getBalance(matching.matchedPremiums)}
              />

            </Col>
          </Row>

        </Col>
        <Col md={3} lg={2}>
          {/*{renderSideBar()}*/}
          <Sidebar
            isSubmitting={isSubmitting}
            unmatchedTransactions={matching.unmatchedTransactions}
            matchedTransactions={matching.matchedTransactions}
            matchedPremiums={matching.matchedPremiums}
            unmatchedPremiums={matching.unmatchedPremiums}
            accountType={resource.data.attributes.account_type}
            onSelectAll={selectAll}
            onDeselectAll={unselectAll}
            onReset={reset}
            onManageBulkMatching={manageBulkMatching}
            onRunBulkMatching={runBulkMatching}
            onPostAdjustment={postAdjustment}
            onSavePaymentsAndReceipts={onSavePaymentsAndReceipts}
            onMakePayment={makePayment}
            accountId={accountId}

            difference={difference}
            transactionFilter={transactionFilter}

          />
        </Col>
      </Row>
    </Form>
  );

};
const FORM_NAME = 'AccountMatching';
const form = reduxForm({ form: FORM_NAME })(AccountMatching);

export default connect(
  null,
  {
    onClearAccount: clearAccount,
    onRunBulkMatching: runBulkMatching,
    onManageBulkMatching: manageBulkMatching,
    onSaveAccountMatching: saveAccountMatching,
    onGetAccount: getAccount,
    onPostAdjustment: postAdjustment,
    onSavePaymentsAndReceipts: savePaymentsAndReceipts,
    onMakePayment: makePayment,
  },
)(form);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { saveQuotes, saveReferral } from '../../redux/productActions';
import { Button } from '../../../../common/components';
import '../QuoteSaveAndExit/styles.scss';

class QuoteSaveExitPolicy extends Component {
  saveQuotes(application) {
    const { dispatch, isReferral, currentProduct } = this.props;

    if (isReferral) {
      return dispatch(saveReferral(application, currentProduct?.data?.id));
    }

    return dispatch(saveQuotes(application, currentProduct?.data?.id));
  }

  render() {
    const { className, product, handleSubmit } = this.props;
    return (
      <div className={className || 'save-quote-btn-sidebar'}>
        <Button
          label={'Save and Exit'}
          bsStyle={'primary'}
          block
          isLoading={product.isSaving}
          handleClick={handleSubmit(this.saveQuotes.bind(this))}
        />
      </div>
    );
  }
}

export default connect()(QuoteSaveExitPolicy);

import React, { useEffect, useMemo } from 'react';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { FieldArray, Form, getFormValues, reduxForm } from 'redux-form';
import OptionsFieldArray from './components/OptionsFieldArray';
import { actions } from '../../../productManagement/redux/ProductReducer';
import { Box } from '../../../../common/components';
import { Alert } from 'react-bootstrap';

const getOptionsList = (productOptions, schemeOptions) => {
  return productOptions
    ?.filter((option) => {
      if (!option?.attributes?.active) {
        return true;
      }
      const alreadyOnScheme = !!schemeOptions?.find((schemeOption) => {
        return schemeOption.key === option?.attributes?.code;
      });
      return !alreadyOnScheme;
    })
    .map((option) => ({ value: option, label: option.attributes.name }));
};

const OptionsForm = ({
  handleSubmit,
  formValues,
  isViewOnly,
  getProductOptions,
  productId,
  coverLevels,
}) => {
  const { productOptions } = useSelector((state) => state.productManagement);

  useEffect(
    () => {
      if (!productOptions) {
        getProductOptions(productId);
      }
    },
    [productOptions, getProductOptions, productId],
  );

  const options = formValues?.meta?.config?.options;
  const optionList = useMemo(() => getOptionsList(productOptions, options), [
    productOptions,
    options,
  ]);
  return (
    <Box>
      <div className="product-container">
        {!isViewOnly && (
          <Alert bsStyle={'info'}>
            Adding options here does not automatically add them to the quote journey. If
            you
            want the options to show in the quote journey add them in the product
            management
            section of this product

          </Alert>
        )}
        <Form onSubmit={handleSubmit}>
          <h2>Additional Options - Rates</h2>
          <FieldArray
            name={'meta.config.options'}
            component={OptionsFieldArray}
            isViewOnly={isViewOnly}
            optionList={optionList}
            coverLevels={coverLevels}
          />
        </Form>
      </div>
    </Box>
  );
};

export default compose(
  connect(
    (state, props) => {
      return {
        formValues: getFormValues(props.formName)(state),
        destroyOnUnmount: false,
        enableReinitialize: true,

        form: props.formName,
      };
    },
    {
      getProductOptions: actions.getProductOptions,
    },
  ),
  reduxForm({}),
)(OptionsForm);

import React from 'react';
import { Field } from 'redux-form';
import EditDeclineField from './EditDeclineField';

const EditDeclineFieldArray = ({
  fields,
  declines,
  regions,
  coverLevels,
  medicalQuestionKeys,
  productType,
}) => {
  const handleRemove = (index) => {
    fields.remove(index);
  };
  return (
    <div>
      {fields.map((field, index) => {
        return (
          <div key={index}>
            <>
              <Field
                name={field}
                component={EditDeclineField}
                declines={declines}
                regions={regions}
                medicalQuestionKeys={medicalQuestionKeys}
                coverLevels={coverLevels}
                productType={productType}
                index={index}
                onRemove={handleRemove}
              />
              <hr/>
            </>
          </div>
        );
      })}
    </div>
  );
};

export default EditDeclineFieldArray;

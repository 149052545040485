import React, { Component } from 'react';

import { connect } from 'react-redux';
import { get, set, find, isEqual, sortBy, startCase, isEmpty } from 'lodash';
import { StickyContainer, Sticky } from 'react-sticky';
import BenefitsModal from '../BenefitsModal';
import { Field } from 'redux-form';
import { TextField } from '../../../../common/components/ReduxFormField';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import QuoteSaveExitPolicy from '../QuoteSaveExitPolicy';
import listContains from '../../../../helpers/listContains';
import benefitByKey from '../../../../helpers/benefitByKey';
import displayCurrency from '../../../../helpers/displayCurrency';
import { saveReferral } from '../../redux/productActions';
import { Icon, Button } from '../../../../common/components';
import { Row, Col, ControlLabel } from 'react-bootstrap';
import Loader from '../../../../common/components/Loader/Loader';
import { isBroker } from '../../../auth/redux/authActions';
import './styles.scss';

class AvailableQuotes extends Component {

  componentWillMount() {
    // clear schemes selected
    const { updateSelection } = this.props;

    let selection = get(this.props.formValues, 'data.relationships.schemes.data', []);
    if (selection.length) {
      updateSelection.removeAll('data.relationships.schemes.data');
    }
  }

  saveReferral(application) {
    // make a deep clone of the application object before updating
    const referredApplication = JSON.parse(JSON.stringify(application));

    const referrals = this.productReferrals(this.props.availableQuotes);
    if (referrals.length) {
      set(referredApplication, 'data.attributes.metadata.referrals', referrals);

      const note = get(referredApplication, 'meta.note.content', '');
      set(
        referredApplication,
        'meta.note.content',
        referrals.join(', ') + (note ? '\n' + note : ''),
      );
    }

    return this.props.dispatch(
      saveReferral(referredApplication, this.props.currentProduct?.data?.id),
    );
  }

  handleSchemeSelect(scheme) {
    const { updateSelection, selectedSchemes, clearPremiumIndication } = this.props;
    const index = listContains(scheme, selectedSchemes);

    if (index !== false) {
      updateSelection.remove('data.relationships.schemes.data', index);
    } else {
      if (scheme) {
        clearPremiumIndication();
        updateSelection.push('data.relationships.schemes.data', scheme);
      }
    }
  }

  schemeIsSelected(scheme) {
    const { selectedSchemes } = this.props;
    if (scheme) {
      const index = listContains(scheme, selectedSchemes);
      if (index !== false) {
        return true;
      }
    }

    return false;
  }

  hasAnnualScheme(scheme, quotes) {
    if (scheme.cover_level === 'Cps_full_cover') {
      return false;
    }

    return find(quotes, (quote) => {
      return (
        quote.meta.scheme.scheme_type === 'annual' &&
        quote.meta.scheme.insurer === scheme.insurer &&
        quote.meta.scheme.cover_level === scheme.cover_level
      );
    });
  }

  componentWillUpdate(nextProps) {
    const {
      dispatch,
      availableQuotes,
      change,
      selectedSchemes,
      updateSelection,
      product,
      requiresReferral
    } = this.props;

    if (!isEqual(nextProps.availableQuotes, availableQuotes)) {
      /* Update any referrals */
      const referrals = this.productReferrals(availableQuotes);
      if (referrals.length) {
        dispatch(change('data.attributes.metadata.referrals', referrals));
      } else {
        dispatch(change('data.attributes.metadata.referrals', []));
      }

      availableQuotes.map((quote) => {
        if (get(quote, 'meta.premium.gross') === 0) {
          const schemeObject = getIncludedResource(
            quote.meta.scheme.id,
            product.schemes,
            false,
            'products/schemes',
          );
          const index = listContains(schemeObject, selectedSchemes);
          if (index !== false) {
            updateSelection.remove('data.relationships.schemes.data', index);
          }
        }
      });
    }

    /* If there is one scheme and it has calculated, pre-select it. */
    if (!nextProps.isTestCenter) {
      // Check for Referrals
      if (
        nextProps.product.schemes.length === 1 &&
        nextProps.availableQuotes.length === 1 &&
        !requiresReferral
      ) {
        const singleScheme = nextProps.availableQuotes[0].meta.scheme;

        const nextSelectedSchemes = nextProps.selectedSchemes || [];
        const alreadyAdded = nextSelectedSchemes.find((scheme) => {
          return scheme.id === singleScheme.id;
        });


        if (!nextProps.product.isCalculating && !alreadyAdded) {
          nextProps.updateSelection.push(
            'data.relationships.schemes.data',
            singleScheme,
          );
        }
      }
    }
  }

  productReferrals(quotes) {
    const referrals = [];
    Object.keys(quotes).forEach((quotedScheme) => {
      const quote = quotes[quotedScheme];
      if (quote.meta.referrals && quote.meta.referrals.length > 0) {
        quote.meta.referrals.map((referral) => {
          if (referral && referrals.indexOf(referral.reason) === -1) {
            referrals.push(referral.reason);
          }
        });
      }
    });

    return referrals;
  }

  displayPrice(value, currencyCode, displayNA = true) {
    if (displayNA && !value) {
      return 'N/A';
    }

    return displayCurrency(value, currencyCode);
  }


  render() {
    const {
      product,
      currentProduct,
      schemeType,
      handleSubmit,
      formValues,
      mta,
      showBlocker,
      requiresReferral,
      referrals
    } = this.props;

    const productCode = get(currentProduct, 'data.attributes.product_code');
    const conditionsCovered = get(formValues, 'data.attributes.metadata.adults[0].conditions_covered');
    const overrides = get(formValues, 'data.attributes.metadata.overrides');

    // Quotes Order:
    let availableQuotes = sortBy(product.quotes, 'meta.scheme.cover_level'); // 'meta.premium.gross'
    const adv = ['ADV', 'PST'].includes(productCode);
    if (adv && overrides) {
      availableQuotes = sortBy(product.quotes, 'meta.scheme.scheme_type');
    }
    const taxType = currentProduct.data.attributes.metadata.tax_type;
    const singleAnnualDiff = (single, annual) => Math.abs(single - annual);
    const isIsleChannelResident = () =>
      get(formValues, 'data.attributes.metadata.residency_declaration') === true;

    const tumc = ['TUMC', 'CPS'].includes(productCode);
    const csp = ['CSP'].includes(productCode);
    const bmc = ['BMC'].includes(productCode);
    const freeSpirit = ['FST'].includes(productCode);
    const showPersonalLiability = !['FST', 'TPL', 'ADV', 'BMC', 'DIA', 'HWN', 'CPS', 'EURP', 'FPS', 'FPR', '247', 'NPO', 'NPOR', 'CSPA', 'CSPAR', 'VEN', 'CSS', 'CSSR'].includes(productCode);
    const eurp = ['EURP'].includes(productCode);
    const fps = ['FPS', 'FPR', 'NPO', 'NPOR', 'CSPA', 'CSPAR', 'CSS', 'CSSR'].includes(productCode);
    let docErrorMsg = '';
    let questionsClassName = 'flex2';
    let lastTitleClassName = questionsClassName;

    if (freeSpirit) {
      const salesChannel = get(formValues, 'data.attributes.metadata.sales_channel', {});
      const awaitingTestChk = get(formValues, 'data.attributes.metadata.medical_declaration_questions.awaiting-tests-with-diagnosis.answer');
      const awaitingChk = get(formValues, 'data.attributes.metadata.medical_declaration_questions.is-awaiting-surgery.answer');
      if ((awaitingTestChk || awaitingChk) && salesChannel === 'call-centre') {
        questionsClassName = 'flex4';
        // lastTitleClassName = '';
      }
    }

    if (bmc) {
      docErrorMsg = ' and a letter is attached to this quotation, available for download to advise this.';
    }

    let declines = false;
    let annualWording = 'Annual';
    let coverWording = 'Top Up My Cover Cancellation';
    if (['CPS'].includes(productCode)) {
      annualWording = 'Frequent Traveller';
      coverWording = 'Coach Plus';
    }

    return (
      <Row>
        {availableQuotes.length !== 0 && !requiresReferral && !showBlocker ? (
          <Col xs={12} className="available-quotes">
            <StickyContainer className="quotes">
              {tumc ? (
                <Sticky>
                  {() => (
                    <div className="quotes-header available-quotes-table">
                      <div style={{ flex: 1.4 }}>Underwriter</div>
                      <div style={{ flex: 1.5 }}>Scheme</div>
                      <div style={{ flex: 1.5 }}>Cancellation</div>
                      <div style={{ flex: 0.5 }}>Benefits</div>
                      <div style={{ flex: 2.9 }}>Single</div>
                    </div>
                  )}

                </Sticky>
              ) : (
                <Sticky>
                  {() => (
                  <div className="quotes-header available-quotes-table">
                    <div className="flex2">Underwriter</div>
                    <div className="flex2 hide-query-4rd">Scheme</div>
                    {!eurp && !fps && !csp && (
                      <div className="flex2 hide-query-4rd">Medical</div>
                    )}
                    {schemeType !== 'single' && showPersonalLiability && (
                      <div className="flex2 hide-query-1st">Personal Liability</div>
                    )}
                    <div className="flex2 hide-query-2nd">Cancellation</div>
                    {!fps && (
                      <div className="flex2 hide-query-1st">Baggage</div>
                    )}
                    {fps && (
                      <div className="flex2 hide-query-1st">Curtailment</div>
                    )}
                    {fps && (
                      <div className="flex2 hide-query-1st">Medical</div>
                    )}
                    {!bmc && (
                      <div className="flex1">Benefits</div>
                    )}
                    {schemeType === 'single' ? (
                      <div className={questionsClassName}>Single</div>
                    ) : (
                      <div className="flex3">{annualWording}</div>
                    )}
                    <div className={lastTitleClassName}>
                      {schemeType === 'single' && annualWording}
                      <br/>
                    </div>
                  </div>
                  )}
                </Sticky>
              )}

              {availableQuotes.map((scheme) => {
                const quote = scheme;
                const annualSelected = schemeType === 'annual';
                const isAnnual = scheme.meta.scheme.scheme_type !== 'single';
                const errors = quote.meta.errors && quote.meta.errors.length > 0;

                const premiumBeforeAdjustment = get(
                  scheme,
                  'meta.premium_before_adjustments.gross',
                  false,
                );
                const premiumGross = get(scheme, 'meta.premium.gross');
                const premiumCurrency = get(scheme, 'meta.premium.currency');
                const annualQuote =
                  !annualSelected && !isAnnual
                    ? this.hasAnnualScheme(quote.meta.scheme, availableQuotes)
                    : false;

                const annualPremiumBeforeAdjustment = get(
                  annualQuote,
                  'meta.premium_before_adjustments.gross',
                  false,
                );

                const quoteSchemeType = get(quote, 'meta.scheme.scheme_type');
                const quoteGross = get(quote, 'meta.premium.gross', 0);
                const annualQuoteGross = get(annualQuote, 'meta.premium.gross', 0);
                const annualQuoteErrors = get(annualQuote, 'meta.errors', []);
                let annualDeclines = false;

                const re = new RegExp(schemeType, 'i');
                const display = quoteSchemeType.match(re);
                let annualDeclineReasons = [];

                const displayCoverLevel = (coverLevel) => {
                  if (coverLevel.toLowerCase() === 'longstay_plus') {
                    return 'Longstay PLUS';
                  }

                  if (coverLevel.toLowerCase() === 'backpacker') {
                    return 'Standard';
                  }

                  return startCase(coverLevel);
                };

                if (!isEmpty(get(quote, 'meta.declines'))) {
                  declines = true;
                }

                if (!isEmpty(get(annualQuote, 'meta.declines'))) {
                  annualDeclineReasons = quote.meta.declines.concat(annualQuote.meta.declines);
                  annualDeclines = true;
                }

                if (quote.meta && display) {
                  return (
                    <div key={quote.meta.scheme.id}>
                      {!showBlocker && (
                        <div
                          className={
                            'quoted-scheme available-quotes-table' +
                            (quote.meta.scheme.status === 'test' ? ' test-scheme' : '')
                          }
                        >
                          <div className="flex2 quote-logo-container ">
                            <div className="quote-logo">
                              <img src={quote.meta.scheme.logo} alt="scheme logo" />
                            </div>
                          </div>
                          <div
                            className={
                              'flex2 quote-value-container hide-query-4rd' +
                              (quote.meta.scheme.cover_level === 'super'
                                ? ' super-scheme'
                                : '') +
                              (quote.meta.scheme.cover_level === 'super_duper'
                                ? ' super-duper-scheme'
                                : '')
                            }
                          >
                            {tumc ? (
                              <span>
                                {coverWording}
                              </span>
                            ) : (
                              <span>
                                {displayCoverLevel(quote.meta.scheme.cover_level)}
                                {quote.meta.scheme.status === 'test' && ' (Test)'}
                              </span>
                            )}
                          </div>
                          {tumc && (
                            <div className="flex2 quote-value-container">
                              <span className="benefit">
                                {benefitByKey(quote.meta.benefits, 'Cancellation')}
                              </span>
                            </div>
                          )}
                          {!tumc && !eurp && !fps && (
                            <div className="flex2 quote-value-container hide-query-4rd">
                              <span className="benefit">
                                {benefitByKey(quote.meta.benefits, 'Medical')}
                              </span>
                            </div>
                          )}
                          {!tumc && (
                            <div className="flex2 quote-value-container hide-query-2nd">
                              <span className="benefit">
                                {benefitByKey(quote.meta.benefits, 'Cancellation')}
                              </span>
                            </div>
                          )}
                          {!tumc && !fps && (
                            <div className="flex2 quote-value-container hide-query-1st">
                              <span className="benefit">
                                {benefitByKey(quote.meta.benefits, 'Baggage')}
                              </span>
                            </div>
                          )}
                          {fps && (
                            <div className="flex2 quote-value-container hide-query-1st">
                              <span className="benefit">
                                {benefitByKey(quote.meta.benefits, 'Curtailment')}
                              </span>
                            </div>
                          )}
                          {fps && (
                            <div className="flex2 quote-value-container hide-query-1st">
                                <span className="benefit">
                                  {benefitByKey(quote.meta.benefits, 'Medical and additional expenses+')}
                                </span>
                            </div>
                          )}
                          {!bmc && (
                            <div className="flex1 quote-value-container">
                              {/* Benefits Icon */}
                              <BenefitsModal
                                scheme={quote.meta.scheme}
                                benefits={quote.meta.benefits}
                              />
                            </div>
                          )}
                          <div className="avl-quotes-table" style={{ background: '#f2f2f2' }}>
                            <div className="quotes-bar">
                              {!annualSelected && (
                                <div className="flex2 quote-value-container">
                                  <span
                                    className={
                                      quoteGross || (bmc && conditionsCovered && !errors)
                                        ? this.schemeIsSelected(quote.meta.scheme)
                                          ? 'premium selected'
                                          : 'premium'
                                        : 'premium unquoted'
                                    }
                                    onClick={() => {
                                      quoteGross || (bmc && conditionsCovered && !errors)
                                        ? this.handleSchemeSelect(quote.meta.scheme)
                                        : quote.meta.errors && !quote.meta.errors.length > 0
                                        ? mta
                                          ? this.handleSchemeSelect(quote.meta.scheme)
                                          : false
                                        : false;
                                    }}
                                  >
                                    <div
                                      className={
                                        ((quoteGross || (bmc && conditionsCovered && !errors) &&
                                          this.schemeIsSelected(quote.meta.scheme)) ||
                                          mta) &&
                                        this.schemeIsSelected(quote.meta.scheme)
                                          ? 'quote-value-selected'
                                          : 'quote-value'
                                      }
                                    >
                                      {!mta &&
                                        premiumBeforeAdjustment !== premiumGross &&
                                        premiumCurrency && (
                                          <span className="previousPremium">
                                            {' '}
                                            {displayCurrency(
                                              premiumBeforeAdjustment,
                                              quote.meta.premium.currency,
                                            )}{' '}
                                          </span>
                                        )}
                                      {this.displayPrice(quoteGross, get(quote, 'meta.premium.currency'), (!mta && !conditionsCovered) || get(quote, 'meta.errors', []).length > 0 || (declines && !adv && isEmpty(overrides)) || (isBroker() && tumc && declines))}
                                      {quoteGross && (annualQuoteGross <= quoteGross) &&
                                      this.schemeIsSelected(quote.meta.scheme) ? (
                                        <span className="icon-padding">
                                          <Icon name="check-circle" />
                                        </span>
                                      ) : (
                                        <span className="icon-padding">
                                          <Icon name="circle-thin" />
                                        </span>
                                      )}
                                    </div>
                                  </span>
                                  {quoteGross && !isIsleChannelResident() ? (
                                    <span className="incl-ipt-mssg">inc. {taxType}</span>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              )}
                              {!tumc && (
                                <div className="flex2 quote-value-container">
                                  {!annualSelected ? (
                                    <span
                                      className={
                                        (annualQuote && annualQuoteGross) || (bmc && annualQuote && conditionsCovered && !errors)
                                          ? this.schemeIsSelected(annualQuote.meta.scheme)
                                            ? 'premium selected'
                                            : 'premium'
                                          : 'premium unquoted'
                                      }
                                      onClick={() => {
                                        // (annualQuote &&
                                        // annualQuoteGross &&
                                        // quoteGross <= annualQuoteGross) || (bmc && annualQuote && conditionsCovered && !errors) ||
                                        // (['247', 'FST', 'TPL', 'VEN'].includes(productCode) && !errors && annualQuoteErrors.length === 0)
                                        (annualQuote &&
                                          annualQuoteGross &&
                                          quoteGross <= annualQuoteGross)
                                        || (bmc && conditionsCovered && !errors || !annualQuoteErrors) || (overrides && adv && quoteGross <= annualQuoteGross) ||
                                        (['247', 'FST', 'TPL', 'VEN'].includes(productCode) && (!errors && annualQuoteErrors.length === 0 && !annualDeclines))
                                         // disabling click if Annual price is cheaper than single
                                          ? this.handleSchemeSelect(annualQuote.meta.scheme)
                                          : false;
                                      }}
                                    >
                                      <div
                                        className={
                                          annualQuote &&
                                          annualQuoteGross &&
                                          this.schemeIsSelected(annualQuote.meta.scheme)
                                            ? 'quote-value-selected'
                                            : 'quote-value'
                                        }
                                      >
                                        {!!(
                                          !mta &&
                                          annualPremiumBeforeAdjustment &&
                                          annualPremiumBeforeAdjustment !== annualQuoteGross &&
                                          (!errors && annualQuoteErrors.length === 0)
                                        ) && (
                                          <span className="previousPremium">
                                            {' '}
                                            {displayCurrency(
                                              annualPremiumBeforeAdjustment,
                                              get(annualQuote.meta, 'premium.currency'),
                                            )}{' '}
                                          </span>
                                        )}
                                        {(annualQuote &&
                                        annualQuoteGross &&
                                        quoteGross <= annualQuoteGross)
                                        || (bmc && conditionsCovered && !errors || !annualQuoteErrors) || (overrides && adv && quoteGross <= annualQuoteGross) ||
                                        (['247', 'FST', 'TPL', 'VEN'].includes(productCode) && (!errors && annualQuoteErrors.length === 0 && !annualDeclines))
                                          // hide annual prices if cheaper than single
                                          ? displayCurrency(
                                              annualQuoteGross,
                                            get(annualQuote, 'meta.premium.currency', 'GBP'),
                                            )
                                          : 'N/A'}
                                        {annualQuote &&
                                        annualQuoteGross &&
                                        this.schemeIsSelected(annualQuote.meta.scheme)
                                          ? (
                                          <span className="icon-padding">
                                            <Icon name="check-circle" />
                                          </span>
                                        ) : (
                                          <span className="icon-padding">
                                            <Icon name="circle-thin" />
                                          </span>
                                        )}
                                      </div>
                                    </span>
                                  ) : (
                                    <span
                                      className={
                                        quoteGross || (bmc && conditionsCovered && !errors && annualQuoteErrors.length === 0)
                                          ? this.schemeIsSelected(quote.meta.scheme)
                                            ? 'premium selected'
                                            : 'premium'
                                          : 'premium unquoted'
                                      }
                                      onClick={() => {
                                        quoteGross || (bmc && conditionsCovered && !errors && annualQuoteErrors.length === 0)
                                          ? this.handleSchemeSelect(quote.meta.scheme)
                                          : quote.meta.errors && !quote.meta.errors.length > 0
                                          ? mta
                                            ? this.handleSchemeSelect(quote.meta.scheme)
                                            : false
                                          : false;
                                      }}
                                    >
                                      <div
                                        className={
                                          ((quoteGross || (bmc && conditionsCovered && !errors && annualQuoteErrors.length === 0) &&
                                            this.schemeIsSelected(quote.meta.scheme)) ||
                                            mta) &&
                                          this.schemeIsSelected(quote.meta.scheme)
                                            ? 'quote-value-selected'
                                            : 'quote-value'
                                        }
                                      >
                                        {this.displayPrice(quoteGross, get(quote, 'meta.premium.currency'), !mta || (get(quote, 'meta.errors', []).length > 0 && annualQuoteErrors.length > 0) || conditionsCovered)}
                                        {((quoteGross &&
                                          this.schemeIsSelected(quote.meta.scheme)) ||
                                          mta) &&
                                        this.schemeIsSelected(quote.meta.scheme) ? (
                                          <span className="icon-padding">
                                            <Icon name="check-circle" />
                                          </span>
                                        ) : (
                                          <span className="icon-padding">
                                            <Icon name="circle-thin" />
                                          </span>
                                        )}
                                      </div>
                                    </span>
                                  )}
                                  {quoteGross && !isIsleChannelResident() ? (
                                    <span className="incl-ipt-mssg">inc. {taxType}</span>
                                  ) : (
                                    ''
                                  )}
                                </div>
                                )}
                            </div>
                            <div className="upgrade-bar">
                              {quoteGross &&
                              (annualQuote &&
                                annualQuoteGross &&
                                annualQuoteGross !== 0 &&
                                quoteGross <= annualQuoteGross) ? (
                                <div className="upgrade-bar-info">
                                  <strong>
                                    {displayCurrency(
                                      singleAnnualDiff(
                                        quoteGross,
                                        annualQuote && annualQuoteGross,
                                      ),
                                    )}{' '}
                                  </strong>
                                  extra to upgrade to Annual
                                </div>
                              ) : (
                                <div />
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="">
                        {quote.meta.referrals && quote.meta.referrals.length > 0 && (
                          <div className="quote-error-box">
                            <ul>
                              {quote.meta.referrals.map((referral, i) => {
                                if (referral) {
                                  return (
                                    <li key={i}>
                                      <strong>Referral:</strong> {referral.reason}
                                    </li>
                                  );
                                }
                              })}
                            </ul>
                          </div>
                        )}
                        {!isEmpty(get(annualQuote, 'meta.declines')) ? (
                          <div className="quote-error-box">
                            <ul>
                              {annualDeclineReasons.map((decline, i) => {
                                if (decline) {
                                  return (
                                    <li key={i}>
                                      <strong>Decline:</strong> {decline.reason}
                                    </li>
                                  );
                                }
                              })}
                            </ul>
                          </div>
                        ) : (
                        !isEmpty(get(quote, 'meta.declines')) && (
                          <div className="quote-error-box">
                            <ul>
                              {quote.meta.declines.map((decline, i) => {
                                if (decline) {
                                  return (
                                    <li key={i}>
                                      <strong>Decline:</strong> {decline.reason}
                                    </li>
                                  );
                                }
                              })}
                            </ul>
                          </div>
                        ))}
                        {quote.meta.errors && quote.meta.errors.length > 0 && (
                          <div className="quote-error-box">
                            <ul>
                              {quote.meta.errors.map((error, i) => {
                                return <li key={i}>{error}{docErrorMsg}</li>;
                              })}
                            </ul>
                          </div>
                        )}
                        {annualQuoteErrors && annualQuoteErrors.length > 0 && (
                          <div className="quote-error-box">
                            <ul>
                              {annualQuoteErrors.map((error, i) => {
                                return <li key={i}>{' annual: '}{error}{docErrorMsg}</li>;
                              })}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }
              })}
            </StickyContainer>
          </Col>
        ) : (
          !product.isCalculating && (
            <Col xs={12}>
              {requiresReferral ? (
                <div>
                  <h3>This quote needs to be referred to the underwriter for approval</h3>

                  <ul>
                    {referrals.map((referral, i) => (
                      <li key={i}>{referral}</li>
                    ))}
                  </ul>
                  <Row>
                    <Col xs={12} componentClass={ControlLabel}>
                        Please add any notes for the underwriter
                        <Field
                          name={'meta.note.content'}
                          type="textarea"
                          component={TextField}
                        />

                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3} className="pull-right">
                      <Button
                        label="Save and request approval"
                        bsStyle="primary"
                        className="pull-right"
                        isLoading={product.isSaving}
                        handleClick={handleSubmit(this.saveReferral.bind(this))}
                      />
                    </Col>
                    <Col sm={3}>
                      <QuoteSaveExitPolicy
                        className={'pull-left'}
                        product={product}
                        isReferral={requiresReferral}
                        currentProduct={currentProduct}
                        selectedSchemes={get(
                          formValues,
                          'data.relationships.schemes.data',
                          [],
                        )}
                        handleSubmit={handleSubmit}
                      />
                    </Col>
                  </Row>
                </div>
              ) : !product.isSaving ? (
                <div>
                  <div>
                    <h4>No products are available</h4>
                    <p>There are no schemes available based on your quotation criteria.</p>
                  </div>
                  {tumc && !['CPS'].includes(productCode) && (
                    <p>
                      <div className="quote-error-box">
                        The “top up” amount selected has exceeded the limit of the policy.
                        Maximum “top up” amount is £20,000 per person (£15,000 per person if
                        aged 75-79) with an overall limit of £100,000 per policy.
                        We have an alternative product ‘High Value Travel Cancellation Insurance
                        - <a
                        href='https://www.pjhayman.com/travel-insurance/high-value-travel-cancellation-insurance/'
                        target='_blank'> Click here</a>{' '}
                        for details and to apply for a quote.
                      </div>
                    </p>
                  )}
                </div>
              ) : null}
            </Col>
          )
        )}
        {product.isCalculating && (
          <div>
            <Loader noShadow />
          </div>
        )}
      </Row>
    );
  }
}

export default connect()(AvailableQuotes);

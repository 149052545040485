export const user = {
  data: {
    type: 'user',
    attributes: {
      title: '',
      first_name: '',
      last_name: '',
      position: '',
      email: '',
      phone1: '',
      phone2: '',
      address: {
        line1: '',
        line2: '',
        line3: '',
        town: '',
        county: '',
        postcode: '',
      },
      metadata: {
        biography: '',
      },
    },
    relationships: {
      organisations: {
        data: [],
      },
      brokers: {
        data: [],
      },
      roles: {
        data: [],
      },
    },
  },
};

export const userRules = {};

export const userStatuses = {
  pending: 'pending',
  active: 'active',
  inactive: 'inactive',
};

export default user;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import './styles.scss';
import { get } from 'lodash';
import displayCurrency from '../../../../helpers/displayCurrency';

class AdjustmentHintBox extends Component {
  constructor(props) {
    super(props);
  }

  getTitle(value) {
    return `Net Override Hints ${value.charAt(0).toUpperCase() + value.slice(1)}`;
  }

  render() {
    const { quotes } = this.props;

    const quotesPreCal = Object.values(quotes);

    return (
      <div className="hint-adjust-box">
        {quotesPreCal.map((quote) => {
          const schemeType = get(quote, 'meta.information.scheme_type', 'Single');
          const preCalculatedRate = get(quote, 'meta.information.pre_calculated_rate', 0);

          if (!preCalculatedRate || !get(quote, 'meta.information.activity_rates', [])) {
            return;
          }

          const activityRates = Object.values(get(quote, 'meta.information.activity_rates', []));
          let counter = 1;

          return (
            <div>
              <h3>{this.getTitle(schemeType)}</h3>
              <Row className='hint-prices-row'>
                {activityRates.map((activityRate) => {
                  let totalHint = preCalculatedRate + activityRate;

                  return (
                    <div>
                      <Col sm={2} className="hint-cat-label">Cat {counter++}:</Col>
                      <Col
                        sm={4}>{`${displayCurrency(preCalculatedRate)} + ${displayCurrency(activityRate)} = ${displayCurrency(totalHint)}`}</Col>
                    </div>
                  );
                })}
              </Row>
            </div>
          );
        })}
      </div>
    );
  }

}

export default connect()(AdjustmentHintBox);

import displayAccountAmount from '../../../../helpers/displayAccountAmount';

export const MAPPING = {
  unmatchedTransactions: {
    title: 'Unmatched Receipts',
    onSelectXfrTo: 'matchedTransactions',
    selected: false,
  },
  matchedTransactions: {
    title: 'Matched Receipts',
    onSelectXfrTo: 'unmatchedTransactions',
    selected: true,
  },
  unmatchedPremiums: {
    title: 'Unmatched Premiums',
    onSelectXfrTo: 'matchedPremiums',
    selected: false,
  },
  matchedPremiums: {
    title: 'Matched Premiums',
    onSelectXfrTo: 'unmatchedPremiums',
    selected: true,
  },
  unmatchedAdjustments: {
    title: 'Unmatched Adjustments and Balancing Entries',
    onSelectXfrTo: 'matchedAdjustments',
    selected: false,
  },
  matchedAdjustments: {
    title: 'Matched Austments and Balancing Entries',
    onSelectXfrTo: 'unmatchedAdjustments',
    selected: true,
  },
};


export const getBalance = (items, formatted = true) =>  {
  let balance = 0.0;

  if (items.length > 0) {
    items.map((item) => {
      balance += parseFloat(item.attributes.amount);
    });
  }

  if (formatted) {
    return displayAccountAmount(balance);
  }

  return balance;
};

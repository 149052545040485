import React, { useMemo } from 'react';
import './DocumentThumbnail.scss';

const getStyles = (theme) => (
  {
    header: {
      backgroundColor: theme.colors.generatedPdf.header.backgroundColor,
      color: theme.colors.generatedPdf.header.color
    },
    page: {
      color: theme.colors.generatedPdf.page.color
    },
    footer: {
      backgroundColor: theme.colors.generatedPdf.footer.backgroundColor,
      color: theme.colors.generatedPdf.footer.color

    },
    divider: {
      borderColor: theme.colors.generatedPdf.divider.color

    }
  }
);

const Row = ({ label, value, styles }) => (
  <tr className={'ul'} style={styles.divider}>
    <td>
      {label}
    </td>
    <td colSpan={3}>
      {value}
    </td>
  </tr>
);

const DocumentThumbnail = ({
  phone,
  displayName,
  theme,
  footer

}) => {

  const styles = useMemo(() => getStyles(theme), [theme]);

  return (
    <div className="whitelabel-document-preview">
      <div className="wl-thumbnail-container">
        <label>Documents</label>
        <div className="wl-thumbnail" style={styles.page}>
          <div className="wl-header" style={styles.header}>
            <div className="wl-logo-container wl-document-logo">
              <img
                className="wl-logo"
                alt="logo"
                src={theme.urls.documentLogo || theme.urls.logo}
              />
            </div>
          </div>

          <div className="wl-document-body">
            <div className={'wl-document-title'}  style={styles.divider}>
              Policy Schedule
            </div>

            <table className="wl-document-table">
              <tr className={'ul'} style={styles.divider}>
                <td>
                  Policy Number:
                </td>
                <td>
                  FST/200110/2021
                </td>
                <td>
                  Date of Issue:
                </td>

                <td>
                  10 Sep 2021
                </td>
              </tr>
              <tr className={'ul'} style={styles.divider}>
                <td colSpan={4}>
                  This schedule forms part of the policy. Please read it in conjunction
                  with the insurance policy and keep it in a safe place. In the event of a
                  claim you
                  will be required to provide this schedule as proof of purchase.
                </td>
              </tr>
              <tr className={'ul'} style={styles.divider}>
                <td colSpan={4}>
                  Issued to:
                </td>
              </tr>
              <Row
                label={'Lead Name:'} value={'Mr Joe Bloggs'} styles={styles}/>
              <Row
                label={'Address:'}
                value={'Limes Court, Hoddesdon, Hertfordshire, EN11 8EP'}
                styles={styles}/>
              <Row
                label={'Scheme Name:'} value={'PJ Scheme'} styles={styles}/>
              <Row
                label={'Policy Type:'} value={'Single'} styles={styles}/>
              <Row
                label={'Area of Cover:'}
                value={'France (see Geographical Areas in the Insurance Policy)'}
                styles={styles}/>
            </table>

            <div className={'wl-document-title'} style={styles.divider}>
              Period of Insurance
            </div>

            <table className="wl-document-table">
              <Row label={' Period of Cover:'} value={'14 days'} styles={styles}/>
              <tr className={'ul'} style={styles.divider}>
                <td>
                 Start Date
                </td>
                <td>
                  10 Sep 2021
                </td>
                <td>
                  End Date:
                </td>

                <td>
                  26 Sep 2021
                </td>
              </tr>

            </table>

            <div className={'wl-document-title'} style={styles.divider}>
              Cover Options
            </div>
            <table className="wl-document-table">
              <Row label={'Additional Options: :'} value={'Activity Pack 1'} styles={styles}/>
              {/*<Row label={' Period of Cover:'} value={'14 days'} styles={styles}/>*/}

              <Row label={'Endorsements:'} value={'None'} styles={styles}/>
              <Row label={'Premium (excluding IPT): '} value={'£26.01'} styles={styles}/>
              <Row label={'IPT:'} value={'£5.20'} styles={styles}/>
              <Row label={<b>Total Premium (including IPT):</b>} value={'£31.21'} styles={styles}/>
            </table>
            <div className={'wl-document-info'}>
              <b>Ongoing Health Warranty</b> If an insured person's health changes after you have purchased your policy but before you commence your trip (or in the case of Annual Multi-trip cover before booking your next trip) or pay the balance or any further instalments for your trip, you must telephone our customer helpline on <b>{phone}</b> to make sure your cover is not affected.
            </div>
            <div>
              <b>Demands and needs statement</b><br/>
              This travel insurance is typically suitable for those people who wish to insure themselves when travelling for the benefits as shown in the Insurance Policy. You may already possess alternative insurance(s) for some or all of the features and benefits provided by this product. It is your responsibility to investigate this. {displayName} has not provided you with any recommendation or advice about whether this product fulfils your specific insurance requirements. You will need to make your own choice about how to proceed.
            </div>
            <div>
              <b>Important contact information</b><br/>
              24/7 Medical Emergency Assistance - <b>+44(0) 123 123 1235</b><br/>
              Claims – <b>23 1234 1234</b><br/>
              Customer Services – <b>12345 123 123</b><br/>
              Further details of these services are shown in your policy documentation.

            </div>


          </div>


          <div className="wl-footer" style={styles.footer}>

              {footer}
          </div>
        </div>
      </div>
    </div>
  );

};
export default DocumentThumbnail;

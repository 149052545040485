import React from 'react';
import { useSelector } from 'react-redux';
import { getWlProductInitialValues } from '../helpers';
import getIncludedResource from '../../../helpers/getIncludedResource';
import WhitelabelTravelForm
  from '../components/WhitelabelTravelForm/WhitelabelTravelForm';

const WhiteLabelTravelFormContainer = ({
  customerId,
  application,
  policyId,
  productMedicalQuestions,
  productCode,
  productId,
  isMta,
  definitions,
  purchaseDeclaration,
  selectOptions,
  productOptions,
  rules,
  covidMessage,
  medicalContent,
  isExpired,
  isEditingSavedQuote
}) => {

  const currentCustomer = useSelector((state) => state.customer?.resources?.[customerId]?.data?.attributes ?? {});
  const { nextQuoteReference } = useSelector((state) => state.product);

  const initialValues = getWlProductInitialValues(currentCustomer, application, productMedicalQuestions, productOptions, productCode, productId, isMta, nextQuoteReference, policyId);
  const source = getIncludedResource(application?.data, application?.included, 'source');
  const channel = getIncludedResource(application?.data, application?.included, 'channel');

  if (!initialValues) {
    return <div/>;
  }

  return (
    <WhitelabelTravelForm
      productId={productId}
      policyId={policyId}
      quoteType={isMta ? 'MTA' : 'NB'}
      channelName={channel?.data?.attributes?.channel_name}
      channelId={channel?.data?.id}
      sourceName={source?.data?.attributes?.name}
      isExpired={isExpired}
      isMta={isMta}
      isEditingSavedQuote={isEditingSavedQuote}
      initialValues={initialValues}
      selectOptions={selectOptions}
      productOptions={productOptions}
      rules={rules}
      covidMessage={covidMessage}
      medicalContent={medicalContent}
      purchaseDeclaration={purchaseDeclaration}
      definitions={definitions}
    />
  );
};
export default WhiteLabelTravelFormContainer;

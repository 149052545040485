import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SelectMenu from '../SelectMenu/SelectMenu';
import './styles.scss';

export const SELECT_IDENTIFIER = 'country';

class SelectCountry extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    label: PropTypes.string,
    labelSize: PropTypes.number,
    input: PropTypes.object.isRequired,
    infoButton: PropTypes.element,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    meta: PropTypes.object,
    object: PropTypes.bool,
    isMulti: PropTypes.bool,
    filters: PropTypes.array,
  };

  render() {
    const { input, meta: { touched, error }, filters, object, isMulti, ...props } = this.props;
    let source = 'products/destinations';

    if (Array.isArray(filters)) {
      filters.map((filter) => {
        source += (source.includes('?') ? '&' : '?') + filter.filter + '=' + filter.value;
      });
    }

    return (
      <div className={(touched && error) ? 'has-error' : ''}>
        <SelectMenu
          id="select_country"
          source={source}
          name={SELECT_IDENTIFIER}
          field={input}
          object={object}
          mapOptions={(option) => {
            return {
              label: option.attributes.name,
              value: option.attributes.name,
              disabled: !option.attributes.included,
            };
          }}
          isMulti={isMulti}
          autoFilterType="include"
          autoFilter="region"
          {...props}
        />
        {(touched && error) && (
          <span className="input-error">{error}</span>
        )}
      </div>
    );
  }
}

export default connect()(SelectCountry);

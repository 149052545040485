import { get } from 'lodash';
import CovidStatement from '../../../../CovidStatement/CovidStatement';
import Markdown from 'react-commonmark';
import { Field } from 'redux-form';
import { CheckboxGroup } from '../../../../../../../common/components/ReduxFormField';
import React from 'react';


const SIGNPOST_DECLARATION = 'This price includes cover for declared medical condition/s.  As your quote includes a high additional medical premium for one or more of you please read the IMPORTANT INFORMATION';
const Declaration = ({ show, declaration, productCode, hasSignpostedPremiums, selectedPremium }) => {

  if (!show) {
    return null;
  }

  const transformDocLinks = (url) => {
    const documents = get(selectedPremium, 'meta.scheme.documents', '');


    if (url === 'policy_wording') {
      return get(documents, 'Policy Wording', '');
    }

    return url;
  };
  const signpostedDeclaration = hasSignpostedPremiums ? `${declaration}\n- ${SIGNPOST_DECLARATION} ` : declaration;
  return (
    <div>
      <h4>Declaration</h4>
      <div>
        {productCode !== 'TPL' && (
          <CovidStatement productCode={productCode}/>
        )}
      </div>
      {declaration ? (
        <div>
          <div className="declaration">
            <Markdown
              source={signpostedDeclaration}
              linkTarget="_blank"
              transformLinkUri={transformDocLinks}
            />
          </div>
        </div>
      ) : (
        <ul>
          <li>The Insured is resident in the United Kingdom.</li>
          <li>Formal instructions have been received to bind cover.</li>
        </ul>
      )}
      <Field
        name={'meta.purchase_declaration'}
        inline
        options={[
          {
            label: 'Please tick to confirm you agree to the above declaration',
            value: true,
          },
        ]}
        component={CheckboxGroup}
      />
    </div>
  );
};

export default Declaration;

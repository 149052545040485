import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';

const DynamicDocumentContent = ({ document }) => {
  const sections = document?.attributes?.sections ?? {};
  return (
    <>
      {Object.keys(sections).map((sectionKey,) => {
        return (
          <Row className="my-15" key={sectionKey}>
            <Col sm={12}>
              <strong>{sectionKey}:</strong>
              <div className="justify-text mt-15"><ReactMarkdown
                source={sections[sectionKey]}/></div>
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default DynamicDocumentContent;

import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import {
  Button,
  ButtonBar,
  DatePickerDob,
  HorizontalFormControl,
  SelectCashAccount
} from '../../../../../common/components';
import { SelectField } from '../../../../../common/components/ReduxFormField';
import InfoLabel from '../../../../../common/components/InfoLabel/InfoLabel';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { required } from 'redux-form-validators';

const PAYMENT_TYPES = [
  { label: 'BACS', value: 'BACS' },
  { label: 'Cheque', value: 'Cheque' },
];

export const MakePaymentForm = ({
  accountingReference,
  onMakePayment,
  handleSubmit,
  submitting,
  formValues,
}) => {
  return (
    <Form onSubmit={handleSubmit(onMakePayment)}>
      <Field
        name="meta.cash_account"
        label="Cash Account"
        labelSize={3}
        component={SelectCashAccount}
      />
      <Field
        name={'meta.payment_type'}
        label="Type"
        labelSize={3}
        options={PAYMENT_TYPES}
        component={SelectField}
      />
      <Field
        name={'meta.description'}
        label="Description"
        labelSize={3}
        component={HorizontalFormControl}
      />
      <Field
        name={'meta.date'}
        label="Date"
        labelSize={3}
        component={DatePickerDob}
        validate={required({ msg:'Date is required' })}
      />
      <InfoLabel value={formValues?.meta?.amount?.toFixed(2)} label="Amount"
                 labelSize={3}/>
      <InfoLabel value={formValues?.meta?.payment_reference} label="Reference"
                 labelSize={3}/>
      <ButtonBar>
        <Button
          type="submit"
          bsStyle="primary"
          isLoading={submitting}
          label="Save Payment"
          className={'pull-right'}
          disabled={!accountingReference}
        />
      </ButtonBar>
    </Form>

  );

};

const FORM_NAME = 'MakePaymentForm';
export default compose(
  connect(
    (state, ownProps) => (
      {
        formValues: getFormValues(FORM_NAME)(state),
        initialValues: {
          meta: {
            amount: ownProps.amount,
            payment_reference: ownProps.accountingReference,
            matched_premiums: ownProps.matchedPremiums,
            matched_transactions: ownProps.matchedTransactions,
          }
        }
      }
    )
  ),
  reduxForm({
    form: FORM_NAME
  })
)(MakePaymentForm);

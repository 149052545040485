import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Modal } from '../../../../common/components';
import { openModal } from '../../../../common/components/Modal/ModalRedux';
import './styles.scss';

class HandlerButton extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    handlers: PropTypes.array.isRequired,
    handlerType: PropTypes.string,
  };

  handleOpen() {
    const { dispatch, name } = this.props;
    dispatch(openModal(name));
  }

  render() {
    const { handlers, handlerType, name } = this.props;
    const title = handlerType ? handlerType + ' Handler(s)' : 'Handler(s)';

    return (
      <div className="handler-btn-wrapper">
        <Button
          bsStyle="default"
          label={handlerType}
          size="sm"
          handleClick={::this.handleOpen}
        />

        <Modal name={name} title={title} close>
          <div className="form-horizontal">
            <div className="col-xs-12">
              {handlers.map((handler, i) => (
                <div key={i} className="handler-item">
                  <p>{handler.details}</p>
                  {handler.phone && (
                    <p>
                      <strong>Phone:</strong> {handler.phone}
                    </p>
                  )}
                  {handler.email && (
                    <p>
                      <strong>Email:</strong> {handler.email}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect()(HandlerButton);

import React, { Component } from 'react';
import Box from '../../../../common/components/Box/Box';
import { LedgerEntriesModal } from '../LedgerEntriesModal';
import { openModal } from '../../../../common/components/Modal/ModalRedux';
import { Row, Col, Label, Alert } from 'react-bootstrap';
import { getSummaries } from '../../redux/accountActions';
import './styles.scss';
import displayAccountAmount from '../../../../helpers/displayAccountAmount';

export default class AccountErrors extends Component {

  constructor(props) {
    super(props);

    this.state = {
      batch: {
        id: '',
      },
      journal: {
        id: '',
      },
    };
  }

  handleBatchClick(id) {
    this.setState({ batch: { id: id }, journal: { id: '' } });
    this.props.dispatch(openModal(LedgerEntriesModal.MODAL_IDENTIFIER));
  }

  handleJournalClick(id) {
    this.setState({ journal: { id: id }, batch: { id: '' } });
    this.props.dispatch(openModal(LedgerEntriesModal.MODAL_IDENTIFIER));
  }

  renderBatchErrors() {
    const { errors: { batches } } = this.props;
    let totalError = 0;
    batches.forEach((batch) => {
      totalError += parseFloat(batch.sum);
    });

    return (
      <Box>
        <h3>
          Batch Errors
          <Label className="pull-right" bsStyle="warning">
            {displayAccountAmount(totalError)}
          </Label>
        </h3>
        <Row>
          <Col sm={8}>
            <h4>System Reference</h4>
          </Col>
          <Col sm={4} className="text-right">
            <h4>Amount</h4>
          </Col>
        </Row>
        {batches.map((batch) => {
          return (
            <Row
              key={batch.id}
              onClick={() => this.handleBatchClick(batch.id)}
              className="error-row"
            >
              <Col sm={8}>
                {batch.id}
              </Col>
              <Col sm={4} className="text-right">
                {displayAccountAmount(batch.sum)}
              </Col>
            </Row>
          );
        })}
      </Box>
    );
  }

  renderJournalErrors() {
    const { errors: { journals } } = this.props;
    let totalError = 0;
    journals.forEach((journal) => {
      totalError += parseFloat(journal.sum);
    });

    return (
      <Box>
        <h3>
          Journal Errors
          <Label className="pull-right" bsStyle="warning">
            {displayAccountAmount(totalError)}
          </Label>
        </h3>
        <Row>
          <Col sm={8}>
            <h4>System Reference</h4>
          </Col>
          <Col sm={4} className="text-right">
            <h4>Amount</h4>
          </Col>
        </Row>
        {journals.map((journal) => {
          return (
            <Row
              key={journal.id}
              onClick={() => this.handleJournalClick(journal.id)}
              className="error-row"
            >
              <Col sm={8}>
                {journal.id}
              </Col>
              <Col sm={4} className="text-right">
                {displayAccountAmount(journal.sum)}
              </Col>
            </Row>
          );
        })}
      </Box>
    );
  }

  renderTrialBalanceDifferences() {
    const { errors: { trial_balance } } = this.props;
    let totalError = 0;
    trial_balance.forEach((tb) => {
      totalError += parseFloat(tb['difference']);
    });

    return (
      <Box>
        <h3>
          Trial Balance Differences
          <Label className="pull-right" bsStyle="warning">
            {displayAccountAmount(totalError)}
          </Label>
        </h3>
        <Row>
          <Col sm={6}>
            <h4>Account</h4>
          </Col>
          <Col sm={2} className="text-right">
            <h4>Ledger</h4>
          </Col>
          <Col sm={2} className="text-right">
            <h4>Reporting</h4>
          </Col>
          <Col sm={2} className="text-right">
            <h4>Difference</h4>
          </Col>
        </Row>
        {trial_balance.map((tb) => {
          return (
            <Row
              key={tb.id}
              className="account-summary-row"
            >
              <Col sm={6}>
                {tb.name}
              </Col>
              <Col sm={2} className="text-right">
                {displayAccountAmount(tb['ledger'])}
              </Col>
              <Col sm={2} className="text-right">
                {displayAccountAmount(tb['reporting'])}
              </Col>
              <Col sm={2} className="text-right">
                {displayAccountAmount(tb['difference'])}
              </Col>
            </Row>
          );
        })}
      </Box>
    );
  }

  render() {
    const { errors } = this.props;

    if (errors) {
      const journalErrors = errors.journals.length;
      const batchErrors = errors.batches.length;
      const tbErrors = errors.trial_balance.length;
      const totalErrors = journalErrors + batchErrors + tbErrors;

      if (totalErrors === 0) {
        return (
          <Box>
            <Alert bsStyle="success" className="text-center"><p>No Errors</p></Alert>
          </Box>
        );
      }

      return (
        <div>
          <Row>
            {batchErrors > 0 && (
              <Col sm={6}>
                {this.renderBatchErrors()}
              </Col>
            )}
            {journalErrors > 0 && (
              <Col sm={6}>
                {this.renderJournalErrors()}
              </Col>
            )}
          </Row>
          {tbErrors > 0 && (
            <Row>
              <Col sm={12}>
                {this.renderTrialBalanceDifferences()}
              </Col>
            </Row>
          )}
          <LedgerEntriesModal
            dispatch={this.props.dispatch}
            journal={this.state.journal}
            batch={this.state.batch}
            unMatchCallback={getSummaries()}
          />
        </div>
      );
    }

    return <div/>;
  }
}

import React from 'react';

const DocumentFile = ({ input, isEditing, displayName, icon, meta, url }) => {
  return (
    <span className="pull-left">
      {input.value ? (
        <a
          target="_blank"
          className={`btn btn-${isEditing ? 'link' : 'primary'} btn-xs`}
          href={url ? url : input.value}
        >
          {icon}
          {input.value ? displayName : ''}
        </a>
      ) : (
        <span/>
      )}
      {meta && meta.touched && meta.error && (
        <span className="input-error">{meta.error}</span>
      )}
    </span>
  );
};

export default DocumentFile;

import Axios from 'axios';
import * as schemeManagementActions from './schemeManagementReducer';
import { SET_SCHEME_LINT_FAILURES } from './schemeManagementReducer';
import { toastr } from 'react-redux-toastr';
import handleErrorResponse from '../../../helpers/handleErrorResponse';
import queryString from '../../../helpers/queryString';
import { addResource } from '../../layout/redux/breadcrumb';

const isLoadingRates = (status, schemeId, message) => {
  return {
    type: schemeManagementActions.IS_LOADING_RATES,
    data: {
      id: schemeId,
      status: status,
      message: message,
    },
  };
};
export const isVersioning = (schemeId, status) => {
  return {
    type: schemeManagementActions.IS_VERSIONING,
    data: {
      id: schemeId,
      status: status,
    },
  };
};


// export const isExporting = (status) =>  {
//   return {
//     type: schemeManagementActions.IS_EXPORTING,
//     status,
//   };
// };


export const isSubmitting = (status) => {
  return {
    type: schemeManagementActions.IS_SUBMITTING,
    status,
  };
};

const getAncillarySchemes = (insurer) => {
  return (dispatch) => {
    const insurerFilter = insurer ? `&filter[insurer]=${insurer}` : '';
    return Axios.get(
      `products/ancillary-schemes?filter[status]=test,active${insurerFilter}&include=replaced_by`,
    ).then((response) => {
      const schemes = response.data.data.reduce((carry, scheme) => {
        if (scheme.relationships.replaced_by.data) {
          return carry;
        }
        if (carry[scheme.attributes.name]) {
          carry[scheme.attributes.name].push(scheme);
        } else {
          carry[scheme.attributes.name] = [scheme];
        }
        return carry;
      }, {});
      dispatch({
        type: schemeManagementActions.SET_ANCILLARY_SCHEMES,
        data: schemes,
      });
      return schemes;
    });
  };
};

const createNewVersion = (schemeId) => {
  return () => {
    return Axios.patch(
      `products/scheme-groups/${schemeId}`,
      {
        meta: {
          queue: true,
        },
      },
    )
      .then(() => {
        toastr.success('Success', 'Request for new scheme version sent');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'There was an error updating the scheme');
      });
  };
};

const activateScheme = (schemeId, values) => {

  return (dispatch) => {
    dispatch(isSubmitting(true));
    return Axios.post(`products/scheme-groups/${schemeId}:activate`, values)
      .then((resp) => {
        toastr.success('Success', 'Schemes have been activated');
        return resp.data;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Failed to activate schemes');
      })
      .finally(() => {
        dispatch(isSubmitting(false));
      });
  };
};

const lintScheme = (schemeGroupId) => {
  return (dispatch) => {
    dispatch(isSubmitting(true));
    return Axios.post(`products/scheme-groups/${schemeGroupId}/configuration:lint`)
      .then((response) => {
        dispatch({ type: SET_SCHEME_LINT_FAILURES, data: response.data?.meta });
        dispatch(isSubmitting(false));
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Failed to lint scheme');
        dispatch(isSubmitting(false));
      });
  };
};

const editRates = (id, loadingMessage) => {
  return (dispatch) => {
    dispatch(isLoadingRates(true, id, loadingMessage));
    return Axios.post(`products/scheme-groups/${id}:edit-rates`, {})
      .then((response) => {
        if (window && response.data.meta.url) {
          window.open(response.data.meta.url, '_blank');
        }
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Failed to get scheme document URL');
      }).finally(() => {

        dispatch(isLoadingRates(false, id, loadingMessage));

      });
  };
};

const importRates = (id, loadingMessage) => {
  return (dispatch) => {
    dispatch(isLoadingRates(true, id, loadingMessage));
    return Axios.post(`products/scheme-groups/${id}:import-rates`, {})
      .then(() => {
        toastr.success('Success', 'Your rates have been imported');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Failed to get scheme document URL');
      }).finally(() => {
        dispatch(isLoadingRates(false, id, loadingMessage));

      });
  };
};

const lockRates = (id, loadingMessage) => {
  return (dispatch) => {
    dispatch(isLoadingRates(true, id, loadingMessage));
    return Axios.post(`products/scheme-groups/${id}:lock-rates`, {})
      .then(() => {

        toastr.success('Success', 'Rates have been locked');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Failed to lock rates');
      }).finally(() => {
        dispatch(isLoadingRates(false, id, loadingMessage));
      });
  };
};
const setSchemeGroupDetails = (scheme, included = null, meta = null) => {
  return (dispatch) => {
    dispatch({
      type: schemeManagementActions.SET_SCHEME_GROUP_DETAILS,
      data: { data: scheme, included: included, meta: meta },
    });
  };
};

const getSchemeGroupDetails = (schemeId) => {
  return (dispatch) => {
    const endpoint = `/products/scheme-groups/${schemeId}`;
    const includes = [
      'all_schemes.claims_handler',
      'all_schemes.assistance_handler',
      'all_schemes.renews_to',
      // 'all_schemes.insurer',
      'insurer',
      'product',
      'product.contents'
    ];
    const meta = ['config', 'options', 'benefits', 'rates', 'rules', 'parameters'];

    return Axios.get(endpoint + queryString(includes, meta)).then((response) => {
      dispatch(
        setSchemeGroupDetails(
          response.data.data,
          response.data.included,
          response.data.meta,
        ),
      );
      dispatch(addResource(response.data.data.id, response.data.data.attributes.name));

    });
  };
};

const createNewSchemeGroupVersion = (schemeId) => {

  return (dispatch) => {

    dispatch(isVersioning(schemeId, true));
    return Axios.patch(`products/scheme-groups/${schemeId}`, {
      // meta: {
      //   queue: true,
      // },
    })
      .then(() => {
        // toastr.success('Success', 'Request for new scheme version sent');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'There was an error updating the scheme');
      }).finally(() => {

        dispatch(isVersioning(null, false));
      });
  };
};


const updateSchemeGroupConfiguration = (values, schemeId) => {
  const endpoint = `products/scheme-groups/${schemeId}/configuration`;
  return (dispatch) => {
    dispatch(isSubmitting(true));
    return Axios.put(endpoint, values)
      .then(() => {
        toastr.success('Success', ' Your changes has been saved.');
        dispatch(isSubmitting(false));
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'Failed to perform this action.');
      });
  };
};

const updateSchemeGroup = (values, schemeId) => {

  const includes = [
    'all_schemes.claims_handler',
    'all_schemes.assistance_handler',
    'all_schemes.renews_to',
    // 'all_schemes.insurer',
    'insurer',
    'product'
  ];

  const endpoint = `products/scheme-groups/${schemeId}${queryString(includes)}`;
  return (dispatch) => {
    dispatch(isSubmitting(true));
    return Axios.patch(endpoint, values)
      .then(() => {
        toastr.success('Success', ' Your changes has been saved.');
        dispatch(isSubmitting(false));
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'Failed to perform this action.');
      });
  };
};

const endScheme = (schemeId, end) => {

  return () => {
    isSubmitting(true);
    return Axios.post(`products/scheme-groups/${schemeId}:end`)
      .then(() => {
        // dispatch(getProduct());
        toastr.success('Success', `Scheme has been ${end ? 'ended' : 'deactivated'}`);
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'Failed to deactivate scheme');
      }).finally(() => {
        isSubmitting(false);
      });
  };
};

const clearSchemeGroupDetails = () => {
  return (dispatch) => {
    dispatch({
      type: schemeManagementActions.SET_SCHEME_GROUP_DETAILS,
      data: undefined,
    });
  };
};

export const actions = {
  createNewVersion,
  activateScheme,
  createNewSchemeGroupVersion,
  endOrDeactivateScheme: endScheme,
  lintScheme,
  editRates,
  importRates,
  lockRates,
  getSchemeGroupDetails,
  clearSchemeGroupDetails,
  updateSchemeGroup,
  updateSchemeGroupConfiguration,
  getAncillarySchemes
};

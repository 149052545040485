import React, { Component } from 'react';
import { DataTable } from '../../../common/components';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { FormattedDate, FormattedTime } from 'react-intl';
import { refreshData } from '../../../common/components/DataTable/redux/dataTableActions';

export default class PrintJobsAudit extends Component {
  formatDate(cell) {
    return (
      <span>
        <FormattedDate value={new Date(cell)} />{' '}
        <FormattedTime
          value={new Date(cell)}
          hour="numeric"
          minute="numeric"
          second="numeric"
        />
      </span>
    );
  }

  refreshList() {
    this.props.dispatch(refreshData('audit'));
  }

  render() {
    return (
      <div>
        <h4 className="resource-name">Audit Log</h4>

        <DataTable
          source={'/events'}
          autoFilters={[
            { type: 'filter[resource]', value: this.props.resource.data.id },
            { type: 'related', value: true },
          ]}
          name="audit"
          showTotal
        >
          <TableHeaderColumn dataField="id" isKey hidden>
            ID
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField="occurredAt"
            dataFormat={this.formatDate}
            width={'30%'}
            dataSort
          >
            Occurred at
          </TableHeaderColumn>

          <TableHeaderColumn dataField="action" width={'30%'} dataSort>
            Action
          </TableHeaderColumn>

          <TableHeaderColumn dataField="description" width={'40%'} dataSort>
            Description
          </TableHeaderColumn>

          <TableHeaderColumn dataField="user_name" width={'20%'}>
            User
          </TableHeaderColumn>
        </DataTable>
      </div>
    );
  }
}

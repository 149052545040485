import React from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import Icon from '../../../../common/components/Icon/Icon';
import { connect } from 'react-redux';
import { actions } from '../../redux/documentManagementActions';
import {
  actions as schemeActions
} from '../../../schemeManagement/redux/schemeManagementActions';

const DocumentDropdownActions = ({
  onViewEditDocument,
  onViewDocument,
  onGetDocument,
  onGetAncillarySchemes,
  document,
  isSuperseding,
}) => {
  const handleViewDocument = () => {
    const action = isSuperseding ? 'view-superseding' : 'view';
    onViewDocument(action, document);
  };

  const handleViewEditDocument = () => {
    const action = isSuperseding ? 'edit-superseding' : 'edit';

    if (document.is_ancillary_scheme_document) {
      return Promise.resolve(onGetAncillarySchemes(document.insurer_code)).then(() => {
        return Promise.resolve(onGetDocument(document.id)).then((resp) => {
          onViewEditDocument(action, resp);
        });
      });
    } else {

      return Promise.resolve(onGetDocument(document.id)).then((resp) => {
        onViewEditDocument(action, resp);
      });
    }
  };

  const handleAddSupersedingDocument = () => {
    if (document.is_ancillary_scheme_document) {
      return Promise.resolve(onGetAncillarySchemes(document.insurer_code)).then(() => {
        return Promise.resolve(onGetDocument(document.id)).then((resp) => {
          onViewEditDocument('add-superseding', resp);
        });
      });
    } else {
      return Promise.resolve(onGetDocument(document.id)).then((resp) => {
        onViewEditDocument('add-superseding', resp);
      });

    }
  };

  const isEditable = document.status !== 'ended' && !document.is_replaced;

  return (
    <div>
      <DropdownButton
        pullRight
        bsSize="small"
        title={'Actions'}
        bsStyle={'default'}
        id={'dropdown-scheme-actions-' + document.id}
        // disabled={schemeStatus === 'inactive'}
      >
        <MenuItem eventKey="1" onClick={handleViewDocument}>
          <Icon name="eye"/> View
        </MenuItem>
        {isEditable && (
          <MenuItem eventKey="2" onClick={handleViewEditDocument}>
            <Icon name="plus-circle"/>{' '}
            {document.status === 'pending' ? 'Edit' : 'Create New Version'}
          </MenuItem>
        )}
        {isEditable && !isSuperseding && (
          <MenuItem eventKey="3" onClick={handleAddSupersedingDocument}>
            <Icon name="plus"/> Add Superseding Document
          </MenuItem>
        )}
      </DropdownButton>
    </div>
  );
};

export default connect(
  null,
  {
    onGetDocument: actions.getDocument,
    onViewDocument: actions.viewDocument,
    onViewEditDocument: actions.editDocument,
    onGetAncillarySchemes: schemeActions.getAncillarySchemes,
  },
)(DocumentDropdownActions);

import React from 'react';
import { useSelector } from 'react-redux';
import { Box, ButtonBar, Loader } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import getIncludedResource from '../../../helpers/getIncludedResource';
import { Button } from '../../../common/components/Button/Button';
import { Definition } from '../components/Definition/Definition';
import ReactMarkdown from 'react-markdown';
import {
  ProductInfoContainer
} from '../components/ProductInfoContainer/ProductInfoContainer';
import ProductName
  from '../components/ProductFrontPage/components/ProductName/ProductName';
import ProductNavigation from '../components/ProductNavigation/ProductNavigation';

const ValueRow = ({ value }) => (
  <div className={'section-item'}>
    <p className={'title-case'}>{value}</p>
  </div>
);
const MedicalQuestion = ({ question }) => {
  return (<>
    <ValueRow value={
      <>
        <div>
          <strong>Question Number </strong>
          {question.question_number}
        </div>

        <div>
          <strong>Category: </strong>
          {question.category}
        </div>
        <div>
          <strong>Screen on YES: </strong>
          {question.show_medical ? 'Yes' : 'No'}
        </div>
        <div>
          <strong>Content: </strong>
          <ReactMarkdown source={question.content ?? ''}/>
        </div>
      </>
    }/>
  </>);
};
const getMedical = (medicalDeclarationQuestions, contents) => {
  const isMedicalContent = medicalDeclarationQuestions === 'getContentVersion';
  if (isMedicalContent) {
    const activeMedicalQuestions = Array.isArray(contents) ? contents?.find((content) => {
      return content.attributes.status === 'active' && content.attributes.content_type === 'question';

    }) : {};
    return activeMedicalQuestions?.attributes?.sections?.questions;

  }
  return medicalDeclarationQuestions?.questions;

};
export const WhitelabelProductShowContainer = ({
  productId,
  currentLocation,
}) => {

  const {
    product,
    isLoading,
  } = useSelector((state) => state.productManagement);

  //const schemeGroups = getIncludedResource(product?.data, product?.included, 'all_scheme_groups');
  const { icon, name, status, metadata } = product?.data?.attributes ?? {};

  const {
    regions,
    residencies,
    options,
    annual_trip_durations: maxTripDurations,
    medical_declaration_questions: medicalDeclarationQuestions,
    definitions,
    pre_quote_declaration: preQuoteDeclaration,
    purchase_declaration: purchaseDeclaration,
    trip_types: tripTypes,
    rules,
    is_wl_product: isWhitelabel
    //cover_levels: coverLevels,
  } = metadata ?? {};
  const {} = metadata ?? {};
  const contents = getIncludedResource(product?.data, product?.included, 'contents');
  const medicalQuestions = getMedical(medicalDeclarationQuestions, contents) ?? {};
  const { age_limit: ageLimit } = rules;


  if (isLoading) {
    return (
      <div className="admin-area">
        <InternalContainer title="Product management">
          <Loader/>
        </InternalContainer>
      </div>
    );
  }


  return (
    <div className="admin-area">
      <InternalContainer title="Product management">
        <div>

          <ProductName
            name={name}
            title={'Product Details'}
            productIcon={icon}
            status={status}

          >
            <ProductNavigation
              currentLocation={currentLocation}
              id={productId}
              isWhitelabel={isWhitelabel}
            />
          </ProductName>
          <Box>
            <ProductInfoContainer title="Residencies">
              <>
                {residencies?.map((residency) => {
                  return (
                    <ValueRow key={residency?.key} value={residency?.value}/>
                  );
                })}
              </>
            </ProductInfoContainer>
            <ProductInfoContainer title="Trip Types">
              <>
                {tripTypes.map((tripType) => {
                  return (
                    <ValueRow key={tripType.key} value={tripType.value}/>
                  );
                })}
              </>
            </ProductInfoContainer>
            <ProductInfoContainer title="Regions">
              <>
                {regions.map((region) => {
                  return (
                    <ValueRow key={region.key} value={region.value}/>
                  );
                })}
              </>
            </ProductInfoContainer>
            <ProductInfoContainer title="Max Trip Durations">
              <>
                {maxTripDurations.map((maxTripDuration) => {
                  return (
                    <ValueRow
                      key={maxTripDuration.key}
                      value={maxTripDuration.value}/>
                  );
                })}
              </>
            </ProductInfoContainer>

            <ProductInfoContainer title="Medical Questions">
              {Object.keys(medicalQuestions).map((key) => {
                return (
                  <ValueRow key={key} value={
                    <MedicalQuestion question={medicalQuestions[key]}/>
                  }/>
                );
              })}
            </ProductInfoContainer>

            <ProductInfoContainer title="Options">
              <>
                {options?.map((option) => {
                  return (
                    <>
                      <ValueRow
                        key={option.key}
                        value={option?.value?.name}
                      />
                    </>
                  );
                })}
              </>
            </ProductInfoContainer>
            <ProductInfoContainer title="B2B Quote Declaration">
              <ValueRow
                value={<ReactMarkdown source={preQuoteDeclaration?.bo || 'Not Set'}/>}
              />
            </ProductInfoContainer>

            <ProductInfoContainer title="B2C Quote Declaration">
              <ValueRow
                value={<ReactMarkdown source={preQuoteDeclaration?.b2c || 'Not Set'}/>}
              />
            </ProductInfoContainer>
            <ProductInfoContainer title="B2B Purchase Declaration">
              <ValueRow
                value={<ReactMarkdown source={purchaseDeclaration?.bo || 'Not Set'}/>}
              />
            </ProductInfoContainer>

            <ProductInfoContainer title="B2C Purchase Declaration">
              <ValueRow
                value={<ReactMarkdown source={purchaseDeclaration?.b2c || 'Not Set'}/>}
              />
            </ProductInfoContainer>

            <ProductInfoContainer title="Defintions">
              <>
                {Object.keys(definitions).map((key) => {
                  return (
                    <>
                      <Definition
                        definition={definitions[key]}
                        name={key}
                      />
                    </>
                  );
                })}
              </>
            </ProductInfoContainer>
            <ProductInfoContainer title="Date Picker Rueles">
              <ValueRow value={
                <>
                  <strong>Adult Max Age: </strong>
                  {ageLimit?.family?.adult?.max_age} Years
                </>}
              />
              <ValueRow value={
                <>
                  <strong>Adult Min Age: </strong>
                  {ageLimit?.family?.adult?.min_age} Years
                </>}
              />
              <ValueRow value={
                <>
                  <strong>Child Max Age: </strong>
                  {ageLimit?.family?.children?.max_age} Years
                </>}
              />
              <ValueRow value={
                <>
                  <strong>Child Min Age: </strong>
                  {ageLimit?.family?.children?.min_age} Years
                </>}
              />
              <ValueRow value={
                <>
                  <strong>Family Adult Max Age: </strong>
                  {ageLimit?.family?.adult?.max_age} Years
                </>}
              />
              <ValueRow value={
                <>
                  <strong>Family Adult Min Age: </strong>
                  {ageLimit?.family?.adult?.min_age} Years
                </>}
              />
              <ValueRow value={
                <>
                  <strong>Family Child Max Age: </strong>
                  {ageLimit?.family?.children?.max_age} Years
                </>}
              />
              <ValueRow value={
                <>
                  <strong>Family Child Min Age: </strong>
                  {ageLimit?.family?.children?.min_age} Years
                </>}
              />

              <ValueRow value={
                <>
                  <strong>Backdate cover: </strong>
                  {rules?.backdate_cover} Days
                </>}
              />
              <ValueRow value={
                <>
                  <strong>Max Trip Duration: </strong>
                  {rules?.max_trip_duration} Days
                </>}
              />
              <ValueRow value={
                <>
                  <strong>Single Trip Advance: </strong>
                  {rules?.st_advance_months}Months
                </>}
              />
              <ValueRow value={
                <>
                  <strong>Annual Advance: </strong>
                  {rules?.amt_advance_days} Days
                </>}
              />

            </ProductInfoContainer>
          </Box>
          <ButtonBar>
            <Button
              link
              to={`/admin/products/${productId}/edit`}
              label={'edit'}
              className={'pull-right'}
            />

          </ButtonBar>
        </div>

      </InternalContainer>
    </div>
  );

};



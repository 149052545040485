import { FaMinus, FaPlus } from 'react-icons/fa';
import React, { useState } from 'react';
import './CollapsableBox.scss';

const CollapsableBox = ({ label, children }) => {
  const [show, setShow]  = useState(false);
  const toggleWhiteLabelContent = () => {
    setShow(!show);
  };

  return (
    <div className="wl-content-collapsed-box">
      <div
        className={`wl-toggle-content-btn ${show ? 'active' : ''}`}
        onClick={toggleWhiteLabelContent}
      >
        <h4>{label}</h4>
        {show ? <FaMinus/> : <FaPlus/>}
      </div>
      {show && (
        <div className="wl-content-box-content">
          {children}
        </div>
      )}
    </div>
  );

};

export default CollapsableBox;

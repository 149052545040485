import React, { useEffect, useState } from 'react';
import { getAccounts } from '../../redux/policyActions';
import { connect } from 'react-redux';
import Loader from '../../../../common/components/Loader/Loader';
import Select from 'react-select';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Box from '../../../../common/components/Box/Box';
import formatDate from '../../../../helpers/formatDate';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import ReallocateCommissionsModalNew
  from '../../../account/components/ReallocateCommissionsModalNew/ReallocateCommissionsModalNew';
import './PolicyAccountsList.scss';
import Icon from '../../../../common/components/Icon/Icon';

const LedgerEntries = ({ row }) => {
  return (
    <BootstrapTable
      data={row}
      options={
        {
          noDataText: (<div className="text-center bs-table-placeholder">No results found</div>),
        }
      }
      striped
      hover
    >

      <TableHeaderColumn dataField="id" isKey hidden/>
      <TableHeaderColumn dataField="account" width={'20%'} dataSort>
        Account Name
      </TableHeaderColumn>
      <TableHeaderColumn dataField="description" width={'50%'} dataSort>
        Description
      </TableHeaderColumn>
      <TableHeaderColumn dataField="element" width={'15%'} dataSort>
        Type
      </TableHeaderColumn>
      <TableHeaderColumn dataField="amount" width={'10%'} dataSort>
        Amount
      </TableHeaderColumn>
    </BootstrapTable>
  );
};

const ExpandColumnComponent = ({ isExpanded }) => {
  if (isExpanded) {
    return (
      <Icon name={'minus'}/>
    );
  }
  return (
    <Icon name={'plus'}/>
  );
};

const PolicyAccountsList = ({ resource, dispatch }) => {
  const [premiumsOptions, setPremiumOptions] = useState(null);
  const [selectedPremium, setSelectedPremium] = useState(null);
  const [accounts, setAccounts] = useState(null);

  useEffect(() => {
    setup();
  }, []);

  const setup = () => {
    Promise.resolve(dispatch(getAccounts(resource.data.id))).then((accounts) => {
      setAccounts(accounts);
      const options = Object.keys(accounts).map((title) => ({ label: title, value: title }));
      setPremiumOptions(options);
    });
  };

  const handleSelectPremiumChange = (premium) => {
    setSelectedPremium(accounts[premium.value]);
  };
  if (!premiumsOptions) {
    return (
      <Loader/>
    );
  }


  const originals = selectedPremium ? Object.values(selectedPremium.journals.original).reduce((carry, entry) => {
    if (entry.journal) {
      carry = carry.concat({
          'journal_name': entry.journal.name,
          'journal_date': entry.journal.date,
          'journal_id': entry.journal.id,
          'entries': entry.entries

        }
      );
    }

    return carry;


  }, []) : [];

  const payables = selectedPremium ? Object.values(selectedPremium.journals.payable || []).reduce((carry, entry) => {
    if (entry.journal) {
      carry = carry.concat({
          'journal_name': entry.journal.name,
          'journal_date': entry.journal.date,
          'journal_id': entry.journal.id,
          'entries': entry.entries

        }
      );
    }
    return carry;
  }, []) : [];

  const expandComponent = (row) => {
    return (<LedgerEntries
      key={row.journal_date}
      row={row.entries}
    />);
  };
  const expandColumnComponent = ({ isExpanded }) => {
    return (<ExpandColumnComponent
      isExpanded={isExpanded}


    />);
  };
  const tableOptions = {
    expandRowBgColor: 'rgba(36,59,121,0.2)',
    noDataText: (<div className="text-center bs-table-placeholder">No ledger Entries found</div>),

  };



  return (
    <Box className={'policy-accounts-list'}>
      <div className={'policy-accounts-list--title-select'}>
        <Select
          options={premiumsOptions}
          onChange={handleSelectPremiumChange}
        />
      </div>
      {selectedPremium && (
        <div>
          <BootstrapTable
            data={originals}
            key={originals.length}
            expandableRow={() => true}
            expandColumnOptions={{
              expandColumnVisible: true,
              columnWidth: '5%',
              expandColumnComponent: expandColumnComponent,
            }}
            expandComponent={expandComponent}
            options={tableOptions}
            striped
            hover
          >
            <TableHeaderColumn dataField="journal_id" isKey hidden />
            <TableHeaderColumn
              dataField="journal_date"
              dataFormat={formatDate}
              width={'10%'}
            >
              Journal Date
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="journal_name"
              width={'70%'}
            >
              Journal Description
            </TableHeaderColumn>


          </BootstrapTable>


          <h3>Payables</h3>

          <BootstrapTable
            data={payables}
            key={payables.length}
            expandableRow={() => true}
            expandColumnOptions={{
              expandColumnVisible: true,
              columnWidth: '5%',
              expandColumnComponent: expandColumnComponent,
            }}
            expandComponent={expandComponent}
            options={tableOptions}
            striped
            hover


          >
            <TableHeaderColumn dataField="journal_id" isKey hidden/>
            <TableHeaderColumn
              dataField="journal_date"
              dataFormat={formatDate}
              width={'10%'}
            >
              Journal Date
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="journal_name"
              width={'70%'}
            >
              Journal Description
            </TableHeaderColumn>

          </BootstrapTable>

          <InternalButtonBar buttons={[
            {
              permissions: ['account.edit'],
              button: (<ReallocateCommissionsModalNew
                premiumId={selectedPremium.premium.id}
                title={selectedPremium.title}
                refresh={setup}


              />)
            }
          ]}/>
        </div>
      )}


    </Box>
  );

};

export default connect(
  (state) => ({
    policy: state.policy
  })
)(PolicyAccountsList);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InfoLabel } from '../../../../common/components';
import { FormControl, FormGroup } from 'react-bootstrap';
import Markdown from 'react-markdown';
import './styles.scss';
import Icon from '../../../../common/components/Icon/Icon';
import { map } from 'lodash';

const FormControlStatic = FormControl.Static;

export default class DeclarationQuestions extends Component {
  static propTypes = {
    declaration: PropTypes.string,
    questions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  };

  constructor(props) {
    super(props);

    this.state = {
      showContent: false,
    };
  }

  toggleContentView = () => {
    this.setState((prevState) => ({ showContent: !prevState.showContent }));
  };

  renderMedicalDeclaration = () => {
    const { declaration, questions, wrapperClass } = this.props;

    if (this.state.showContent) {
      return (
        <div className="col-xs-12">
          {declaration && <Markdown source={declaration} />}
          {map(questions, (declaration, key) => {
            return (
              <FormGroup
                key={key}
                className={
                  wrapperClass ? wrapperClass + ' infolabel-wrapper' : 'infolabel-wrapper'
                }
              >
                {declaration.type === 'question' ? (
                  <FormControlStatic>
                    {declaration.content} -{' '}
                    <strong>{declaration.answer ? 'Yes' : 'No'}</strong>
                  </FormControlStatic>
                ) : (
                  <FormControlStatic>
                    <strong>{declaration.content}</strong>
                  </FormControlStatic>
                )}
              </FormGroup>
            );
          })}
        </div>
      );
    }

    return <div />;
  };

  render() {
    const { declaration, questions, wrapperClass } = this.props;

    const renderIcon = () => {
      return (
        <div className="display-icon" onClick={this.toggleContentView}>
          <Icon name={!this.state.showContent ? 'plus' : 'minus'} />
        </div>
      );
    };

    return (
      <div className="row">
        <div className="col-xs-6">
          <InfoLabel
            label="Medical declaration"
            value={renderIcon()}
            labelSize={4}
            wrapperClass={'clear-margin'}
          />
        </div>
        <div className="col-xs-12" hidden={!this.state.showContent}>
          {declaration && <Markdown source={declaration} />}
          {map(questions, (declaration, key) => {
            return (
              <FormGroup
                key={key}
                className={
                  wrapperClass ? wrapperClass + ' infolabel-wrapper' : 'infolabel-wrapper'
                }
              >
                {declaration.type === 'question' ? (
                  <FormControlStatic>
                    <span>{declaration.content} </span>{' '}
                    <strong>{declaration.answer ? 'Yes' : 'No'}</strong>
                  </FormControlStatic>
                ) : (
                  <FormControlStatic>
                    <strong>{declaration.content}</strong>
                  </FormControlStatic>
                )}
              </FormGroup>
            );
          })}
        </div>
      </div>
    );
  }
}

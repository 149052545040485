import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { get, find, union, reduce, isEmpty, some, forEach, map, set } from 'lodash';
import { ButtonGroup } from '../../ReduxForm/index';
import ScreeningContainer from '../../../../../screening/containers/ScreeningContainer';
import { Button, ButtonBar } from '../../../../../../common/components/index';
import { Col, FormGroup, Row } from 'react-bootstrap';
import confirm from 'react-confirm2';
import './styles/MedicalTab.scss';
import ReactMarkdown from 'react-markdown';
import { CheckboxGroup } from '../../../../../../common/components/ReduxFormField';
import Markdown from 'react-markdown';

const MEDICAL_META = 'data.attributes.metadata.medical_declaration_questions';
const META = 'data.attributes.metadata';

// FIXME: This should come from the API!
const WARNING_MESSAGE = {
  default: `We notice that you haven’t declared any medical conditions.
Unfortunately, if you do not declare all relevant medical conditions, your policy could get cancelled,
claim payments refused or your claim rejected.  Are you happy to proceed?`,
  MPT: 'We notice that you have ticked ‘Yes’ to one or more of the Medical Screening questions, but you have not declared any medical conditions. Unfortunately, if you do not declare all relevant medical conditions, your policy could get cancelled, claim payments refused or your claim rejected. Are you happy to proceed?\n\n **IMPORTANT NOTE** : If you or anyone else to be insured reside in the United Kingdom or the Isle of Man and your trip is to or within the United Kingdom or the Isle of Man, you do not need to declare any medical conditions. Please click **OK** to continue.',
  LSB: 'As you have answered ‘Yes’ to one or more of the Medical Screening questions, you must now contact Travel Administration Facilities at [www.pjhlongstay.protectif.co.uk](http://www.pjhlongstay.protectif.co.uk) or call them on 0203 829 3855. They will tell you if your pre-existing medical condition/s can be covered. This can be done when you save the quote or after purchasing cover.\n\n **Please note:** We are unable to provide any cover for your medical pre-existing condition, unless we have agreed cover in writing and any additional premium has been paid.',
};

export class MedicalTab03 extends Component {
  handleMedicalNext = () => {
    const {
      dispatch,
      handleSubmit,
      formValues,
      openSpecificTab,
      currentProduct,
      nextTab
    } = this.props;
    const getProductCode = get(currentProduct, 'data.attributes.product_code');

    let questions = get(formValues, MEDICAL_META, []);
    let hasConditions = false;
    const requiresScreening = find(questions, (question) => {
      return question.question_type === 'boolean' && question.answer && (['247', 'VEN'].includes(getProductCode) && question.category !== 'health');
    });

    if (requiresScreening) {
      const travellers = union(
        get(formValues, `${META}.adults`),
        get(formValues, `${META}.children`),
      );
      hasConditions = reduce(
        travellers,
        (carry, traveller) => {
          return carry || !isEmpty(get(traveller, 'screening.meta.conditions'));
        },
        false,
      );
    }
    const answerYesAndNoConditions = !isEmpty(requiresScreening) && !hasConditions;

    if (answerYesAndNoConditions) {
      confirm('Warning', {
        description: (
          <div className="medical-modal">
            {WARNING_MESSAGE[getProductCode] ? (
              <ReactMarkdown
                source={WARNING_MESSAGE[getProductCode]}
                renderers={{
                  link: (props) => (
                    <a href={props.href} target="_blank">
                      {props.children}
                    </a>
                  ),
                }}
              />
            ) : (
              WARNING_MESSAGE.default
            )}
          </div>
        ),
        done: () => {
          return dispatch(handleSubmit(() => openSpecificTab(nextTab)));
        },
        close: () => {
          return true;
        },
      });
    } else {
      return dispatch(handleSubmit(() => openSpecificTab(nextTab)));
    }

  };

  render() {
    const {
      formValues,
      openSpecificTab,
      screening,
      channel,
      currentProduct,
      handleScreeningComplete,
      dispatch,
      change,
      mta,
    } = this.props;
    const medicalMeta = 'data.attributes.metadata.medical_declaration_questions';
    const medicalHeader = get(
      currentProduct,
      'data.attributes.metadata.purchase_declaration.b2c.medical_screening',
    );

    const region = get(formValues, 'data.attributes.metadata.region', '');
    const productCode = get(currentProduct, 'data.attributes.product_code');
    let requiresScreening = false;
    const displayKeys = [];

    const getHeaderFromApi = ['TPL', 'MPT', 'BMC', 'ADV', 'DIA', 'PST'].indexOf(productCode) !== -1;
    const bmc = ['BMC'].includes(productCode);
    const happyToProceed = get(formValues, 'data.attributes.metadata.medical_declaration_questions[happy-proceed].answer', false);
    let hideBmcQuestions = true;
    if (bmc) {
      hideBmcQuestions = happyToProceed;
    }

    let medicalTextStyle = 'medical-question-text';
    let headerStyle = '';

    if (bmc) {
      medicalTextStyle = 'medical-question-text medical-question-header-bmc';
      headerStyle = 'medical-header-bmc';
    }

    let screeningQuestionYes = some(
      get(formValues, 'data.attributes.metadata.medical_declaration_questions', []),
      (declaration, i) => {
        if (declaration.type === 'question' && declaration.question_type === 'boolean') {
          return (
            get(
              formValues,
              'data.attributes.metadata.medical_declaration_questions[' + i + '].answer',
              false,
            ) === true
          );
        }
      },
    );

    if (!['TUMC'].includes(productCode)) {
      forEach(get(formValues, `${medicalMeta}`, {}), (declaration, key) => {
        const answer = get(formValues, `${medicalMeta}[${key}].answer`, false);
        const questionType = declaration.question_type;

        if (displayKeys.length === 0) {
          displayKeys.push(key);
        }

        if (questionType === 'boolean' && answer === true && key !== 'happy-proceed') {
          if (['247', 'VEN'].includes(productCode) && declaration.category === 'health') {
            requiresScreening = false;
          } else {
            requiresScreening = true;
          }

          if (declaration.next_key) {
            displayKeys.push(declaration.next_key);
          }

          if (declaration.true_key) {
            displayKeys.push(declaration.true_key);
          }
        }

        if (questionType === 'boolean' && answer === false) {
          if (declaration.next_key) {
            displayKeys.push(declaration.next_key);
          }

          if (declaration.false_key) {
            displayKeys.push(declaration.false_key);
          }
        }
      });
    }

    if (['CPS'].includes(productCode) && get(formValues, `${META}.medical_declaration_cps`)) {
      requiresScreening = true;
    }

    const getCpiMedDeclaration = get(formValues, `${META}.medical_declaration_cpi`) || !['CPI'].includes(productCode);
    let medTitle = ['CPI'].includes(productCode) ? 'Important Conditions Relating To Your Health'
      : 'Medical Screening';

    if (['CPS'].includes(productCode)) {
      medTitle = 'HEALTH DECLARATION';
    }

    let medicalQuestions = get(formValues, 'data.attributes.metadata.medical_declaration_questions', []);
    if (medicalQuestions.length === 0 && ['ADV', 'PST'].includes(productCode)) {
      medicalQuestions = get(currentProduct, 'data.attributes.metadata.medical_declaration_questions', []);
      forEach(medicalQuestions, (medicalQuestion) => {
        medicalQuestion.answer = undefined;
      });
      dispatch(change(set(formValues, 'data.attributes.metadata.medical_declaration_questions', medicalQuestions)));
    }
    let medConfirmTxt = 'Please tick to confirm that you have understood the Important Conditions above';
    if (['CPS'].includes(productCode)) {
      medConfirmTxt = 'Please therefore CLICK HERE if you need to declare a medical condition. Please note an additional premium may be payable.';
    }

    if (get(
      formValues,
      'data.attributes.metadata.medical_declaration_questions', []).length < 1)
    {
      dispatch(change('data.attributes.metadata.medical_declaration_questions', get(currentProduct,
        'data.attributes.metadata.medical_declaration_questions', [])));
    }

    const options = get(formValues, 'data.attributes.metadata.options');

    const warningMessage = get(screening, 'errors.warning', '');

    return (
      <div>
        {get(channel, 'data.attributes.channel_type') === 'AGG' ? (
          <div className="questions">
            {map(
              get(
                formValues,
                'data.attributes.metadata.medical_declaration_questions',
                [],
              ),
              (declaration, key) => {
                return (
                  <div className="medical-internal-container2" key={key}>
                    <div className="medical-question-text">
                      <ReactMarkdown
                        source={declaration.content || ''}
                        renderers={{
                          link: (props) => (
                            <a href={props.href} target="_blank">
                              {props.children}
                            </a>
                          ),
                        }}
                      />
                    </div>
                    <div className="medical-buttons-container">
                      {declaration.question_type === 'boolean' && (
                        <Field
                          name={
                            'data.attributes.metadata.medical_declaration_questions[' +
                            key +
                            '].answer'
                          }
                          component={ButtonGroup}
                          inline
                          options={[
                            { label: 'Yes', value: true },
                            { label: 'No', value: false },
                          ]}
                        />
                      )}
                    </div>
                  </div>
                );
              },
            )}
          </div>
        ) : (
          <div>
            {!['LSB', 'TUMC', 'CPI', 'CPS'].includes(productCode) && (
              <div>
                {getHeaderFromApi ? (
                  <div className={headerStyle}>
                    <ReactMarkdown
                      source={(medicalHeader && medicalHeader.header) || 'not found'}
                      renderers={{
                        link: (props) => (
                          <a href={props.href} target="_blank">
                            {props.children}
                          </a>
                        ),
                      }}
                    />
                  </div>
                ) : (
                  <div className="medical-header">
                    <div className="medical-header--pink medical-header--pb">
                      {MEDICAL.health.header}
                    </div>
                    <div className="medical-header--pb">{MEDICAL.health.body}</div>
                    {MEDICAL.health.leadingQuestion}
                  </div>
                )}
              </div>
            )}
            <div className="questions">
              {map(
                get(
                  formValues,
                  'data.attributes.metadata.medical_declaration_questions',
                  [],
                ),
                (declaration, key) => {
                  if (declaration.category === 'health') {
                    return (
                      <div className="medical-internal-container2" key={key}>
                        <div className="medical-question-text">
                          <ReactMarkdown
                            source={declaration.content || ''}
                            renderers={{
                              link: (props) => (
                                <a href={props.href} target="_blank">
                                  {props.children}
                                </a>
                              ),
                            }}
                          />
                        </div>
                        <div className="medical-buttons-container">
                          {declaration.question_type === 'boolean' && (
                            <Field
                              name={
                                'data.attributes.metadata.medical_declaration_questions[' +
                                key +
                                '].answer'
                              }
                              component={ButtonGroup}
                              inline
                              options={[
                                { label: 'Yes', value: true },
                                { label: 'No', value: false },
                              ]}
                            />
                          )}
                        </div>
                      </div>
                    );
                  }
                },
              )}

              {!getHeaderFromApi && (
                <div className="medical-header">
                  <div className="medical-header--pink medical-header--pb">
                    {medTitle}
                  </div>
                  {!['CPS'].includes(productCode) && (
                    <div>{MEDICAL.screening[productCode] || MEDICAL.screening.body}</div>
                  )}
                </div>
              )}
              {!['CPI'].includes(productCode) && !['CPS'].includes(productCode) ? (
                <div>
                  {map(get(
                      formValues,
                      'data.attributes.metadata.medical_declaration_questions', []),
                    (declaration, key) => {
                      if (declaration.category === 'medical' && hideBmcQuestions) {
                        return (
                          <div className="medical-internal-container" key={key}>
                            <div className={medicalTextStyle}>
                              <ReactMarkdown
                                source={declaration.content}
                                renderers={{
                                  link: (props) => (
                                    <a href={props.href} target="_blank">
                                      {props.children}
                                    </a>
                                  ),
                                }}
                              />
                            </div>
                            <div className="medical-buttons-container">
                              {declaration.question_type === 'boolean' && (
                                <Field
                                  name={
                                    'data.attributes.metadata.medical_declaration_questions[' +
                                    key +
                                    '].answer'
                                  }
                                  component={ButtonGroup}
                                  inline
                                  options={[
                                    { label: 'Yes', value: true },
                                    { label: 'No', value: false },
                                  ]}
                                />
                              )}
                            </div>
                          </div>
                        );
                      } else if (declaration.category === 'medical' && bmc && key === 'happy-proceed') {
                        return (
                          <div className="medical-internal-container" key={key}>
                            <div className={medicalTextStyle}>
                              <ReactMarkdown
                                source={declaration.content}
                                renderers={{
                                  link: (props) => (
                                    <a href={props.href} target="_blank">
                                      {props.children}
                                    </a>
                                  ),
                                }}
                              />
                            </div>
                            <div className="medical-buttons-container">
                              {declaration.question_type === 'boolean' && (
                                <Field
                                  name={
                                    'data.attributes.metadata.medical_declaration_questions[' +
                                    key +
                                    '].answer'
                                  }
                                  component={ButtonGroup}
                                  inline
                                  options={[
                                    { label: 'Yes', value: true },
                                    { label: 'No', value: false },
                                  ]}
                                />
                              )}
                            </div>
                          </div>
                        );
                      }
                    },
                  )}
                </div>
              ) : (
                ['CPS'].includes(productCode) ? (
                  <div>
                    {region === 'uk' ? (
                      <div>
                        <div className="medical-header--pink-cpi medical-header--pb" style={{ paddingTop: '10px', }}>
                          TRAVELLING WITHIN THE UK OR THE ISLE OF MAN
                        </div>
                        <p>
                          If you are travelling in the UK or The Isle of Man, you are not required to declare
                          your medical conditions. However, to be covered for any medical conditions you have
                          or have had, you must be able to comply with the following;
                        </p>
                      </div>
                    ) : (
                      <div>
                        <div className="medical-header--pink-cpi medical-header--pb" style={{ paddingTop: '10px', }}>
                          TRAVELLING WITHIN THE UK OR ISLE OF MAN
                        </div>
                        <p>
                          If you are travelling in the UK or Isle of Man, you are not required to
                          declare your medical conditions. However, to be covered for any medical conditions you
                          have or have had, you must be able to comply with the following:
                        </p>
                      </div>
                    )}
                    <p className="cpi-medical-questions">
                      1. You are fit to travel and undertake your planned journey.
                    </p>
                    <p className="cpi-medical-questions">
                      2. Following any claim investigation, your doctor confirms they would
                      have agreed with or recommended your travel plans when you took out this insurance,
                      booked your journey or at the time you travelled (whichever is later).
                    </p>
                    <p className="cpi-medical-questions">
                      3. You are not travelling with the purpose of having medical treatment or a consultation.
                    </p>
                    <p className="cpi-medical-questions">
                      4. You are not awaiting the outcome of any medical tests or an appointment at a medical facility for any medical investigations.
                    </p>
                    <div className="medical-header--pink-cpi medical-header--pb">
                      TRAVELLING OUTSIDE OF THE UK AND ALL AIR & CRUISE HOLIDAYS
                    </div>
                    <p>
                      If you are travelling outside of the UK or are going on an Air or Cruise Holiday, you will not be covered for any claims
                      arising as a direct or indirect result of an existing medical condition if, in the 12 months before taking out this
                      insurance or booking your journey (whichever is later), you:
                    </p>
                    <p className="cpi-medical-questions">
                      a) Were referred for tests, investigations, treatment or surgery, or were awaiting the results of any referral;
                    </p>
                    <p className="cpi-medical-questions">
                      b) Attended or were due to attend a hospital or clinic as an outpatient or inpatient;
                    </p>
                    <p className="cpi-medical-questions">
                      c) Received treatment or saw a medical practitioner for a medical condition; or
                    </p>
                    <p className="cpi-medical-questions">
                      d)    Were prescribed medication
                    </p>
                    <p className="cpi-medical-questions">
                      Unless the condition(s) has (have) been declared to us and accepted in writing.
                    </p>
                    <p className="cpi-medical-questions">
                      <FormGroup>
                        <Col md={12} lg={12}>
                          <Field
                            name={'data.attributes.metadata.medical_declaration_cps'}
                            inline
                            options={[
                              {
                                label: medConfirmTxt,
                                value: true,
                              },
                            ]}
                            component={CheckboxGroup}
                          />
                        </Col>
                      </FormGroup>
                    </p>
                    <div className="medical-header--pink-cpi medical-header--pb">
                      PLEASE NOTE
                    </div>
                    <p>
                      You will NOT be covered if:
                    </p>
                    <ul>
                      <li>
                        Following any claim investigation, your doctor confirms they
                        would not have agreed with or recommended you travel as planned
                        either when you took out this insurance, booked your journey or at
                        the time you travelled (whichever is later).
                      </li>
                      <li>
                        You know you will need medical treatment or a consultation at a medical facility during your journey.
                      </li>
                      <li>
                        You are travelling specifically for the purpose of having surgery, procedures or hospital treatment, whether medically necessary or not.
                      </li>
                      <li>
                        You have been diagnosed with a terminal illness prior to the date you took out this insurance or booked your trip (whichever is later).
                      </li>
                    </ul>
                    <p>
                      If we are unable to cover a medical condition, this will mean that any person insured with us will not be able to make a claim arising from the medical condition(s).
                    </p>
                  </div>
                ) : (
                  <div className="medical-header">
                    <div className="medical-header--pink-cpi medical-header--pb" style={{ paddingTop: '10px', }}>
                      1. The insured person:
                    </div>
                    <p className="cpi-medical-questions">
                      i) is aware of any set of circumstances which could reasonably have been expected to give rise
                      to a claim; or
                    </p>
                    <p className="cpi-medical-questions">
                      ii) has suffered from a chronic or recurring medical condition during the previous 12 months
                      UNLESS permission is obtained from the treating doctor of fitness to travel at the time of
                      booking; or
                    </p>
                    <p className="cpi-medical-questions">
                      iii) is travelling against the advice of a doctor or a medical professional such as your dentist
                      or where they would have been if they had sought their advice before beginning the journey; or
                    </p>
                    <p className="cpi-medical-questions">
                      iv) knows that they will need treatment or consultation at any medical facility during the
                      journey; or
                    </p>
                    <p className="cpi-medical-questions">
                      v) is travelling for the purpose of obtaining and/or receiving any elective surgery, procedure or
                      hospital treatment.
                    </p>
                    <div className="medical-header--pink-cpi medical-header--pb">
                      2. The person whose condition gives rise to a claim:
                    </div>
                    <p className="cpi-medical-questions">
                      i) is receiving, or on a waiting list for treatment in a hospital or nursing home; or
                    </p>
                    <p className="cpi-medical-questions">
                      ii) is awaiting the results of any tests or investigations; or
                    </p>
                    <p className="cpi-medical-questions">
                      iii) has been given a terminal prognosis.
                    </p>
                    <p className="cpi-medical-questions">
                      <div>
                        If the insured person has an existing medical condition for which consultation or treatment has
                        been received during the 6 months prior to the date of issue of the policy, the excesses under
                        Cancellation/Curtailment and Medical repatriation & other expenses are increased from £40 to
                        £80.
                      </div>
                      <FormGroup>
                        <Col md={12} lg={12}>
                          <Field
                            name={'data.attributes.metadata.medical_declaration_cpi'}
                            inline
                            options={[
                              {
                                label: medConfirmTxt,
                                value: true,
                              },
                            ]}
                            component={CheckboxGroup}
                          />
                        </Col>
                      </FormGroup>
                    </p>
                  </div>
                )
              )}
            </div>
          </div>
        )}

        <hr />
        {requiresScreening && (
          <>
            <div className="row medical-screening">
              <div className="col-xs-12 screening-message">
                <p className="control-label">
                  Please note: <br />
                  Any medical conditions(s) not declared to and accepted by us, will not be
                  covered.
                </p>
              </div>
              {get(formValues, 'data.attributes.metadata.adults', []).map(
                (adult, index) => {
                  return (
                    <div key={index}>
                      <ScreeningContainer
                        key={index}
                        index={index + 1}
                        traveller={adult}
                        region={get(formValues, 'data.attributes.metadata.region', '')}
                        tripType={get(
                          formValues,
                          'data.attributes.metadata.scheme_type',
                          '',
                        )}
                        destinations={get(
                          formValues,
                          'data.attributes.metadata.destinations',
                          [],
                        )}
                        path={`data.attributes.metadata.adults.${index}`}
                        callback={handleScreeningComplete}
                        currentTab={this.props.currentTab}
                        currentProduct={currentProduct}
                        options={options}
                        applicationId={get(formValues, 'data.id')}
                        travellerType={'adults'}
                        adultIndex={index}
                        mta={mta}
                      />
                    </div>
                  );
                },
              )}
              {get(formValues, 'data.attributes.metadata.children', []).map(
                (child, index) => {
                  return (
                    <ScreeningContainer
                      key={index}
                      index={
                        get(formValues, 'data.attributes.metadata.adults', []).length +
                        index +
                        1
                      }
                      traveller={child}
                      region={get(formValues, 'data.attributes.metadata.region', '')}
                      tripType={get(formValues, 'data.attributes.metadata.scheme_type', '')}
                      destinations={get(
                        formValues,
                        'data.attributes.metadata.destinations',
                        [],
                      )}
                      path={`data.attributes.metadata.children.${index}`}
                      callback={handleScreeningComplete}
                      currentTab={this.props.currentTab}
                      currentProduct={currentProduct}
                      options={options}
                      applicationId={get(formValues, 'data.id')}
                      travellerType={'children'}
                      childIndex={index}
                      mta={mta}
                    />
                  );
                },
              )}
            </div>
            {warningMessage && (
              <div style={{
                backgroundColor: '#FDE5E5FF',
                padding: '10px',
                margin: '10px 0 10px 0',
              }}>
                <div>There has been an issue with a condition below, please review the
                  declaration
                  again:
                </div>
                <div style={{ color: 'red', fontSize: '12px' }}>
                  <Markdown source={warningMessage} />
                </div>
              </div>
            )}
          </>
        )}
        <Row>
          <Col xs={12}>
            <ButtonBar>
              <Button
                label="Back"
                id="back_medical"
                className="btn-action-back pull-left"
                bsStyle="primary"
                disabled={screening.index !== 0 && screeningQuestionYes}
                handleClick={() => openSpecificTab(2)}
              />
              <Button
                label="Next"
                id="next_medical"
                className="pull-right btn-action-blue"
                bsStyle="primary"
                disabled={screening.index !== 0 && screeningQuestionYes || !getCpiMedDeclaration}
                handleClick={this.handleMedicalNext}
              />
            </ButtonBar>
          </Col>
        </Row>
      </div>
    );
  }
}

const FORM_NAME = 'LeisureTravelForm';
const form = reduxForm({ form: FORM_NAME })(MedicalTab03);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  const values = selector(state, 'data.id', 'data.attributes.metadata');

  return {
    auth: state.auth,
    initialValues: props.resource,
    form: 'LeisureTravelForm',
    formValues: values,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    product: state.product,
  };
};

const MEDICAL = {
  health: {
    header: 'Important Conditions Relating To Your Health',
    body:
      'We need to ask you some medical questions about you and anyone else to be insured on the policy to make sure that you have the right cover.',
    leadingQuestion: 'Are you or anyone else to be insured on this policy:',
  },
  screening: {
    body: "Please answer the following questions. If you or anyone else to be insured on this policy answer 'Yes' to any of these you will need to go through our medical screening process. If you are not sure of any of the information you are giving us or do not know the answer, please check with the treating G.P.",
    LSB: 'We need to ask you some medical questions about you and anyone else to be insured on the policy to make sure that you have the right cover.',
    CPI: 'Staycationplan does not cover claims where at the time of booking your journey, taking out this insurance or paying any further balance or instalments:'
  },
};

export default connect(mapStateToProps)(form);

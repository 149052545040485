import React, {  useMemo, useState } from 'react';
import { InfoLabel, Button, Icon } from '../../../../common/components';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import {
  deactivateBroker,
  reactivateBroker,
  viewStatement,
} from '../../redux/brokerActions';
import TransferBrokerButton from '../TransferBrokerButton';
import { BROKER_STATUSES } from '../../../../schemas/broker';
import displayName from '../../../../helpers/displayName';
import displayAddress from '../../../../helpers/displayAddress';
import { Row, Col, Panel } from 'react-bootstrap';
import {
  InternalButtonBar
} from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import { Link } from 'react-router';
import './styles.scss';
import { connect, useSelector } from 'react-redux';


const getButtons = (resource, accountId, organisationId, viewBrokerStatement,  deactivateBroker, reactivateBroker, isDeclining) => {
  const deactivate = () => {
    deactivateBroker(brokerId);
  };

  const reactivate = () => {
    reactivateBroker(brokerId);
  };

  const viewStatement = () => {
    viewBrokerStatement(brokerId);
  };

  const resourceAttributes = resource.data.attributes;
  const brokerType = resourceAttributes?.broker_type ?? 'broker';
  const brokerId = resource.data.id;


  const actionButtons = [
    {
      types: ['broker', 'tracking', 'introducer'],
      permissions: ['broker.edit'],
      button: (
        <Button
          label={brokerType !== 'tracking' ? 'Edit Broker' : 'Edit Tracking Link'}
          link
          to={'/brokers/' + brokerId + '/edit'}
          className="pull-right"
          bsStyle="primary"
          rightIcon="pencil-square-o"
        />
      ),
    },
    {
      types: ['broker', 'introducer'],
      permissions: ['account.view'],
      button: (
        <Button
          className="pull-right"
          bsStyle="primary"
          rightIcon="list"
          // isLoading={this.props.broker.isGeneratingStatement}
          handleClick={viewStatement}
          label="View Statement"
        />
      ),
    },
    {
      types: ['broker', 'introducer'],
      permissions: ['account.view'],
      button: (
        <Button
          className="pull-right"
          bsStyle="primary"
          link
          to={`/accounts/ledger/${accountId}`}
          label="View Account"
        />
      ),
    },
    {
      types: ['broker', 'introducer'],
      permissions: ['broker.edit'],
      button: (
        <TransferBrokerButton
          className="pull-right"
          label="Merge Agencies"
          resource={resource}
          resourceType={'brokers'}
        />
      ),
    },
    {
      types: ['broker', 'introducer'],
      statuses: ['active'],
      permissions: ['broker.deactivate'],
      button: (
        <Button
          className="pull-right"
          label="Deactivate"
          bsStyle="primary"
          rightIcon="ban"
          isLoading={isDeclining}
          handleClick={deactivate}
        />
      ),
    },
    {
      types: ['broker', 'introducer'],
      statuses: ['inactive'],
      permissions: ['broker.deactivate'],
      button: (
        <Button
          className="pull-right"
          label="Reactivate"
          bsStyle="primary"
          rightIcon="check"
          isLoading={isDeclining}
          handleClick={reactivate}
        />
      ),
    },
    {
      types: ['broker', 'introducer'],
      permissions: ['organisation.view'],
      button: (
        <Button
          className="pull-right"
          label="View organisation"
          bsStyle="primary"
          link
          to={`/admin/organisations/${organisationId}`}
        />
      ),
    },
  ];

  return actionButtons.filter((button) => {
    if (button.types.indexOf(brokerType) === -1) {
      return false;
    }


    return !(button.statuses && button.statuses.indexOf(resource?.data?.attributes?.status) === -1);
  });
};
const BrokerDetails = ({
  deactivateBroker,
  reactivateBroker,
  viewBrokerStatement,
  resource

}) => {
  const [open, setOpen] = useState(true);


  const resourceAttributes = resource.data.attributes;
  const organisation = getIncludedResource(
    resource.data,
    resource.included,
    'organisation',
  );

  const account = getIncludedResource(
    organisation.data,
    resource.included,
    'account',
  );


  const openClose = () => {
    setOpen(!open);
  };



  const accountId = account?.data?.id;
  const organisationId = account?.data?.id;

  const {  isDeclining } = useSelector((state) => state.broker);

  const buttons = useMemo(() => getButtons(resource, accountId, organisationId, viewBrokerStatement, deactivateBroker, reactivateBroker, isDeclining), [resource, accountId,organisationId, viewBrokerStatement ,reactivateBroker,deactivateBroker ,isDeclining ]);

  const region = getIncludedResource(
    resource.data,
    resource.included,
    'broker_region',
  );
  const networks = getIncludedResource(
    resource.data,
    resource.included,
    'networks',
  );
  const users = getIncludedResource(
    resource.data,
    resource.included,
    'users',
  );
  const primaryContacts = getIncludedResource(
    resource.data,
   resource.included,
    'primary_contacts',
  );
  const accountsContacts = getIncludedResource(
    resource.data,
    resource.included,
    'accounts_contacts',
  );
  // const commissions = getIncludedResource(this.props.resource.data, this.props.resource.included, 'active_commissions')
  const address = displayAddress(resourceAttributes.address);
  const trackingLinks = resource.data?.relationships?.tracking_links?.meta ?? [];

  return (
    <>

      <Row>
        <Col sm={6}>
          <InfoLabel
            label="Organisation"
            value={organisation.data.attributes.name}
            labelSize={4}
          />
          <InfoLabel label="Trading Name" value={resourceAttributes.trading_name} labelSize={4}/>
          <InfoLabel label="Branch Name" value={resourceAttributes.branch_name} labelSize={4}/>
          <InfoLabel
            label="Region"
            value={region.data.attributes ? region.data.attributes.name : ''}
            labelSize={4}
          />
        </Col>

        <Col sm={6}>
          <InfoLabel label="Broker Type" value={resourceAttributes.broker_type} labelSize={4}/>
          <InfoLabel label="Reference" value={resourceAttributes.reference} labelSize={4}/>
          <InfoLabel
            label="Hold Client Money"
            value={resourceAttributes?.metadata?.hold_client_money?? ''}
            labelSize={4}
          />
          {/*<InfoLabel label="FCA Number"*/}
          {/*value={organisation.data.attributes.regulatory_authority_number}*/}
          {/*labelSize={4}/>*/}
          <InfoLabel label="Address" value={address} labelSize={4}/>
          <InfoLabel label="Phone Number" value={resourceAttributes.phone_number} labelSize={4}/>
          <InfoLabel label="Display Number" value={resourceAttributes?.metadata?.display_phone_number}
                     labelSize={4}/>
          <InfoLabel label="Own Tracking Url" labelSize={4} value={resourceAttributes.url}/>
        </Col>
      </Row>

      <div className="hr"/>

      <Row>
        <Col sm={12}>
          <Panel className="panel-tracking-links">
            <Panel.Heading>
              <Panel.Toggle componentClass="div" onClick={openClose}>
                <label>Tracking Links</label>
                <div className="pull-right">
                  <Icon name={!open ? 'minus' : 'plus'}/>
                </div>
              </Panel.Toggle>
            </Panel.Heading>
            <Panel.Collapse>
              <Panel.Body>
                {trackingLinks.map((link, key) => {
                  return (
                    <Row key={key}>
                      <Col sm={3}>
                        <label>{link.channel_name}</label>
                      </Col>
                      <Col sm={9}>{link.url}</Col>
                    </Row>
                  );
                })}
              </Panel.Body>
            </Panel.Collapse>
          </Panel>
        </Col>
      </Row>

      <div className="hr"/>

      <Row className="row">
        <Col sm={6}>
          {Array.isArray(networks) && (
            <InfoLabel
              label="Networks"
              value={networks.map((network, i) => {
                let detail = i ? ', ' : '';
                return detail + network.attributes.name;
              })}
              labelSize={4}
            />
          )}
          <InfoLabel
            label="Network Reference"
            value={resourceAttributes?.metadata?.network_reference ?? ''}
            labelSize={4}
          />
          <InfoLabel
            label="Other Networks"
            value={resourceAttributes?.metadata?.other_networks ?? ''}
            labelSize={4}
          />
        </Col>

        <Col sm={6}>
          <InfoLabel
            label="Status"
            value={displayName(BROKER_STATUSES[resourceAttributes?.status ?? 'applied'])}
            labelSize={4}
          />
          <InfoLabel
            label="Created At"
            date
            value={resourceAttributes.created_at}
            labelSize={4}
          />
          <InfoLabel
            label="Updated At"
            date
            value={resourceAttributes.updated_at}
            labelSize={4}
          />
          {/*<InfoLabel label="Last Meeting" date value={ get(resource, 'metadata.last_meeting', '') } labelSize={4}/>*/}
        </Col>
      </Row>

      <div className="hr"/>

      <Row>
        <Col sm={12}>
          <InfoLabel
            label="Users"
            labelSize={1}
            value={users.map((user) => {
              return (
                <div className="user-item" key={user.id}>
                  <Link to={'/admin/users/' + user.id} onlyActiveOnIndex>
                    {user.attributes.full_name}
                  </Link>
                </div>
              );
            })}
          />
        </Col>
      </Row>

      <div className="hr"/>

      <Row>
        <Col sm={6}>
          <InfoLabel
            label="Primary Contacts"
            labelSize={2}
            value={primaryContacts.map((contact) => {
              return (
                <div className="user-item">
                  <Link to={'/admin/users/' + contact.id} onlyActiveOnIndex>
                    {contact.attributes.full_name}
                  </Link>
                </div>
              );
            })}
          />
        </Col>
        <Col sm={6}>
          <InfoLabel
            label="Accounts Contacts"
            labelSize={2}
            value={accountsContacts.map((contact) => {
              return (
                <div className="user-item">
                  <Link to={'/admin/users/' + contact.id} onlyActiveOnIndex>
                    {contact.attributes.full_name}
                  </Link>
                </div>
              );
            })}
          />
        </Col>
      </Row>

      <div className="hr"/>

      <Row>
        <Col sm={12}>
          <InternalButtonBar buttons={buttons}/>
        </Col>
      </Row>
    </>
  );
};

export default connect(null, {
  deactivateBroker: deactivateBroker,
  reactivateBroker: reactivateBroker,
  viewBrokerStatement: viewStatement
})(BrokerDetails);

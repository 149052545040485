import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, ButtonBar, Icon } from '../../../../common/components';
import { Col, FormControl, FormGroup, InputGroup, Row } from 'react-bootstrap';
import { Field, Form, reduxForm } from 'redux-form';
import '../LoginForm/LoginForm.scss';
import { push } from 'react-router-redux';

const InputGroupAddon = InputGroup.Addon;

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Registered email address is required';
  }
  if (!values.password) {
    errors.password = 'A new password is required';
  } else if (values.password.length < 8) {
    errors.password = 'Password must be 8 characters or more';
  }
  if (!values.password_confirmation) {
    errors.password_confirmation = 'The password must be confirmed';
  }
  if (values.password !== values.password_confirmation) {
    errors.password_confirmation = 'Password does not match confirmation';
  }
  return errors;
};

class ResetPasswordForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
  };

  renderEmailField(field) {
    const { input, meta } = field;
    return (
      <FormGroup>
        <Col sm={12}>
          <InputGroup>
            <InputGroupAddon>
              <Icon name="envelope" />
            </InputGroupAddon>
            <FormControl {...input} type="email" placeholder="Email address" />
          </InputGroup>
          <div className="input-error pull-right">{meta.error}</div>
        </Col>
      </FormGroup>
    );
  }

  renderPasswordField(field) {
    const { input, meta, placeholder, icon } = field;
    return (
      <FormGroup>
        <Col sm={12}>
          <InputGroup>
            <InputGroupAddon>
              <Icon name={icon} />
            </InputGroupAddon>
            <FormControl {...input} type="password" placeholder={placeholder} />
          </InputGroup>
          <div className="input-error pull-right">{meta.error}</div>
        </Col>
      </FormGroup>
    );
  }

  render() {
    const { dispatch, error, handleSubmit, submitting } = this.props;

    if (error && error.includes('Reset token has expired')) {
      setTimeout(() => {
        dispatch(push('/password/remind'));
      }, 4000);
    }

    return (
      <div className="login-box">
        <div className="head">
          <h2>Reset your password</h2>
        </div>
        <Box>
          <Form onSubmit={handleSubmit} className="login-form">
            <Field id="email" name="email" component={this.renderEmailField} />
            <Field
              id="password"
              name="password"
              icon="lock"
              placeholder="New password"
              component={this.renderPasswordField}
            />
            <Field
              id="password"
              name="password_confirmation"
              icon="check"
              placeholder="Confirm new password"
              component={this.renderPasswordField}
            />
            <Row>
              <Col sm={12}>
                <ButtonBar>
                  <Button
                    type="submit"
                    bsStyle="primary"
                    className="pull-right"
                    disabled={submitting}
                  >
                    {submitting ? (
                      <i className="fa fa-cog fa-spin" />
                    ) : (
                      <i className="fa" />
                    )}{' '}
                    reset password
                  </Button>
                </ButtonBar>
              </Col>
            </Row>
          </Form>
        </Box>
        {error && (
          <Row>
            <Col sm={12}>
              <div className="invalid-error">
                <strong>{error}</strong>
              </div>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default reduxForm({
  form: 'reset_password',
  validate,
})(ResetPasswordForm);

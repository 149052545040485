import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { fetchReminders } from '../../../../dashboard/redux/dashboardActions';
import { hasPermission } from '../../../../auth/redux/authActions';
import { get, reduce } from 'lodash';
import './Notifications.scss';

class Notifications extends Component {
  componentWillMount() {
    this.props.dispatch(fetchReminders());
  }

  render() {
    const { dashboard } = this.props;
    const referrals = get(dashboard.stats, 'meta.referrals_requiring_approval', 0);
    const requiringApproval = get(dashboard.stats, 'meta.mtas_requiring_approval', 0);
    const renewalsAwait = get(dashboard.stats, 'meta.renewals_awaiting_conversion', 0);
    const brokersAwait = get(dashboard.stats, 'meta.broker_applications_awaiting_approval', 0);
    const usersAwait = get(dashboard.stats, 'meta.users_awaiting_activation', 0);
    const enquiries = get(dashboard.stats, 'meta.enquiries', 0);

    const arrayOfStats = [
      referrals,
      requiringApproval,
      renewalsAwait,
      brokersAwait,
      usersAwait,
    ];
    if (hasPermission('system.notes_view_internal')) {
      arrayOfStats.push(enquiries);
    }

    const sumOfStats = reduce(arrayOfStats, (sum, n) => sum + n, 0);

    return (
      <table className="table notifications-list-popup">
        <colgroup>
          <col width="67%"/>
          <col width="33%"/>
        </colgroup>
        <thead/>
        <tbody>
        {referrals !== 0 && (
          <tr>
            <td>
              You have&nbsp;<span className="text-secondary">{referrals}</span>
              &nbsp;referrals requiring approval
            </td>
            <td className="reminder-action">
              <Link to="/policies/referrals">View referrals</Link>
            </td>
          </tr>
        )}

        {requiringApproval !== 0 && (
          <tr>
            <td>
              You have&nbsp;
              <span className="text-secondary">{requiringApproval}</span>
              &nbsp;mid term adjustments requiring approval
            </td>
            <td className="reminder-action">
              <Link to="/policies/mid-term-adjustments">View MTAs</Link>
            </td>
          </tr>
        )}

        {renewalsAwait !== 0 && (
          <tr>
            <td>
              There are&nbsp;<span className="text-secondary">{renewalsAwait}</span>
              &nbsp;renewals awaiting conversion
            </td>
            <td className="reminder-action">
              <Link to="/policies/renewals">View renewals</Link>
            </td>
          </tr>
        )}

        {hasPermission('broker.approve') && brokersAwait !== 0 && (
          <tr>
            <td>
              You have&nbsp;<span className="text-secondary">{brokersAwait}</span>
              &nbsp;broker applications awaiting approval
            </td>
            <td className="reminder-action">
              <Link to="/admin/broker-applications">View Broker Applications</Link>
            </td>
          </tr>
        )}

        {hasPermission('user.edit') && usersAwait !== 0 && (
          <tr>
            <td>
              You have&nbsp;<span className="text-secondary">{usersAwait}</span>
              &nbsp;users yet to log in
            </td>
            <td className="reminder-action">
              <Link to="/admin/users?status=pending">View Users</Link>
            </td>
          </tr>
        )}

        {hasPermission('system.notes_view_internal') && enquiries !== 0 && (
          <tr>
            <td>
              You have&nbsp;<span className="text-secondary">{enquiries}</span>
              &nbsp;Enquiries yet to be responded to
            </td>
            <td className="reminder-action">
              <Link to="/admin/enquiries">View Enquiries</Link>
            </td>
          </tr>
        )}

        {sumOfStats === 0 && (
          <tr key={1}>
            <td>You have no notifications</td>
            <td/>
          </tr>
        )}
        </tbody>
      </table>
    );
  }
}

Notifications.propTypes = {
  dispatch: PropTypes.func.isRequired,
  dashboard: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    dashboard: state.dashboard,
  };
}

export default connect(mapStateToProps)(Notifications);

import React from 'react';
import { Button } from '../../../../common/components';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Col, Row } from 'react-bootstrap';
import {
  InternalButtonBar
} from '../../../layout/components/InternalButtonBar/InternalButtonBar';

const BrokerProducts = ({ resource }) => {

  const commissions = getIncludedResource(
    resource.data,
    resource.included,
    'active_commissions'
  );

  const brokerId = resource?.data?.id;
  return (
    <>
      <table className="table">
        <thead>
        <tr>
          <td>Product</td>
          <td>Active date</td>
          <td>Commission</td>
        </tr>
        </thead>
        <tbody>
        {commissions &&
          commissions.map((commission, i) => {
            const product = getIncludedResource(
              commission,
              resource.included,
              'product',
            );
            if (product.data.attributes) {
              return (
                <tr key={i}>
                  <td>{product.data.attributes.name}</td>
                  <td>
                    <FormattedDate value={commission.attributes.from_date}/>{' '}
                    <FormattedTime value={commission.attributes.from_date}/>
                  </td>
                  <td>{commission.attributes.rate + '%'}</td>
                </tr>
              );
            }
          })}
        {commissions.length === 0 && (
          <tr>
            <td colSpan={3}>No active products</td>
          </tr>
        )}
        </tbody>
      </table>

      <Row>
        <Col sm={12}>
          <InternalButtonBar
            buttons={[
              {
                permissions: ['user.edit', 'broker.edit'],
                button: (
                  <Button
                    link={true}
                    to={`/brokers/${brokerId}/commissions/edit`}
                    className="pull-right"
                    label="Edit"
                    bsStyle="primary"
                    rightIcon="percent"
                  />
                ),
              },
            ]}
          />
        </Col>
      </Row>
    </>
  );
};

export default BrokerProducts;

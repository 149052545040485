import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../../common/components';
import VeriskSession from '../VeriskSession/VeriskSession';
import { FormattedDate } from 'react-intl';
import './styles.scss';
import { get } from 'lodash';

export default class ScreeningSession extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    traveller: PropTypes.object.isRequired,
    screening: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    startScreening: PropTypes.func.isRequired,
    finishScreening: PropTypes.func.isRequired,
    changeConditionsCovered: PropTypes.func,
    conditionsCovered: PropTypes.bool,
    applicationId: PropTypes.string,
  };

  render() {
    const {
      index,
      traveller,
      screening,
      dispatch,
      startScreening,
      finishScreening,
      deleteConditions,
      applicationId,
    } = this.props;

    const screeningErrors = screening?.errors || null;
    const travellerFullName =
      traveller.title + ' ' + traveller.first_name + ' ' + traveller.last_name;
    const hasScreeningError = get(screeningErrors, travellerFullName, false);

    return (
      <div key={index} className={'traveller-row col-xs-12 traveller' + index}>
        {screening.token && screening.index === index ? (
          <div className="screening-row screening-session">
            {!traveller.first_name ? (
              <p>Screening for Adult {index}</p>
            ) : (
              <p>
                Screening for{' '}
                {traveller.title + ' ' + traveller.first_name + ' ' + traveller.last_name}
              </p>
            )}

            {screening.service === 'verisk' && (
              <VeriskSession
                index={index}
                screening={screening}
                dispatch={dispatch}
                finishScreening={finishScreening}
              />
            )}
          </div>
        ) : (
          <div className="screening-row">
            {(!screening.index || screening.index === index) && (
              <div>
                {traveller.screening_id && !hasScreeningError && (
                  <div>
                    <Button
                      className="pull-right button-margin-left"
                      bsStyle="primary"
                      label={'Edit Conditions'}
                      isLoading={screening.isSubmitting && screening.index === index}
                      disabled={screening.index !== 0 && screening.index !== index}
                      handleClick={startScreening}
                    />
                  </div>
                )}

                {!traveller.screening_id && !hasScreeningError && (
                  <div>
                    <Button
                      className="pull-right button-margin-left"
                      bsStyle="primary"
                      label={'Add conditions'}
                      isLoading={screening.isSubmitting && screening.index === index}
                      disabled={screening.index !== 0 && screening.index !== index}
                      handleClick={startScreening}
                    />
                  </div>
                )}

                {(traveller?.screening?.meta?.id && applicationId) && (
                  <div>
                    <Button
                      className="pull-right"
                      bsStyle="danger"
                      label={'Delete Conditions'}
                      isLoading={screening.isSubmitting && screening.index === index}
                      disabled={screening.index !== 0 && screening.index !== index}
                      handleClick={deleteConditions}
                    />
                  </div>
                )}
              </div>
            )}
            {!traveller.first_name ? (
              <h4>
                <strong>Adult {index}</strong>
              </h4>
            ) : (
              <div className="traveller-name-title">
                {hasScreeningError && (
                  <div style={{ color: 'red', fontSize: '12px' }}>
                    Traveller has screening errors please use 'Delete Conditions' button
                    and re-add the condition(s).
                  </div>
                )}
                {traveller.title + ' ' + traveller.first_name + ' ' + traveller.last_name}
                {traveller.dob && (
                  <span className="traveller-dob">
                    (<FormattedDate value={new Date(traveller.dob)} />)
                  </span>
                )}
              </div>
            )}
            <ul
              className={
                'declared-conditions-list' +
                (traveller.screening && traveller.screening.meta ? '' : ' empty')
              }
            >
              {traveller.screening && traveller.screening.meta ? (
                traveller.screening.meta.conditions.map((condition, i) => {
                  return (
                    <li key={i}>
                      <span>{condition}</span>
                    </li>
                  );
                })
              ) : (
                <li>
                  <span>No conditions declared</span>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

import React from 'react';
import { DataTable } from '../../../../common/components';
import { TableHeaderColumn } from 'react-bootstrap-table';
import CreateContractButton from '../CreateContractButton';
import {
  refreshData
} from '../../../../common/components/DataTable/redux/dataTableActions';
import { Col, Row } from 'react-bootstrap';
import {
  InternalButtonBar
} from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import { connect } from 'react-redux';
import formatDateTime from '../../../../helpers/formatDateTime';

const BrokerContracts = ({ refreshData, resource }) => {

  const handleRowClick = (row) => {
    if (window) {
      window.open(row.file_path, '_blank');
    }

    return false;
  };

  const refreshList = () => {
    refreshData('contracts');
  };

  const brokerId = resource.data.id;

  return (
    <>
      <DataTable
        source={'/brokers/contracts'}
        name="contracts"
        showTotal
        autoFilter={brokerId}
        autoFilterType="filter[broker]"
        onRowSelect={handleRowClick}
      >
        <TableHeaderColumn dataField="id" isKey hidden/>
        <TableHeaderColumn
          dataField="created_at"
          dataFormat={formatDateTime}
          width={'180px'}
          dataSort
        >
          Created
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="agreed_at"
          dataFormat={formatDateTime}
          width={'180px'}
          dataSort
        >
          Agreed
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="ended_at"
          dataFormat={formatDateTime}
          width={'180px'}
          dataSort
        >
          Ended
        </TableHeaderColumn>
        <TableHeaderColumn dataField="status" dataSort width={'180px'}>
          Status
        </TableHeaderColumn>
      </DataTable>
      <Row>
        <Col sm={12}>
          <InternalButtonBar
            buttons={[
              {
                permissions: ['broker.edit'],
                button: (
                  <CreateContractButton
                    broker={resource.data}
                    callback={refreshList}
                  />
                ),
              },
            ]}
          />
        </Col>
      </Row>
    </>
  );

};

export default connect(
  null,
  {
    refreshData: refreshData
  }
)(BrokerContracts);

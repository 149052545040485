import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, DataTable } from '../../../../common/components';
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { ControlLabel, FormControl } from 'react-bootstrap';
import { push } from 'react-router-redux';
import { FormattedDate, FormattedTime } from 'react-intl';

export default class ContentAnnouncementList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    content: PropTypes.object.isRequired,
  };

  formatDate(cell) {
    return (
      <span>
        <FormattedDate value={new Date(cell)} /> <FormattedTime value={new Date(cell)} />
      </span>
    );
  }

  handleRowClick(row) {
    this.props.dispatch(push('/admin/content/' + row.id));
  }

  handleFilterUpdate(event) {
    this.props.dispatch(
      applyFilter('announcements', 'filter[template.type]', event.target.value),
    );
  }

  handleSearchUpdate(event) {
    this.props.dispatch(applyFilter('announcements', 'filter[name]', event.target.value));
  }

  render() {
    return (
      <div>
        <Box>
          <div className="row">
            <div className="col-sm-5">
              <ControlLabel className="filter-label">Search</ControlLabel>
              <FormControl onChange={::this.handleSearchUpdate} />
            </div>
          </div>

          <DataTable
            source="/contents"
            name="announcements"
            autoFilters={[{ type: 'filter[template.type]', value: 'announcement' }]}
            showTotal
            onRowSelect={this.handleRowClick.bind(this)}
          >
            <TableHeaderColumn dataField="id" isKey hidden />
            <TableHeaderColumn dataField="name" dataSort>
              Name
            </TableHeaderColumn>
            <TableHeaderColumn dataField="content_type" dataSort width={'180px'}>
              Type
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="created_at"
              dataSort
              dataFormat={this.formatDate}
              width={'180px'}
            >
              Created
            </TableHeaderColumn>
          </DataTable>

          <div className="row actions">
            <Button
              link
              to="/admin/content/new?template=announcement"
              className="pull-right"
              bsStyle="primary"
              label="New Announcement"
            />
          </div>
        </Box>
      </div>
    );
  }
}

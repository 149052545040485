import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, FieldArray, FormSection, getFormValues, reduxForm } from 'redux-form';
import { InfoButton, } from '../../../../../../common/components';
import SelectField from '../../../../../../common/components/ReduxFormField/SelectField';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';
import TravellerFieldArray from '../TravellerFieldArray/TravellerFieldArray';
import { required } from 'redux-form-validators';

export const COL_WIDTHS = {
  labels: { sm: 2, md: 2, lg: 3 },
  fields: { sm: 10, md: 10, lg: 8 },
};
const leadTraveller = {
  title: '',
  first_name: '',
  last_name: '',
  dob: '',
  email: '',
  phone1: '',
  phone2: '',
  conditions_covered: true,
  screening: '',
  metadata: {
    doctor_form_info: '',
  },
  screening_id: '',
  address: {
    line1: '',
    line2: '',
    line3: '',
    town: '',
    county: '',
    postcode: '',
  },
};

const TravellersTab = ({
  dispatch,
  change,
  formValues,
  travellerGroupOptions,
  definitions,
  ageLimits
}) => {
  const travellerGroup = formValues?.data.attributes.metadata.traveller_group;
  const adults = formValues?.data?.attributes?.metadata?.adults ?? [];

  const children = formValues?.data?.attributes?.metadata?.children ?? [];


  useEffect(() => {
    if (travellerGroup === 'independent_child') {
      dispatch(change('data.attributes.metadata.adults', []));
      if (!children?.length) {
        dispatch(change('data.attributes.metadata.children', [leadTraveller]));
      }
    }
    if (travellerGroup === 'couple') {
      dispatch(change('data.attributes.metadata.children', []));
    }
    if (travellerGroup !== 'independent_child' && adults?.length === 0) {
      dispatch(change('data.attributes.metadata.adults', [leadTraveller]));
    }

  }, [travellerGroup]);

  return (
    <div>
      <FormSection name={'data.attributes.metadata'}>

        <FormGroup>
          <Col sm={COL_WIDTHS.labels.sm} md={COL_WIDTHS.labels.md}
               lg={COL_WIDTHS.labels.lg} componentClass={ControlLabel}>
            <InfoButton content={definitions.group_type}/> Group Type
          </Col>
          <Col sm={COL_WIDTHS.fields.sm} md={COL_WIDTHS.fields.md}
               lg={COL_WIDTHS.fields.lg}>
            <Field
              name="traveller_group"
              id="traveller_group"
              component={SelectField}
              options={travellerGroupOptions || []}
              validate={required({ msg: 'Group type is required' })}
            />
          </Col>
        </FormGroup>

        <div className="hr"/>

        <FieldArray
          name={'adults'}
          travellerType="adult"
          component={TravellerFieldArray}

          travellerGroup={travellerGroup}
          travellers={adults}
          ageLimits={ageLimits}
        />
        <FieldArray
          name="children"
          component={TravellerFieldArray}
          isChild
          travellerGroup={travellerGroup}
          travellerType="children"
          ageLimits={ageLimits}
          travellers={children}
        />
      </FormSection>

    </div>
  );

};


const FORM_NAME = 'WhitelabelTravelForm';
export default connect(
  (state) => ({
    //  initialValues: ownProps.initialValues,
    formValues: getFormValues(FORM_NAME)(state),
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })
)(reduxForm({ form: FORM_NAME })(TravellersTab));

import React from 'react';
import { DataTable } from '../../../../common/components';
import { TableHeaderColumn } from 'react-bootstrap-table';

import formatDateTime from '../../../../helpers/formatDateTime';

const BrokerAudit = ({ brokerId }) => {
  return (
    <>
      <DataTable
        source={'/events'}
        autoFilters={[
          { type: 'filter[resource]', value: brokerId },
          { type: 'related', value: true },
        ]}
        name="audit"
        showTotal
      >
        <TableHeaderColumn dataField="id" isKey hidden/>
        <TableHeaderColumn
          dataField="occurredAt"
          dataFormat={formatDateTime}
          width={'180px'}
          dataSort
        >
          Occurred&nbsp;at
        </TableHeaderColumn>

        <TableHeaderColumn dataField="action" width={'200px'} dataSort>
          Action
        </TableHeaderColumn>

        <TableHeaderColumn dataField="description" width={'500px'} dataSort>
          Description
        </TableHeaderColumn>

        <TableHeaderColumn dataField="user_name">User</TableHeaderColumn>
      </DataTable>
    </>
  );
};

export default BrokerAudit;

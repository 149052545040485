import React, { Component } from 'react';
import { Box, DataTable } from '../../../../common/components';
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { Button } from '../../../../common/components';
import { FormControl, ControlLabel, Row, Col } from 'react-bootstrap';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import { push } from 'react-router-redux';
import { FormattedDate, FormattedTime } from 'react-intl';
import { reduxForm } from 'redux-form';
import validate from '../../../dashboard/containers/validate';
import DatePickerDobMasked from '../../../../common/components/DatePicker/DatePickerDobMasked';
import { connect } from 'react-redux';
import moment from 'moment/moment';

class CustomerList extends Component {
  formatDate(cell) {
    return (
      <span>
        <FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/>
      </span>
    );
  }

  formatAddress(cell, row) {
    return row.address.postcode;
  }

  handleRowClick(row) {
    this.props.dispatch(push('/customers/' + row.id));
  }

  handleFilterNameUpdate(event) {
    this.props.dispatch(applyFilter('customers', 'filter[name]', event.target.value));
  }

  handleFilterEmailUpdate(event) {
    this.props.dispatch(applyFilter('customers', 'filter[email]', event.target.value));
  }

  handleFilterPostcodeUpdate(event) {
    this.props.dispatch(applyFilter('customers', 'filter[address.postcode]', event.target.value));
  }

  handleFilterDOBUpdate(event) {
    const dateOfBirth = moment(event.target.value, 'DD/MM/YYYY').utcOffset(0, true);
    const dob = dateOfBirth.isValid() ? dateOfBirth.format() : '';

    this.props.dispatch(applyFilter('customers', 'filter[dob]', dob));
  }

  render() {
    const { showCreateCustomer = true, handleRowClick } = this.props;

    return (
      <div>
        <Box>
          <Row>
            <Col sm={4}>
              <ControlLabel className="filter-label">Name</ControlLabel>
              <FormControl onChange={::this.handleFilterNameUpdate}/>
            </Col>
            <Col sm={4}>
              <ControlLabel className="filter-label">Email</ControlLabel>
              <FormControl onChange={::this.handleFilterEmailUpdate}/>
            </Col>
            <Col sm={2}>
              <ControlLabel className="filter-label">Postcode</ControlLabel>
              <FormControl onChange={::this.handleFilterPostcodeUpdate}/>
            </Col>
            <Col sm={2}>
              <ControlLabel className="filter-label">DOB</ControlLabel>
              <DatePickerDobMasked
                input={{
                  onChange: (value) => this.handleFilterDOBUpdate(value)
                }}
              />
            </Col>
          </Row>
        </Box>

        <Box>
          <div className="row">
            <div className="col-xs-12">
              <div className="form-horizontal">
                <h3>Customers</h3>
                <DataTable
                  source="/customers"
                  name="customers"
                  showTotal
                  onRowSelect={handleRowClick || this.handleRowClick.bind(this)}
                >
                  <TableHeaderColumn dataField="id" isKey hidden />
                  <TableHeaderColumn
                    headerAlign="left"
                    dataAlign="left"
                    dataField="customer_name"
                    width={'200px'}
                  >
                    Name
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    headerAlign="left"
                    dataAlign="left"
                    dataField="email"
                    width={'200px'}>
                    Email
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    headerAlign="left"
                    dataAlign="left"
                    dataField="address"
                    width={'100px'}
                    dataSort
                    dataFormat={this.formatAddress}
                  >
                    Postcode
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    headerAlign="left"
                    dataAlign="left"
                    dataField="created_at"
                    width={'100px'}
                    dataFormat={this.formatDate}
                    dataSort
                  >
                    Customer Since
                  </TableHeaderColumn>
                </DataTable>
                {showCreateCustomer && (
                  <Row>
                    <Col sm={12}>
                      <InternalButtonBar buttons={[
                        {
                          permissions: ['customer.create'],
                          button: <Button
                            link
                            to="/customers/new"
                            className="pull-right"
                            bsStyle="primary"
                            label="new customer"
                          />,
                        },
                      ]} />
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </div>
        </Box>
      </div>
  );
  }
  }

  const FORM_NAME = 'CustomerList';
const form = reduxForm({ form: FORM_NAME, validate })(CustomerList);

export default connect()(form);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DataTable, Modal, Loader } from '../../../../common/components';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { FormattedDate, FormattedTime } from 'react-intl';
import { UnmatchBatchButton } from '../UnmatchBatchButton';
import { closeModal } from '../../../../common/components/Modal/ModalRedux';
import { refreshData } from '../../../../common/components/DataTable/redux/dataTableActions';
import { get } from 'lodash';

export default class LedgerEntriesModal extends Component {
  static MODAL_IDENTIFIER = 'ledger-entries-modal';

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    journal: PropTypes.object,
    batch: PropTypes.object,
    ledgerEntry: PropTypes.object,
    callback: PropTypes.func,
  };

  formatDate(cell) {
    return (
      <span>
        <FormattedDate value={new Date(cell)} /> <FormattedTime value={new Date(cell)} />
      </span>
    );
  }

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded, callback } = this.props;
    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('');
      }
      dispatch(closeModal(LedgerEntriesModal.MODAL_IDENTIFIER));
    }
  }

  render() {
    const { dispatch, journal, batch, ledgerEntry, unMatchCallback } = this.props;

    let resourceType = null;
    let resourceId = null;
    let filter = null;
    let modalFooter = <div>&nbsp;</div>;

    if (get(journal, 'id')) {
      resourceType = 'Journal';
      resourceId = journal.id;
      filter = 'filter[journal]';
    } else if (get(ledgerEntry, 'id')) {
      resourceType = 'Journal';
      resourceId = ledgerEntry.id;
      filter = 'filter[journal.ledger_entry]';
    } else if (get(batch, 'id')) {
      resourceType = 'Batch';
      resourceId = batch.id;
      filter = 'filter[batch]';
      modalFooter = (
        <UnmatchBatchButton
          batch={batch}
          callback={() => dispatch(unMatchCallback || refreshData('ledger-entries'))}
        />
      );
    }

    return (
      <Modal
        name={LedgerEntriesModal.MODAL_IDENTIFIER}
        title={resourceType}
        close
        footer={modalFooter}
        bsSize={'large'}
      >
        {!resourceId ? (
          <Loader noShadow />
        ) : (
          <DataTable
            source={'/accounts/ledger-entries'}
            autoFilters={[
              { type: filter, value: resourceId },
              { type: 'page[limit]', value: 100 },
            ]}
            name="ledger-entries"
            showTotal
          >
            <TableHeaderColumn dataField="id" isKey hidden />

            <TableHeaderColumn
              dataField="created_at"
              dataSort
              width={'75px'}
              dataFormat={::this.formatDate}
            >
              Transacted at
            </TableHeaderColumn>

            <TableHeaderColumn dataField="account_name" width={'150px'}>
              Account
            </TableHeaderColumn>

            <TableHeaderColumn dataField="description" width={'200px'} dataSort>
              Description
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="formatted_amount"
              width={'75px'}
              dataAlign="right"
            >
              Amount
            </TableHeaderColumn>
          </DataTable>
        )}
      </Modal>
    );
  }
}

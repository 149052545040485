import React from 'react';
import pluralize from 'pluralize';

const SupersedingDocumentsCount = ({
  supersedingDocumentCount,
  onOpenSupersedingModal,
}) => {
  if (supersedingDocumentCount > 0) {
    return (
      <div className="show-documents" onClick={onOpenSupersedingModal}>
        <span>
          {supersedingDocumentCount} Superseding {pluralize('Document', supersedingDocumentCount)}

        </span>
      </div>
    );
  }
  return (
    <div className="no-documents-alert">
      <span>No superseding documents to show...</span>
    </div>
  );
};

export default SupersedingDocumentsCount;

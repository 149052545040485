import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SimpleDatePicker from '../SimpleDatePicker/SimpleDatePicker';
import moment from 'moment';
import 'moment/locale/en-gb';
import './styles.scss';

class DatePicker extends Component {
  static propTypes = {
    label: PropTypes.string,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object,
    fieldSize: PropTypes.string,
    labelSize: PropTypes.number,
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    minDateToday: PropTypes.bool,
    showYear: PropTypes.bool,
  };

  onChange(date) {
    if (this.props.input && this.props.input.onChange) {
      this.props.input.onChange(date);
    }
  }

  render() {
    const {
      input,
      shouldCloseOnSelect,
      dateFormat,
      meta,
      minDate,
      maxDate,
      minDateToday,
      showYear,
      onSelect,
      showTime,
    } = this.props;
    const selected = input.value ? input.value : null;
    const error = meta && meta.error;
    const touched = meta && meta.touched;

    return (
      <div className={(touched && error) ? 'has-error' : ''}>
        <SimpleDatePicker
          monthsShown={1}
          minDate={minDateToday ? moment().format() : minDate}
          maxDate={maxDate}
          showYear={showYear}
          showTime={showTime}
          selected={selected}
          dateFormat={dateFormat}
          onChange={::this.onChange}
          onSelect={onSelect}
          shouldCloseOnSelect={shouldCloseOnSelect}
        />
        {meta && meta.touched && meta.error && (
          <div>
            <span className="input-error">{meta.error}</span>
          </div>
        )}
      </div>
    );
  }
}

export default DatePicker;

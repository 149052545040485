import React from 'react';
import { HorizontalFormControl, InfoLabel } from '../../../../../common/components';
import { Field } from 'redux-form';

const CoverLevelInfoLabel = ({ label, input, labelSize }) => {
  return <InfoLabel label={label} labelSize={labelSize} value={input.value}/>;
};

const BenefitTextField = ({ input, isViewOnly, label, labelSize, coverLevels }) => {


  if (coverLevels) {

    return (
      <>
        {coverLevels.map((coverLevel) => (
          <>
            <Field
              label={`${coverLevel} Text:`}
              labelSize={labelSize}
              name={`${input.name}.cover_level.${coverLevel}`}
              component={isViewOnly ? CoverLevelInfoLabel : HorizontalFormControl}
            />
          </>
        ))}
      </>
    );
  }
  return (
    <Field
      label={label}
      labelSize={labelSize}
      name={`${input.name}.text`}
      component={isViewOnly ? InfoLabel : HorizontalFormControl}
    />
  );
};
export default BenefitTextField;

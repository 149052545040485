import { map } from 'lodash';

export default function mapReactSelect(options) {
  return map(options, (name, key) => {
    return {
      label: name,
      value: key,
    };
  });
}

import React, { useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import DocumentListByType from '../DocumentListByType/DocumentListByType';
import { connect, useSelector } from 'react-redux';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import NewProductDocumentModal from '../NewProductDocumentModal/NewProductDocumentModal';
import { schemesForCheckbox } from '../../helpers/schemesForCheckbox';
import { actions as documentActions } from '../../redux/documentManagementActions';
import { ButtonBar } from '../../../../common/components';
import ViewDocumentModal from '../ViewDocumentModal/ViewDocumentModal';
import EditDocumentModal from '../EditDocumentModal/EditDocumentModal';
import NewDocumentModal from '../NewDocumentModal/NewDocumentModal';

const DISPLAY_DOCS = [
  'Covid Statement',
  'Policy Wording',
  'Insurance Product Information Document',
  'Terms of Business Customer Agreement',
];

const getSchemesByInsurer = (product) => {
  const allSchemes = getIncludedResource(product.data, product.included, 'all_schemes');
  if (!Array.isArray(allSchemes)) {
    return {};
  }

  return allSchemes.reduce((carry, data) => {
    if (data.attributes.status === 'ended') {
      return carry;
    }
    const insurerId = data?.relationships?.insurer?.data?.id;
    if (!insurerId) {
      return carry;
    }

    const insurer = getIncludedResource(data, product.included, 'insurer');
    if (carry[insurerId]) {
      carry[insurerId]['schemes'].push(data);
    } else {
      carry[insurerId] = {
        schemes: [data],
        insurer: insurer.data,
      };
    }
    return carry;

  }, {});
};

const getDocumentTypeName = (name) => {
  switch (name) {
    case 'Information':
      return 'Complaints Procedure';
    default:
      return name;
  }
};

const DocumentList = ({
  productId,
  product,
  onAddDocument,
  onEditDocument,
  onSaveDocument,
  summary,
  documentTypes,
  onGetDocumentsByType

}) => {
  const [activeDocumentTypeId, setActiveDocumentTypeId] = useState();

  const schemes = useMemo(() => schemesForCheckbox(getSchemesByInsurer(product)), [
    product,
  ]);

  const {
    showViewDocumentModal,
    activeViewDocument,
    activeEditDocument,
    showEditDocumentModal,
    documentsGenerated,
    isRetrievingDocuments,
    actionType
  } = useSelector((state) => state.documentManagement);

  const documentTypesList =
    documentTypes?.filter((document) =>
      DISPLAY_DOCS.includes(document.attributes.name),
    ) ?? [];

  const handleShowDocumentsByType = (documentTypeId) => {
    setActiveDocumentTypeId(documentTypeId);
  };

  const save = (values) => {
    return Promise.resolve(onSaveDocument(values).then(() => {
      onGetDocumentsByType(productId, activeDocumentTypeId);
    }));
  };

  const add = (values) => {
    return Promise.resolve(onAddDocument(values).then(() => {
      onGetDocumentsByType(productId, activeDocumentTypeId);
    }));
  };

  return (
    <div>
      <Row>
        <Col sm={12}>
          {documentTypesList?.map((documentType) => {
            return (
              <div key={documentType.id}>
                <DocumentListByType
                  productId={productId}
                  summary={summary?.[activeDocumentTypeId]}
                  name={getDocumentTypeName(documentType.attributes.name)}
                  isActive={documentType.id === activeDocumentTypeId}
                  documentTypeId={documentType.id}
                  isRetrievingDocuments={isRetrievingDocuments}
                  onShowDocumentByType={handleShowDocumentsByType}
                  onGetDocumentsByType={onGetDocumentsByType}
                  documentsGenerated={documentsGenerated}
                />
              </div>
            );
          })}
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <ButtonBar>
            <NewDocumentModal
              schemes={schemes}
              onAddDocument={add}
              documentTypesList={documentTypesList}
            />
            <NewProductDocumentModal
              productId={productId}
              onAddDocument={add}
              documentTypesList={documentTypesList}
            />
            {showViewDocumentModal && (
              <ViewDocumentModal
                document={activeViewDocument}
                showDocumentModal={showViewDocumentModal}
              />
            )}

            {showEditDocumentModal && (
              <EditDocumentModal
                document={activeEditDocument}
                show={showEditDocumentModal && activeDocumentTypeId}
                schemes={schemes}
                actionType={actionType}
                onEditDocument={onEditDocument}
                onSaveDocument={save}
                onAddDocument={add}
              />
            )}
          </ButtonBar>
        </Col>
      </Row>
    </div>
  );
};

export default connect(
  null,
  {
    onGetDocumentsByType: documentActions.getDocumentsByType
    // getDocument: documentActions.getDocument
  },
)(DocumentList);

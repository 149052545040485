import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { MenuItem, Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import CreateNoteForm from './CreateNoteForm';
import {
  createNote
} from '../../../../../common/components/CreateNoteButton/noteActions';
import { clearFiles } from '../../../../../common/components/Upload/UploadRedux';
import { Icon } from '../../../../../common/components';
import { withRouter } from 'react-router';


const CreateNoteMenuItem = ({
  onClearFiles,
  onCreateNote,
  notableResourceType,
  resourceId,
  menuLabel,
  modalLabel,
  location
}) => {
  const [open, setOpen] = useState(false);
  const { files } = useSelector((state) => state.upload);

  const currentLocation = location.pathname;
  const closeModal = () => {
    onClearFiles();
    setOpen(false);
  };

  const save = (values) => {

    const attachments = files.length ? files.map((file) => ({
      id: file.id,
      type: file.type,
    })) : [];

    const saveValues = {
      data: {
        attributes: {
          ...values.data.attributes
        },
        relationships: {
          ...values.data.relationships,
          attachments: {
            data: attachments
          }
        }
      },
      meta: {
        ...values.meta
      }
    };

    return Promise.resolve(onCreateNote(saveValues, { id: resourceId }, notableResourceType)).then(() => {
      closeModal();
    });
  };

  const handleOpen = () => {
    onClearFiles();
    setOpen(true);
  };


  return (
    <>
      <MenuItem eventKey="create-note" onSelect={handleOpen}>
        <Icon name="note-sticky"/> {menuLabel}
      </MenuItem>
      <Modal
        show={open}
        onHide={closeModal}
      >
        <ModalHeader
          closeButton
        >
          {modalLabel}

        </ModalHeader>
        <ModalBody>
          <CreateNoteForm
            currentLocation={currentLocation}
            onSave={save}
            files={files}
          />
        </ModalBody>

      </Modal>
    </>
  );
};


export default connect(
  null,
  {
    onClearFiles: clearFiles,
    onCreateNote: createNote,
  }
)(withRouter(CreateNoteMenuItem));

import React, { Component } from 'react';
import { isNil } from 'lodash';
import { connect } from 'react-redux';
import { Tabs, Tab, Button } from 'react-bootstrap';
import InternalContainer from '../../layout/containers/Internal';
import PrintList from '../components/PrintList';
import { fetchDashboard, createPrintJob } from '../redux/printJobsActions';
import { Icon } from '../../../common/components';
import './styles.scss';

class PrintJobsListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
    };
    this.createPrintJob = this.createPrintJob.bind(this);
    this.handleTab = this.handleTab.bind(this);
  }

  componentWillMount() {
    this.props.fetchDashboard();
  }

  createPrintJob(type) {
    const { stats, isLoading } = this.props;
    if (stats[type] === 0 || isLoading || isNil(stats[type])) {
      return;
    }
    this.props.createPrintJob(type);
  }

  handleTab(key) {
    this.setState(() => ({ activeTab: key }));
  }

  render() {
    const { stats, isLoading } = this.props;

    const loadItem = (item) => {
      if (isLoading) {
        return <Icon spin name="spinner" />;
      } else if (stats) {
        return stats[item];
      }
    };

    // Check if need to disable the cursor
    const validation = (type) => stats[type] === 0 || isLoading || isNil(stats[type]);
    const classname = (type) => [
      'dashboard__card',
      validation(type) && 'dashboard__card--cursor-off',
    ];

    return (
      <InternalContainer title="Print Jobs">
        <Button
          bsStyle="link"
          className="printjob__stats-refresh"
          onClick={this.props.fetchDashboard}
        >
          <Icon name="refresh fa-fw" spin={isLoading} />
        </Button>

        <div className="dashboard">
          <div className="row">
            <div className="col-md-4">
              <div
                className={classname('policy').join(' ')}
                onClick={() => this.createPrintJob('policy')}
              >
                <div className="dashboard__card--hover-effect">
                  Policies
                  <span className="dashboard__card--item"> {loadItem('policy')}</span>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={classname('quote').join(' ')}
                onClick={() => this.createPrintJob('quote')}
              >
                <div className="dashboard__card--hover-effect">
                  Quotes
                  <span className="dashboard__card--item">{loadItem('quote')}</span>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className={classname('renewal').join(' ') + ' dashboard__card--no-border'}
                onClick={() => this.createPrintJob('renewal')}
              >
                <div className="dashboard__card--hover-effect">
                  Renewals
                  <span className="dashboard__card--item">{loadItem('renewal')}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Tabs
          activeKey={this.state.activeTab}
          id="content-tabs"
          unmountOnExit
          onSelect={this.handleTab}
        >
          <Tab eventKey={1} title="Print Jobs List">
            <PrintList {...this.props} />
          </Tab>
        </Tabs>
      </InternalContainer>
    );
  }
}

const mapDispatch = (dispatch) => {
  return {
    fetchDashboard: (state) => dispatch(fetchDashboard(state)),
    createPrintJob: (type) => dispatch(createPrintJob(type)),
  };
};

function mapStateToProps(state) {
  return {
    content: state.content,
    stats: state.printJobs.stats,
    isLoading: state.printJobs.isLoading,
  };
}

export default connect(
  mapStateToProps,
  mapDispatch,
)(PrintJobsListContainer);

export const STORE_SEARCH = 'socrates-online/sidebar/STORE_SEARCH';
export const STORE_CLEAR_SEARCH = 'socrates-online/sidebar/STORE_CLEAR_SEARCH';

const initialState = {
  storeCustomer: '',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case STORE_SEARCH:
      return {
        ...state,
        storeCustomer: action.storeCustomer,
      };
    case STORE_CLEAR_SEARCH:
      return {
        ...state,
        storeCustomer: initialState,
      };
    default:
      return state;
  }
}

export function storeCustomer(storeCustomer) {
  return (dispatch) => {
    dispatch({
      type: STORE_SEARCH,
      storeCustomer: storeCustomer,
    });
  };
}

export function clearCustomer(storeCustomer) {
  return (dispatch) => {
    dispatch({
      type: STORE_CLEAR_SEARCH,
      storeCustomer: storeCustomer,
    });
  };
}

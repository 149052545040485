import {  format, parseISO } from 'date-fns';

const getFilterString = (accountId, brokerId, filters) => {

  const defaultFilter =  [
  ];
  if (brokerId) {
    defaultFilter.push(
      `filter[broker]=${brokerId}`
    );
  }

  const filterKeys = Object.keys(filters);

  const query = filterKeys?.reduce((carry, filterKey) => {
    const value = filters[filterKey];
    if (!value) {
      return carry;
    }

    if (filterKey.includes('date')) {
      const dateString = format(parseISO(value),'yyyy-MM-dd');
      carry.push(`filter[${filterKey}]=${dateString}`);
    } else if (filterKey.includes('amount')){
      const amount = parseFloat(value);
      carry.push(`filter[${filterKey}]=${amount}`);
    } else {
      carry.push(`filter[${filterKey}]=${value}`);
    }

    return carry;

  }, defaultFilter);

  return query?.join('&') ?? '';
};

export const getFilterQuery = ( accountId, brokerId, transactionFilters, premiumFilters ) => {
  if (!accountId) {
    return;
  }
  const premiumFilter = getFilterString(accountId, brokerId, premiumFilters);
  const transactionFilter = getFilterString(accountId, brokerId,  transactionFilters);

  return {
     premiumFilter,
    transactionFilter
  };
  // if (premiumFilterUrl !== premiumFilter || matchingFilters.premium.type !== premiumType) {
  //   actions.cachePremiums([]);
  //   actions.getPremiums(matchingFilters.accountId, matchingFilters.premium.type, 'sort=created_at' + premiumFilterUrl);
  //   this.setState({
  //     premiumType: matchingFilters.premium.type,
  //     premiumFilter: premiumFilterUrl,
  //   });
  // }

  // Transactions
  // if (matchingFilters.transaction.dateFrom) {
  //   transactionFilters['date:from'] = moment(matchingFilters.transaction.dateFrom).format('YYYY-MM-DD');
  // }
  //
  // if (matchingFilters.transaction.dateTo) {
  //   transactionFilters['date:to'] = moment(matchingFilters.transaction.dateTo).format('YYYY-MM-DD');
  // }
  // if (matchingFilters.transaction.amountFrom !== '') {
  //   transactionFilters['amount:from'] = parseFloat(matchingFilters.transaction.amountFrom) * -1;
  // }
  //
  // if (matchingFilters.transaction.amountTo !== '') {
  //   transactionFilters['amount:to'] = parseFloat(matchingFilters.transaction.amountTo) * -1;
  // }

  // let transactionFilterUrl = '';
  // if (transactionFilters) {
  //   forEach(transactionFilters, (filter, key) => {
  //     transactionFilterUrl += `&filter[${key}]=${filter}`;
  //   });
  // }
  //
  // if (transactionFilterUrl !== transactionFilter) {
  //   actions.cacheTransactions([]);
  //   actions.getTransactions(matchingFilters.accountId, 'sort=created_at' + transactionFilterUrl);
  //   this.setState({ transactionFilter: transactionFilterUrl });
  // }
};

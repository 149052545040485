import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get, isEmpty, filter } from 'lodash';
import { required } from 'redux-form-validators';
import { CheckboxUnlabeled } from '../../../../common/components/ReduxFormField';
import InternalContainer from '../../../layout/containers/Internal';
import { Field, FieldArray, formValueSelector, reduxForm } from 'redux-form';
import { Row, Col, ControlLabel, FormGroup, Form } from 'react-bootstrap';
import 'moment/locale/en-gb';
import UploadCropComponent from '../../../../common/components/Upload/UploadCrop';
import { triggerFileUpload } from '../../../../common/components/Upload/UploadRedux';
import {
  Box,
  Button,
  DatePickerDob,
  HorizontalFormControl,
  SelectChannel,
  SelectProduct,
} from '../../../../common/components';
import SelectField from '../../../../common/components/ReduxFormField/SelectField';
import Select from 'react-select';
import SelectBrokerFields from '../SelectBrokerCampaigns/SelectBrokerFields';
import './styles.scss';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';

const templateDefaults = {
  id: null,
  type: 'templates',
  attributes: {
    name: '',
    slug: '',
    sections: {},
  },
};

class CampaignNewForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      template: templateDefaults,
      destinationOptions: [],
      familyOptions: [],
      coverLevelsOptions: [],
      valueRegions: [],
      valueTravellerGroup: [],
      valueCoverLevels: [], // cover Level
      allAges: false,
      allScores: false,
      allDurations: false,
      banner: false,
    };
    this.allAges = this.allAges.bind(this);
    this.allScores = this.allScores.bind(this);
    this.renderField = this.renderField.bind(this);
    this.allDurations = this.allDurations.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.coverSelection = this.coverSelection.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleUploadComplete = this.handleUploadComplete.bind(this);
    this.destinationSelection = this.destinationSelection.bind(this);
    this.familyGroupsSelection = this.familyGroupsSelection.bind(this);
    this.handleSelectCoverLevels = this.handleSelectCoverLevels.bind(this);
    this.handleSelectChangeDestinations = this.handleSelectChangeDestinations.bind(this);
  }

  componentWillMount() {
    const getFamilyGroup = [
      { label: 'Individual', value: 'individual' },
      { label: 'Couple', value: 'couple' },
      { label: 'Family', value: 'family' },
      { label: 'Single Parent Family', value: 'single_parent_family' },
    ];

    const getDestinations = [
      { label: 'United Kingdom & Isle Of Man', value: 'uk' },
      { label: 'Europe Area 1', value: 'europe_excluding' },
      { label: 'Europe Area 2', value: 'europe' },
      { label: 'Australia & New Zealand', value: 'australia_new_zealand' },
      { label: 'Worldwide Exc USA/Canada/Caribbean', value: 'worldwide_excluding' },
      { label: 'Worldwide Inc USA/Canada/Caribbean', value: 'worldwide' },
    ];

    this.setState({
      familyOptions: getFamilyGroup,
      destinationOptions: getDestinations,
    });
  }

  componentWillReceiveProps(nextProps) {
    const coverLevelNextProps = get(
      nextProps.formValues,
      'data.attributes.cover_level.equal',
    );
    const coverLevelOldProps = get(
      this.props.formValues,
      'data.attributes.cover_level.equal',
    );

    const getDestinationsSingle = [
      { label: 'UK', value: 'uk' },
      { label: 'Europe Excluding', value: 'europe_excluding' },
      { label: 'Europe', value: 'europe' },
      { label: 'Worldwide Excluding', value: 'worldwide_excluding' },
      { label: 'Worldwide', value: 'worldwide' },
    ];
    const getDestinationsAnnual = [
      { label: 'Europe', value: 'europe' },
      { label: 'Worldwide', value: 'worldwide' },
    ];
    if (coverLevelNextProps !== coverLevelOldProps) {
      if (coverLevelNextProps === 'Annual') {
        this.setState(() => ({
          destinationOptions: getDestinationsAnnual,
        }));
      }
      if (coverLevelNextProps === 'Single') {
        this.setState(() => ({
          destinationOptions: getDestinationsSingle,
        }));
      }
    }
  }

  handleSelectChange(valueTravellerGroup) {
    this.setState({ valueTravellerGroup });
    const valuesArr = valueTravellerGroup.split(',');
    this.props.dispatch(
      this.props.change('data.attributes.criterion.traveller_group.in', valuesArr),
    );
  }

  handleSelectChangeDestinations(valueRegions) {
    this.setState({ valueRegions });
    const valuesArr = valueRegions.split(',');
    this.props.dispatch(
      this.props.change('data.attributes.criterion.region.in', valuesArr),
    );
  }

  handleUpload() {
    const { dispatch } = this.props;
    dispatch(triggerFileUpload());
  }

  handleUploadComplete(data) {
    const { dispatch, change } = this.props;
    dispatch(change('data.relationships.avatar.data', data.data));
  }

  renderField(field) {
    const { input, type, meta } = field;
    const className =
      meta.submitFailed && meta.touched && meta.error && meta.error.length > 0
        ? 'form-control has-error'
        : 'form-control';
    let placeHolder = field.placeholder;
    switch (field.id) {
      case 'ageStart':
        placeHolder = '-';
        break;
      case 'ageEnd':
        placeHolder = '-';
        break;
    }
    return (
      <div>
        <input
          disabled={field.disabled}
          {...input}
          className={className}
          type={type}
          placeholder={this.state.allAges ? placeHolder : ''}
        />
        {meta && meta.touched && meta.error && (
          <span className="input-campaign-error">{meta.error}</span>
        )}
      </div>
    );
  }

  familyGroupsSelection({ input, label, meta }) {
    return (
      <FormGroup>
        <Col xs={4} componentClass={ControlLabel}>
          {label}
        </Col>
        <Col xs={8}>
          <Select
            {...input}
            multi
            simpleValue
            autoBlur
            value={this.state.valueTravellerGroup}
            options={this.state.familyOptions}
            onChange={this.handleSelectChange}
            dispatch={this.props.dispatch}
            change={this.props.change}
          />
          {meta && meta.touched && meta.error && (
            <span className="input-campaign-error">{meta.error}</span>
          )}
        </Col>
      </FormGroup>
    );
  }

  allAges() {
    const { dispatch, change } = this.props;
    Promise.resolve(dispatch(change('data.attributes.criterion.max_age.min', '')))
      .then(dispatch(change('data.attributes.criterion.max_age.max', '')))
      .then(() =>
        this.setState((prevState) => ({
          allAges: !prevState.allAges,
        })),
      );
  }

  allDurations() {
    const { dispatch, change } = this.props;
    Promise.resolve(dispatch(change('data.attributes.criterion.trip_duration.min', '')))
      .then(dispatch(change('data.attributes.criterion.trip_duration.max', '')))
      .then(() =>
        this.setState((prevState) => ({
          allDurations: !prevState.allDurations,
        })),
      );
  }

  allScores() {
    const { dispatch, change } = this.props;
    Promise.resolve(dispatch(change('data.attributes.criterion.max_score.min', '')))
      .then(dispatch(change('data.attributes.criterion.max_score.max', '')))
      .then(() =>
        this.setState((prevState) => ({
          allScores: !prevState.allScores,
        })),
      );
  }

  destinationSelection({ input, label, meta }) {
    return (
      <FormGroup>
        <Col xs={4} componentClass={ControlLabel}>
          {label}
        </Col>
        <Col xs={8}>
          <Select
            {...input}
            autoBlur
            multi
            simpleValue
            disabled={this.state.disabled}
            value={this.state.valueRegions}
            options={this.state.destinationOptions}
            onChange={this.handleSelectChangeDestinations}
            dispatch={this.props.dispatch}
            change={this.props.change}
          />
          {meta && meta.touched && meta.error && (
            <span className="input-campaign-error">{meta.error}</span>
          )}
        </Col>
      </FormGroup>
    );
  }

  coverSelection({ input, label, meta }) {
    return (
      <div className="form-group">
        <div className="col-xs-4">
          <label className="control-label">{label}</label>
        </div>
        <div className="col-xs-8">
          <Select
            {...input}
            autoBlur
            multi
            simpleValue
            disabled={this.state.disabled}
            value={this.state.valueCoverLevels}
            options={this.state.coverLevelsOptions}
            onChange={this.handleSelectCoverLevels}
            dispatch={this.props.dispatch}
            change={this.props.change}
          />
          {meta && meta.touched && meta.error && (
            <span className="input-campaign-error">{meta.error}</span>
          )}
        </div>
      </div>
    );
  }

  handleSelectCoverLevels(valueCoverLevels) {
    this.setState({ valueCoverLevels });

    let valuesArr = valueCoverLevels.split(',');
    this.props.dispatch(
      this.props.change('data.attributes.criterion.cover_level.in', valuesArr),
    );
  }

  selectBrokerIfB2B() {
    const { formValues, campaign } = this.props;

    const channel = get(formValues, 'data.relationships.channels.data');
    const getB2B = filter(channel, function (o) {
      return get(o, 'attributes.channel_type') === 'B2B';
    });

    if (isEmpty(getB2B)) {
      return;
    }

    return (
      <div>
        <FieldArray
          name="data.relationships.brokers.data"
          component={SelectBrokerFields}
          campaign={campaign}
          formValues={formValues}
          dispatch={this.props.dispatch}
          change={this.props.change}
          labelSize={4}
        />
      </div>
    );
  }

  render() {
    const { handleSubmit, saving, formValues } = this.props;
    const submitting = ''; // placeholder
    const buttons = (
      <Button bsStyle="default" label="Back" isBackButton leftIcon="caret-left"/>
    );
    const schemeLevel = [
      { label: 'Annual', value: 'annual' },
      { label: 'Single', value: 'single' },
    ];

    const discountTypes = [
      {
        label: 'Gross Selling Price Discount',
        value: 'Gross Selling Price Discount',
        field: 'data.attributes.discount',
        fieldLabel: 'Discount',
      },
      {
        label: 'Net Discount',
        value: 'Net Discount',
        field: 'data.attributes.discount',
        fieldLabel: 'Discount',
      },
      {
        label: 'Broker Commission Override',
        value: 'Broker Commission Override',
        field: 'data.attributes.premium_overrides.commissions.commission_broker',
        fieldLabel: 'Commission Override',
      },
      {
        label: 'PJH Commission Override',
        value: 'Owner Commission Override',
        field: 'data.attributes.premium_overrides.commissions.commission_owner',
        fieldLabel: 'Commission Override',
      },
      {
        label: 'Total Commission Override',
        value: 'Total Commission Override',
        field: 'data.attributes.premium_overrides.commissions.commission_rate',
        fieldLabel: 'Commission Override',
      },
    ];

    const selectedDiscountType = () => {
      const discountType = get(
        formValues,
        'data.attributes.discount_type',
        'Gross Selling Price Discount',
      );
      for (let i = 0; i < discountTypes.length; i++) {
        if (discountTypes[i].value === discountType) {
          return discountTypes[i].field;
        }
      }
      return 'data.attributes.discount';
    };

    const selectedDiscountLabel = () => {
      const discountType = get(
        formValues,
        'data.attributes.discount_type',
        'Gross Selling Price Discount',
      );
      for (let i = 0; i < discountTypes.length; i++) {
        if (discountTypes[i].value === discountType) {
          return discountTypes[i].fieldLabel;
        }
      }

      return 'data.attributes.discount';
    };

    return (
      <InternalContainer title="Admin" buttons={buttons}>
        <Box className={saving ? 'currently-saving' : ''}>
          <h4 className="title-campaign">New Campaign</h4>
          <hr id="first-row"/>
          <Form onSubmit={handleSubmit} autoComplete="off" className="form-horizontal">
            <Row>
              <Col sm={6}>
                <Field
                  name="data.attributes.name"
                  label="Name"
                  labelSize={4}
                  component={HorizontalFormControl}
                  validate={[required({ msg: 'Field is required' })]}
                />
                <Field
                  name="data.attributes.code"
                  label="Voucher Code"
                  labelSize={4}
                  component={HorizontalFormControl}
                  // validate={[required({ msg: 'Field is required' })]}
                />
                <Field
                  name="data.attributes.exclusive"
                  label={<strong>Exclusive</strong>}
                  component={CheckboxUnlabeled}
                  positionTop
                  inline
                  labelSize={4}
                  options={[{ label: '', value: true }]}
                />
                <Field
                  name="data.attributes.has_url"
                  label={<strong>Has url?</strong>}
                  component={CheckboxUnlabeled}
                  positionTop
                  inline
                  labelSize={4}
                  options={[{ label: '', value: true }]}
                />
              </Col>
              <Col sm={6}>
                <Field
                  name="data.attributes.discount_type"
                  label="Discount Type"
                  multi
                  object
                  labelSize={4}
                  options={discountTypes}
                  component={SelectField}
                  validate={[required({ msg: 'Field is required' })]}
                />
                <Field
                  name={selectedDiscountType()}
                  label={selectedDiscountLabel()}
                  labelSize={4}
                  object
                  mdFieldSize={4}
                  addonAfter={'%'}
                  component={HorizontalFormControl}
                />
                <Field
                  name="data.attributes.from_date"
                  label="Start Date"
                  labelSize={4}
                  mdFieldSize={7}
                  component={DatePickerDob}
                  showTime
                  timeFormat="HH:mm"
                  validate={[required({ msg: 'Field is required' })]}
                />
                <Field
                  name="data.attributes.to_date"
                  label="End Date"
                  labelSize={4}
                  mdFieldSize={7}
                  component={DatePickerDob}
                  showTime
                  timeFormat="HH:mm"
                />
              </Col>
            </Row>
            <hr id="second-row"/>
            <Row>
              <Col sm={6}>
                <div className="form-horizontal">
                  <Field
                    name="data.relationships.channels.data"
                    label="Channels"
                    labelSize={4}
                    component={SelectChannel}
                  />
                  {this.selectBrokerIfB2B()}
                  <Field
                    name="data.relationships.product.data.id"
                    label="Product"
                    labelSize={4}
                    component={SelectProduct}
                    validate={[required({ msg: 'Field is required' })]}
                  />
                </div>
              </Col>
              <Col sm={6}>
                <div className="form-horizontal">
                  <Field
                    label="Cover Levels"
                    name="data.attributes.criterion.cover_level.in"
                    options={getCoverLevelOptions}
                    component={SelectField}
                    isMulti
                    labelSize={4}
                  />
                  <Field
                    name="data.attributes.criterion.scheme_type.equal"
                    label="Scheme Type"
                    multi
                    object
                    labelSize={4}
                    options={schemeLevel}
                    component={SelectField}
                  />
                </div>
              </Col>
            </Row>

            <hr id="third-row-banner"/>
            <Row>
              <Col sm={6}>
                <Field
                  label="Family Group"
                  labelSize={4}
                  name="data.attributes.criterion.traveller_group.in"
                  component={SelectField}
                  isMulti
                  options={this.state.familyOptions}
                />
                <Field
                  label="Destinations"
                  labelSize={4}
                  name="data.attributes.criterion.region.in"
                  component={SelectField}
                  isMulti
                  options={this.state.destinationOptions}
                />
              </Col>
              <Col sm={6}>
                <FormGroup controlId="age">
                  <Col componentClass={ControlLabel} sm={4}>
                    Target Ages
                  </Col>
                  <Col sm={3}>
                    <Field
                      name="data.attributes.criterion.max_age.min"
                      type="text"
                      id="ageStart"
                      component={this.renderField}
                      disabled={this.state.allAges}
                    />
                  </Col>
                  <Col sm={1}>
                    <p className="text-adjust">to</p>
                  </Col>
                  <Col sm={3}>
                    <Field
                      name="data.attributes.criterion.max_age.max"
                      type="text"
                      id="ageEnd"
                      component={this.renderField}
                      disabled={this.state.allAges}
                    />
                  </Col>
                  <Col sm={1}>
                    <p
                      className={
                        'select-all-link' + (this.state.allAges ? ' all-visited' : '')
                      }
                      onClick={() => this.allAges()}
                    >
                      All
                    </p>
                  </Col>
                </FormGroup>
                <FormGroup controlId="range-fields">
                  <Col componentClass={ControlLabel} sm={4}>
                    Target Trip Duration
                  </Col>
                  <Col sm={3}>
                    <Field
                      name="data.attributes.criterion.trip_duration.min"
                      type="text"
                      component={this.renderField}
                      disabled={this.state.allDurations}
                    />
                  </Col>
                  <Col sm={1}>
                    <p className="text-adjust">to</p>
                  </Col>
                  <Col sm={3}>
                    <Field
                      name="data.attributes.criterion.trip_duration.max"
                      type="text"
                      component={this.renderField}
                      disabled={this.state.allDurations}
                    />
                  </Col>
                  <Col sm={1}>
                    <p
                      className={
                        'select-all-link' +
                        (this.state.allDurations ? ' all-visited' : '')
                      }
                      onClick={() => this.allDurations()}
                    >
                      All
                    </p>
                  </Col>
                </FormGroup>
                <FormGroup controlId="">
                  <Col componentClass={ControlLabel} sm={4}>
                    Target Healix Scores
                  </Col>
                  <Col sm={3}>
                    <Field
                      name="data.attributes.criterion.max_score.min"
                      type="text"
                      disabled={this.state.allScores}
                      id="healixStart"
                      component={this.renderField}
                    />
                  </Col>
                  <Col sm={1}>
                    <p className="text-adjust">to</p>
                  </Col>
                  <Col sm={3}>
                    <Field
                      name="data.attributes.criterion.max_score.max"
                      type="text"
                      disabled={this.state.allScores}
                      id="healixEnd"
                      component={this.renderField}
                    />
                  </Col>
                  <Col sm={1}>
                    <p
                      className={
                        'select-all-link' + (this.state.allScores ? ' all-visited' : '')
                      }
                      onClick={() => this.allScores()}
                    >
                      All
                    </p>
                  </Col>
                </FormGroup>
              </Col>
            </Row>
            {this.state.banner && (
              <div className="banner-container">
                <hr id="fourth-row-banner"/>
                <h4 className="title-campaign">Banner</h4>
                <Row>
                  <Col sm={6}>
                    <Field
                      name="data.attributes.banner_enabled"
                      label="Enabled"
                      component={CheckboxUnlabeled}
                      positionTop
                      inline
                      labelSize={4}
                      options={[{ label: '', value: true }]}
                    />
                    <Field
                      name="data.attributes.page"
                      label="Page"
                      labelSize={4}
                      component={HorizontalFormControl}
                    />
                    <Field
                      name="data.attributes.banner"
                      label="Banner Text"
                      labelSize={4}
                      component={HorizontalFormControl}
                    />
                  </Col>
                  <Col sm={6}>
                    <div className="form-horizontal">
                      <FormGroup controlId="range-fields">
                        <Col sm={4}>
                          <p className="image-upload-text">Image Upload</p>
                        </Col>
                        <Col sm={4}>
                          <Button
                            type="button"
                            bsStyle="primary settings-button"
                            className="pull-right"
                            handleClick={this.handleUpload}
                          >
                            {submitting ? (
                              <i className="fa fa-cog fa-spin"/>
                            ) : (
                              <i className="fa"/>
                            )}{' '}
                            Select File
                          </Button>
                        </Col>
                      </FormGroup>
                      <Field
                        name="data.attributes.time_idle"
                        label="Time Idle"
                        labelSize={4}
                        mdFieldSize={4}
                        component={HorizontalFormControl}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            )}

            <Row>
              <Col xs={12}>
                <InternalButtonBar buttons={[
                  {
                    permissions: ['marketing.campaign_create'],
                    button: (
                      <Button
                        size="large"
                        type="submit"
                        className="pull-right pad-right"
                        label="Save Campaign"
                        isLoading={saving}
                        bsStyle="primary"
                      />
                    )
                  }
                ]}/>
              </Col>
            </Row>
          </Form>
        </Box>

        <UploadCropComponent
          onComplete={this.handleUploadComplete}
          width={200}
          height={200}
          bsSize="sm"
          circle={false}
        />
      </InternalContainer>
    );
  }
}

const getCoverLevelOptions = [
  { label: 'Super', value: 'super' },
  { label: 'Super Duper', value: 'super_duper' },
];

const FORM_NAME = 'CampaignNewForm';
const form = reduxForm({ form: FORM_NAME })(CampaignNewForm);
const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (state, props) => {
  const values = selector(state, 'data', 'metadata');

  return {
    auth: state.auth,
    dashboard: state.dashboard,
    initialValues: props.resource,
    formValues: values,
    campaign: state.campaign,
  };
};

export default connect(mapStateToProps)(form);

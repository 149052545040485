import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, DataTable } from '../../../../common/components';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { FormattedDate, FormattedTime } from 'react-intl';
import { hasPermission } from '../../../auth/redux/authActions';
import { openModal } from '../../../../common/components/Modal/ModalRedux';
import { connect } from 'react-redux';
import UpdateTransactionModal from '../UpdateTransactionModal/UpdateTransactionModal';
import { refreshData } from '../../../../common/components/DataTable/redux/dataTableActions';

class CustomerTransactions extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      transaction: {},
    };
  }

  formatDate(cell) {
    return (
      <span>
        <FormattedDate value={new Date(cell)} /> <FormattedTime value={new Date(cell)} />
      </span>
    );
  }

  formatAmount(cell) {
      return parseFloat(cell).toFixed(2);
  }

  handleRowClick(row) {
    this.setState({
      transaction: row,
    });

    if (hasPermission('account.edit') && row && row.reference && row.amount > 0) {
      this.props.dispatch(openModal('transactionForm'));
    }
  }

  refreshList() {
    this.props.dispatch(refreshData('transactions'));
  }

  render() {
    const resource = this.props.resource.data.attributes;

    return (
      <div>
        <Box>
          <h2 className="resource-name">
            {resource.title + ' ' + resource.first_name + ' ' + resource.last_name}
          </h2>

          <DataTable
            source={'/policies/transactions'}
            autoFilter={this.props.resource.data.id}
            autoFilterType="filter[customer]"
            name="transactions"
            showTotal
            onRowSelect={this.handleRowClick.bind(this)}
          >
            <TableHeaderColumn dataField="id" isKey hidden>
              ID
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="reference"
              dataSort
              width={'30%'}
              dataFormat={this.formatReference}
            >
              Reference
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="currency"
              dataSort={false}
              width={'10%'}
              dataAlign="center"
            >
              Currency
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="amount"
              dataSort={false}
              dataAlign="right"
              dataFormat={this.formatAmount}
              width={'20%'}
            >
              Amount
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="source"
              dataSort={false}
              dataFormat={this.formatSource}
              width={'20%'}
              dataAlign="center"
            >
              Source
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="created_at"
              dataFormat={this.formatDate}
              dataSort
              width={'10%'}
            >
              Transacted At
            </TableHeaderColumn>
            <TableHeaderColumn dataField="status" width={'10%'}>
              Status
            </TableHeaderColumn>
          </DataTable>
          <div className="col-xs-12">
            <UpdateTransactionModal
              hidden
              label={'Refund Transaction'}
              callback={::this.refreshList}
              refundTransaction={this.state.transaction}
            />
          </div>
        </Box>
      </div>
    );
  }
}

export default connect()(CustomerTransactions);

import React, { Component } from 'react';
import { Link } from 'react-router';
import GuestNavigation from '../GuestNavigation/GuestNavigation';

import logo from '../../../../images/brand/logo.svg';

import './GuestHeader.scss';

export default class GuestHeader extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      mobileDropdown: false,
    };

    this.recomputeState = ::this.recomputeState;
  }

  componentDidMount() {
    this.on(
      ['resize', 'scroll', 'touchstart', 'touchmove', 'touchend', 'pageshow', 'load'],
      this.recomputeState,
    );
  }

  componentWillUnmount() {
    this.off(
      ['resize', 'scroll', 'touchstart', 'touchmove', 'touchend', 'pageshow', 'load'],
      this.recomputeState,
    );
  }

  recomputeState() {
    if (this.getDistanceFromTop() < -100) {
      return this.setState({ collapsed: true });
    }

    this.setState({ collapsed: false });
  }

  getDistanceFromTop() {
    if (this.refs.header) {
      return this.refs.header.getBoundingClientRect().top;
    }
    return 0;
  }

  on(events, callback) {
    events.forEach((evt) => {
      window.addEventListener(evt, callback);
    });
  }

  off(events, callback) {
    events.forEach((evt) => {
      window.removeEventListener(evt, callback);
    });
  }

  render() {
    return (
      <div>
        <div className="header-publics">
          <Link to="/">
            <img className="logo" src={logo} alt="logo" />
          </Link>
          <GuestNavigation {...this.props} />
        </div>
      </div>
    );
  }
}

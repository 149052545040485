import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Col, FormControl, ControlLabel, InputGroup } from 'react-bootstrap';
import './PhoneFormControl.scss';

const handleOnChange = (customOnChange, input) => {
  return (event) => {
    if (input) {
      input.onChange(event);
    }

    if (customOnChange) {
      customOnChange(event);
    }
  };
};

const PhoneFormControl = ({
  phone,
  input,
  meta,
  type,
  labelSize,
  mdFieldSize,
  label,
  infoButton,
  placeholder,
  rows,
  disabled,
  note,
  inputOnly,
  onChange,
  readOnly,
  className,
}) => {
  const size = labelSize ? labelSize : 2;

  if (!readOnly) readOnly = false;

  const formControlComponent = phone ? (
    <InputGroup>
      <FormControl
        {...input}
        type={type}
        className={className}
        placeholder={placeholder}
        value={input.value}
        rows={rows}
        disabled={disabled}
        onChange={handleOnChange(onChange, input)}
        onBlur={handleOnChange(onChange, input)}
        readOnly={readOnly}
      />
    </InputGroup>
  ) : (
    <div>
      <div className="flex-container">
        <div className="flex-item-form">
          <FormControl
            {...input}
            type={type}
            placeholder={placeholder}
            rows={rows}
            disabled={disabled}
            onChange={handleOnChange(onChange, input)}
            onBlur={handleOnChange(onChange, input)}
            readOnly={readOnly}
          />
        </div>
        <div className="flex-item-text">
          {type === 'mobile' && (
            <div>
              {' '}
              Please note you have the option to send policy confirmation and medical
              emergency number by SMS.{' '}
            </div>
          )}
        </div>
      </div>
    </div>
  );

  if (inputOnly) {
    return formControlComponent;
  }

  return (
    <FormGroup
      controlId={input ? input.name : label}
      className={meta && meta.touched && meta.error ? ' has-error' : ''}
    >
      <Col sm={size}>
        <ControlLabel>{label}</ControlLabel> {infoButton}
      </Col>
      <Col sm={12 - size} md={mdFieldSize ? mdFieldSize : 0}>
        {formControlComponent}
        {note ? <div className="note">{note}</div> : ''}
        {meta && meta.touched && meta.error && (
          <span className="input-error">{meta.error}</span>
        )}
      </Col>
    </FormGroup>
  );
};

PhoneFormControl.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  labelSize: PropTypes.number,
  mdFieldSize: PropTypes.number,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  percentage: PropTypes.bool,
  inputOnly: PropTypes.bool,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  monetaryValue: PropTypes.string,
  note: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  isCalc: PropTypes.bool,
  className: PropTypes.string,
};

export default PhoneFormControl;

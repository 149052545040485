import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { Button, Box } from '../../../../common/components';
import Partner from './Partner';
import { updatePartners } from '../../redux/contentActions';
import { get } from 'lodash';

const FORM_NAME = 'partnersForm';

class PartnersForm extends Component {
  static propTypes = {
    content: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired,
  };

  savePartners(resource) {
    this.props.dispatch(updatePartners(resource));
  }

  render() {
    const { formValues, handleSubmit } = this.props;

    return (
      <Box className="no-top-padding">
        <form onSubmit={handleSubmit(::this.savePartners)}>
          <h2 className="resource-name">Partners</h2>
          <div className="row">
            <div className="col-xs-12">
              <FieldArray
                name="data.partners.data"
                items={get(formValues, 'data.partners.data', [])}
                component={Partner}
              />
            </div>
          </div>

          <div className="row actions">
            <div className="col-xs-12">
              <Button
                type="submit"
                label="Save Changes"
                bsStyle="primary"
                className="pull-right"
                isLoading={this.props.content.isUpdatingPartners}
              />
            </div>
          </div>
        </form>
      </Box>
    );
  }
}

const form = reduxForm({ form: FORM_NAME })(PartnersForm);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  const values = selector(state, 'data', 'data.partners');

  return {
    initialValues: {
      data: {
        partners: {
          data: props.content.partners,
        },
      },
    },
    formValues: values,
  };
};
export default connect(mapStateToProps)(form);

import React, { Component } from 'react';
import { get } from 'lodash';
import { FaCheck, FaTimes }  from 'react-icons/fa';
import './ButtonGroup.scss';

class ButtonGroup extends Component {
  render() {
    const {
      input,
      label,
      options,
      objectKey,
      inline,
      meta: { touched, error },
    } = this.props;

    return (
      <div
        className={touched && error ? 'form-group application-has-error' : 'form-group'}
      >
        <div>
          {options.map((option, key) => {
            const value = objectKey ? get(option.value, objectKey) : option.value;
            return (
              <div
                className={'radio-container-broker' + (inline ? ' inline' : '')}
                key={key}
              >
                <div className="form-radio">
                  <input
                    {...input}
                    type="radio"
                    id={input.name + option.label}
                    value={option.value}
                    checked={input.value.toString() === value.toString()}
                  />
                  <label htmlFor={input.name + option.label}>
                    <div
                      className={
                        touched && error ? 'radio-label has-error' : 'radio-label'
                      }
                      onClick={() => input.onChange(option.value)}
                    >
                      {option.label === 'No' ? <FaTimes /> : <FaCheck />}
                      {option.label}
                    </div>
                  </label>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default ButtonGroup;

import React, { useState } from 'react';
import { FormattedDate } from 'react-intl';
import { FaFilePdf } from 'react-icons/fa';
import DynamicDocumentContent from './DynamicDocumentContent';
import DynamicDocumentVersionSelector
  from '../../DynamicDocumentVersionSelector/DynamicDocumentVersionSelector';
import DynamicDocumentDropdownActions from './DynamicDocumentDropdownActions';
import UpdateDynamicDocumentForm from './UpdateDynamicDocumentForm';


const ContentSection = ({
  showForm,
  isLoading,
  document,
  onSave,
  onCancel

}) => {

  if (showForm) {
    return (
      <UpdateDynamicDocumentForm
        key={document.id}
        isLoading={isLoading}
        document={document}
        onSave={onSave}
        onCancel={onCancel}
      />
    );

  }
  return (
    <DynamicDocumentContent document={document}/>
  );
};

const DynamicDocumentListItem = ({
  documents,
  index,
  activeItem,
  onUpdate,
  isLoading,
  onSelectItem
}) => {
  const [showForm, setShowForm] = useState(false);
  const [activeDocument, setActiveDocument] = useState(documents[0]);

  const fromDate = activeDocument ? activeDocument.attributes.from_date : '';
  const toDate = activeDocument ? activeDocument.attributes.to_date : '';
  const name = activeDocument ? activeDocument.attributes.name : '';
  const isActive = activeItem === index;


  const handleToggleForm = () => {
    setShowForm(!showForm);

  };

  const showContent = () => {
    onSelectItem(index);
  };

  const hideContent = () => {
    onSelectItem(null);
  };

  const save = (formValues) => {
    return Promise.resolve(onUpdate(formValues)).then(() => {
      setShowForm(false);
    });
  };

  const selectDocument = (document) => {
    setActiveDocument(document);
    setShowForm(false);
  };

  return (
    <div
      className={`document-container-section ${!isActive && 'section-collapsed'}`}
      onClick={!isActive ? showContent : undefined}
    >
      <div className="document-content">
        <div className="dynamic-document-header">
          <div>
            <h4 onClick={hideContent}>
              <FaFilePdf/> {name}
            </h4>
            {isActive && (
              <div>
                <strong>Active dates:</strong>
                <div>
                  from <FormattedDate value={fromDate}/>{' '}
                  {toDate && (
                    <span>
                        {' '}
                      - to <FormattedDate value={toDate}/>
                      </span>
                  )}
                </div>
              </div>
            )}
          </div>
          {isActive && (
            <div>
              <DynamicDocumentVersionSelector
                documentList={documents}
                activeDocument={activeDocument}
                onSelectDocument={selectDocument}
              />
              {activeDocument.attributes.status === 'active' && (
                <DynamicDocumentDropdownActions
                  showForm={showForm}
                  document={activeDocument}
                  onCreateNewVersion={handleToggleForm}
                />
              )}
            </div>
          )}
        </div>
        {isActive && (
          <div className="animated fadeIn mb-30 bt">
            <ContentSection
              showForm={showForm && activeDocument}
              onSave={save}
              onCancel={handleToggleForm}
              document={activeDocument}
              isLoading={isLoading}
            />

          </div>
        )}
      </div>
      {isActive && (
        <div onClick={hideContent} className="close-document-container">
          Close
        </div>
      )}
    </div>
  );
};


export default DynamicDocumentListItem;

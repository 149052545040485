import * as actions from './enquiryReducer';
import Axios from 'axios';
import { push } from 'react-router-redux';
import { toastr } from 'react-redux-toastr';
import queryString from '../../../helpers/queryString';
import handleErrorResponse from '../../../helpers/handleErrorResponse';
import { ADD_RESOURCE } from '../../layout/redux/breadcrumb';

const defaultIncludes = ['responded_by'];

export function clearEnquiry() {
  return {
    type: actions.CLEAR_ENQUIRY,
  };
}

export function isSubmitting(status) {
  return {
    type: actions.IS_SUBMITTING,
    status,
  };
}

export function getEnquiry(id, includes = defaultIncludes) {
  return (dispatch) => {
    let endpoint = 'enquiry/' + id;
    endpoint += queryString(includes);

    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: actions.SET_ENQUIRY,
          data: response.data,
        });
        dispatch({
          type: ADD_RESOURCE,
          id: response.data.data.id,
          name: response.data.data.attributes.name,
        });
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function createEnquiry(resource, publicRequest = true, redirect = false) {
  return (dispatch) => {
    dispatch(isSubmitting(true));
    return Axios.post((publicRequest ? 'public/' : '/') + 'enquiries', resource)
      .then((response) => {
        dispatch(isSubmitting(false));
        toastr.success('Enquiry sent', 'Your enquiry was submitted to PJH');

        if (redirect) {
          dispatch(push('/enquiries/' + response.data.data.id));
        }
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'There was an error creating your enquiry');
      });
  };
}

export function respondToEnquiry(resource) {
  return (dispatch) => {
    dispatch(isSubmitting(true));
    return Axios.post('/enquiry/' + resource.data.id + ':respond', resource)
      .then(() => {
        dispatch(isSubmitting(false));
        dispatch(getEnquiry(resource.data.id));
        toastr.success('Enquiry sent', 'The enquiry was marked as responded');
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'There was an error update the enquiry');
      });
  };
}

import React from 'react';
import './CovidStatement.scss';
import Markdown from 'react-markdown';
import { Col, FormGroup } from 'react-bootstrap';
import { Field } from 'redux-form';
import { CheckboxGroup } from '../../../../common/components/ReduxFormField';
import { required } from 'redux-form-validators';

const CovidStatement = ({ covidMessage, showAgreement }) => {
  if (!covidMessage || covidMessage === '' || covidMessage === '\u200b') {
    return null;
  }

  return (
    <div>
      <div className={'covid-statement'}>
        <Markdown source={covidMessage}/>
      </div>
      {showAgreement && (
        <FormGroup>
          <Col md={12} lg={12}>
            <Field
              name="data.attributes.metadata.storage.covid_declaration_question"
              component={CheckboxGroup}
              inline
              options={[{
                label: 'I have read the above to the insured and they understand and agree to the statement.',
                value: true
              }]}
              validate={required({ msg: 'Please Agree to the above statement' })}
            />
          </Col>
        </FormGroup>
      )}
    </div>
  );
};

export default CovidStatement;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import Icon from '../Icon/Icon';
import { connect } from 'react-redux';
import { forEach } from 'lodash';
import './Breadcrumb.scss';

class Breadcrumb extends Component {
  static propTypes = {
    location: PropTypes.object,
    name: PropTypes.string,
  };

  render() {
    const { broker, breadcrumb } = this.props;
    let pattern = /(.*)\/\/[a-zA-Z:.0-9-@]+\//;
    let path = '';
    let segments = [];
    let brokerType = null;

    forEach(broker.resources, (item) => {
      brokerType = item.data.attributes.broker_type;
    });

    if (typeof window !== 'undefined' && typeof window.location !== 'undefined') {
      segments = window.location.href
        .split(/[?#]/)[0]
        .replace(pattern, '')
        .split('/');

      if (segments[0] === 'brokers' && brokerType === 'tracking') {
        segments.splice(0, 1, 'tracking');
      }
      if (segments[1] === 'source-types') {
        segments.splice(1, 2, 'Source Groups');
      }
    }

    const capitalise = (s) => {
      const bc = s.replace(/-/g, ' ');
      return bc[0].toUpperCase() + bc.slice(1);
    };

    const filteredSegment = (s) => {
      let uuidPattern = /[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}/;
      const uuid = s.match(uuidPattern);

      if (uuid) {
        return breadcrumb.resources[uuid] || 'resource';
      }

      return s;
    };

    const excludedPaths = [];
    const notClickablePaths = ['resource', 'Leisure Travel'];
    const display = (s) => {
      if (s === 'resource') {
        return (
          <span>
            Resource
          </span>
        );
      }

      return capitalise(s);
    };

    return (
      <div className="breadcrumb-box">
        <ul className="breadcrumb">
          <li className="li-home">
            <Link to="/dashboard">
              <Icon name="home" />
            </Link>
          </li>
          {segments.map((segment, part) => {
            path += '/' + segment;
            segment = filteredSegment(segment);
            if (!segment) {
              return;
            }
            if (segments.length === part + 1 || excludedPaths.indexOf(segment) !== -1) {
              return (
                <li key={part} className="li-active">
                  <span className="last-child-padding">
                    <Link>&nbsp;{display(segment)}</Link>
                  </span>
                </li>
              );
            }
            if (notClickablePaths.indexOf(segment) !== -1) {
              return (
                <li key={part} className="li-breadcrumb">
                  <Link to={''} activeClassName="active">
                    &nbsp;{display(segment)}
                  </Link>
                </li>
              );
            }
            return (
              <li key={part} className="li-breadcrumb">
                <Link to={path}>&nbsp;{display(segment)}</Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    broker: state.broker,
    breadcrumb: state.breadcrumb,
  };
}

export default connect(mapStateToProps)(Breadcrumb);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { SelectField } from '../../../../common/components/ReduxFormField';
import {
  Box,
  Button,
  DatePickerDob,
  SelectProduct,
  SelectAccountingPeriod,
} from '../../../../common/components';
import { generateReport } from '../../redux/accountActions';
import { get, indexOf } from 'lodash';
import './styles.scss';
import { Form, Col, Row, ControlLabel, FormGroup } from 'react-bootstrap';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';

const FORM_NAME = 'brokerReportsForm';

const validate = (values, endDateOnlyRequired) => {
  const errors = {};

  if (!get(values, 'report')) {
    errors.report = 'Report is required';
  }

  if (
    indexOf(endDateOnlyRequired, get(values, 'report')) === -1 &&
    !get(values, 'from_date')
  ) {
    errors.from_date = 'From date is required';
  }

  if (!get(values, 'to_date')) {
    errors.to_date = 'To date is required';
  }

  return errors;
};

class BrokerReports extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    isGeneratingReport: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      queue: false
    };
  }

  handleSubmit(values) {
    this.props.dispatch(generateReport(values));
  }

  componentWillReceiveProps(nextProps) {
    const { change, dispatch, formValues } = this.props;
    const reportOld = get(formValues, 'report');
    const reportNew = get(nextProps.formValues, 'report');
    if (reportOld !== reportNew) {
      dispatch(change('product', ''));
    }
  }

  handleGenerateOnQueue() {
    const { change, dispatch } = this.props;

    if (this.state.queue) {
      dispatch(change('generator', null));
    } else {
      dispatch(change('generator', 'queue'));
    }

    this.setState({ queue: !this.state.queue });
  }

  render() {
    const {
      formValues,
      handleSubmit,
      isGeneratingReport,
      endDateOnlyRequired,
    } = this.props;

    const reports = [
      { label: 'Quote Report', value: 'quote_report:bespoke' },
      { label: 'Policy Report', value: 'policy_report:bespoke' },
      { label: 'Statement', value: 'broker_own_statement' },
    ];

    return (
      <Box>
        <h2 className="resource-name">Reports</h2>
        <Form onSubmit={handleSubmit(::this.handleSubmit)}>
          <Row>
            <Col sm={6}>
              <Field
                name="report"
                label="Report"
                labelSize={3}
                component={SelectField}
                options={reports}
              />

              {indexOf(endDateOnlyRequired, get(formValues, 'report')) !== -1 ? (
                <Field
                  name="to_date"
                  label="As at date"
                  labelSize={3}
                  component={DatePickerDob}
                />
              ) : (
                <div>
                  <Field
                    name="accounting_period"
                    label="Accounting Period"
                    labelSize={3}
                    component={SelectAccountingPeriod}
                  />
                  {!get(formValues, 'accounting_period') && (
                    <div>
                      <Field
                        name="from_date"
                        label="From"
                        labelSize={3}
                        component={DatePickerDob}
                      />
                      <Field
                        name="to_date"
                        label="To"
                        minDate={get(formValues, 'from_date')}
                        labelSize={3}
                        component={DatePickerDob}
                      />
                    </div>
                  )}
                </div>
              )}
              <FormGroup>
                <Col sm={3} componentClass={ControlLabel}>
                  Queue
                </Col>
                <Col sm={9}>
                  <div className="queue-checkbox">
                    <div className="checkbox-container inline">
                      <div className="form-radio">
                        <input
                          id="Queue"
                          type="checkbox"
                          checked={this.state.queue}
                          onChange={() => this.handleGenerateOnQueue()}
                          value="generator"
                        />
                        <label htmlFor="Queue"/>
                      </div>
                    </div>
                  </div>
                </Col>
              </FormGroup>
            </Col>

            <Col sm={6}>
              {get(formValues, 'report') !== 'broker_own_statement' && (
                <Field
                  name="product"
                  label="Product"
                  labelSize={3}
                  component={SelectProduct}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <InternalButtonBar buttons={[
                {
                  button: (
                    <Button
                      className="pull-right"
                      type="submit"
                      label="Generate Report"
                      isLoading={isGeneratingReport}
                      bsStyle="primary"
                    />
                  )
                }
              ]}/>
            </Col>
          </Row>
        </Form>
      </Box>
    );
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(BrokerReports);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state) => {
  const values = selector(
    state,
    'from_date',
    'to_date',
    'report',
    'accounting_period',
    'product',
  );
  const endDateOnlyRequired = ['broker_own_statement'];
  return {
    endDateOnlyRequired: endDateOnlyRequired,
    formValues: values,
    isGeneratingReport: state.account.isGeneratingReport,
  };
};
export default connect(mapStateToProps)(form);

import { Col, ControlLabel, FormGroup, Row } from 'react-bootstrap';
import {
  SchemeGroupDetailsField
} from '../SchemeGroupDetailsField/SchemeGroupDetailsField';
import React from 'react';
import { ButtonBar } from '../../../../../../common/components';
import { Button } from '../../../../../../common/components/Button/Button';

export const SchemeGroupDetailsFieldArray = ({
  fields,
  schemeType,
  quoteType,
  renews
}) => {

  //const schemeType =
  const remove = (index) => {
    fields.remove(index);
  };

  const add = () => {
    fields.push({
      cover_level: '',
      name: '',
      quote_type: quoteType,
      renews: renews,
      scheme_type: schemeType,
      status: 'active'
    });
  };
  return (
    <>

      <FormGroup>
        <Col sm={12}>
          <ControlLabel>
            Scheme Details
          </ControlLabel>
        </Col>
      </FormGroup>
      {fields.map((field, index) => {
        return (
          <SchemeGroupDetailsField
            field={field}
            onRemove={remove}
            index={index}


          />
        );
      })}
      <Row>

        <Col sm={12}>
          <ButtonBar>
            <Button
              bsStyle={'action'}
              className={'pull-right'}
              handleClick={add}
            >
              Add Another Cover Level
            </Button>

          </ButtonBar>
        </Col>
      </Row>

    </>
  );

};

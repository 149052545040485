import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { FormGroup } from 'react-bootstrap';
import { fetchSearchResults, clearSearchResults } from '../../redux/dashboardActions';
import { FormattedDate, FormattedTime } from 'react-intl';
import { debounce } from 'lodash';
import './QuickSearch.scss';

export class QuickSearch extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    dashboard: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.props.dispatch(fetchSearchResults(''));
  }

  componentWillMount() {
    this.search = debounce(this.search, 250);

    // We should bind `this` to click event handler right here
    this.clearResults = ::this.clearResults;
  }

  componentDidMount() {
    // Hide dropdown block on click outside the block
    window.addEventListener('click', this.clearResults, false);
  }

  componentWillUnmount() {
    this.props.dispatch(fetchSearchResults(''));

    // Remove click event listener on component unmount
    window.removeEventListener('click', this.clearResults, false);
  }

  clearResults() {
    this.props.dispatch(clearSearchResults());
  }

  search(value) {
    this.props.dispatch(fetchSearchResults(value));
  }

  renderResult(item) {
    return (
      <Link
        to={
          item.policy_number
            ? '/policies/issued/' + item.id
            : '/policies/applications/' + item.id
        }
      >
        <div className="row">
          <div className="col-sm-2">
            <strong>
              {item.policy_number ? item.policy_number : item.quote_reference}
            </strong>
          </div>
          <div className="col-sm-2">
            <strong>{item.name}</strong>
          </div>
          <div className="col-sm-2">
            <strong>{item.product}</strong>
          </div>
          <div className="col-sm-4">
            <strong>{item.broker}</strong>
          </div>
          <div className="col-sm-2">
            <strong>
              <FormattedDate
                value={item.issued_at ? item.issued_at : item['@created_at']}
              />
              &nbsp;
              <FormattedTime
                value={item.issued_at ? item.issued_at : item['@created_at']}
              />
            </strong>
          </div>
        </div>
      </Link>
    );
  }

  render() {
    const { dashboard } = this.props;
    let policyApplications = dashboard.search_results.meta.policy_applications;
    let policies = dashboard.search_results.meta.policies;
    return (
      <div className="quick-search-container">
        <div className="search-input">
          <FormGroup controlId="quick-search">
            <input
              type="text"
              className="quick-search form-control"
              placeholder="Search..."
              onChange={(event) => this.search(event.target.value)}
            />
          </FormGroup>
        </div>
        {(policies.length > 0 || policyApplications.length > 0) && (
          <div className="search-results-panel">
            {policies.length > 0 && (
              <div>
                <strong>Policies:</strong>
                <br />
                <br />
                <ul className="results-list">
                  {policies.map((result, i) => (
                    <li key={i}>{this.renderResult(result)}</li>
                  ))}
                </ul>
              </div>
            )}
            {policyApplications && policyApplications.length > 0 && (
              <div>
                <strong>Quotes:</strong>
                <br />
                <br />
                <ul className="results-list">
                  {policyApplications.map((result, i) => (
                    <li key={i}>{this.renderResult(result)}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dashboard: state.dashboard,
  };
};
export default connect(mapStateToProps)(QuickSearch);

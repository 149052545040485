export const SET_CONTENT = 'socrates-online/content/SET_CONTENT';
export const SET_KEY_PERSONNEL = 'socrates-online/content/SET_KEY_PERSONNEL';
export const IS_UPDATING_KEY_PERSONNEL =
  'socrates-online/content/IS_UPDATING_KEY_PERSONNEL';
export const SET_PARTNERS = 'socrates-online/content/SET_PARTNERS';
export const IS_UPDATING_PARTNERS = 'socrates-online/content/IS_UPDATING_PARTNERS';
export const IS_FETCHING_SIGNPOSTS = 'socrates-online/content/IS_FETCHING_SIGNPOSTS';

const initialState = {
  types: {
    announcement: 'Announcement',
    page: 'Page',
    article: 'News Article',
    email: 'Email',
    document: 'Document',
    endorsement: 'Endorsement',
  },
  keyPersonnel: [],
  isUpdatingKeyPersonnel: false,
  partners: [],
  isUpdatingPartners: false,
  resources: [],
  isFetchingSignposts: false,
};

/**
 * The content reducer
 * @param state
 * @param action
 * @returns {{}}
 */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CONTENT:
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.data.data.id]: {
            ...action.data,
          },
        },
      };
    case IS_UPDATING_KEY_PERSONNEL:
      return {
        ...state,
        isUpdatingKeyPersonnel: action.status,
      };
    case SET_KEY_PERSONNEL:
      return {
        ...state,
        keyPersonnel: action.data,
        isUpdatingKeyPersonnel: false,
      };
    case IS_UPDATING_PARTNERS:
      return {
        ...state,
        isUpdatingPartners: action.status,
      };
    case SET_PARTNERS:
      return {
        ...state,
        partners: action.data,
        isUpdatingPartners: false,
      };
    case IS_FETCHING_SIGNPOSTS:
      return {
        ...state,
        isFetchingSignposts: action.status,
      };
    default:
      return state;
  }
}

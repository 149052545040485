export const IS_SUBMITTING = 'socrates-online/customer/IS_SUBMITTING';
export const SET_CUSTOMER = 'socrates-online/customer/SET_CUSTOMER';
export const CLEAR_DATA = 'socrates-online/customer/CLEAR_DATA';
export const SUBMIT_SUCCEEDED = 'socrates-online/customer/SUBMIT_SUCCEEDED';

export const initialState = {
  isSubmitting: false,
  resources: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.status,
      };
    case SUBMIT_SUCCEEDED:
      return {
        ...state,
        submitSucceeded: action.status,
      };
    case SET_CUSTOMER:
      return {
        ...state,
        resources: {
          [action.data.data.id]: {
            ...action.data,
          },
        },
      };
    case CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';

const PercentageFormat = ({ data }) => {
  const percentageString = data + '%';
  return <span>{data ? percentageString : ''}</span>;
};

PercentageFormat.propTypes = {
  data: PropTypes.string,
};

export default PercentageFormat;

import React from 'react';
import InternalContainer from '../../layout/containers/Internal';
import TrackingLinkDetails from '../components/TrackingLinkDetails';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as trackingLinksActions } from '../redux/TrackingLinkReducer';
import { get } from 'lodash';
import { Loader, Button } from '../../../common/components';

class TrackingLinksShowContainer extends React.Component {
  componentWillMount() {
    const { actions } = this.props;
    actions.track.getTrackingLink(this.props.params.id);
    actions.track.getChannels();
  }

  render() {
    const { trackingLinks } = this.props;
    const resource = get(trackingLinks, 'resources[' + this.props.params.id + ']');
    return (
      <InternalContainer title={'Tracking Link'}>
        {resource ? (
          <TrackingLinkDetails {...this.props} resource={resource} />
        ) : (
          <Loader />
        )}
        <div className="btn-block">
          <Button buttonClass="default" link to="/admin/tracking-links">
            Back
          </Button>
        </div>
      </InternalContainer>
    );
  }
}

export default connect(
  (state) => ({
    trackingLinks: state.trackingLinks,
  }),
  (dispatch) => ({
    actions: {
      track: bindActionCreators({ ...trackingLinksActions }, dispatch),
    },
  }),
)(TrackingLinksShowContainer);

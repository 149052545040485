import { Button } from '../../../../common/components';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

const NavButton = ({ to, show, label }) => {
  if (!show) {
    return null;
  }

  return (
    <Button
      className="btn-blue pad-right"
      link
      to={to}
    > {label}
    </Button>
  );

};

const ProductNavigation = ({ id, currentLocation, isWhitelabel }) => {

  return (
    <>
      <Row>
        <Col sm={12}>

          <NavButton
            show={currentLocation !== `/admin/products/${id}/scheme-management`}
            label={'Scheme Management'}
            to={`/admin/products/${id}/scheme-management`}
          />
          <NavButton
            show={currentLocation !== `/admin/products/${id}/product-management`}
            label={' Product Management'}
            to={`/admin/products/${id}/product-management`}
          />
          <NavButton
            show={currentLocation !== `/admin/products/${id}/documents`}
            label={'Static Documents'}
            to={`/admin/products/${id}/documents`}
          />
          <NavButton
            show={currentLocation !== `/admin/products/${id}/dynamic-documents`}
            label={'Dynamic Documents'}
            to={`/admin/products/${id}/dynamic-documents`}
          />
          <NavButton
            show={currentLocation !== `/admin/products/${id}/document-pack`}
            label={'Document Pack'}
            to={`/admin/products/${id}/document-pack`}
          />
          <NavButton
            show={currentLocation !== `/admin/products/${id}/brochure-content`}
            label={'Brochure Content'}
            to={`/admin/products/${id}/brochure-content`}
          />

          <NavButton
            show={!isWhitelabel && currentLocation !== `/admin/products/${id}/covid-message-content`}
            label={'Covid Message'}
            to={`/admin/products/${id}/covid-message-content`}
          />

        </Col>
      </Row>

    </>
  );

};
export default connect()(ProductNavigation);

import React from 'react';
import { Checkbox, FormControl, HelpBlock, Radio } from 'react-bootstrap';

const FieldFormControl = ({
  placeholder,
  type,
  input,
  componentClass,
  className = '',
  style,
  meta,
  value,
  ...props
}) => {
  const classes = className + (meta.dirty ? ' dirty-field' : '') + ' sessioncamhidetext';
  let component = null;

  switch (type) {
    case 'checkbox':
      component = <Checkbox style={style} className={classes} {...input} {...props} />;
      break;
    case 'radio':
      component = (
        <Radio style={style} className={classes} value={value} {...input} {...props} />
      );
      break;
    default:
      component = (
        <FormControl
          style={style}
          type={type}
          placeholder={placeholder}
          componentClass={componentClass}
          className={classes}
          {...input}
          {...props}
        />
      );
      break;
  }

  return (
    <div className={meta.touched && meta.error ? 'has-error' : ''}>
      {component}
      {meta.touched && meta.error && <HelpBlock>{meta.error}</HelpBlock>}
    </div>
  );
};

export default FieldFormControl;

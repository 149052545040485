import React from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { Col, ControlLabel, Row } from 'react-bootstrap';
import { Button, SelectDocuments } from '../../../../common/components';

import { compose } from 'redux';
import { connect } from 'react-redux';

const DocumentPacksForm = ({
  handleSubmit,
  onUpdateDocumentPack,
  submitting,
  formValues,
}) => {
  return (
    <div className="product-container">
      <Form onSubmit={handleSubmit(onUpdateDocumentPack)}>
        {formValues?.map((pack, index) => {
          return (
            <div className="" key={index}>
              <Row>
                <Col sm={12}>
                  <h3 className="capitalise">{pack.attributes.for} pack</h3>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <ControlLabel>
                    {'Documents to be included in the ' +
                      pack.attributes.for +
                      ' document pack'}
                  </ControlLabel>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Field
                    name={`${index}.relationships.scheme_documents.data`}
                    component={SelectDocuments}
                  />
                </Col>
              </Row>

              <div className="hr transparent"/>
            </div>
          );
        })}
        <div className="divider margin-bottom"/>
        <Row>
          <Col sm={12}>
            <Button bsStyle="default" link={true} to={'/admin/products'}>
              Back
            </Button>
            <Button
              bsStyle="primary"
              className="pull-right"
              type="submit"
              isLoading={submitting}
            >
              Save Changes
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const FORM_NAME = 'DocumentPacksForm';
export default compose(
  connect((state, ownProps) => {
    return {
      initialValues: ownProps.documentPacks,
      formValues: getFormValues(FORM_NAME)(state),
    };
  }),
  reduxForm({
    form: FORM_NAME,
  }),
)(DocumentPacksForm);

import React from 'react';

import { connect } from 'react-redux';
import { reduxForm, Field, getFormValues } from 'redux-form';
import {
  Button,

  DatePickerDob,
  HorizontalFormControl,
  SelectCurrency,
  SelectBroker, ButtonBar,
} from '../../../../common/components';

import moment from 'moment/moment';
import {  compose } from 'redux';

import { Modal } from 'react-bootstrap';
import { required } from 'redux-form-validators';

const FORM_NAME = 'makeAdjustmentForm';

const MakeAdjustmentForm = ({
  accountId,
  onPostAdjustment,
  handleSubmit,
  submitting,
}) => {

  const update = (values) => {
    return onPostAdjustment(values);
  };

  return (

    <div>
      <form onSubmit={handleSubmit(update)}>


        <Field
          async
          name={'data.relationships.broker.data.id'}
          key={'select-broker-' + accountId}
          label="Broker"
          labelSize={3}
          component={SelectBroker}
          filters={{ account: accountId }}
        />
        <hr/>
        <Field
          name={'data.attributes.date'}
          label="Date"
          labelSize={3}
          maxDate={moment().format()}
          component={DatePickerDob}
          validate={required({ msg:'Date is required' })}
        />
        <Field
          name={'data.attributes.description'}
          label="Description"
          labelSize={3}
          component={HorizontalFormControl}
        />
        <Field
          name={'data.attributes.currency'}
          label="Currency"
          labelSize={3}
          defaultValue={{ value: 'GBP', label: 'Pound sterling' }}
          component={SelectCurrency}
        />
        <Field
          name={'data.attributes.amount'}
          label="Amount"
          labelSize={3}
          currency
          component={HorizontalFormControl}
          validate={required({ msg: 'Amount is required' })}
        />
        <Modal.Footer>
          <ButtonBar>
            <Button
              type="submit"
              bsStyle="primary"
              isLoading={submitting}
              label="Save Adjustment"
            />
          </ButtonBar>


        </Modal.Footer>
      </form>
    </div>

  );
};



export default compose(
  connect(
    (state, ownProps) => ({

      formValues: getFormValues(FORM_NAME)(state),
      initialValues: {
        data: {
          relationships: {
            broker: {
              data: {
                id: '',
              },
            },
          },
          attributes: {
            date: '',
            currency: 'GBP',
            amount: ownProps.amount || 0,
            metadata: {
              match_item: true,
            },
          },
        },
      },
    }),
  ),
  reduxForm({ form: FORM_NAME })
)(MakeAdjustmentForm);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, DataTable } from '../../../../common/components';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { FormattedDate, FormattedTime } from 'react-intl';
import { refreshData } from '../../../../common/components/DataTable/redux/dataTableActions';

export default class AccountAudit extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
  };

  formatDate(cell) {
    return (
      <span>
        <FormattedDate value={new Date(cell)} /> <FormattedTime value={new Date(cell)} />
      </span>
    );
  }

  handleRowClick() {
    //this.props.dispatch(push('/admin/users/' + row.id));
    return false;
  }

  refreshList() {
    this.props.dispatch(refreshData('audit'));
  }

  render() {
    const { resource } = this.props;

    return (
      <div>
        <Box>
          <h2 className="resource-name">{resource.name}</h2>

          <DataTable
            source={'/events'}
            autoFilters={[
              { type: 'filter[resource]', value: this.props.resource.data.id },
              { type: 'related', value: true },
            ]}
            name="audit"
            showTotal
            onRowSelect={this.handleRowClick.bind(this)}
          >
            <TableHeaderColumn dataField="id" isKey hidden />
            <TableHeaderColumn
              dataField="occurredAt"
              dataFormat={this.formatDate}
              width={'180px'}
              dataSort
            >
              Occurred at
            </TableHeaderColumn>
            <TableHeaderColumn dataField="action" width={'180px'} dataSort>
              Action
            </TableHeaderColumn>
            <TableHeaderColumn dataField="description" width={'550px'} dataSort>
              Description
            </TableHeaderColumn>
            <TableHeaderColumn dataField="user_name">User</TableHeaderColumn>
          </DataTable>
        </Box>
      </div>
    );
  }
}

import React from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Icon } from '../../../../common/components';
import { Row,  Col } from 'react-bootstrap';
import displayName from '../../../../helpers/displayName';
import './styles.scss';

const LedgerEntry = ( { item, selected, onItemSelect }) => {

  const onSelect = () => {
    onItemSelect(item);
  };

  return (
      <Row className="sortable-container-item" onClick={onSelect}>
        {selected && (
          <Col sm={2} md={1}>
            <Icon name="angle-double-left"/>
          </Col>
        )}
        <Col sm={4} md={7}>
          <div className="header">
            {item.attributes.policy_number && (
            <strong>
              {item.attributes.policy_number}
            </strong>
            )}
            {item.attributes.premium_element_type ? (
              ' (' + displayName(item.attributes.premium_element_type) + ')'
            ) : (
              <span>{item.attributes.amount > 0 ? 'Payment' : 'Receipt'}</span>
            )}
          </div>
          <div className="description">
            <FormattedDate value={item.attributes.created_at} />{' '}
            <FormattedTime value={item.attributes.created_at} /> | {item.attributes.summary}
          </div>
          {!item.attributes.policy_number && 'premium' === item.attributes.entry_type && (
          <div className="description">
            Journal: {item.attributes?.journal_description}
          </div>
          )}
        </Col>
        <Col sm={6} md={4}>
          <span
            className={
              'pull-right item-amount-' + (item.attributes.amount > 0 ? 'dr' : 'cr')
            }
          >
            <strong>
              {item.attributes.currency} {item.attributes.formatted_amount}
            </strong>
          </span>

        </Col>
        {!selected && (
          <Col sm={2} md={1}>
            <Icon name="angle-double-right"/>
          </Col>
        )}
      </Row>
  );

};

export default LedgerEntry;

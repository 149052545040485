import React, { Component } from 'react';
import { Link } from 'react-router';
import { get, startCase, map, replace, compact, indexOf, size, first, capitalize } from 'lodash';
import { Row, Col } from 'react-bootstrap';
import { InfoLabel } from '../../../../../../common/components';
import displayName from '../../../../../../helpers/displayName';
import DeclarationQuestions from '../../../DeclarationQuestions';
import getStatus from '../../../../../../schemas/policy';
import Humanize from 'humanize-plus';

class Summary extends Component {
  getStatus(status) {
    const { resource } = this.props;
    if (resource.quote_type === 'mid term adjustment' && status === 'quote') {
      return 'awaiting approval';
    }
    return getStatus(status);
  }

  getQuoteDate(premiums) {
    let quoteDate = '';
    Array.isArray(premiums) &&
      premiums.map((premium) => {
        if (premium.attributes.premium_type === 'initial premium') {
          quoteDate = premium.attributes.created_at;
        }
      });
    return quoteDate;
  }

  render() {
    const { resource, policy, premiums, product } = this.props;
    const activities = get(resource, 'metadata.activities', []);
    const resultActivities = activities && Humanize.oxford(activities);

    const optionsMetadata = get(resource, 'metadata.options', []);
    const optionsToIgnore = ['hazardous_activities', 'Legal Fees'];
    const optionsFiltered = map(optionsMetadata, (myOption, index) => {
      const optionName = startCase(replace(index, '_', ' '));
      return myOption && indexOf(optionsToIgnore, index) === -1 ? optionName : null;
    });
    const optionsCompact = compact(optionsFiltered);
    const policyOptions = optionsCompact && Humanize.oxford(optionsCompact);
    const activityPacks = {
      pack1: 'Activity Pack 1',
      pack2: 'Activity Pack 2',
      pack3: 'Activity Pack 3',
      pack4: 'Activity Pack 4',
    };
    const hazardousActivities = activityPacks[get(optionsMetadata, 'hazardous_activities')];
    const regions = get(product, 'data.attributes.metadata.regions', {
      uk: 'United Kingdom',
      europe_excluding: 'Europe Excluding',
      europe: 'Europe',
      australia_new_zealand: 'Australia and New Zealand',
      worldwide_excluding: 'Worldwide Excluding',
      worldwide: 'Worldwide',
    });
    const region = get(resource, 'metadata.region');
    const destinations = get(resource, 'metadata.destinations', []) || [];

    const destination =
      regions[region] ||
      destinations.map((destination, i) => {
        let detail = i ? ', ' : '';
        return (
          detail +
          (get(destination, 'attributes.name', false)
            ? get(destination, 'attributes.name')
            : destination)
        );
      });

    const isAnnual = resource.metadata.scheme_type === 'annual';
    let renewalStatus = '';
    if (isAnnual) {
      if (resource.policy_number) {
        renewalStatus = resource.renewal_status;
      }
      if (resource.quote_type === 'renewal') {
        renewalStatus = policy.data.attributes.renewal_status;
      }
    }

    const productCode = product.data.attributes.product_code;

    let travellerGroup = get(resource, 'metadata.traveller_group');

    if (get(resource, 'metadata.traveller_group') === 'individual' && productCode === 'CPI') {
      travellerGroup = 'individual booking';
    }

    return (
      <div className="form-horizontal summary-section">
        <Row>
          <Col sm={12} className="section-title">
            <h4>Summary</h4>
          </Col>
          <Col sm={6}>
            {get(policy, 'data.attributes') && (
              <InfoLabel
                labelSize={4}
                label="Policy"
                value={
                  <Link to={'/policies/issued/' + policy.data.id}>
                    {policy.data.attributes.policy_number +
                      ' (' +
                      this.getStatus(policy.data.attributes.status) +
                      ')'}
                  </Link>
                }
                wrapperClass={'clear-margin'}
              />
            )}
            <InfoLabel
              label="Start Date"
              date
              value={get(resource, 'starts_at', get(resource, 'commences_at'))}
              labelSize={4}
              wrapperClass={'clear-margin'}
            />
            <InfoLabel
              label="End Date"
              date
              value={get(resource, 'expires_at', get(resource, 'ends_at'))}
              labelSize={4}
              wrapperClass={'clear-margin'}
            />
            <InfoLabel
              label="Destination"
              labelSize={4}
              value={destination}
              wrapperClass={'clear-margin'}
            />
            {productCode !== 'CSP' && (
              <InfoLabel
                label="Traveller Group"
                value={displayName(travellerGroup, true)}
                labelSize={4}
                wrapperClass={'clear-margin'}
              />
            )}
          </Col>
          <Col sm={6}>
            <InfoLabel
              label="Scheme"
              value={
                size(premiums) === 1
                  ? get(first(premiums), 'attributes.scheme.name', '-')
                  : '-'
              }
              labelSize={4}
              wrapperClass={'clear-margin'}
            />
            <InfoLabel
              label="Channel"
              value={get(resource, 'channel_name')}
              labelSize={4}
              wrapperClass={'clear-margin'}
            />
            <InfoLabel
              label="Quote Date"
              date
              value={this.getQuoteDate(premiums)}
              labelSize={4}
              wrapperClass={'clear-margin'}
            />
            <InfoLabel
              label="Issue Date"
              date
              value={resource.issued_at}
              labelSize={4}
              wrapperClass={'clear-margin'}
            />
            {policyOptions && (
              <InfoLabel
                label="Policy options"
                value={policyOptions}
                labelSize={4}
                wrapperClass={'clear-margin'}
              />
            )}
            {hazardousActivities && (
              <InfoLabel
                label="Hazardous Activities"
                value={hazardousActivities}
                labelSize={4}
                wrapperClass={'clear-margin'}
              />
            )}
            {resultActivities && (
              <InfoLabel
                label="Activities"
                value={resultActivities}
                labelSize={4}
                wrapperClass={'clear-margin'}
              />
            )}
            {renewalStatus &&
            <div>
              <InfoLabel
                label="Renewal Status"
                value={capitalize(renewalStatus)}
                labelSize={4}
              />
            </div>
            }
            {isAnnual && resource.policy_number &&
            <div>
              <InfoLabel
                label="Term Number"
                value={resource.term_number}
                labelSize={4}
              />
            </div>
            }
          </Col>
          {productCode !== 'TUMC' && productCode !== 'CSP' && (
            <Col sm={12}>
              <DeclarationQuestions
                wrapperClass={'clear-margin declaration-display'}
                declaration={get(resource, 'metadata.medical_declaration')}
                questions={get(resource, 'metadata.medical_declaration_questions', [])}
              />
            </Col>
          )}
        </Row>
        <div className="hr" />
      </div>
    );
  }
}

export default Summary;

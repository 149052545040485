import React, { Component } from 'react';
import { Col, ControlLabel, FormGroup, Row } from 'react-bootstrap';
import {
  Button,
  ButtonBar,
  DatePicker,
  InfoButton
} from '../../../../../../common/components/index';
import { Field, FormSection, formValueSelector, reduxForm } from 'redux-form';
import {
  SelectField,
} from '../../../../../../common/components/ReduxFormField';
import { get } from 'lodash';
import mapReactSelect from '../../../../../../helpers/mapReactSelect';
import moment from 'moment';
import { hasPermission } from '../../../../../auth/redux/authActions';
import { SelectRegion } from '../../ReduxForm';
import { COL_WIDTHS } from './TravellersTab02';
import CompanyDetails from '../../../CompanyDetails';
import GroupTravellerNumbers from '../../../GroupTravellerNumbers';
import { connect } from 'react-redux';
import selectTheme, { styles } from '../../../../../../helpers/selectTheme';
import Select from 'react-select';

const META = 'data.attributes.metadata';

const AnnualDatePicker = ({ isExpired, minDate, maxDate }) => {
  if (isExpired) {
    return (
      <Field
        name="data.attributes.metadata.start_date"
        id="start_date"
        component={DatePicker}
      />
    );
  }
  return (
    <Field
      name="data.attributes.metadata.start_date"
      id="start_date"
      minDate={minDate}
      maxDate={maxDate}
      component={DatePicker}
    />
  );
};

const backDateCover = (product) => {
  if (hasPermission('policy.backdate_cover')) {
    const backdateCover = get(
      product,
      'data.attributes.metadata.backdate_cover',
      0,
    );
    return moment()
      .subtract(backdateCover, 'days');
  }
  return moment();
};

export class CSPDetailsTab extends Component {

  getRegion() {
    const { currentProduct, formValues } = this.props;

    if (['FPS', 'FPR', 'NPO', 'NPOR', 'CSPA', 'CSPAR', 'CSS', 'CSSR'].includes(currentProduct.data.attributes.product_code)) {
      return get(currentProduct, `${META}.definitions.cover_choice_amt_worldwide`);
    }

    const region = get(formValues, `${META}.region`, '');
    return get(
      currentProduct,
      `${META}.definitions.region_` + region,
      get(currentProduct, `${META}.definitions.region`),
    );
  }

  render() {
    const { mta, formValues, currentProduct, handleSubmit, isExpired, nextTab, openSpecificTab, dispatch, formState } = this.props;

    let advancedDays = 30;
    const backdateCoverDate = backDateCover(currentProduct).format();
    const definitions = get(currentProduct, `${META}.definitions`, { });
    const annualMaxDate = moment().add(advancedDays, 'days').format();
    const regions = mapReactSelect(currentProduct.data.attributes.metadata.regions || {});
    const residencies = mapReactSelect(get(currentProduct, `${META}.residencies`, []));
    const regionsInfo = this.getRegion();

    return (
      <div className="fadein-fields">
        {mta && (
          <FormGroup>
            <Col md={4} lg={3} componentClass={ControlLabel}>
              <InfoButton content={definitions.trip_type}/> Effective Date
            </Col>
            <Col md={8} lg={6}>
              <Field
                name="data.attributes.effective_date"
                minDate={moment().format()}
                component={DatePicker}
              />
            </Col>
          </FormGroup>
        )}
        <FormGroup>
          <Col md={4} lg={3} componentClass={ControlLabel}>
            <InfoButton content={definitions.residence}/> Residence
          </Col>
          <Col md={8} lg={6}>
            <Field
              name="data.attributes.metadata.residency"
              component={SelectField}
              options={residencies}
            />
          </Col>
        </FormGroup>
        <FormGroup>
          <Col md={4} lg={3} componentClass={ControlLabel}>
            <InfoButton content={definitions.travel_dates_annual}/> Inception date
          </Col>
          <Col md={8} lg={9}>
            <AnnualDatePicker
              minDate={backdateCoverDate}
              maxDate={annualMaxDate}
              isExpired={isExpired}
            />
          </Col>
        </FormGroup>
        <FormGroup>
          <Col md={4} lg={3} componentClass={ControlLabel}>
            <InfoButton content={regionsInfo}/> What region are you going to?
          </Col>
          <Col md={8} lg={6} className={'select-region'}>
            {regions.length === 1 ? (
              <Select
                defaultValue={regions[0]}
                styles={styles}
                theme={(theme) => selectTheme(theme)}
                isDisabled={true}
              />
            ) : (
              <Field
                name="data.attributes.metadata.region"
                component={SelectRegion}
                options={regions}
              />
            )}
          </Col>
        </FormGroup>
        <hr/>
        <FormSection name={META}>
          <div className="form-horizontal">
            <CompanyDetails
              dispatch={dispatch}
              change={this.props.change}
              formValues={get(formValues, 'data.attributes.metadata')}
              currentProduct={currentProduct}
              productCode={get(currentProduct, 'data.attributes.product_code')}
            />
          </div>
          <hr/>
          <FormGroup>
            <Col sm={COL_WIDTHS.labels.sm} md={COL_WIDTHS.labels.md} lg={COL_WIDTHS.labels.lg} componentClass={ControlLabel}>
              <InfoButton content={definitions.group_type}/> Number of members
            </Col>
            <Col sm={COL_WIDTHS.fields.sm} md={COL_WIDTHS.fields.md} lg={COL_WIDTHS.fields.lg}>
              <GroupTravellerNumbers formState={formState}/>
            </Col>
          </FormGroup>
        </FormSection>
        <hr/>
        <Row>
          <Col sm={12}>
            <ButtonBar>
              <Button
                label="Back"
                id="back"
                className="btn-action-back pull-left"
                bsStyle="primary"
                handleClick={() => openSpecificTab(1)}
              />
              <Button
                label="Next"
                id="next"
                className="pull-right btn-action-blue"
                bsStyle="primary"
                handleClick={() => dispatch(handleSubmit(() => openSpecificTab(nextTab)))}
              />
            </ButtonBar>
          </Col>
        </Row>
      </div>
    );
  }
}

const FORM_NAME = 'LeisureTravelForm';
const form = reduxForm({ form: FORM_NAME })(CSPDetailsTab);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  const values = selector(
    state,
    'data.id',
    'meta.purchase_declaration',
    'data.attributes.metadata',
  );

  return {
    auth: state.auth,
    initialValues: props.resource,
    form: 'LeisureTravelForm',
    formValues: values,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    product: state.product,
  };
};

export default connect(mapStateToProps)(form);

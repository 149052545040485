import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, DataTable, Button } from '../../../../common/components';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Row, Col, FormControl, ControlLabel } from 'react-bootstrap';
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions';
import { openModal } from '../../../../common/components/Modal/ModalRedux';
import { LedgerEntriesModal } from '../LedgerEntriesModal';
import selectTheme, { styles } from '../../../../helpers/selectTheme';
import Select from 'react-select';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';

export default class AccountJournalList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      journal: {},
    };
  }

  formatDate(cell) {
    return (
      <span>
        <FormattedDate value={new Date(cell)} />
      </span>
    );
  }

  formatDateTime(cell) {
    return (
      <span>
        <FormattedDate value={new Date(cell)} /> <FormattedTime value={new Date(cell)} />
      </span>
    );
  }

  formatBool(cell, row) {
    return <span>{row.requires_approval ? 'Approval required' : 'Approved'}</span>;
  }

  handleSearchUpdate(event) {
    this.props.dispatch(
      applyFilter('journals', 'filter[description]', event.target.value),
    );
  }

  handleJournalTypeUpdate(option) {
    this.props.dispatch(applyFilter('journals', 'filter[manual]', option.value));
  }

  handleRowClick(row) {
    this.setState({ journal: row });
    this.props.dispatch(openModal(LedgerEntriesModal.MODAL_IDENTIFIER));
  }

  render() {
    return (
      <Box>
        <h2>Journals</h2>
        <Row>
          <Col sm={4}>
            <ControlLabel className="filter-label">Description</ControlLabel>
            <FormControl onChange={::this.handleSearchUpdate} />
          </Col>
          <Col sm={2}>
            <ControlLabel className="filter-label">Type</ControlLabel>
            <Select
              theme={(theme) => selectTheme(theme)}
              styles={styles}
              onChange={(option) => this.handleJournalTypeUpdate(option)}
              options={[
                { label: 'Manual', value: 'true' },
                { label: 'System', value: 'false' },
                { label: 'All', value: 'all' },
              ]}
              defaultValue={{ label: 'Manual', value: 'true' }}
            />
          </Col>
        </Row>

        <Row className="data-table-row">
          <Col xs={12}>
            <DataTable
              source="/accounts/journals"
              name="journals"
              autoFilters={[{ type: 'filter[manual]', value: true }]}
              showTotal
              onRowSelect={this.handleRowClick.bind(this)}
            >
              <TableHeaderColumn dataField="id" isKey hidden />

              <TableHeaderColumn dataField="description" width={'16.66%'} dataSort>
                Description
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="requires_approval"
                width={'16.66%'}
                dataFormat={this.formatBool}
                dataSort
              >
                Requires Approval
              </TableHeaderColumn>

              <TableHeaderColumn dataField="journal_size" width={'16.66%'}>
                No. Entries
              </TableHeaderColumn>

              <TableHeaderColumn dataField="user_name" width={'16.66%'}>
                User
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="date"
                dataFormat={this.formatDate}
                width={'16.66%'}
                dataSort
              >
                Journal Date
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="created_at"
                dataFormat={this.formatDateTime}
                width={'16.66%'}
                dataSort
              >
                Created At
              </TableHeaderColumn>
            </DataTable>
            <LedgerEntriesModal
              dispatch={this.props.dispatch}
              journal={this.state.journal}
            />

            <Row>
              <Col sm={12}>
                <InternalButtonBar buttons={[
                  {
                    permissions: ['account_ledger.journal_create'],
                    button: (
                      <Button
                        className="pull-right"
                        link
                        to="/accounts/journal/new"
                        label="New Manual Journal"
                        bsStyle="primary"
                      />
                    )
                  }
                ]}/>
              </Col>
            </Row>
          </Col>
        </Row>
      </Box>
    );
  }
}

import React from 'react';
import './styles.scss';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';

const CheckboxGroup = ({
                         input,
                         label,
                         labelSize = 3,
                         infoButton,
                         options,
                         inline,
                         meta: { touched, error },
                       }) => {
  const renderCheckBoxGroup = () => {
    return (<div>
        <div className={'radio-group'}>
          {options.map((option, key) => {
            return (
              <div className={'radio-container' + (inline && ' inline')} key={key}>
                <div className={'form-radio'}>
                  <input
                    {...input}
                    type="checkbox"
                    id={input.name + option.value}
                    checked={input.value === option.value}
                  />
                  <label htmlFor={input.name + option.value}/>
                </div>
                <div
                  className="radio-label"
                  onClick={() => input.onChange(!input.value)}
                >
                  {option.label}
                </div>
              </div>
            );
          })}

        </div>
        <div>
          {touched && error && (
            <span className="input-error">{error}</span>
          )}
        </div>
      </div>
    );
  };

  if (label) {
    return (
      <FormGroup className={(touched && error) ? 'has-error' : ''}>
        <Col sm={labelSize}>
          <ControlLabel className={inline && ' inline-radio-label'}>
            {label}
          </ControlLabel>{' '}
          {infoButton}
        </Col>
        <Col sm={12 - labelSize}>
          {renderCheckBoxGroup()}
        </Col>
      </FormGroup>
    );
  }

  return renderCheckBoxGroup();
};

export default CheckboxGroup;

import React from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { InfoButton } from '../../../../../../common/components';
import { Col, Row } from 'react-bootstrap';
import Loader from '../../../../../../common/components/Loader/Loader';
import OptionsCheckboxes
  from '../../../LeisureTravelForm/Components/OptionsCheckboxes/OptionsCheckboxes';
import {
  HazRadioGroup,
  OptionsCheckboxesRedux
} from '../../../LeisureTravelForm/ReduxForm';

export const FORM_NAME = 'WhitelabelTravelForm';

const Container = ({ children }) => (
  <div>
    <h4 className="title-options">Optional Cover</h4>
    <div className="container-fluid">
      {children}
    </div>
  </div>
);

const BooleanOption = ({ option, definitions, hasWinterSportsExclusion }) => {

  if (option.auto_include) {
    return (
      <OptionsCheckboxes
        index={option.key}
        name={`data.attributes.metadata.options.${option.key}`}
        disableWinter={hasWinterSportsExclusion}
        infoButton={<InfoButton content={definitions?.[option.key]}/>}
        options={[
          {
            label: option.name,
            value: true,
          },
        ]}
        readOnly
        value={true}

      />
    );

  }

  return (
    <Field
      key={option.key}
      name={`data.attributes.metadata.options.${option.key}`}
      component={OptionsCheckboxesRedux}
      disableWinter={hasWinterSportsExclusion}
      infoButton={<InfoButton large content={definitions?.[option.key]}/>}
      options={[
        {
          label: option.name,
          value: true,
        },
      ]}
    />
  );

};

const OptionsTab = ({
  definitions,
  selectedScheme,
  //productOptions,
  hasWinterSportsExclusion,
  isCalculating,
  //referrals
}) => {

  const allOptions = selectedScheme?.meta?.information?.available_options ?? {};
  const sortedOptions = Object.keys(allOptions).reduce((carry, optionKey) => {
    const option = allOptions[optionKey];
    if (option.name === 'winter_sports' && hasWinterSportsExclusion) {
      return carry;
    }
    if (option.input_type === 'enum') {
      carry.enumOptions.push(option);
    }
    if (option.input_type === 'boolean') {
      carry.booleanOptions.push(option);
    }


    return carry;
  }, {
    booleanOptions: [],
    enumOptions: []
  });
  const booleanOptions = sortedOptions.booleanOptions;
  const enumOptions = sortedOptions.enumOptions;
  //const selectedOptions = formValues?.data?.attributes?.metadata?.options;
  if (isCalculating) {
    return (
      <Container>
        <Loader/>
      </Container>
    );

  }


  return (
    <Container>
      <Row>
        <Col sm={12}>
          {booleanOptions?.map((option) => {
            //const option = allOptions[optionKey];
            return (
              <BooleanOption
                option={option}
                hasWinterSportsExclusion={hasWinterSportsExclusion}
                definitions={definitions}
              />

            );
          })}
        </Col>
      </Row>


      {enumOptions?.map((enumOption) => {

        return (
          <Row key={enumOption.key}>
            <Col sm={12}>
              <h4 className="title-options">{enumOption.name}</h4>
            </Col>
            <Col sm={12}>
              <div key={enumOption.key}>
                <Field
                  name={`data.attributes.metadata.options.${enumOption.key}`}
                  component={HazRadioGroup}
                  options={enumOption.options?.map((opt) => {

                    return {
                      label: opt?.name,
                      value: opt.key,
                    };
                  })}
                  definitions={definitions?.[enumOption.key] || definitions}
                />

              </div>
            </Col>
          </Row>
        );
      })}
      {!isCalculating && !allOptions.length && !enumOptions.length && (
        <p>No options available</p>
      )}
    </Container>
  );

};


export default connect(
  (state) => ({
    formValues: getFormValues(FORM_NAME)(state),
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })
)(reduxForm({ form: FORM_NAME })(OptionsTab));

import { Col } from "react-bootstrap";
import { Field } from "redux-form";
import { SelectCampaign } from "../../../../../../../common/components";
import React from "react";

export const Campaign = ({ show, productId, brokerId, channelKey }) => {
  const campaignFilters = {
    'channel.key': channelKey,
    product: productId,
  };

  if (brokerId) {
    campaignFilters['broker'] = brokerId;
  }


  return (
    <Col sm={12} className={"campaign-sidebar-container"}>
      <div className="quote-title">Campaign:</div>
      <div className="campaign-info">
        <Field
          key={`select-campaign-${brokerId}`}
          sourceKey={brokerId}
          labelSize={0}
          name="data.attributes.metadata.campaign"
          filters={campaignFilters}
          component={SelectCampaign}
          isClearable
        />
      </div>
    </Col>);
}

import { Col, ControlLabel } from 'react-bootstrap';
import InfoButton from '../../../../../../common/components/InfoButton';
import { Field } from 'redux-form';
import { HorizontalFormControl, InfoLabel } from '../../../../../../common/components';
import parseFloatValue from '../../../../../../helpers/parseFloatValue';
import React from 'react';

const LABEL_SIZE = 4;
const FIELD_SIZE = 8;
const NumTravellerRules = ({ isViewOnly }) => (
  <>
    <Col sm={12}>
      <ControlLabel className={'pad-bottom'}>
        <h4>
          {' '}
          Max Number of Travellers - Default: <InfoButton content={'tbc'}/>
        </h4>
      </ControlLabel>
    </Col>
    <Col sm={12}>
      <ControlLabel className={'pad-bottom'}>
        <h5>Adults</h5>
      </ControlLabel>
    </Col>
    <Col sm={3}>
      <Field
        name="meta.config.rules.max_num_travellers.rules.adults.individual"
        label="Individuals:"
        labelSize={LABEL_SIZE}
        fieldSize={FIELD_SIZE}
        component={isViewOnly ? InfoLabel : HorizontalFormControl}
        parse={parseFloatValue}
      />
    </Col>
    <Col sm={3}>
      <Field
        name="meta.config.rules.max_num_travellers.rules.adults.couple"
        label="Couple:"
        labelSize={LABEL_SIZE}
        fieldSize={FIELD_SIZE}
        component={isViewOnly ? InfoLabel : HorizontalFormControl}
        parse={parseFloatValue}
      />
    </Col>
    <Col sm={3}>
      <Field
        name="meta.config.rules.max_num_travellers.rules.adults.family"
        label="Family:"
        labelSize={LABEL_SIZE}
        fieldSize={FIELD_SIZE}
        component={isViewOnly ? InfoLabel : HorizontalFormControl}
        parse={(value) => (!value ? null : parseFloatValue(value))}
      />
    </Col>
    <Col sm={3}>
      <Field
        name="meta.config.rules.max_num_travellers.rules.adults.single_parent_family"
        label="SPF:"
        labelSize={LABEL_SIZE}
        fieldSize={FIELD_SIZE}
        component={isViewOnly ? InfoLabel : HorizontalFormControl}
        parse={parseFloatValue}
      />
    </Col>

    <Col sm={12}>
      <ControlLabel className={'pad-bottom'}>
        <h5>Children</h5>
      </ControlLabel>
    </Col>
    <Col sm={3}>
      <Field
        name="meta.config.rules.max_num_travellers.rules.children.individual"
        label="Individuals:"
        labelSize={LABEL_SIZE}
        fieldSize={FIELD_SIZE}
        component={isViewOnly ? InfoLabel : HorizontalFormControl}
        parse={parseFloatValue}
      />
    </Col>
    <Col sm={3}>
      <Field
        name="meta.config.rules.max_num_travellers.rules.children.couple"
        label="Couple:"
        labelSize={LABEL_SIZE}
        fieldSize={FIELD_SIZE}
        component={isViewOnly ? InfoLabel : HorizontalFormControl}
        parse={parseFloatValue}
      />
    </Col>
    <Col sm={3}>
      <Field
        name="meta.config.rules.max_num_travellers.rules.children.family"
        label="Family:"
        labelSize={LABEL_SIZE}
        fieldSize={FIELD_SIZE}
        component={isViewOnly ? InfoLabel : HorizontalFormControl}
        parse={parseFloatValue}
      />
    </Col>
    <Col sm={3}>
      <Field
        name="meta.config.rules.max_num_travellers.rules.children.single_parent_family"
        label="SPF:"
        labelSize={LABEL_SIZE}
        fieldSize={FIELD_SIZE}
        component={isViewOnly ? InfoLabel : HorizontalFormControl}
        parse={parseFloatValue}
      />
    </Col>


  </>
);

export default NumTravellerRules;

import React from 'react';
import { Field } from 'redux-form';
import TextArea from '../../../content/components/TextArea/TextArea';
import { Col, Row } from 'react-bootstrap';
import CollapsableBox from '../CollapsableBox/CollapsableBox';
import { HorizontalFormControl } from '../../../../common/components';

const ComponentMap = {
  textarea: TextArea,
  text: HorizontalFormControl,
};

const PrivacyPageSections = ({ template, input }) => {
  if (!template) {
    return null;
  }

  const sections = template.attributes.sections;
  const sectionKeys = Object.keys(sections);

  return (
    <>
      {sectionKeys.map((templateSectionKey) => {
        //loop through top level sections
        const fieldType = sections[templateSectionKey].fieldtype;
        return (
          <div key={templateSectionKey}>
            <Row>
              <Col sm={12}>
                <Field
                  key={templateSectionKey}
                  name={`${input.name}.${templateSectionKey}`}
                  type={templateSectionKey}
                  label={templateSectionKey}
                  labelSize={2}
                  colSize={12}
                  component={ComponentMap[fieldType]}
                />
              </Col>
            </Row>
          </div>
        );
      })}
    </>
  );
};

const PrivacyPage = ({ template }) => {



  return (
    <CollapsableBox label={'Privacy page content'}>
      <Field
        name={'meta.privacy.content.attributes.sections'}
        template={template}
        component={PrivacyPageSections}
      />
    </CollapsableBox>
  );
};
export default PrivacyPage;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAccount } from '../redux/accountActions';
import { Label } from 'react-bootstrap';
import { Button, Loader } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import AccountDetails from '../components/AccountDetails';
import AccountTransactions from '../components/AccountTransactions';
import AccountNotes from '../components/AccountNotes';
import AccountAudit from '../components/AccountAudit';
import InternalTabs from '../../layout/components/InternalTabs/InternalTabs';
import { get } from 'lodash';

const TAB_NAME = 'accountsTabs';

class AccountShowContainer extends Component {
  componentWillMount() {
    const { dispatch } = this.props;

    dispatch(getAccount(this.props.params.id));
  }

  render() {
    const { params, account } = this.props;
    const resource = get(account, 'resources[' + params.id + ']');

    const labelStyle = (account) => {
      if (account.data.attributes.suspended) {
        return 'danger';
      } else if (account.data.attributes.limit < account.data.attributes.balance) {
        return 'warning';
      } else {
        return 'success';
      }
    };

    const accountLabel = (account) => {
      return (
        <Label className="pull-right" bsStyle={labelStyle(account)}>
          {account.data.attributes.formatted_balance}
        </Label>
      );
    };

    const buttons = (
      <Button bsStyle="default" label="back" isBackButton leftIcon="caret-left" />
    );

    return (
      <InternalContainer title="Account" buttons={buttons}>
        {resource ? (
          <InternalTabs
            id={TAB_NAME}
            tabs={[
              {
                permissions: ['account.view'],
                title: 'Details',
                content: (
                  <AccountDetails
                    {...this.props}
                    resource={resource}
                    accountLabel={accountLabel(resource)}
                  />
                ),
              },
              {
                permissions: ['account.view'],
                title: 'Ledger Entries',
                content: (
                  <AccountTransactions
                    {...this.props}
                    resource={resource}
                    accountLabel={accountLabel(resource)}
                  />
                ),
              },
              {
                permissions: ['account.view', 'policy.notes_view', 'policy.notes_create'],
                title: 'Notes',
                content: (
                  <AccountNotes
                    {...this.props}
                    resource={resource}
                    accountLabel={accountLabel(resource)}
                  />
                ),
              },
              {
                permissions: ['account.view'],
                title: 'Audit',
                content: <AccountAudit {...this.props} resource={resource} />,
              },
            ]}
          />
        ) : (
          <Loader />
        )}
      </InternalContainer>
    );
  }
}

AccountShowContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    account: state.account,
  };
}

export default connect(mapStateToProps)(AccountShowContainer);

import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';


import { Icon, InfoLabel } from '../../../../../common/components';
import { Field } from 'redux-form';

import EditDeclineModal from './EditDeclineModal';
import { startCase } from 'lodash';
import SchemeManagementSectionContainer
  from '../../SchemeManagementSectionContainer/SchemeManagementSectionContainer';

const isObject = (value) => {
  return value === Object(value);
};

const humanReadable = (name) => {
  if (name.includes('swap.tax')) {
    return startCase(name.replace('swap.tax', 'ipt Rate'));
  }
  return startCase(name);
};

const getTitle = (decline, isOptionDecline) => {
  const referral = decline?.decline ?? decline?.check;

  const value = decline?.option?.value ?? referral?.equal;
  const declineName = decline.name;
  if (isOptionDecline) {
    return startCase(
      decline?.option?.key ??
      `${declineName.substring(declineName.indexOf('.') + 1)} ${value}`,
    ).replace('_', ' ');
  }

  const name = Object.keys(referral)?.[0];
  return startCase(`${name} `.replace('_', ' '));
};

const ExtraOptionInfo = ({ input, labelSize }) => {
  if (typeof input.value?.option?.value === 'string') {
    return (
      <InfoLabel
        label={startCase(input.value?.option?.key)}
        value={input.value?.option?.value}
        labelSize={labelSize}
      />
    );
  }
  return null;
};

const RuleMapInfo = ({ input, labelSize }) => {
  const decline = input.value?.decline ?? input.value?.check;


  const ruleNames = Object.keys(decline);
  return (
    <>
      {ruleNames.map((rule) => {
        const rules = decline?.[rule];
        if (Array.isArray(rules)) {
          return (
            <>
              <InfoLabel
                label={`${humanReadable(input.value.name)}`}
                value={rules.join(', ')}
                labelSize={labelSize}
              />
            </>
          );
        }
        if (isObject(rules)) {
          const rulesKeys = Object.keys(rules);
          return (
            <>
              {rulesKeys.map((ruleKey) => (
                <InfoLabel
                  label={humanReadable(`${input.value.name} - ${ruleKey}`)}
                  value={rules[ruleKey]}
                  labelSize={labelSize}
                />
              ))}
            </>
          );
        }


        if (Number.isInteger(rules) || typeof rules === 'string' || rules instanceof String) {
          return (
            <InfoLabel
              label={humanReadable(`${input.value.name} - ${rule}`)}
              value={decline[rule]}
              labelSize={labelSize}
            />
          );
        }
        if (typeof rules == 'boolean') {
          const label = input.value.name;
          const labelValue = label.includes('medical_declaration_questions.') ? label.replace('medical_declaration_questions.', '').replace('.answer', '') : label;


          return (
            <InfoLabel
              label={`${labelValue} - ${humanReadable(`${rule}`)}`}
              value={decline[rule] ? 'Yes' : 'No'}
              labelSize={labelSize}
            />
          );
        }


      })}
    </>
  );
};

const RuleConditionInfo = ({ input, labelSize }) => {
  if (!input.value) {
    return null;
  }
  const value = input.value;

  const conditionKeys = Object.keys(value);
  return (
    <>
      {conditionKeys.map((conditionKey) => {
        const conditions = value[conditionKey];

        const conditionsValue = Array.isArray(conditions)
          ? conditions.join(', ')
          : conditions;

        const isString = typeof conditionsValue === 'string';

        return (
          <InfoLabel
            labelSize={labelSize}
            label={startCase(`${conditionKey} - Is One Of`)}
            value={
              isString ? startCase(conditionsValue.replace('_', ' ')) : conditionsValue
            }
          />
        );
      })}
    </>
  );
};

const DeclineInfoField = ({
  input,
  isViewOnly,
  onRemove,
  index,
  regions,
  options,
  medicalQuestionKeys,
  coverLevels,
  productType,
}) => {

  const [showEditDeclineModal, setShowEditDeclineModal] = useState(false);

  const isOptionDecline = input.value?.name?.startsWith('option') || !!input.value.option;

  const decline = input.value;

  const handleRemoveReferral = () => {
    onRemove(index);
  };

  const handleShowModal = () => {
    if (!isViewOnly) {
      setShowEditDeclineModal(true);
    }
  };

  const handleUpdateDecline = (values) => {
    input.onChange(values);
    setShowEditDeclineModal(false);
  };

  const handleHideModal = () => {
    setShowEditDeclineModal(false);
  };
  return (
    <>
      <div>
        <Col sm={12}>
          <SchemeManagementSectionContainer
            isViewOnly={isViewOnly}
            subTitle={getTitle(decline, isOptionDecline)}
          >
            <Row className={`section-item ${isViewOnly ? '' : 'clickable-section-item'}`}>
              <Col sm={12}>
                {!isViewOnly && (
                  <span className="pull-right" onClick={handleRemoveReferral}>
                    <Icon name={'trash'}/>
                  </span>
                )}
              </Col>

              <div onClick={handleShowModal}>
                <Col sm={12}>
                  <h4>Rule Details:</h4>
                </Col>
                <Col sm={12}>
                  <InfoLabel
                    value={!!input?.value?.decline ? 'Decline' : 'Referral'}
                    labelSize={3}
                    label={'Type:'}
                    component={InfoLabel}
                  />
                  <Field
                    name={`${input.name}.reason`}
                    labelSize={3}
                    label={'Reason:'}
                    component={InfoLabel}
                  />
                  <Field
                    name={`${input.name}.team_factor`}
                    labelSize={3}
                    label={'Team Factor:'}
                    component={InfoLabel}
                  />
                </Col>
                <Col sm={12}>
                  <Field name={`${input.name}`} labelSize={3} component={RuleMapInfo}/>
                  <Field
                    name={`${input.name}`}
                    labelSize={3}
                    component={ExtraOptionInfo}
                  />
                </Col>
                <Col sm={12}>
                  <Field
                    name={`${input.name}.conditions`}
                    component={RuleConditionInfo}
                    labelSize={3}
                  />
                </Col>
              </div>
            </Row>
          </SchemeManagementSectionContainer>
        </Col>
      </div>
      <EditDeclineModal
        show={showEditDeclineModal}
        onClose={handleHideModal}
        decline={input.value}
        regions={regions}
        isOptionDecline={isOptionDecline}
        options={options}
        coverLevels={coverLevels}
        medicalQuestionKeys={medicalQuestionKeys}
        onUpdateDecline={handleUpdateDecline}
        productType={productType}
      />
    </>
  );
};

export default DeclineInfoField;

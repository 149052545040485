import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getContent } from '../redux/contentActions';
import { Button, Loader } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import ContentShow from '../components/ContentShow/ContentShow';

class ContentShowContainer extends Component {
  componentWillMount() {
    this.props.dispatch(getContent(this.props.params.id));
  }

  render() {
    const resource = this.props.content.resources[this.props.params.id];
    const buttons = (
      <Button bsStyle="default" label="back" isBackButton leftIcon="caret-left" />
    );

    return (
      <InternalContainer title="Content Management" buttons={buttons}>
        {resource ? <ContentShow resource={resource} /> : <Loader />}
      </InternalContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    content: state.content,
  };
}

ContentShowContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(ContentShowContainer);

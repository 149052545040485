import * as actions from './screeningReducer';
import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';
import confirm from 'react-confirm2';
import handleErrorResponse from '../../../helpers/handleErrorResponse';
import { getFormValues } from 'redux-form';

export function setIndex(index) {
  return {
    type: actions.SET_INDEX,
    index,
  };
}

export function isSubmitting(status) {
  return {
    type: actions.IS_SUBMITTING,
    status,
  };
}

export function isRecalculatingScore(status) {
  return {
    type: actions.IS_RECALCULATING_SCORE,
    status,
  };
}

export function setScreeningErrors(errors = null) {
  return {
    type: actions.SET_ERROR,
    errors,
  };
}

export function isEditing(status) {
  return {
    type: actions.IS_EDITING,
    status,
  };
}

export function isDeleting(status) {
  return {
    type: actions.IS_DELETING,
    status,
  };
}

export function isSearching(status) {
  return {
    type: actions.IS_SEARCHING,
    status,
  };
}

function startVeriskSession(region, destinations, id, dob) {
  return Axios.post('/public/medical/verisk:start', {
    meta: {
      region,
      destinations,
      id,
      dob,
    },
  });
}

function startCegaSession(id) {
  return Axios.post('/public/medical/cega:start', {
    meta: {
      id,
    },
  });
}

function finishVeriskSession(id) {
  return Axios.post('/public/medical/verisk:finish', {
    meta: {
      id,
    },
  });
}

function finishCegaSession(id) {
  return Axios.post('/public/medical/cega:finish', {
    meta: {
      id,
    },
  });
}

const INCLUDED =
  '?include=conditions,conditions.questions.options,conditions.questions.selected';

export function startScreening(
  travellerIndex,
  screeningId,
  region,
  destinations = [],
  service = 'verisk',
  dob,
) {
  return (dispatch) => {
    dispatch(setIndex(travellerIndex));
    dispatch(isSubmitting(true));

    let promise = null;

    if (service === 'verisk') {
      promise = startVeriskSession(region, destinations, screeningId, dob);
    }

    if (service === 'cega') {
      promise = startCegaSession(screeningId);
    }

    return promise
      .then((sessionResponse) => {
        if (service === 'verisk' && screeningId) {
          return Axios.get(
            '/public/medical/verisk/' + sessionResponse.data.meta.id + INCLUDED,
          )
            .then((res) => {
              dispatch({
                type: actions.START_SCREENING,
                token: sessionResponse.data.meta.id,
                conditions: res.data,
                service,
              });
              dispatch(isSubmitting(false));

              return true;
            })
            .catch((err) => {
              dispatch(isSubmitting(false));
              console.error(err);

              handleErrorResponse(err);
            });
        }

        dispatch({
          type: actions.START_SCREENING,
          token: sessionResponse.data.meta.id,
          conditions: {},
          service,
        });
        dispatch(isSubmitting(false));

        return true;
      })
      .catch((err) => {
        dispatch(isSubmitting(false));
        console.error(err);

        handleErrorResponse(err);
      });
  };
}

export function recalculateScore(
  callback,
  screeningId,
  region,
  destinations = [],
  createNewStore = false,
  traveller = null,
  path
) {
  return (dispatch, getState) => {
    if (getState().screening.service === 'cega') {
      return;
    }

    const application = getFormValues('LeisureTravelForm')(getState());
    const quoteReference = application?.data?.attributes?.quote_reference || '';
    const policyNumber =
      application?.data?.attributes?.mta_policy_number ||
      application?.data?.attributes?.policy_number ||
      '';

    let travellerName = null;

    if (traveller) {
      travellerName =
        traveller?.title + ' ' + traveller?.first_name + ' ' + traveller?.last_name;
    }

    let errors = getState()?.screening?.errors || {};

    dispatch(isRecalculatingScore(true));

    return Axios.post('/public/medical/verisk:recalculate', {
      meta: {
        region,
        destinations,
        id: screeningId,
        create_new_store: createNewStore,
        channel_type: 'DIR',
        quote_reference: quoteReference,
        policy_number: policyNumber,
      },
    })
      .then((res) => {
        if (callback) {
          if (createNewStore) {
            screeningId = res.data.meta.id;
          }
          callback(
            res.data.meta.conditions.length ? res.data : '', // screening payload
            res.data.meta.conditions.length ? screeningId : '', // screening id
            path,
          );
        }
        if (travellerName) {
          errors[travellerName] = false;
          dispatch(setScreeningErrors(errors));
        }
        dispatch(isRecalculatingScore(false));
      })
      .catch((err) => {
        dispatch(isRecalculatingScore(false));

        if (travellerName) {
          toastr.error(
            'Error',
            `There has been an error with screening on traveller ${travellerName}. Please delete and re-screen.`,
            { timeOut: 10000 },
          );
          errors[travellerName] = true;
          dispatch(setScreeningErrors(errors));
        }
        console.error(err);
      });
  };
}

export function finishScreening(callback, path) {
  return (dispatch, getState) => {
    dispatch(isSubmitting(true));
    const state = getState().screening;

    let promise = null;

    if (state.service === 'verisk') {
      promise = finishVeriskSession(state.token);
    }

    if (state.service === 'cega') {
      promise = finishCegaSession(state.token);
    }

    return promise
      .then((res) => {
        dispatch({
          type: actions.FINISH_SCREENING,
          data: res.data,
        });

        callback(
          res.data.meta.conditions.length ? res.data : '',
          res.data.meta.conditions.length ? state.token : '',
          path,
        );

        dispatch(isSubmitting(false));
        dispatch(setScreeningErrors(null));
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        const warning = _.get(error.response, 'data.errors[0].detail', '');
        if (warning) {
          dispatch(setScreeningErrors({ warning }));
        }
        console.error(error);
      });
  };
}

export function findCondition(search) {
  return (dispatch, getState) => {
    const state = getState().screening;

    if (!search.length || search.length < 2) {
      return null;
    }

    dispatch(isSearching(true));

    Axios.get(
      '/public/medical/verisk/' +
        state.token +
        '/conditions?filter[name]=' +
        encodeURIComponent(search),
    )
      .then((res) => {
        const options = res.data.data.attributes.conditions
          .map((condition) => {
            return {
              value: condition.id,
              label: condition.name,
            };
          })
          .filter(Boolean);

        dispatch(isSearching(false));

        dispatch({
          type: actions.FIND_CONDITION,
          data: options,
          hasMisspelledConditions: res.data.data.attributes.has_misspelled_conditions,
          hasSurgicalProcedure: res.data.data.attributes.has_surgical_procedure,
          isAlreadyDeclared: res.data.data.attributes.is_already_declared,
          existingCondition: res.data.data.attributes.existing_condition ?? null,
        });
        return true;
      })
      .catch((err) => {
        dispatch(isSearching(false));
        console.error(err);
      });
  };
}

export function startDeclaration(condition) {
  return (dispatch, getState) => {
    const state = getState().screening;
    dispatch(isSubmitting(true));

    return Axios.post(
      '/public/medical/verisk/' + state.token + '/declaration' + INCLUDED,
      {
        id: condition.value,
        name: condition.label,
      },
    )
      .then((res) => {
        dispatch({
          type: actions.START_DECLARATION,
          index: 0,
          declaration: res.data,
        });

        dispatch(isSubmitting(false));
        return true;
      })
      .catch((err) => {
        const message = _.get(err.response, 'data.errors[0].detail');

        toastr.error('Error', message);

        dispatch(isSubmitting(false));
        console.error(err);
      });
  };
}

export function editDeclaration(declarationId) {
  return (dispatch, getState) => {
    const state = getState().screening;
    dispatch(isEditing(declarationId));

    return Axios.post(
      '/public/medical/verisk/' + state.token + '/declaration' + INCLUDED,
      {
        id: declarationId,
      },
    )
      .then((res) => {
        dispatch({
          type: actions.START_DECLARATION,
          index: 0,
          declaration: res.data,
        });
        dispatch(isEditing(false));
        return true;
      })
      .catch((err) => {
        dispatch(isEditing(false));
        console.error(err);
      });
  };
}

export function finishDeclaration() {
  return (dispatch, getState) => {
    const state = getState().screening;
    dispatch(isSubmitting(true));

    return Axios.patch(
      '/public/medical/verisk/' + state.token + '/declaration' + INCLUDED,
      {
        finish: true,
      },
    )
      .then(() => {
        return Axios.get('/public/medical/verisk/' + state.token + INCLUDED)
          .then((res) => {
            dispatch({
              type: actions.FINISH_DECLARATION,
              conditions: res.data,
            });
            dispatch(isSubmitting(false));
            return true;
          })
          .catch((err) => {
            dispatch(isSubmitting(false));
            console.error(err);
          });
      })
      .catch((err) => {
        dispatch(isSubmitting(false));
        console.error(err);
      });
  };
}

export function deleteTravellerMedicalCondition(
  callback,
  travellerIndex,
  applicationId,
  travellerType,
  mta,
  childIndex,
  adultIndex,
  traveller,
  path,
) {
  return (dispatch, getState) => {
    dispatch(isDeleting(true));

    let errors = getState()?.screening?.errors || {};
    let travellerName = null;

    if (traveller) {
      travellerName =
        traveller?.title + ' ' + traveller?.first_name + ' ' + traveller?.last_name;
    }

    return new Promise((resolve, reject) => {
      confirm(
        `You have requested to remove ${traveller?.first_name} from your medical declaration; are you sure you wish to proceed?`,
        {
          close: () => {
            dispatch(isDeleting(false));

            // Resolve
            resolve();
          },
          done: () => {
            if (mta) {
              callback(null, null, path);
              if (travellerName) {
                errors[travellerName] = false;
                dispatch(setScreeningErrors(errors));
              }
              resolve();
              return;
            }

            Axios.post(`policies/applications/${applicationId}:delete-traveller-screening`, {
              applicationId: applicationId,
              travellerType,
              travellerIndex,
              childIndex,
              adultIndex,
            })
              .then(() => {
                callback(null, null, path);
                toastr.success(
                  'Success',
                  `Medical conditions removed from traveller ${name}`,
                );

                if (travellerName) {
                  errors[travellerName] = false;
                  dispatch(setScreeningErrors(errors));
                }
                resolve();
              })
              .catch((err) => {
                dispatch(isDeleting(false));
                console.error(err);
                toastr.error(
                  'Error',
                  'Sorry there has been an error removing conditions',
                );

                //Reject
                reject(err);
              });
          },
        },
      );
    });
  };
}

export function deleteDeclaration(groupNumber, name) {
  return (dispatch, getState) => {
    const state = getState().screening;
    dispatch(isDeleting(groupNumber));

    return new Promise((resolve, reject) => {
      confirm(
        `You have requested to remove ${name} from your medical declaration; are you sure you wish to proceed?`,
        {
          close: () => {
            dispatch(isDeleting(false));

            // Resolve
            resolve();
          },
          done: () => {
            Axios.delete(
              '/public/medical/verisk/' + state.token + '/conditions/' + groupNumber,
              {
                id: groupNumber,
              },
            )
              .then(() => {
                Axios.get('/public/medical/verisk/' + state.token + INCLUDED)
                  .then((res) => {
                    dispatch({
                      type: actions.DELETE_DECLARATION,
                      conditions: res.data,
                    });
                    dispatch(isDeleting(false));

                    // Resolve
                    resolve(res);
                  })
                  .catch((err) => {
                    dispatch(isDeleting(false));
                    console.error(err);

                    // Reject
                    reject(err);
                  });
              })
              .catch((err) => {
                dispatch(isDeleting(false));
                console.error(err);

                //Reject
                reject(err);
              });
          },
        },
      );
    });
  };
}

export function setAnswer(condition, question, answer, sendAnswerAsObject = false) {
  return (dispatch, getState) => {
    const state = getState().screening;

    dispatch(isSubmitting(true));

    return Axios.patch(
      '/public/medical/verisk/' +
        state.token +
        '/declaration/conditions/' +
        condition.attributes.number +
        '/questions/' +
        question.attributes.number +
        INCLUDED,
      sendAnswerAsObject
        ? answer
        : {
            id: answer.value,
          },
    )
      .then((res) => {
        dispatch({
          type: actions.SET_ANSWER,
          declaration: res.data,
        });

        dispatch(isSubmitting(false));
        return true;
      })
      .catch((err) => {
        dispatch(isSubmitting(false));
        console.error(err);
      });
  };
}

export function getAnswerOptions(condition, question, declarationNumber) {
  return (dispatch, getState) => {
    const state = getState().screening;

    dispatch(isSubmitting(true));

    return Axios.post(
      '/public/medical/verisk/' +
        state.token +
        '/declaration/' +
        declarationNumber +
        '/conditions/' +
        condition.attributes.number +
        '/questions/' +
        question.attributes.number +
        INCLUDED,
      {
        question: question?.attributes?.text,
        conditionName: condition?.attributes?.name,
      },
    )
      .then((res) => {
        dispatch({
          type: actions.SET_ANSWER,
          declaration: res.data,
        });
        dispatch(isSubmitting(false));
        return true;
      })
      .catch((err) => {
        dispatch(isSubmitting(false));
        console.error(err);
      });
  };
}

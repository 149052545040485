import React from 'react';
import { Col, ControlLabel, FormControl, Row } from 'react-bootstrap';
import displayCurrency from '../../../../../../helpers/displayCurrency';
import PremiumBreakdown from '../../../PremiumBreakdown/PremiumBreakdown';
import './MtaAdjustmentForm.scss';
import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { Button, } from '../../../../../../common/components';

const OverrideInput = ({ input }) => (
  <FormControl className={'mta-adjustment-form--override-input'}
               {...input}

               value={input.value}
  />
);

const PremiumValue = ({ isPremiumAdjusting, children }) => {
  if (isPremiumAdjusting) {
    return (
      <span className="loader-dots">
        <i className="fa fa-circle loader-dot"/>
        <i className="fa fa-circle loader-dot"/>
        <i className="fa fa-circle loader-dot"/>
      </span>
    );
  }
  return (
    <span className="mta-adjustment-form--premium block-premium">
      {children}

    </span>
  );

};

const MtaAdjustmentForm = ({
  isPremiumAdjusting,
  formValues,
  selectedScheme,
  onApplyOverride,
  onClearOverride

}) => {

  const {
    gross_premium: grossPremiumOverride,
  } = formValues || {};

  const { premium, scheme, mta } = selectedScheme?.meta ?? {};

  const originalPremium = mta?.old_premium?.gross;
  const applyPremium = () => {
    const override = originalPremium + parseFloat(grossPremiumOverride);
    if (Math.abs(override) > 0) {
      onApplyOverride({ gross_premium: override });
    }
  };

  return (
    <div className="mta-adjustment-form">

      <div className="mta-adjustment-form--container">
        <Row>
          <Col xs={4}>
            <h4>Original Premium</h4>
            <PremiumValue
              isPremiumAdjusting={isPremiumAdjusting}
            ><>
              {displayCurrency(mta?.old_premium?.gross ?? 0, premium?.currency ?? 'GBP')}
            </>
            </PremiumValue>
          </Col>
          <Col xs={4}>
            <h4>New Premium</h4>
            <PremiumValue
              isPremiumAdjusting={isPremiumAdjusting}
            ><>
              {displayCurrency(mta?.new_premium?.gross ?? 0, premium?.currency ?? 'GBP')}
            </>
            </PremiumValue>
          </Col>
          <Col xs={4}>
            <h4>Total amount due</h4>
            <PremiumValue
              isPremiumAdjusting={isPremiumAdjusting}
              value={mta?.old_premium?.gross ?? 0}
              currency={premium?.currency ?? 'GBP'}
            >
              <div className={'mta-adjustment-form--total'}>


                <PremiumBreakdown
                  premium={selectedScheme?.meta}
                  block
                  showIpt
                  taxType={'IPT'}
                />
              </div>
            </PremiumValue>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <p className="mta-adjustment-form--scheme-name">
              {scheme?.name}
            </p>
          </Col>
          <Row>
            <Col sm={12}>
              <div className={'mta-adjustment-form--override-row'}>
                <Col sm={12} componentClass={ControlLabel} className="adj-label">
                  Override (total due)
                </Col>
                <Field
                  name={'gross_premium'}
                  currency
                  component={OverrideInput}
                  className="text-right"
                />
                <Button
                  bsStyle={'default'}
                  label={'Apply'}
                  className={'mta-adjustment-form--override-button'}
                  handleClick={applyPremium}
                />
                <Button
                  bsStyle={'default'}
                  label={'Cancel'}
                  className={'mta-adjustment-form--override-button'}
                  handleClick={onClearOverride}
                />
              </div>
            </Col>
            <Col sm={6}/>
          </Row>
        </Row>
      </div>
    </div>
  );

};


const FORM_NAME = 'MtaAdjustmentForm';
export default connect(
  (state) => ({
    formValues: getFormValues(FORM_NAME)(state),
    initialValues: {
      gross_premium: ''
    },
  })
)(reduxForm({ form: FORM_NAME })(MtaAdjustmentForm));

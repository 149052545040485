import React from 'react';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';

const FormGroupRow = ({ children, meta, label, infoButton, labelSize, fieldSize }) => {
  const labelSmSize = labelSize ? labelSize : 2;
  const menuSize = fieldSize ? fieldSize : !label ? 12 : 12 - labelSize;

  return (
    <FormGroup className={meta && meta.touched && meta.error ? ' has-error' : ''}>
      {label && (
        <Col sm={labelSmSize}>
          <ControlLabel>
            {label} {infoButton}
          </ControlLabel>
        </Col>
      )}
      <Col sm={menuSize}>{children}</Col>
    </FormGroup>
  );
};

export default FormGroupRow;

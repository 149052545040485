import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Icon, SelectUser, Avatar } from '../../../../common/components';
import SortableItem from './SortableItem';
import './styles.scss';

class Personnel extends Component {
  static propTypes = {
    fields: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: '',
      user: null,
      dragIndex: null,
      included: [],
    };
  }

  handleSort(values) {
    const { fields, items, dispatch } = this.props;
    this.setState({ dragIndex: values.draggingIndex });

    if (values.items) {
      values.items.forEach((value, i) => {
        const index = items.indexOf(value);
        if (index !== i) {
          dispatch(fields.swap(index, i));
        }
      });
    }
  }

  render() {
    const { fields, items } = this.props;

    return (
      <div className="content-key-personnel">
        <div className="sortable-list">
          <div className={'list' + (this.state.dragIndex !== null ? ' dragging' : '')}>
            {fields.map((field, index) => {
              const person = items[index] ? items[index] : { attributes: {} };

              return (
                <SortableItem
                  key={index}
                  sortId={index}
                  outline="list"
                  items={items}
                  updateState={::this.handleSort}
                  draggingIndex={this.state.dragIndex}
                >
                  <div key={index} className="row">
                    <div className="col-xs-12">
                      <span
                        className="remove-item pull-right"
                        onClick={() => fields.remove(index)}
                      >
                        <Icon name="times" />
                      </span>
                      <Avatar
                        user={{ data: person, included: this.state.included }}
                        url={person.attributes.url}
                      />
                      <label className="control-label">
                        {person.attributes.title} {person.attributes.first_name}{' '}
                        {person.attributes.last_name}
                      </label>
                    </div>
                  </div>
                </SortableItem>
              );
            })}
          </div>
        </div>
        <div>
          <SelectUser
            async
            input={{
              value: this.state.user,
              onChange: (value, included) => {
                this.setState({ user: value, included });
              },
              onBlur: (value, included) => {
                this.setState({ user: value, included });
              },
            }}
            labelKeys={[
              'attributes.title',
              'attributes.first_name',
              'attributes.last_name',
            ]}
            filters={[{ filter: 'include', value: 'avatar' }]}
          />

          <Button
            type="button"
            disabled={!this.state.user}
            handleClick={() => {
              fields.push(this.state.user);
              this.setState({ user: null });
            }}
          >
            Add Person
          </Button>
        </div>
      </div>
    );
  }
}
export default connect()(Personnel);

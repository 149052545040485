import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { goBack } from 'react-router-redux';
import { Icon } from '../../components';
import { MdPeople, MdPerson, MdSearch } from 'react-icons/md';
import { FaPlane } from 'react-icons/fa';
import './Button.scss';

export class Button extends Component {
  handleClick(e) {
    const { dispatch, handleClick, isBackButton } = this.props;
    if (isBackButton) {
      dispatch(goBack());
    }
    if (handleClick) {
      handleClick(e);
    }
  }

  render() {
    const {
      id,
      bsStyle,
      size,
      type,
      className,
      disabled,
      isLoading,
      children,
      label,
      leftIcon,
      rightIcon,
      link,
      block,
      to,
    } = this.props;

    const buttonClass =
      'btn btn-' +
      (bsStyle || 'default') +
      (size ? ' btn-' + size : '') +
      (className ? ' ' + className : '') +
      (block ? ' btn-block' : '');

    if (link) {
      return (
        <Link className={buttonClass} to={to}>
          {isLoading ? (
            <span className="btn-loading">
              <Icon spin name="spinner" />
              <span>{children || label}</span>
            </span>
          ) : (
            <span>
              {this.props.plane && (
                <span style={{ fontSize: '20px' }}>
                  <FaPlane />{' '}
                </span>
              )}
              {this.props.planeSmall && (
                <span>
                  <FaPlane />{' '}
                </span>
              )}

              {leftIcon && <Icon name={leftIcon} align="left" />}
              {children || label}
            </span>
          )}
        </Link>
      );
    }

    return (
      <button
        id={id}
        type={type || 'button'}
        className={buttonClass}
        onClick={this.handleClick.bind(this)}
        disabled={disabled || isLoading}
      >
        {isLoading ? (
          <span className="btn-loading">
            <Icon spin name="spinner" />
            <span>{children || label}</span>
          </span>
        ) : (
          <span>
            {this.props.individual && (
              <span>
                <MdPerson />{' '}
              </span>
            )}
            {this.props.couple && (
              <span>
                <MdPeople />{' '}
              </span>
            )}
            {this.props.family && (
              <span>
                {' '}
                <MdPeople />
                <MdPeople />{' '}
              </span>
            )}
            {this.props.search && (
              <span>
                {' '}
                <MdSearch />{' '}
              </span>
            )}
            {leftIcon && <Icon name={leftIcon} align="left" />}
            {children || label}
            {rightIcon && <Icon name={rightIcon} align="right" />}
          </span>
        )}
      </button>
    );
  }

  static propTypes = {
    dispatch: PropTypes.func,
    bsStyle: PropTypes.string,
    size: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    handleClick: PropTypes.func,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isBackButton: PropTypes.bool,
    children: PropTypes.node,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    leftIcon: PropTypes.string,
    rightIcon: PropTypes.string,
    link: PropTypes.bool,
    block: PropTypes.bool,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  };
}

export default connect()(Button);

import React, { Component } from 'react';
import { Field } from 'redux-form';
import { CheckboxMarketingOptions } from '../../../../common/components/ReduxFormField';
import { Col, Row } from 'react-bootstrap';
import { get, isEmpty, reduce } from 'lodash';
import './Marketing.scss';

// Marketing options component

export default class Marketing extends Component {
  constructor(props) {
    super(props);
    this.renderCheckboxes = this.renderCheckboxes.bind(this);
  }

  selectAll(e) {
    const { dispatch, change, name } = this.props;
    const selected = e.currentTarget.checked ? 1 : -1;
    const marketingOptions = {
      post: selected,
      sms: selected,
      email: selected,
      phone: selected,
    };
    dispatch(change(name, marketingOptions));
  }

  renderCheckboxes() {
    const { name, formValues } = this.props;

    const marketingOptions = get(formValues, name);
    const marketingOptionsSet = marketingOptions && !isEmpty(marketingOptions);

    const selectedAll = () =>
      reduce(
        marketingOptions,
        (carry, option) => {
          return carry && option > 0;
        },
        true,
      );

    const email = [{ label: 'Email', value: 1, valid: [1, 2] }];
    const sms = [{ label: 'SMS', value: 1, valid: [1, 2] }];
    const post = [{ label: 'Post', value: 1, valid: [1, 2] }];
    const phone = [{ label: 'Phone', value: 1, valid: [1, 2] }];

    return (
      <Row>
        <Col md={2}>
          <Field
            name={'selectAll'}
            options={[{ label: 'All', value: true }]}
            component={CheckboxMarketingOptions}
            onChange={(e) => this.selectAll(e)}
            checked={marketingOptionsSet ? selectedAll() : false}
          />
        </Col>
        <Col md={2}>
          <Field
            name={name + '.post'}
            options={post}
            component={CheckboxMarketingOptions}
            format={this.consentToBool()}
            normalize={this.consentToInt()}
          />
        </Col>
        <Col md={3}>
          <Field
            name={name + '.email'}
            options={email}
            component={CheckboxMarketingOptions}
            format={this.consentToBool()}
            normalize={this.consentToInt()}
          />
        </Col>
        <Col md={2}>
          <Field
            name={name + '.sms'}
            options={sms}
            component={CheckboxMarketingOptions}
            format={this.consentToBool()}
            normalize={this.consentToInt()}
          />
        </Col>
        <Col md={3}>
          <Field
            name={name + '.phone'}
            options={phone}
            component={CheckboxMarketingOptions}
            format={this.consentToBool()}
            normalize={this.consentToInt()}
          />
        </Col>
      </Row>
    );
  }

  consentToInt() {
    return (v) => (v ? 1 : -1);
  }

  consentToBool() {
    return (v) => v === 1 || v === 2;
  }

  render() {
    const { displayTitle = true } = this.props;
    let TITLE = '';
    if (displayTitle) {
      TITLE = `We may from time to time contact you by email or other electronic means to provide you with information
in relation to special offers, services and events which may be of interest to you.`;
    }

    return (
      <div className="marketing-ip">
        <p className="mkt-title">{TITLE}</p>
        {this.renderCheckboxes()}
      </div>
    );
  }
}

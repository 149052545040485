import React, { Component } from 'react';
import { openModal } from '../../../common/components/Modal/ModalRedux';
import { connect } from 'react-redux';

class ErrorCurrentOrganisation extends Component {
  componentDidMount() {
    this.props.dispatch(openModal('organisation-settings'));
  }

  render() {
    return (
      <div>
        <div className="full-width-section background-waves">
          <div className="container">
            <div className="row">
              <div className="col-sm-offset-2 col-sm-6 hero">
                <h2 className="faded">Select an organisation</h2>
                <h1>To continue, an organisation must be selected...</h1>
                <h4>If you have any issues contact us.</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(ErrorCurrentOrganisation);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InfoLabel, Box, Button } from '../../../../common/components';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import {
  suspendAccount,
  reinstateAccount,
  viewStatement,
} from '../../redux/accountActions';
import displayName from '../../../../helpers/displayName';
import './styles.scss';
import { Col, Row } from 'react-bootstrap';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';

class AccountDetails extends Component {
  constructor(props) {
    super(props);
    this.suspendAccount = this.suspendAccount.bind(this);
    this.reinstateAccount = this.reinstateAccount.bind(this);
    this.viewStatement = this.viewStatement.bind(this);
  }

  suspendAccount() {
    this.props.dispatch(suspendAccount(this.props.resource.data.id));
  }

  reinstateAccount() {
    this.props.dispatch(reinstateAccount(this.props.resource.data.id));
  }

  viewStatement() {
    this.props.dispatch(viewStatement(this.props.resource.data.id));
  }

  buttons() {
    const resource = this.props.resource.data.attributes;
    const id = this.props.resource.data.id;
    const organisation = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'organisation',
    );
    const accountsMatchingLink = {
      pathname: '/accounts/' + id + '/matching',
      state: {
        accountType: resource.account_type,
        account: id,
      },
    };

    const actionButtons = [
      {
        permissions: ['account.edit'],
        button: (
          <Button
            link
            to={'/accounts/ledger/' + id + '/edit'}
            className="pull-right"
            bsStyle="primary"
            rightIcon="pencil-square-o"
            label="Edit Account"
          />
        ),
      },
    ];

    if (!resource.suspended && !resource.locked) {
      actionButtons.push({
        permissions: ['account.edit'],
        button: (
          <Button
            className="pull-right"
            bsStyle="primary"
            rightIcon="bomb"
            isLoading={this.props.account.isSuspending}
            handleClick={::this.suspendAccount}
            label="Suspend Account"
          />
        ),
      });
    }

    if (resource.suspended && !resource.locked) {
      actionButtons.push({
        permissions: ['account.edit'],
        button: (
          <Button
            className="pull-right"
            bsStyle="primary"
            rightIcon="thumbs-up"
            isLoading={this.props.account.isReinstating}
            handleClick={::this.reinstateAccount}
            label="Reinstate Account"
          />
        ),
      });
    }

    if (resource.account_type === 'debtor') {
      actionButtons.push({
        permissions: ['account.view'],
        button: (
          <Button
            className="pull-right"
            bsStyle="primary"
            rightIcon="list"
            isLoading={this.props.account.isSubmitting}
            handleClick={this.viewStatement}
            label="View Statement"
          />
        ),
      });
    }

    if (organisation.data.attributes) {
      actionButtons.push({
        permissions: ['organisation.view'],
        button: (
          <Button
            label="ViewOrganisation"
            className="pull-left"
            link
            to={'/admin/organisations/' + organisation.data.id}
          />
        )
      });
    }

    actionButtons.push({
      permissions: ['account.view'],
      button: (
        <Button
          type="button"
          bsStyle="primary"
          className="pull-right"
          link
          to={accountsMatchingLink}
          label="Matching"
        />
      ),
    });

    return actionButtons;
  }

  render() {
    const resource = this.props.resource.data.attributes;
    const organisation = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'organisation',
    );
    const contacts = getIncludedResource(
      organisation.data,
      this.props.resource.included,
      'accounts_contacts',
    );

    return (
      <Box>
        <h2 className="resource-name">
          {resource.name}
          {this.props.accountLabel}
        </h2>

        <Row>
          <Col sm={6}>
            <InfoLabel label="Name" value={resource.name} labelSize={2}/>
            {organisation.data.attributes && (
              <InfoLabel
                label="Organisation"
                value={organisation.data.attributes.name}
                labelSize={2}
              />
            )}
          </Col>
          <Col sm={6}>
            <InfoLabel
              label="Account Type"
              value={displayName(resource.account_type)}
              labelSize={6}
            />
            <InfoLabel
              label="Accounting System Reference"
              value={resource.accounting_system_reference}
              labelSize={6}
            />
            <InfoLabel label="Limit" value={resource.limit} labelSize={6}/>
            <InfoLabel
              label="Status"
              value={resource.suspended ? 'Suspended' : 'Active'}
              labelSize={6}
            />
          </Col>
        </Row>

        {contacts && contacts.length > 0 && (
          <div>
            <div className="hr"/>
            <h2>Accounts contacts</h2>
          </div>
        )}

        {Array.isArray(contacts) &&
        contacts.map((contact, i) => (
          <div key={i} className="form-horizontal">
            <Row>
              <Col sm={6}>
                <InfoLabel
                  label="Name"
                  value={
                    contact.attributes.first_name + ' ' + contact.attributes.last_name
                  }
                  labelSize={4}
                />
              </Col>
              <Col sm={6}>
                <InfoLabel
                  label="Phone"
                  value={contact.attributes.phone1}
                  labelSize={4}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <InfoLabel
                  label="Email"
                  value={contact.attributes.email}
                  labelSize={4}
                />
              </Col>
            </Row>

            <div className="hr transparent"/>
          </div>
        ))}

        <Row>
          <Col sm={12}>
            <InternalButtonBar buttons={this.buttons()}/>
          </Col>
        </Row>
      </Box>
    );
  }
}

AccountDetails.propTypes = {
  dispatch: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  resource: PropTypes.object.isRequired,
  accountLabel: PropTypes.object.isRequired,
};

export default AccountDetails;

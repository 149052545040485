export const account = {
  data: {
    type: 'account',
    attributes: {
      name: '',
      account_type: '',
      accounting_system_reference: '',
      limit: 0,
      metadata: {},
    },
    relationships: {
      organisation: {
        data: {
          id: null,
        },
      },
    },
  },
};

export const accountRules = {};

export default account;

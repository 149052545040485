import React from 'react';
import { Field } from 'redux-form';
import PlainTextField from './PlainTextField';
import './styles.scss';
import { FormGroup, ControlLabel, Col } from 'react-bootstrap';

const AddressFields = ({
 baseName,
 label,
 labelSizes,
 fieldSizes,
 organisation,
 disabled,
}) => {
  return (
    <div>
      {organisation && (
        <FormGroup>
          <Col
            sm={fieldSizes.sm || 8}
            md={fieldSizes.md || 8}
            lg={fieldSizes.lg || 8}
          >
            <Field
              name={baseName + '.organisation'}
              placeholder="Organisation"
              component={PlainTextField}
            />
          </Col>
        </FormGroup>
      )}

      <FormGroup>
        <Col
          sm={labelSizes.sm || 2}
          md={labelSizes.md || 2}
          lg={labelSizes.lg || 2}
          componentClass={ControlLabel}
        >
          {label}
        </Col>
        <Col
          sm={fieldSizes.sm || 8}
          md={fieldSizes.md || 8}
          lg={fieldSizes.lg || 8}
        >
          <Field
            name={baseName + '.line1'}
            placeholder="Line 1"
            component={PlainTextField}
            disabled={!!disabled}
          />
        </Col>
      </FormGroup>

      <FormGroup>
        <Col
          sm={fieldSizes.sm || 8}
          smOffset={labelSizes.sm || 2}
          md={fieldSizes.md || 8}
          mdOffset={labelSizes.md || 2}
          lg={fieldSizes.lg || 8}
          lgOffset={labelSizes.lg || 2}
        >
          <Field
            name={baseName + '.line2'}
            placeholder="Line 2"
            component={PlainTextField}
            disabled={!!disabled}
          />
        </Col>
      </FormGroup>

      <FormGroup>
        <Col
          sm={fieldSizes.sm || 8}
          smOffset={labelSizes.sm || 2}
          md={fieldSizes.md || 8}
          mdOffset={labelSizes.md || 2}
          lg={fieldSizes.lg || 8}
          lgOffset={labelSizes.lg || 2}
        >
          <Field
            name={baseName + '.line3'}
            placeholder="Line 3"
            component={PlainTextField}
            disabled={!!disabled}
          />
        </Col>
      </FormGroup>

      <FormGroup>
        <Col
          sm={fieldSizes.sm || 8}
          smOffset={labelSizes.sm || 2}
          md={fieldSizes.md || 8}
          mdOffset={labelSizes.md || 2}
          lg={fieldSizes.lg || 8}
          lgOffset={labelSizes.lg || 2}
        >
          <Field
            name={baseName + '.town'}
            placeholder="Town"
            component={PlainTextField}
            disabled={!!disabled}
          />
        </Col>
      </FormGroup>

      <FormGroup>
        <Col
          sm={fieldSizes.sm || 8}
          smOffset={labelSizes.sm || 2}
          md={fieldSizes.md || 8}
          mdOffset={labelSizes.md || 2}
          lg={fieldSizes.lg || 8}
          lgOffset={labelSizes.lg || 2}
        >
          <Field
            name={baseName + '.county'}
            placeholder="County"
            component={PlainTextField}
            disabled={!!disabled}
          />
        </Col>
      </FormGroup>
    </div>
  );
};

export default AddressFields;

import merge from 'deepmerge';

export default function mapFields(resource, values = {}) {
  resource = merge(resource, values);

  let fields = ['data.id', 'data.type', 'data.relationships'];
  const attributes = resource.data.attributes || {};
  const relationships = resource.data.relationships || {};
  fields = mapObject(fields, 'data.attributes', attributes);
  fields = mapObject(fields, 'data.relationships', relationships);

  return fields;
}

function mapObject(fields, path, object) {
  Object.keys(object).map((key) => {
    if (path.indexOf('relationships') !== -1) {
      fields.push(path + '.' + key + '.data');
    } else if (Array.isArray(object[key])) {
      fields = mapArray(fields, path + '.' + key, object[key]);
    } else if (typeof object[key] === 'object' && object[key]) {
      fields = mapObject(fields, path + '.' + key, object[key]);
    } else {
      fields.push(path + '.' + key);
    }
  });

  return fields;
}

function mapArray(fields, path, array) {
  if (typeof array[0] === 'object' && array[0]) {
    fields = mapObject(fields, path + '[]', array[0]);
  } else {
    fields.push(path + '[]');
  }

  return fields;
}

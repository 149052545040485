import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs } from 'react-bootstrap';
import { grantPermission } from '../../../auth/redux/authActions';
import connect from 'react-redux/es/connect/connect';
import { SET_ACTIVE_TAB } from '../../redux/tabs';

class InternalTabs extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    tabs: PropTypes.array.isRequired,
  };

  handleTab(key) {
    const { dispatch, id } = this.props;

    dispatch({
      type: SET_ACTIVE_TAB,
      name: id,
      number: key,
    });
  }

  renderTabs() {
    const { tabs } = this.props;

    const ts = tabs.filter((tab) => {
      return tab.content && grantPermission(tab);
    });
    const className = 'tabs-' + ts.length;

    return ts.map((tab, key) => {
      return (
        <Tab eventKey={key + 1} title={tab.title} key={key} tabClassName={className}>
          {tab.content}
        </Tab>
      );
    });
  }

  render() {
    const { id, activeTab } = this.props;

    return (
      <Tabs activeKey={activeTab} id={id} unmountOnExit onSelect={::this.handleTab}>
        {this.renderTabs()}
      </Tabs>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    activeTab: state.tabs.activeTabs[ownProps.id],
  };
}

export default connect(mapStateToProps)(InternalTabs);

import React from 'react';
import './ProductName.scss';
import { Label } from 'react-bootstrap';
import { Box, Button, Icon } from '../../../../../../common/components';
import { goBack } from 'react-router-redux';
import { connect } from 'react-redux';

const MAPPING = {
  active: 'success',
  inactive: 'danger',
  test: 'warning'
};
const ProductName = ({ icon, status, name, title, children, onGoBack, }) => {
  if (!name) {
    return <div/>;
  }

  const displayTitle = `${name} ${title ? `- ${title}` : ''}`;

  return (
    <Box className="product-name">

      <div className={'product-name--info'}>
        <div className={'product-name--header'}>
          {icon && (
            <img src={icon} alt={name}/>
          )}
          <div className={'product-name--title'}>
            {displayTitle}
          </div>
          <Label bsStyle={MAPPING[status]}>
            <div className={'product-name--status'}> {status}</div>
          </Label>
        </div>
        <Button
          className="pull-right"
          bsStyle="link"
          handleClick={onGoBack}
        >
          <Icon name="arrow-left"/>
          Back
        </Button>
      </div>
      <div>
        {children}
      </div>


    </Box>
  );
};

export default connect(
  null, {
    onGoBack: goBack,
  }
)(ProductName);

import { connect, useSelector } from 'react-redux';
import React, { useEffect, useMemo } from 'react';
import InternalContainer from '../../layout/containers/Internal';

import { Loader } from '../../../common/components';

import EditProductForm from '../components/EditProductForm/EditProductForm';
import { actions } from '../redux/ProductReducer';
import getIncludedResource from '../../../helpers/getIncludedResource';

const DEFAULT_QUESTIONS = {
  'has-conditions-no-diagnosis': {
    'question_number': 1,
    'show_medical': false,
    'content': 'Aware of any medical condition(s) for which you have not yet had a diagnosis?',
    'type': 'boolean',
    'header': '',
    'category': 'health'
  },
  'had-surgery': {
    'show_medical': true,
    'content': 'Do you or anyone else to be insured on this policy have any medical condition(s) for which you have received a diagnosis and are on a waiting list for, or have knowledge of the need for:\n\n- surgery, or\n- inpatient treatment, or\n- investigation, or\n- referral to a specialist consultant?',
    'type': 'boolean',
    'category': 'health',
    'question_number': 2
  },
  'is-terminal-prognosis': {
    'show_medical': false,
    'content': 'Have you or anyone to be insured by this policy received a terminal diagnosis?',
    'type': 'boolean',
    'category': 'health',
    'question_number': 3
  },
  'is-travelling-against-medical-advice': {
    'show_medical': false,
    'content': 'Travelling against the advice of a medical practitioner or travelling for surgery, treatment or investigations??',
    'type': 'boolean',
    'category': 'health',
    'question_number': 4
  },
  'is-awaiting-tests': {
    'show_medical': true,
    'content': 'Awaiting tests or test results for a medical condition(s) for which you have received a diagnosis?',
    'type': 'boolean',
    'category': 'health',
    'question_number': 5
  },
  'has-received-treatment': {
    'question_number': 7,
    'show_medical': true,
    'content': 'Within the last 5 years, been treated (including prescribed medication) for: \n\n- Any respiratory condition (relating to the lungs or breathing) \n- Any heart or heart related condition \n- Any circulatory condition (relating to the blood or circulation) \n- Any kidney or renal condition \n- Any liver condition \n- Any condition relating to the pancreas e.g. diabetes \n- Any cerebral or neurological condition (relating to the brain) \n- Any type of cancer \n- Any type of stroke \n- Any central nervous system disorder \n- Any psychiatric or psychological condition',
    'type': 'boolean',
    'category': 'health',
    'header': '## Medical Screening\n\nPlease answer the following questions. If you or anyone else to be insured on this policy answer ‘Yes’ to any of these you will need to go through our medical screening process,'
  },

  'has-been-prescribed-medication': {
    'show_medical': true,
    'content': 'Been prescribed medication for any medical condition in the last 2 years?',
    'type': 'boolean',
    'category': 'health',
    'question_number': 6
  },

  'has-received-surgery': {
    'show_medical': true,
    'content': 'Received any surgery, inpatient or outpatient treatment or had any tests or investigations in hospital or clinic or been seen by a specialist consultant within the last 2 years?',
    'type': 'boolean',
    'category': 'health',
    'question_number': 8
  },


};


const ALLOWED_REGIONS = {
  'uk': 'UK',
  'europe_excluding': 'Europe',
  'worldwide_excluding': 'Worldwide Exc USA/Canada/Caribbean',
  'worldwide': 'Worldwide Inc USA/Canada/Caribbean'
};

const ALLOWED_OPTIONS = {
  'cruise_cover': {
    name: 'Cruise Cover',
    type: 'boolean',
    question: 'Cruise Cover'
  },
  'excess_waiver': {
    name: 'Excess Waiver',
    type: 'boolean',
    question: 'Excess Waiver'
  },
  'winter_sports': {
    name: 'Winter Sports',
    type: 'boolean',
    question: 'Winter Sports'
  },
  'golf_cover': {
    name: 'Golf Cover',
    type: 'boolean',
    question: 'Golf Cover'
  },
  'business_equipment': {
    name: 'Business Equipment',
    type: 'boolean',
    question: 'Business Equipment'
  },
  'gadget_cover': {
    name: 'Gadget Cover',
    type: 'boolean',
    question: 'Business Equipment'
  },
  'wedding_cover': {
    name: 'Wedding Cover',
    type: 'boolean',
    question: 'Wedding Cover'
  },
  'end_supplier_failure': {
    name: 'End Supplier Failure',
    type: 'boolean',
    question: 'End Supplier Failure'
  },
  'travel_dispute': {
    name: 'Travel Dispute',
    type: 'boolean',
    question: 'Travel Dispute'
  },
  'acceptable_activities': {
    default: 'pack1',
    name: 'Acceptable Activities',
    options: {
      pack1: 'Activity Pack 1',
      pack2: 'Activity Pack 2',
      pack3: 'Activity Pack 3',
      pack4: 'Activity Pack 4'
    },
    question: 'Acceptable Activities',
    type: 'string'
  },
  'hazardous_activities': {
    default: 'pack1',
    name: 'Hazard Activities',
    options: {
      pack1: 'Activity Pack 1',
      pack2: 'Activity Pack 2',
      pack3: 'Activity Pack 3',
      pack4: 'Activity Pack 4'
    },
    question: 'Hazard Activities',
    type: 'string'
  },

};

const ALLOWED_RESIDENCIES = {
  'uk': 'United Kingdom',
  'channel islands': 'Channel Islands',
  'isle of man': 'Isle of Man',
};

const ALLOWED_GROUP_TYPES = {
  'individual': 'Individual',
  'couple': 'Couple',
  'single_parent_family': 'Single Parent Family',
  'family': 'Family',

};

const ALLOWED_TRIP_TYPES = {
  'single': 'Single Trip',
  'annual': 'Annual Trip',


};


const getSelectValues = (allowedValues, productValues) => {
  return Object.keys(allowedValues).map((allowedKey) => {

    const isEnabled = !!productValues?.find((v) => v.key === allowedKey);

    return ({
        enabled: isEnabled,
        key: allowedKey,
        value: allowedValues[allowedKey]
      }
    );
  });
};

const formatSelectValues = (values) => {
  return values.reduce((carry, value) => {
    if (value?.enabled) {
      carry.push({
        key: value.key,
        value: value.value,
      });
    }
    return carry;

  }, []);
};

const formatMedial = (medicalQuestion) => {

  const medical = Object.keys(medicalQuestion).map((medicalKey) => {
    return {
      key: medicalKey,
      question: medicalQuestion[medicalKey]
    };

  });
  return medical.sort((a, b) => {
    return a.question.question_number < b.question.question_number ? -1 : 1;


  });
};

const formatForSaving = (values) => {
  const medical = values?.meta.medical?.reduce((carry, question) => {
    carry[question.key] = question.question;
    return carry;
  }, {});


  const annualTripDurations = values?.data?.attributes.metadata.annual_trip_durations?.map((tripDuration) => {
    const parsedTripDuration = Number.parseInt(tripDuration.key);
    return {
      key: Number.parseInt(tripDuration.key),
      value: `${parsedTripDuration} days`
    };

  }, {});
  const icon = values?.data?.attributes.icon;
  return {
    data: {
      attributes: {
        ...values?.data?.attributes,
        metadata: {
          ...values?.data?.attributes.metadata,
          options: formatSelectValues(values?.data?.attributes.metadata.options),
          regions: formatSelectValues(values?.data?.attributes.metadata.regions),
          group_type_trip_annual: formatSelectValues(values?.data?.attributes.metadata.group_type_trip_annual),
          group_type_trip_single: formatSelectValues(values?.data?.attributes.metadata.group_type_trip_single),
          annual_trip_durations: annualTripDurations

        }
      },
      relationships: {
        icon_file: {
          data: {
            id: icon?.id

          }
        }
      }
    },
    meta: {
      medical_content: {
        questions: medical

      }
    }

  };

};
const getInitialValues = (product) => {
  if (!product) {
    return null;
  }
  const schemeGroups = getIncludedResource(product?.data, product?.included, 'all_scheme_groups');

  const contents = getIncludedResource(product?.data, product?.included, 'contents');

  const medicalContent = Array.isArray(contents) ? contents?.find((content) => (content.attributes.status === 'active' && content.attributes.content_type === 'question')) : undefined;

  const medical = formatMedial(medicalContent ? medicalContent.attributes.sections.questions : DEFAULT_QUESTIONS);
  const productMeta = product?.data?.attributes?.metadata ?? {};
  const {
    annual_trip_durations: annualTripDurations,
    options,
    regions,
    residencies,
    group_type_trip_annual: groupTypesAnnual,
    group_type_trip_single: groupTypesSingle
  } = productMeta;

  const tripTypeFromGroups = Array.isArray(schemeGroups) ? schemeGroups?.reduce((carry, schemeGroup) => {
    const tripType = schemeGroup?.attributes?.group_key;
    if (ALLOWED_TRIP_TYPES?.[tripType]) {

      carry.push({
        key: tripType,
        value: ALLOWED_TRIP_TYPES?.[tripType]
      });
    }


    return carry;
  }, []) : [];


  const regionSelect = getSelectValues(ALLOWED_REGIONS, regions);
  const optionSelect = getSelectValues(ALLOWED_OPTIONS, options);
  const residencySelect = getSelectValues(ALLOWED_RESIDENCIES, residencies);
  const singleGroupTypes = getSelectValues(ALLOWED_GROUP_TYPES, groupTypesSingle);
  const annualGroupTypes = getSelectValues(ALLOWED_GROUP_TYPES, groupTypesAnnual);

  return {
    data: {
      attributes: {
        ...product?.data?.attributes,
        metadata: {
          ...productMeta,
          annual_trip_durations: annualTripDurations,
          // //cover_levels: objectToArray(coverLevels),
          options: optionSelect,
          regions: regionSelect,
          residencies: residencySelect,
          trip_types: tripTypeFromGroups,
          group_type_trip_annual: singleGroupTypes,
          group_type_trip_single: annualGroupTypes,


        }
      }
    },
    meta: {
      medical: medical
    }
  };


};


const INCLUDES = ['all_scheme_groups', 'contents'];
const ProductEditContainer = ({
  params,
  onGetProduct,
  onUpdateProduct,
}) => {
  const { productId } = params;

  const {
    product,
    isLoading,
  } = useSelector((state) => state.productManagement);


  const initialValues = useMemo(() => getInitialValues(product), [product]);

  const currentProductId = product?.data?.id;

  useEffect(
    () => {
      if (!currentProductId || currentProductId !== productId) {
        onGetProduct(productId, INCLUDES);
      }

    },
    [productId, currentProductId, onGetProduct],
  );

  const save = (values) => {
    onUpdateProduct(currentProductId, formatForSaving(values));
  };
  if (isLoading || currentProductId !== productId || !initialValues) {
    return (
      <div className="admin-area">
        <InternalContainer title="Product management">
          <Loader/>
        </InternalContainer>
      </div>
    );
  }
  return (
    <div className="admin-area">
      <InternalContainer title="Product management">
        <div>
          <EditProductForm
            onSave={save}
            resource={initialValues}

          />
        </div>

      </InternalContainer>
    </div>
  );
};

export default connect(
  null,
  {
    onGetProduct: actions.getProduct,
    onUpdateProduct: actions.updateClonedProduct
  }
)(ProductEditContainer);

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, FieldArray, getFormValues, reduxForm } from 'redux-form';
import { Col, Form, Row } from 'react-bootstrap';
import SchemeManagementSectionContainer
  from '../SchemeManagementSectionContainer/SchemeManagementSectionContainer';
import TextArea from '../../../content/components/TextArea';

const SMSMessageCoverLevelField = ({ input, isViewOnly }) => {
  return (
    <>
      <SchemeManagementSectionContainer
        title={''}
        subTitle={`${input.value?.cover_level ?? ''}`}
        isViewOnly={isViewOnly}
      >
        <Row className={'section-item'}>
          <Col sm={12}>
            <Field
              labelSize={2}
              type="textarea"
              component={TextArea}
              label="SMS Message Text:"
              textOnly={true}
              minHeight={'100px'}
              name={`${input.name}.metadata.sms_message`}
            />
          </Col>
        </Row>
      </SchemeManagementSectionContainer>
    </>
  );
};

const SmsMessagesFieldArray = ({ fields, isViewOnly }) => {
  return (
    <>
      {fields.map((field) => (
        <>
          <Field
            labelSize={2}
            type="textarea"
            component={SMSMessageCoverLevelField}
            label="SMS Message Text:"
            textOnly={true}
            isViewOnly={isViewOnly}
            name={`${field}`}
          />
        </>
      ))}
    </>
  );
};

const SmsConfirmationMessageForm = ({ isViewOnly, handleSubmit, formValues, title }) => {
  const hasSchemeDetails = !!formValues?.data?.attributes?.scheme_details?.length;
  if (!hasSchemeDetails) {
    return (
      <Form horizontal onSubmit={handleSubmit}>
        <SchemeManagementSectionContainer
          title={title || 'SMS Policy Confirmation'}
          subTitle={'SMS Policy Confirmation'}
          isViewOnly={isViewOnly}
        >
          <Row className={'section-item'}>
            <Col sm={12}>
              <Field
                labelSize={2}
                type="textarea"
                component={TextArea}
                label="SMS Message Text:"
                textOnly={true}
                name="data.attributes.metadata.sms_message"
              />
            </Col>
          </Row>
        </SchemeManagementSectionContainer>
      </Form>
    );
  }
  return (
    <Form horizontal onSubmit={handleSubmit}>
      <h2>SMS Policy Confirmation</h2>
      <FieldArray
        name={'data.attributes.scheme_details'}
        component={SmsMessagesFieldArray}
        handlerPath={'claims_handlers'}
        isViewOnly={isViewOnly}
      />
    </Form>
  );
};

export default compose(
  connect((state, props) => {
    return {
      formValues: getFormValues(props.formName)(state),
      destroyOnUnmount: false,
      enableReinitialize: true,
      form: props.formName,
    };
  }),
  reduxForm({}),
)(SmsConfirmationMessageForm);

import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Loader } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import { actions } from '../redux/ProductReducer';
import { WhitelabelProductShowContainer } from './WhitelabelProductShowContainer';
import ActiveProductContainer from './ActiveProductContainer';

const INCLUDES = ['all_scheme_groups', 'contents'];

const ProductShowContainer = ({
  params,
  onGetProduct,
  location,
}) => {

  const { productId } = params;
  const {
    product,
    isLoading
  } = useSelector((state) => state.productManagement);

  const currentProductId = product?.data?.id;

  useEffect(
    () => {
      if (!currentProductId || currentProductId !== productId) {
        onGetProduct(productId, INCLUDES);
      }
    },
    [productId, currentProductId, onGetProduct],
  );

  if (isLoading || !product) {
    return (
      <div className="admin-area">
        <InternalContainer title="Product management">
          <Loader/>
        </InternalContainer>
      </div>
    );
  }
  const isWhitelabel = product?.data?.attributes?.metadata?.is_wl_product;
  if (isWhitelabel) {
    return (
      <WhitelabelProductShowContainer
        productId={productId}
        currentLocation={location.pathname}

      />
    );
  }
  return (
    <ActiveProductContainer
      productId={productId}
      currentLocation={location.pathname}
    />);
};

export default connect(
  null,
  {
    onGetProduct: actions.getProduct
  }
)(ProductShowContainer);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import {
  Button,
  Modal,
  Loader,
  HorizontalFormControl,
} from '../../../../common/components';
import { FormattedDate, FormattedTime } from 'react-intl';
import displayName from '../../../../helpers/displayName';
import {
  recalculateCommissions,
  reallocateCommissions,
  clearCommissionReallocation,
} from '../../redux/accountActions';
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux';
import { toastr } from 'react-redux-toastr';
import { get, keys } from 'lodash';
import './styles.scss';

const FORM_NAME = 'ReallocateCommissionForm';

class ReallocateCommissionsModal extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    policyNumber: PropTypes.string,
    premium: PropTypes.object,
    brokerOrganisationId: PropTypes.string,
    networkOrganisationId: PropTypes.string,
    callback: PropTypes.func,
    accountCommissions: PropTypes.object,
    isCommissionRecalculating: PropTypes.bool,
  };

  formatDate(cell) {
    return (
      <span>
        <FormattedDate value={new Date(cell)} /> <FormattedTime value={new Date(cell)} />
      </span>
    );
  }

  handleSubmit() {
    const { premium, formValues, dispatch } = this.props;
    if (!this.equalCommission()) {
      toastr.error(
        'Commissions reallocation splits must equal ' + this.normalisedTotal() + '%',
      );
      return false;
    }
    if (this.props.dispatch(reallocateCommissions(premium.id, formValues))) {
      dispatch(closeModal(FORM_NAME));
    }
  }

  componentWillMount() {
    this.props.dispatch(clearCommissionReallocation());
  }

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded, callback } = this.props;

    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('');
      }
      dispatch(closeModal(FORM_NAME));
    }
  }

  componentDidUpdate(oldProps) {
    const { formValues } = this.props;

    if (
      get(oldProps.formValues, 'meta.commissions') !==
        get(formValues, 'meta.commissions') &&
      this.equalCommission()
    ) {
      this.recalculate(formValues);
    }
  }

  recalculate(commissions) {
    const { dispatch, premium } = this.props;
    dispatch(recalculateCommissions(premium.id, commissions));
  }

  equalCommission() {
    return parseFloat(this.normalisedTotal()) === parseFloat(this.totalCommission());
  }

  normalisedTotal() {
    const { premium } = this.props;
    let total = 0;

    premium.attributes.commissions.map((commission) => {
      const commissionRate = get(commission, 'effective_rate', commission.rate) || 0;
      total += parseFloat(commissionRate) * 100;
    });

    return total;
  }

  totalCommission() {
    const { formValues } = this.props;
    let total = 0;
    const commissions = get(formValues, 'meta.commissions', {});

    keys(commissions).map((commission) => {
      const commissionRate = commissions[commission] || 0;
      total += parseFloat(commissionRate);
    });

    return total;
  }

  handleOpen() {
    const { dispatch, reset } = this.props;
    dispatch(reset());
    dispatch(openModal(FORM_NAME));
  }

  render() {
    const {
      premium,
      policyNumber,
      handleSubmit,
      accountCommissions,
      isCommissionRecalculating,
    } = this.props;

    let modalFooter = <div>&nbsp;</div>;
    let commissionSplits = <div>&nbsp;</div>;
    const commissions = get(premium, 'attributes.commissions', []);

    if (commissions) {
      modalFooter = (
        <Button
          label="Post Commission Adjustment"
          bsStyle="primary"
          className="pull-right"
          type="submit"
          handleClick={::this.handleSubmit}
        />
      );

      let includeCommission = {
        owner: 'owner',
        broker: this.props.brokerOrganisationId || false,
        network: this.props.networkOrganisationId || false,
      };

      commissions.map((commission, key) => {
        if (!get(commission, 'commission_type')) {
          commissions.splice(key);
        }
        includeCommission[commission.commission_type] = false;
      });

      let premiumCommissions = commissions;
      keys(includeCommission).map((commissionType) => {
        if (includeCommission[commissionType] !== false) {
          premiumCommissions.push({
            commission_type: commissionType,
            organisation_id: includeCommission[commissionType],
            effective_rate: 0,
            rate: 0,
            amount: 0,
          });
        }
      });

      commissionSplits = premiumCommissions.map((commission, i) => (
        <div key={i} className="row form-horizontal">
          <div className="col-sm-7">
            <HorizontalFormControl
              disabled
              label={displayName(commission.commission_type)}
              labelSize={3}
              input={{ value: '' }}
              percentage
              placeholder={(get(commission, 'effective_rate', commission.rate) * 100)
                .toFixed(2)
                .toString()}
              monetaryValue={commission.amount.toString()}
              className="text-right adjustable"
            />
          </div>
          <div className="col-sm-5">
            <Field
              name={'meta.commissions[' + commission.organisation_id + ']'}
              percentage
              inputOnly
              placholder={(commission.rate * 100).toFixed(2).toString()}
              monetaryValue={get(
                accountCommissions,
                'meta.commissions[' + commission.organisation_id + '].amount',
                0,
              ).toString()}
              component={HorizontalFormControl}
              isCalc={isCommissionRecalculating}
              className="text-right adjustable"
            />
          </div>
        </div>
      ));
    }

    return (
      <div>
        <Button
          className="pull-right"
          type="button"
          bsStyle="primary"
          label="Reallocate Commission"
          handleClick={::this.handleOpen}
        />
        <Modal
          name={FORM_NAME}
          title={'Commission reallocation'}
          close
          footer={modalFooter}
        >
          {premium ? (
            <div className="commissions-container">
              <h4>
                {policyNumber} {premium.attributes.premium_type} (
                <FormattedDate value={premium.attributes.created_at} /> )
              </h4>
              <form onSubmit={handleSubmit(::this.handleSubmit)}>
                <div className="row commission-header-row">
                  <div className="col-sm-push-2 col-sm-5 text-center">
                    <strong>Current Allocation</strong>
                  </div>
                  <div className="col-sm-push-2 col-sm-5 text-center">
                    <strong>New Allocation</strong>
                  </div>
                </div>
                {commissionSplits}
                <div className="row form-horizontal">
                  <div className="col-sm-7">
                    <HorizontalFormControl
                      name="meta.total.original"
                      disabled
                      label="Total"
                      labelSize={3}
                      input={{ value: '' }}
                      percentage
                      placeholder={this.normalisedTotal()
                        .toFixed(2)
                        .toString()}
                      monetaryValue={premium.attributes.commission.toString()}
                      className="text-right adjustable"
                    />
                  </div>
                  <div className="col-sm-5">
                    <HorizontalFormControl
                      name="meta.total.new"
                      disabled
                      input={{ value: '' }}
                      inputOnly
                      percentage
                      placeholder={this.totalCommission()
                        .toFixed(2)
                        .toString()}
                      monetaryValue={premium.attributes.commission.toString()}
                      isCalc={isCommissionRecalculating}
                      className={
                        'text-right adjustable' +
                        (!this.equalCommission() && ' total-error')
                      }
                    />
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <Loader />
          )}
        </Modal>
      </div>
    );
  }
}

const form = reduxForm({ form: FORM_NAME })(ReallocateCommissionsModal);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  const values = selector(
    state,
    'meta.commissions',
    'meta.total.original',
    'meta.total.new',
  );

  return {
    initialValues: props.resource,
    formValues: values,
    accountCommissions: state.account.commissions,
    isCommissionRecalculating: state.account.isCommissionRecalculating,
  };
};

export default connect(mapStateToProps)(form);

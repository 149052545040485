import * as actions from './printJobsReducer';
import Axios from 'axios';
import { push } from 'react-router-redux';
import { toastr } from 'react-redux-toastr';
import queryString from '../../../helpers/queryString';
import handleErrorResponse from '../../../helpers/handleErrorResponse';

export const defaultIncludes = ['documents', 'files'];

export function isLoading(status) {
  return {
    type: actions.IS_LOADING,
    status,
  };
}

export function unloadResource() {
  return { type: actions.UNLOAD_RESOURCE };
}

export function getPrintJob(id, includes = defaultIncludes) {
  return (dispatch) => {
    let endpoint = 'print-jobs/' + id;
    endpoint += queryString(includes);
    dispatch(isLoading(true));

    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: actions.SET_PRINT_JOB,
          data: response.data,
        });
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error);
      });
  };
}

export function downloadDocument(jobId, fileId) {
  return (dispatch) => {
    let endpoint = 'print-jobs/' + jobId + '/files/' + fileId + ':download';
    dispatch(isLoading(true));

    return Axios.get(endpoint)
      .then((response) => {
        dispatch(isLoading(false));

        window.location.href = response.data.meta.url;
      })
      .catch((error) => {
        handleErrorResponse(error);
      });
  };
}

export function createPrintJob(type) {
  return (dispatch) => {
    let endpoint = '/print-jobs?include=documents,files';
    const values = {
      meta: {
        type: type,
      },
    };
    dispatch(isLoading(true));

    return Axios.post(endpoint, values)
      .then((response) => {
        dispatch(push('/policies/print-jobs/' + response.data.data.id));
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error);
      });
  };
}

export function cancelPrintJob(id) {
  return (dispatch) => {
    let endpoint = '/print-jobs/' + id + ':cancel';

    dispatch(isLoading(true));

    return Axios.post(endpoint)
      .then((response) => {
        if (response.status === 204) {
          toastr.success('Success', 'Cancelled');
        } else {
          toastr.error('Error', response.status);
        }

        dispatch(getPrintJob(id));

        return true;
      })
      .catch((error) => {
        handleErrorResponse(error);
      });
  };
}

export function runPrintJob(id) {
  return (dispatch) => {
    let endpoint = '/print-jobs/' + id + ':run';

    dispatch(isLoading(true));

    return Axios.post(endpoint)
      .then((response) => {
        if (response.status === 202) {
          toastr.success('Success');
        } else {
          toastr.error('Error', response.status);
        }

        dispatch(getPrintJob(id));

        return true;
      })
      .catch((error) => {
        handleErrorResponse(error);
      });
  };
}

export function postPrintJob(id) {
  return (dispatch) => {
    let endpoint = '/print-jobs/' + id + ':post';

    dispatch(isLoading(true));

    return Axios.post(endpoint)
      .then((response) => {
        if (response.status === 202) {
          toastr.success('Success');
        } else {
          toastr.error('Error', response.status);
        }

        dispatch(getPrintJob(id));

        return true;
      })
      .catch((error) => {
        handleErrorResponse(error);
      });
  };
}

export function fetchDashboard() {
  return (dispatch) => {
    dispatch(isLoading(true));
    return Axios.get('/print-job-queue:count').then((response) => {
      dispatch({
        type: actions.SET_STATS,
        data: response.data.meta,
      });
      return true;
    });
  };
}

import React from 'react';
import { get } from 'lodash';
import { Col, FormGroup, ControlLabel } from 'react-bootstrap';
import './styles.scss';

const RadioGroup = ({
  input,
  label,
  labelSize,
  infoButton,
  options,
  objectKey,
  inline,
  width,
  meta: { touched, error },
}) => {
  const renderRadioGroup = () => {
    return (
      <div className={'radio-group'}>
        {options.map((option, key) => {
          const value = objectKey ? get(option.value, objectKey) : option.value;

          const onBlur = () => input.onBlur(value);
          const onChange = () => input.onChange(value);
          const complexValueOverrides = { onBlur, onChange };

          return (
            <div
              className={'radio-container' + (inline ? ' inline' : '')}
              key={key}
              style={width ? { width: width } : {}}
            >
              <div className={'form-radio'}>
                <input
                  {...input}
                  {...complexValueOverrides}
                  type="radio"
                  id={input.name + option.label}
                  value={option.value}
                  checked={input.value === value}
                />
                <label htmlFor={input.name + option.label}/>
              </div>
              <div
                className="radio-label"
                onClick={() => input.onChange(option.value)}
              >
                {option.label}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  if (label) {
    return (
      <FormGroup className={(touched && error) ? 'has-error' : ''}>
        <Col sm={labelSize}>
          <ControlLabel className={inline && ' inline-radio-label'}>
            {label}
          </ControlLabel>{' '}
          {infoButton}
        </Col>
        <Col sm={12 - labelSize}>
          {renderRadioGroup()}
        </Col>
      </FormGroup>
    );
  }

  return renderRadioGroup();
};

export default RadioGroup;

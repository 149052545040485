import React, {  useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { getBroker, getAllProducts } from '../redux/brokerActions';
import { hasPermission } from '../../auth/redux/authActions';
import { Box, Button, Loader } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import BrokerDetails from '../components/BrokerDetails/BrokerDetails';
import BrokerContracts from '../components/BrokerContracts/BrokerContracts';
import BrokerProducts from '../components/BrokerProducts';
import BrokerUsers from '../components/BrokerUsers/BrokerUsers';
import BrokerNotes from '../components/BrokerNotes/BrokerNotes';
import BrokerAudit from '../components/BrokerAudit';

import InternalTabs from '../../layout/components/InternalTabs/InternalTabs';
import BrokerLabel from '../components/BrokerLabel/BrokerLabel';
import BrokerTheme from '../components/BrokerTheme/BrokerTheme';

const TAB_NAME = 'brokerTabs';

const BrokerTab = ({ children, name, status }) => (
  <Box>
    <BrokerLabel brokerStatus={status} name={name}/>
    {children}

  </Box>
);


const BrokerShowContainer = ({ params,getBroker, getAllProducts }) => {


  const { id } = params;
  const includes = [
    'organisation',
    'users',
    'channel',
    'broker_region',
    'networks',
    'channel',
    'channels',
    'channel.theme',
    'channel.contents',
    'channel.products',
    'channel.contents.template',
    'active_commissions',
    'active_commissions.product',
    'primary_contacts',
    'accounts_contacts',
    'tracking_links',
  ];
  if (hasPermission('account.view')) {
    includes.push('organisation.account');
  }

  useEffect(() => {

    getBroker(id, includes);
    getAllProducts();


  }, [getBroker, id, getAllProducts]);

  const { resources, } = useSelector((state) => state.broker);


  const refreshBroker = () => {
    getBroker(id, includes);
  };

  const resource = resources?.[id];

  if (!resource?.data) {
    return (
      <InternalContainer title="Broker"
                         buttons={<Button
                           bsStyle="default"
                           label="Back"
                           isBackButton
                           leftIcon="caret-left"
                         />}>
        <Loader/>
      </InternalContainer>
    );
  }



  return (
    <InternalContainer title="Broker"
                       buttons={
                         <Button
                           bsStyle="default"
                           label="Back"
                           isBackButton
                           leftIcon="caret-left"/>}>

        <InternalTabs
          id={TAB_NAME}
          tabs={[
            {
              permissions: ['broker.view'],
              title: 'Details',
              content: (
                <>
                  <BrokerTab
                    name={resource?.data?.attributes?.name}
                    status={resource?.data?.attributes?.status}
                  >
                    <BrokerDetails
                      resource={resource}
                    />
                  </BrokerTab>
                </>
              ),
            },
            {
              permissions: ['user.view'],
              title: 'Users',
              content: (
                  <BrokerTab
                    name={resource?.data?.attributes?.name}
                    status={resource?.data?.attributes?.status}
                  >
                    <BrokerUsers
                      resource={resource}
                    />
                  </BrokerTab>
              ),
            },
            {
              permissions: ['broker.edit'],
              title: 'Products',
              content: (
                <BrokerTab
                  name={resource?.data?.attributes?.name}
                  status={resource?.data?.attributes?.status}
                >
                  <BrokerProducts
                    resource={resource}
                  />
                </BrokerTab>
              ),
            },
            {
              permissions: ['broker.approve'],
              title: 'Contracts',
              content: (
                <BrokerTab
                  name={resource?.data?.attributes?.name}
                  status={resource?.data?.attributes?.status}
                >
                  <BrokerContracts
                    resource={resource}
                  />
                </BrokerTab>
              ),
            },
            {
              permissions: ['broker.approve'],
              title: 'White Label',
              content: (
                <BrokerTab
                  name={resource?.data?.attributes?.name}
                  status={resource?.data?.attributes?.status}
                >
                  <BrokerTheme
                    onRefreshBroker={refreshBroker}
                    resource={resource}
                  />
                </BrokerTab>
              ),
            },
            {
              permissions: ['broker.notes_view'],
              title: 'Notes',
              content: (
                <BrokerTab
                  name={resource?.data?.attributes?.name}
                  status={resource?.data?.attributes?.status}
                >
                  <BrokerNotes
                    resource={resource}
                  />
                </BrokerTab>
              ),
            },
            {
              permissions: ['system.audit_logs_view'],
              orgTypes: ['owner'],
              title: 'Audit',
              content: (

                <BrokerTab
                  name={resource?.data?.attributes?.name}
                  status={resource?.data?.attributes?.status}
                >
                  <BrokerAudit
                    brokerId={resource.data.id}
                    resource={resource}
                  />
                </BrokerTab>


              ),
            },
          ]}
        />

    </InternalContainer>
  );
};
export default connect(null, {
  getAllProducts: getAllProducts,
  getBroker: getBroker
})(BrokerShowContainer);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InternalSection from '../components/InternalSection/InternalSection';

class InternalContainer extends Component {
  static propTypes = {
    title: PropTypes.string,
    breadcrumbs: PropTypes.object,
  };

  render() {
    return <InternalSection {...this.props} />;
  }
}

export default connect()(InternalContainer);

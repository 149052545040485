import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, FormGroup, ControlLabel } from 'react-bootstrap';
import selectTheme, { styles } from '../../../../helpers/selectTheme';
import { startCase } from 'lodash';
import Select from 'react-select';

class StatusFilter extends Component {
  static propTypes = {
    label: PropTypes.string,
    labelSize: PropTypes.number,
    infoButton: PropTypes.element,
  };

  render() {
    const {
      onChange,
      label,
      labelSize,
      infoButton,
      policyFilter,
      trackingList,
      printJobs,
    } = this.props;
    let options = ['all', 'quote', 'applied', 'issued'];

    if (policyFilter) {
      options = [
        'all',
        'pending',
        'cancelled',
        'renewed',
        'on cover',
        'expired',
        'issued',
        'active',
      ];
    }

    if (printJobs) {
      options = ['processing', 'pending', 'success', 'failed', 'cancelled', 'complete'];
    }

    if (trackingList) {
      options = ['active', 'inactive'];
    }

    return (
      <FormGroup controlId="select-status">
        {label && (
          <Col componentClass={ControlLabel} sm={labelSize}>
            {label} {infoButton}
          </Col>
        )}
        <Col sm={12 - labelSize}>
          <Select
            theme={(theme) => selectTheme(theme)}
            styles={styles}
            onChange={onChange}
            options={options.map((option) => {
                return { label: startCase(option), value: option };
              })
            }
            defaultValue={{ label: 'All', value: '' }}
          />
        </Col>
      </FormGroup>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(StatusFilter);

import React, { Component } from 'react';
import LoginContainer from '../../auth/containers/Login';

export default class HomepageContainer extends Component {
  render() {
    return (
      <div>
        <div className="guest-title"> Welcome to the P J Hayman Insurance Portal</div>
        <LoginContainer {...this.props} />
      </div>
    );
  }
}

import '../brochure.scss';
import { connect } from 'react-redux';
import Markdown from 'react-remarkable';
import React, { Component } from 'react';
import { Box } from '../../../common/components';
import CenteredSection from '../components/CenteredSection/CenteredSection';

class OurProducts extends Component {
  componentWillMount() {
    const { layout, router } = this.props;
    const product = layout.activeBrochureProduct;

    if (!product) {
      router.push('/');
    }
  }

  render() {
    const { layout } = this.props;
    const product = layout.activeBrochureProduct;

    return (
      <div className="guest-section">
        <div className="guest-title">Products</div>
        <CenteredSection className="page-content">
          <Box>
            <h2>{product.attributes.name}</h2>
            <Markdown source={product.attributes.metadata.details} />
          </Box>
        </CenteredSection>
      </div>
    );
  }
}

export default connect((state) => ({
  layout: state.layout,
}))(OurProducts);

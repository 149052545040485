import { Button, ButtonBar } from '../../../../common/components';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

const ExpiredMidTermAdjustmentButton = ({ productId, policyId }) => {
  const [showModal, setShowModal] = useState(false);
  const to = '/products/' + productId + '/adjustment/' + policyId;

  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <Modal.Title>Edit Expired Policy?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You are about to edit an Expired Policy - Are you sure you wish to continue?
        </Modal.Body>
        <Modal.Footer>
          <ButtonBar>

            <Button
              label="Continue"
              className="pull-right"
              key="mta-button"
              bsStyle="primary"
              rightIcon="pencil"
              link
              to={to}
            />
            <Button
              label="Cancel"
              className="pull-right"
              key="cancel-button"
              bsStyle="default"
              rightIcon="ban"
              handleClick={handleCloseModal}
            />
          </ButtonBar>
        </Modal.Footer>
      </Modal>
      <Button
        label="Mid-term Adjustment"
        className="pull-right"
        key="mta-button"
        bsStyle="primary"
        rightIcon="pencil"
        handleClick={handleOpenModal}
      />
    </div>
  );
};

export default ExpiredMidTermAdjustmentButton;

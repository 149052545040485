import React from 'react';

export const matching = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95 95">
    <title>Icons</title>
    <path d="M49.62,30.19V72.91H69.74A1,1,0,0,1,70.79,74v2.12a1,1,0,0,1-1.06,1.06H25.26a1,1,0,0,1-1.06-1.06V74a1,1,0,0,1,1.06-1.06H45.38V30.19a6.41,6.41,0,0,1-3.87-3.87H25.26a1,1,0,0,1-1.06-1.06V23.15a1,1,0,0,1,1.06-1.06H41.51a6.35,6.35,0,0,1,12,0H69.74a1,1,0,0,1,1.06,1.06v2.12a1,1,0,0,1-1.06,1.06H53.49A6.41,6.41,0,0,1,49.62,30.19Z" />
    <path d="M26.61,31.41a2.62,2.62,0,0,0-4.58,0l-1.15,2.06C12.44,48.57,9,55.09,9,56c0,6.84,9.14,10,15.32,10s15.32-3.19,15.32-10c0-.88-3.44-7.4-11.89-22.51Zm9.57,24.06H12.46L24.32,33.72Z" />
    <path d="M74.14,33.52,73,31.41a2.62,2.62,0,0,0-4.58,0l-1.15,2.06C58.8,48.57,55.35,55.09,55.35,56c0,6.84,9.14,10,15.32,10S86,62.81,86,56C86,55.08,82.67,48.79,74.14,33.52Zm8.4,22H58.81L70.68,33.72Z" />
  </svg>
);

export default matching;

import React, { useState } from 'react';
import { Field } from 'redux-form';
import OptionHazardousRatesTable from './OptionHazardousRatesTable';
import { Button, Icon } from '../../../../../common/components';
import { Col, ControlLabel, FormGroup, Row } from 'react-bootstrap';
import SchemeManagementSectionContainer
  from '../../SchemeManagementSectionContainer/SchemeManagementSectionContainer';
import OptionSingleRate from './OptionSingleRate';
import OptionAncillary from './OptionAncillary';
import Select from 'react-select';
import selectTheme from '../../../../../helpers/selectTheme';
import CoverLevelRatesTable from './CoverLevelRatesTable';

const getEmptyOption = (optionType, name, code, coverLevels) => {
  if (optionType === 'single_rate') {
    return {
      name: name,
      key: code,
      input: 'boolean',
      rate: 0,
      rate_type: 'fixed',
    };
  }
  if (optionType === 'rates_table') {

    const coverLevelList = coverLevels?.reduce((carry, coverLevel) => {
      carry[coverLevel] = 0;
      return carry;
    }, {});
    return {
      name: name,
      key: code,
      input: 'boolean',
      arguments: ['cover_level'],
      rates: coverLevelList,
      rate_type: 'fixed',
    };
  }

  if (optionType === 'haz_act') {
    return {
      key: code,
      name: name,
      default: 'pack1',
      options: [
        { key: 'pack1', rate: 0, name: 'Activity Pack 1' },
        { key: 'pack2', rate: 0, name: 'Activity Pack 2' },
        { key: 'pack3', rate: 0, name: 'Activity Pack 3' },
        { key: 'pack4', rate: 0, name: 'Activity Pack 4' },
        { key: 'pack5', rate: 0, name: 'Activity Pack 5' },
        { key: 'pack6', rate: 0, name: 'Activity Pack 6' },
        { key: 'pack7', rate: 0, name: 'Activity Pack 7' },
        { key: 'pack8', rate: 0, name: 'Activity Pack 8' },
      ],
      rate_type: 'loading',
    };
  }

  if (optionType === 'bolton') {
    return {
      bolt_on: true,
      input: 'boolean',
      key: code,
      name: name,
      rate_type: 'ancillary_scheme',
    };
  }


};

const RemoveButton = ({ onRemove, index }) => {
  const handleRemove = () => {
    onRemove(index);
  };
  return (
    <>
      <Col sm={12}>
        <Button
          size="xs"
          handleClick={handleRemove}
          className="pull-right remove-option-button"
        >
          Remove <Icon name={'trash'}/>
        </Button>
      </Col>
    </>
  );
};

const OptionMap = ({ input, isViewOnly, onRemove, index, coverLevels }) => {

  const hasOptions = !!input?.value?.options?.length;
  const rateType = input?.value?.rate_type;
  const hasArguments = !!input?.value?.arguments;
  const isSingleRate = !hasArguments && !hasOptions;
  if (hasOptions) {
    return (
      <SchemeManagementSectionContainer
        subTitle={input.value.name}
        isViewOnly={isViewOnly}
      >
        <Row className={'section-item'}>
          <Field
            name={input.name}
            component={OptionHazardousRatesTable}
            isViewOnly={isViewOnly}
          />
          <RemoveButton onRemove={onRemove} index={index}/>
        </Row>
      </SchemeManagementSectionContainer>
    );
  }
  if (rateType === 'ancillary_scheme') {
    return (
      <SchemeManagementSectionContainer
        subTitle={input.value.name}
        isViewOnly={isViewOnly}
      >
        <Row className={'section-item'}>
          <Field
            name={input.name}
            component={OptionAncillary}
            isViewOnly={isViewOnly}
            coverLevels={coverLevels}
          />
          <RemoveButton onRemove={onRemove} index={index}/>
        </Row>
      </SchemeManagementSectionContainer>
    );
  }

  if (isSingleRate) {
    return (
      <SchemeManagementSectionContainer
        subTitle={input.value.name}
        isViewOnly={isViewOnly}
      >
        <Row className={'section-item'}>
          <Field
            name={input.name}
            component={OptionSingleRate}
            isViewOnly={isViewOnly}
            coverLevels={coverLevels}
          />
          <RemoveButton onRemove={onRemove} index={index}/>
        </Row>
      </SchemeManagementSectionContainer>
    );
  }
  if (hasArguments) {
    return (
      <SchemeManagementSectionContainer
        subTitle={input.value.name}
        isViewOnly={isViewOnly}
      >
        <Row className={'section-item'}>
          <Field
            name={input.name}
            component={CoverLevelRatesTable}
            isViewOnly={isViewOnly}
            coverLevels={coverLevels}
          />
          <RemoveButton onRemove={onRemove} index={index}/>
        </Row>
      </SchemeManagementSectionContainer>
    );
  }
  return null;
};

const OptionsFieldArray = ({ fields, isViewOnly, optionList, coverLevels }) => {

  const [showAddOption, setShowAddOption] = useState(false);
  const [newOption, setNewOption] = useState(false);

  const handleShowAddOption = () => {
    setShowAddOption(true);
  };
  const handleNewOptionChange = (option) => {
    setNewOption(option.value);
  };

  const handleNewOptionTypeSelect = (option) => {

    const emptyOption = getEmptyOption(
      option.value,
      newOption.attributes.name,
      newOption.attributes.code,
      coverLevels
    );
    fields.push(emptyOption);
    setShowAddOption(false);
    setNewOption(false);
  };

  const handleRemoveOption = (index) => {
    fields.remove(index);
  };

  return (
    <>
      {!fields.length && (
        <div>
          <h3>No Options on this scheme</h3>
        </div>
      )}
      {fields.map((field, index) => {
        return (
          <>
            <Field
              name={field}
              component={OptionMap}
              onRemove={handleRemoveOption}
              index={index}
              isViewOnly={isViewOnly}
              coverLevels={coverLevels}
            />
          </>
        );
      })}
      {!isViewOnly && (
        <Row>
          <Col sm={2}>
            <Button
              className="benefit-btn"
              bsStyle="primary"
              //size="xs"
              handleClick={handleShowAddOption}
              viewOnly={isViewOnly}
            >
              Add Option
            </Button>
          </Col>
          {showAddOption && !!optionList?.length && (
            <Col sm={4}>
              <FormGroup>
                <Col sm={4}>
                  <ControlLabel> New Option: </ControlLabel>
                </Col>
                <Col sm={8}>
                  <Select
                    theme={selectTheme}
                    options={optionList}
                    onChange={handleNewOptionChange}
                  />
                </Col>
              </FormGroup>
            </Col>
          )}

          {newOption && (
            <Col sm={4}>
              <FormGroup>
                <Col sm={4}>
                  <ControlLabel> Option Type: </ControlLabel>
                </Col>
                <Col sm={8}>
                  <Select
                    theme={selectTheme}
                    options={[
                      { value: 'haz_act', label: 'Acceptable Activities' },
                      { value: 'single_rate', label: 'Single Rate' },
                      { value: 'bolton', label: 'Bolton' },
                      { value: 'rates_table', label: 'Rates Per Cover Level' },
                    ]}
                    onChange={handleNewOptionTypeSelect}
                  />
                </Col>
              </FormGroup>
            </Col>
          )}
        </Row>
      )}
    </>
  );
};
export default OptionsFieldArray;

export const SET_STATS = 'socrates-online/print-jobs/SET_STATS';
export const SET_PRINT_JOB = 'socrates-online/print-jobs/SET_PRINT_JOB';
export const IS_LOADING = 'socrates-online/print-jobs/IS_LOADING';
export const UNLOAD_RESOURCE = 'socrates-online/print-jobs/UNLOAD_RESOURCE';

export const initialState = {
  isLoading: false,
  resource: null,
  stats: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_PRINT_JOB:
      return {
        ...state,
        isLoading: false,
        resource: {
          ...action.data,
        },
      };
    case UNLOAD_RESOURCE:
      return {
        ...state,
        resource: null,
      };
    case SET_STATS:
      return {
        ...state,
        isLoading: false,
        stats: {
          ...action.data,
        },
      };
    case IS_LOADING: {
      return {
        ...state,
        isLoading: action.status,
      };
    }
    default:
      return state;
  }
}

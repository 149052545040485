import { Modal } from 'react-bootstrap';
import { Field, Form, reduxForm } from 'redux-form';
import { DatePicker } from '../../../../common/components';
import { Button } from '../../../../common/components/Button/Button';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';

const LintFailures = ({ criticalFailures, failures }) => {
  if (!criticalFailures?.length && !failures?.length) {
    return null;
  }
  return (
    <div>
      <p>
        {' '}
        WARNING: The following issues were found with the scheme. You can still activate
        the scheme but we recommend you check that these are not errors
      </p>
      <div>
        <p>SEVERE WARNINGS </p>
        <ul className="activate-scheme-critical-failure-message">
          {criticalFailures?.map((failure, key) => {
            return <li key={key}>{failure}</li>;
          })}
        </ul>
        <p>WARNINGS</p>
        <ul>
          {failures?.map((failure, key) => {
            return <li key={key}>{failure}</li>;
          })}
        </ul>
      </div>
    </div>
  );
};

const FORM_NAME = 'ActivateSchemeForm';
const ActivateSchemeForm = ({ onCancel, handleSubmit, submitting, onActivate }) => {
  const { critical, warning } = useSelector(
    (state) => state.schemeManagement?.lintFailures
  );
  return (
    <Form onSubmit={handleSubmit(onActivate)}>
      <Modal.Body>
        <LintFailures criticalFailures={critical} failures={warning}/>

        <p>
          Please note: Once the scheme has been made active it will no longer be available
          for editing.
        </p>

        <p>Select a start date:</p>
        <Field
          showTime
          dateFormat={'dd/MM/yyyy HH:mm'}
          labelSize={4}
          name="meta.date"
          label="Start date"
          timeFormat="HH:mm"
          component={DatePicker}
          shouldCloseOnSelect={false}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" className="btn-blue" isLoading={submitting}>
          Activate
        </Button>
        <Button bsStyle="default" className="pull-left" handleClick={onCancel}>
          Cancel
        </Button>
      </Modal.Footer>
    </Form>
  );
};

export default compose(
  connect(
    null,
    null,
  ),
  reduxForm({
    form: FORM_NAME,
  }),
)(ActivateSchemeForm);

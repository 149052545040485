import React, { useState } from 'react';
import { connect } from 'react-redux';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { actions } from '../../redux/schemeManagementActions';
import { Icon } from '../../../../common/components';
import { push } from 'react-router-redux';
import ActivateSchemeModal from '../ActivateSchemeModal/ActivateSchemeModal';
import confirm from 'react-confirm2';
import CreateNoteMenuItem from './components/CreateNoteMenuItem';

const SchemeDropdownActions = ({

  schemeId,
  status,
  version,
  name,
  onEditRates,
  onImportRates,
  onActivateScheme,
  onEndScheme,
  onCreateNewVersion,
  onRefreshPage,
  onLintScheme,
  onLockRates,
  onGoTo
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleActivateScheme = (values) => {
    // const { actions, params, isSchemeGroup } = this.props;
    return Promise.resolve(onActivateScheme(schemeId, values)).then(() => {
      onRefreshPage();
    });
  };

  const handleEndScheme = () => {
    const end = status === 'active' || status === 'pending';


    confirm('Deactivate Scheme', {
      description: 'Are you sure you want to deactivate this scheme group and associated schemes?',
      done: () =>
        onEndScheme(schemeId, end).then(() => {
          onRefreshPage();
        }),
    });
  };

  const editScheme = () => {
    onGoTo(`/admin/scheme-management/${schemeId}/edit`);
  };

  const viewScheme = () => {
    onGoTo(`/admin/scheme-management/${schemeId}`);
  };
  const handleCreateNewVersion = () => {
    return Promise.resolve(onCreateNewVersion(schemeId)).then(() => {
      onRefreshPage();
    });
  };

  const handleRates = (type) => {
    if (type === 'view-rates') {
      onEditRates(schemeId, 'Loading rates');
    }

    if (type === 'lock-rates') {
      onLockRates(schemeId, 'Locking rates - in progress');
    }
    if (type === 'import-rates') {
      onImportRates(schemeId, 'Importing rates - in progress');
    }
  };

  const lockRates = () => {
    onLockRates(schemeId, 'Locking rates - in progress');
  };

  const openModal = () => {
    return Promise.resolve(onLintScheme(schemeId)).then(() => {
      setShowModal(true);
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const showNewVersion = status !== 'test' && status !== 'inactive';
  return (
    <DropdownButton
      pullRight
      bsSize="small"
      title={'Actions'}
      bsStyle={'default'}
      id={'dropdown-scheme-actions'}
      disabled={status === 'inactive'}
    >
      {status === 'test' && (
        <MenuItem eventKey="activate" onSelect={openModal}>
          <Icon name="check-circle"/> Activate
        </MenuItem>
      )}
      {status !== 'ended' && status !== 'inactive' && status !== 'test' && (
        <MenuItem eventKey="end" onClick={handleEndScheme}>
          <Icon name="ban"/> End
        </MenuItem>
      )}
      {status === 'test' && (
        <MenuItem
          eventKey={'edit-scheme-group'}
          onSelect={editScheme}
        >
          <Icon name="pencil"/> View/Edit
        </MenuItem>
      )}
      {(status === 'active' || status === 'ended' || status === 'pending') && (
        <MenuItem
          eventKey={'view-scheme-group'}
          onSelect={viewScheme}
        >
          <Icon name="eye"/> View
        </MenuItem>
      )}
      {showNewVersion && <MenuItem divider/>}
      {showNewVersion && (
        <MenuItem eventKey="new-version" onSelect={handleCreateNewVersion}>
          <Icon name="plus-circle"/> Create New Version
        </MenuItem>
      )}
      <MenuItem divider/>
      <MenuItem eventKey="view-rates" onSelect={handleRates}>
        <Icon name="table"/> {status === 'test' ? 'View/Edit Rates' : 'View Rates'}
      </MenuItem>
      <MenuItem eventKey="lock-rates" onSelect={lockRates}>
        <Icon name="lock"/> Lock Rates
      </MenuItem>
      {status === 'test' && (
        <MenuItem eventKey="import-rates" onSelect={handleRates}>
          <Icon name="upload"/> Import Rates
        </MenuItem>
      )}

      <MenuItem divider/>

      <CreateNoteMenuItem
        menuLabel={'Add a Note'}
        modalLabel={'Add a Note for ' + name + ' v' + version}
        notableResourceType={'scheme-groups'}
        resourceId={schemeId}
        // hideReminder
      />

      {/*<CreateNoteButton*/}
      {/*  key={schemeId}*/}
      {/*  label={*/}
      {/*  menuItem={true}*/}
      {/*  name={name}*/}
      {/*  className="pull-right"*/}
      {/*  hideReminder*/}
      {/*  notableResourceType={'scheme-groups'}*/}
      {/*  notableResource={notableResource}*/}
      {/*/>*/}

      <ActivateSchemeModal
        show={showModal}
        onClose={handleCloseModal}
        onActivate={handleActivateScheme}
      />
    </DropdownButton>
  );
};

//
// export default compose(
//   connect(
//     null,
//     {
//       activateScheme: actions.activateSchemeNew,
//       endOrDeactivateScheme:   actions.endOrDeactivateScheme,
//       createNewVersion:   actions.createNewVersion,
//       importRates:   actions.importRates,
//       editRates:   actions.editRates,
//       lockRates:   actions.lockRates,
//       lintScheme:   actions.lintScheme,
//       viewOrEditScheme:   actions.viewOrEditScheme,
//       goTo: push
//
//     }
//   ),
//   reduxForm({
//     form: 'schemeActions',
//   }),
// )(SchemeDropdownActionsNew)

export default connect(
  null,
  {
    onActivateScheme: actions.activateScheme,
    onLintScheme: actions.lintScheme,
    onEndScheme: actions.endOrDeactivateScheme,
    onCreateNewVersion: actions.createNewSchemeGroupVersion,
    onLockRates: actions.lockRates,
    onImportRates: actions.importRates,
    onEditRates: actions.editRates,
    // lockRates: actions.lockRates,
    // lintScheme: actions.lintScheme,
    // viewOrEditScheme: actions.viewOrEditScheme,
    onGoTo: push,
  },
)(SchemeDropdownActions);

import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
} from '../../../../common/components';
import { Modal } from 'react-bootstrap';
import { generateAccountingReference } from '../../redux/accountActions';
import MakePaymentForm from './components/MakePaymentForm';

import './styles.scss';

const MakePaymentButton  = ({
  amount,
  block,
  label,
  matchedPremiums,
  matchedTransactions,
  //onCacheTransactions,
  onMakePayment,
  onGenerateAccountingReference,
}) =>  {

  const [accountingReference, setAccountingReference] = useState('');
  const handleOpen = () => {
    if (!accountingReference) {
      return Promise.resolve(onGenerateAccountingReference(true)).then((reference) => {
        setAccountingReference(reference);

      });
    }
  };

  const handleCancel = () => {
    setAccountingReference('');
  };
  const pay = (values) => {
    return Promise.resolve(onMakePayment(values)).then(() => {
      setAccountingReference('');
    });

  };

  const title = label || 'Make Payment';
  const show = !!accountingReference;

    return (
      <div>
        <Button
          className="matching-sidebar-btn"
          bsStyle="primary"
          //isLoading={submitting}
          label={title}
          handleClick={handleOpen}
          block={block}
        />
        <Modal
          show={show}
          onHide={handleCancel}
        >
          <Modal.Header closeButton>
            <div className={'title-case'}>
              {title}
            </div>

          </Modal.Header>
          <Modal.Body>
            {show && (
              <MakePaymentForm
                amount={amount}
                accountingReference={accountingReference}
                matchedPremiums={matchedPremiums}
                matchedTransactions={matchedTransactions}
                onMakePayment={pay}
              />
            )}

          </Modal.Body>


        </Modal>
      </div>
    );

};


export default connect(null, {
  onGenerateAccountingReference: generateAccountingReference,

})(MakePaymentButton);

import React from 'react';
import PropTypes from 'prop-types';
import './ButtonBar.scss';

const ButtonBar = ({ children, simple }) => {

  if (simple) {
    return <div className="button-bar">{children}</div>;
  }
  return <div className="button-bar">{children}</div>;
};

ButtonBar.propTypes = {
  children: PropTypes.node,
};

export default ButtonBar;

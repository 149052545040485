import React, { Component } from 'react';
import { Box } from '../../../../common/components';
import displayName from '../../../../helpers/displayName';
import { push } from 'react-router-redux';
import { Row, Col } from 'react-bootstrap';
import './styles.scss';

class AccountSummary extends Component {

  filters(accountType) {
    let sort = '';
    if (accountType === 'creditor') {
      sort = '&sort=formatted_balance';
    }
    if (accountType === 'debtor') {
      sort = '&sort=formatted_balance';
    }
    this.props.dispatch(push('/accounts/ledger?filter=' + accountType + sort));
  }

  render() {
    const { summaries, currencies, accountTypes } = this.props;

    return (
      <Box>
        <Row>
          <Col sm={2} className="text-center account-summary-header no-hover">
            <h3>Currency</h3>
          </Col>
          {accountTypes.map((accountType) => {
            return (
              <Col
                sm={2}
                className="text-right account-summary-header"
                onClick={() => this.filters(accountType)}
                key={accountType}
              >
                <h3>{displayName(accountType)}</h3>
              </Col>
            );
          })}
        </Row>
        {currencies.map((currency) => {
          return (
            <Row key={currency} className='account-summary-row'>
              <Col sm={2} className="text-center">
                {currency}
              </Col>
              {accountTypes.map((accountType) => {
                return (
                  <Col sm={2} className="text-right" key={currency + accountType}>
                    <span className="account-total">
                      {summaries[accountType][currency] || ' - '}
                    </span>
                  </Col>
                );
              })}
            </Row>
          );
        })}
      </Box>
    );
  }
}

export default AccountSummary;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { Button, Modal, DatePicker } from '../../../../common/components';
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux';
import { TextField } from '../../../../common/components/ReduxFormField';
import { createContract } from '../../redux/brokerActions';
import { Col, Row, ControlLabel } from 'react-bootstrap';
import { get } from 'lodash';
import './styles.scss';

const FORM_NAME = 'createContractForm';

class CreateContractButton extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    broker: PropTypes.object,
    callback: PropTypes.func,
    label: PropTypes.string,
  };

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded, callback } = this.props;
    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('');
      }
      dispatch(closeModal(FORM_NAME));
    }
  }

  handleSubmit(values) {
    return this.props.dispatch(
      createContract(values, this.props.resource, this.props.resourceType),
    );
  }

  handleOpen() {
    const { dispatch, reset } = this.props;
    dispatch(reset());
    dispatch(openModal(FORM_NAME));
  }

  render() {
    const { handleSubmit, submitting, broker } = this.props;

    const submitButton = (
      <Button
        type="submit"
        bsStyle="primary"
        disabled={submitting}
        isLoading={submitting}
        label="New Contract"
      />
    );

    return (
      <div>
        <Button className="pull-right" bsStyle="primary" label="New Contract" handleClick={::this.handleOpen}/>

        <Modal
          name={FORM_NAME}
          title={'Broker Contract for ' + broker.attributes.name}
          close
          handleSubmit={handleSubmit(::this.handleSubmit)}
          footer={submitButton}
        >
          <div className="form-horizontal">
            <Row>
              <Col xs={12}>
                <p>Ensure product commissions have been set before issuing contract</p>
              </Col>
            </Row>
            <Row>
              <Col xs={4} componentClass={ControlLabel}>
                Contract date
              </Col>
              <Col xs={8}>
                <Field
                  name={'data.attributes.date'}
                  block
                  component={DatePicker}
                />
              </Col>
            </Row>
            <Field
              name={'data.attributes.note'}
              label="Add a message for broker"
              labelSize={4}
              type="textarea"
              component={TextField}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

const form = reduxForm({ form: FORM_NAME })(CreateContractButton);

const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  const values = selector(state, 'data.relationships.broker.data.id');

  return {
    initialValues: {
      data: {
        relationships: {
          broker: {
            data: {
              id: get(props.broker, 'id', ''),
            },
          },
        },
      },
    },
    formValues: values,
  };
};

export default connect(mapStateToProps)(form);

import React, { Component } from 'react';
import { Breadcrumb } from '../../../../common/components';
import InternalNotifications from '../InternalNotifications/InternalNotifications';
import './InternalSection.scss';

export default class InternalSection extends Component {
  render() {
    const { hideBreadcrumbs = false } = this.props;

    return (
      <div className="main-container">
        <div className="internal-title">
          <h2>{this.props.title ? this.props.title : ' '}</h2>
          <div className="notifications-nav">
            <InternalNotifications {...this.props} />
          </div>
        </div>

        {!hideBreadcrumbs && <Breadcrumb path={this.props.breadcrumb} />}

        <div className="internal-section">{this.props.children}</div>

        {this.props.buttons && (
          <div className="internal-section-button-bar">{this.props.buttons}</div>
        )}

        <div style={{ clear: 'both' }} />
      </div>
    );
  }
}

import React from 'react';

const announcements = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95 95">
    <title>Icons</title>
    <path d="M82.5,42.5a5,5,0,0,1-5,5v15a5,5,0,0,1-5,5c-7-5.78-18.16-13.71-31.72-14.84-4.65,1.56-6.25,7-3.2,10.12-2.73,4.49.78,7.66,4.92,10.9C40.08,78.44,30,78.52,26.41,75.2c-2.27-7-5.62-13.91-2.89-22.7H18.75a6.27,6.27,0,0,1-6.25-6.25v-7.5a6.27,6.27,0,0,1,6.25-6.25H37.5c15,0,27.5-8.75,35-15a5,5,0,0,1,5,5v15A5,5,0,0,1,82.5,42.5Zm-10-18.67c-10.2,7.81-20.08,12.31-30,13.4V47.78c9.92,1.09,19.8,5.51,30,13.32Z" />
  </svg>
);

export default announcements;

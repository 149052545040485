import Axios from 'axios';
import * as actions from './dashboardReducer';
import { toastr } from 'react-redux-toastr';
import handleErrorResponse from '../../../helpers/handleErrorResponse';

export function clearData() {
  return {
    type: actions.CLEAR_DATA,
  };
}

export function clearSearchResults() {
  return {
    type: actions.CLEAR_SEARCH_RESULTS,
  };
}

export function fetchSearchResults(query) {
  return (dispatch) => {
    if (query.length < 1) {
      return dispatch({
        type: actions.SET_SEARCH_RESULTS,
        data: {
          meta: {
            policies: [],
            policy_applications: [],
          },
        },
      });
    }

    return Axios.get('search/quick?filter[query]=' + query)
      .then((response) => {
        dispatch({
          type: actions.SET_SEARCH_RESULTS,
          data: response.data,
        });
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function fetchStats() {
  return (dispatch) => {
    return Axios.get('statistics/dashboard')
      .then((response) => {
        dispatch({
          type: actions.SET_STATS,
          data: response.data,
        });
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function fetchBrokerStats(range = 'month') {
  return (dispatch) => {
    return Axios.get('statistics/brokers/' + range)
      .then((response) => {
        dispatch({
          type: actions.SET_BROKER_STATS,
          data: response.data,
        });
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function fetchReminders() {
  return (dispatch, getState) => {
    const id = getState().auth.user.data.id;
    let endpoint = 'notes?filter[user]=' + id + '&filter[reminders]=true';

    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: actions.SET_REMINDERS,
          data: response.data,
        });
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function fetchNewBrokers() {
  return (dispatch) => {
    let endpoint = 'brokers?filter[status]=awaiting%20approval';

    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: actions.SET_NEW_BROKERS,
          data: response.data.data,
        });
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function fetchNewUsers() {
  return (dispatch) => {
    let endpoint = 'users?filter[status]=pending';

    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: actions.SET_NEW_USERS,
          data: response.data.data,
        });
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function fetchAnnouncements() {
  return (dispatch) => {
    let endpoint = 'contents?filter[template.type]=announcement&filter[active]=true';

    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: actions.SET_ANNOUNCEMENTS,
          data: response.data.data,
          included: response.data.included,
        });
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function fetchDueDiligence() {
  return (dispatch) => {
    let endpoint = 'brokers?filter[due_diligence]=true';

    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: actions.SET_DUE_DILIGENCE,
          data: response.data.data,
        });
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function clearReminder(id) {
  return (dispatch) => {
    return Axios.patch('notes/' + id, { data: { attributes: { reminder: null } } })
      .then(() => {
        dispatch(fetchReminders());
        toastr.success('Success', 'The reminder has been cleared');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'There was an error creating clearing the reminder');
        console.error(error);
      });
  };
}

export function agreeContract(id) {
  return (dispatch) => {
    return Axios.post('brokers/contracts/' + id + ':agree')
      .then(() => {
        dispatch(fetchReminders());
        toastr.success('Success', 'Your contract has been agreed');
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'There was an error agreeing your contract');
        console.error(error);
      });
  };
}

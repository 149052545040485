import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { actions } from '../redux/ProductReducer';
import { Loader } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import CovidMessageContentForm
  from '../components/CovidMessageContentForm/CovidMessageContentForm';
import ProductName
  from '../components/ProductFrontPage/components/ProductName/ProductName';
import ProductNavigation from '../components/ProductNavigation/ProductNavigation';

const INCLUDES = ['all_scheme_groups', 'contents'];

const CovidMessageContentContainer = ({
  params,
  onGetProduct,
  onUpdateProduct,
  location,
}) => {
  const [showForm, setShowForm] = useState(false);

  const { productId } = params;
  const {
    product,
    isLoading
  } = useSelector((state) => state.productManagement);

  const currentProductId = product?.data?.id;

  useEffect(
    () => {
      if (!currentProductId || currentProductId !== productId) {
        onGetProduct(productId, INCLUDES);
      }
    },
    [productId, currentProductId, onGetProduct],
  );


  const handleToggleForm = () => {
    setShowForm(!showForm);

  };

  const save = (values) => {


    return Promise.resolve(onUpdateProduct(productId, values)).then(() => {
      onGetProduct(productId, INCLUDES);
      setShowForm(false);
    });
  };

  if (isLoading || !product) {
    return (
      <div className="admin-area">
        <InternalContainer title="Product management">
          <Loader/>
        </InternalContainer>
      </div>
    );
  }

  const { icon, name, status, metadata } = product?.data?.attributes ?? {};
  const { is_wl_product: isWhitelabel } = metadata ?? {};

  return (
    <InternalContainer title={'Product Management'}>
      <ProductName
        title={'Covid Message'}
        name={name}
        icon={icon}
        status={status}
      >
        <ProductNavigation
          id={productId}
          currentLocation={location.pathname}
          isWhitelabel={isWhitelabel}
        />

        <CovidMessageContentForm
          covidMessage={product?.data?.attributes?.metadata?.covid_message}
          showForm={showForm}
          isLoading={isLoading}
          onSave={save}
          onToggleForm={handleToggleForm}
        />
      </ProductName>
    </InternalContainer>
  );


};
export default connect(
  null,
  {
    onGetProduct: actions.getProduct,
    onUpdateProduct: actions.updateProduct
  }
)(CovidMessageContentContainer);


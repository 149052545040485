import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';
import moment from 'moment';
import {
  DatePicker,
  DatePickerLeisure,
  InfoButton,
  SelectCountries,
} from '../../../../../../common/components/index';
import {
  CheckboxGroup,
  RadioGroupCircle,
  SelectField,
} from '../../../../../../common/components/ReduxFormField/index';
import CovidStatement from '../../../CovidStatement/CovidStatement';
import { backDateCover } from '../../../../helpers';
import { AnnualDatePicker } from '../AnnualDatePicker/AnnualDatePicker';

import { length, required } from 'redux-form-validators';
import SelectRegion from '../../../LeisureTravelForm/ReduxForm/SelectRegion';


const SingleDatePicker = ({ isExpired, minDate, maxDate, name, validate }) => {
  if (isExpired) {
    return (
      <Field
        name={name}
        component={DatePickerLeisure}
        validate={validate}
      />
    );
  }

  return (
    <Field
      name={name}
      minDate={minDate}
      maxDate={maxDate}
      component={DatePickerLeisure}
      validate={validate}
    />
  );
};

const TripDetailsTab = ({
  formValues,
  dispatch,
  change,
  definitions,
  rules,
  selectOptions,
  isMta,
  covidMessage,
  isExpired,
}) => {
  const tripType = formValues?.data?.attributes?.metadata?.scheme_type;
  const startDate = formValues?.data?.attributes?.metadata?.start_date;
  const region = formValues?.data?.attributes?.metadata?.region;

  const hasSingleMaxTrip = selectOptions.maxTripDurations?.length === 1;
  const singleMaxTrip = hasSingleMaxTrip ? selectOptions.maxTripDurations?.[0]?.value : '';

  const getRegionDefinition = () => {
    return definitions?.[`region_${region}`];

  };


  useEffect(() => {
    if (tripType === 'single') {
      dispatch(change('data.attributes.metadata.region', ''));
      dispatch(change('data.attributes.metadata.max_trip_duration', ''));
    }
    if (tripType === 'annual') {
      dispatch(change('data.attributes.metadata.max_trip_duration', singleMaxTrip));
      dispatch(change('data.attributes.metadata.destinations', []));
      dispatch(change('data.attributes.metadata.end_date', ''));
      dispatch(change('data.relationships.schemes.data', []));

    }
  }, [tripType]);

  const showCruiseQuestion = false; // ['FST'].includes(productCode) && !mta;
  const tripTypes = selectOptions.tripTypes;
  const regions = selectOptions.regions;
  const showDestinations = tripType !== 'annual';
  const showRegions = tripType === 'annual';

  const minDate = startDate ? moment(startDate, 'DD/MM/YYYY') : moment();
  const endDateMin = minDate.format();
  const startDateMax = moment().add(rules?.st_advance_months, 'months');
  const endDateMax = minDate.add(rules?.max_trip_duration, 'day');

  const backdateCoverDate = backDateCover(rules?.backdate_cover).format();
  const annualMaxDate = moment().add(rules?.amt_advance_days, 'days').format();


  return (
    <div className="fadein-fields">
      {isMta && (
        <FormGroup>
          <Col md={4} lg={3} componentClass={ControlLabel}>
            <InfoButton content={definitions?.trip_type}/> Effective Date
          </Col>
          <Col md={8} lg={6}>
            <Field
              name="data.attributes.effective_date"
              minDate={moment().format()}
              component={DatePicker}
              validate={required({ msg: 'Effective date is required' })}
            />
          </Col>
        </FormGroup>
      )}
      <div>
        <CovidStatement covidMessage={covidMessage} showAgreement={true}/>
      </div>

      <FormGroup>
        <Col md={4} lg={3} componentClass={ControlLabel}>
          <InfoButton content={definitions.residence}/> Residence
        </Col>
        <Col md={8} lg={6}>
          <Field
            name="data.attributes.metadata.residency"
            component={SelectField}
            options={selectOptions.residencies}
          />
        </Col>
      </FormGroup>
      {!isMta && tripTypes.length > 1 && (
        <FormGroup>
          <Col md={4} lg={3} componentClass={ControlLabel}>
            <InfoButton content={definitions.trip_type}/> Trip Type
          </Col>
          <Col md={8} lg={6}>
            <Field
              name="data.attributes.metadata.scheme_type"
              infoButton={<InfoButton content={definitions.trip_type}/>}
              component={RadioGroupCircle}
              inline
              options={tripTypes}
              showError
              validate={required({ msg: 'Trip Type is required' })}
            />
          </Col>
        </FormGroup>
      )}
      {tripType !== 'annual' && (
        <div>
          <FormGroup>
            <Col md={4} lg={3} componentClass={ControlLabel}>
              <InfoButton content={definitions?.travel_dates_single_start_date}/> Start
              Date
            </Col>
            <Col md={8} lg={9}>
              <SingleDatePicker
                name="data.attributes.metadata.start_date"
                id="start_date"
                isExpired={isExpired}
                minDate={backdateCoverDate}
                validate={required({ msg: 'Start Date is required' })}
                maxDate={startDateMax}/>
            </Col>
          </FormGroup>
          <FormGroup>
            <Col md={4} lg={3} componentClass={ControlLabel}>
              <InfoButton content={definitions.travel_dates_single_end_date}/> Return Date
            </Col>
            <Col md={8} lg={9}>
              <SingleDatePicker
                name="data.attributes.metadata.end_date"
                isExpired={isExpired}
                id="end_date"
                minDate={endDateMin}
                maxDate={endDateMax}
                validate={required({ msg: 'End Date is required' })}
              />
            </Col>
          </FormGroup>
        </div>
      )}
      {tripType === 'annual' && (
        <FormGroup>
          <Col md={4} lg={3} componentClass={ControlLabel}>
            <InfoButton content={definitions.travel_dates_annual}/> Travel Dates
          </Col>
          <Col md={8} lg={9}>
            <AnnualDatePicker
              minDate={backdateCoverDate}
              maxDate={annualMaxDate}
              isExpired={isExpired}
              validate={required({ msg: 'Start Date is required' })}
            />
          </Col>
        </FormGroup>
      )}

      {showDestinations && (
        <FormGroup>
          <Col md={4} lg={3} componentClass={ControlLabel}>
            <InfoButton content={definitions.destinations}/> Destination
          </Col>
          <Col md={8} lg={6}>
            <Field
              name="data.attributes.metadata.destinations"
              placeholder="Search for countries..."
              component={SelectCountries}
              optionValuePath={'attributes.name'}
              validate={length({ msg: 'At least one destination is required', min: 1 })}
            />
          </Col>
        </FormGroup>
      )}
      {showRegions && (
        <FormGroup>
          <Col md={4} lg={3} componentClass={ControlLabel}>
            <InfoButton content={getRegionDefinition()}/> What region are you going to?
          </Col>
          <Col md={8} lg={6} className={'select-region'}>
            <Field
              name="data.attributes.metadata.region"
              component={SelectRegion}
              validate={required({ msg: 'Region is required' })}
              options={regions}
            />

          </Col>
        </FormGroup>
      )}
      {tripType === 'annual' && !hasSingleMaxTrip && (
        <FormGroup>
          <Col md={4} lg={3} componentClass={ControlLabel}>
            <InfoButton content={definitions.max_trip_duration}/>
            Max Trip Duration
          </Col>
          <Col md={8} lg={6}>
            <Field
              name="data.attributes.metadata.max_trip_duration"
              component={SelectField}
              validate={required({ msg: 'Trip Duration is required' })}
              options={selectOptions.maxTripDurations}
            />
          </Col>
        </FormGroup>
      )}
      {showCruiseQuestion && (
        <FormGroup>
          <Col md={4} lg={3} componentClass={ControlLabel}>
            <InfoButton content={definitions.cruise_cover}/> Are you going on a
            cruise?
          </Col>
          <Col md={8} lg={6}>
            <Field
              name="data.attributes.metadata.options.cruise_cover"
              component={CheckboxGroup}
              inline
              options={[{ label: 'Yes', value: true }]}
            />
          </Col>
        </FormGroup>
      )}
    </div>
  );

};

const FORM_NAME = 'WhitelabelTravelForm';
export default connect(
  (state) => ({
    formValues: getFormValues(FORM_NAME)(state),
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })
)(reduxForm({ form: FORM_NAME })(TripDetailsTab));

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal as BootstrapModal } from 'react-bootstrap';
import { initModal, closeModal } from './ModalRedux';
import { Button, ButtonBar } from '../';
import { Form } from 'react-bootstrap';
import './modal.scss';

const ModalHeader = BootstrapModal.Header;
const ModalTitle = BootstrapModal.Title;
const ModalBody = BootstrapModal.Body;
const ModalFooter = BootstrapModal.Footer;

class Modal extends Component {
  static propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    modals: PropTypes.object,
    footer: PropTypes.node,
    close: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    children: PropTypes.node,
    handleSubmit: PropTypes.func,
    backdrop: PropTypes.string,
    bsSize: PropTypes.string,
    onHide: PropTypes.func,
    onShow: PropTypes.func,
  };

  componentWillMount() {
    this.props.dispatch(initModal(this.props.name));
  }

  closeModal() {
    const { dispatch, onHide, enforceFocus } = this.props;

    if (enforceFocus) {
      return;
    }

    dispatch(closeModal(this.props.name));

    if (onHide) {
      onHide();
    }
  }

  handleSubmit(e) {
    const { handleSubmit } = this.props;

    if (!handleSubmit) {
      e.preventDefault();
      return null;
    }

    return handleSubmit(e);
  }

  render() {
    const {
      name,
      title,
      modals,
      footer,
      close,
      children,
      bsSize,
      onShow,
      handleSubmit,
      backdrop,
      backdropClassName,
      closeButton = true,
    } = this.props;

    const modal = (
      <div>
        <ModalHeader closeButton={closeButton}>
          <ModalTitle id={modals[name]}>{title}</ModalTitle>
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <ButtonBar>
            {close && (
              <Button
                className="pull-left settings-button"
                bsStyle="default"
                leftIcon="times"
                label="Close"
                handleClick={::this.closeModal}
              />
            )}
            {footer}
          </ButtonBar>
        </ModalFooter>
      </div>
    );

    return (
      <BootstrapModal
        show={modals[name] ? modals[name].visible : false}
        onEntered={onShow}
        onHide={::this.closeModal}
        bsSize={bsSize}
        backdrop={backdrop}
        backdropClassName={backdropClassName}
      >
        {handleSubmit ? (
          <Form onSubmit={::this.handleSubmit}>{modal}</Form>
        ) : (
          <div>{modal}</div>
        )}
      </BootstrapModal>
    );
  }
}

function mapStateToProps(state) {
  return {
    modals: state.modal.modals,
  };
}

export default connect(mapStateToProps)(Modal);

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { FormGroup, ControlLabel, Col, Button } from 'react-bootstrap';
import { FormattedDate, FormattedTime } from 'react-intl';
import './InfoLabel.scss';

const InfoLabel = ({
  labelSize,
  label,
  date,
  dateTime,
  value,
  input,
  link,
  linkLabel,
  className,
  info,
  wrapperClass,
  addonAfter,
}) => {
  const size = labelSize ? labelSize : label ? 2 : 0;
  const displayValue = input ? input.value : value;
  const displayInfo = displayValue || info;

  return (
    <FormGroup
      className={wrapperClass ? 'infolabel-wrapper ' + wrapperClass : 'infolabel-wrapper'}
    >
      {label && (
        <Col componentClass={ControlLabel} sm={size}>
          {label}
        </Col>
      )}

      <Col sm={12 - size}>
        <div className={'form-control-static ' + className}>
          {date && displayValue ? (
            <FormattedDate value={new Date(displayValue)} />
          ) : dateTime && displayValue ? (
            <span>
              <FormattedDate value={new Date(displayValue)} />
              &nbsp;
              <FormattedTime value={new Date(displayValue)} />
            </span>
          ) : (
            displayInfo
          )}
          {addonAfter && <span>{addonAfter}</span>}
        </div>
        {link && (
          <Link to={link}>
            <Button bsStyle="default" bsSize="sm">
              {linkLabel}
            </Button>
          </Link>
        )}
      </Col>
    </FormGroup>
  );
};

InfoLabel.propTypes = {
  labelSize: PropTypes.number,
  label: PropTypes.string,
  date: PropTypes.bool,
  value: PropTypes.node,
  link: PropTypes.string,
  linkLabel: PropTypes.string,
  input: PropTypes.object,
};

export default InfoLabel;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { RadioGroup, SelectField } from '../../../../common/components/ReduxFormField';
import {
  Icon,
  DatePickerDob,
  SelectAccount,
  SelectBroker,
  SelectCurrency,
  Button,
  HorizontalFormControl,
} from '../../../../common/components';
import './styles.scss';
import moment from 'moment/moment';

const transactionDefaults = {
  date: '',
  account: '',
  description: '',
  currency: 'GBP',
  amount: 0,
};

class PaymentReceipt extends Component {
  componentWillMount() {
    this.props.fields.push(transactionDefaults);
  }

  render() {
    const { fields, formValues } = this.props;
    const paymentTypes = [
      { label: 'BACS', value: 'BACS' },
      { label: 'Cheque', value: 'Cheque' },
    ];

    return (
      <div>
        {fields.map((entry, index) => {
          return (
            <div key={index} className="transaction-entry">
              {index > 0 && (
                <span className="remove-entry" onClick={() => fields.remove(index)}>
                  <Icon name="times" />
                </span>
              )}
              <div className="row form-group">
                <div className="col-sm-6">
                  <Field
                    name={entry + '.account'}
                    id={'account' + index}
                    label="Account"
                    labelSize={2}
                    component={SelectAccount}
                  />
                </div>
                <div className="col-sm-6">
                  <div className="col-sm-4">
                    <label htmlFor="transactionType" className="radio-margin-top">
                      Transaction Type
                    </label>
                  </div>
                  <Field
                    id={'transactionType' + index}
                    name={entry + '.transaction_type'}
                    component={RadioGroup}
                    inline
                    options={[
                      { label: 'Receipt', value: 'receipt' },
                      { label: 'Payment', value: 'payment' },
                    ]}
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-xs-4">
                  <Field
                    name={entry + '.date'}
                    label="Date"
                    labelSize={3}
                    maxDate={moment().format()}
                    component={DatePickerDob}
                  />
                </div>
                <div className="col-xs-4">
                  <Field
                    name={entry + '.description'}
                    label="Description"
                    labelSize={3}
                    component={HorizontalFormControl}
                  />
                </div>
                <div className="col-xs-4">
                  <Field
                    name={entry + '.payment_type'}
                    label="Type"
                    labelSize={3}
                    options={paymentTypes}
                    component={SelectField}
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-xs-4">
                  <Field
                    name={entry + '.broker'}
                    id={'broker' + index + formValues[index].account}
                    label="Broker"
                    labelSize={3}
                    component={SelectBroker}
                    filters={[
                      { filter: 'filter[account]', value: formValues[index].account },
                    ]}
                  />
                </div>
                <div className="col-xs-4">
                  <Field
                    name={entry + '.currency'}
                    label="Currency"
                    labelSize={3}
                    defaultValue={{ value: 'GBP', label: 'Pound sterling' }}
                    component={SelectCurrency}
                  />
                </div>
                <div className="col-xs-4">
                  <Field
                    name={entry + '.amount'}
                    label="Amount"
                    labelSize={3}
                    currency
                    component={HorizontalFormControl}
                  />
                </div>
              </div>
            </div>
          );
        })}
        <div className="actions">
          <Button
            bsStyle="primary"
            size="xs"
            className="pull-right"
            label="Add a new line"
            handleClick={() => fields.push(transactionDefaults)}
          />
        </div>
      </div>
    );
  }
}

PaymentReceipt.propTypes = {
  fields: PropTypes.object.isRequired,
  formValues: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default PaymentReceipt;

import * as actions from './toolReducer';
import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import handleErrorResponse from '../../../helpers/handleErrorResponse';
import moment from 'moment';

export function isReindexing(status) {
  return {
    type: actions.IS_REINDEXING,
    status,
  };
}

export function reindexData(values) {
  const _values = {
    ...values,
    fromDate: values.fromDate ? moment(values.fromDate).format('YYYY-MM-DD') : null,
    toDate: values.toDate ? moment(values.toDate).format('YYYY-MM-DD') : null,
  };

  return (dispatch) => {
    dispatch(isReindexing(true));
    return Axios.post('tools/reindex', _values)
      .then((response) => {
        dispatch(isReindexing(false));
        if (response.status === 200) {
          if (response.data.original.error !== null) {
            toastr.error('The reindexing can not be complete');
            return response.data.original;
          } else {
            toastr.success('The reindexing is running successfully');
            return response.data.original;
          }

        }
      })
      .catch((error) => {
        dispatch(isReindexing(false));
        handleErrorResponse(error, 'Error Reindexing');
      });
  };
}

export function fetchJobStatus(jobId) {
  return async (dispatch) => {
    try {
      const response = await Axios.get('/tools/reindex/status');

      dispatch({
        type: actions.FETCH_JOB_STATUS,
        payload: response.data,
      });
      const job = response.data.find(job => job.id === jobId);
      return job ? job.status : null;
    } catch (error) {
      console.error('Error fetching job statuses:', error);
      dispatch({
        type: actions.FETCH_JOB_STATUS_ERROR,
        payload: handleErrorResponse(error, 'Error Fetching Job Status'),
      });
      return null;
    }
  };
}



import React, { useEffect, useMemo, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { FormattedDate, FormattedTime } from 'react-intl';
import { useSelector } from 'react-redux';
import SchemeHeader from '../SchemeHeader/SchemeHeader';
import { Box } from '../../../../common/components';

import './SchemeContainer.scss';

const sortSchemes = (schemes) => {
  return schemes.sort((schemeA, schemeB) => {
    return schemeA.attributes.version < schemeB.attributes.version ? 1 : -1;
  });
};
const SchemeContainer = ({ schemes, baseUrl, onRefreshPage }) => {

  const defaultScheme = schemes?.[0];
  const sortedSchemes = useMemo(() => sortSchemes(schemes), [schemes]);
  const [activeScheme, setActiveScheme] = useState(sortedSchemes?.[0]);

  useEffect(
    () => {
      if (
        defaultScheme.id === activeScheme.id &&
        defaultScheme.attributes.status !== activeScheme.attributes.status
      ) {
        setActiveScheme(defaultScheme);
      }
    },
    [defaultScheme, activeScheme],
  );

  const selectScheme = (scheme) => {
    setActiveScheme(scheme);
  };

  const attributes = activeScheme?.attributes ?? schemes?.[0]?.attributes ?? undefined;
  const id = activeScheme?.id ?? schemes?.[0]?.id ?? undefined;

  const { isLoadingRates, isVersioning } = useSelector((state) => state.schemeManagement);
  const isLoadingRatesStatus = isLoadingRates?.status === true;
  const isVersioningsStatus = isVersioning?.status === true;
  const isLoadingRatesId = isLoadingRates?.id;
  const isVersioningId = isVersioning?.id;
  const isLoadingRatesMessage = isLoadingRates?.message;


  if (!attributes) {
    return null;
  }

  const fromDate = attributes.from_date;
  const createdAt = attributes.created_at;
  const updatedAt = attributes.updated_at;

  const doesRatesIdMatchSchemeId = isLoadingRatesId === id;
  const doesVersionIdMatchSchemeId = isVersioningId === id;

  return (
    <Box className="scheme-container">
      <SchemeHeader
        schemes={sortedSchemes}
        activeScheme={activeScheme}
        baseUrl={baseUrl}
        //onNewVersion={handleSetNewVersionSubmitting}
        onSelectScheme={selectScheme}
        onRefreshPage={onRefreshPage}
      />

      <div className="hr"/>
      <div>
        <strong>Activated:</strong>
        <span className="pull-right">
          <FormattedDate value={fromDate}/>
          {' - '}
          <FormattedTime value={fromDate}/>
        </span>
      </div>
      <div>
        <strong>Created:</strong>
        <span className="pull-right">
          <FormattedDate value={createdAt}/>
          {' - '}
          <FormattedTime value={createdAt}/>
        </span>
      </div>
      <div>
        <strong>Updated:</strong>
        <span className="pull-right">
          <FormattedDate value={updatedAt}/>
          {' - '}
          <FormattedTime value={updatedAt}/>
        </span>
      </div>

      {isLoadingRatesStatus && doesRatesIdMatchSchemeId && (
        <Alert bsStyle="info" className="margin-top">
          <p>
            {isLoadingRatesMessage} <i className="fa fa-cog fa-spin"/>
          </p>
        </Alert>
      )}
      {isVersioningsStatus && doesVersionIdMatchSchemeId && (
        <Alert bsStyle="info" className="margin-top">
          <p>
            Creating new Version <i className="fa fa-cog fa-spin"/>
          </p>
        </Alert>
      )}
    </Box>
  );
};

export default SchemeContainer;

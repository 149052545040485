import React, { useMemo } from 'react';
import './WebsiteThumbnail.scss';

const getStyles = (theme) => (
  {
    page: {
      backgroundColor: theme.colors.page.backgroundColor,
    },
    header: {
      backgroundColor: theme.colors.header.backgroundColor,
      color: theme.colors.header.color,
    },
    breadcrumbPageNumberVisited: {
      backgroundColor: theme.colors.breadcrumb.pageNumber.enabled.backgroundColor,
      color: theme.colors.breadcrumb.pageNumber.enabled.color,
    },
    breadcrumbPageNumberActive: {
      backgroundColor: theme.colors.breadcrumb.pageNumber.active.backgroundColor,
      color: theme.colors.breadcrumb.pageNumber.active.color,
    },
    breadcrumbPageNumberDisabled: {
      backgroundColor: theme.colors.breadcrumb.pageNumber.backgroundColor,
      color: theme.colors.breadcrumb.pageNumber.color,
    },
    breadcrumbTitleVisited: {
      backgroundColor: theme.colors.breadcrumb.enabled.backgroundColor,
      color: theme.colors.breadcrumb.enabled.color,
    },
    breadcrumbTitleActive: {
      backgroundColor: theme.colors.breadcrumb.active.backgroundColor,
      color: theme.colors.breadcrumb.active.color,
    },
    breadcrumbTitleDisabled: {
      color: theme.colors.breadcrumb.color,
    },
    title: {
      color: theme.colors.text.dark.color,
    },
    divider: {
      color: theme.colors.divider.color,
    },
    primaryBox: {
      backgroundColor: theme.colors.box.primary.backgroundColor,
      color: theme.colors.box.primary.color,
    },
    secondaryBox: {
      backgroundColor: theme.colors.box.secondary.backgroundColor,
      color: theme.colors.box.secondary.color,
    },
    premiumHeader: {
      backgroundColor: theme.colors.premium.header.backgroundColor,
      color: theme.colors.premium.header.color,
    },
    premiumBody: {
      backgroundColor: theme.colors.premium.body.backgroundColor,
      color: theme.colors.premium.body.color,
    },
    premiumBenefits: {
      backgroundColor: theme.colors.premium.benefits.backgroundColor,
      color: theme.colors.premium.benefits.color,
      borderColor: theme.colors.premium.benefits.borderColor,

    },
    actionButton: {
      backgroundColor: theme.buttons.action.backgroundColor,
      color: theme.buttons.action.color,

    },
    secondaryButton: {
      backgroundColor: theme.buttons.secondary.backgroundColor,
      color: theme.buttons.secondary.color,

    },
    defaultButton: {
      backgroundColor: theme.buttons.default.backgroundColor,
      color: theme.buttons.default.color,

    },
    pdfButton: {
      backgroundColor: theme.buttons.pdf.backgroundColor,
      color: theme.buttons.pdf.color,

    },
    // quoteContainer: {
    //   backgroundColor: theme.colors.quoteContainerAlt.backgroundColor,
    //   color: theme.colors.quoteContainerAlt.color,
    // },
    // quoteContainerBox: {
    //   backgroundColor: theme.colors.box.totalPayable.backgroundColor,
    //   color: theme.colors.box.totalPayable.colorAlt,
    // },
    // sectionHeading: {
    //   color: theme.colors.quoteContainerAlt.color,
    // },
    // quoteHeader: {
    //   color: theme.colors.quoteContainerAlt.color,
    // },
    // infoButton: {
    //   color: theme.buttons.action.backgroundColor,
    // },
    // optionButtonActive: {
    //   backgroundColor: theme.colors.button.group.ampHover.backgroundColor,
    //   borderColor: theme.colors.button.group.borderColor,
    //   color: theme.colors.button.group.color,
    // },
    // optionButton: {
    //   backgroundColor: theme.colors.button.group.backgroundColor,
    //   color: theme.colors.button.group.color,
    //   borderColor: theme.colors.button.group.borderColor,
    // },
    // backButton: {
    //   backgroundColor: theme.colors.button.action.alt.backgroundColor,
    //   color: theme.colors.button.action.color,
    // },
    // nextButton: {
    //   backgroundColor: theme.colors.button.action.backgroundColor,
    //   color: theme.colors.button.action.color,
    // },
    footer: {
      backgroundColor: theme.colors.footer.backgroundColor,
      color: theme.colors.footer.color,
    },
  }
);

const WebsiteThumbnail = ({ theme, phone, openingHours, footer }) => {

  const styles = useMemo(() => getStyles(theme), [theme]);


  return (
    <div className="whitelabel-preview">
      <div className="wl-thumbnail-container">
        <label>B2C Website</label>
        <div className="wl-thumbnail" style={styles.page}>
          <div className="wl-header" style={styles.header}>
            <div className="wl-logo-container">
              <img alt="logo" className="wl-logo" src={theme.urls.logo}/>
            </div>
            <div className="wl-right-hand-container">
              <div className="wl-headings">
                <span className="links">
                  | LOGIN | ABOUT US | CONTACT
                </span>
                <div className={'wl-info'}>
                  <span className="telephone">
                    {phone || '0800 0000 0000'}
                  </span>
                  <span className="hours">
                    {openingHours || '9-5'}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="wl-breadcrumb">
            <ul>
              <li>
                <div className={'wl-breadcrumb--page-number'}
                     style={styles.breadcrumbPageNumberVisited}>
                  1
                </div>
                <span className={'wl-breadcrumb--title'}
                      style={styles.breadcrumbTitleVisited}>
                  TRIP DETAILS
                </span>
              </li>
              <li>
                <div className={'wl-breadcrumb--page-number'}
                     style={styles.breadcrumbPageNumberVisited}>
                  2
                </div>
                <span className={'wl-breadcrumb--title'}
                      style={styles.breadcrumbTitleVisited}>
                 MEDICAL
                </span>
              </li>
              <li>
                <div className={'wl-breadcrumb--page-number'}
                     style={styles.breadcrumbPageNumberActive}>
                  3
                </div>
                <span className={'wl-breadcrumb--title'}
                      style={styles.breadcrumbTitleActive}>
                 COVER CHOICE
                </span>
              </li>
              <li>
                <div className={'wl-breadcrumb--page-number'}
                     style={styles.breadcrumbPageNumberDisabled}>
                  4
                </div>
                <span className={'wl-breadcrumb--title'}
                      style={styles.breadcrumbTitleDisabled}>
                 COVER_OPTIONS
                </span>
              </li>
              <li>
                <div className={'wl-breadcrumb--page-number'}
                     style={styles.breadcrumbPageNumberDisabled}>
                  5
                </div>
                <span className={'wl-breadcrumb--title'}
                      style={styles.breadcrumbTitleDisabled}>
                 PERSONAL DETAILS
                </span>
              </li>
              <li>
                <div className={'wl-breadcrumb--page-number'}
                     style={styles.breadcrumbPageNumberDisabled}>
                  6
                </div>
                <span className={'wl-breadcrumb--title'}
                      style={styles.breadcrumbTitleDisabled}>
                 PAYMENT
                </span>
              </li>

            </ul>
          </div>
          <div className="wl-quote-container">
            <p className="wl-quote-header" style={styles.title}>
              COVER CHOICE
            </p>
            <hr style={styles.divider}/>
            <div className={'wl-quote-container--section'}>
              <div className={'premiums'}>
                <table>
                  <tr>
                    <td/>
                    <td className={'premiums--header'} style={styles.premiumHeader}>
                      SUPER
                    </td>
                    <td className={'premiums--header'} style={styles.premiumHeader}>
                      SUPER DUPER
                    </td>
                  </tr>
                  <tr style={styles.premiumBody}>
                    <td className={'premiums--label'}>
                      Single Cover
                    </td>
                    <td>
                      <div className={'premiums--select'}>
                        <div className={'premiums--price'}>
                          £20.42
                        </div>
                        <div className={'premiums--select-button'}
                             style={styles.actionButton}>
                          SELECT
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={'premiums--select'}>
                        <div className={'premiums--price'}>
                          £50.89
                        </div>
                        <div className={'premiums--select-button'} style={styles.actionButton}>
                          SELECT
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr style={styles.premiumBody}>
                    <td className={'premiums--label'}>
                      Annual Multi-Trip Cover
                    </td>
                    <td>
                      <div className={'premiums--select'}>
                        <div className={'premiums--price'}>
                          £24.06
                        </div>
                        <div className={'premiums--select-button'} style={styles.actionButton}>
                          SELECT
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className={'premiums--select'}>
                        <div className={'premiums--price'}>
                          £76.58
                        </div>
                        <div className={'premiums--select-button'} style={styles.actionButton}>
                          SELECT
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className={'premiums--label'} style={styles.premiumBody}>
                      Standard Excess
                    </td>
                    <td className={'premiums--benefit'} style={styles.premiumBenefits}>
                      £95
                    </td>
                    <td className={'premiums--benefit'} style={styles.premiumBenefits}>
                      £50
                    </td>
                  </tr>
                  <tr>
                    <td className={'premiums--label'} style={styles.premiumBody}>
                      Gadget Cover
                    </td>
                    <td className={'premiums--benefit'} style={styles.premiumBenefits}>
                      £1,000
                    </td>
                    <td className={'premiums--benefit'} style={styles.premiumBenefits}>
                      £1,000
                    </td>
                  </tr>
                  <tr>
                    <td className={'premiums--label'} style={styles.premiumBody}>
                      End Supplier Failure
                    </td>
                    <td className={'premiums--benefit'} style={styles.premiumBenefits}>
                      No Cover
                    </td>
                    <td className={'premiums--benefit'} style={styles.premiumBenefits}>
                      No Cover
                    </td>
                  </tr>
                  <tr>
                    <td className={'premiums--label'} style={styles.premiumBody}>
                      Cancellation
                    </td>
                    <td className={'premiums--benefit'} style={styles.premiumBenefits}>
                      £2,500
                    </td>
                    <td className={'premiums--benefit'} style={styles.premiumBenefits}>
                      £2,500
                    </td>
                  </tr>

                  <tr>
                    <td className={'premiums--label'} style={styles.premiumBody}>
                      Medical
                    </td>
                    <td className={'premiums--benefit'} style={styles.premiumBenefits}>
                      £5,000,000
                    </td>
                    <td className={'premiums--benefit'} style={styles.premiumBenefits}>
                      £5,000,000
                    </td>
                  </tr>
                  <tr>
                    <td className={'premiums--label'} style={styles.premiumBody}>
                      Home help
                    </td>
                    <td className={'premiums--benefit'} style={styles.premiumBenefits}>
                      £500
                    </td>
                    <td className={'premiums--benefit'} style={styles.premiumBenefits}>
                      £500
                    </td>
                  </tr>
                  <tr>
                    <td className={'premiums--label'} style={styles.premiumBody}>
                      Cosmetic surgery
                    </td>
                    <td className={'premiums--benefit'} style={styles.premiumBenefits}>
                      £3,000
                    </td>
                    <td className={'premiums--benefit'} style={styles.premiumBenefits}>
                      £3,000


                    </td>
                  </tr>
                  <tr>
                    <td className={'premiums--label'} style={styles.premiumBody}>
                      Physiotherapy
                    </td>
                    <td className={'premiums--benefit'} style={styles.premiumBenefits}>
                      £750
                    </td>
                    <td className={'premiums--benefit'} style={styles.premiumBenefits}>
                      £750
                    </td>
                  </tr>
                  <tr>
                    <td className={'premiums--label'} style={styles.premiumBody}>
                      Curtailment
                    </td>
                    <td className={'premiums--benefit'} style={styles.premiumBenefits}>
                      £2,500
                    </td>
                    <td className={'premiums--benefit'} style={styles.premiumBenefits}>
                      £2,500
                    </td>
                  </tr>
                  <tr>
                    <td/>
                    <td style={styles.premiumBenefits}>
                      <div className={'premiums--btns'}>
                      <div className={'premiums--btn'}
                           style={styles.pdfButton}
                      >
                        VIEW
                      </div>
                        <div className={'premiums--btn'}
                             style={styles.actionButton}
                        >
                          MORE
                        </div>

                      </div>

                    </td>
                    <td style={styles.premiumBenefits}>
                      <div className={'premiums--btns'}>
                        <div className={'premiums--btn'}
                             style={styles.pdfButton}
                        >
                          VIEW
                        </div>
                        <div className={'premiums--btn'}
                             style={styles.actionButton}
                        >
                          MORE
                        </div>

                      </div>

                    </td>
                  </tr>



                </table>
              </div>
              <div className={'sidebar'}>
                <div className={'sidebar--summary'} style={styles.primaryBox}>
                  <div className={'sidebar--summary-section'}>
                    <span className={'sidebar--summary-label'}>Destination:</span>
                    <span className={'sidebar--summary-value'}>France:</span>
                  </div>
                  <div className={'sidebar--summary-section'}>
                    <span className={'sidebar--summary-label'}>Travel Dates:</span>
                    <span
                      className={'sidebar--summary-value'}>14/12/2022 - 22/12/2022</span>
                  </div>
                  <div className={'sidebar--summary-section'}>
                    <span className={'sidebar--summary-label'}>Traveller:</span>
                    <div className={'sidebar--summary-value'}>
                      <div className={'sidebar--summary-travellers'}>
                        <div>
                          Individual (1 adult)
                        </div>
                        <div>
                          Adult 1: Mr Joe Bloggs (12/12/1987
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={'sidebar--summary-section'}>
                    <span className={'sidebar--summary-label'}>Quote Reference:</span>
                    <span className={'sidebar--summary-value'}>NB-ILVRBZD</span>
                  </div>

                </div>

                <div className={'sidebar--info'} style={styles.secondaryBox}>
                  <div className={'sidebar--info-title'}>
                    <i className="fa fa-chevron-circle-right"/> INFORMATION
                  </div>
                  <div className={'sidebar--info-btn'} style={styles.pdfButton}>
                    INSURANCE POLICY
                  </div>
                  <div className={'sidebar--info-btn'} style={styles.pdfButton}>
                    FREQUENTLY ASKED QUESTIONS
                  </div>
                  <div  >
                    Buy with confidence
                  </div>

                </div>
                <div className={'sidebar--contact'} style={styles.primaryBox}>
                  <div className={'sidebar--contact-phone-icon'}>
                    <i className="fa fa-phone"/>
                  </div>
                  <div className={'sidebar--contact-phone'}>
                    {phone}
                  </div>
                  <div className={'sidebar--contact-opening'}>
                    {openingHours}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="wl-footer" style={styles.footer}>
            {footer}
          </div>
        </div>
      </div>
    </div>);

};

export default WebsiteThumbnail;

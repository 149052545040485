import React, { useEffect } from 'react';
import { Field, FormSection } from 'redux-form';
import moment from 'moment';
import { Col, ControlLabel, FormGroup, Row } from 'react-bootstrap';
import {
  Button,
  DatePickerDob,
  Icon,
  NameFields,
} from '../../../../../../common/components';
import LeadTraveller from './components/LeadTraveller';
import { required } from 'redux-form-validators';

export const COL_WIDTHS = {
  labels: { sm: 2, md: 2, lg: 3 },
  fields: { sm: 10, md: 10, lg: 8 },
};
const DEFAULT_TRAVELLER = {
  title: '',
  first_name: '',
  last_name: '',
  dob: '',
  email: '',
  phone1: '',
  phone2: '',
  conditions_covered: true,
  screening: '',
  screening_id: '',
};

const Dob = ({ travellerType, ageLimits }) => {
  const minAge = ageLimits?.[travellerType]?.['min_age'];
  const maxAge = ageLimits?.[travellerType]?.['max_age'];


  const minDate = moment().subtract(maxAge, 'years').format();
  const maxDate = moment().subtract(minAge, 'years').format();

  return (
    <Field
      name={'dob'}
      showYear
      minDate={minDate}
      maxDate={maxDate}
      component={DatePickerDob}
      validate={required({ msg: 'Dob is required' })}
    />
  );
};
const showButton = (travellerGroup, numTravellers, isChild) => {
  if (travellerGroup === 'couple') {
    return false;
  }
  if (isChild && ['family', 'single_parent_family'].includes(travellerGroup) && numTravellers > 10) {
    return false;
  }
  if (!isChild && ['family'].includes(travellerGroup) && numTravellers >= 2) {
    return false;
  }
  if (!isChild && ['single_parent_family'].includes(travellerGroup) && numTravellers >= 1) {
    return false;
  }
  return numTravellers <= 10;
};

const Header = ({ isLeadTraveller, labelField, hideRemove, index, onRemove }) => {

  const remove = () => {
    onRemove(index);
  };

  if (isLeadTraveller) {
    return (
      <h4>
        {<strong>Lead Traveller</strong>}
      </h4>
    );
  }

  return (

    <div>
      <h4>
        {`${labelField} ${index + 1}`}
        {!hideRemove && (
          <span
            //style={{ border: '1px solid blue' }}
            className="remove-traveller pull-right"
            onClick={remove}
          >

            <Icon name="times"/>
          </span>
        )}
      </h4>
    </div>
  );


};

const TravellerFieldArray = ({
  isChild,
  travellerGroup,
  travellerType,
  ageLimits,
  travellers,
  fields,
}) => {

  useEffect(() => {
    // fields.removeAll();
    if (isChild) {
      if (travellerGroup === 'couple') {
        fields.removeAll();
      }
      if (['family', 'single_parent_family'].includes(travellerGroup) && !travellers.length) {
        fields.push(DEFAULT_TRAVELLER);
      }

    }
    if (!isChild) {
      if (travellerGroup === 'couple') {
        if (travellers.length > 2) {
          fields.splice(2, fields.length);
        }
        if (travellers.length < 2) {
          fields.push(DEFAULT_TRAVELLER);
        }

      }
      if (travellerGroup === 'single_parent_family' && travellers.length > 1) {
        fields.splice(1, fields.length);
      }
      if (travellerGroup === 'family' && travellers.length < 2) {
        fields.push(DEFAULT_TRAVELLER);
      }
    }
  }, [travellerGroup]);

  const remove = (index) => {
    fields.remove(index);
  };

  const numTravellers = fields.length;


  const showAddButton = showButton(travellerGroup, numTravellers, isChild);
  const buttonLabel = 'Add ' + (isChild ? 'a Child' : 'an Adult');
  const addTraveller = () => {
    fields.push(DEFAULT_TRAVELLER);
  };

  return (
    <div className="travellers">
      <div>
        {fields.map((insured, index) => {
          const isLeadTraveler = !isChild && !index;
          const hideRemove = 'couple' === travellerGroup;

          return (
            <div
              key={index}
              className={
                'traveller-row-address traveller' +
                index +
                (isChild ? ' child' : '')
              }
            >
              <Header
                isLeadTraveller={isLeadTraveler}
                hideRemove={hideRemove}
                index={index}
                onRemove={remove}
                labelField={isChild ? 'Child' : 'Adult'}
              />
              <FormGroup>
                <Col
                  sm={COL_WIDTHS.labels.sm}
                  md={COL_WIDTHS.labels.md}
                  lg={COL_WIDTHS.labels.lg}
                  componentClass={ControlLabel}>
                  Name
                </Col>
                <Col
                  sm={COL_WIDTHS.fields.sm}
                  md={COL_WIDTHS.fields.md}
                  lg={COL_WIDTHS.fields.lg}>
                  <NameFields
                    baseName={insured}
                    disableOnBlur
                    fieldComponent={Field}
                    child={isChild}
                    validateFirstName={required({ msg: 'First name is required' })}
                    validateLastName={required({ msg: 'First name  is required' })}
                  />
                </Col>
              </FormGroup>

              <FormGroup>
                <Col
                  sm={COL_WIDTHS.labels.sm}
                  md={COL_WIDTHS.labels.md}
                  lg={COL_WIDTHS.labels.lg}
                  componentClass={ControlLabel}>
                  Date of birth
                </Col>
                <Col
                  sm={COL_WIDTHS.fields.sm}
                  md={COL_WIDTHS.fields.md}

                  lg={COL_WIDTHS.fields.lg}
                >
                  <FormSection name={insured}>
                    <Dob
                      travellerType={travellerType}
                      travellerGroup={travellerGroup}
                      ageLimits={ageLimits}
                    />
                  </FormSection>
                </Col>

              </FormGroup>

              {isLeadTraveler && (
                <Field
                  name={'adults.0'}
                  component={LeadTraveller}
                />

              )}
            </div>
          );
        })}
      </div>
      {showAddButton && (
        <Row className="travellers-address">
          <Col sm={COL_WIDTHS.labels.sm + COL_WIDTHS.fields.sm}
               md={COL_WIDTHS.labels.md + COL_WIDTHS.fields.md}
               lg={COL_WIDTHS.labels.lg + COL_WIDTHS.fields.lg}>
            <Button
              bsStyle="primary"
              size="xs"
              className="add-traveller pull-right"
              leftIcon={'plus'}
              label={
                buttonLabel
              }
              handleClick={addTraveller}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default TravellerFieldArray;

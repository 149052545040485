import React from 'react';
import { Router } from 'react-router';
import ReduxToastr from 'react-redux-toastr';
import createRoutes from './routes';
import { hot } from 'react-hot-loader/root';

function App({ store, hashLinkScroll, history }) {
  const routes = createRoutes(store);

  return (
    <div>
      <Router history={history} onUpdate={hashLinkScroll}>
        {routes}
      </Router>
      <ReduxToastr
        timeOut={4000}
        newestOnTop={true}
        progressBar={true}
        preventDuplicates={true}
        position="top-right"
      />
    </div>
  );
}

export default hot(App);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ResetPasswordForm from '../components/ResetPasswordForm/ResetPasswordForm';
import { resetPassword } from '../redux/authActions';

class ResetPasswordContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    params: PropTypes.object,
  };

  handleSubmit(values) {
    return this.props.dispatch(
      resetPassword(
        this.props.params.token,
        values.email,
        values.password,
        values.password_confirmation,
      ),
    );
  }

  render() {
    return (
      <div>
        <div className="guest-title">Welcome to the P J Hayman Insurance Portal</div>
        <div className="login-margin-top">
          <ResetPasswordForm onSubmit={this.handleSubmit.bind(this)} />
        </div>
      </div>
    );
  }
}

export default connect()(ResetPasswordContainer);

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { Col, Form, Row } from 'react-bootstrap';
import InfoLabel from '../../../../common/components/InfoLabel/InfoLabel';
import SchemeManagementSectionContainer
  from '../SchemeManagementSectionContainer/SchemeManagementSectionContainer';
import AssignHandler from '../AssignHandler/AssignHandler';

const AssignedHandler = ({ input, handler, handlerType }) => {
  const inputName = input?.value?.attributes?.name ?? handler ?? '';

  return (
    <InfoLabel
      value={
        inputName
          ? `${handlerType} Handler: ${inputName}`
          : `${handlerType} Handler: ${inputName} not assigned`
      }
    />
  );
};

const HandlersForm = ({
  isViewOnly,
  handleSubmit,
  claimsHandler,
  assistanceHandler,
  title,
}) => {
  return (
    <div className="product-container">
      <Form horizontal onSubmit={handleSubmit}>
        <SchemeManagementSectionContainer
          title={title || 'Assistance / Claims Handler'}
          subTitle={'Handler Details'}
          isViewOnly={isViewOnly}
        >
          <Row className={'section-item'}>
            <Col sm={4} md={2}>
              <label className="control-label blue-title">Claims handlers:</label>
            </Col>
            <Col sm={8} md={10}>
              <Row>
                <Col xs={4}>
                  <Field
                    name={'data.relationships.claims_handler.data'}
                    component={AssignedHandler}
                    handler={claimsHandler}
                    handlerType={'Claims'}
                  />
                </Col>
                <Col xs={5}>
                  <Field
                    name={'data.relationships.claims_handler.data'}
                    component={AssignHandler}
                    isViewOnly={isViewOnly}
                    handlerType={'Claims'}
                    handlerPath={'claims_handler'}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className={'section-item'}>
            <Col sm={4} md={2}>
              <label className="control-label blue-title">Assistance handlers:</label>
            </Col>
            <Col sm={8} md={10}>
              <Row>
                <Col xs={4}>
                  <Field
                    name={'data.relationships.assistance_handler.data'}
                    component={AssignedHandler}
                    handler={assistanceHandler}
                    handlerType={'Assistance'}
                  />
                </Col>
                <Col xs={5}>
                  <Field
                    name={'data.relationships.assistance_handler.data'}
                    component={AssignHandler}
                    isViewOnly={isViewOnly}
                    handlerType={'Assistance'}
                    handlerPath={'assistance_handler'}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </SchemeManagementSectionContainer>
      </Form>
    </div>
  );
};

export default compose(
  connect((state, props) => {
    return {
      formValues: getFormValues(props.formName)(state),
      destroyOnUnmount: false,
      enableReinitialize: true,
      form: props.formName,
    };
  }),
  reduxForm({}),
)(HandlersForm);

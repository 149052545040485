import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Row, Col, FormGroup, ControlLabel } from 'react-bootstrap';
import { size, get, set } from 'lodash';
import moment from 'moment';
import {
  InfoButton,
  DatePickerLeisure,
  //SelectCountry,
  SelectCountries,
  DatePicker,
  ButtonBar,
  Button,
  HorizontalFormControl,
  HorizontalFormCurrency,
} from '../../../../../../common/components/index';
import {
  RadioGroupCircle,
  CheckboxGroup,
  SelectField,
} from '../../../../../../common/components/ReduxFormField/index';
import mapReactSelect from '../../../../../../helpers/mapReactSelect';
import { SelectRegion } from '../../ReduxForm';
import { hasPermission } from '../../../../../auth/redux/authActions';
import './styles/styles.scss';
import CovidStatement from '../../../CovidStatement/CovidStatement';

const META = 'data.attributes.metadata';

const holidayValueChk = (value) => {
  let pass = true;
  if (value < 0 || value > 6000) {
    pass = false;
  }
  return pass ? null : 'Please have a value greater than 300 and less that 6000';
};


const getMonthsInFuture = (productCode) => {
  if (['FST'].includes(productCode)) {
    return 12;
  }
  if (['TUMC', 'CPI', 'CPS'].includes(productCode)) {
    return 18;
  }
  if (['FPS', 'FPR', 'NPO', 'NPOR', 'CSPA', 'CSPAR', 'CSS', 'CSSR']
    .includes(productCode)) {
    return 1;
  }
  return 12;
};

const maxStartDate = (productCode) => {
  if (['247', 'VEN', 'FST'].includes(productCode)) {
    return moment()
      .add(548, 'days');
  }
  return moment().add(getMonthsInFuture(productCode), 'months');
};

// const minStartDate = (productCode) => {
//   if (['CSPA'].includes(productCode)) {
//     return moment(moment().year() + '-07-01');
//   }

//   return moment(moment().year() + '-06-01');
// };

const maxReturnDate = (productCode, startDate) => {

  if (productCode === 'CPI') {
    return (
      startDate.add(45, 'day')
    );
  }
  if (productCode === 'TUMC') {
    return (
      startDate.add(18, 'months')
    );
  }

  if (productCode === 'LSB') {
    return (
      startDate.add(546, 'day')
    );
  }

  if (productCode === 'ADV') {
    return (
      startDate.add(6, 'months')
    );
  }

  if (productCode === 'PST') {
    return (
      startDate.add(185, 'day')
    );
  }

  if (productCode === 'BMC') {
    return (
      startDate.add(365, 'day')
    );
  }

  if (productCode === 'FST') {
    return (
      startDate.add(115, 'day')
    );
  }

  return startDate.add(94, 'day');
};

const backDateCover = (product) => {
  if (hasPermission('policy.backdate_cover')) {
    const backdateCover = get(
      product,
      'data.attributes.metadata.backdate_cover',
      0,
    );
    return moment()
      .subtract(backdateCover, 'days');
  }

  return moment();
};

const AnnualDatePicker = ({ isExpired, minDate, maxDate }) => {
  if (isExpired) {
    return (
      <Field
        name="data.attributes.metadata.start_date"
        id="start_date"
        component={DatePicker}
      />
    );
  }
  return (
    <Field
      name="data.attributes.metadata.start_date"
      id="start_date"
      minDate={minDate}
      maxDate={maxDate}
      component={DatePicker}
    />
  );
};

const SingleDatePicker = ({ isExpired, minDate, maxDate, name }) => {
  if (isExpired) {
    return (
      <Field
        name={name}
        component={DatePickerLeisure}
      />
    );
  }

  return (
    <Field
      name={name}
      minDate={minDate}
      maxDate={maxDate}
      component={DatePickerLeisure}
    />
  );
};

export class TripDetailsTab01 extends Component {
  constructor(props) {
    super(props);
    this.transformDocLinks = this.transformDocLinks.bind(this);
  }

  // componentDidMount() {
  //   const { dispatch, change } = this.props;
  //   dispatch(change('data.current_tab', 1));
  // }

  transformDocLinks(url) {
    const documents = this.state.premium.attributes.metadata.scheme.documents;

    if (url === 'policy_wording') {
      return documents['Policy Wording'];
    } else if (url === 'policy_summary') {
      return documents['Policy Summary'];
    } else if (url === 'tobca') {
      return documents['Terms of Business Customer Agreement'];
    }
    return url;
  }

  getRegion() {
    const { currentProduct, formValues } = this.props;

    if (['FPS', 'FPR', 'CSS', 'CSSR'].includes(currentProduct.data.attributes.product_code)) {
      return get(currentProduct, `${META}.definitions.cover_choice_amt_worldwide`);
    }

    const region = get(formValues, `${META}.region`, '');
    return get(
      currentProduct,
      `${META}.definitions.region_` + region,
      get(currentProduct, `${META}.definitions.region`),
    );
  }

  activities(product) {
    const types = get(product, 'schemes[0].attributes.metadata.activities', {});

    return Object.values(types).map((type) => {
      return { label: type, value: type };
    });
  }

  render() {
    const {
      formValues,
      currentProduct,
      mta,
      productCode,
      isExpired,
      nextTab,
      openSpecificTab,
      product,
      resource,
    } = this.props;
    const goingOnCruise = get(resource, 'data.attributes.metadata.going_on_cruise');
    let showCruiseQuestion = false;
    if (['FST'].includes(productCode)) {
      if (!mta) {
        showCruiseQuestion = true;
      } else {
        if (!goingOnCruise) {
          showCruiseQuestion = true;
        }
      }
    }
    const showActivities = ['ADV', 'PST'].includes(productCode);
    const residencies = mapReactSelect(get(currentProduct, `${META}.residencies`, []));
    const tripType = get(formValues, 'data.attributes.metadata.scheme_type', '');
    const tripTypes = mapReactSelect(currentProduct.data.attributes.metadata.trip_type || {});
    let regions = mapReactSelect(currentProduct.data.attributes.metadata.regions || {});
    const regionsInfo = this.getRegion();
    const definitions = get(currentProduct, `${META}.definitions`);
    const showDestinations = tripType !== 'annual' && !['LSB', 'TUMC', 'BMC', 'DIA', 'ADV', 'PST', 'CPI', 'CPS', 'EURP'].includes(productCode);
    const showRegions = tripType === 'annual' || ['LSB', 'BMC', 'DIA', 'ADV', 'PST', 'CPI', 'CPS', 'EURP'].includes(productCode);

    const normalizeCurrency = (value) => value && value.replace(/[^0-9.]/g, '');
    let vehicleAges = [];

    if (['TUMC', 'CSP'].includes(productCode)) {
      set(currentProduct, 'data.attributes.metadata.region', 'worldwide');
    }

    if (['CPI'].includes(productCode)) {
      set(currentProduct, 'data.attributes.metadata.region', 'uk');
    }

    if (['EURP'].includes(productCode)) {
      set(currentProduct, 'data.attributes.metadata.region', 'europe');
      vehicleAges = [
        { label: '0 years ', value: '00' },
        { label: '1 year old', value: '01' },
        { label: '2 years old', value: '02' },
        { label: '3 years old', value: '03' },
        { label: '4 years old', value: '04' },
        { label: '5 years old', value: '05' },
        { label: '6 years old', value: '06' },
        { label: '7 years old', value: '07' },
        { label: '8 years old', value: '08' },
        { label: '9 years old', value: '09' },
        { label: '10 years old', value: '10' },
        { label: '11 years old', value: '11' },
        { label: '12 years old', value: '12' },
        { label: '13 years old', value: '13' },
        { label: '14 years old', value: '14' },
        { label: 'No more than 15 years old', value: '15' },
      ];
    }
    if (['FPS', 'FPR', 'NPO', 'NPOR', 'CSPA', 'CSPAR', 'CSS', 'CSSR'].includes(productCode)) {
      const startDate = get(formValues, 'data.attributes.metadata.start_date') ? moment(get(formValues, 'data.attributes.metadata.start_date')) : moment();
      let expiryDate = moment(startDate.year() + '-10-31');

      if (['NPO', 'NPOR'].includes(productCode)) {
        expiryDate = moment(startDate.year() + '-05-31');
      }

      if (['CSPA', 'CSPAR'].includes(productCode)) {
        expiryDate = moment(startDate.year() + '-06-30');
      }

      if (['CSS', 'CSSR'].includes(productCode)) {
        expiryDate = moment(startDate.year() + '-09-30');
      }

      if (startDate.isAfter(expiryDate)) {
        expiryDate = expiryDate.add(1, 'year');
      }

      if (!['NPO', 'NPOR', 'CSPA', 'CSPAR', 'CSS', 'FPS', 'FPR'].includes(productCode)) {
        set(currentProduct, 'data.attributes.metadata.region', 'worldwide');
        set(formValues, 'data.attributes.metadata.region', 'worldwide');
      }
      set(formValues, 'data.attributes.metadata.end_date', expiryDate.format());
    }
    if (['CPS'].includes(productCode)) {
      set(currentProduct, 'data.attributes.metadata.region', 'uk');
    }

    const maxTripDurationOptions = mapReactSelect(
      get(currentProduct, 'data.attributes.metadata.annual_trip_durations'),
    );
    const displayMaxTripOption = size(maxTripDurationOptions) > 1;

    const minDate = get(formValues, 'data.attributes.metadata.start_date')
      ? moment(get(formValues, 'data.attributes.metadata.start_date', 'DD/MM/YYYY')) : moment();

    let advancedDays = 30;
    if (['ADV', 'PST'].includes(productCode)) {
      advancedDays = 45;
    }
    if (['EURP'].includes(productCode)) {
      advancedDays = 31;
    }
    if (['BMC'].includes(productCode)) {
      advancedDays = 2000;
    }

    const endDateMin = minDate.format();
    let startDateMax = maxStartDate(productCode).format();
    // const startDateMin = minStartDate(productCode).format();
    let endDateMax = maxReturnDate(productCode, minDate).format();
    const backdateCoverDate = backDateCover(currentProduct).format();
    const annualMaxDate = moment().add(advancedDays, 'days').format();
    const covidMessage = get(currentProduct, 'data.attributes.metadata.covid_message.travel_detail_bo', '');

    let groupTypeOptions = [];

    const filteredGroupTypeOptions = (groupTypeOptions) => {
      const order = ['individual', 'couple', 'family'];
      return groupTypeOptions.sort((a, b) => {
        return order.indexOf(a.value) - order.indexOf(b.value);
      });
    };

    if (['FPS', 'FPR', 'NPO', 'NPOR', 'CSPA', 'CSPAR'].includes(productCode)) {
      startDateMax = moment(`${moment().format('YYYY')}-10-31`);

      if (moment().format('DD') >= 1 && moment().format('MM') >= 11) {
        startDateMax = moment(`${startDateMax.add(1, 'years').format('YYYY-MM-DD')}`);
      }

      const groupType = get(currentProduct, 'data.attributes.metadata.group_type_trip_annual', {});

      groupTypeOptions = Object.entries(groupType).map((value) => {
        return { label: value[1], value: value[0] };
      });
    }

    if (['CSS', 'CSSR'].includes(productCode)) {
      startDateMax = moment(`${moment().format('YYYY')}-01-31`);

      if (moment().format('DD') >= 1 && moment().format('MM') >= 2) {
        startDateMax = moment(`${startDateMax.add(1, 'years').format('YYYY-MM-DD')}`);
      }

      const groupType = get(currentProduct, 'data.attributes.metadata.group_type_trip_annual', {});

      groupTypeOptions = Object.entries(groupType).map((value) => {
        return { label: value[1], value: value[0] };
      });
    }

    if (['FPS', 'FPR'].includes(productCode)) {
      startDateMax = '';
      endDateMax = '';
    }

    return (
      <div className="fadein-fields">
        {mta && (
          <FormGroup>
            <Col md={4} lg={3} componentClass={ControlLabel}>
              <InfoButton content={definitions.trip_type} /> Effective Date
            </Col>
            <Col md={8} lg={6}>
              <Field
                name="data.attributes.effective_date"
                minDate={moment().format()}
                component={DatePicker}
              />
            </Col>
          </FormGroup>
        )}
        {!['CPS', 'FST'].includes(productCode) &&
          <div>
            <CovidStatement covidMessage={covidMessage}
                            showAgreement={(covidMessage && covidMessage !== '' && covidMessage.length > 1)} />
          </div>
        }
        {productCode === 'FST' && (
          <div style={{ marginBottom: '20px' }}>
            <p>
              This insurance policy will not cover you for travel to a specific country or
              to an area where the Foreign Commonwealth and Development Office (FCDO)
              has advised against all, or all but essential travel at the time you purchased
              your policy or booked your trip, whichever is later.
            </p>
            <p>
              You must ensure that you have the permission of all travellers to act on
              their behalf before providing us with their information.
            </p>
          </div>
        )}
        {['FPS', 'FPR', 'NPO', 'NPOR', 'CSPA', 'CSPAR', 'CSS', 'CSSR'].includes(productCode) && (
          <FormGroup>
            <Col md={4} lg={3} componentClass={ControlLabel}>
              <InfoButton content={definitions.group_type} /> Group Type
            </Col>
            <Col sm={6}>
              <Field
                name="data.attributes.metadata.traveller_group"
                labelSize={6}
                smFieldSize={4}
                options={filteredGroupTypeOptions(groupTypeOptions)}
                component={SelectField}
              />
            </Col>
          </FormGroup>
        )}
        <FormGroup>
          <Col md={4} lg={3} componentClass={ControlLabel}>
            <InfoButton content={definitions.residence} /> Residence
          </Col>
          <Col md={8} lg={6}>
            <Field
              name="data.attributes.metadata.residency"
              component={SelectField}
              options={residencies}
            />
          </Col>
        </FormGroup>
        {!mta && tripTypes.length > 1 && (
          <FormGroup>
            <Col md={4} lg={3} componentClass={ControlLabel}>
              <InfoButton content={definitions.trip_type} /> Trip Type
            </Col>
            <Col md={8} lg={6}>
              <Field
                name="data.attributes.metadata.scheme_type"
                infoButton={<InfoButton content={definitions.trip_type} />}
                component={RadioGroupCircle}
                inline
                options={tripTypes}
              />
            </Col>
          </FormGroup>
        )}
        {showCruiseQuestion && (
          <FormGroup>
            <Col md={4} lg={3} componentClass={ControlLabel}>
              <InfoButton content={definitions.cruise_cover} /> Are you going on a
              cruise?
            </Col>
            <Col md={8} lg={6}>
              <Field
                name="data.attributes.metadata.going_on_cruise"
                component={CheckboxGroup}
                inline
                options={[{ label: 'Yes', value: true }]}
              />
              <p style={{ fontSize: '14px', marginTop: '10px' }}>
                Let us know if you are planning on going on an ocean or river cruise
                (including UK cruises) so that we can show you options to cover additional
                benefits such as cabin confinement and missed departure.
                You don’t need to tell us about ferry crossings.
              </p>
            </Col>
          </FormGroup>
        )}
        {get(formValues, 'data.attributes.metadata.scheme_type') !== 'annual' && !['FPS', 'FPR', 'NPO', 'NPOR', 'CSPA', 'CSPAR', 'CSS', 'CSSR'].includes(productCode) && (
          <div>
            <FormGroup>
              <Col md={4} lg={3} componentClass={ControlLabel}>
                <InfoButton content={definitions.travel_dates_single_start_date} /> Start
                Date
              </Col>
              <Col md={8} lg={9}>
                <SingleDatePicker
                  name="data.attributes.metadata.start_date"
                  id="start_date"
                  isExpired={isExpired}
                  minDate={backdateCoverDate}
                  maxDate={startDateMax} />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col md={4} lg={3} componentClass={ControlLabel}>
                <InfoButton content={definitions.travel_dates_single_end_date} /> Return
                Date
              </Col>
              <Col md={8} lg={9}>
                <SingleDatePicker
                  name="data.attributes.metadata.end_date"
                  isExpired={isExpired}
                  id="end_date"
                  minDate={endDateMin}
                  maxDate={endDateMax}
                />
              </Col>
            </FormGroup>
          </div>
        )}
        {get(formValues, 'data.attributes.metadata.scheme_type') === 'annual' && !['FPS', 'FPR', 'NPO', 'NPOR', 'CSPA', 'CSPAR', 'CSS', 'CSSR'].includes(productCode) && (
          <FormGroup>
            <Col md={4} lg={3} componentClass={ControlLabel}>
              <InfoButton content={definitions.travel_dates_annual} /> Travel Dates
            </Col>
            <Col md={8} lg={9}>
              <AnnualDatePicker
                minDate={backdateCoverDate}
                maxDate={annualMaxDate}
                isExpired={isExpired}
              />
            </Col>
          </FormGroup>
        )}
        {['FPS', 'FPR', 'CSS', 'CSSR'].includes(productCode) && (
          <div>
            <FormGroup>
              <Col md={4} lg={3} componentClass={ControlLabel}>
                <InfoButton content={definitions.travel_dates_single_start_date} /> Start
                Date
              </Col>
              <Col md={8} lg={9}>
                <SingleDatePicker
                  name="data.attributes.metadata.start_date"
                  id="start_date"
                  isExpired={isExpired}
                  minDate={backdateCoverDate}
                  maxDate={startDateMax} />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col md={4} lg={3} componentClass={ControlLabel}>
                <InfoButton content={definitions.travel_dates_single_end_date} />
                {!['FPS', 'FPR', 'CSPA', 'CSPAR', 'NPO', 'NPOR'].includes(productCode) ? 'Return Date' : 'End Date'}
              </Col>
              <Col md={8} lg={9}>
                <Field
                  name="data.attributes.metadata.end_date"
                  id="end_date"
                  component={DatePickerLeisure}
                />
              </Col>
            </FormGroup>
          </div>
        )}
        {['NPO', 'NPOR', 'CSPA', 'CSPAR'].includes(productCode) && (
          <div>
            <FormGroup>
              <Col md={4} lg={3} componentClass={ControlLabel}>
                <InfoButton content={definitions.travel_dates_single_start_date} /> Start
                Date
              </Col>
              <Col md={8} lg={9}>
                <SingleDatePicker
                  name="data.attributes.metadata.start_date"
                  id="start_date"
                  isExpired={isExpired}
                  // minDate={startDateMin}
                  maxDate={startDateMax} />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col md={4} lg={3} componentClass={ControlLabel}>
                <InfoButton content={definitions.travel_dates_single_end_date} />
                {!['FPS', 'FPR', 'CSPA', 'CSPAR', 'NPO', 'NPOR'].includes(productCode) ? 'Return Date' : 'End Date'}
              </Col>
              <Col md={8} lg={9}>
                <Field
                  name="data.attributes.metadata.end_date"
                  id="end_date"
                  component={DatePickerLeisure}
                />
              </Col>
            </FormGroup>
          </div>
        )}
        {showDestinations && !['FPS', 'FPR', 'CSPA', 'CSPAR', 'NPO', 'NPOR'].includes(productCode) && (
          <FormGroup>
            <Col md={4} lg={3} componentClass={ControlLabel}>
              <InfoButton content={definitions.destinations} /> Destination
            </Col>
            <Col md={8} lg={6}>
              <Field
                name="data.attributes.metadata.destinations"
                placeholder="Search for countries..."
                component={SelectCountries}
                optionValuePath={'attributes.name'}
              />
            </Col>
          </FormGroup>
        )}
        {showRegions && (
          <FormGroup>
            <Col md={4} lg={3} componentClass={ControlLabel}>
              <InfoButton content={regionsInfo} /> What region are you going to?
            </Col>
            <Col md={8} lg={6} className={'select-region'}>
              <Field
                name="data.attributes.metadata.region"
                component={SelectRegion}
                options={regions}
              />
            </Col>
          </FormGroup>
        )}
        {tripType === 'annual' && displayMaxTripOption && (
          <FormGroup>
            <Col md={4} lg={3} componentClass={ControlLabel}>
              <InfoButton content={definitions.max_trip_duration} />
              Max Trip Duration
            </Col>
            <Col md={8} lg={6}>
              <Field
                name="data.attributes.metadata.max_trip_duration"
                component={SelectRegion}
                options={maxTripDurationOptions}
              />
            </Col>
          </FormGroup>
        )}
        {showActivities && (
          <FormGroup>
            <Col md={4} lg={3} componentClass={ControlLabel}>
              Activities
            </Col>
            <Col md={8} lg={6}>
              <Field
                name="data.attributes.metadata.activities"
                component={SelectField}
                options={this.activities(product)}
                isMulti={true}
              />
            </Col>
          </FormGroup>
        )}
        {['EURP'].includes(productCode) && (
          <div>
          <FormGroup>
              <Col md={4} lg={3} componentClass={ControlLabel}>
                <InfoButton content={definitions.age_vehicle} />How old is your Vehicle?
              </Col>
              <Col md={8} lg={6}>
                <Field
                  name="data.attributes.metadata.age_vehicle"
                  component={SelectField}
                  options={vehicleAges}
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col md={4} lg={3} componentClass={ControlLabel}>
                <InfoButton content={definitions.vehicle_reg} />Vehicle Registration
                Number
              </Col>
              <Col md={8} lg={6}>
                <Field
                  name="data.attributes.metadata.vehicle_reg"
                  component={HorizontalFormControl}
                />
              </Col>
            </FormGroup>
          </div>
        )}
        {['TUMC'].includes(productCode) && (
          <div>
            <FormGroup>
              <Col md={4} lg={3} componentClass={ControlLabel}>
                <InfoButton content={definitions.existing_provider} /> Existing provider
              </Col>
              <Col md={8} lg={6}>
                <Field
                  name="data.attributes.metadata.existing_provider"
                  component={HorizontalFormControl}
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col md={4} lg={3} componentClass={ControlLabel}>
                <InfoButton content={definitions.underlying_cover} /> Existing cover (per
                person)
              </Col>
              <Col md={8} lg={6}>
                <Field
                  name="data.attributes.metadata.underlying_cover"
                  component={HorizontalFormCurrency}
                  normalize={normalizeCurrency}
                  currency={true}
                  code={'gbp'}
                  noLabel={true}
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col md={4} lg={3} componentClass={ControlLabel}>
                <InfoButton content={definitions.topup_cover} /> Additional cover (per
                person)
              </Col>
              <Col md={8} lg={6}>
                <Field
                  name="data.attributes.metadata.topup_cover"
                  component={HorizontalFormCurrency}
                  normalize={normalizeCurrency}
                  currency={true}
                  code={'gbp'}
                  noLabel={true}
                />
              </Col>
            </FormGroup>
          </div>
        )}
        {['CPI'].includes(productCode) && (
          <div>
            <FormGroup>
              <Col md={4} lg={3} componentClass={ControlLabel}>
                <InfoButton content={definitions.max_holiday_value} /> Holiday value
              </Col>
              <Col md={8} lg={6}>
                <Field
                  name="data.attributes.metadata.max_holiday_value"
                  component={HorizontalFormCurrency}
                  normalize={normalizeCurrency}
                  currency={true}
                  code={'gbp'}
                  noLabel={true}
                  validate={holidayValueChk}
                />
              </Col>
            </FormGroup>
          </div>
        )}
        {!['FPS', 'FPR', 'NPO', 'NPOR', 'CSPA', 'CSPAR', 'CSS', 'CSSR'].includes(productCode) ? (
          <div>
            <hr />
            <Row>
              <Col sm={12}>
                <ButtonBar>
                  <Button
                    id="next"
                    label="Next"
                    className="pull-right btn-action-blue"
                    bsStyle="primary"
                    handleClick={() => openSpecificTab(nextTab)}
                  />
                </ButtonBar>
              </Col>
            </Row>
          </div>
        ) : (
          <hr />
        )}
      </div>
    );
  }
}

const FORM_NAME = 'LeisureTravelForm';
const form = reduxForm({ form: FORM_NAME })(TripDetailsTab01);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  const values = selector(
    state,
    'data.id',
    'meta.purchase_declaration',
    'data.attributes.metadata',
  );

  return {
    auth: state.auth,
    initialValues: props.resource,
    form: 'LeisureTravelForm',
    formValues: values,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    product: state.product,
  };
};

export default connect(mapStateToProps)(form);

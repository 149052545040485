import { startCase } from 'lodash';

export const ALLOWED_INPUTS = {
  'Leisure Travel Insurance': [
    {
      value: 'max_age',
      label: 'Max Age',
      type: 'int',
    },
    {
      value: 'max_trip_duration',
      label: 'Max Trip Duration',
      type: 'int',
    },
    {
      value: 'max_score',
      label: 'Max Medical Score',
      type: 'int',
    },
    {
      value: 'trip_deferred_days',
      label: 'Trip Deferred Days',
      type: 'int',
    },
    {
      value: 'trip_duration',
      label: 'Trip Duration',
      type: 'int',
    },
    {
      value: 'region',
      label: 'Region',
      type: 'array',
    },
    {
      value: 'swap.tax',
      label: 'IPT Rate',
      type: 'ipt',
    },
    // {
    //   value: 'medical_declaration_questions.terminal-question.answer',
    //   label: 'Terminal Days',
    //   type: 'int',
    // },
    // {
    //   value: 'medical_declaration_questions.terminal-question.*',
    //   label: 'Medical Question',
    //   type: 'boolean',
    // },
    // {
    //   value: 'start_date',
    //   label: 'Advance day restriction',
    //   type: 'date',
    // },
    {
      value: 'cover_level',
      label: 'Cover Level',
      type: 'array',
    },
    {
      value: 'swap.tax',
      label: 'IPT Rate',
      type: 'ipt',
    },
    {
      value: 'advance_days',
      label: 'Advance day restriction',
      type: 'int',
    },
    {
      value: 'adults',
      label: 'Number of Adults',
      type: 'int',
    },
  ],
};

const ALLOWED_RULES = [
  {
    value: 'max',
    label: 'More Than',
    type: 'int',
  },
  {
    value: 'min',
    label: 'Less Than',
    type: 'int',
  },
  {
    value: 'conditions',
    label: 'In',
    type: 'array',
  },
  {
    value: 'minDateFormula',
    label: 'Date',
    type: 'date',
  },
  {
    value: 'minAmount',
    label: 'Equals',
    type: 'int',
  },
  {
    value: 'isOneOf',
    label: 'In',
    type: 'array',
  },
  {
    value: 'equal',
    label: 'Equals',
    type: 'string',
  },
  {
    value: 'equal',
    label: 'Equals',
    type: 'ipt',
  },

  {
    value: 'equal',
    label: 'Equals',
    type: 'int',
  },
  {
    value: 'equal',
    label: 'Equals',
    type: 'boolean',
  },
  // {
  //   value: 'isNotEmpty',
  //   label: 'Not Empty',
  //   type: 'array',
  // }
];

export const EMPTY_RULE = {
  input: '',
  rule: '',
  value: '',
};
const getAllowedMedicalInputs = (medicalKeys) => {
  return medicalKeys?.map((medicalKey) => {

    if (medicalKey !== 'terminal-question') {
      return {
        value: `medical_declaration_questions.${medicalKey}.answer`,
        label: `Medical Question ${medicalKey}`,
        type: 'boolean',
      };
    }
    return {
      value: 'medical_declaration_questions.terminal-question.answer',
      label: 'Terminal Days',
      type: 'int',
    };
  }) ?? [];
};

export const getAllowedInputs = (productType, medicalKeys) => {
  const medicalInputs = getAllowedMedicalInputs(medicalKeys);

  return medicalInputs?.concat(ALLOWED_INPUTS[productType]);
};

const formatMultiDeclines = (referral, decline, productType, medicalKeys) => {
  const referralType = referral?.decline ?? referral?.check;

  return Object.keys(referralType[decline]).map((declineName) => {
    const value = referralType[decline][declineName];
    const matchedAllowedInput = getAllowedInputs(productType, medicalKeys)?.find(
      (allowedInput) => allowedInput.value === declineName,
    );

    return {
      input: declineName,
      rule: decline.substring(5, decline.length).toLowerCase(),
      value: value,
      type: matchedAllowedInput?.type ?? 'string',
    };
  });
};

const formatDecline = (decline, productType, medicalKeys) => {
  if (!!decline.conditions) {
    return [];
  }
  const referral = decline?.decline ?? decline?.check;

  const rule = Object.keys(referral)[0];
  const value = referral[rule];

  const matchedAllowedInput = getAllowedInputs(productType, medicalKeys).find(
    (allowedInput) => allowedInput.value === decline.name,
  );
  return [
    {
      input: decline.name,
      rule: rule,
      value: value,
      type: matchedAllowedInput?.type ?? 'string',

    },
  ];
};

export const getDeclinesList = (decline, referral, productType) => {
  const conditions = referral.conditions;
  const conditionsList = conditions
    ? Object.keys(conditions).map((conditionKey) => {
      return {
        value: conditions[conditionKey],
        input: conditionKey,
        rule: 'conditions',
        type: 'array',
      };
    })
    : [];


  const declines = decline?.startsWith('multi')
    ? formatMultiDeclines(referral, decline, productType)
    : formatDecline(referral, productType);
  conditionsList.map((cond) => declines.push(cond));

  return declines;
};

export const getValidInputs = (inputType, declines, productType, medicalQuestionKeys) => {
  if (declines?.length <= 1) {
    return getAllowedInputs(productType, medicalQuestionKeys);
  }
  const declineInputs = declines.map((decline) => decline.input);

  return getAllowedInputs(productType, medicalQuestionKeys).filter((allowedInput) => {
    if (inputType === allowedInput.value) {
      return true;
    }
    return !declineInputs.includes(allowedInput.value);
  });
};

export const getValidRules = (inputType, declines) => {
  const alreadyHasConditions =
    !!declines.find((dec) => dec.rule === 'conditions') || declines?.length === 1;


  const allowedRules = ALLOWED_RULES.filter((rule) => {
    return alreadyHasConditions ? rule.value !== 'conditions' : rule.value !== 'isOneOf';
  });

  if (!inputType) {
    return allowedRules;
  }
  return allowedRules.filter((allowedRule) => {
    return allowedRule.type === inputType;
  });
};

export const formatOptionDeclines = (decline) => {
  if (!decline?.declines?.length) {
    /*
     * a simple decline for this option
     *
     * name: option.exclude_baggage
     * reason: no baggage
     * equal: true
     *
     * or
     *
     * name: option.hazardous_activities
     * reason: no baggage
     * equal: B
     *
     * */

    const dec = {
      name: decline.option,
      reason: decline.reason,
      decline: {
        equal: decline.value || true,
      },
    };
    if (decline?.team_factor) {
      dec['team_factor'] = Number.parseInt(decline.team_factor);
    }

    return dec;
  }

  /* decline with an option attached
   * e.g
   * name: max_age
   * reason: winter sports decline
   * option
   *   key winter_sport
   *   value: true
   * decline:
   *   max: 60
   *
   * name: max_age
   * reason: winter sports decline
   * option
   *   key hazardous_activities
   *   value: D
   * decline:
   *   max: 60
   *
   * name: max_age
   * reason: winter sports decline
   * option
   *   key winter_sports
   *   value: true
   * decline:
   *   multiMax:
   *     max_age :60
   *     max_trip_duration :60
   *
   * name: max_age
   * reason: winter sports decline
   * option
   *   key hazardous_activities
   *   value: D
   * decline:
   *   max: 60
   * conditions:
   *   region
   *     - worldwide
   */

  const optionName = decline.option;
  const dec = {
    name: decline.declines[0].input,
    reason: decline.reason,
    option: {
      value: decline.value || true,
      key: optionName.substring(optionName.indexOf('.') + 1),
    },
    ...formatDeclineSection(decline.declines, decline.referral_type),
  };
  if (decline?.team_factor) {
    dec['team_factor'] = Number.parseInt(decline.team_factor);
  }

  return dec;
};

export const formatDeclines = (decline) => {

  const dec = {
    name: decline.declines[0].input,
    reason: decline.reason,
    ...formatDeclineSection(decline.declines, decline.referral_type),
  };
  if (decline?.team_factor) {
    dec['team_factor'] = Number.parseInt(decline.team_factor);
  }

  return dec;
};

const formatDeclineSection = (declines, referralType) => {
  const groupedDeclines = declines.reduce((carry, decline) => {
    if (carry[decline.rule]) {

      carry[decline.rule].push(decline);
      return carry;
    }
    carry[decline.rule] = [decline];
    return carry;
  }, {});


  /**
   *  decline with conditions - can be a single string but using array for simplicity
   *  conditions:
   *    region
   *      - europe
   *      - worldwide
   */
  const conditions = groupedDeclines?.conditions
    ? {
      conditions: {
        [groupedDeclines?.conditions?.[0]?.input]: groupedDeclines?.conditions?.[0]
          ?.value,
      },
    }
    : {};

  const formattedDeclines = getFormattedDeclines(groupedDeclines);

  const declineValues = formattedDeclines
    ? {
      [referralType]: formattedDeclines,
    }
    : {};

  return {
    ...declineValues,
    ...conditions,
  };
};

const getFormattedDeclines = (groupedDeclines) => {
  const rulesList = Object.keys(groupedDeclines);
  return rulesList.reduce((carry, ruleKey) => {
    if (ruleKey === 'conditions') {
      return carry;
    }
    const declines = groupedDeclines[ruleKey];

    if (declines.length > 1) {
      /**
       * more than one decline means this needs to be either a multiMax or multiMin decline
       * e.g
       *  name: max_age
       *  reason: a reason
       *  decline:
       *    multiMax
       *      max_trip_duration: 31
       *      max_age: 63
       */
      const declineKey = `multi${startCase(ruleKey)}`;
      return {
        ...carry,
        [declineKey]: declines.reduce((carry, input) => {
          carry[input.input] = input.value;
          return carry;
        }, {}),
      };
    }

    if (declines.length === 1) {
      const input = declines[0];
      return {
        ...carry,
        [ruleKey]: input.value,
      };
    }
  }, {});
};

import { toastr } from 'react-redux-toastr';
import { SubmissionError } from 'redux-form';
import { get } from 'lodash';

export default function handleErrorResponse(
  error,
  defaultMessage = 'An application error has occurred',
) {
  const message = get(error.response, 'data.errors[0].detail', defaultMessage);
  toastr.error('Error', message);

  throw new SubmissionError({ _error: message });
}

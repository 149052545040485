import React from 'react';
import '../styles.scss';
import { get } from 'lodash';
import { Field } from 'redux-form';
import InfoButton from '../../../../../../../common/components/InfoButton/InfoButton';
import {
  Button,
  HorizontalFormControl,
  NameFields,
  Icon,
} from '../../../../../../../common/components';
import { Row, Col, FormGroup, ControlLabel } from 'react-bootstrap';
import SelectRole from '../../../../../../../common/components/Selects/SelectRole';
import ButtonBar from '../../../../../../../common/components/ButtonBar/ButtonBar';

const USERS = 'data.attributes.users';

export default class BrokerUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      roles: {}
    };
  }

  componentWillMount() {
    const { formValues } = this.props;
    let userRoles = {};

    get(formValues, USERS, []).map((user, index) => {
      userRoles[index] = user.role_id;
    });

    this.setState({
      roles: userRoles
    });
  }

  componentDidMount() {
    const { fields, formValues } = this.props;
    if (!get(formValues, USERS) || get(formValues, USERS) === []) {
      fields.push();
    }
  }

  render() {
    const { fields, label, isLoggedIn, dispatch, change } = this.props;
    const { roles } = this.state;
    let showButton = true;

    return (
      <div className="brokerap-new-user">

        {fields.map((user, index) => {
          const USER = `${USERS}[${index}]`;

          return (
            <div key={index}>
              <Row>
                <Col sm={12}>
                  {!index && !label ? null : (
                    <span className="pull-right" onClick={() => fields.remove(index)}>
                        <Icon name="times"/>
                      </span>
                  )}
                  <h4>
                    <strong>New User</strong>
                  </h4>
                </Col>
              </Row>
              <NameFields
                baseName={user}
                label="Name"
                labelSizes={{ sm: 2 }}
                fieldSizes={{ sm: 10 }}
                fieldComponent={Field}
                disableOnBlur
              />
              <FormGroup>
                <Col sm={2} componentClass={ControlLabel}>
                  Email
                </Col>
                <Col sm={4}>
                  <Field
                    name={`${USER}.email`}
                    type="email"
                    component={HorizontalFormControl}
                  />
                </Col>
                {isLoggedIn && (
                  <div>
                    <Col sm={2} componentClass={ControlLabel}>
                      User Role&nbsp;
                      <InfoButton
                        content={
                          'Set at least one user to Admin so as to make a primary contact'
                        }
                      />
                    </Col>
                    <Col sm={4}>
                      <SelectRole
                        key={'roleId' + index}
                        autoFilters={[{ type: 'organisation_type', match: 'Broker' }]}
                        single
                        isObject
                        input={{
                          value: { id: roles[index] },
                          onChange: (role) => {
                            this.setState({
                              roles: {
                                ...roles,
                                [index]: role.id,
                              }
                            });
                            dispatch(change(`${USER}.role_id`, role.id));
                          }
                        }}
                      />
                    </Col>
                  </div>
                )}
              </FormGroup>
            </div>
          );
        })}

        {showButton && (
          <Row>
            <Col sm={12}>
              <ButtonBar>
                <Button
                  bsStyle="primary"
                  className="pull-right"
                  size="xs"
                  leftIcon="plus"
                  label={'Add ' + 'User'}
                  handleClick={() => fields.push()}
                />
              </ButtonBar>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

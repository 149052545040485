import React, { Component } from 'react';
import { sortBy, get, map, startCase, forEach } from 'lodash';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { FormattedDate } from 'react-intl';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { MergeCustomersButton } from '../MergeCustomersButton';
import displayAddress from '../../../../helpers/displayAddress';
import { hasPermission } from '../../../auth/redux/authActions';
import { createCustomerAccount } from '../../redux/customerActions';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import { InfoLabel, DataTable, Alert, Box, Button } from '../../../../common/components';
import './styles.scss';
import {getRenewalProductCodes} from '../../../../helpers/getRenewalProductCodes';

export default class CustomerDetails extends Component {
  constructor(props) {
    super(props);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleCreateCustomerAccount = this.handleCreateCustomerAccount.bind(this);
  }

  formatDate(cell) {
    return <FormattedDate value={new Date(cell)} />;
  }

  formatName(cell, row) {
    return `${row.title}{' '}${row.first_name}{' '}${row.last_name}`;
  }

  formatAddress(cell, row) {
    return row.address.postcode;
  }

  handleCreateCustomerAccount() {
    this.props.dispatch(createCustomerAccount(this.props.resource.data.id));
  }

  handleRowClick(row) {
    return this.props.dispatch(push('/policies/issued/' + row.id));
  }

  handleGetQuote(productId) {
    const reference = this.props.resource.data.id;
    this.props.dispatch(push(`/products/${productId}/quote/?customer=${reference}`));
  }

  render() {
    const { user } = this.props.auth;
    const resource = this.props.resource.data.attributes;
    const mktgReferral = get(resource, 'metadata.marketing_referral');
    const address = displayAddress(this.props.resource.data.attributes.address);
    const marketingOptionsArray = [];
    const NOT_APPLICABLE = 'not applicable';

    let index = 0;
    const marketingOptions = map(get(resource, 'marketing_options', []), (option, i) => {
      if (option > 0) {
        let detail = index ? ', ' : '';
        index++;
        return detail + startCase(i);
      }
    });

    forEach(resource.marketing_options, (item, key) => {
      if (item === 1) {
        return marketingOptionsArray.push(key);
      }
    });
    const renderFullName = `${resource.title} ${resource.first_name} ${
      resource.last_name
    }`;

    const products = sortBy(
      getIncludedResource(user.data, user.included, 'available_products'),
      'attributes.name',
    );

    return (
      <Box>
        <h2 className="resource-name">{renderFullName}</h2>
        <div className="row">
          <div className="col-xs-12">
            <Alert />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-horizontal">
              {resource.company_name && (
                <InfoLabel
                  label="Company Name"
                  value={resource.company_name}
                  labelSize={4}
                />
              )}
              <InfoLabel label="Name" value={renderFullName} labelSize={4} />
              <InfoLabel label="Date of Birth" value={resource.dob} date labelSize={4} />
              <InfoLabel label="Email" value={resource.email} labelSize={4} />
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-horizontal">
              <InfoLabel label="Address" value={address} labelSize={4} />
              <InfoLabel label="Phone" value={resource.phone1} labelSize={4} />
              <InfoLabel
                label="Alternative Phone"
                value={resource.phone2}
                labelSize={4}
              />
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-horizontal">
              <InfoLabel
                label="Marketing Options:"
                value={marketingOptions}
                labelSize={4}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-horizontal">
              <InfoLabel label="Marketing Referral" value={mktgReferral} labelSize={4} />
            </div>
          </div>
        </div>

        <div className="hr transparent" />

        <div className="row">
          <div className="col-xs-12">
            <div className="form-horizontal">
              <h3>New Quote</h3>
              {products.map((product) => {
                if (getRenewalProductCodes().includes(product.attributes.product_code)) {
                  return null;
                }

                return (
                  <Button
                    bsStyle="primary"
                    className="pad-right"
                    handleClick={() => this.handleGetQuote(product.id)}
                    key={product.id}
                  >
                    {product.attributes.name}
                  </Button>
                );
              })}
            </div>
          </div>
        </div>

        <div className="hr transparent" />

        <div className="row">
          <div className="col-xs-12">
            <div className="form-horizontal">
              <h3>Policies</h3>
              <DataTable
                source="/policies"
                name="policies"
                autoFilterType="filter[customer]"
                autoFilter={this.props.resource.data.id}
                showTotal
                onRowSelect={this.handleRowClick}
              >
                <TableHeaderColumn dataField="id" isKey hidden />
                <TableHeaderColumn dataField="policy_number" dataSort width={'150px'}>
                  Policy Number
                </TableHeaderColumn>
                <TableHeaderColumn dataField="insured" dataSort width={'150px'}>
                  Insured
                </TableHeaderColumn>
                <TableHeaderColumn dataField="status" dataSort width={'150px'}>
                  Status
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="created_at"
                  dataFormat={this.formatDate}
                  dataSort
                  width={'100px'}
                >
                  Quote Date
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="issued_at"
                  dataFormat={this.formatDate}
                  dataSort
                  width={'100px'}
                >
                  Issue Date
                </TableHeaderColumn>
              </DataTable>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12" />
          {hasPermission('customer.edit') && (
            <Button
              link
              to={'/customers/' + this.props.resource.data.id + '/edit'}
              className="pull-right"
              bsStyle="primary"
              rightIcon="pencil-square-o"
            >
              Edit Customer
            </Button>
          )}
          {resource.status === NOT_APPLICABLE && hasPermission('customer.edit') && (
            <Button
              className="pull-right pad-right"
              bsStyle="primary"
              handleClick={this.handleCreateCustomerAccount}
              rightIcon="address-card-o"
            >
              Create Customer Account
            </Button>
          )}
          <MergeCustomersButton className="pull-right pad-right" />
        </div>
      </Box>
    );
  }
}

CustomerDetails.propTypes = {
  dispatch: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
  resource: PropTypes.object.isRequired,
};

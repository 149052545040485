import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { ControlLabel, FormControl, Row, Col } from 'react-bootstrap';
import { Box, Button, DataTable } from '../../../../common/components';
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions';
import formatDateTime from '../../../../helpers/formatDateTime'
import Select from 'react-select';
import selectTheme from "../../../../helpers/selectTheme";
import SelectProduct from "../../../../common/components/Selects/SelectProduct";

export default class Underwriting extends Component {
  handleRowClick = (row) =>  {
    this.props.dispatch(push('/admin/content/' + row.id));
  };

  handleFilterUpdate = (event) =>  {
    this.props.dispatch(
      applyFilter('content', 'filter[template.type]', event.target.value),
    );
  };

  handleStatusUpdate(option) {
    const { dispatch } = this.props;
    dispatch(applyFilter('content', 'filter[status]', option ? option.value : ''));

  }

  handleProductUpdate(option) {
    const { dispatch } = this.props;
    dispatch(applyFilter('content', 'filter[product]', option ? option.value : ''));

  }

  handleSearchUpdate = (event) =>  {
    this.props.dispatch(applyFilter('content', 'filter[name]', event.target.value));
  };

  render() {
    const { filterBy } = this.props;
    return (
      <div>
        <Box>
          <Row>
            <Col sm={5}>
              <ControlLabel className="filter-label">Search</ControlLabel>
              <FormControl onChange={this.handleSearchUpdate} />
            </Col>
            <Col sm={3}>
              <ControlLabel className="filter-label">Status</ControlLabel>
              <Select
                theme={(theme) => selectTheme(theme)}
                options={[
                  { label: 'Active', value: 'active' },
                  { label: 'All', value: '' },
                ]}
                isClearable
                onChange={(option) => this.handleStatusUpdate(option)}
              /></Col>
            <Col sm={3}>
              <ControlLabel className="filter-label">Product</ControlLabel>
              <SelectProduct
                isClearable
                onChange={(option) => this.handleProductUpdate(option)}
              />

            </Col>
            </Row>

          <DataTable
            source="/contents"
            name="content"
            showTotal
            onRowSelect={this.handleRowClick}
            autoFilters={[
              { type: 'filter[template.type]', value: filterBy },
              { type: 'filter[status]', value: 'active' }]}
          >
            <TableHeaderColumn dataField="id" isKey hidden>
              ID
            </TableHeaderColumn>
            <TableHeaderColumn dataField="name" dataSort>
              Name
            </TableHeaderColumn>
            <TableHeaderColumn dataField="content_type" dataSort width={'180px'}>
              Type
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="created_at"
              dataSort
              dataFormat={formatDateTime}
              width={'180px'}
            >
              Created
            </TableHeaderColumn>
          </DataTable>

          <div className="row actions">
            <Button
              link
              to="/admin/content/new"
              className="pull-right"
              bsStyle="primary"
              label="New Content"
            />
          </div>
        </Box>
      </div>
    );
  }
}

Underwriting.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InternalContainer from '../../layout/containers/Internal';
import ContentAnnouncementList from '../components/ContentAnnouncementList';

class AnnouncementList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    content: PropTypes.object.isRequired,
  };

  state = {
    activeTab: 1,
  };

  handleTab(key) {
    this.setState({ activeTab: key });
  }

  render() {
    return (
      <InternalContainer title="Announcement List">
        <ContentAnnouncementList {...this.props} />
      </InternalContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    content: state.content,
  };
}

export default connect(mapStateToProps)(AnnouncementList);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Box, Button, SelectRole } from '../../../../common/components';
import { Form, Col, Row } from 'react-bootstrap';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';

const FORM_NAME = 'userRolesForm';

class UserRolesForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    resource: PropTypes.object,
    formValues: PropTypes.object,
  };

  render() {
    const { resource, handleSubmit, submitting, organisation } = this.props;

    return (
      <Box>
        <h2>
          {resource
            ? resource.data.attributes.first_name +
              ' ' +
              resource.data.attributes.last_name
            : 'New user'}
        </h2>
        <Form onSubmit={handleSubmit} autoComplete="off">
          <Row>
            <Col xs={12}>
              <div className="form-horizontal">
                <h3>{organisation.data.attributes.name}</h3>
                <Field
                  name="data.relationships.roles.data"
                  label="Roles"
                  labelSize={2}
                  autoFilters={[{ type: 'organisation_type', match: organisation.data.attributes.organisation_type }]}
                  component={SelectRole}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <InternalButtonBar
                buttons={[
                  {
                    permissions: ['marketing.source_create'],
                    button: (
                      <Button
                        className="pull-right"
                        type="submit"
                        bsStyle="primary"
                        label="Save Changes"
                        isLoading={submitting}
                      />
                    ),
                  },
                ]}
              />
            </Col>
          </Row>
        </Form>
      </Box>
    );
  }
}

const form = reduxForm({ form: FORM_NAME })(UserRolesForm);
const mapStateToProps = (state, props) => {
  const initialValues = {
    data: {
      relationships: {
        organisation: {
          data: {
            id: props.organisation.data.id,
          },
        },
        roles: {
          data: props.roles.data,
        },
      },
    },
  };

  return {
    initialValues,
  };
};

export default connect(mapStateToProps)(form);

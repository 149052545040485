import React, { Component } from 'react';
import '../../brochure.scss';
import './CenteredSection.scss';
export default class CenteredSection extends Component {
  render() {
    const { children } = this.props;

    return (
      <div
        id={this.props.id}
        className={'full-width-section centered-section ' + (this.props.className || '')}
      >
        {this.props.className && this.props.className !== 'anchor' && <div />}

        <div className="container relative">
          <div className="row">
            <div className="col-md-12">{children}</div>
          </div>
        </div>

        {this.props.className && this.props.className !== 'anchor' && <div />}
      </div>
    );
  }
}

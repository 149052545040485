import React, { Component } from 'react';
import { getMonitoredJobs } from '../redux/systemHealthActions';
import { get } from 'lodash';
import { Box, InfoButton } from '../../../common/components';
import displayName from '../../../helpers/displayName';
import './style.scss';

export default class SystemHealthList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      jobList: [],
      dateList: [],
    };
  }

  componentWillMount() {
    const { dispatch } = this.props;
    const days = 6;
    Promise.resolve(dispatch(getMonitoredJobs(days))).then((response) => {
      this.setState({
        dateList: get(response, 'data.meta.dates', []),
        jobList: get(response, 'data.meta.job_list', []),
      });
    });
  }

  getTDColor = (jobStatus) => {
    switch (jobStatus) {
      case 'NOT RUN':
      case 'FAILURE':
        return 'red';
      case 'OK':
        return 'green';
      default:
        return 'white';
    }
  }

  getDisplayableMetadata = (objMetadata) => {
    let strMetadataMsg = '<div style="min-width: 220px;">';
    Object.keys(objMetadata).map((metadataKey) => {
      strMetadataMsg += `${displayName(metadataKey)}: ${objMetadata[metadataKey]}<br/>`;
    });

    return strMetadataMsg + '</div>';
  }

  render() {
    const { dateList, jobList } = this.state;
    return (
      <Box>
        <h2 className="resource-name">System Health</h2>
        <div className="row healthTable">
          <table className='table table-striped table-hover'>
            <thead>
            <tr>
              <th id="th1">Category</th>
              <th id="th2">Job Name</th>
              {dateList.map((date, index) => <th className={ 'sort-column' } key={index}>{date}</th>)}
            </tr>
            </thead>

            {
              jobList.map((jobDetail, index) => {
                return (
                    <tr key={index}>
                      <td headers="th1" className="table-data">{get(jobDetail, 'category.name')}</td>
                      <td headers="th2" className="table-data">{get(jobDetail, 'job_name')}</td>
                      {get(jobDetail, 'history') && jobDetail.history.map((history, index) => (
                        <td className={this.getTDColor(history.status) + ' table-data'} key={index}>
                          {history.status} &nbsp;{' '}
                          {history.status === 'OK' && history.metadata && (
                            <InfoButton position={'top'} content={this.getDisplayableMetadata(history.metadata)} />
                          )}
                        </td>
                      ))}
                    </tr>
                );
              })
            }
          </table>
        </div>
      </Box>
    );
  }
}

import TravellerInformationModal
  from "../../../../TravellerInformationModal/TravellerInformationModal";
import React from "react";
import { getDisplayName, getTravellerNumbers } from "../helpers";
import { SidebarSection } from "./SidebarSection";
import { formatDateString } from "../../../../../../../helpers/formatDate";

export const TravellerName = ({ traveller }) => {
  return (
    <div>
      {`${traveller.title ?? ''} ${traveller.first_name ?? ''} ${traveller.last_name ?? ''} (${formatDateString(traveller.dob)}`})
    </div>
  );
}

export const TravellerInfo = ({ travellerGroup, adults, children, selectOptions }) => {

  const travellerGroupName = getDisplayName(selectOptions, travellerGroup);
  const adultNum = getTravellerNumbers(adults?.length ?? 0, 'adult');
  const childrenNum = getTravellerNumbers(children?.length ?? 0, 'child');

  if (!travellerGroup) {
    return null;
  }
  return (
    <>

      <SidebarSection
        label={'Travellers:'}
        value={<>
          {travellerGroupName}
          <TravellerInformationModal
            adults={adults}
            children={children}
          />
          <br/>
          <span>
                  {adultNum} {childrenNum ? `, ${childrenNum}` : ''}
          </span>

          {adults?.map((adult) => (
            <TravellerName traveller={adult}/>
          ))}
          {children?.map((adult) => (
            <TravellerName traveller={adult}/>
          ))}

        </>}
      />


    </>
  )
}

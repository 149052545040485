import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import Slider from 'react-rangeslider';
import { get } from 'lodash';
import './styles.scss';
import Button from '../../../../common/components/Button/Button';

class CommissionAdjust extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productId: null,
      rate: 0,
    };
    this.handleChange = this.handleChange.bind(this);
    this.reCalculate = this.reCalculate.bind(this);
  }

  componentWillUpdate(nextProps) {
    const rate = this.getCommissionRate(nextProps.currentProduct.data.id);
    if (rate !== this.state.rate) {
      if (!nextProps.input.value) {
        nextProps.input.onChange(rate);
      }

      this.setState({
        productId: nextProps.currentProduct.data.id,
        rate,
      });
    }
  }

  handleChange() {
    const { input } = this.props;
    input.onChange(input.value === this.state.rate ? null : input.value); // fix for synthetic react errors
    // const { input, calculate } = this.props
    // calculate()
  }

  getCommissionRate(productId) {
    const { user } = this.props;
    const broker = getIncludedResource(user.data, user.included, 'current_broker');
    const commissions = getIncludedResource(
      broker.data,
      user.included,
      'active_commissions',
    );
    let rate = 0;

    if (Array.isArray(commissions)) {
      commissions.map((commission) => {
        if (get(commission, 'relationships.product.data.id') === productId) {
          rate = commission.attributes.rate;
        }
      });
    }

    return rate;
  }

  reCalculate() {
    this.props.reCalculate();
  }

  render() {
    const { input, product, user } = this.props;
    const broker = getIncludedResource(user.data, user.included, 'current_broker');

    // Return empty if no current broker is set
    if (
      !broker.data ||
      (Array.isArray(broker.data) && !broker.data.length) ||
      this.state.rate === 0
    ) {
      return <div />;
    }

    const format = (value) => {
      return value + '%';
    };

    return (
      <div className="commission-adjust">
        <h4>Adjust your commission!</h4>
        <div className="row">
          <div className="col-xs-4 col-md-12 col-lg-3 text-center">
            <span className="icon-wrapper">{format(input.value)}</span>
          </div>
          <div className="col-xs-8 col-md-12 col-lg-7">
            <span className="information">
              Did you know you can adjust your commission to offer a cheaper price?
            </span>
          </div>
        </div>

        <div className="slider">
          <Slider
            {...input}
            value={parseInt(input.value)}
            disabled={product.isCalculating}
            max={this.state.rate}
            format={format}
            onChangeComplete={this.handleChange}
          />
          <div className="center-block">
            <Button
              label="Calculate Premium"
              handleClick={this.reCalculate}
              bsStyle="primary"
              size="small"
              className="btn-calculate-premiums"
              block
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

CommissionAdjust.propTypes = {
  input: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  currentProduct: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  reCalculate: PropTypes.func.isRequired,
  meta: PropTypes.object,
};

export default connect(mapStateToProps)(CommissionAdjust);

import React, { Component } from 'react';
import ReactSelectComponent from 'react-select';

export default class ReactSelect extends Component {
  render() {
    const { value, onBlur, clearable = true, async, ...props } = this.props;
    const Select = async ? ReactSelectComponent.Async : ReactSelectComponent;

    return (
      <Select
        value={value || ''} // because react-select doesn't like the initial value of undefined
        clearable={clearable}
        // just pass the current value (updated on change) on blur
        // onChange={(val, items) => onChange(items)}
        onBlur={() => (onBlur ? onBlur(value) : null)}
        autoBlur // Auto close the dropdown after selection
        {...props}
      />
    );
  }
}

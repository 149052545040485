import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, InfoLabel } from '../../../../common/components';
import { respondToEnquiry } from '../../redux/enquiryActions';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import { get } from 'lodash';
import './styles.scss';
import { Col, Row } from 'react-bootstrap';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';

export default class EnquiryDetails extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    enquiry: PropTypes.object.isRequired,
    resource: PropTypes.object.isRequired,
  };

  markAsResponded() {
    this.props.dispatch(respondToEnquiry(this.props.resource));
  }

  render() {
    const resource = this.props.resource.data.attributes;
    const user = get(
      getIncludedResource(
        this.props.resource.data,
        this.props.resource.included,
        'responded_by',
      ),
      'data.attributes',
    );

    return (
      <Box>
        <h2 className="resource-name">Enquiry Details</h2>

        <div className="row">
          <div className="col-sm-6">
            <div className="form-horizontal">
              <InfoLabel label="Name" value={resource.name} labelSize={4} />
              <InfoLabel label="Email" value={resource.email} labelSize={4} />
              <InfoLabel
                label="Responded To By"
                value={get(user, 'full_name', '')}
                labelSize={4}
              />
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-horizontal">
              <InfoLabel
                label="Created At"
                dateTime
                value={resource.created_at}
                labelSize={4}
              />
              <InfoLabel
                label="Status"
                value={get(resource, 'status', '')}
                labelSize={4}
              />
              <InfoLabel
                label="Responded At"
                dateTime
                value={get(resource, 'responded_at', '')}
                labelSize={4}
              />
            </div>
          </div>
        </div>

        <div className="hr" />

        <div className="row">
          <div className="col-xs-12">
            <div className="form-horizontal complaint-display">
              <InfoLabel label="Message" value={resource.message} />
            </div>
          </div>
        </div>

        <Row>
          <Col sm={12}>
            <InternalButtonBar
              buttons={[
                {
                  button: (
                    <Button
                      className="pull-right"
                      bsStyle="primary"
                      rightIcon="check"
                      isLoading={this.props.enquiry.isSubmitting}
                      handleClick={this.markAsResponded.bind(this)}
                      label="Mark as responded"
                    />
                  ),
                },
              ]}
            />
          </Col>
        </Row>
      </Box>
    );
  }
}

import React from 'react';
import Markdown from 'react-remarkable';
import { Col, Row } from 'react-bootstrap';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import TextArea from '../../../content/components/TextArea';
import Button from '../../../../common/components/Button/Button';
import { compose } from 'redux';
import { connect } from 'react-redux';

const BrochureContentForm = ({
  resource,
  isLoading,
  handleSubmit,
  showForm,
  onSave,
  onToggleForm
}) => {
  const brochureContent = resource.data.attributes.metadata.details;
  const defaultMessage = 'No content found. Click "Edit" to add content.';
  return (
    <Form onSubmit={handleSubmit(onSave)}>
      {showForm ? (
        <Field
          component={TextArea}
          name={'data.attributes.metadata.details'}
        />
      ) : (
        <Markdown source={brochureContent ? brochureContent : defaultMessage}/>
      )}
      <Row>
        <Col sm={12}>
          {showForm && (
            <>
              <Button type="button" bsStyle="default" handleClick={onToggleForm}>
                Cancel
              </Button>
              <Button
                type="submit"
                bsStyle="primary"
                className="pull-right"
                isLoading={isLoading}
              >
                Save
              </Button>
            </>
          )}
          {!showForm && (
            <Button
              type="button"
              bsStyle="primary"
              className="pull-right"
              handleClick={onToggleForm}
            >
              Edit
            </Button>
          )}
        </Col>
      </Row>
    </Form>
  );
};


const FORM_NAME = 'BrochureContentForm';
export default compose(
  connect(
    (state, ownProps) => ({
      formValues: getFormValues(FORM_NAME)(state),
      initialValues: {
        data: {
          attributes: {
            metadata: {
              details: ownProps?.resource?.data?.attributes?.metadata?.details ?? ''
            }
          }
        }
      }

    }),
  ),
  reduxForm({ form: FORM_NAME })
)(BrochureContentForm);


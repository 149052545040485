import * as actions from './brokerApplicationReducer';
import Axios from 'axios';
import { push } from 'react-router-redux';
import queryString from '../../../helpers/queryString';
import handleErrorResponse from '../../../helpers/handleErrorResponse';
import { login } from '../../auth/redux/authActions';
import { ADD_RESOURCE } from '../../layout/redux/breadcrumb';
import { set } from 'lodash';

export const defaultIncludes = [];
// export const defaultIncludes = ['parent', 'principal', 'organisation_type', 'legal_entity_type', 'users', 'primary_contacts', 'accounts_contacts', 'network.standard_commission', 'insurers.authorised_users']

// + '?include=brokers,channels'
export function getBrokerApplication(id, includes = defaultIncludes) {
  return (dispatch) => {
    let endpoint =
      '/brokers/applications/' +
      id +
      '?include=organisation,networks,broker_region,broker_category,legal_entity_type';
    endpoint += queryString(includes);

    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: actions.SET_BROKER_APPLICATION,
          data: response.data,
        });
        dispatch({
          type: ADD_RESOURCE,
          id: response.data.data.id,
          name: response.data.data.attributes.name,
        });
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function createBrokerApplication(resource) {
  return (dispatch) => {
    dispatch(isSubmitting(true));
    return Axios.post('brokers/applications', resource)
      .then(() => {
        dispatch(push('/admin/broker-applications/'));
        dispatch(isSubmitting(false));
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'There was an error creating the broker application');
      });
  };
}

export function createPublicBrokerApplication(resource) {
  return (dispatch) => {
    dispatch(isSubmitting(true));

    if (resource) {
      set(resource, 'meta.send_email', true);
    }

    return Axios.post('public/brokers/applications', resource)
      .then(() => {
        dispatch({ type: actions.PUBLIC_SUBMIT_SUCCESS });
        dispatch(isSubmitting(false));
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'There was an error creating the broker application');
      });
  };
}

export function updateBrokerApplication(resource, includes = defaultIncludes) {
  return (dispatch) => {
    dispatch(isSubmitting(true));
    const id = resource.data.id;
    let endpoint = '/brokers/applications/' + id;
    endpoint += queryString(includes);

    return Axios.patch(endpoint, resource)
      .then((response) => {
        dispatch({
          type: actions.SET_BROKER_APPLICATION,
          data: response.data,
        });
        dispatch(isSubmitting(false));
        dispatch(push('/admin/broker-applications'));
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'There was an error updating the broker application');
      });
  };
}

export function declineBrokerApplication(resource) {
  return (dispatch) => {
    dispatch(isSubmitting(true));
    const id = resource.data.id;
    let endpoint = '/brokers/applications/' + id + ':decline';

    return Axios.post(endpoint, resource)
      .then(() => {
        // dispatch({
        //   type: actions.SET_BROKER_APPLICATION,
        //   data: response.data
        // })
        dispatch(isSubmitting(false));
        dispatch(push('/admin/broker-applications/'));
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'There was an error updating the broker application');
      });
  };
}

export function approveBrokerApplication(resource) {
  return (dispatch) => {
    dispatch(isSubmitting(true));
    const id = resource.data.id;
    let endpoint = '/brokers/applications/' + id + ':approve';

    if (resource) {
      set(resource, 'meta.send_email', true);
    }

    return Axios.post(endpoint, resource)
      .then(() => {
        // dispatch({
        //   type: actions.SET_BROKER_APPLICATION,
        //   data: response.data
        // })
        dispatch(isSubmitting(false));
        dispatch(push('/admin/broker-applications/'));
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'There was an error updating the broker application');
      });
  };
}

export function resendBrokerApplication(resource) {
  return (dispatch) => {
    dispatch(isSubmitting(true));
    const id = resource.data.id;
    let endpoint = '/brokers/applications/' + id + ':send-approval-email';

    return Axios.post(endpoint, resource)
      .then(() => {
        // dispatch({
        //   type: actions.SET_BROKER_APPLICATION,
        //   data: response.data
        // })
        dispatch(isSubmitting(false));
        dispatch(push('/admin/broker-applications/'));
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'There was an error updating the broker application');
      });
  };
}

export function saveAndApproveBrokerApplication(resource) {
  return (dispatch) => {
    if (resource.data.id) {
      return dispatch(updateBrokerApplication(resource)).then(() => {
        return dispatch(approveBrokerApplication(resource));
      });
    } else {
      return dispatch(createBrokerApplication(resource)).then((response) => {
        return dispatch(approveBrokerApplication(response));
      });
    }
  };
}

export function createTrackingLinkBroker(resource) {
  return (dispatch) => {
    return Axios.post('brokers', resource)
      .then((response) => {
        dispatch(push('/brokers/' + response.data.data.id)); //redirect to broker page
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'There was an error creating the broker');
      });
  };
}

export function isSubmitting(status) {
  return {
    type: actions.IS_SUBMITTING,
    status,
  };
}

export function getProducts() {
  return (dispatch) => {
    return Axios.get('products?page[limit]=500')
      .then((response) => {
        dispatch({
          type: actions.SET_PRODUCTS,
          data: response.data,
        });
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function activateBroker(token, values) {
  return (dispatch) => {
    return Axios.post(`public/brokers/applications/${token}:activate`, values)
      .then(() => {
        dispatch(login(values.meta.email, values.meta.password));
        return true;
      })
      .catch((error) => {
        handleErrorResponse(
          error,
          'There was an error submitting your broker activation, please contact P J Hayman.',
        );
      });
  };
}

export const SET_COMPANY = 'socrates-online/company/SET_COMPANY';

const initialState = {
  company: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_COMPANY:
      return {
        ...state,
        company: {
          ...state.company,
          [action.id]: action.data,
        },
      };

    default:
      return state;
  }
}

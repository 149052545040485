import React from "react";
import './ProductInfoContainer.scss';

export const ProductInfoContainer = ({ title, children }) => {
  return (
    <div className={'product-info-container'}>
      <h5 className="section-title join-cover">{title}</h5>
      <div className="product-container-section join">
        <div className="scroll-section">
          {children}
        </div>
      </div>
    </div>
  );
}

import React, { Component } from 'react';
import { FormGroup, Col, FormControl, ControlLabel } from 'react-bootstrap';
import './styles.scss';
export default class AdjustmentFieldTitle extends Component {
  render() {
    const { input, type, placeholder, label, labelSize } = this.props;
    const size = labelSize ? labelSize : 2;
    return (
      <FormGroup controlId={input ? input.name : label}>
        <Col sm={size}>
          <ControlLabel className="adjustment-control-label">{label}</ControlLabel>
        </Col>
        <Col sm={12 - size} md={0}>
          <div className="input-container">
            <FormControl {...input} type={type} placeholder={placeholder} />
          </div>
        </Col>
      </FormGroup>
    );
  }
}

import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { HorizontalFormControl, InfoLabel } from '../../../../common/components';
import SchemeManagementSectionContainer
  from "../SchemeManagementSectionContainer/SchemeManagementSectionContainer";

const SchemeGroupExcessForm = ({ isViewOnly, handleSubmit, formValues, coverLevels }) => {
  if (!formValues) {
    return null;
  }


  return (
    <SchemeManagementSectionContainer
      title={'Excess'}
      subTitle={formValues.data.attributes.name}
      isViewOnly={isViewOnly}
    >
      <Form horizontal onSubmit={handleSubmit}>
        {coverLevels?.map((coverLevel, index) => {
          return (
            <>
              <Row>
                <Col key={index} sm={12}>
                  <Field
                    name={`meta.patch.standard_excess.${coverLevel}`}
                    labelSize={4}
                    mdFieldSize={4}
                    label={`${coverLevel} Excess:`}
                    component={isViewOnly ? InfoLabel : HorizontalFormControl}
                  />
                </Col>
              </Row>
            </>
          );
        })}
      </Form></SchemeManagementSectionContainer>
  );
};

export default compose(
  connect((state, props) => {
    return {
      formValues: getFormValues(props.formName)(state),
      destroyOnUnmount: false,
      enableReinitialize: true,

      form: props.formName,
    };
  }),
  reduxForm({}),
)(SchemeGroupExcessForm);

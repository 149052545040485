import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { saveUser, createUser } from '../redux/userActions';
import UserForm from '../components/UserForm/UserForm';

export default class UserFormContainer extends Component {
  static propTypes = {
    resource: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    isInOwnerOrganisation: PropTypes.bool,
    contact: PropTypes.bool,
  };

  handleSubmit(resource) {
    if (resource.data.id) {
      return this.props.dispatch(saveUser(resource));
    }

    return this.props.dispatch(createUser(resource));
  }

  render() {
    return (
      <UserForm
        {...this.props}
        onSubmit={::this.handleSubmit}
        contact={this.props.contact}
      />
    );
  }
}

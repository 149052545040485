import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { grantPermission } from '../../../auth/redux/authActions';
import { openModal } from '../../../../common/components/Modal/ModalRedux';
import Icon from '../../../../common/components/Icon/Icon';
import { browserHistory } from 'react-router';
import { push } from 'react-router-redux';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import { connect } from 'react-redux';
import { sortBy } from 'lodash';
import './styles.scss';

class InternalNavigation extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    layout: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      dropDown: {
        accounts: false,
        admin: false,
        organisations: false,
        policies: false,
        settings: false,
        tools: false,
      },
    };
  }

  componentDidMount() {
    const { auth, dispatch } = this.props;
    if (auth.user.data.relationships.current_organisation.data === null) {
      dispatch(openModal('organisation-settings'));
    }
  }

  menu() {
    return [
      {
        key: 'home',
        label: 'Home',
        route: 'dashboard',
        icon: 'home',
      },
      {
        key: 'settings',
        label: 'Settings',
        icon: 'cog',
        borderBottom: true,
        subMenu: [
          {
            key: 'personal-settings',
            label: 'Personal Settings',
            modal: 'settings',
          },
          {
            key: 'organisation-settings',
            label: 'Organisation Settings',
            modal: 'organisation-settings',
          },
        ],
      },
      {
        key: 'quotes',
        label: 'Get a Quote',
        icon: 'plane',
        route: 'products',
        permissions: ['policy.quote'],
        emphasis: true,
        borderBottom: true,
      },
      {
        key: 'policies',
        label: 'Policy Management',
        icon: 'tasks',
        permissions: ['policy.view'],
        subMenu: [
          {
            key: 'customers',
            label: 'Customer Management',
            route: 'customers',
            orgTypes: ['owner', 'broker'],
            permissions: ['customer.view'],
            borderTop: true,
          },
          {
            key: 'issued',
            label: 'Policies',
            route: 'policies/issued',
            permissions: ['policy.view'],
          },
          {
            key: 'quote',
            label: 'Quotes',
            route: 'policies/applications',
            permissions: ['policy.quote'],
            borderBottom: true,
          },
          {
            key: 'mta',
            label: 'Mid Term Adjustments',
            route: 'policies/mid-term-adjustments',
            permissions: ['policy.quote'],
          },
          {
            key: 'renewal',
            label: 'Renewals',
            route: 'policies/renewals',
            permissions: ['policy.quote'],
            borderTop: true,
          },
          // {
          //   key: 'referred-renewal',
          //   label: 'Referred Renewals',
          //   route: 'policies/referred-renewals',
          //   permissions: ['policy.quote'],
          // },
          // {
          //   key: 'renewals-scrutiny',
          //   label: 'Renewals Scrutiny',
          //   route: 'policies/renewals-scrutiny',
          //   permissions: ['policy.quote'],
          // },
          // {
          //   key: 'non-renewals',
          //   label: 'Non Renewals',
          //   route: 'policies/non-renewals',
          //   permissions: ['policy.quote'],
          // },
          {
            key: 'referral',
            label: 'Referrals',
            route: 'policies/referrals',
            permissions: ['policy.quote'],
            borderTop: true,
          },
          {
            key: 'print-job',
            label: 'Print Jobs',
            orgTypes: ['owner'],
            route: 'policies/print-jobs',
            permissions: ['print_job.access'],
            borderTop: true,
          },
        ],
      },
      {
        key: 'organisations',
        label: 'Organisations',
        icon: 'sitemap',
        orgTypes: ['owner'],
        permissions: ['organisation.view'],
        subMenu: [
          {
            key: 'broker-branches',
            label: 'Brokers/Branches',
            route: 'accounts/brokers',
            borderTop: true,
          },
          {
            key: 'brokers',
            label: 'Brokers',
            route: this.organisationRoute('Broker'),
          },
          {
            key: 'broker-applications',
            label: 'Broker Applications',
            route: 'admin/broker-applications',
            borderBottom: true,
          },
          {
            key: 'assistance-handlers',
            label: 'Assistance Companies',
            route: this.organisationRoute('Assistance Handler'),
          },
          {
            key: 'claims-handlers',
            label: 'Claims Handlers',
            route: this.organisationRoute('Claims Handler'),
          },
          {
            key: 'insurers',
            label: 'Insurers',
            route: this.organisationRoute('Insurer'),
          },
          {
            key: 'networks',
            label: 'Networks',
            route: this.organisationRoute('Network'),
          },
          {
            key: 'payment-gateway-providers',
            label: 'Payment Gateway Providers',
            route: this.organisationRoute('Payment Gateway Provider'),
          },
          {
            key: 'screening-handlers',
            label: 'Screening Handlers',
            route: this.organisationRoute('Screening Handler'),
          },
        ],
      },
      {
        key: 'admin',
        label: 'Admin',
        icon: 'cogs',
        permissions: [
          'content_management.view',
          'scheme.view',
          'organisation.view',
          'user.view',
          'role.view',
        ],
        subMenu: [
          {
            key: 'announcements',
            label: 'Announcements',
            route: 'admin/announcements',
            orgTypes: ['owner'],
            permissions: ['content_management.view'],
          },
          {
            key: 'enquiries',
            label: 'Enquiries',
            route: 'admin/enquiries',
            permissions: ['content_management.view'],
            orgTypes: ['owner'],
            borderTop: true,
          },
          {
            key: 'content',
            label: 'Content',
            route: 'admin/content',
            permissions: ['content_management.view'],
            orgTypes: ['owner'],
            borderBottom: true,
          },
          {
            key: 'campaigns',
            label: 'Campaigns',
            route: 'admin/campaigns',
            permissions: ['content_management.view'],
            orgTypes: ['owner'],
          },
          // {
          //   key: 'sources',
          //   label: 'Source',
          //   route: 'admin/sources',
          //   permissions: ['marketing.source_view'],
          //   orgTypes: ['owner'],
          // },
          {
            key: 'tracking-links',
            label: 'Tracking Links',
            route: 'admin/tracking-links',
            permissions: ['broker.view'],
            orgTypes: ['owner'],
          },
          {
            key: 'product-management',
            label: 'Product Management',
            route: 'admin/products',
            permissions: ['scheme.view'],
            orgTypes: ['owner'],
            borderTop: true,
          },
          {
            key: 'system-health',
            label: 'System Health',
            route: 'admin/system-health',
            permissions: ['scheme.view'],
            orgTypes: ['owner'],
            borderTop: true,
          },
          // {
          //   key: 'document-management',
          //   label: 'Document Management',
          //   route: 'admin/document-management',
          //   permissions: ['scheme.view'],
          //   orgTypes: ['owner'],
          //   borderBottom: true,
          // },
          // {
          //   key: 'notifications',
          //   label: 'Notifications',
          //   route: 'admin/notifications',
          //   permissions: ['content_management.view'],
          //   orgTypes: ['owner'],
          // },
          // {
          //   key: 'stats',
          //   label: 'Stats',
          //   route: 'admin/stats',
          //   permissions: ['report.view'],
          //   orgTypes: ['owner'],
          // },
          {
            key: 'roles',
            label: 'Roles',
            route: 'admin/roles',
            permissions: ['role.view'],
            orgTypes: ['owner'],
            borderTop: true,
          },
          {
            key: 'users',
            label: 'Users',
            route: 'admin/users',
            permissions: ['user.view'],
            borderBottom: true,
          },
          // {
          //   key: 'white-labels',
          //   label: 'White Labels',
          //   route: 'admin/whitelabels',
          //   permissions: ['marketing.source_view'],
          //   orgTypes: ['owner'],
          // // },
          // {
          //   key: 'channels',
          //   label: 'Channels',
          //   route: 'admin/channels',
          //   permissions: ['organisation.view'],
          //   orgTypes: ['owner'],
          // },
        ],
      },
      {
        key: 'accounts',
        label: 'Accounts',
        icon: 'bank',
        orgTypes: ['owner'],
        permissions: ['account.view'],
        subMenu: [
          {
            key: 'accounts-summary',
            label: 'Summary',
            route: 'accounts/summary',
            borderTop: true,
            borderBottom: true,
          },
          {
            key: 'ledger',
            label: 'Ledger',
            route: 'accounts/ledger',
          },
          {
            key: 'bookkeeping',
            label: 'Bookkeeping',
            route: 'accounts/bookkeeping',
          },
          {
            key: 'accounts-matching',
            label: 'Matching',
            route: 'accounts/matching',
          },
        ],
      },
      {
        key: 'tools',
        label: 'Socrates Tools',
        icon: 'cogs',
        orgTypes: ['owner'],
        permissions: ['account.view'],
        subMenu: [
          {
            key: 'reindex',
            label: 'Reindex Data',
            route: 'socrates-tools/reindex-data',
          },
        ],
      },
      {
        key: 'reports',
        label: 'Reports',
        icon: 'bar-chart',
        route: 'reports',
        permissions: ['report.bdx', 'report.predefined'],
      },
    ];
  }

  renderMenu() {
    const { dropDown } = this.state;
    return this.menu().map((item) => {
      return (
        <li key={item.key}>
          {this.menuItem(item)}
          {item.subMenu && dropDown[item.key] && (
            <ul className="dropdown-box">
              {item.subMenu.map((subItem) => {
                return this.subMenuItem(subItem);
              })}
            </ul>
          )}
        </li>
      );
    });
  }

  menuItem(item) {
    const { dropDown } = this.state;
    if (!grantPermission(item)) {
      return;
    }

    let onItemClick = (e) => {
      e.preventDefault();
    };
    if (item.route) {
      onItemClick = () => {
        this.route(item.route);
      };
    }

    return (
      <div
        className="menu-item-wrapper"
        onClick={() => this.toggleDropDowns(item.key)}
        key={item.key}
      >
        <div className={this.menuClassName(item)} onClick={onItemClick}>
          <div className="menu-icon">
            <Icon name={item.icon} />
          </div>
          <div className={item.emphasis ? 'menu-text-red' : 'menu-text'}>
            {item.label}
            {item.subMenu && (
              <div
                className={dropDown[item.key] ? 'chevron-icon rotate' : 'chevron-icon'}
              >
                <Icon name="chevron-right" />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  subMenuItem(item) {
    if (!grantPermission(item)) {
      return;
    }

    let onItemClick = () => {
      this.route('home');
    };
    if (item.route) {
      onItemClick = () => {
        this.route(item.route);
      };
    } else if (item.modal) {
      onItemClick = (e) => {
        e.preventDefault();
        this.openModal(item.modal);
      };
    }

    return (
      <li className={this.menuClassName(item, true)} onClick={onItemClick} key={item.key}>
        <div className="dropdown-menu-text"> {item.label} </div>
      </li>
    );
  }

  toggleDropDowns(dropDownMenu) {
    const menus = ['accounts', 'admin', 'organisations', 'policies', 'settings', 'tools'];

    menus.forEach((menu) => {
      if (menu === dropDownMenu) {
        this.setState((prevState) => ({
          dropDown: {
            ...prevState.dropDown,
            [menu]: !prevState.dropDown[menu],
          },
        }));
      } else {
        this.setState((prevState) => ({
          dropDown: {
            ...prevState.dropDown,
            [menu]: false,
          },
        }));
      }
    });
  }

  openModal(modal) {
    this.props.dispatch(openModal(modal));
  }

  route(id) {
    this.props.dispatch(push('/' + id));
  }

  getProductIdByName(productName) {
    const products = sortBy(
      getIncludedResource(
        this.props.auth.user.data,
        this.props.auth.user.included,
        'available_products',
      ),
      'attributes.name',
    );
    let productId = 'Unknown';

    products.map((product) => {
      if (product.attributes.name === productName) {
        productId = product.id;
      }
    });

    return productId;
  }

  menuClassName(item, dropDownMenuItem = false) {
    const currentUrl = browserHistory ? browserHistory.getCurrentLocation() : '';
    let className = dropDownMenuItem ? 'dropdown-menu-item' : 'menu-item';
    if (
      item.route &&
      currentUrl.pathname + currentUrl.search === encodeURI('/' + item.route)
    ) {
      className += ' selected';
    }

    if (item.borderTop) {
      className += ' border-top';
    }

    if (item.borderBottom) {
      className += ' border-bottom';
    }

    return className;
  }

  organisationRoute(orgType) {
    const { organisationTypes } = this.props.layout;
    let orgTypeRoute = 'admin/organisations';

    organisationTypes.forEach((type) => {
      if (type.attributes.name === orgType) {
        orgTypeRoute += '?filter=' + type.id + '&display=' + type.attributes.name;
      }
    });

    return orgTypeRoute;
  }

  ownerRoute() {
    const { auth } = this.props;
    return (
      'admin/organisations/' + auth.user.data.relationships.current_organisation.data.id
    );
  }

  render() {
    return <ul className="sidebar-nav">{this.renderMenu()}</ul>;
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    layout: state.layout,
  };
}

export default connect(mapStateToProps)(InternalNavigation);

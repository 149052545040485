import React, { Component } from 'react';

import { Popover, OverlayTrigger } from 'react-bootstrap';
import cx from 'classnames';
import Markdown from 'react-markdown';
import { Icon } from '../';
import './styles.scss';
import {get} from 'lodash';


export default class InfoButton extends Component {


  render() {
    const { content, large, className } = this.props;

    const title =  get(content, 'title', undefined) ;
    const infoContent =  get(content, 'content') ? get(content, 'content', '') : content;

    if (!infoContent || (infoContent && !infoContent.replace(/[^a-zA-Z0-9_ ]/g, ''))) {
      return <span />;
    }

    const popoverContent = (
      <Popover
        className={cx('info-popover-content', {
          'info-popover-content--large': !!large,
          [`info-popover-content--${className}`]: !!className
        })}
        id={Math.random()
          .toString(36)
          .replace(/[^a-z]+/g, '')
          .substr(0, 15)}
        title={title}
      >

        <Markdown
          source={infoContent}
          renderers={{
            Link: (props) => (
              <a href={props.href} target="_blank">
                {props.children}
              </a>
            ),
          }}
        />
      </Popover>
    );

    return (
      <OverlayTrigger trigger="click" overlay={popoverContent} rootClose>
        <div className="info-button-icon">
          <Icon name="info-circle" />
        </div>
      </OverlayTrigger>
    );
  }
}

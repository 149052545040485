import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, map, cloneDeep, size } from 'lodash';
import { connect } from 'react-redux';
import { fetchAnnouncements } from '../../../dashboard/redux/dashboardActions';
import { openModal } from '../../../../common/components/Modal/ModalRedux';
import moment from 'moment';
import './Marquee.scss';
import Modal from '../../../../common/components/Modal/Modal';
import Markdown from 'react-commonmark';
import getIncludedResponse from '../../../../helpers/getIncludedResource';

class Marquee extends Component {
  constructor(props) {
    super(props);
    this.openAnnouncementModal = this.openAnnouncementModal.bind(this);
    this.renderModal = this.renderModal.bind(this);
    this.state = {
      announcement: {},
    };
  }

  componentWillMount() {
    this.props.dispatch(fetchAnnouncements());
  }

  renderModal() {
    const { dashboard } = this.props;
    let attachments =
      getIncludedResponse(
        this.state.announcement,
        dashboard.announcements.included,
        'files',
      ) || [];
    return (
      <Modal
        name={this.state.announcement.id}
        title={get(this.state, 'announcement.attributes.name')}
        close
      >
        <div>
          <Markdown
            source={get(this.state, 'announcement.attributes.sections.content')}
          />
        </div>
        {attachments.length > 0 && (
          <p className="announcement-attachments">
            <strong>Attachments: </strong>
            {attachments.map((data, key) => (
              <span className="attachment-link" key={'announcement-attachment-' + key}>
                <a href={data.attributes.url} target="_blank">
                  {data.attributes.name}
                </a>
                {attachments.length - 1 !== key && <span>, &nbsp;</span>}
              </span>
            ))}
          </p>
        )}
      </Modal>
    );
  }
  openAnnouncementModal(announcement) {
    this.setState({
      announcement,
    });

    this.props.dispatch(openModal(announcement.id));
  }

  render() {
    const { dashboard } = this.props;
    const getAnnounData = cloneDeep(dashboard.announcements.data);
    const currentTime = moment().format();
    const announActive = [];
    map(getAnnounData, (reminder) => {
      let to_date = reminder.attributes.to_date;
      let from_date = reminder.attributes.from_date;
      let priority = get(reminder, 'attributes.metadata.priority', false);
      if (
        moment(currentTime).isSameOrBefore(to_date) &&
        moment(currentTime).isSameOrAfter(from_date) &&
        priority
      ) {
        announActive.push(reminder);
      }
    });

    return (
      <div>
        {size(announActive) > 0 && (
          <div className="marquee-box">
            <marquee className="marquee--upper">
              <ul className="marquee-ul">
                {announActive.map((announcement) => {
                  return (
                    <li
                      className="marquee-li"
                      key={announcement.id}
                      onClick={() => this.openAnnouncementModal(announcement)}
                    >
                      <span>HIGH ALERT ANNOUNCEMENT: </span>
                      {announcement.attributes.name} >{' '}
                      <span className="marquee--link">
                        {announcement.attributes.sections.preview}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </marquee>

            {this.renderModal()}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dashboard: state.dashboard,
  };
}

Marquee.propTypes = {
  dispatch: PropTypes.func.isRequired,
  dashboard: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(Marquee);

export const policyStatuses = {
  pending: 'pending',
  active: 'active',
  issued: 'issued',
  cancelled: 'cancelled',
  renewed: 'renewed',
  onCover: 'on cover',
  expired: 'expired',
  applied: 'applied',
  quote: 'quote',
  renewal: 'renewal',
  lapsed: 'lapsed',
  'on cover': 'On Cover',
  declined: 'declined',
  referred: 'referred',
  'referral approved': 'referral approved',
  single_parent_family: 'Single Parent Family',
  individual: 'Individual',
  couple: 'Couple',
  family: 'Family',
};

export default function getStatus(status) {
  return policyStatuses[status];
}

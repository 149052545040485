import React, { useState } from 'react';

import { Form, reduxForm, getFormValues } from 'redux-form';
import { createWhiteLabel } from '../../redux/brokerActions';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import { Col, Row, ControlLabel } from 'react-bootstrap';

import UploadCropComponent from '../../../../common/components/Upload/UploadCrop';
import { connect } from 'react-redux';
import { triggerFileUpload } from '../../../../common/components/Upload/UploadRedux';
import ReactModal from 'react-modal';
import './BrokerTheme.scss';
import { compose } from 'redux';
import Warning from './Warning';
import { InfoLabel } from '../../../../common/components';
import WhiteLabelSummary
  from '../../../whitelabel/components/WhiteLabelSummary/WhiteLabelSummary';
import { Button } from '../../../../common/components/Button/Button';
import {
  InternalButtonBar
} from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import { Link } from 'react-router';
import WhiteLabelEditor from './WhiteLabelEditor/WhiteLabelEditor';
import WebsiteThumbnail
  from '../../../whitelabel/components/WebsiteThumbnail/WebsiteThumbnail';
import EmailThumbnail from '../../../whitelabel/components/EmailThumbnail/EmailThumbnail';
import DocumentThumbnail
  from '../../../whitelabel/components/DocumentThumbnail/DocumentThumbnail';
import WhiteLabelContent
  from '../../../whitelabel/components/WhitelabelContent/WhiteLabelContent';
import SenderConfig from '../../../whitelabel/components/SenderConfig/SenderConfig';
import Faqs from '../../../whitelabel/components/Faqs/Faqs';
import PrivacyPage from '../../../whitelabel/components/PrivacyPage/PrivacyPage';
import EligibilityQuestions
  from "../../../whitelabel/components/EligibilityQuestions/EligibilityQuestions";
import InformationProcessingConsent
  from "../../../whitelabel/components/InformationProcessingConsent/InformationProcessingConsent";

const FORM_NAME = 'ThemeForm';
ReactModal.setAppElement('#app');

const getButtons = (isSubmitting, resource, onUpdateTheme, logo, onUpload) => {

  const handleLogoUpload = () => {
    onUpload('logo');
  };

  const channelData = getIncludedResource(
    resource.data,
    resource.included,
    'channel'
  );

  const channel = channelData.data.attributes !== undefined;

  const themeButtons = [
    {
      render: true,
      permissions: ['broker.edit'],
      filter: [{ filterValues: [false], value: !channel && !logo }],
      button: (
        <Button
          key={'wl'}
          className="pull-right"
          type="submit"
          bsStyle="primary"
          disabled={isSubmitting}
          isLoading={isSubmitting}
          label={(channel ? 'Save' : 'Create') + ' White Label'}
        />
      ),
    },
    {
      render: channel,
      permissions: ['broker.edit'],
      button: (
        <Button
          key={'theme'}
          className="pull-right"
          bsStyle="primary"
          handleClick={onUpdateTheme}
          disabled={isSubmitting}
          label="Update Theme"
        />
      ),
    },
    {
      render: true,
      permissions: ['broker.edit'],
      button: (
        <Button
          key={'logo'}
          className="pull-right"
          bsStyle="primary"
          handleClick={handleLogoUpload}
          label={(logo ? 'Replace' : 'Upload') + ' Logo'}
          disabled={isSubmitting}
          rightIcon="image"
        />
      ),
    },
    // {
    //   render: true,
    //   permissions: ['marketing.source_view'],
    //   button: (
    //     <Button
    //       key={'channel'}
    //       label="View Channel"
    //       className="pull-right"
    //       bsStyle="primary"
    //       disabled={isSubmitting}
    //       link
    //       to={'/admin/channels/' + channelData.data.id}
    //     />
    //   ),
    // },
  ];

  return themeButtons.filter((button) => {
    return button.render;
  });
};

const getFaqTemplate = (resource) => {
  return resource?.included?.find((inc) => {
    return inc.type === 'templates' && inc.attributes.key === 'wl-faqs';
  });

};

const getPrivacyTemplate = (resource) => {
  return resource?.included?.find((inc) => {
    return inc.type === 'templates' && inc.attributes.key === 'wl-privacy';
  });
};

const getMedicalDeclarationTemplate = (resource) => {
  return resource?.included?.find((inc) => {
    return inc.type === 'templates' && inc.attributes.key === 'travel-medical-declaration-questions';
  });
};

const BrokerTheme = ({
  resource,
  formValues,
  handleSubmit,
  createWhiteLabel,
  onRefreshBroker,
  submitting,
  triggerFileUpload,
  dispatch,
  change
}) => {
  const brokerId = resource?.data?.id;
  const [logo, setLogo] = useState(resource?.data?.attributes?.logo ?? '');
  const [editingTheme, setEditingTheme] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [url, setUrl] = useState('');

  const [uploadType, setUploadType] = useState(null);

  const themeConfig = formValues?.meta?.theme?.config;

  const channel = getIncludedResource(
    resource.data,
    resource.included,
    'channel',
  );

  const isWhitelabel = !!channel?.data?.attributes;
  const faqTemplate = getFaqTemplate(resource);
  const privacyTemplate = getPrivacyTemplate(resource);
  const medicalDeclarationsTemplate = getMedicalDeclarationTemplate(resource);

  const handleUpdateTheme = () => {
    if (!channel) {
      return;
    }
    setEditingTheme(true);
    setUrl(`${channel.data.attributes.url}/?embeddable=true&channel=${channel.data.attributes.key}&skipcache=true`);
  };

  const handleUploadComplete = (data) => {
    if (uploadType === 'logo') {
      dispatch(change('data.relationships.logo_file.data', data.data));
      setLogo(data.data.attributes.url);
    }
  };

  const handleUpload = (uploadType) => {
    setUploadType(uploadType);
    triggerFileUpload();
  };

  const closeEditor = () => {
    setEditingTheme(false);
    setUrl('');
  };

  const saveTheme = (theme, metadata) => {
    dispatch(change('meta.theme.config', theme));
    dispatch(change('meta.theme.metadata', metadata));
    setIsDirty(true);
  };

  const handleFormSubmit = (values) => {
    return Promise.resolve(createWhiteLabel(brokerId, values))
      .then(() => {
        setIsDirty(false);
        return onRefreshBroker();
      });
  };

  //
  // const customDomain = () => {
  //   const { dispatch, change } = this.props;
  //   dispatch(change('meta.url', ''));
  // };

  // const resourceAttributes = resource.data.attributes;

  const hasWhiteLabel = channel.data.attributes !== undefined;
  // const resourceName = hasWhiteLabel
  //   ? channel.data.attributes.name
  //   : resource.name + ' (No Whitelabel)';

  const buttons = getButtons(
    submitting, resource, handleUpdateTheme, logo, handleUpload
  );

  // if (!formValues) {
  //   return <Loader/>;
  // }

  return (
    <>
      <Warning isDirty={isDirty}/>
      <WhiteLabelEditor
        isEditing={editingTheme}
        onCloseEditor={closeEditor}
        url={url}
        onUpdateTheme={saveTheme}
      />
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <Row>
          <Col sm={7}>
            <Col sm={3}>
              <ControlLabel>Logo</ControlLabel>
            </Col>
            <Col sm={8}>
              <div className="broker-logo-container">
                {logo ? (
                  <img alt="logo" src={logo}/>
                ) : (
                  <h3 className="text-center">No Logo</h3>
                )}
              </div>
            </Col>
          </Col>
          <Col sm={5}>
            {hasWhiteLabel && (
              <>
                <InfoLabel
                  label="Status"
                  labelSize={3}
                  value={channel.data.attributes.status}
                />
                <InfoLabel
                  label="URL"
                  labelSize={3}
                  value={
                    <Link
                      to={channel.data.attributes.url + '?skipcache=true'}
                      target="_blank"
                      onlyActiveOnIndex
                    >
                      {channel.data.attributes.url}
                    </Link>
                  }
                />
                <InfoLabel
                  label="Created"
                  labelSize={3}
                  dateTime
                  value={channel.data.attributes.created_at}
                />
                <InfoLabel
                  label="Updated"
                  labelSize={3}
                  dateTime
                  value={channel.data.attributes.updated_at}
                />
              </>
            )}
            <WhiteLabelSummary
              hasWhiteLabel={hasWhiteLabel}
              customDomain={formValues?.meta?.custom_domain}
              brokerId={brokerId}
            />
          </Col>
        </Row>
        {themeConfig?.colors && (
          <div>
            <div className="hr"/>
            <Row>
              <Col lg={4}>
                <WebsiteThumbnail
                  theme={themeConfig}
                  phone={formValues?.meta?.whitelabel?.content?.telephone_number ?? ''}
                  openingHours={formValues?.meta?.whitelabel?.content?.opening_hours ?? ''}
                  footer={formValues?.meta?.whitelabel?.content?.footer ?? ''}
                />
              </Col>
              <Col lg={4}>
                <EmailThumbnail
                  theme={themeConfig}
                  phone={formValues?.meta?.whitelabel?.content?.telephone_number ?? ''}
                  footer={formValues?.meta?.whitelabel?.content?.footer ?? ''}
                  openingHours={formValues?.meta?.whitelabel?.content?.opening_hours ?? ''}
                  displayName={formValues?.meta?.whitelabel?.content?.display_name ?? ''}
                />
              </Col>
              <Col lg={4}>
                <DocumentThumbnail
                  theme={themeConfig}
                  footer={formValues?.meta?.whitelabel?.content?.footer ?? ''}
                />
              </Col>
            </Row>
          </div>
        )}
        <div className="hr"/>
        {isWhitelabel && (
          <>
            <WhiteLabelContent/>
            <SenderConfig/>
            <Faqs template={faqTemplate}/>
            <PrivacyPage template={privacyTemplate}/>
            <EligibilityQuestions/>
            <InformationProcessingConsent/>
            {/*<MedicalDeclaration template={medicalDeclarationsTemplate}/>*/}
          </>
        )}
        <Warning isDirty={isDirty}/>
        <UploadCropComponent
          onComplete={handleUploadComplete}
          width={uploadType === 'banner' ? 655 : 355}
          height={238}
          bsSize="lg"
          circle={false}
          minScale={30}
        />
        <Row>
          <Col sm={12}>
            <InternalButtonBar buttons={buttons}/>
          </Col>
        </Row>
      </Form>
    </>

  );
};


const getInitialValues = (resource) => () => {
  const b2cChannel = getIncludedResource(resource.data, resource.included, 'channel');
  const products = getIncludedResource(b2cChannel.data, resource.included, 'products');
  const channelTheme = getIncludedResource(b2cChannel?.data, resource.included, 'theme');
  const channelContents = getIncludedResource(b2cChannel?.data, resource.included, 'contents');
  const channelContentsList = Array.isArray(channelContents) ? channelContents : [];
  const faq = channelContentsList?.find((content) => content.attributes.content_type === 'page' && content.attributes.slug === '/faqs' && content.attributes.name !== 'Default Whitelabel FAQS');

  const privacy = channelContentsList?.find((content) => content.attributes.content_type === 'page' && content.attributes.slug === '/privacy' && content.attributes.name !== 'Default Privacy');

  const themeId = channelTheme?.data?.attributes?.name !== 'Pj Whitelabel' ? channelTheme?.data?.id : undefined;

  return {
    meta: {
      custom_domain: false,
      url: '',
      theme: {
        config: channelTheme?.data?.attributes?.config ?? {},
        metadata: channelTheme?.data?.attributes?.metadata ?? {}

      },
      whitelabel: {
        content: b2cChannel?.data?.attributes?.metadata?.content ?? {},
        sms_sender_name: b2cChannel?.data?.attributes?.metadata?.sms_sender_name ?? '',
        sender: b2cChannel?.data?.attributes?.metadata?.sender ?? '',
      },
      faq: {
        content: faq
      },
      privacy: {
        content: privacy
      }

    },


    data: {
      relationships: {
        theme: {
          data: {
            id: themeId
          }
        },
        product: {
          data: {
            id: products?.[0]?.id ?? ''
          }
        }
      }
    }
  };
};


export default compose(
  connect(
    (state, ownProps) => {
      return {
        initialValues: getInitialValues(ownProps.resource)(state),
        formValues: getFormValues(FORM_NAME)(state),
      };
    },
    {
      createWhiteLabel: createWhiteLabel,
      triggerFileUpload: triggerFileUpload
    },
  ),
  reduxForm({ form: FORM_NAME })
)(BrokerTheme);



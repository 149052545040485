import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Box, ButtonBar, Loader } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import { actions } from '../redux/ProductReducer';
import { Row } from 'react-bootstrap';
import ProductName
  from '../components/ProductFrontPage/components/ProductName/ProductName';
import DisplayListContent from '../components/DisplayListContent';

import { Button } from '../../../common/components/Button/Button';
import {
  ProductInfoContainer
} from '../components/ProductInfoContainer/ProductInfoContainer';
import ProductNavigation from '../components/ProductNavigation/ProductNavigation';

const ActiveProductContainer = ({
  productId,
  currrentLocation
}) => {

  const {
    product,
    isLoading,
  } = useSelector((state) => state.productManagement);

  const { icon, name, status, metadata } = product?.data?.attributes ?? {};
  const {
    regions,
    options,
    annual_trip_durations: maxTripDurations,
    medical_declaration_questions: medicalQuestions,
    definitions,
    is_wl_product: isWhitelabel,
  } = metadata ?? {};


  if (isLoading) {
    return (
      <div className="admin-area">
        <InternalContainer title="Product management">
          <Loader/>
        </InternalContainer>
      </div>
    );
  }

  return (
    <div className="admin-area">
      <InternalContainer title="Product management">
        <div>
          <ProductName
            icon={icon}
            name={name}
            status={status}
          >
            <ProductNavigation
              id={productId}
              currentLocation={currrentLocation}
              isWhitelabel={isWhitelabel}
            />
          </ProductName>
          <Box className={'product-container'}>


            <div className="hr"/>


            <Row>
              <ProductInfoContainer title="Regions">
                <DisplayListContent
                  contentToMap={regions}/>
              </ProductInfoContainer>

              <ProductInfoContainer title="Regions">
                <DisplayListContent
                  contentToMap={maxTripDurations}/>
              </ProductInfoContainer>

              <ProductInfoContainer title="Medical Questions">

                <DisplayListContent
                  customType="medical-questions"
                  contentToMap={medicalQuestions}
                />
              </ProductInfoContainer>

              <ProductInfoContainer title="Options">
                <DisplayListContent
                  customType="options"
                  contentToMap={options}
                />
              </ProductInfoContainer>

              <ProductInfoContainer title="Definitions - (info-button content)">

                <DisplayListContent
                  customType="definitions"
                  contentToMap={definitions}
                />
                <ButtonBar>
                  <Button
                    link
                    to={`/admin/products/${productId}/definitions`}>
                    Edit
                  </Button>

                </ButtonBar>
              </ProductInfoContainer>
            </Row>


          </Box>
          {/*<InternalButtonBar buttons={[*/}
          {/*  {*/}
          {/*    permissions: ['scheme.edit'],*/}
          {/*    button: (*/}
          {/*      <Button*/}
          {/*        link*/}
          {/*        to={`/admin/products/${productId}/edit`}*/}
          {/*        label={'Edit'}*/}
          {/*      />*/}
          {/*    )*/}
          {/*  }*/}
          {/*]}/>*/}
        </div>

      </InternalContainer>
    </div>
  );

};

export default connect(
  (state) => ({
    productManagement: state.productManagement,
  }),
  {
    onGetProduct: actions.getProduct,
    onGetDestinations: actions.getDestinations,
    onGetProductsStats: actions.getProductsStats,
  }
)(ActiveProductContainer);

export default function displayAccountAmount(value, zeroValue = '0.00 DR') {
  if (!value) {
    return zeroValue;
  }

  value = parseFloat(value);

  return value < 0
    ? Math.abs(value)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' CR'
    : Math.abs(value)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' DR';
}

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import { compose } from 'redux';
import SchemeGroupDetails from '../SchemeGroupDetails/SchemeGroupDetails';
import { Form } from 'react-bootstrap';
import SchemeManagementSectionContainer
  from '../SchemeManagementSectionContainer/SchemeManagementSectionContainer';

const MainSchemeGroupDetailsForm = ({
  handleSubmit,
  isViewOnly,
  formValues,
  onUpdatePrefix,
}) => {
  const prefix = formValues?.data?.attributes?.prefix;

  useEffect(
    () => {
      if (prefix && onUpdatePrefix) {
        onUpdatePrefix(prefix);
      }
    },
    [prefix, onUpdatePrefix],
  );
  const scheme = formValues;
  if (!scheme) {
    return null;
    //return (<div/>;
  }
  const version = scheme.data.attributes.version
    ? ` v${scheme.data.attributes.version}`
    : '';

  const title = `${scheme.data.attributes.name}${version}`;

  return (
    <SchemeManagementSectionContainer
      title={'Scheme Details'}
      subTitle={title}
      isViewOnly={isViewOnly}
    >
      <Form horizontal onSubmit={handleSubmit}>
        <SchemeGroupDetails isViewOnly={isViewOnly}/>
      </Form>
      {/*  {viewOnly && <AssistanceDetails scheme={scheme} viewOnly={true}/>}*/}
    </SchemeManagementSectionContainer>
  );
};

export default compose(
  connect((state, props) => {
    return {
      formValues: getFormValues(props.formName)(state),
      destroyOnUnmount: false,
      enableReinitialize: true,

      form: props.formName,
    };
  }),
  reduxForm({}),
)(MainSchemeGroupDetailsForm);

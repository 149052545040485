import React from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import Icon from '../../../../../common/components/Icon/Icon';


const DynamicDocumentDropdownActions = ({ onCreateNewVersion, document, showForm }) => {
  const handleDropdownAction = () => {

    onCreateNewVersion();
  };


  return (
    <div>
      <DropdownButton
        pullRight
        bsSize="small"
        title={'Actions'}
        bsStyle={'default'}
        id={'dropdown-scheme-actions-' + document.id}
        disabled={document.attributes.status === 'ended' || showForm}
      >
        {document.attributes.status !== 'ended' && !document.attributes.replaced && (
          <MenuItem eventKey="2" onClick={handleDropdownAction}>
            <Icon name="edit"/>{' '}
            {document.attributes.status === 'pending' ? 'Edit' : 'New Version'}
          </MenuItem>
        )}
      </DropdownButton>
    </div>
  );

};

export default DynamicDocumentDropdownActions;

import Axios from 'axios';


export function getMonitoredJobs(days) {
  return (dispatch) => {
    const endpoint = 'system-health/monitoring-jobs/'+ days
    return Axios
      .get(endpoint)
      .then(response => {
        return response
      }).catch(error => {
        console.error(error)
      })
  }
}

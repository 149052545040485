import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, DataTable } from '../../../../common/components';
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions';
import { ControlLabel, Col, Row } from 'react-bootstrap';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { FormattedDate, FormattedTime } from 'react-intl';
import getStatus from '../../../../schemas/policy';
import { push } from 'react-router-redux';
import selectTheme, { styles } from '../../../../helpers/selectTheme';
import Select from 'react-select';

export default class CustomerApplications extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
  };

  formatDate(cell) {
    return (
      <span>
        <FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/>
      </span>
    );
  }

  handleRowClick(row) {
    this.props.dispatch(push('/policies/applications/' + row.id));
  }

  handleFilterUpdate(option) {
    this.props.dispatch(
      applyFilter('applications', 'filter[version]', option.value),
    );
  }

  getStatus(status) {
    const resource = this.props.resource.data.attributes;
    if (resource.quote_type === 'mid term adjustment' && status === 'quote') {
      return 'awaiting approval';
    }

    return getStatus(status);
  }

  render() {
    const resource = this.props.resource.data.attributes;

    return (
      <div>
        <Box>
          <h2>{resource.customer_name}</h2>

          <Row>
            <Col sm={3}>
              <ControlLabel className="filter-label">Quote Version</ControlLabel>
              <Select
                theme={(theme) => selectTheme(theme)}
                styles={styles}
                onChange={(option) => this.handleFilterUpdate(option)}
                options={[
                  { label: 'Current', value: '' },
                  { label: 'All', value: 'all' },
                ]}
                defaultValue={{ label: 'Current', value: '' }}
              />
            </Col>
          </Row>

          <Row className="data-table-row">
            <Col xs={12}>
              <DataTable
                source={'/policies/applications'}
                autoFilter={this.props.resource.data.id}
                autoFilterType="filter[customer]"
                name="applications"
                showTotal
                onRowSelect={this.handleRowClick.bind(this)}
              >
                <TableHeaderColumn dataField="id" isKey hidden/>

                <TableHeaderColumn dataField="quote_reference" width={'100px'}>
                  Quote Reference
                </TableHeaderColumn>

                <TableHeaderColumn dataField="version" width={'125px'} dataAlign={'center'}>
                  Version
                </TableHeaderColumn>

                <TableHeaderColumn dataField="product_name" width={'150px'}>
                  Product
                </TableHeaderColumn>
                <TableHeaderColumn dataField="insured" width={'150px'}>
                  Insured
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="status"
                  dataSort
                  width={'100px'}
                  dataFormat={getStatus}
                >
                  Status
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="created_at"
                  dataFormat={this.formatDate}
                  dataSort
                  width={'100px'}
                >
                  Quote Date
                </TableHeaderColumn>
              </DataTable>
            </Col>
          </Row>
        </Box>
      </div>
    );
  }
}

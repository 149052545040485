import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PanelGroup, Panel } from 'react-bootstrap';
import { Button } from '../../../../common/components';
import HandlerButton from '../HandlerButton';
import Markdown from 'react-remarkable';
import './styles.scss';

export default class ProductDetails extends Component {
  static propTypes = {
    product: PropTypes.object.isRequired,
    insurers: PropTypes.array.isRequired,
  };

  render() {
    const { product, insurers } = this.props;
    const insurer = insurers[Object.keys(insurers)[0]];

    return (
      <div className="row product-details">
        <div className="col-xs-9 col-sm-10">
          <h2>{product.attributes.name}</h2>
          <div className="get-quotes-btn-box">
            <Button
              link
              to={'products/' + product.id + '/quote'}
              //className='pull-right'
              bsStyle="primary"
              label="Get a quote"
            />
          </div>
          <Markdown source={product.attributes.description} />
          {Object.keys(insurers).length === 1 ? (
            <div className="documents">
              {insurer.documents.map((document, key) => {
                return (
                  <a key={key} href={document.attributes.file_path} target="_blank">
                    <Button size="sm" label={document.attributes.short_name} />
                  </a>
                );
              })}
              {insurer.claims_handlers && (
                <HandlerButton
                  name={product.attributes.name + 'claims'}
                  handlers={insurer.claims_handlers}
                  handlerType="Claims"
                />
              )}
              {(insurer.assistance_handlers && product.attributes.product_code !== 'TUMC') && (
                <HandlerButton
                  name={product.attributes.name + 'medical'}
                  handlers={insurer.assistance_handlers}
                  handlerType="Medical Assistance"
                />
              )}
            </div>
          ) : (
            <div className="product-insurers">
              <PanelGroup defaultActiveKey="1" accordion>
                {Object.keys(insurers).map((insurerKey, key) => {
                  const data = insurers[insurerKey];
                  return (
                    <Panel header={data.insurer.data.id} key={key} eventKey={key}>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="documents">
                            {data.documents.map((document, dk) => {
                              return (
                                <a
                                  key={dk}
                                  href={document.attributes.file_path}
                                  target="_blank"
                                >
                                  <Button
                                    size="sm"
                                    label={document.attributes.short_name}
                                  />
                                </a>
                              );
                            })}
                            {data.claims_handlers && (
                              <HandlerButton
                                name={product.attributes.name + 'claims' + key}
                                handlers={data.claims_handlers}
                                handlerType="Claims"
                              />
                            )}
                            {(data.assistance_handlers && product.attributes.product_code !== 'TUMC') && (
                              <HandlerButton
                                name={product.attributes.name + 'medical' + key}
                                handlers={data.assistance_handlers}
                                handlerType="Medical Assistance"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </Panel>
                  );
                })}
              </PanelGroup>
            </div>
          )}
        </div>
      </div>
    );
  }
}

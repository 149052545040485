import React, { Component } from 'react';
import { Button, SelectRole, } from '../../../../../common/components';
import { Col, Row, ControlLabel } from 'react-bootstrap';
import { saveUserOrganisationRoles, getUserOrganisationRoles } from '../../../redux/userActions';


class EditUserRoles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editRoles: false,
      roles: [],
    };
  }

  componentWillMount() {
    const { organisationRoles } = this.props;
    this.setState({ roles: organisationRoles });
  }

  handleEditRoles() {
    this.setState({ editRoles: true });
  }

  handleSelectRoles(options) {
    this.setState({ roles: options });
  }

  handleSubmitRoles() {
    const { dispatch, organisationId, userId } = this.props;
    this.setState({ editRoles: false });
    Promise.resolve(
      dispatch(
        saveUserOrganisationRoles({
          data: {
            relationships: {
              organisation: {
                data: {
                  id: organisationId
                }
              },
              roles: {
                data: this.state.roles
              }
            }
          }
        }, userId)
      )
    ).then(() => dispatch(getUserOrganisationRoles(userId)));
  }

  render() {
    const { organisationId, organisationType } = this.props;

    if (!this.state.editRoles) {
      return (
        <Button
          bsStyle="primary"
          className="pull-right"
          rightIcon="pencil-square-o"
          label="Edit Roles"
          handleClick={::this.handleEditRoles}
        />
      );
    }

    return (
      <Row>
        <Col sm={2} componentClass={ControlLabel}>
          Assign Roles:
        </Col>
        <Col sm={6}>
          <SelectRole
            name={'selectRole' + organisationId}
            sourceKey={'MultiRoles' + organisationId}
            autoFilters={[{ type: 'organisation_type', match: organisationType }]}
            input={{
              value: this.state.roles,
              onChange: (options) => this.handleSelectRoles(options),
              onBlur: (options) => this.handleSelectRoles(options),
            }}
          />
        </Col>
        <Button
          bsStyle="primary"
          className="pull-right"
          label="Save Changes"
          handleClick={::this.handleSubmitRoles}
        />
      </Row>
    );
  }
}

export default EditUserRoles;

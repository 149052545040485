import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUser } from '../redux/userActions';
import { Button, Loader } from '../../../common/components';
import getIncludedResource from '../../../helpers/getIncludedResource';
import InternalContainer from '../../layout/containers/Internal';
import UserFormContainer from './UserFormContainer';
import { get } from 'lodash';

class UserEditContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
  };

  componentWillMount() {
    this.props.dispatch(getUser(this.props.params.id));
  }

  isInOwnerOrganisation(resource) {
    const organisations = getIncludedResource(
      resource.data,
      resource.included,
      'organisations',
    );

    let isOwner = false;

    if (organisations) {
      organisations.forEach((organisation) => {
        if (organisation.attributes.organisation_type_name === 'Owner') {
          isOwner = true;
        }
      });
    }

    return isOwner;
  }

  render() {
    const contact = get(this.props, 'location.query.contact', false) === true;
    const resource = this.props.user.resources[this.props.params.id];
    const buttons = (
      <Button bsStyle="default" label="back" isBackButton leftIcon="caret-left" />
    );

    return (
      <InternalContainer title="User" buttons={buttons}>
        {resource ? (
          <UserFormContainer
            {...this.props}
            resource={resource}
            isInOwnerOrganisation={::this.isInOwnerOrganisation(resource)}
            contact={contact}
          />
        ) : (
          <Loader />
        )}
      </InternalContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(UserEditContainer);

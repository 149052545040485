import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InternalContainer from '../../layout/containers/Internal';
import EnquiryList from '../components/EnquiryList';

class EnquiryListContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    enquiries: PropTypes.object,
  };

  render() {
    return (
      <InternalContainer title="Enquiries">
        <EnquiryList {...this.props} />
      </InternalContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    enquiry: state.enquiry,
  };
}

export default connect(mapStateToProps)(EnquiryListContainer);

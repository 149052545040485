import React from 'react';
import InternalContainer from '../../layout/containers/Internal';
import JournalForm from '../components/JournalForm';

const AccountJournalContainer  = () => {


    return (
      <InternalContainer title="Manual Journal">
        <JournalForm />
      </InternalContainer>
    );

};
export default AccountJournalContainer;

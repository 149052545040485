import React from 'react';
import {
  HorizontalFormControl,
  Icon,
  InfoLabel,
} from '../../../../../common/components';
import { Field } from 'redux-form';
import { Col, } from 'react-bootstrap';
import SelectField from '../../../../../common/components/ReduxFormField/SelectField';
import { AutoIncludeField, ViewAutoInclude } from './AutoIncludeField/AutoIncludeField';

const CoverLevelRatesTable = ({ input, isViewOnly, coverLevels }) => {
  const argumentList = input.value.arguments;
  if (argumentList.length > 1) {
    return <div>no support for scheme management multi argument optopms </div>;
  }
  const argumentName = argumentList?.[0];

  const rateType = input.value.rate_type;
  return (
    <>
      <Col sm={12}>
        <h3 className={'capitalise'}>Argument: {argumentName.replace(/_/, ' ')}</h3>
      </Col>
      <Col sm={6}>
        <Field
          name={`${input.name}.rate_type`}
          label={'Rate Type:'}
          fieldSize={2}
          labelSize={2}
          component={isViewOnly ? InfoLabel : SelectField}
          isDisabled={isViewOnly}
          options={[
            { value: 'loading', label: 'Loading' },
            { value: 'fixed', label: 'Fixed' },
          ]}
        />
      </Col>

      {coverLevels.map((rateKey) => {
        return (
          <Col sm={12}>
            <Field
              labelSize={1}
              label={rateKey}
              name={`${input.name}.rates.${rateKey}`}
              component={isViewOnly ? InfoLabel : HorizontalFormControl}
              addonBefore={rateType === 'fixed' && <Icon name={'pound-sign'}/>}
            />
          </Col>
        );
      })}
      <Col sm={6}>
        <Field
          name={`${name}.auto_include`}
          label={'Auto-include:'}
          labelSize={4}
          fieldSize={8}
          noFormGroup
          component={isViewOnly ? ViewAutoInclude : AutoIncludeField}
          coverLevels={coverLevels}
          options={[{ label: '', value: true }]}
        />
      </Col>
    </>
  );
};
export default CoverLevelRatesTable;

import React, { Component } from 'react';
import CenteredSection from '../../brochure/components/CenteredSection/CenteredSection';
import { Tabs, Tab, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Form, formValueSelector, reduxForm } from 'redux-form';
import { getBrokerApplicationQuestions } from '../../brochure/redux/brochureActions';
import General from '../components/BrokerApNewForm/components/tabsContent/General';
import Company from '../components/BrokerApNewForm/components/tabsContent/Company';
import Management from '../components/BrokerApNewForm/components/tabsContent/Management';
import Regulation from '../components/BrokerApNewForm/components/tabsContent/Regulation';
import Users from '../components/BrokerApNewForm/components/tabsContent/Users';
import Declaration from '../components/BrokerApNewForm/components/tabsContent/Declaration';
import Button from '../../../common/components/Button/Button';
import { createPublicBrokerApplication } from '../redux/brokerApplicationActions';
import Box from '../../../common/components/Box/Box';

class OpenAnAccount extends Component {
  state = {
    activeTab: 1,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getBrokerApplicationQuestions());
  }

  handleTab = (key) => {
    this.setState({ activeTab: key });
  };

  goPreviousTab = () => {
    this.setState((prevState) => ({
      activeTab: prevState.activeTab - 1,
    }));
  };

  goNextTab = () => {
    this.setState((prevState) => ({
      activeTab: prevState.activeTab + 1,
    }));
  };

  handleSave = (formValues) => {
    const { dispatch } = this.props;
    dispatch(createPublicBrokerApplication(formValues));
  };

  render() {
    const {
      organisation,
      brochure,
      brokerApplication,
      handleSubmit,
      formValues,
    } = this.props;
    const { activeTab } = this.state;

    return (
      <div>
        <Form onSubmit={handleSubmit(this.handleSave)}>
          <div className="guest-section">
            <div className="guest-title">Open an Account</div>
            {!brokerApplication.publicSubmitSuccess ? (
              <CenteredSection className="page-content">
                <Box>
                  <h3>Open a New Agency</h3>
                  To open an account just follow the steps below.
                </Box>
                <Tabs
                  activeKey={activeTab}
                  id="broker-application"
                  justified
                  unmountOnExit
                  onSelect={this.handleTab}
                >
                  <Tab eventKey={1} title={'General'}>
                    <General
                      organisation={organisation}
                      brokerType={'broker'}
                      form={FORM_NAME}
                    />
                  </Tab>
                  <Tab eventKey={2} title={'Company'}>
                    <Company organisation={organisation} form={FORM_NAME} />
                  </Tab>
                  <Tab eventKey={3} title={'Management'}>
                    <Management form={FORM_NAME} {...this.props} />
                  </Tab>
                  <Tab eventKey={4} title={'Regulation'}>
                    <Regulation content={brochure} form={FORM_NAME} />
                  </Tab>
                  <Tab eventKey={5} title={'Users'}>
                    <Users form={FORM_NAME} />
                  </Tab>
                  <Tab eventKey={6} title={'Declaration'}>
                    <Declaration formValues={formValues} />
                  </Tab>
                </Tabs>
                <div className="btn-bar-container">
                  <Row>
                    <Col sm={12}>
                      {activeTab > 1 && (
                        <Button
                          className="btn-action-back"
                          bsStyle="default"
                          handleClick={this.goPreviousTab}
                          label="Back"
                        />
                      )}
                      {activeTab < 6 && (
                        <Button
                          className="btn-action pull-right"
                          bsStyle="primary"
                          handleClick={handleSubmit(this.goNextTab)}
                          label="Next"
                        />
                      )}
                      {activeTab > 5 && (
                        <Button
                          type="submit"
                          size="large"
                          className="pull-right"
                          label={'Submit Application'}
                          bsStyle="primary"
                          isLoading={brokerApplication.isSubmitting}
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              </CenteredSection>
            ) : (
              <CenteredSection className="page-content">
                <Box>
                  <h3>Application Successful</h3>
                  <p>Thank you for submitting your application.</p>
                  <p>You will hear from us shortly.</p>
                </Box>
              </CenteredSection>
            )}
          </div>
        </Form>
      </div>
    );
  }
}

const FORM_NAME = 'BrokerApplication';
const form = reduxForm({ form: FORM_NAME })(OpenAnAccount);
const selector = formValueSelector(FORM_NAME);
export default connect((state) => {
  const values = selector(state, 'data', 'meta');

  return {
    organisation: state.organisation,
    brochure: state.brochure,
    brokerApplication: state.brokerApplication,
    formValues: values,
  };
})(form);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';
import SelectMenu from '../SelectMenu/SelectMenu';
import { forEach } from 'lodash';

export const SELECT_IDENTIFIER = 'campaigns';

class SelectCampaign extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    label: PropTypes.string,
    labelSize: PropTypes.number,
    input: PropTypes.object.isRequired,
    infoButton: PropTypes.element,
    name: PropTypes.string,
    meta: PropTypes.object,
    labelKeys: PropTypes.array,
    isObject: PropTypes.bool,
    isMulti: PropTypes.bool,
  };

  renderSelectMenu() {
    const { filters } = this.props;
    let filterUrl = '';

    if (filters) {
      const joinChar = (url) => {
        return url.includes('?') ? '&' : '?';
      };

      forEach(filters, (filter, key) => {
        if (filter) {
          filterUrl += `${joinChar(filterUrl)}filter[${key}]=${filter}`;
        }
      });
    }

    return (
      <SelectMenu
        override
        source={SELECT_IDENTIFIER}
        filterUrl={filterUrl}
        optionValuePath={'id'}
        {...this.props}
      />
    );
  }

  render() {
    const { infoButton, name, meta, label, labelSize } = this.props;

    if (label) {
      return (
        <FormGroup
          controlId={name}
          className={meta && meta.touched && meta.error ? ' has-error' : ''}
        >
          {label && (
            <Col componentClass={ControlLabel} sm={labelSize}>
              {label} {infoButton}
            </Col>
          )}
          <Col sm={12 - labelSize}>
            {this.renderSelectMenu()}
          </Col>
        </FormGroup>
      );
    }

    return this.renderSelectMenu();
  }
}

export default connect()(SelectCampaign);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { TextField } from '../../../../common/components/ReduxFormField';
import { HorizontalFormControl } from '../../../../common/components';
import { get, set } from 'lodash';
import { Button } from '../../../../common/components';
import { createEnquiry } from '../../../enquiry/redux/enquiryActions';
import { Row, Col, Form, ControlLabel } from 'react-bootstrap';
import './styles.scss';

const FORM_NAME = 'challengeForm';
const validate = (values) => {
  const errors = {};

  if (!get(values, 'data.attributes.message')) {
    set(errors, 'data.attributes.message', 'Field required');
  }

  return errors;
};

export class ContactForm extends Component {
  handleSubmit(resource) {
    return this.props.dispatch(createEnquiry(resource));
  }

  render() {
    const { handleSubmit, submitting, submitSucceeded } = this.props;

    return (
      <div className="guest-contact">
        {!submitSucceeded ? (
          <Form onSubmit={handleSubmit(::this.handleSubmit)} className="form-horizontal">
            <Field
              name="data.attributes.name"
              placeholder="Name"
              label="Name"
              type="text"
              component={HorizontalFormControl}
            />
            <Field
              name="data.attributes.email"
              label="Email"
              placeholder="Email"
              type="text"
              component={HorizontalFormControl}
            />
            <div className="guest-contact-textarea">
              <Row>
                <Col xs={12} componentClass={ControlLabel}>
                  Message
                </Col>
              </Row>
              <Field
                name="data.attributes.message"
                placeholder="Message"
                type="textarea"
                component={TextField}
                // component={HorizontalFormControl}
              />
            </div>

            <Row>
              <Col xs={12}>
                <Button
                  bsStyle='primary'
                  isLoading={submitting}
                  type='submit'
                  className='pull-right'
                  label={'Submit'}
                />
              </Col>
            </Row>
          </Form>
        ) : (
          <h2>Thank you for your enquiry.</h2>
        )}
      </div>
    );
  }
}

ContactForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
};

export default reduxForm({ form: FORM_NAME, validate })(ContactForm);

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import { Form } from 'react-bootstrap';
import SchemeManagementSectionContainer
  from "../SchemeManagementSectionContainer/SchemeManagementSectionContainer";


const GeneralDetailsForm = ({ handleSubmit, children, isViewOnly, formValues }) => {
  if (!formValues) {
    return null;
  }
  return (
    <SchemeManagementSectionContainer
      title={'General'}
      subTitle={'Rules'}
      isViewOnly={isViewOnly}
    >
      <Form horizontal onSubmit={handleSubmit}>
        {children}
      </Form>
    </SchemeManagementSectionContainer>
  );
};

export default compose(
  connect((state, props) => {
    return {
      formValues: getFormValues(props.formName)(state),
      destroyOnUnmount: false,
      enableReinitialize: true,

      form: props.formName,
    };
  }),
  reduxForm({}),
)(GeneralDetailsForm);

import React, { Component } from 'react';
import { Field } from 'redux-form';
import {
  HorizontalFormControl,
  NameFields,
  // PhoneFormControl,
  DatePickerDob,
  Button,
  Icon,
} from '../../../../common/components';
import { Row, Col, ControlLabel, FormGroup } from 'react-bootstrap';
import moment from 'moment';
import 'moment/locale/en-gb';
import './styles.scss';
import { get, has } from 'lodash';
import { COL_WIDTHS } from '../LeisureTravelForm/Components/TabContent/TravellersTab02';

const travellerDefaults = {
  title: '',
  first_name: '',
  last_name: '',
  dob: '',
  email: '',
  phone1: '',
  phone2: '',
  conditions_covered: true,
  screening: '',
  screening_id: '',
};

/***************************************************
 * Component to handle Traveller Groups with children
 ***************************************************/

export default class Travellers extends Component {
  componentWillReceiveProps(nextProps) {
    const { fields, travellerGroup } = nextProps;
    if (travellerGroup !== this.props.travellerGroup) {
      if (fields.length === 0) {
        // fields.push(travellerDefaults)
      }

      // clear all children fields for individual and couple
      if (travellerGroup === 'couple') {
        fields.removeAll();
      }

      let isItFamilyOrSingleParentFamily = false;

      if (travellerGroup === 'family' || travellerGroup === 'single_parent_family') {
        isItFamilyOrSingleParentFamily = true;
      }

      if (fields.length === 0 && isItFamilyOrSingleParentFamily) {
        fields.push(travellerDefaults);
      }
    }
  }

  renderDob (insured, travellerType, travellerGroup, ageLimits) {
    const { currentProduct } = this.props;

    const path = has(ageLimits,
      `${travellerGroup}.${travellerType}`)
      ?
      `${travellerGroup}.${travellerType}`
      :
      `${travellerType}`;

    const getDateForMinAge = (path,  ageLimits) => {
      const defaultMinAge = travellerType === 'children' ? 0  : 23;
      const minAgeLimit = get(ageLimits, `${path}.min_age`, defaultMinAge);
      return moment().subtract(minAgeLimit , 'years').format();
    };

    const getDateForMaxAge = (path, ageLimits) => {
      const defaultMaxAge = travellerType === 'children' ? 22 : 105;
      let maxAgeLimit = get(ageLimits, `${path}.max_age`, defaultMaxAge);
      const productCode = get(currentProduct, 'data.attributes.product_code', '');

      if (['FST', '247', 'VEN'].includes(get(currentProduct, 'data.attributes.product_code', '')) && travellerType === 'children') {
        maxAgeLimit = maxAgeLimit + 1;
      }

      if ('BMC' === productCode) {
        maxAgeLimit = maxAgeLimit + 1;
        const maxAgeDate = moment().subtract(maxAgeLimit, 'years');
        return maxAgeDate.subtract(1, 'days').format();
      }


      if (['FPS', 'FPR', 'NPO', 'NPOR', 'CSPA', 'CSPAR', 'CSS', 'CSSR'].includes(productCode)) {
        return moment().add(1, 'day').subtract(maxAgeLimit, 'years').format();
      }

      maxAgeLimit = 110;

      return moment().subtract(maxAgeLimit, 'years').format();
    };
    return (
        <Field
          name={insured + '.dob'}
          showYear
          minDate={getDateForMaxAge(path, ageLimits)}
          maxDate={getDateForMinAge(path, ageLimits)}
          component={DatePickerDob}
        />
    );

  }

  render() {
    const {
      fields,
      child,
      travellerGroup,
      travellerType,
      noTravellers,
      ageLimits,
      currentProduct
    } = this.props;
    const productCode = get(currentProduct, 'data.attributes.product_code');
    let showButton = true;

    if (travellerGroup === 'couple') {
      showButton = false;
    }

    // numberOfTravellers
    if (noTravellers) {
      if (
        travellerGroup === 'family' ||
        travellerGroup === 'individual' ||
        travellerGroup === 'independent_child'
      ) {
        showButton =
          // (!child && noTravellers.adults < (extendedFamily ? 4 : 10)) ||
          // ((child && noTravellers.children >= 0) &&
          child && noTravellers.children <= 9;
      }

      if (
        (travellerGroup === 'family' ||
          travellerGroup === 'individual' ||
          travellerGroup === 'independent_child') &&
        (
          productCode === 'ADV' ||
          productCode === 'PST'
        )
      ) {
        showButton =
          // (!child && noTravellers.adults < (extendedFamily ? 4 : 10)) ||
          // ((child && noTravellers.children >= 0) &&
          child && noTravellers.children <= 49;
      }
    }

    if (['BMC', 'CPI'].includes(productCode)) {
      showButton = false;
    }

    if (['FPS', 'FPR', 'NPO', 'NPOR', 'CSPA', 'CSPAR', 'CSS', 'CSSR'].includes(productCode) && (travellerGroup === '' || travellerGroup === 'individual')) {
      showButton = false;
    }

    // Hide if only set to display for specific groups
    // if (displayGroups && displayGroups.indexOf(travellerGroup) === -1) {
    //   return <div />
    // }

    return (
      <div className="travellers">
        {fields.map((insured, index) => {
          return (
            <div
              key={index}
              className={
                'traveller-row traveller' + index + (child ? ' child' : '')
              }
            >
              {child && (
                <div>
                  <span
                    className="remove-traveller pull-right"
                    onClick={() => fields.remove(index)}
                  >
                    {travellerGroup !== 'couple' && <Icon name="times"/>}
                  </span>
                  <h4>
                    {'Child'} {index + 1}
                  </h4>
                </div>
              )}
              <FormGroup>
                <Col sm={COL_WIDTHS.labels.sm} md={COL_WIDTHS.labels.md} lg={COL_WIDTHS.labels.lg} componentClass={ControlLabel}>
                  Name
                </Col>
                <Col sm={COL_WIDTHS.fields.sm} md={COL_WIDTHS.fields.md} lg={COL_WIDTHS.fields.lg}>
                  <NameFields
                    baseName={insured}
                    fieldComponent={Field}
                    child={child}
                    disableOnBlur
                  />
                </Col>
              </FormGroup>
              {/* Fix: displaying doubled DOB for adults */}
              {index !== 0 && (
                <FormGroup>
                  <Col sm={COL_WIDTHS.labels.sm} md={COL_WIDTHS.labels.md} lg={COL_WIDTHS.labels.lg} componentClass={ControlLabel}>
                    Date of birth
                  </Col>
                  <Col sm={COL_WIDTHS.fields.sm} md={COL_WIDTHS.fields.md} lg={COL_WIDTHS.fields.lg}>
                    {this.renderDob(insured,travellerType, travellerGroup, ageLimits)}
                  </Col>
                </FormGroup>
              )}
              {/* Fix:  displaying DOB for additional childs */}
              {(child && !index) && (
                <FormGroup>
                  <Col sm={COL_WIDTHS.labels.sm} md={COL_WIDTHS.labels.md} lg={COL_WIDTHS.labels.lg} componentClass={ControlLabel}>
                    Date of birth
                  </Col>
                  <Col sm={COL_WIDTHS.fields.sm} md={COL_WIDTHS.fields.md} lg={COL_WIDTHS.fields.lg}>
                    {this.renderDob(insured, travellerType, travellerGroup, ageLimits)}
                  </Col>
                </FormGroup>
              )}
              {(!child && !index) && (
                <div>
                  <FormGroup>
                    <Col sm={COL_WIDTHS.labels.sm} md={COL_WIDTHS.labels.md} lg={COL_WIDTHS.labels.lg} componentClass={ControlLabel}>
                      Email
                    </Col>
                    <Col sm={COL_WIDTHS.fields.sm} md={COL_WIDTHS.fields.md} lg={COL_WIDTHS.fields.lg}>
                      <Field
                        name={insured + '.email'}
                        type="email"
                        component={HorizontalFormControl}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col sm={COL_WIDTHS.labels.sm} md={COL_WIDTHS.labels.md} lg={COL_WIDTHS.labels.lg} componentClass={ControlLabel}>
                      Telephone
                    </Col>
                    <Col sm={COL_WIDTHS.fields.sm} md={COL_WIDTHS.fields.md} lg={COL_WIDTHS.fields.lg}>
                      <Field
                        name={insured + '.phone1'}
                        component={HorizontalFormControl}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col sm={COL_WIDTHS.labels.sm} md={COL_WIDTHS.labels.md} lg={COL_WIDTHS.labels.lg} componentClass={ControlLabel}>
                      Date of birth
                    </Col>
                    <Col sm={COL_WIDTHS.fields.sm} md={COL_WIDTHS.fields.md} lg={COL_WIDTHS.fields.lg}>
                      {this.renderDob(insured, travellerType, travellerGroup, ageLimits)}
                    </Col>
                  </FormGroup>
                </div>
              )}
            </div>
          );
        })}

        {showButton && (
          <Row className="travellers">
            <Col sm={COL_WIDTHS.labels.sm + COL_WIDTHS.fields.sm} md={COL_WIDTHS.labels.md + COL_WIDTHS.fields.md} lg={COL_WIDTHS.labels.lg + COL_WIDTHS.fields.lg}>
              <Button
                bsStyle="primary"
                className="add-traveller pull-right"
                size="xs"
                leftIcon={'plus'}
                label={'Add a Child'}
                handleClick={() => fields.push(travellerDefaults)}
              />
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export const SET_PRODUCT_DOCUMENT_TYPE_LIST = 'socrates-online/document-management/SET_PRODUCT_DOCUMENT_TYPE_LIST';
export const IS_RETRIEVING_DOCUMENTS = 'socrates-online/document-management/IS_RETRIEVING_DOCUMENTS';
export const IS_SUBMITTING = 'socrates-online/document-management/IS_SUBMITTING';

export const SET_DOCUMENT_SUMMARY_LIST = 'socrates-online/document-management/SET_DOCUMENT_SUMMARY_LIST';
export const SET_DOCUMENT_SCHEME_LIST = 'socrates-online/document-management/SET_DOCUMENT_SCHEME_LIST';
// export const VIEW_OR_EDIT_DOCUMENT = 'socrates-online/document-management/VIEW_OR_EDIT_DOCUMENT';
export const CLOSE_DOCUMENT_MODAL = 'socrates-online/document-management/CLOSE_DOCUMENT_MODAL';
export const CLEAR_DOCUMENT_MANAGEMENT_DATA =
  'socrates-online/document-management/CLEAR_DOCUMENT_MANAGEMENT_DATA';
export const SET_DYNAMIC_DOCUMENTS_LIST = 'socrates-online/document-management/SET_DYNAMIC_DOCUMENTS_LIST';
export const CLEAR_DYNAMIC_DOCUMENT_DATA = 'socrates-online/document-management/CLEAR_DYNAMIC_DOCUMENT_DATA';
export const IS_LOADING = 'socrates-online/document-management/IS_LOADING';
export const IS_RETRIEVING_DOCUMENT_TYPES = 'socrates-online/document-management/IS_RETRIEVING_DOCUMENT_TYPES';

export const VIEW_DOCUMENT = 'socrates-online/document-management/VIEW_DOCUMENT';
export const EDIT_DOCUMENT = 'socrates-online/document-management/EDIT_DOCUMENT';
export const CLEAR_DATA = 'socrates-online/document-management/CLEAR_DATA';
export const CLOSE_VIEW_DOCUMENT_MODAL = 'socrates-online/document-management/CLOSE_VIEW_DOCUMENT_MODAL';
export const CLOSE_EDIT_DOCUMENT_MODAL = 'socrates-online/document-management/CLOSE_EDIT_DOCUMENT_MODAL';

export const initialState = {
  isLoading: false,
  isRetrievingDocuments: false,
  documentTypes: [],
  actionType: null,
  summary: {},
  showViewDocumentModal: false,
  showEditDocumentModal: false,
  activeViewDocument: null,
  activeEditDocument: null,
  dynamicDocuments: [],

  // showDocumentModal: false,
  // activeDocument: null,
  // dynamicDocuments: {
  //   list: [],
  // },

};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: action.status,
      };
    case SET_PRODUCT_DOCUMENT_TYPE_LIST:
      return {
        ...state,
        documentTypeList: action.data,

      };

    case IS_RETRIEVING_DOCUMENTS:
      return {
        ...state,
        isRetrievingDocuments: action.data,
      };
    case SET_DOCUMENT_SUMMARY_LIST:
      return {
        ...state,
        summary: {
          [action.data.documentTypeId]: action.data.summary,
        },
      };
    case VIEW_DOCUMENT:
      return {
        ...state,
        showViewDocumentModal: true,
        actionType: action.data.action,
        activeViewDocument: action.data.document,
      };
    case EDIT_DOCUMENT:
      return {
        ...state,
        showEditDocumentModal: true,
        actionType: action.data.action,
        activeEditDocument: action.data.document,
      };
    case CLOSE_VIEW_DOCUMENT_MODAL:
      return {
        ...state,
        showViewDocumentModal: false,
        activeViewDocument: null,
      };

    case CLOSE_EDIT_DOCUMENT_MODAL:
      return {
        ...state,
        showEditDocumentModal: false,
        activeEditDocument: null,
      };

    case SET_DOCUMENT_SCHEME_LIST:
      return {
        ...state,
        schemeList: action.data,

      };
    // case VIEW_OR_EDIT_DOCUMENT:
    //   return {
    //     ...state,
    //     showDocumentModal: true,
    //     actionType: action.data.action,
    //     activeDocument: action.data.document,
    //
    //   };
    case CLOSE_DOCUMENT_MODAL:
      return {

        ...state,
        showDocumentModal: false,
        activeDocument: null,

      };
    case CLEAR_DOCUMENT_MANAGEMENT_DATA:
      return {
        ...state,
        docTypeList: [],
        showDocumentModal: false,
        activeDocument: null,
        insurerList: [],

      };
    case SET_DYNAMIC_DOCUMENTS_LIST:
      return {
        ...state,
        dynamicDocuments: {
          ...state.dynamicDocuments,
          [action.data.productId]: {
            list: action.data.data,
          },
        },
      };
    case CLEAR_DYNAMIC_DOCUMENT_DATA:
      return {
        ...state,
        dynamicDocuments: {
          ...state.dynamicDocuments,
          list: [],
        },
      };
    case CLEAR_DATA:
      return initialState;
    default:
      return state;
  }

}

import React from 'react';

import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import {
  Field,
  FieldArray,
  Form,
  FormSection,
  getFormValues,
  reduxForm
} from 'redux-form';
import UploadModalComponent, {
  UploadModal
} from '../../../../common/components/Upload/UploadModal';
import {
  Box,
  ButtonBar,
  FormGroupRow,
  HorizontalFormControl,
  InfoButton
} from '../../../../common/components';
import TextArea from '../../../content/components/TextArea';
import { Col, ControlLabel, FormGroup, Image } from 'react-bootstrap';
import SelectInsurer from '../../../../common/components/Selects/SelectInsurer';
import { Button } from '../../../../common/components/Button/Button';
import { openModal } from '../../../../common/components/Modal/ModalRedux';
import { clearFiles } from '../../../../common/components/Upload/UploadRedux';
import SelectChannel from '../../../../common/components/Selects/SelectChannel';
import {
  ProductSchemeGroupFieldArray
} from './components/ProductSchemeGroupFieldArray/ProductSchemeGroupFieldArray';
import { DynamicDocument } from './components/DynamicDocuments/DynamicDocuments';
import './CloneProductForm.scss';
import { format, required } from 'redux-form-validators';

const LABEL_SIZE = 2;
const ALLOWED_CONTENT_OPTIONS = [
  {
    label: 'Schedule',
    value: 'travel-certificate'
  },
  {
    label: 'Cover Letter',
    value: 'policy-holder-letter'
  },
  {
    label: 'Quotation',
    value: 'travel-quotation'
  },
  {
    label: 'Quote Letter',
    value: 'quotation-letter'
  }
];

const LOGO_MODAL_IDENTIFIER = 'LOGO_MODAL';


const CloneProductForm = ({
  handleSubmit,
  submitting,
  dispatch,
  change,
  onSave,
  formValues,
  onUpload,
  onClearFiles
}) => {
  //const [savedProductName, setSavedProductName] = useState()
  const test = useSelector((state) => state.upload.files?.[0]);

  const handleUpload = () => {
    onUpload(UploadModal.MODAL_IDENTIFIER);
  };

  const handleLogoUpload = () => {
    onUpload(LOGO_MODAL_IDENTIFIER);
  };

  const handleUploadComplete = () => {
    dispatch(change('data.relationships.icon_file.data', test));
    onClearFiles();
  };

  const handleLogoUploadComplete = () => {
    dispatch(change('data.relationships.logo_file.data', test));
    onClearFiles();
  };

  const iconUrl = formValues?.data?.relationships?.icon_file?.data?.attributes?.url;
  const logoUrl = formValues?.data?.relationships?.logo_file?.data?.attributes?.url;
  const documents = formValues?.data?.attributes?.dynamic_documents;

  const availableOptions = ALLOWED_CONTENT_OPTIONS?.filter((option) => {
    return !documents?.find((doc) => doc.key === option.value);
  });

  return (
    <Box>
      <Form onSubmit={handleSubmit(onSave)} className={'clone-product-form'}>
        <h2>Product Information </h2>
        <FormGroupRow
          label={'Product Icon'}
          infoButton={<InfoButton
            content={'This icon displays on the Get a Quote page'}/>}
        >
          {iconUrl && (
            <Image src={iconUrl} responsive className={'product-icon-img'}/>
          )}
          <Button
            type="button"
            bsStyle="primary"
            className="pull-right pad-right"
            handleClick={handleUpload}
            disabled={submitting}
          >
            {submitting ? (
              <i className="fa fa-cog fa-spin"/>
            ) : (
              <i className="fa fa-upload"/>
            )}{' '}
            Add Product Icon
          </Button>
        </FormGroupRow>
        <FormGroupRow
          label={'Product Logo'}
          infoButton={<InfoButton
            content={'This logo displays on the documents and emails'}/>}
        >
          {logoUrl && (
            <Image src={logoUrl} responsive className={'product-icon-img'}/>
          )}
          <Button
            type="button"
            bsStyle="primary"
            className="pull-right pad-right"
            handleClick={handleLogoUpload}
            disabled={submitting}
          >
            {submitting ? (
              <i className="fa fa-cog fa-spin"/>
            ) : (
              <i className="fa fa-upload"/>
            )}{' '}
            Add Logo
          </Button>
        </FormGroupRow>
        <Field
          name={'data.relationships.insurer.data.id'}
          label={'Insurer'}
          component={SelectInsurer}
          labelSize={LABEL_SIZE}
          validate={required()}
        />
        <Field
          name="data.relationships.channels.data"
          label="Channels"
          multi
          object
          component={SelectChannel}
          labelSize={LABEL_SIZE}
          filters={'?filter[channel_type]=B2B,DIR,AGG'}
        />
        <FormSection name={'data.attributes'}>
          <Field
            name={'name'}
            label={'Product Name'}
            component={HorizontalFormControl}
            labelSize={LABEL_SIZE}
            validate={required()}
          />
          <Field
            name={'code'}
            label={'Product Code'}
            component={HorizontalFormControl}
            labelSize={LABEL_SIZE}
            validate={[required(), format({
              with: /^[a-z]+$/i,
              message: 'Invalid format'
            })]}
          />
          <FormGroup>
            <Col sm={12}>
              <ControlLabel>
                Summary
              </ControlLabel>
            </Col>
          </FormGroup>
          <Field
            name={'summary'}
            component={TextArea}
          />
          <FormGroup>
            <Col sm={12}>
              <ControlLabel>
                Description
              </ControlLabel>
            </Col>
          </FormGroup>
          <Field
            name={'description'}
            label={'Product Code'}
            component={TextArea}
            labelSize={LABEL_SIZE}
          />
        </FormSection>
        <h2>Scheme Information </h2>
        <FieldArray
          name={'data.attributes.scheme_groups'}
          component={ProductSchemeGroupFieldArray}
          labelSize={LABEL_SIZE}
        />
        <h2>Contents
          <InfoButton
          content={'These contents will be copied to the new product'}/>
        </h2>
        <DynamicDocument availableOptions={availableOptions}/>
        <ButtonBar>
          <Button
            type="submit"
            bsStyle="primary"
            className="pull-right"
            isLoading={submitting}
            label="Clone Product"
          />
        </ButtonBar>
      </Form>
      <UploadModalComponent
        onComplete={handleUploadComplete}
        accept="image/svg+xml"
        maxFiles={1}
      />
      <UploadModalComponent
        onComplete={handleLogoUploadComplete}
        modalIdentifier={LOGO_MODAL_IDENTIFIER}
        accept="image/*"
        maxFiles={1}
      />
    </Box>
  );
};

const getInitialValues = (productId, productName, productSummary, productDescription, schemeGroups) => {

  const schemeDetails = schemeGroups?.map((schemeGroup) => {

    const schemes = schemeGroup?.attributes?.scheme_details?.map((scheme) => {
      return {
        cover_level: scheme.cover_level,
        prefix: '',
        name: scheme.name,
        metadata: {
          assistance_handlers: [],
          claims_handlers: [],
          sms_message: '',
        },
        quote_type: scheme.quote_type,
        renews: scheme.renews,
        scheme_type: scheme.scheme_type,
      };
    });
    return {
      id: schemeGroup.id,
      name: schemeGroup?.attributes?.name,
      group_key: schemeGroup?.attributes?.group_key,
      scheme_details: schemes
    };
  });

  return {
    data: {
      attributes: {
        name: productName,
        code: '',
        summary: productSummary,
        description: productDescription,
        scheme_groups: schemeDetails,
        assign_to_current_user: true,
        contents: [
          {
            key: 'travel-certificate',
            name: `${productName} Insurance Schedule`,
          },
          {
            key: 'policy-holder-letter',
            name: `${productName} Cover Letter`,
          },
          {
            key: 'travel-quotation',
            name: `${productName} Saved Quote`,
          },
          {
            key: 'quotation-letter',
            name: `${productName} Quotation Letter`,
          },
          {
            key: 'medical-declaration',
            name: `${productName} Medical Declaration`,
          },
          {
            key: 'travel-medical-declaration-questions',
            name: `${productName} Medical Declaration Questions`,
          },
          {
            key: 'wl-quote-single-scheme',
            name: `${productName} Single Scheme Quote Email`,
          },
          {
            key: 'wl-quote',
            name: `${productName} Multi-Scheme Scheme Quote Email`,
          },
          {
            key: 'wl-policy',
            name: `${productName} Purchase Email`,
          }
        ]
      },
      relationships: {
        insurer: {
          data: {
            id: ''
          }
        },
        icon_file: {
          data: {
            id: ''
          }
        },
        logo_file: {
          data: {
            id: ''
          }
        }
      },
    },
  };
};

const FORM_NAME = 'CloneProductForm';
export default compose(
  connect(
    (state, ownProps) => (
      {
        formValues: getFormValues(FORM_NAME)(state),
        initialValues: getInitialValues(ownProps.productId, ownProps.productName, ownProps.productSummary, ownProps.productDescription, ownProps.schemeGroups),
      }
    ),
    {
      onUpload: openModal,
      onClearFiles: clearFiles,
    }
  ),
  reduxForm({
    form: FORM_NAME
  })
)(CloneProductForm);

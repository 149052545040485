import React, { Component } from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { connect } from 'react-redux';
import { ControlLabel } from 'react-bootstrap';
import { FormattedDate } from 'react-intl';
import { Box, DataTable } from '../../../common/components';
import { applyFilter } from '../../../common/components/DataTable/redux/dataTableActions';
import { reduxForm, formValueSelector } from 'redux-form';
import { push } from 'react-router-redux';
import StatusFilter from '../../policy/components/StatusFilter/StatusFilter';
import './styles.scss';

/**********************
 Print List DataTable
 **********************/

class PrintList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectType: '',
    };
    this.handleSelectType = this.handleSelectType.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
  }

  formatDate(cell) {
    if (!cell) {
      return;
    }
    return (
      <span>
        <FormattedDate value={new Date(cell)} />
      </span>
    );
  }

  handleSelectType(option) {
    this.props.dispatch(applyFilter('print-jobs', 'filter[state]', option.value));
  }

  handleRowClick(row) {
    this.props.dispatch(push('/policies/print-jobs/' + row.id));
  }

  render() {
    return (
      <div>
        <Box>
          <div className="row">
            <div className="col-sm-4">
              <ControlLabel className="filter-label">Status</ControlLabel>
              <StatusFilter onChange={this.handleSelectType} printJobs />
            </div>
          </div>

          <DataTable
            source="/print-jobs"
            name="print-jobs"
            showTotal
            onRowSelect={this.handleRowClick}
          >
            <TableHeaderColumn dataField="id" isKey hidden>
              ID
            </TableHeaderColumn>
            <TableHeaderColumn dataField="reference">Reference</TableHeaderColumn>
            <TableHeaderColumn
              dataField="input"
              dataFormat={(input) => input.type}
              dataSort={false}
              width={'30%'}
            >
              Type
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="started_at"
              dataSort={false}
              dataFormat={this.formatDate}
            >
              Started at
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="stopped_at"
              dataSort={false}
              dataFormat={this.formatDate}
            >
              Stopped at
            </TableHeaderColumn>
            <TableHeaderColumn dataField="state" dataSort={false}>
              <span>Status</span>
            </TableHeaderColumn>
          </DataTable>
        </Box>
      </div>
    );
  }
}

const FORM_NAME = 'PrintList';
const form = reduxForm({ form: FORM_NAME })(PrintList);
const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (state) => {
  const values = selector(state, 'meta', 'data');
  return {
    formValues: values,
    stats: state.printJobs.stats,
    dataTables: state.dataTables,
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(form);

import { Sticky, StickyContainer } from 'react-sticky';
import { Button } from '../../../../../../common/components';
import { ControlLabel } from 'react-bootstrap';
import displayAccountAmount from '../../../../../../helpers/displayAccountAmount';
import { Field } from 'redux-form';
import DatePickerDob from '../../../../../../common/components/DatePicker/DatePickerDob';

import React from 'react';
import MakePaymentButton from '../../../MakePaymentButton/MakePaymentButton';
import MakeAdjustmentButton from '../../../MakeAdjustmentButton/MakeAdjustmentButton';
import MakeReceiptButton from '../../../MakeReceiptButton/MakeReceiptButton';



const Sidebar = ({

  accountId,
  accountType,
  matchedTransactions,
  matchedPremiums,
  unmatchedTransactions,
  unmatchedPremiums,
  onSelectAll,
  onDeselectAll,
  onReset,
  onPostAdjustment,
  onSavePaymentsAndReceipts,
  onMakePayment,
  isSubmitting,
  difference,
  transactionFilter,
})  => {




  // const accountType = resource.data.attributes.account_type;
  // const difference = this.difference();
  const matchedEntries = matchedTransactions.length + matchedPremiums.length;
  const unmatchedEntries = unmatchedTransactions.length + unmatchedPremiums.length;
  // const totalEntries = matchedEntries + unmatchedEntries;

  return (
    <StickyContainer className="matching-sidebar">
      <Sticky>
        {() => (
          <>
            <div className="matched-buttons">
              {(unmatchedEntries > 0) && (
                <Button
                  label="Select All"
                  bsStyle={'primary'}
                  className="matching-sidebar-btn"
                  handleClick={onSelectAll}
                  isLoading={isSubmitting}
                  block
                />
              )}
              {(matchedEntries > 0) && (
                <Button
                  label="Deselect All"
                  className="matching-sidebar-btn"
                  bsStyle={'primary'}
                  handleClick={onDeselectAll}
                  isLoading={isSubmitting}
                  block
                />
              )}
            </div>
            <div className="matched-stats">
              <ControlLabel>Payments/Receipts</ControlLabel>
              {matchedTransactions.length || 'None'}
            </div>
            <div className="matched-stats">
              <ControlLabel>Premiums</ControlLabel>
              {matchedPremiums.length || 'None'}
            </div>
            <div className="matched-stats">
              <ControlLabel>Difference</ControlLabel>
              {displayAccountAmount(difference?.difference)}
            </div>
            {difference.balancesMatch && (
              <div className="matched-stats">
                <div className="matched-buttons">
                  <Field
                    name="date"
                    component={DatePickerDob}
                  />
                  <Button
                    className="match-btn"
                    type="submit"
                    bsStyle="success"
                    label="Match"
                    isLoading={isSubmitting}
                    block
                  />
                </div>
              </div>
            )}
            <div className="matched-buttons">
              {(difference.difference).toFixed(2) < 0.0 && (
                <MakePaymentButton
                  label="Pay"
                  matchedPremiums={matchedPremiums}
                  matchedTransactions={matchedTransactions}
                  amount={difference.difference * -1}
                  onMakePayment={onMakePayment}
                  block
                />
              )}

              <MakeAdjustmentButton
                label="Adjustment"
                accountId={accountId}
                amount={difference.difference}
                matchedTransactions={matchedTransactions}
                matchedPremiums={matchedPremiums}
                submitting={isSubmitting}
                onPostAdjustment={onPostAdjustment}
                block
              />


              <MakeReceiptButton
                label={accountType === 'creditor' ? 'payment' : 'receipt'}
                accountId={accountId}
               // resource={resource}
                amount={difference.difference || 0}
                onSavePaymentsAndReceipts={onSavePaymentsAndReceipts}
                transactionType={difference.difference < 0 ? 'payment' : 'receipt'}
                matchedTransactions={matchedTransactions}
                matchedPremiums={matchedPremiums}
                filters={transactionFilter}
                submitting={isSubmitting}
                block
              />

              {(matchedEntries > 0) && (
                <Button
                  label="Reset"
                  className="reset-button matching-sidebar-btn"
                  handleClick={onReset}
                  isLoading={isSubmitting}
                  block
                />
              )}



            </div>
          </>
        )}
      </Sticky>

    </StickyContainer>
  );
};

export default Sidebar;

import React, { Component } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { Form, ControlLabel, Row, Col } from 'react-bootstrap';
import {
  TextField,
  CheckboxGroup,
  SelectField,
} from '../../../../common/components/ReduxFormField';
import { Box, Button } from '../../../../common/components';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import { get } from 'lodash';
import './styles.scss';
import selectTheme, { styles } from '../../../../helpers/selectTheme';

const FORM_NAME = 'brokerCommissionForm';

class BrokerCommissionForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    resource: PropTypes.object.isRequired,
    formValues: PropTypes.object,
    brokerLabel: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = { enableAll: true };
  }

  handleCommissionDefault(option) {
    const productMap = {};
    this.props.broker.allProducts.data.map((product) => {
      productMap[product.id] = product.attributes.product_code;
    });

    get(this.props.formValues, 'meta.commissions', []).map((commission, i) => {
      const defaultRates = {};
      this.defaultCommissions(productMap[commission.product]).map((defaultRate) => {
        defaultRates[defaultRate.label] = defaultRate.value;
      });

      this.props.dispatch(
        this.props.change(
          'meta.commissions[' + i + '].rate',
          defaultRates[option.value],
        ),
      );
    });
  }

  handleEnableAll() {
    this.setState({ enableAll: !this.state.enableAll });

    get(this.props.formValues, 'meta.commissions', []).map((commission, i) => {
      this.props.dispatch(
        this.props.change('meta.commissions[' + i + '].enabled', this.state.enableAll),
      );
    });
  }

  // DEFAULT COMMISSIONS hardcoded - this can be placed on the product metadata
  defaultCommissions(productCode) {
    const commissionDefaults = [
      { label: 'Level one', value: 20 },
      { label: 'Level two', value: 25 },
      { label: 'Level three', value: 30 },
    ];

    const productCommissionDefaults = {
      TPL: [
        { label: 'Level one', value: 25 },
        { label: 'Level two', value: 27.5 },
        { label: 'Level three', value: 30 },
      ],
    };

    return productCommissionDefaults[productCode] || commissionDefaults;
  }

  render() {
    const { change, dispatch, formValues, handleSubmit, submitting } = this.props;
    const resource = this.props.resource.data.attributes;
    const products = this.props.broker.allProducts;

    return (
      <div>
        <Box>
          <Row>
            <Col sm={3} componentClass={ControlLabel}>
              Enable All
            </Col>
            <Col sm={2}>
              <div className="radio-container inline enable-all-checkbox">
                <div className="form-radio">
                  <input
                    type="checkbox"
                    id="enableAll"
                    onChange={::this.handleEnableAll}
                    checked={!this.state.enableAll}
                  />
                  <label htmlFor="enableAll" />
                </div>
                <div className="radio-label" />
              </div>
            </Col>
            <Col sm={3} componentClass={ControlLabel}>
              Default Commission Level
            </Col>
            <Col sm={4}>
              <Select
                styles={styles}
                theme={(theme) => selectTheme(theme)}
                onChange={(option) => this.handleCommissionDefault(option)}
                options={this.defaultCommissions().map((commissionDefault) => {
                  return { label: commissionDefault.label, value: commissionDefault.label };
                })}
              />
            </Col>
          </Row>
        </Box>

        <Box>
          <Form onSubmit={handleSubmit} autoComplete="off">
            <h2 className="resource-name">
              {resource.trading_name} {this.props.brokerLabel}
            </h2>
            <Row className="row commission-headings">
              <Col sm={6} componentClass={ControlLabel}>
                Product
              </Col>
              <Col sm={2} componentClass={ControlLabel}>
                Enabled
              </Col>
              <Col sm={4} className="text-center">
                <ControlLabel>Commission Rate</ControlLabel>
              </Col>
            </Row>

            {products &&
              products.data.map((product, i) => {
                return (
                  <Row className="commission-row" key={i}>
                    <Col sm={6} componentClass={ControlLabel}>
                      {product.attributes.name}
                    </Col>
                    <Col sm={2}>
                      <Field
                        name={'meta.commissions[' + i + '].enabled'}
                        labelSize={1}
                        inline
                        options={[{ label: '', value: true }]}
                        component={CheckboxGroup}
                      />
                    </Col>
                    <Col sm={4}>
                      <Row>
                        <Col xs={6}>
                          <SelectField
                            options={this.defaultCommissions(
                              product.attributes.product_code,
                            )}
                            input={{
                              value: get(
                                formValues,
                                'meta.commissions[' + i + '].rate',
                                '',
                              ),
                              onChange: (value) => {
                                dispatch(
                                  change('meta.commissions[' + i + '].rate', value),
                                );
                              },
                            }}
                          />
                        </Col>
                        <Col xs={6}>
                          <Field
                            name={'meta.commissions[' + i + '].rate'}
                            component={TextField}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              })}
            <Row>
              <Col xs={12}>
                <InternalButtonBar
                  buttons={[
                    {
                      permissions: ['broker.edit'],
                      button: (
                        <Button
                          className="pull-right"
                          type="submit"
                          bsStyle="primary"
                          label="Save Broker Commissions"
                          isLoading={submitting}
                        />
                      ),
                    },
                  ]}
                />
              </Col>
            </Row>
          </Form>
        </Box>
      </div>
    );
  }
}

const form = reduxForm({ form: FORM_NAME })(BrokerCommissionForm);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  const initialValues = {
    meta: {
      commissions: [],
    },
  };

  const values = selector(state, 'meta[0].rate', 'meta');

  const getCommission = (productId) => {
    let commission = null;
    props.commissions.map((item) => {
      if (item.relationships.product.data.id === productId) {
        commission = item;
      }
    });
    return commission;
  };

  state.broker.allProducts.data.map((product) => {
    const commission = getCommission(product.id);
    initialValues.meta.commissions.push(
      commission
        ? {
            enabled: true,
            rate: commission.attributes.rate,
            product: commission.relationships.product.data.id,
          }
        : {
            enabled: false,
            rate: '',
            product: product.id,
          },
    );
  });

  return {
    initialValues,
    formValues: values,
    broker: state.broker,
  };
};

export default connect(mapStateToProps)(form);

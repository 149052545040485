import React from 'react';

export const roles = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95 95">
    <title>Icons</title>
    <path d="M74.09,75.57A4.43,4.43,0,0,1,69.66,80H25.34a4.43,4.43,0,0,1-4.43-4.43V49a4.43,4.43,0,0,1,4.43-4.43h1.48V35.68a20.68,20.68,0,1,1,41.36,0v8.86h1.48A4.43,4.43,0,0,1,74.09,49Zm-14.77-31V35.68a11.82,11.82,0,0,0-23.64,0v8.86Z" />
  </svg>
);

export default roles;

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { get, flow } from 'lodash';
import AsyncSelect from 'react-select/lib/Async';
import Axios from 'axios';
import { actions as selectMenuActions } from './SelectMenuRedux';
import { bindActionCreators } from 'redux';
import selectTheme from '../../../helpers/selectTheme';
import getIncludedResource from '../../../helpers/getIncludedResource';

const CancelToken = Axios.CancelToken;

const AsyncSelectMenu = ({
  id,
  input,
  meta,
  dispatch,
  disabled,
  placeholder,
  showError,
  errorMessage,
  onChangeCallback,
  url,
  filters,
  preSelectedOption,
  filterName = 'name',
  sort,
  labelPath = 'attributes.name',
  optionValuePath,
  includes,
  extraIncludedNames,
  ...props
}) => {
  const getOptionLabel = (option) => {
    return labelPath ? get(option, labelPath) : 'n/k';
  };

  const getOptionValue = (option) => {
    return optionValuePath ? get(option, optionValuePath, option) : option;
  };

  const getIncluded = (option, included) => {
    if (!extraIncludedNames) {
      return {};
    }


    return extraIncludedNames.reduce((carry, extraIncludedName) => {
      carry[extraIncludedName] = getIncludedResource(option, included, extraIncludedName);
      return carry;
    },{});


  };

  const compareOptions = (optionA, optionB) => {
    if (optionValuePath) {
      return getOptionValue(optionA) === getOptionValue(optionB);
    }
    return optionA.id === optionB.id;
  };

  const [options, setOptions] = useState(
    preSelectedOption
      ? [{ label: getOptionLabel(preSelectedOption), value: preSelectedOption }]
      : null,
  );
  const [selectedOption, setSelectedOption] = useState();
  const [cancelTokens, setCancelTokens] = useState({});

  const handleOnBlur = () => {
    if (input && input.onBlur) {
      input.onBlur(input.value);
    }
  };

  const handleOnChange = (option) => {
    if (option) {
      setSelectedOption(option.value);
      if (input) {
        input.onChange(option.value);
      }
      if (onChangeCallback) {
        onChangeCallback(option);
      }
    } else {
      input ? input.onChange(null) : null;
      if (onChangeCallback) {
        onChangeCallback(null);
      }
    }
  };

  const getSuggestions = (
    searchTerm,
    queryUrl,
    id,
    dispatch,
    filterName,
    sort,
    labelKeys,
    includes = '',
  ) => {
    let token = cancelTokens[id];

    if (token) {
      token();
    }

    const requestCancelToken = new CancelToken(function executor(c) {
      token = c;
    });
    setCancelTokens({
      ...cancelTokens,
      [id]: token,
    });

    let searchUrl = queryUrl;
    const joinChar = (url) => {
      return url.includes('?') ? '&' : '?';
    };

    searchUrl += includes;
    if (filterName && searchTerm) {
      searchUrl += `${joinChar(searchUrl)}&filter[${filterName}]=${searchTerm}`;
    }
    return Axios.get(searchUrl, { cancelToken: requestCancelToken })
      .then((response) => {
        const newOptions = response.data.data.map((source) => {
          const extraInfo =  getIncluded(source, response?.data?.included ?? []) ;
          return {
            label: getOptionLabel(source),
            value: getOptionValue(source),
            ...extraInfo
          };
        });
        if (preSelectedOption) {
          newOptions.push({
            label: getOptionLabel(preSelectedOption),
            value: getOptionValue(preSelectedOption),
          });
        }
        setOptions(newOptions);
        return newOptions;
      });
  };

  const getUrl = () => {
    const joinChar = (url) => {
      return url.includes('?') ? '&' : '?';
    };

    let urlFilters = filters;
    if (filters && !Array.isArray(filters)) {
      urlFilters = Object.keys(filters).map((key) => ({
        type: key,
        match: filters[key]
      }));
    }

    const uri = urlFilters
      ? urlFilters.reduce((uri, filter) => {
        const filterName = filter.type;
        const filterValue = filter.match;

        uri += `${joinChar(uri)}filter[${filterName}]=${filterValue}`;
        return uri;
      }, url)
      : url;
    return uri + (sort ? `${joinChar(uri)}sort=${sort}` : '');
  };

  const getDefaultValue = () => {
    if (input) {
      if (selectedOption) {
        const alreadyExists = options.find((opt) =>
          compareOptions(opt.value, selectedOption),
        );
        if (!alreadyExists) {
          options.push({
            value: selectedOption,
            label: getOptionLabel(selectedOption),
          });
        }
      }

      const defaultOption =
        getOptionValue(input.value) && options
          ? options.find((option) => compareOptions(option.value, input.value))
          : null;

      return defaultOption ? defaultOption : null;
    }
  };
  const error = meta && meta.error;
  const touched = meta && meta.touched;
  const showErrorMsg = showError || (touched && meta);
  const errorMsg = errorMessage || error;
  return (
    <div className={'select-menu'}>
      <AsyncSelect
        defaultOptions
        isDisabled={disabled}
        loadOptions={(inputValue) =>
          getSuggestions(
            inputValue,
            getUrl(),
            id,
            dispatch,
            filterName,
            sort,
            labelPath,
            includes,
          )
        }
        theme={(theme) => selectTheme(theme)}
        onBlur={handleOnBlur}
        onChange={(option) => handleOnChange(option)}
        placeholder={placeholder || 'Type...'}
        value={getDefaultValue()}
        {...props}
      />
      {showErrorMsg && errorMsg && <span className="input-error">{errorMsg}</span>}
    </div>
  );
};

export default flow([
  connect(
    null,
    (dispatch) => ({
      dispatch,
      selectMenuActions: bindActionCreators(selectMenuActions, dispatch),
    }),
  ),
])(AsyncSelectMenu);

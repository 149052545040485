import * as actions from './campaignReducer';
import Axios from 'axios';
import { push } from 'react-router-redux';
import queryString from '../../../helpers/queryString';
import handleErrorResponse from '../../../helpers/handleErrorResponse';
import { ADD_RESOURCE } from '../../layout/redux/breadcrumb';

export const defaultIncludes = [];

export function getBrokers() {
  return (dispatch) => {
    const endPoint = 'brokers?page[limit]=10';
    return Axios.get(endPoint)
      .then((response) => {
        dispatch({
          type: actions.SET_BROKERS,
          data: response.data,
        });
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function searchBrokers(searchTerm) {
  return (dispatch) => {
    const endPoint = `brokers?page[limit]=10&filter[name]=${searchTerm}`;
    return Axios.get(endPoint)
      .then((response) => {
        dispatch({
          type: actions.SET_BROKERS,
          data: response.data,
        });
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function getCampaigns(id, includes = defaultIncludes) {
  return (dispatch) => {
    let endpoint = 'campaigns/' + id + '?include=brokers,channels,product';
    endpoint += queryString(includes);

    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: actions.SET_CAMPAIGNS,
          data: response.data,
        });
        dispatch({
          type: ADD_RESOURCE,
          id: response.data.data.id,
          name: response.data.data.attributes.name,
        });
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function updateCampaign(resource, includes = defaultIncludes) {
  return (dispatch) => {
    const id = resource.data.id;
    let endpoint = 'campaigns/' + id + '?include=brokers,channels,product';
    endpoint += queryString(includes);

    return Axios.patch(endpoint, resource)
      .then((response) => {
        dispatch({
          type: actions.SET_CAMPAIGNS,
          data: response.data,
        });
        dispatch({
          type: ADD_RESOURCE,
          id: response.data.data.id,
          name: response.data.data.attributes.name,
        });
        dispatch(push('/admin/campaigns/'));
        return true;
      })
      .catch((error) => {
        handleErrorResponse(error, 'There was an error updating the campaign');
      });
  };
}

export function isSaving(status) {
  return {
    type: actions.IS_SAVING,
    status,
  };
}

export function createCampaign(resource) {
  return (dispatch) => {
    dispatch(isSaving(true));
    return Axios.post('campaigns?include=brokers,channels,product', resource)
      .then((response) => {
        dispatch(isSaving(false));
        dispatch(push('/admin/campaigns/' + response.data.data.id));
        return true;
      })
      .catch((error) => {
        dispatch(isSaving(false));
        handleErrorResponse(error, 'There was an error creating the campaign');
      });
  };
}

export const IS_LOADING = 'socrates-online/scheme-management/IS_LOADING';
export const IS_LOADING_RATES = 'socrates-online/scheme-management/IS_LOADING_RATES';
export const IS_VERSIONING = 'socrates-online/scheme-management/IS_VERSIONING';
export const IS_SUBMITTING = 'socrates-online/scheme-management/IS_SUBMITTING';
export const CLEAR_DATA = 'socrates-online/scheme-management/CLEAR_DATA';

export const SET_SCHEME_GROUP_DETAILS = 'socrates-online/scheme-management/SET_SCHEME_GROUP_DETAILS';

export const SET_SCHEME_LINT_FAILURES = 'socrates-online/scheme-management/SET_SCHEME_LINT_FAILURES';
export const SET_ANCILLARY_SCHEMES = 'socrates-online/scheme-management/SET_ANCILLARY_SCHEMES';


export const initialState = {


  isLoadingRates: {
    id: null,
    status: false,
  },
  isExporting: false,
  isSubmitting: false,
  isVersioning: {
    id: null,
    status: false
  },
  schemeGroupDetails: undefined,
  lintFailures: {
    critical: [],
    warning: []
  },
  // product: undefined,
  // products: [],
  // scheme: null,
  // schemeGroupList: null,
  // activeSchemeGroup: {
  //   single: null,
  //   annual: null,
  // },
  // activeScheme: {},
  // stats: {},
  // destinations: {},
  // documentManagement: {
  //   docTypeList: [],
  //   showDocumentModal: false,
  //   activeDocument: null,
  // },
  // dynamicDocuments: {
  //   list: [],
  // },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    // case IS_COPYING:
    //   return {
    //     ...state,
    //     isCopying: action.status,
    //   };
    case IS_LOADING_RATES:
      return {
        ...state,
        isLoadingRates: {
          id: action.data.id,
          status: action.data.status,
          message: action.data.message,
        },
      };
    case SET_SCHEME_LINT_FAILURES:
      return {
        ...state,
        lintFailures: action.data,
      };
    case IS_VERSIONING:
      return {
        ...state,
        isVersioning: {
          id: action.data.id,
          status: action.data.status,
        }
      };

    case SET_SCHEME_GROUP_DETAILS:
      return {
        ...state,
        schemeGroupDetails: action.data,
      };
    // case SET_PRODUCT_DOCUMENT_TYPE_LIST:
    //   return {
    //     ...state,
    //     documentManagement: {
    //       ...state.documentManagement,
    //       docTypeList: action.data,
    //     },
    //   };
    // case SET_DOCUMENT_SCHEME_LIST:
    //   return {
    //     ...state,
    //     documentManagement: {
    //       ...state.documentManagement,
    //       schemeList: action.data,
    //     },
    //   };
    // case VIEW_OR_EDIT_DOCUMENT:
    //   return {
    //     ...state,
    //     documentManagement: {
    //       ...state.documentManagement,
    //       showDocumentModal: true,
    //       actionType: action.data.action,
    //       activeDocument: action.data.document,
    //     },
    //   };
    // case CLOSE_DOCUMENT_MODAL:
    //   return {
    //     ...state,
    //     documentManagement: {
    //       ...state.documentManagement,
    //       showDocumentModal: false,
    //       activeDocument: null,
    //     },
    //   };
    // case CLEAR_DOCUMENT_MANAGEMENT_DATA:
    //   return {
    //     ...state,
    //     documentManagement: {
    //       docTypeList: [],
    //       showDocumentModal: false,
    //       activeDocument: null,
    //       insurerList: [],
    //     },
    //   };
    // case SET_DYNAMIC_DOCUMENTS_LIST:
    //   return {
    //     ...state,
    //     dynamicDocuments: {
    //       ...state.dynamicDocuments,
    //       list: action.data,
    //     },
    //   };
    // case CLEAR_DYNAMIC_DOCUMENT_DATA:
    //   return {
    //     ...state,
    //     dynamicDocuments: {
    //       ...state.dynamicDocuments,
    //       list: [],
    //     },
    //   };
    case CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
}


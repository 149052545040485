import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Form, getFormValues, reduxForm } from 'redux-form';
import { Box, ButtonBar } from '../../../../common/components';
import { Tab, Tabs } from 'react-bootstrap';
import { Button } from '../../../../common/components/Button/Button';
import { EditProductMedical } from './EditProductMedical/EditProductMedical';
import { EditProductDetails } from './EditProductDetails';

const EDIT = 1;
const MEDICAL_CONTENT = 2;
const EditProductForm = ({ onSave, handleSubmit, submitting }) => {
  const [activeTab, setActiveTab] = useState(EDIT);
  const handleTab = (key) => {
    setActiveTab(key);
  };


  return (
    <>
      <Form onSubmit={handleSubmit(onSave)}>
        <Tabs
          activeKey={activeTab}
          //unmountOnExit
          onSelect={handleTab}
        >
          <Tab
            eventKey={EDIT}
            id="enquiry-tabs"
            title={'Product Info'}
          >
            <Box className={'product-container'}>
              <EditProductDetails isSubmitting={submitting}/>
            </Box>
            <ButtonBar>

              <Button
                type={'submit'}
                bsStyle={'action'}
                className={'pull-right'}
              >
                Save
              </Button>
            </ButtonBar>
          </Tab>
          <Tab eventKey={MEDICAL_CONTENT}
               title={'Medical Content'}
          >
            <Box className={'product-container'}>
              <EditProductMedical/>
            </Box>
            <ButtonBar>

              <Button
                type={'submit'}
                bsStyle={'action'}
                className={'pull-right'}
              >
                Save
              </Button>
            </ButtonBar>

          </Tab>

        </Tabs>
      </Form>
    </>
  );
};

const FORM_NAME = ' EditProductForm';
export default compose(
  connect(
    (state, ownProps) => ({
      formValues: getFormValues(FORM_NAME)(state),
      initialValues: ownProps.resource,
      destroyOnUnmount: !module.hot,

    }),
  ),
  reduxForm({ form: FORM_NAME })
)(EditProductForm);


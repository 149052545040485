import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, DataTable } from '../../../../common/components';
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { FormControl, ControlLabel } from 'react-bootstrap';
import { push } from 'react-router-redux';
import { FormattedDate } from 'react-intl';

export default class RoleList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    role: PropTypes.object.isRequired,
  };

  formatDate(cell) {
    return <FormattedDate value={new Date(cell)} />;
  }

  handleRowClick(row) {
    this.props.dispatch(push('/admin/roles/' + row.id));
  }

  handleSearchUpdate(event) {
    this.props.dispatch(applyFilter('roles', 'filter[name]', event.target.value));
  }

  render() {
    return (
      <div>
        <Box>
          <div className="row">
            <div className="col-sm-5">
              <ControlLabel className="filter-label">Search</ControlLabel>
              <FormControl onChange={::this.handleSearchUpdate} />
            </div>
            <div className="col-sm-5" />
            <div className="col-sm-2" />
          </div>
        </Box>

        <Box>
          <DataTable
            source="/roles"
            name="roles"
            showTotal
            onRowSelect={this.handleRowClick.bind(this)}
          >
            <TableHeaderColumn dataField="id" isKey hidden />
            <TableHeaderColumn dataField="name" dataSort width={'150px'}>
              Name
            </TableHeaderColumn>
            <TableHeaderColumn dataField="description" dataSort width={'300px'}>
              Description
            </TableHeaderColumn>
          </DataTable>
        </Box>
      </div>
    );
  }
}

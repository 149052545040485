import * as actions from './dataTableReducer';
import Axios from 'axios';
import { debounce } from 'lodash';

const fetchDebounced = debounce((dispatch, name) => dispatch(fetchData(name)), 400);
const CancelToken = Axios.CancelToken;
let cancel;

export function clearData(name) {
  return {
    type: actions.CLEAR_DATA,
    table: name,
  };
}

export function initTable(name, source, perPage = 10) {
  return {
    type: actions.INIT_TABLE,
    table: name,
    perPage,
    source,
  };
}

export function setSource(name, source) {
  return {
    type: actions.SET_SOURCE,
    table: name,
    source,
  };
}

export function setData(name, data, source, currentPage = 1) {
  return {
    type: actions.SET_DATA,
    table: name,
    currentPage,
    source,
    data,
  };
}

export function setPreloadedData(name, data, source = '', currentPage = 1) {
  return {
    type: actions.SET_DATA,
    table: name,
    currentPage,
    source,
    data,
  };
}

export function applyFilter(name, type, value) {
  if (!value) {
    return removeFilter(name, type);
  }

  return (dispatch, getState) => {
    const state = getState().dataTables;
    if (getFilter(state, name, type) !== value) {
      dispatch(setFilters(name, type, value));

      const offsetFilter = 'page[offset]';
      if (type !== offsetFilter && getFilter(state, name, offsetFilter)) {
        dispatch(setFilters(name, offsetFilter, 0));
      }

      fetchDebounced(dispatch,name);
    }
  };
}

export function removeFilter(name, type) {
  return (dispatch) => {
    dispatch({
      type: actions.REMOVE_FILTER,
      table: name,
      filterType: type,
    });

    fetchDebounced(dispatch,name);
  };
}

export function getFilter(state, name, type) {
  const filter =
    state.tables[name] && state.tables[name].filters[type]
      ? state.tables[name].filters[type]
      : '';
  return filter ? filter : '';
}

export function refreshData(name) {
  return (dispatch) => {
    dispatch(fetchData(name));
  };
}

export function setFilters(name, type, value) {
  if (value === false) {
    value = 'false';
  }

  if (value === true) {
    value = 'true';
  }

  return {
    type: actions.SET_FILTERS,
    table: name,
    filterType: type,
    value,
  };
}

function beginFetch(name) {
  return {
    type: actions.FETCH_DATA,
    table: name,
  };
}

// function fetchError(name) {
//   return {
//     type: actions.FETCH_DATA_ERROR,
//     table: name,
//   };
// }

export function fetchData(name, customFilter) {
  if (cancel) {
    cancel();
  }

  return (dispatch, getState) => {
    const state = getState();
    let currentPage = 1;

    dispatch(beginFetch(name));
    dispatch(clearData(name));

    let dataTable = state.dataTables.tables[name];
    const source = dataTable.source;

    if (!source) {
      return;
    }

    let url = source;

    // add filters to the source url
    if (dataTable.filters) {
      let keys = Object.keys(dataTable.filters);

      if (keys.length) {
        keys.forEach((key, index) => {
          if (!index) {
            url += '?';
          }

          if (dataTable.filters[key] !== 0) {
            if (index) {
              url += '&';
            }
            url += key + '=' + dataTable.filters[key];
          }
        });
      }
    }

    if (dataTable.filters['page[offset]'] && dataTable.filters['page[limit]']) {
      currentPage +=
        parseInt(dataTable.filters['page[offset]']) /
        parseInt(dataTable.filters['page[limit]']);
    }

    if (url) {
      let endpoint = '';
      if (customFilter) {
        endpoint = url + customFilter;
      } else {
        endpoint = url;
      }

      return Axios
        .get(
          endpoint,
          {
            cancelToken: new CancelToken(function executor(c) {
              cancel = c;
            })
          }
        ).then((response) => {
          if (response) {
            return dispatch(setData(name, response.data, source, currentPage));
          }
        }).catch((error) => {
          console.error(error);
        });
    }
  };
}

import React, { useState } from 'react';
import SchemeManagementSectionContainer
  from '../../SchemeManagementSectionContainer/SchemeManagementSectionContainer';
import { Col, Row } from 'react-bootstrap';
import {
  Button,
  HorizontalFormControl,
  Icon,
  InfoLabel,
} from '../../../../../common/components';
import { Field } from 'redux-form';
import { CreatableSelectField } from '../../../../../common/components/ReduxFormField';
import BenefitTextField from './BenefitTextField';

const SubBenefitField = ({ isViewOnly, onRemove, input, index }) => {
  const [showMoreInfo, setShowMoreInfo] = useState(true);

  const handleRemoveBenefit = () => {
    onRemove(index);
  };

  const toggleShowMoreInfo = () => {
    setShowMoreInfo(!showMoreInfo);
  };

  const benefit = input.value;

  return (
    <Col xs={12}>
      <SchemeManagementSectionContainer
        isViewOnly={isViewOnly}
        subTitle={
          <div onClick={toggleShowMoreInfo}>
            {benefit.name ? benefit.name : 'New Benefit'}
            <span className={'remove-benefit pull-right'} title={'remove benefit'}>
              <Icon name={showMoreInfo ? 'minus' : 'plus'}/>
            </span>
          </div>
        }
      >
        {showMoreInfo && (
          <>
            <Row className={'section-item'}>
              <Col sm={12}>
                <Row>
                  <Col sm={12}>
                    <h4>Sub Benefit Details:</h4>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Field
                      label="Name:"
                      labelSize={4}
                      name={`${input.name}.name`}
                      component={isViewOnly ? InfoLabel : HorizontalFormControl}
                    />
                  </Col>
                  <Col sm={6}>
                    <Field
                      label="Excess:"
                      isClearable
                      labelSize={4}
                      name={`${input.name}.excess`}
                      component={isViewOnly ? InfoLabel : CreatableSelectField}
                      options={[
                        { label: 'Nil', value: 'nil' },
                        { label: 'Standard', value: 'standard' },
                        { label: 'Other', value: 'other' },
                      ]}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Field
                      label="Text:"
                      labelSize={4}
                      name={`${input.name}`}
                      isViewOnly={isViewOnly}
                      component={BenefitTextField}
                    />
                  </Col>
                </Row>

                {!isViewOnly && (
                  <Row className={'section-item'}>
                    <Col sm={12}>
                      <>
                        <Button handleClick={handleRemoveBenefit} className="pull-right">
                          Remove <Icon name={'trash'}/>
                        </Button>
                      </>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </>
        )}
      </SchemeManagementSectionContainer>
    </Col>
  );
};

export default SubBenefitField;

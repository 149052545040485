import React from 'react';
import cx from 'classnames';
import benefitByKey from '../../../../../../helpers/benefitByKey';
import BenefitsModal from '../../../BenefitsModal/BenefitsModal';
import { PremiumSelect } from './components/PremiumSelect/PremiumSelect';

export const QuoteRow = ({
  isMta,
  annualQuote,
  singleQuote,
  selectedSchemes,
  isAnnualSelected,
  onSchemeSelect
}) => {


  const displayQuote = isAnnualSelected ? annualQuote : singleQuote;

  const { scheme, errors, declines, referrals, benefits } = displayQuote ?? {};
  const { logo, cover_level: coverLevel } = scheme ?? {};


  const isTest = scheme.status === 'test';


  return (
    <div>
      <div
        className={
          cx('quoted-scheme wl-available-quotes-table', {
            'test-scheme': isTest
          })
        }
      >
        <div className="flex2 quote-logo-container ">
          <div className="quote-logo">
            <img src={logo} alt="scheme logo"/>
          </div>
        </div>
        <div className={'flex2 quote-value-container hide-query-4rd'}>
          <span className={'title-case'}>
            {coverLevel}
            {isTest && ' (Test)'}
          </span>

        </div>
        <div className="flex2 quote-value-container hide-query-4rd">
          <span className="benefit">
            {benefitByKey(benefits, 'Medical')}
           </span>
        </div>


        <div className="flex2 quote-value-container hide-query-2nd">
          <span className="benefit">
            {benefitByKey(benefits, 'Cancellation')}
          </span>
        </div>


        <div className="flex2 quote-value-container hide-query-1st">
          <span className="benefit">
            {benefitByKey(benefits, 'Baggage')}
          </span>
        </div>


        <div className="flex1 quote-value-container">
          {/* Benefits Icon */}
          <BenefitsModal
            scheme={scheme}
            benefits={benefits}
          />
        </div>
        <PremiumSelect
          isAnnualSelected={isAnnualSelected}
          isMta={isMta}
          onSchemeSelect={onSchemeSelect}
          annualQuote={annualQuote}
          singleQuote={singleQuote}
          selectedSchemes={selectedSchemes}
        />

      </div>

      <div className="">
        {referrals.length > 0 && (
          <div className="wl-quote-error-box">
            <ul>
              {referrals.map((referral, i) => {
                if (referral) {
                  return (
                    <li key={i}>
                      <strong>Referral:</strong> {referral.reason}
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        )}
        {!!declines?.length && (
          <div className="wl-quote-error-box">
            <ul>
              {declines.map((decline, i) => {
                if (decline) {
                  return (
                    <li key={i}>
                      <strong>Decline:</strong> {decline.reason}
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        )}
        {errors?.length > 0 && (
          <div className="wl-quote-error-box">
            <ul>
              {errors.map((error, i) => {
                return <li key={i}>{error}</li>;
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};



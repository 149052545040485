import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, DataTable, Button } from '../../../../common/components';
import {
  applyFilter,
  refreshData,
} from '../../../../common/components/DataTable/redux/dataTableActions';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { Col, FormGroup, ControlLabel, Row } from 'react-bootstrap';
import { FormattedDate, FormattedTime } from 'react-intl';
import {
  addToPrintJobs,
  removeFromPrintJobs,
  generateDocuments,
  sendCustomerLoginEmail,
  sendPolicyCancelledEmail,
  sendEndorsementsEmail,
  sendCustomerResetPasswordEmail,
} from '../../redux/policyActions';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import { policyStatuses } from '../../../../schemas/policy';
import { get, indexOf } from 'lodash';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import PrintDocumentsButton from '../PrintDocumentsButton/PrintDocumentsButton';
import selectTheme, { styles } from '../../../../helpers/selectTheme';
import Select from 'react-select';

export default class PolicyDocuments extends Component {
  constructor(props) {
    super(props);
    this.handleFilterUpdate = this.handleFilterUpdate.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleGenerateDocuments = this.handleGenerateDocuments.bind(this);
    this.handleCancellationEmail = this.handleCancellationEmail.bind(this);
    this.handleCustomerLogin = this.handleCustomerLogin.bind(this);
    this.handleCustomerResetPasswordEmail = this.handleCustomerResetPasswordEmail.bind(this);
    this.handleTogglePrintJobs = this.handleTogglePrintJobs.bind(this);
    this.setPrintQueueStatus(props.resource);
  }

  setPrintQueueStatus(resource) {
    this.inPrintQueue = resource.data.attributes.metadata.in_print_queue;
  }

  formatDate(cell) {
    return (
      <span>
        <FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/>
      </span>
    );
  }

  handleFilterUpdate(option) {
    this.props.dispatch(applyFilter('documents', 'filter[version]', option.value));
  }

  handleRowClick(row) {
    if (window) {
      window.open(row.file_path, '_blank');
    }

    return false;
  }

  handleGenerateDocuments() {
    const { resource, dispatch } = this.props;
    dispatch(
      generateDocuments(
        resource,
        resource.data.attributes.policy_number ? 'policies' : 'policies/applications',
        () => dispatch(refreshData('documents')),
      ),
    );
  }

  handleTogglePrintJobs() {
    const { dispatch, resource } = this.props;
    const func = this.inPrintQueue ? removeFromPrintJobs : addToPrintJobs;

    dispatch(
      func(
        resource,
        resource.data.attributes.policy_number ? 'policies' : 'policies/applications',
        () => dispatch(refreshData('documents')),
      ),
    );
    this.inPrintQueue = !this.inPrintQueue;
  }

  handleCustomerLogin() {
    const { resource, dispatch } = this.props;
    dispatch(
      sendCustomerLoginEmail(
        resource.data.attributes.policy_number ? 'policies' : 'policies/applications',
        resource.data.id,
      ),
    );
  }

  handleCancellationEmail() {
    const { resource, dispatch } = this.props;
    dispatch(sendPolicyCancelledEmail(resource.data.id));
  }

  handleEndorsementsEmail() {
    const { resource, dispatch } = this.props;
    dispatch(sendEndorsementsEmail(resource.data.id));
  }

  handleCustomerResetPasswordEmail() {
    const { resource, dispatch } = this.props;
    dispatch(
      sendCustomerResetPasswordEmail(
        resource.data.id,
        resource.data.attributes.policy_number ? 'policies' : 'policies/applications',
      ),
    );
  }

  buttons() {
    const { resource, isGeneratingDocs } = this.props;
    const type = resource.data.attributes.policy_number ? 'policy' : 'application';
    const customer = getIncludedResource(resource.data, resource.included, 'customer');
    const onPrintQueue = !!resource.data.attributes.metadata.in_print_queue;
    const address = customer.data.attributes.address;
    const canTogglePrintQueue = address.line1 && address.postcode;
    const productCode = get(resource, 'data.attributes.metadata.product_code', '');

    let buttons = [
      {
        orgTypes: ['owner', 'broker'],
        button: (
          <Button
            bsStyle="primary"
            className="pull-right"
            label="Generate New Documents"
            rightIcon="file-o"
            isLoading={isGeneratingDocs}
            handleClick={this.handleGenerateDocuments}
          />
        ),
      },
      {
        orgTypes: ['owner', 'broker'],
        button: (
          <PrintDocumentsButton
            label="Download Documents"
            className="pull-right"
            resource={resource}
            customer={customer}
            resourceType={
              resource.data.attributes.policy_number
                ? 'policies'
                : 'policies/applications'
            }
          />
        ),
      },
    ];

    if (canTogglePrintQueue) {
      buttons.push({
        orgTypes: ['owner'],
        button: (
          <Button
            bsStyle="primary"
            className="pull-right"
            label={(onPrintQueue ? 'Remove from' : 'Add to') + ' Print Job'}
            rightIcon="print"
            isLoading={isGeneratingDocs}
            handleClick={this.handleTogglePrintJobs}
          />
        ),
      });
    }

    if ((type === 'policy' || indexOf(['applied', 'referral approved'], resource.data.attributes.status) === -1) && productCode !== 'CSP') {
      buttons.push({
        orgTypes: ['owner', 'broker'],
        button: (
          <Button
            label="Send Reset Password Email"
            bsStyle="primary"
            className="pull-right"
            handleClick={this.handleCustomerResetPasswordEmail}
          />
        ),
      });
      buttons.push({
        orgTypes: ['owner', 'broker'],
        button: (
          <Button
            label="Send Customer Login Email"
            bsStyle="primary"
            className="pull-right"
            handleClick={::this.handleCustomerLogin}
          />
        )
      });

      if (resource.data.attributes.status === policyStatuses.cancelled) {
        buttons.push({
          orgTypes: ['owner', 'broker'],
          button: (
            <Button
              label="Send Policy Cancellation Email"
              bsStyle="primary"
              className="pull-right"
              handleClick={::this.handleCancellationEmail}
            />
          )
        });
      }
    }

    if (type === 'policy' && resource.data.attributes.version > 1  && productCode !== 'CSP') {
      buttons.push({
        orgTypes: ['owner', 'broker'],
        button: (
          <Button
            label="Send Endorsements Email"
            bsStyle="primary"
            className="pull-right"
            handleClick={::this.handleEndorsementsEmail}
          />
        )
      });
    }

    return buttons;
  }

  render() {
    const { reference, resource } = this.props;

    if (!resource.data.id) {
      return null;
    }

    return (
      <div>
        <Box>
          <h2 className="resource-name">{reference}</h2>
          <div className="row">
            <div className="col-sm-4 col-sm-offset-8">
              <FormGroup controlId="select-status">
                <Col componentClass={ControlLabel} sm={4}>
                  Versions
                </Col>
                <Col sm={8}>
                  <Select
                    theme={(theme) => selectTheme(theme)}
                    styles={styles}
                    onChange={(option) => this.handleFilterUpdate(option)}
                    options={[
                      { label: 'All', value: 'all' },
                      { label: 'Current', value: '' },
                    ]}
                    defaultValue={{ label: 'All', value: 'all' }}
                  />
                </Col>
              </FormGroup>
            </div>
          </div>
          <div className="hr transparent"/>
          {/* We don't want to send through anything more than 1 if this value is populated */}
          <DataTable
            waitForItems={
              get(resource, 'data.attributes.minimum_document_count', 0) > 0 ? 1 : 0
            }
            waitForItemsMessage="Please wait whilst we get your documents. If you have just issued a policy or saved a quote, then your documents are being generated in the background and could take up to a minute to show here."
            source="documents"
            autoFilters={[
              {
                type:
                'filter[' +
                (resource.data.attributes.policy_number
                  ? 'policy'
                  : 'policy_application') +
                ']',
                value: resource.data.id,
              },
            ]}
            name="documents"
            showTotal
            onRowSelect={this.handleRowClick.bind(this)}
          >
            <TableHeaderColumn dataField="id" isKey hidden/>
            <TableHeaderColumn dataField="display_name" dataSort>
              Name
            </TableHeaderColumn>
            <TableHeaderColumn dataField="version_number" dataSort width={'100px'}>
              Version
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="from_date"
              dataFormat={this.formatDate}
              dataSort
              width={'180px'}
            >
              Effective Date
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="created_at"
              dataFormat={this.formatDate}
              dataSort
              width={'180px'}
            >
              Created
            </TableHeaderColumn>
          </DataTable>
          <Row>
            <Col xs={12}>
              <InternalButtonBar buttons={this.buttons()}/>
            </Col>
          </Row>
        </Box>
      </div>
    );
  }
}

PolicyDocuments.propTypes = {
  dispatch: PropTypes.func.isRequired,
  resource: PropTypes.object.isRequired,
  reference: PropTypes.string.isRequired,
  isGeneratingDocs: PropTypes.bool,
};

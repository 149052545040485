import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box, Button, DataTable } from '../../../../common/components';
import {
  applyFilter,
  getFilter,
} from '../../../../common/components/DataTable/redux/dataTableActions';
import { SelectField } from '../../../../common/components/ReduxFormField';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { push } from 'react-router-redux';
import { FormattedDate } from 'react-intl';
import displayAccountAmount from '../../../../helpers/displayAccountAmount';
import { Row, Col, FormControl, ControlLabel } from 'react-bootstrap';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';

class AccountList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    dataTables: PropTypes.object.isRequired,
  };

  state = {
    accountType: '',
    suspended: '',
    sortBalances: '',
    sort: '',
  };

  componentWillMount() {
    this.setState({
      accountType: this.props.location.query.filter || '',
      suspended: this.props.location.query.suspended || '',
      sort: this.props.location.query.sort || '',
    });
  }

  formatDate(cell) {
    return <FormattedDate value={new Date(cell)} />;
  }

  formatLimit(cell) {
    return displayAccountAmount(cell, ' - ');
  }

  handleRowClick(row) {
    return this.props.dispatch(push('/accounts/ledger/' + row.id));
  }

  handleSearchUpdate(event) {
    this.props.dispatch(applyFilter('accounts', 'filter[name]', event.target.value));
  }

  handleSearchReferenceUpdate(event) {
    this.props.dispatch(applyFilter('accounts', 'filter[reference]', event.target.value));
  }

  render() {
    const { dataTables } = this.props;

    return (
      <div>
        <Box>
          <Row>
            <Col sm={3}>
              <ControlLabel className="filter-label">Reference</ControlLabel>
              <FormControl onChange={::this.handleSearchReferenceUpdate} />
            </Col>
            <Col sm={3}>
              <ControlLabel className="filter-label">Name</ControlLabel>
              <FormControl onChange={::this.handleSearchUpdate} />
            </Col>
            <Col sm={3}>
              <ControlLabel className="filter-label">Type</ControlLabel>
              <SelectField
                input={{
                  onChange: (value) =>
                    this.props.dispatch(applyFilter('accounts', 'filter[type]', value)),
                  value: getFilter(dataTables, 'accounts', 'filter[type]'),
                }}
                options={[
                  { label: 'All', value: '' },
                  { label: 'Debtor', value: 'debtor' },
                  { label: 'Creditor', value: 'creditor' },
                  { label: 'Cash', value: 'cash' },
                  { label: 'Control', value: 'control' },
                ]}
              />
            </Col>
            <Col sm={3}>
              <ControlLabel className="filter-label">Status</ControlLabel>
              <SelectField
                input={{
                  onChange: (value) =>
                    this.props.dispatch(
                      applyFilter('accounts', 'filter[suspended]', value),
                    ),
                  value: getFilter(dataTables, 'accounts', 'filter[suspended]'),
                }}
                value={this.state.suspended ? this.state.suspended : undefined}
                options={[
                  { label: 'All', value: '' },
                  { label: 'Active', value: 'false' },
                  { label: 'Suspended', value: 'true' },
                ]}
              />
            </Col>
          </Row>
        </Box>

        <Box>
          <DataTable
            source="/accounts"
            name="accounts"
            showTotal
            autoFilters={[
              { type: 'filter[type]', value: this.state.accountType },
              { type: 'filter[suspended]', value: this.state.suspended },
              { type: 'sort', value: this.state.sort },
            ]}
            onRowSelect={this.handleRowClick.bind(this)}
          >
            <TableHeaderColumn dataField="id" isKey hidden />
            <TableHeaderColumn
              dataField="accounting_system_reference"
              width={'100px'}
              dataSort
            >
              Reference
            </TableHeaderColumn>
            <TableHeaderColumn dataField="name" width={'330px'} dataSort>
              Account Name
            </TableHeaderColumn>
            <TableHeaderColumn dataField="account_type" width={'150px'}>
              Account Type
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="formatted_balance"
              dataAlign="right"
              width={'120px'}
              dataSort
            >
              Balance
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="limit"
              dataAlign="right"
              width={'120px'}
              dataSort
              dataFormat={this.formatLimit}
            >
              Limit
            </TableHeaderColumn>
          </DataTable>
          <Row>
            <Col xs={12}>
              <InternalButtonBar buttons={[
                {
                  permissions: ['account.edit'],
                  button: (
                    <Button
                      link
                      to="/accounts/ledger/new"
                      className="pull-right"
                      bsStyle="primary"
                      label="new account"
                    />
                  )
                }
              ]}/>
            </Col>
          </Row>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataTables: state.dataTables,
  };
};

export default connect(mapStateToProps)(AccountList);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '../../../common/components';
import { isBroker } from '../../auth/redux/authActions';
import InternalContainer from '../../layout/containers/Internal';
import UserList from '../components/UserList/UserList';
import { get } from 'lodash';

class UserListContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
  };

  render() {
    let buttons = '';

    if (isBroker()) {
      const brokerId = get(
        this.props.auth.user,
        'data.relationships.current_broker.data.id',
        null,
      );
      const organisationId = get(
        this.props.auth.user,
        'data.relationships.current_organisation.data.id',
        null,
      );
      if (brokerId && organisationId) {
        buttons = (
          <Button
            link
            to={'/admin/users/new/' + organisationId + '/' + brokerId}
            bsStyle="primary"
            rightIcon="user"
            label="add a new user"
          />
        );
      }
    }

    return (
      <InternalContainer title="Admin / Users">
        <UserList {...this.props} buttons={buttons} />
      </InternalContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(UserListContainer);

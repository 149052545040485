import React from 'react';
import { Field, FieldArray, FormSection } from 'redux-form';
import CollapsableBox from '../CollapsableBox/CollapsableBox';
import TextArea from "../../../content/components/TextArea";
import {
  Button,
  CheckboxControl,
  HorizontalFormControl
} from "../../../../common/components";
import { Col, FormGroup } from "react-bootstrap";

const Delete = ({ onRemove, index }) => {
  const remove = () => {
    onRemove(index);
  };
  return (
    <i className="fa fa-trash" onClick={remove}/>

  );
};

const Question = ({ field, index, onRemove }) => {
  return (
    <>
      <FormSection name={field}>
        <div className={'eligibility-questions--section'}>

          <Delete onRemove={onRemove} index={index}/>
          <Field name={'question_number'}
                 label={'question number'}
                 component={HorizontalFormControl}
          />
          <Field name={'content'}
                 label={'content'}
                 component={TextArea}
          />

          <Field name={'required'}
                 label={'Required'}
                 options={[{ label: ' ', value: true }]}
                 component={CheckboxControl}
          />
        </div>
      </FormSection>
    </>
  )
}

const QuestionFieldArray = ({ fields }) => {

  const add = () => {
    fields.push({
      question_number: fields.length + 1,
      content: '',
      required: false
    })
  }
  const remove = (index) => {
    fields.remove(index);
  }

  return (
    <>
      {fields.map((field, index) => (
        <Question field={field} index={index} onRemove={remove}/>
      ))}
      <FormGroup>
        <Col sm={12}>
          <Button
            handleClick={add}
            bsStyle={'primary'}
            className={'pull-right'}
          >
            Add
          </Button>
        </Col>
      </FormGroup>
    </>
  )
};
const EligibilityQuestions = ({}) => {
  return (
    <CollapsableBox label={'Eligibility Questions'}>
      <FieldArray
        name={'meta.whitelabel.content.eligibility_questions'}
        component={QuestionFieldArray}
      />
    </CollapsableBox>
  );

};

export default EligibilityQuestions;

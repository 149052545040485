import React, { Component } from 'react';
import Select from 'react-select';
import { get, filter, isEmpty, flow, some } from 'lodash';
import selectTheme, { styles } from '../../../helpers/selectTheme';

import { bindActionCreators } from 'redux';
import { actions as selectMenuActions } from './SelectMenuRedux';
import { connect } from 'react-redux';

class MultiSelectMenu extends Component {
  componentDidMount() {
    const {
      selectMenuActions,
      source,
      sourceKey = '',
      filter,
      labelPath,
      optionValuePath,
      includes,
      filterUrl,
    } = this.props;
    Promise.resolve(
      selectMenuActions.fetchData(
        source,
        sourceKey,
        filter,
        labelPath,
        optionValuePath,
        true,
        includes,
        filterUrl,
      ),
    ).then(() => {
      this.filterOptions();
    });
  }

  filterOptions() {
    const {
      autoFilters,
      allData,
      allOptions,
      selectMenuActions,
      source,
      sourceKey,
      isObject,
      labelPath,
      optionValuePath,
    } = this.props;
    const matchFilter = (filter, option) => {
      const exactMatch = filter.match
        ? get(option, `attributes.${filter.type}`) === filter.match
        : true;
      const appendMatch = filter.append
        ? get(option, `attributes.${filter.type}`, '')
            .toLowerCase()
            .includes(filter.append.toLowerCase())
        : true;
      return exactMatch && appendMatch;
    };
    if (autoFilters && isObject) {
      const filteredOptions = filter(allOptions, (option) => {
        return autoFilters.reduce((carry, filter) => {
          return carry && matchFilter(filter, JSON.parse(option.value));
        }, true);
      });
      selectMenuActions.setDisplayOptions(source + sourceKey, filteredOptions);
    }

    if (autoFilters && !isObject) {
      //need to go to original data to get filtered options
      const filteredOptions = allData.reduce((carry, option) => {
        const matchFound = autoFilters.reduce((carry, filter) => {
          return carry && matchFilter(filter, option);
        }, true);
        if (matchFound) {
          carry.push({
            label: get(option, labelPath ? labelPath : 'attributes.name'),
            value: optionValuePath ? get(option, optionValuePath) : option,
          });
        }
        return carry;
      }, []);
      selectMenuActions.setDisplayOptions(source + sourceKey, filteredOptions);
    }

    if (!autoFilters) {
      selectMenuActions.setDisplayOptions(source + sourceKey, allOptions);
    }
  }

  getValues() {
    const { allOptions, input, isObject } = this.props;
    if (!input || isEmpty(input.value)) {
      return undefined;
    }
    if (!isEmpty(allOptions)) {
      if (isObject) {
        return allOptions.filter((option) => {
          const optionValue = get(JSON.parse(option.value), 'id');
          return some(input.value, ['id', optionValue]);
        });
      } else {
        return allOptions.filter((option) => {
          return input.value.indexOf(JSON.parse(option.value)) >= 0;
        });
      }
    }
    return undefined;
  }

  handleOnChange = (selectedOptions) => {
    const { input } = this.props;
    input.onChange(selectedOptions.map((s) => JSON.parse(s.value)));
  };

  handleOnBlur = () => {
    const { input } = this.props;
    input.onBlur(input.value);
  };

  render() {
    const { meta, showError, errorMessage, placeholder, displayOptions } = this.props;
    const placeholderText = placeholder ? placeholder : 'Please Select...';
    const error = meta && meta.error;
    const touched = meta && meta.touched;
    const showErrorMsg = showError || (touched && meta);
    const errorMsg = errorMessage || error;

    return (
      <div className={'select-menu'}>
        <Select
          theme={(theme) => selectTheme(theme)}
          options={displayOptions}
          value={this.getValues()}
          isMulti
          placeholder={placeholderText}
          onBlur={() => this.handleOnBlur()}
          onChange={(values) => this.handleOnChange(values)}
          styles={styles}
          {...this.props}
        />
        {(showErrorMsg && errorMsg) && <span className="input-error">{errorMsg}</span>}
      </div>
    );
  }
}

MultiSelectMenu.defaultProps = {
  labelPath: 'attributes.name',
  append: '',
  optionName: 'name',
};

export default flow([
  connect(
    (state, props) => {
      const sourceKey = props.sourceKey || '';
      return {
        allOptions: get(state, `selects.${props.source}${sourceKey}.options`),
        displayOptions: get(state, `selects.${props.source}${sourceKey}.displayOptions`),
        allData: get(state, `selects.${props.source}${sourceKey}.data`),
      };
    },
    (dispatch) => ({
      selectMenuActions: bindActionCreators(selectMenuActions, dispatch),
    }),
  ),
])(MultiSelectMenu);

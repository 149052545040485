import { isFunction, startCase } from 'lodash';

export default function displayName(value, titleCase = false, customFunction) {
  if (!value) {
    return '';
  }

  value = value.replace(/-/g, ' ').replace(/_/g, ' ');
  if (titleCase && !customFunction) {
    return value.replace(/\w\S*/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  if (customFunction && isFunction(customFunction)) {
    return customFunction(value);
  }

  return startCase(value);
}

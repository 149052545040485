import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, FormSection, formValueSelector, reduxForm } from 'redux-form';
import {
  get,
  map,
  forEach,
  has,
  indexOf,
  isEqual,
  set,
  includes,
  concat,
  head,
  uniq,
  filter,
} from 'lodash';
import confirm from 'react-confirm2';
import { InfoButton } from '../../../../../../common/components';
import { HazRadioGroup, OptionsCheckboxesRedux } from '../../ReduxForm/';
import OptionsCheckboxes from '../OptionsCheckboxes/OptionsCheckboxes';
import getIncludedResource from '../../../../../../helpers/getIncludedResource';
import { Row, Col, ControlLabel } from 'react-bootstrap';
import './styles/styles.scss';
import Loader from '../../../../../../common/components/Loader/Loader';
import SelectField from '../../../../../../common/components/ReduxFormField/SelectField';
import FieldFormControl
  from '../../../../../../common/components/FieldFormControl/FieldFormControl';
import moment from 'moment';
import displayName from '../../../../../../helpers/displayName';

const META = 'data.attributes.metadata';

export class OptionsTab05 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      excludedOptions: ['hazardous_activities', 'max_trip_duration'],
      alertVisible: false,
      hasWinterSportsExclusion: false,
    };
    this.handleAlertDismiss = this.handleAlertDismiss.bind(this);
    this.singleRecalculation = this.singleRecalculation.bind(this);
    this.singleRecalculationCallback = this.singleRecalculationCallback.bind(this);
  }


  componentWillMount() {
    const { formValues, dispatch, change, product, selection } = this.props;

    const productCode = get(product, 'productCode');
    // Detect Exclusion in screening to hide the price and add a warning
    map(get(formValues, `${META}.adults`), (adult) => {
      const screeningInfo = get(adult, 'screening.meta.hasWinterSportsExclusion');
      let dobDate = moment(get(adult, 'dob'));
      const todayDate = moment();

      if (productCode === 'CPS' && 75 <= todayDate.diff(dobDate, 'years')) {
        this.setState(() => ({
          hasWinterSportsExclusion: true,
        }));
      }
      if (screeningInfo) {
        this.setState(() => ({
          hasWinterSportsExclusion: true,
        }));
      }
    });

    if (has(formValues, `${META}.children`)) {
      map(get(formValues, `${META}.children`), (children) => {
        const screeningInfo = get(children, 'screening.meta.hasWinterSportsExclusion');
        if (screeningInfo) {
          this.setState(() => ({
            hasWinterSportsExclusion: true,
          }));
        }
      });
    }

    if (!get(selection, 'meta.information.display_winter_sport', true)) {
      this.setState(() => ({
        hasWinterSportsExclusion: true,
      }));
    }

    if (has(formValues, `${META}.referrals`)) {
      map(get(formValues, `${META}.referrals`), (referral) => {
        if (referral.includes('winter sports')) {
          this.setState(() => ({
            hasWinterSportsExclusion: true,
          }));
        }
      });
    }

    const premium = get(formValues, 'data.relationships.schemes.data', []);
    const premiumId = head(premium);
    const schemeObject = getIncludedResource(
      get(premiumId, 'id'),
      product.schemes,
      false,
      'products/schemes',
    );
    const getIncludedOptions = get(
      schemeObject,
      'data.attributes.metadata.included_options',
    );

    // check the options if the scheme contains included options
    const optionToCheck = 'cruise_cover';

    if (indexOf(getIncludedOptions, optionToCheck) !== -1) {
      dispatch(change(`${META}.options.${optionToCheck}`, true));
    }


    // if (get(formValues, 'data.attributes.metadata.scheme_type') === 'single') {
    //   const MAX_DAYS_WINTER = 17
    //   const start = moment(get(formValues, 'data.attributes.metadata.start_date'))
    //   const end = moment(get(formValues, 'data.attributes.metadata.end_date'))
    //   const duration = end.diff(start, 'days')
    //   if (duration > MAX_DAYS_WINTER) {
    //     this.setState(prevState => ({
    //       excludedOptions: [...prevState.excludedOptions, 'winter_sports']
    //     }))
    //     dispatch(change(`${META}.options.winter_sports`, false))
    //   }
    // }
  }

  singleRecalculation() {
    // to making sure to have the latest formValues
    Promise.resolve(() => {}).then(() => {
      this.singleRecalculationCallback();
    });
  }

  singleRecalculationCallback() {
    const { formValues, singleCalculation } = this.props;
    const selectedSchemes = get(formValues, 'data.relationships.schemes.data', []);
    const schemeSelected = head(selectedSchemes);

    singleCalculation(schemeSelected, formValues);
  }

  handleAlertDismiss() {
    this.setState(() => ({
      alertVisible: false,
    }));
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch, change, formValues, hazMta, selection } = this.props;

    // Setting default options
    const getAllOptions = get(selection, 'meta.information.available_options');
    forEach(getAllOptions, (option, key) => {
      if (option.default) {
        if (get(formValues, `data.attributes.metadata.options[${key}]`) == null) {
          dispatch(change(`${META}.options.${key}`, option.default));
        }
      }
    });

    const newHazProps = get(nextProps.formValues, 'data.attributes.metadata.activities');
    const oldHazProps = get(formValues, 'data.attributes.metadata.activities');

    if (!isEqual(newHazProps, oldHazProps)) {
      this.setState(() => ({ alertVisible: false }));
    }

    map(hazMta, (act) => {
      let test = includes(newHazProps, act);
      if (test === false) {
        let con = concat(hazMta, newHazProps);
        set(formValues, 'data.attributes.metadata.activities', uniq(con));
        this.setState(() => ({ alertVisible: true }));
      }
    });

    // If the scheme contains included_options, keep it checked.
    const optionToCheck = 'cruise_cover';
    const getScheme = head(get(formValues, 'data.relationships.schemes.data', []));

    // Get the includedOptions property from schemes for both normal quote and MTAs
    const getIncludedOptions = !this.props.mta
      ? get(getScheme, 'attributes.metadata.included_options')
      : get(getScheme, 'included_options');

    // If user uncheck the Cruiser checkbox
    const cruiseValue = get(nextProps.formValues, `${META}.options.cruise_cover`);
    const cruiseValueOld = get(formValues, `${META}.options.cruise_cover`);
    if (
      cruiseValue !== true &&
      indexOf(getIncludedOptions, optionToCheck) === -1 &&
      cruiseValue !== cruiseValueOld &&
      indexOf(this.state.excludedOptions, 'cruise_cover') === -1
    ) {
      confirm('Warning', {
        description:
          'You are about to remove the Cruise option from your quote. Would you like to continue?',
        close: () => {
          dispatch(change(`${META}.options.cruise_cover`, true));
        },
      });
    } else if (indexOf(getIncludedOptions, optionToCheck) !== -1) {
      // Don't allow the disabling as it is already included in IncludedOptions
      dispatch(change(`${META}.options.cruise_cover`, true));
    }
    // if(getAllOptions && product.productCode === 'FST' ){
    //   dispatch(change(`${META}.options.waiting_list`, getAllOptions.waiting_list.selected));
    // }
  }

  render() {
    const { currentProduct, selection, product, formValues, dispatch, change } = this.props;
    const salesChannel = get(formValues, 'data.attributes.metadata.sales_channel', {});
    const productCode = get(formValues, 'data.attributes.metadata.product_code', {});
    const schemeType = get(formValues, 'data.attributes.metadata.scheme_type', {});
    const definitions = get(currentProduct, `${META}.definitions`, {});
    const getAllOptions = get(selection, 'meta.information.available_options', {});
    const getOptionsBoolean = Object.keys(getAllOptions).filter((optionKey) => {
      const option = getAllOptions[optionKey];
      if (option.key === 'waiting_list' && (!option.auto_include || salesChannel !== 'call-centre' || schemeType !== 'annual')) {
        return false;
      }
      return option.input_type === 'boolean';

    });
    const getOptionsEnum = filter(getAllOptions, (o) => {
      return o.input_type === 'enum' && o.key !== 'period_of_insurance';
    });

    let options = get(formValues, 'data.attributes.metadata.options', {});
    if ((productCode === 'ADV' || productCode === 'PST') && options) {
      if (options['part_c'] === true && (!options['part_b'] || options['part_b'] === false)) {
        dispatch(change(`${META}.options.part_b`, true));
      }
    }
    const productOptions = Object.keys(get(currentProduct, 'data.attributes.metadata.options', {}));

    return (
      <div>
        <h4 className="title-options">Optional Cover</h4>
        <FormSection name={META}>
          <div className="container-fluid">
            {product.isCalculating && <Loader noShadow/>}
            {productCode === 'CSP' && getOptionsBoolean.length === 0 && (
              <Row>
                <Col sm={6}>
                  {productOptions.map(
                    (option, i) => {
                      return (
                        <div key={i}>
                          <Field
                            component={OptionsCheckboxesRedux}
                            onChange={() => this.singleRecalculation()}
                            name={'options.' + option}
                            infoButton={<InfoButton large content={definitions[option]}/>}
                            options={[{ label: displayName(option), value: true }]}
                          />
                        </div>
                      );
                    },
                  )}
                </Col>
              </Row>
            )}
            <Row>
              <Col sm={12}>
                {getOptionsBoolean.map((optionKey) => {
                  const option = getAllOptions[optionKey];
                  return option.auto_include ? (<OptionsCheckboxes
                      index={optionKey}
                      name={option.key}
                      disableWinter={this.state.hasWinterSportsExclusion}
                      infoButton={<InfoButton content={definitions[option.key]}/>}
                      options={[
                        {
                          label: option.name,
                          value: true,
                        },
                      ]}
                      readOnly
                      value={true}

                    />) :
                    (<Field
                        key={optionKey}
                        name={'options.' + option.key}
                        onChange={() => this.singleRecalculation()}
                        component={OptionsCheckboxesRedux}
                        disableWinter={this.state.hasWinterSportsExclusion}
                        infoButton={<InfoButton large content={definitions[option.key]}/>}
                        options={[
                          {
                            label: option.name,
                            value: true,
                          },
                        ]}
                      />
                    );
                })}
              </Col>
            </Row>
            { productCode === 'EURP' && (
              <Row>
                <div className={'form-group'}>
                  <Col sm={5} componentClass={ControlLabel}>
                    <h4>Vehicle Details</h4>
                  </Col>
                </div>
                <div className={'form-group'}>
                  <Col sm={5} componentClass={ControlLabel}>
                    Company name:
                  </Col>
                  <Col sm={7}>
                    <Field
                      name="company_name"
                      id="company_name"
                      type="input"
                      component={FieldFormControl}
                    />
                  </Col>
                </div>
                <div className={'form-group'}>
                  <Col sm={5} componentClass={ControlLabel}>
                    Make:
                  </Col>
                  <Col sm={7}>
                    <Field
                      name="make"
                      id="make"
                      type="input"
                      component={FieldFormControl}
                    />
                  </Col>
                </div>
                <div className={'form-group'}>
                  <Col sm={5} componentClass={ControlLabel}>
                    Model:
                  </Col>
                  <Col sm={7}>
                    <Field
                      name="model"
                      id="model"
                      type="input"
                      component={FieldFormControl}
                    />
                  </Col>
                </div>
                <div className={'form-group'}>
                  <Col sm={5} componentClass={ControlLabel}>
                    Type:
                  </Col>
                  <Col sm={7}>
                    <Field
                      name="type"
                      id="type"
                      component={SelectField}
                      options={[
                        { label: 'Car / LPG (including estates and SUVs)', value: 'Car/LPG/Estate/SUV' },
                        { label: 'Motorcycle', value: 'Motorcycle' },
                        { label: 'Light Commercial Less 3.5T', value: 'Light Commercial Less 3.5T' },
                        { label: 'Motorhome under 3.5t', value: 'Motorhome under 3.5t' },
                        { label: 'Campervan', value: 'Campervan' },
                        { label: 'Minibus', value: 'Minibus' },
                      ]}
                    />
                  </Col>
                </div>
                <div className={'form-group'}>
                  <Col sm={5} componentClass={ControlLabel}>
                    Year:
                  </Col>
                  <Col sm={3}>
                    <Field
                      name="year"
                      id="year"
                      type="number"
                      component={FieldFormControl}
                    />
                  </Col>
                </div>
                <div className={'form-group'}>
                  <Col sm={5} componentClass={ControlLabel}>
                    Value:
                  </Col>
                  <Col sm={3}>
                    <Field
                      name="value"
                      id="value"
                      type="input"
                      component={FieldFormControl}
                    />
                  </Col>
                </div>
                <div className={'form-group'}>
                  <Col sm={5} componentClass={ControlLabel}>
                    Colour:
                  </Col>
                  <Col sm={7}>
                    <Field
                      name="colour"
                      id="colour"
                      type="input"
                      component={FieldFormControl}
                    />
                  </Col>
                </div>
                <div className={'form-group'}>
                  <Col sm={5} componentClass={ControlLabel}>
                    Trans type:
                  </Col>
                  <Col sm={7}>
                    <Field
                      name="trans_type"
                      id="trans_type"
                      component={SelectField}
                      options={[
                        { label: 'Manual', value: 'M' },
                        { label: 'Automatic', value: 'A' },
                      ]}
                    />
                  </Col>
                </div>
                <div className={'form-group'}>
                  <Col sm={5} componentClass={ControlLabel}>
                    Fuel type:
                  </Col>
                  <Col sm={7}>
                    <Field
                      name="fuel_type"
                      id="fuel_type"
                      component={SelectField}
                      options={[
                        { label: 'Petrol', value: 'P' },
                        { label: 'Diesel', value: 'D' },
                        { label: 'LPG', value: 'LPG' },
                        { label: 'Electric/Hybrid', value: 'EH' },
                      ]}
                    />
                  </Col>
                </div>
                <div className={'form-group'}>
                  <Col sm={5} componentClass={ControlLabel} >
                    4x4 Drive:
                  </Col>
                  <Col sm={7}>
                    <Field
                      name="four_by_four_drive"
                      id="four_by_four_drive"
                      component={SelectField}
                      options={[
                        { label: 'Yes', value: 'Y' },
                        { label: 'No', value: 'N' },
                      ]}
                    />
                  </Col>
                </div>
              </Row>
            )}

            {map(getOptionsEnum, (enumOption, i) => {
              return (
                <Row key={i}>
                  <Col sm={12}>
                    <h4 className="title-options">{enumOption.name}</h4>
                  </Col>
                  <Col sm={12}>
                    <div key={i}>
                      <Field
                        name={`options.${enumOption.key}`}
                        component={HazRadioGroup}
                        onChange={() => this.singleRecalculation()}
                        options={map(enumOption.options, (label) => {
                          return {
                            label: label.name,
                            value: label.key,
                          };
                        })}
                        definitions={definitions[enumOption.key] || definitions}
                      />
                    </div>
                  </Col>
                </Row>
              );
            })}
            {!product.isCalculating && !getOptionsBoolean.length && !getOptionsEnum.length && (productCode === 'CPS' && !productOptions.length) &&  (
              <p>No options available</p>
            )}
          </div>
        </FormSection>
        <hr/>
      </div>
    );
  }
}

const FORM_NAME = 'LeisureTravelForm';
const form = reduxForm({ form: FORM_NAME })(OptionsTab05);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  const values = selector(
    state,
    'data.id',
    'data.relationships',
    'data.attributes.metadata',
  );

  return {
    auth: state.auth,
    initialValues: props.resource,
    form: 'LeisureTravelForm',
    formValues: values,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    product: state.product,
  };
};

export default connect(mapStateToProps)(form);

import React, {  useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
} from '../../../../common/components';
import {
  cachePremiums,
  cacheTransactions,
  cacheMatchedEntries
} from '../../redux/accountActions';
import { Modal } from 'react-bootstrap';
import MakeReceiptForm from './MakeReceiptForm';




const MakeReceiptButton = ({
  label,
  block,
  accountId,
  amount,
  transactionType,
  matchedTransactions,
  matchedPremiums,
  onSavePaymentsAndReceipts,
  onCacheTransactions,
  onCachePremiums,
  onCacheMatchedEntries,

}) => {

  const [show, setShow] = useState(false);


  const handleOpen = () => {

    const matchedTransactionIds = matchedTransactions?.map((transaction) => (transaction.id));
    const matchedPremiumIds = matchedPremiums?.map((premium) => (premium.id));

    onCacheTransactions(matchedTransactions);
    onCachePremiums(matchedPremiums);
    onCacheMatchedEntries(matchedTransactionIds, matchedPremiumIds);
    setShow(true);
  };


  const handleCancel = () => {
    onCacheTransactions([]);
    onCachePremiums([]);
    setShow(false);
  };


  const save = (values) => {
    return Promise.resolve(onSavePaymentsAndReceipts(values, accountId)).then(() => {
      onCacheTransactions( []);
      setShow(false);
    });

  };


  const title = transactionType || label;



  return (
    <div>
      <Button
        className="matching-sidebar-btn"
        bsStyle="primary"
        label={title}
        handleClick={handleOpen}
        block={block}
      />
      <Modal
        show={show}
        onHide={handleCancel}
      >
        <Modal.Header closeButton>
            <div className={'title-case'}>
              {title}
            </div>

        </Modal.Header>
        <Modal.Body>
          <MakeReceiptForm
            amount={Math.abs(amount).toFixed(2)}
            accountId={accountId}
            transactionType={transactionType}
            onSave={save}
          />

        </Modal.Body>


      </Modal>
    </div>
  );
};


export default connect(null, {
  onCacheTransactions: cacheTransactions,
  onCachePremiums: cachePremiums,
  onCacheMatchedEntries: cacheMatchedEntries
})(MakeReceiptButton);

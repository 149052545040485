import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box, DataTable, Button } from '../../../../common/components';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { FormattedDate, FormattedTime } from 'react-intl';
import { applyFilter, getFilter, refreshData } from '../../../../common/components/DataTable/redux/dataTableActions';
import { LedgerEntriesModal } from '../LedgerEntriesModal';
import { openModal } from '../../../../common/components/Modal/ModalRedux';
import { Row, Col, FormControl, ControlLabel } from 'react-bootstrap';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import SelectField from '../../../../common/components/ReduxFormField/SelectField';

const TABLE_NAME = 'accountTransactions';

class AccountTransactions extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    accountLabel: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      ledgerEntry: {},
    };
  }

  formatDate(cell) {
    return (
      <span>
        <FormattedDate value={new Date(cell)} /> <FormattedTime value={new Date(cell)} />
      </span>
    );
  }

  refreshList() {
    this.props.dispatch(refreshData(TABLE_NAME));
  }

  handleRowClick(row) {
    this.setState({ ledgerEntry: row });
    this.props.dispatch(openModal(LedgerEntriesModal.MODAL_IDENTIFIER));
  }

  handleSearchUpdate(event) {
    this.props.dispatch(applyFilter(TABLE_NAME, 'filter[description]', event.target.value));
  }

  render() {
    const { dispatch, dataTables, resource, accountLabel } = this.props;
    const { ledgerEntry } = this.state;
    const id = resource.data.id;
    const account = resource.data.attributes;

    return (
      <div>
        <Box>
          <Row>
            <Col sm={9}>
              <ControlLabel className="filter-label">Search</ControlLabel>
              <FormControl onChange={::this.handleSearchUpdate} />
            </Col>
            <Col sm={3}>
              <ControlLabel className="filter-label">Matched</ControlLabel>
              <SelectField
                input={{
                  onChange: (value) =>
                    dispatch(
                      applyFilter(TABLE_NAME, 'filter[batched]', value),
                    ),
                  value: getFilter(dataTables, TABLE_NAME, 'filter[batched]'),
                }}
                options={[
                  { label: 'Unmatched', value: 'unmatched' },
                  { label: 'Matched', value: 'matched' },
                  { label: 'Paid', value: 'paid' },
                  { label: 'All', value: 'all' },
                ]}
              />
            </Col>
          </Row>
        </Box>

        <Box>
          <h2 className="resource-name">
            {account.name}
            {accountLabel}
          </h2>
          <DataTable
            source={'/accounts/ledger-entries'}
            autoFilter={id}
            autoFilterType="filter[account]"
            name={TABLE_NAME}
            showTotal
            onRowSelect={this.handleRowClick.bind(this)}
          >
            <TableHeaderColumn dataField="id" isKey hidden />
            <TableHeaderColumn
              dataField="created_at"
              dataFormat={this.formatDate}
              width={'180px'}
            >
              Date
            </TableHeaderColumn>
            <TableHeaderColumn dataField="description" width={'500px'}>
              Description
            </TableHeaderColumn>
            <TableHeaderColumn dataField="currency" dataAlign="center" width={'100px'}>
              Currency
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="formatted_amount"
              dataAlign="right"
              width={'200px'}
            >
              Amount
            </TableHeaderColumn>
            <TableHeaderColumn dataField="matched" width={'200px'}>
              Matched
            </TableHeaderColumn>
          </DataTable>
          <LedgerEntriesModal
            key="ledger-modal"
            dispatch={dispatch}
            ledgerEntry={ledgerEntry}
          />

          <Row>
            <Col sm={12}>
              <InternalButtonBar buttons={[
                {
                  permissions: ['account.edit'],
                  button: (
                    <Button
                      type="button"
                      bsStyle="primary"
                      className="pull-right pad-right"
                      link
                      to={'accounts/' + id + '/matching'}
                      label="Matching"
                    />
                  )
                }
              ]}/>
            </Col>
          </Row>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataTables: state.dataTables,
  };
};

export default connect(mapStateToProps)(AccountTransactions);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { SelectField } from '../../../../common/components/ReduxFormField';
import {
  Box,
  Button,
  DatePickerDob,
  SelectAccountingPeriod,
} from '../../../../common/components';
import { generateReport } from '../../redux/accountActions';
import { get, indexOf } from 'lodash';
import './styles.scss';
import { Form, Col, Row, FormGroup, ControlLabel } from 'react-bootstrap';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';

const FORM_NAME = 'ledgerReportForm';

const validate = (values, endDateOnlyRequired) => {
  const errors = {};

  if (!get(values, 'report')) {
    errors.report = 'Report type is required';
  }

  if (
    indexOf(endDateOnlyRequired, get(values, 'report')) === -1 &&
    !get(values, 'from_date')
  ) {
    errors.from_date = 'From date is required';
  }

  if (!get(values, 'to_date')) {
    errors.to_date = 'To date is required';
  }

  return errors;
};

class LedgerReports extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    isGeneratingReport: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      queue: false
    };
  }

  handleSubmit(values) {
    this.props.dispatch(generateReport(values));
  }

  handleGenerateOnQueue() {
    const { change, dispatch } = this.props;

    if (this.state.queue) {
      dispatch(change('generator', null));
    } else {
      dispatch(change('generator', 'queue'));
    }

    this.setState({ queue: !this.state.queue });
  }

  render() {
    const {
      formValues,
      handleSubmit,
      isGeneratingReport,
      endDateOnlyRequired,
    } = this.props;

    const reportTypes = [
      { label: 'Trial Balance', value: 'trial_balance' },
      { label: 'Ledger Transactions', value: 'ledger_transactions:bespoke' },
      { label: 'Ledger Export (Transactions)', value: 'ledger_export_transactions' },
      { label: 'Ledger Export (Movement)', value: 'ledger_export_movement' },
      { label: 'Manual Journals', value: 'manual_journals:bespoke' },
    ];

    return (
      <Box>
        <h2 className="resource-name">Ledger</h2>
        <Form onSubmit={handleSubmit(::this.handleSubmit)}>
          <Row>
            <Col sm={6}>
              <Field
                name="report"
                label="Report Type"
                labelSize={3}
                component={SelectField}
                options={reportTypes}
              />

              {indexOf(endDateOnlyRequired, get(formValues, 'report')) !== -1 ? (
                <Field
                  name="to_date"
                  label="As at date"
                  labelSize={3}
                  component={DatePickerDob}
                />
              ) : (
                <div>
                  <Field
                    name="accounting_period"
                    label="Accounting Period"
                    labelSize={3}
                    component={SelectAccountingPeriod}
                  />

                  {!get(formValues, 'accounting_period') && (
                    <div>
                      <Field
                        name="from_date"
                        label="From"
                        labelSize={3}
                        component={DatePickerDob}
                      />
                      <Field
                        name="to_date"
                        label="To"
                        minDate={get(formValues, 'from_date')}
                        labelSize={3}
                        component={DatePickerDob}
                      />
                    </div>
                  )}
                </div>
              )}
              <FormGroup>
                <Col sm={3} componentClass={ControlLabel}>
                  Queue
                </Col>
                <Col sm={9}>
                  <div className="queue-checkbox">
                    <div className="checkbox-container inline">
                      <div className="form-radio">
                        <input
                          id="Queue"
                          type="checkbox"
                          checked={this.state.queue}
                          onChange={() => this.handleGenerateOnQueue()}
                          value="generator"
                        />
                        <label htmlFor="Queue"/>
                      </div>
                    </div>
                  </div>
                </Col>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <InternalButtonBar buttons={[
                {
                  button: (
                    <Button
                      className="pull-right"
                      type="submit"
                      label="Generate Report"
                      isLoading={isGeneratingReport}
                      bsStyle="primary"
                    />
                  ),
                },
              ]}/>
            </Col>
          </Row>
        </Form>
      </Box>
    );
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(LedgerReports);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state) => {
  const values = selector(state, 'from_date', 'to_date', 'report', 'accounting_period');
  const endDateOnlyRequired = ['trial_balance'];
  return {
    endDateOnlyRequired: endDateOnlyRequired,
    formValues: values,
    isGeneratingReport: state.account.isGeneratingReport,
  };
};
export default connect(mapStateToProps)(form);

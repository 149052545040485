import React from 'react';
import {
  DataTable,
  CreateNoteButton,
  NotesList,
} from '../../../../../../common/components';
import { refreshData } from '../../../../../../common/components/DataTable/redux/dataTableActions';
import { FormattedDate } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import Box from '../../../../../../common/components/Box/Box';
import { InternalButtonBar } from '../../../../../layout/components/InternalButtonBar/InternalButtonBar';

class NotesForm extends React.Component {
  formatDate(cell) {
    return <FormattedDate value={new Date(cell)}/>;
  }

  handleRowClick() {
    //this.props.dispatch(push('/admin/users/' + row.id));
    return false;
  }

  refreshList() {
    this.props.dispatch(refreshData('notes'));
  }

  render() {
    const { id, formValues } = this.props;
    return (
      <Box>
        <h4 className="page-title">Supporting Documents</h4>
        <DataTable
          source={'/notes'}
          autoFilter={id}
          autoFilterType="filter[resource]"
          name="notes"
          showTotal
          onRowSelect={this.handleRowClick.bind(this)}
          component={NotesList}
        />
        <Row>
          <Col sm={12}>
            <InternalButtonBar
              buttons={[
                {
                  permissions: ['account.notes_create'],
                  button: (
                    <CreateNoteButton
                      label="Add new document"
                      className="pull-right"
                      notableResource={formValues}
                      notableResourceType="brokers/applications"
                      callback={::this.refreshList}
                      hideReminder
                    />
                  ),
                },
              ]}
            />
          </Col>
        </Row>
      </Box>
    );
  }
}

export default connect()(NotesForm);

import React, { useEffect } from 'react';
import { actions } from '../redux/ProductReducer';
import { connect, useSelector } from 'react-redux';
import InternalContainer from '../../layout/containers/Internal';
import { Loader } from '../../../common/components';
import getIncludedResource from '../../../helpers/getIncludedResource';
import CloneProductForm from '../components/CloneProductForm/CloneProductForm';
import { push } from 'react-router-redux';

const INCLUDES = ['scheme_groups'];
const CloneProductContainer = (
  {
    params,
    onGetProduct,
    onClone,
    onGoTo

  }
) => {
  const { productId } = params;
  const {
    product,
    isLoading,
  } = useSelector((state) => state.productManagement);

  const currentProductId = product?.data?.id;

  useEffect(
    () => {
      if (!currentProductId || currentProductId !== productId) {
        onGetProduct(productId, INCLUDES);
      }

    },
    [productId, currentProductId, onGetProduct],
  );


  const isValid = currentProductId && currentProductId === productId;
  if (!isValid || isLoading) {
    return (
      <div className="admin-area">
        <InternalContainer title="Product management">
          <Loader/>
        </InternalContainer>
      </div>
    );
  }
  const schemeGroups = getIncludedResource(product?.data, product?.included, 'scheme_groups');
  const { name, summary, description } = product?.data?.attributes ?? {};


  // const schemeDetails = schemeGroups?.reduce((carry, schemeGroup) => {
  //
  //   carry[schemeGroup?.attributes?.group_key] = {
  //     name: schemeGroup?.attributes?.name,
  //     scheme_details: schemeGroup?.attributes?.scheme_details
  //   }
  //   return carry;
  // }, {})


  const save = (values) => {
    return Promise.resolve(onClone(productId, values)).then(
      (resp) => {
        return onGoTo(`/admin/products/${resp?.data?.id}/product-management`);

      });

  };


  return (
    <div className="admin-area">
      <InternalContainer title="Clone Product">
        <CloneProductForm
          onSave={save}
          productId={productId}
          productName={name}
          productSummary={summary}
          productDescription={description}
          schemeGroups={schemeGroups}
        />
      </InternalContainer>
    </div>);

};

export default connect(
  null,
  {
    onGetProduct: actions.getProduct,
    onClone: actions.cloneProduct,
    onGoTo: push,
  }
)(CloneProductContainer);

import React, { useState } from 'react';
import { Field, FieldArray } from 'redux-form';
import { ButtonBar, HorizontalFormControl } from '../../../../../../common/components';
import { Button } from '../../../../../../common/components/Button/Button';
import Select from 'react-select';

import './DynamicDocuments.scss';

const Delete = ({ onRemove, index }) => {
  const remove = () => {
    onRemove(index);
  };
  return (
    <i className="fa fa-trash" onClick={remove}/>

  );
};

const Content = ({ fields, options }) => {
  const [showAddOptions, setShowAddOptions] = useState(false);
  const remove = (index) => {
    fields.remove(index);
  };
  const showAddSelect = () => {
    setShowAddOptions(true);
  };

  const addOption = (option) => {
    fields.push({
      key: option.value,
      name: option.label
    });
    setShowAddOptions(false);
  };

  return (
    <>
      {fields?.map((field, index) => {
        return (
          <div className={'clone-dynamic-document--row'}>
            <Delete onRemove={remove} index={index}/>
            <Field
              name={`${field}.name`}

              component={HorizontalFormControl}
            />
          </div>

        );
      })}
      {showAddOptions && (
        <Select
          options={options}
          onChange={addOption}
        />)
      }
      {!!options.length && (
        <ButtonBar>

          <Button handleClick={showAddSelect}>
            Add
          </Button>
        </ButtonBar>
      )}

    </>
  );
};
export const DynamicDocument = ({ availableOptions }) => {

  return (
    <div className={'clone-dynamic-document'}>


      <FieldArray
        name={'data.attributes.contents'}
        component={Content}
        options={availableOptions}
      />


    </div>
  );

};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { Box, DataTable, Modal, Button } from '../../../../common/components';
import { RadioGroup } from '../../../../common/components/ReduxFormField';
import { refreshData } from '../../../../common/components/DataTable/redux/dataTableActions';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { hasPermission } from '../../../auth/redux/authActions';
import { updateAccountingPeriod } from '../../redux/accountActions';
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux';
import { FormattedDate, FormattedTime } from 'react-intl';

const FORM_NAME = 'accountPeriodForm';

class AccountPeriodList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded } = this.props;

    if (newProps.submitSucceeded && !submitSucceeded) {
      dispatch(refreshData('accounting-periods'));
      dispatch(closeModal(FORM_NAME));
    }
  }

  formatDate(cell) {
    return (
      <span>
        <FormattedDate value={new Date(cell)} /> <FormattedTime value={new Date(cell)} />
      </span>
    );
  }

  formatBool(cell, row) {
    return <span>{row.open ? 'Open' : 'Locked'}</span>;
  }

  handleRowClick(row) {
    const { dispatch, change, reset } = this.props;

    if (hasPermission('account_ledger.journal_approve')) {
      dispatch(reset());
      dispatch(change('data.id', row.id));
      dispatch(change('data.attributes.open', row.open ? 'true' : 'false'));
      dispatch(openModal(FORM_NAME));
    }
  }

  handleSubmit(values) {
    const { dispatch } = this.props;
    return dispatch(updateAccountingPeriod(values));
  }

  render() {
    const { submitting, handleSubmit } = this.props;

    const title = 'Accounting period';
    const submitButton = (
      <Button
        bsStyle="primary"
        isLoading={submitting}
        label="Save Changes"
        handleClick={handleSubmit(::this.handleSubmit)}
      />
    );

    return (
      <div>
        <Box>
          <h2>Accounting Periods</h2>
          <DataTable
            source="/accounts/accounting-periods"
            name="accounting-periods"
            showTotal
            onRowSelect={::this.handleRowClick}
          >
            <TableHeaderColumn dataField="id" isKey hidden />

            <TableHeaderColumn dataField="period" width={'200px'}>
              Period
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="from_date"
              dataFormat={this.formatDate}
              width={'200px'}
            >
              From Date
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="to_date"
              dataFormat={this.formatDate}
              width={'200px'}
            >
              To Date
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="open"
              dataFormat={this.formatBool}
              width={'200px'}
            >
              Status
            </TableHeaderColumn>
          </DataTable>
        </Box>

        <Modal name={FORM_NAME} title={title} close footer={submitButton}>
          <div className="form-horizontal">
            <div className="col-xs-12">
              <div className="col-xs-12">
                <Field
                  name={'data.attributes.open'}
                  label=""
                  inline
                  options={[
                    { label: 'Open', value: 'true' },
                    { label: 'Locked', value: 'false' },
                  ]}
                  component={RadioGroup}
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const form = reduxForm({ form: FORM_NAME })(AccountPeriodList);

const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state) => {
  const values = selector(state, 'data.id', 'data.attributes.open');

  return {
    formValues: values,
  };
};

export default connect(mapStateToProps)(form);

import React, { Component } from 'react';
import Select from 'react-select';
import selectTheme, { styles } from '../../../../../helpers/selectTheme';

class SelectRegion extends Component {
  getValue = (value) => {
    const { options } = this.props;
    const opt = options.find((opt) => {
      return opt.value === value;
    });
    return opt ? opt : null;
  };

  render() {
    const { input, placeholder, options, clearable, meta: { touched, error } } = this.props;
    const inputValue = input.value.value ? input.value.value : input.value;

    return (
      <div className={(touched && error) ? 'has-error' : ''}>
        <Select
          defaultValue={options}
          styles={styles}
          theme={(theme) => selectTheme(theme)}
          placeholder={placeholder}
          clearable={clearable}
          value={this.getValue(inputValue)}
          onBlur={() => (input.onBlur ? input.onBlur(input.value) : '')}
          onChange={(data) => input.onChange(data ? data.value : '')}
          options={options}
        />
        {touched && error && (
          <span className="input-error">{error}</span>
        )}
      </div>
    );
  }
}

export default SelectRegion;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import AccountForm from './AccountForm';
import accountSchema from '../../../schemas/account';

class AccountCreateContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    account: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
  };

  render() {
    const buttons = (
      <Button bsStyle="default" label="back" isBackButton leftIcon="caret-left" />
    );

    return (
      <InternalContainer title="Account" buttons={buttons}>
        <AccountForm {...this.props} resource={accountSchema} />
      </InternalContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    account: state.account,
  };
}

export default connect(mapStateToProps)(AccountCreateContainer);

import React, { Component } from 'react';
import './styles.scss';
import { connect } from 'react-redux';
import BrokerUsers from './components/BrokerUsers';
import Box from '../../../../../../common/components/Box/Box';
import { FieldArray, formValueSelector, reduxForm } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import { get, set, isEmpty } from 'lodash';

class Users extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { formValues, isLoggedIn, dispatch, change } = this.props;
    return (
      <Box>
        <Row>
          <Col sm={12}>
            <h4 className="page-title">Users</h4>
            <hr />
          </Col>
          <Col sm={12}>
            <FieldArray
              name="data.attributes.users"
              isLoggedIn={isLoggedIn}
              formValues={formValues}
              component={BrokerUsers}
              dispatch={dispatch}
              change={change}
            />
          </Col>
        </Row>
      </Box>
    );
  }
}

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    { field: 'data.attributes.users[0].first_name', name: 'Field' },
    { field: 'data.attributes.users[0].last_name', name: 'Field' },
    { field: 'data.attributes.users[0].email', name: 'Field' },
  ];

  requiredFields.map((required) => {
    const isFieldEmpty = isEmpty(get(values, required.field));
    if (isFieldEmpty) {
      set(errors, required.field, required.name + ' is required');
    }
  });

  errors._error = errors.data;
  return errors;
};

const FORM_NAME = 'BrokerApplication';
const form = reduxForm({ form: FORM_NAME, validate })(Users);
const mapStateToProps = (state, props) => {
  const values = formValueSelector(props.form)(state, 'data', 'data.attributes.users');

  return {
    auth: state.auth,
    initialValues: props.resource,
    form: props.form,
    formValues: values,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    product: state.product,
  };
};

export default connect(mapStateToProps)(form);

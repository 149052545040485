const formatNumber = (value) => {
  if (typeof value === 'string' && value.endsWith('.')) {
    return value;
  }
  if (typeof value === 'string' && value === '-') {
    return value;
  }
  const nf = new Intl.NumberFormat();
  if (value && !Number(value)) {
    return value;
  }
  return value || value === 0 ? nf.format(value) : '';
};

const parseNumber = (value) => {
  if (typeof value === 'number') {
    //just return it no need to parse
    return value;
  }
  const clean = value.replace(/,/g, ''); //remove comma separators
  if (clean === '-') {
    // don't parse half typed negative numbers
    return clean;
  }
  if (clean.endsWith('.') && !Number(clean)) {
    // don't parse half typed decimals
    return '';
  }
  return Number.isNaN(parseFloat(clean)) || value.endsWith('.')
    ? value
    : parseFloat(clean); //convert to float
};

export { formatNumber, parseNumber };

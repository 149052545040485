import React from 'react';
import './SchemeManagementSectionContainer.scss';
import cx from 'classnames';

const SchemeManagementSectionContainer = ({ children, title, subTitle, isViewOnly }) => {
  return (
    <div className="scheme-management-section-container">
      <div>
        <h2>{title}</h2>
      </div>

      <div>
        <div className={
          cx('section-title join', {
            'section-title-active': !isViewOnly,
            'section-title-default': isViewOnly
          })}>
          {subTitle}
        </div>
      </div>
      <div className="scheme-management-section-container-section join">{children}</div>
    </div>
  );
};

export default SchemeManagementSectionContainer;

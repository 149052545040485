import { Alert } from 'react-bootstrap';
import React from 'react';
import './DocumentIsGenerating.scss';

const DocumentIsGenerating = ({ isGenerating }) => {
  if (!isGenerating) {
    return null;
  }
  return (
    <div className={'document-is-generating'}>
      <Alert bsStyle="info" className="margin-top">
        <p>
          A new version of this document is being generated.
          <br/>
          You will receive and email when it has finished.
          <br/>
          You may need to refresh this page after receiving the email in order to see the
          new document
          <br/>
          Please wait for this document to finishing versioning before creating a new
          version of a document within this group
        </p>
      </Alert>
    </div>
  );
};

export default DocumentIsGenerating;

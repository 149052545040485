import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import FaFilePdf from 'react-icons/lib/fa/file';
import FaRefresh from 'react-icons/lib/fa/refresh';
import DocumentListItemContainer
  from '../DocumentListItemContainer/DocumentListItemContainer';
import { Loader } from "../../../../common/components";

import './DocumentListByType.scss';

const Content = ({ summary, isRetrievingDocuments, documentsGenerated, isActive }) => {
  const documentNames = summary ? Object.keys(summary) : [];
  if (!isActive) {
    return null;
  }

  if (isRetrievingDocuments) {
    return <Loader/>;
  }

  if (!documentNames?.length) {
    return <Col sm={6}>No Documents of this type</Col>;
  }

  return (
    <Row>
      {documentNames.map((documentName) => {
        const documents = summary[documentName];
        return (
          <Col sm={6} key={documentName}>
            <DocumentListItemContainer
              documents={documents}
              documentsGenerated={documentsGenerated || []}
            />
          </Col>
        );
      })}
    </Row>
  );
};

const DocumentListByType = ({
  productId,
  onGetDocumentsByType,
  insurerCode,
  documentTypeId,
  isRetrievingDocuments,
  name,
  summary,
  isActive,
  onShowDocumentByType,
  documentsGenerated,
}) => {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const showMoreInfo = () => {
    onGetDocumentsByType(productId, documentTypeId, insurerCode);
    onShowDocumentByType(documentTypeId);
  };
  const onRefresh = () => {
    setIsRefreshing(true);
    Promise.resolve(onGetDocumentsByType(productId, documentTypeId, insurerCode))
      .then(() => {
        onShowDocumentByType(documentTypeId);
      })
      .finally(() => setIsRefreshing(false));
  };

  const hideMoreInfo = () => {
    onShowDocumentByType(undefined);
  };

  return (
    <div
      className={`document-list-by-type ${!isActive && 'section-collapsed'}`}
      onClick={!isActive ? showMoreInfo : undefined}
    >
      <div className="document-content">
        <div className="document-content-header">
          <h4>
            <FaFilePdf/> {name}
            <span className={'refresh'} onClick={onRefresh}>
              <FaRefresh className={isRefreshing ? 'fa-spin' : ''}/>
            </span>
          </h4>
        </div>

        <Content
          summary={summary}
          isRetrievingDocuments={isRetrievingDocuments}
          documentsGenerated={documentsGenerated}
          isActive={isActive}
        />
      </div>
      {isActive && (
        <div onClick={hideMoreInfo} className="close-document-container">
          Close
        </div>
      )}
    </div>
  );
};
export default DocumentListByType;

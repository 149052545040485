import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router';
import MySettings from '../../../auth/containers/MySettings';
import OrganisationSettings from '../../../auth/containers/OrganisationSettings';
import InternalNavigation from '../InternalNavigation/InternalNavigation';
import logo from '../../../../images/brand/logo.svg';
import { FaSignOutAlt } from 'react-icons/fa';
import { logOut, requiresOrganisationSelection } from '../../../auth/redux/authActions';
import { fetchStats } from '../../../dashboard/redux/dashboardActions';
import Icon from '../../../../common/components/Icon/Icon';
import { Avatar } from '../../../../common/components';
import QuickSearch from '../../../dashboard/components/QuickSearch/QuickSearch';
import './InternalHeader.scss';
import { push } from 'react-router-redux';

export default class InternalHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileDropdown: false,
    };
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;

    if (requiresOrganisationSelection()) {
      dispatch(push('/select-organisation'));
    }
  }

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(fetchStats());
    //dispatch(getOrganisationTypes())
  }

  componentWillReceiveProps() {
    if (process.env.BROWSER) {
      this.setState({
        mobileDropdown: false,
      });
    }
  }

  handleLogout(e) {
    e.preventDefault();
    this.props.dispatch(logOut());
  }

  render() {
    const { auth } = this.props;
    //const currentOrganisation = getIncludedResource(auth.user.data, auth.user.included, 'current_organisation')
    return (
      <header className="header">
        <div className="navbar">
          <Link to="/dashboard">
            <img className="logo" src={logo} />
          </Link>
          <div className="info-container">
            <div className="user-info">
              {auth.user.data.attributes.first_name +
                ' ' +
                auth.user.data.attributes.last_name}
              <br />
              <span className="date">
                <div className="broker-support-info">Broker Support: 023 92 419 050</div>{' '}
                {new Date().getUTCDate()} / {new Date().getMonth() + 1} /{' '}
                {new Date().getUTCFullYear()}{' '}
              </span>
              <div className="logout-title" onClick={this.handleLogout}>
                <FaSignOutAlt size={15} /> <span className="logout-title--pl">Log out</span>
              </div>
            </div>
            <div className="img-fluid" style={{ border: 'transparent' }}>
              <Avatar user={auth.user} />
            </div>
          </div>
          <div className="quick-search-container">
            <QuickSearch />
          </div>
          <div className="burgerMenu">
            <Button
              className="burgerMenuBt"
              onClick={() =>
                this.setState({ mobileDropdown: !this.state.mobileDropdown })
              }
            >
              <Icon name="bars" />
            </Button>
            <div className="dropmenu mobile-dropdown-menu-box">
              {this.state.mobileDropdown && (
                <div>
                  <InternalNavigation {...this.props} />
                  <div className="divider" />
                  <span className="logout-bt-mobile" onClick={this.handleLogout}>
                    <Icon name="sign-out" />
                    Logout
                  </span>
                  <span
                    className="close-area-mobile-menu"
                    onClick={() =>
                      this.setState({ mobileDropdown: !this.state.mobileDropdown })
                    }
                  />
                </div>
              )}
            </div>
            <MySettings {...this.props} />
            <OrganisationSettings {...this.props} />
          </div>
        </div>
      </header>
    );
  }
}

InternalHeader.propTypes = {
  auth: PropTypes.object.isRequired,
  layout: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

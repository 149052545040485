import React from 'react';
import { Field } from 'redux-form';
import PlainTextField from './PlainTextField';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';
import './styles.scss';

const ExpiryDateFields = ({ baseName, label, infoButton, labelSizes, fieldSizes }) => {
  const monthField = (
    <Field
      className="text-center"
      name={baseName + '.expiry_month'}
      placeholder="mm"
      component={PlainTextField}
    />
  );

  const yearField = (
    <Field
      className="text-center"
      name={baseName + '.expiry_year'}
      placeholder="yy"
      component={PlainTextField}
    />
  );

  let smFieldSize = 5;
  let mdFieldSize = 5;
  let lgFieldSize = 5;

  if (fieldSizes.sm) {
    smFieldSize = fieldSizes.sm / 2;
  }
  if (fieldSizes.md) {
    mdFieldSize = fieldSizes.md / 2;
  }
  if (fieldSizes.lg) {
    lgFieldSize = fieldSizes.lg / 2;
  }


  return (
    <FormGroup>
      <Col
        sm={labelSizes.sm || 2}
        md={labelSizes.md || 2}
        lg={labelSizes.lg || 2}
        componentClass={ControlLabel}
      >
        {label} {infoButton}
      </Col>
      <Col
        sm={smFieldSize}
        md={mdFieldSize}
        lg={lgFieldSize}
      >
        {monthField}
      </Col>
      <Col
        sm={smFieldSize}
        md={mdFieldSize}
        lg={lgFieldSize}
      >
        {yearField}
      </Col>
    </FormGroup>
  );
};

export default ExpiryDateFields;

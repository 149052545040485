import { isEqual, isObject, transform } from 'lodash';

export const difference = (newValues, existingValues) => {
  function changes(newValues, existingValues) {
    return transform(newValues, function (result, value, key) {
      if (!isEqual(value, existingValues[key])) {
        //TODO may need to make arrays that are excluded from diff more flexible
        if (['exclude_destinations', 'scheme_details'].includes(key)) {
          result[key] = value;
        }
        const compareObjects =
          isObject(value) &&
          isObject(existingValues[key]) &&
          ['exclude_destinations', 'scheme_details'].includes(key);
        result[key] = compareObjects ? changes(value, existingValues[key]) : value;
      }
    });
  }

  return changes(newValues, existingValues);
};

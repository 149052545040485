import { Modal } from 'react-bootstrap';
import React, { useMemo } from 'react';
import EditDeclineForm from './EditDeclineForm/EditDeclineForm';
import EditOptionDeclineForm from './EditDeclineForm/EditOptionDeclineForm';
import { getDeclinesList } from '../../../helpers/declineHelpers';

const EditDeclineModal = ({
  show,
  onClose,
  decline,
  regions,
  isOptionDecline,
  options,
  coverLevels,
  medicalQuestionKeys,
  onUpdateDecline,
  productType,
}) => {

  const referral = decline?.decline ?? decline?.check;


  const declineName = Object.keys(referral)[0];
  const isOptionKey = !!decline?.name?.startsWith('options');
  const declinesList = useMemo(
    () => (!isOptionKey ? getDeclinesList(declineName, decline, productType) : []),
    [declineName, decline, isOptionKey, productType],
  );
  const optionName = decline?.option?.key
    ? `options.${decline.option.key}`
    : decline.name;
  const optionValue = decline?.option?.value ?? referral.equal;

  const isDecline = !!decline?.decline;
  const teamFactor = decline?.team_factor;


  return (
    <>
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Decline</Modal.Title>
        </Modal.Header>
        {!isOptionDecline && (
          <EditDeclineForm
            declines={declinesList}
            reason={decline.reason}
            regions={regions}
            coverLevels={coverLevels}
            medicalQuestionKeys={medicalQuestionKeys}
            teamFactor={teamFactor}
            onUpdateDecline={onUpdateDecline}
            productType={productType}
            referralType={isDecline ? 'decline' : 'check'}
          />
        )}
        {isOptionDecline && (
          <EditOptionDeclineForm
            declines={declinesList}
            reason={decline.reason}
            regions={regions}
            options={options}
            teamFactor={teamFactor}
            coverLevels={coverLevels}
            medicalQuestionKeys={medicalQuestionKeys}
            optionName={optionName}
            optionValue={optionValue}
            isOptionKey={isOptionKey}
            onUpdateDecline={onUpdateDecline}
            productType={productType}
            referralType={isDecline ? 'decline' : 'check'}
          />
        )}
      </Modal>
    </>
  );
};

export default EditDeclineModal;

import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { FormSection, getFormValues, reduxForm } from 'redux-form';
import ScreeningContainer from '../../../../../screening/containers/ScreeningContainer';
import { MedicalQuestionRow } from '../MedicalQuestionRow/MedicalQuestionRow';
import './styles/MedicalTab.scss';

const getCategoryQuestions = (questions, category) => {
  if (!questions) {
    return {};
  }

  return Object.keys(questions).reduce((carry, questionKey) => {
    const question = questions[questionKey];
    if (question?.category === category) {
      carry[questionKey] = question;
    }
    return carry;
  }, {});
};

const Screening = ({
  travellers,
  region,
  schemeType,
  destinations,
  path,
  onScreeningComplete,
  startIndex
}) => {
  return (
    <>
      {travellers.map((traveller, index) => {

          return (
            <ScreeningContainer
              key={index}
              index={startIndex + index + 1}
              traveller={traveller}
              region={region}
              tripType={schemeType}
              destinations={destinations}
              path={`${path}.${index}`}
              callback={onScreeningComplete}

            />
          );
        },
      )}
    </>
  );
};

const MedicalTab = ({
    isScreeningRequired,
    formValues,
    onScreeningComplete
  }) => {
    const adults = formValues?.data?.attributes?.metadata?.adults ?? [];
    const children = formValues?.data?.attributes?.metadata?.children ?? [];
    const region = formValues?.data?.attributes?.metadata?.region ?? '';
    const tripType = formValues?.data?.attributes?.metadata?.scheme_type;
    const destinations = formValues?.data?.attributes?.metadata?.destinations ?? [];
    const questions = formValues?.data?.attributes?.metadata?.medical_declaration_questions;
    const healthQuestions = useMemo(() => getCategoryQuestions(questions, 'health'), [questions]);
    const medicalQuestions = useMemo(() => getCategoryQuestions(questions, 'medical'), [questions]);

    return (
      <div className={'wl-medical-tab'}>

        <div className="questions">
          <FormSection name={'data.attributes.metadata.medical_declaration_questions'}>
            <>
              {Object.keys(healthQuestions).map((questionKey) => {
                return (
                  <MedicalQuestionRow
                    question={healthQuestions[questionKey]}
                    questionKey={questionKey}/>
                );

              })}
              {Object.keys(medicalQuestions).map((questionKey) => {
                return (
                  <MedicalQuestionRow
                    question={medicalQuestions[questionKey]}
                    questionKey={questionKey}/>
                );
              })}
            </>
          </FormSection>
        </div>

        <hr/>
        {isScreeningRequired && (
          <div className="row medical-screening">
            <div className="col-xs-12 screening-message">
              <p className="control-label">
                Please note: <br/>
                Any medical conditions(s) not declared to and accepted by us, will not be
                covered.
              </p>
            </div>

            <Screening
              travellers={adults}
              region={region}
              schemeType={tripType}
              startIndex={0}
              destinations={destinations}
              path={'data.attributes.metadata.adults'}
              onScreeningComplete={onScreeningComplete}
            />
            <Screening
              travellers={children}
              region={region}
              schemeType={tripType}
              startIndex={adults.length + 1}
              destinations={destinations}
              path={'data.attributes.metadata.children'}
              onScreeningComplete={onScreeningComplete}
            />

          </div>
        )}

      </div>

    );
  }
;


const FORM_NAME = 'WhitelabelTravelForm';
export default connect(
  (state) => ({
    //initialValues: ownProps.initialValues,
    formValues: getFormValues(FORM_NAME)(state),
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })
)(reduxForm({ form: FORM_NAME })(MedicalTab));

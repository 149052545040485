import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InternalContainer from '../../layout/containers/Internal';
import OrganisationList from '../components/OrganisationList/OrganisationList';

class OrganisationListContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    organisation: PropTypes.object.isRequired,
  };

  render() {
    return (
      <InternalContainer title="Admin / Organisations">
        <OrganisationList {...this.props} />
      </InternalContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    organisation: state.organisation,
  };
}

export default connect(mapStateToProps)(OrganisationListContainer);

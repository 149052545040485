import { hasPermission, requiresOrganisationSelection } from '../modules/auth/redux/authActions';
import { toastr } from 'react-redux-toastr';

const requirePermission = (permission) => {
  return (nextState, replace, callback) => {
    if (requiresOrganisationSelection()) {
      replace({
        pathname: '/select-organisation',
      });
      toastr.warning('Please select an organisation');
    } else if (!hasPermission(permission)) {
      replace({
        pathname: '/dashboard',
      });
      toastr.error("You don't have permission to perform the requested action");
    }
    callback();
  };
};

export default requirePermission;

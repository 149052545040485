import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormGroup, Col, ControlLabel } from 'react-bootstrap';
import SelectMenu from '../SelectMenu/SelectMenu';

export const SELECT_IDENTIFIER = 'account';

class SelectCashAccountAccount extends Component {
  render() {
    const { infoButton, name, meta, label, labelSize, id = 'selectCash' } = this.props;
    const source = 'accounts';
    const filterUrl = '?filter[type]=cash';

    return (
      <FormGroup
        controlId={name}
        className={meta && meta.touched && meta.error ? ' has-error' : ''}
      >
        {label && (
          <Col componentClass={ControlLabel} sm={labelSize}>
            {label} {infoButton}
          </Col>
        )}
        <Col sm={12 - labelSize}>
          <SelectMenu
            source={source}
            sourceKey={id}
            optionValuePath={'id'}
            filterUrl={filterUrl}
            {...this.props}
          />
        </Col>
      </FormGroup>
    );
  }
}

SelectCashAccountAccount.propTypes = {
  dispatch: PropTypes.func,
  label: PropTypes.string,
  labelSize: PropTypes.number,
  input: PropTypes.object.isRequired,
  infoButton: PropTypes.element,
  name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  meta: PropTypes.object,
  object: PropTypes.bool,
  multi: PropTypes.bool,
  filters: PropTypes.array,
};

export default connect()(SelectCashAccountAccount);

import React, { useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field } from 'redux-form';
import { numericality, required } from 'redux-form-validators';
import {
  HorizontalFormControl,
  Icon,
  InfoButton,
} from '../../../../../../common/components';
import { SelectField } from '../../../../../../common/components/ReduxFormField';

import {
  getAllowedInputs,
  getValidInputs,
  getValidRules,
} from '../../../../helpers/declineHelpers';
import parseInteger from '../../../../../../helpers/parseInteger';
import { startCase } from 'lodash';

const inputDefinition =
  'The input that this rule will use - \n\n * Option - if an option is selected\n * Max Age - the max age of the oldest traveller * Trip duration  - single trip duration\n ';
const ruleHelp = 'The rule that you want to apply';

const EditDeclineField = ({
  input,
  onRemove,
  index,
  declines,
  regions,
  coverLevels,
  medicalQuestionKeys,
  productType,
}) => {
  const { input: inputName, type: inputType, rule, value } = input.value;


  const validInputs = useMemo(() => getValidInputs(inputName, declines, productType, medicalQuestionKeys), [
    inputName,
    declines,
    productType,
    medicalQuestionKeys,
  ]);

  const validRules = useMemo(() => getValidRules(inputType, declines), [
    inputType,
    declines,
  ]);

  useEffect(
    () => {
      const newInputType = getAllowedInputs(productType, medicalQuestionKeys).find(
        (allowedInput) => allowedInput.value === inputName,
      );

      if (newInputType?.type) {
        input.onChange({
          ...input.value,
          // rule: '',
          type: newInputType.type,
        });
      }
    },
    [inputName],
  );

  const handleRemove = () => {
    onRemove(index);
  };

  const inputNameKey = inputName + index || 'newValue' + index;
  return (
    <div key={index}>
      <Row>
        <Col sm={12}>
          <span className="clickable pull-right" onClick={handleRemove}>
            <Icon name="trash"/>
          </span>
        </Col>
      </Row>
      <Field
        name={`${input.name}.input`}
        label={'Input:'}
        infoButton={<InfoButton content={inputDefinition}/>}
        fieldSize={9}
        labelSize={3}
        isClearable
        key={`${inputNameKey}`}
        component={SelectField}
        options={validInputs}
        validate={required({ message: 'Required' })}
      />

      <Field
        name={`${input.name}.rule`}
        smFieldSize={9}
        label={'Rule:'}
        infoButton={<InfoButton content={ruleHelp}/>}
        labelSize={3}
        options={validRules}
        isClearable
        key={`${inputName}|${rule}`}
        component={SelectField}
        validate={required({ message: 'Required' })}
      />
      {inputType === 'int' && (
        <Field
          name={`${input.name}.value`}
          smFieldSize={9}
          label={'Value:'}
          labelSize={3}
          component={HorizontalFormControl}
          validate={numericality({ message: 'Must be a Number', allowBlank: false })}
          normalize={parseInteger}
        />
      )}

      {inputType === 'boolean' && (
        <Field
          name={`${input.name}.value`}
          smFieldSize={9}
          label={'Value:'}
          labelSize={3}
          component={SelectField}
          options={[
            {
              label: 'Yes',
              value: true
            },
            {
              label: 'No',
              value: false
            }
          ]
          }
          validate={required({ message: 'Required' })}
          // normalize={}
        />
      )}

      {inputType === 'array' && inputName === 'region' && (
        <Field
          name={`${input.name}.value`}
          smFieldSize={9}
          label={'Value:'}
          labelSize={3}
          component={SelectField}
          isMulti
          validate={required({ message: 'Required' })}
          options={regions.map((region) => ({ value: region, label: startCase(region) }))}
        />
      )}

      {inputType === 'array' && inputName === 'cover_level' && (
        <Field
          name={`${input.name}.value`}
          smFieldSize={9}
          label={'Value:'}
          labelSize={3}
          component={SelectField}
          isMulti={!value || Array.isArray(value)}
          validate={required({ message: 'Required' })}
          options={coverLevels.map((coverLevel) => ({
            value: coverLevel,
            label: startCase(coverLevel),
          }))}
        />
      )}

      {inputType === 'ipt' && (
        <Field
          name={`${input.name}.value`}
          smFieldSize={9}
          label={'Value:'}
          labelSize={3}
          component={SelectField}
          isMulti={false}
          validate={required({ message: 'Required' })}
          options={[
            {
              value: 'IPT - Zero Rate',
              label: 'Tax Exempt',
            }
          ]}
        />
      )}

      {['string', 'date'].includes(inputType) && (
        <Field
          name={`${input.name}.value`}
          smFieldSize={9}
          label={'Value:'}
          labelSize={3}
          component={HorizontalFormControl}
        />
      )}
    </div>
  );
};

export default EditDeclineField;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, DataTable } from '../../../../common/components';
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { FormControl, ControlLabel } from 'react-bootstrap';
import { push } from 'react-router-redux';
import { FormattedDate } from 'react-intl';

export default class EnquiryList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    enquiry: PropTypes.object.isRequired,
  };

  formatDate(cell) {
    return <FormattedDate value={new Date(cell)} />;
  }

  handleRowClick(row) {
    this.props.dispatch(push('/admin/enquiries/' + row.id));
  }

  handleFilterUpdate(event) {
    this.props.dispatch(applyFilter('enquiries', 'filter[status]', event.target.value));
  }

  handleSearchUpdate(event) {
    this.props.dispatch(applyFilter('enquiries', 'filter[search]', event.target.value));
  }

  render() {
    // const complaintStatuses = [
    //   { label: 'received', value: 'received' },
    //   { label: 'responded', value: 'responded' },
    // ]

    return (
      <div>
        <Box>
          <div className="row">
            <div className="col-sm-4">
              <ControlLabel className="filter-label">Search</ControlLabel>
              <FormControl onChange={::this.handleSearchUpdate} />
            </div>
            <div className="col-sm-4" />
            {/*<div className="col-sm-4">*/}
            {/*<ControlLabel className="filter-label">Status</ControlLabel>*/}
            {/*<FormControl componentClass="select"*/}
            {/*type="select"*/}
            {/*onChange={::this.handleFilterUpdate}*/}
            {/*placeholder="select"*/}
            {/*>*/}
            {/*<option value="">Select...</option>*/}
            {/*{complaintStatuses.map((status, key) => {*/}
            {/*return ( <option key={key} value={status.value}>{status.label}</option>)*/}
            {/*})}*/}
            {/*</FormControl>*/}
            {/*</div>*/}
          </div>
        </Box>

        <Box>
          <DataTable
            source="/enquiry"
            name="enquiries"
            showTotal
            onRowSelect={this.handleRowClick.bind(this)}
          >
            <TableHeaderColumn dataField="id" isKey hidden />
            <TableHeaderColumn dataField="name" dataSort width={'20%'}>
              Name
            </TableHeaderColumn>
            <TableHeaderColumn dataField="email" dataSort width={'20%'}>
              Email
            </TableHeaderColumn>
            <TableHeaderColumn dataField="status" dataSort width={'20%'}>
              Status
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="created_at"
              dataFormat={this.formatDate}
              width={'20%'}
            >
              Created At
            </TableHeaderColumn>
            <TableHeaderColumn dataField="message" dataSort width={'40%'}>
              Message
            </TableHeaderColumn>
          </DataTable>
        </Box>
      </div>
    );
  }
}

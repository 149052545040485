import * as actions from './roleReducer';
import Axios from 'axios';
import { push } from 'react-router-redux';
import queryString from '../../../helpers/queryString';
import handleErrorResponse from '../../../helpers/handleErrorResponse';
import { toastr } from 'react-redux-toastr';
import { ADD_RESOURCE } from '../../layout/redux/breadcrumb';

export const defaultIncludes = ['permissions', 'organisation_type'];

export function isSubmitting(status) {
  return {
    type: actions.IS_SUBMITTING,
    status,
  };
}

export function getRole(id, includes = defaultIncludes) {
  return (dispatch) => {
    const endpoint = 'roles/' + id + queryString(includes);

    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: actions.SET_ROLE,
          data: response.data,
        });
        dispatch({
          type: ADD_RESOURCE,
          id: response.data.data.id,
          name: response.data.data.attributes.name,
        });
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function createRole(resource) {
  return (dispatch) => {
    return Axios.post('roles', resource)
      .then((response) => {
        dispatch(isSubmitting(false));
        dispatch(push('/admin/roles/' + response.data.data.id));
        toastr.success('Success', 'Role has been created successfully');
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'there was an error creating your role');
      });
  };
}

export function updateRole(resource, includes = defaultIncludes) {
  return (dispatch) => {
    dispatch(isSubmitting(true));
    const id = resource.data.id;
    let endpoint = 'roles/' + id;
    endpoint += queryString(includes);

    return Axios.patch(endpoint, resource)
      .then(() => {
        dispatch(isSubmitting(false));
        dispatch(push('/admin/roles/' + id));
        toastr.success('Success', 'Role has been updated successfully');
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'there was an error updating your role');
      });
  };
}

export function getPermissions() {
  return (dispatch) => {
    return Axios.get('permissions?page[limit]=500')
      .then((response) => {
        dispatch({
          type: actions.SET_PERMISSIONS,
          data: response.data.data,
        });
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

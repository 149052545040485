export const OPEN_SIDEBAR = 'socrates-online/sidebar/OPEN_SIDEBAR';

const initialState = {
  menu: true,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN_SIDEBAR:
      return {
        ...state,
        menu: action.menu,
      };
    default:
      return state;
  }
}

export function openMenu(menu) {
  return (dispatch) => {
    dispatch({
      type: OPEN_SIDEBAR,
      menu: menu,
    });
  };
}

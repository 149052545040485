import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field } from 'redux-form';
import {
  HorizontalFormControl,
  Icon,
  InfoLabel,
} from '../../../../../common/components';
import parseFloatValue from '../../../../../helpers/parseFloatValue';
import { numericality } from 'redux-form-validators';
import SelectField from '../../../../../common/components/ReduxFormField/SelectField';

const OptionHazardousRatesTable = ({ input, isViewOnly }) => {
  const options = input.value.options;
  const rateType = input.value.rate_type;
  const name = input.name;

  return (
    <>
      <Col sm={5}>
        <Field
          name={`${name}.rate_type`}
          label={'Rate Type:'}
          labelSize={4}
          smFieldSize={4}
          component={isViewOnly ? InfoLabel : SelectField}
          isDisabled={isViewOnly}
          options={[
            { value: 'loading', label: 'Loading' },
            { value: 'fixed', label: 'Fixed' },
          ]}
        />
      </Col>

      <Row>
        <Col sm={12}>
          {options.map((option, index) => {
            return (
              <Col sm={3}>
                <Field
                  name={`${name}.options.${index}.rate`}
                  label={option.key}
                  labelSize={2}
                  fieldSize={8}
                  component={isViewOnly ? InfoLabel : HorizontalFormControl}
                  disabled={isViewOnly}
                  validate={
                    numericality({
                      message: 'Must be a number',
                      allowBlank: true,
                    })}

                  normalize={parseFloatValue}

                  addonBefore={
                    !isViewOnly && rateType === 'fixed' ? (
                      <Icon name={'pound-sign'}/>
                    ) : undefined
                  }
                  addonAfter={
                    !isViewOnly && rateType === 'loading' ? (
                      <Icon name={'percent'}/>
                    ) : undefined
                  }
                />
              </Col>
            );
          })}
        </Col>
      </Row>
    </>
  );
};
export default OptionHazardousRatesTable;

import { endsWith, isFinite } from 'lodash';

const parseFloatValue = (value) => {
  if (!value) {
    return value;
  }
  return endsWith(value, '.') || !isFinite(parseFloat(value)) ? value : parseFloat(value);
};

export default parseFloatValue;

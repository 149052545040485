import React from 'react';
import { Modal } from 'react-bootstrap';

import EditBrandDetailsForm from '../EditBrandDetailsForm/EditBrandDetailsForm';

export const EditBrandModal = ({ show, onHide }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
          <span className={'title-case'}>
             Edit Brand
          </span>

      </Modal.Header>
      <Modal.Body>
        <div>
          {show && (
            <EditBrandDetailsForm
              resource={show}
              onUpdateComplete={onHide}
            />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

import React from 'react';

export const content = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95 95">
    <title>Icons</title>
    <path d="M82.5,31.25v47.5a3.75,3.75,0,0,1-3.75,3.75H41.25a3.75,3.75,0,0,1-3.75-3.75V67.5H16.25a3.75,3.75,0,0,1-3.75-3.75V37.5a10.47,10.47,0,0,1,2.66-6.41L31.09,15.16A10.48,10.48,0,0,1,37.5,12.5H53.75a3.75,3.75,0,0,1,3.75,3.75V29.06a10.81,10.81,0,0,1,5-1.56H78.75A3.75,3.75,0,0,1,82.5,31.25Zm-30,2.5V17.5h-15V33.75a3.75,3.75,0,0,1-3.75,3.75H17.5v25h20v-10a10.47,10.47,0,0,1,2.66-6.41ZM20.82,32.5H32.5V20.82Zm56.68,0h-15V48.75a3.75,3.75,0,0,1-3.75,3.75H42.5v25h35Zm-31.68,15H57.5V35.82Z" />
  </svg>
);

export default content;

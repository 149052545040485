import { Label } from 'react-bootstrap';
import React from 'react';

const labelStyle = (account) => {
  if (account.data.attributes.suspended) {
    return 'danger';
  } else if (account.data.attributes.limit < account.data.attributes.balance) {
    return 'warning';
  } else {
    return 'success';
  }
};
const AccountLabel = ({ account }) => {


  const label = labelStyle(account);
  return (
    <Label className="pull-right" bsStyle={label}>
      {account.data.attributes.formatted_balance}
    </Label>
  );
};
export default AccountLabel;

import LedgerEntry from '../../LedgerEntry';
import React from 'react';
import { MAPPING } from '../../helpers';



const Container = ({ children, balance, title }) => {
  return (<div className="sortable-container">
    <h4>
      {title}
      <span className="pull-right">
           {balance}
          </span>
    </h4>
    {children}
  </div>);
};
export const LedgerEntryContainer = ({ type, isLoading, entries, onMoveEntry, balance }) =>  {

  const typeMap = MAPPING[type];
  ////onItemSelect={() => this.moveItem(item, type, typeMap.onSelectXfrTo)}
  // const moveItem = (type) => {
  //
  // }

  const moveItem = (item) => {
    onMoveEntry(item, type, typeMap.onSelectXfrTo);
  };


  if (isLoading) {
    return (
      <Container
        title={typeMap.title}
        balance={0}
      >
        <div className="loading__center-container">
            <span className="loader-dots">
              <i className="fa fa-circle loader-dot"/>
              <i className="fa fa-circle loader-dot"/>
              <i className="fa fa-circle loader-dot"/>
            </span>
        </div>
      </Container>);
  }

  return (

    <Container
      title={typeMap.title}
       balance={balance}
      // {/*{this.getBalance(loader ? 0 : entries)}*/}
    >
      <div className="items">
        {entries?.map((item, i) => (
          <LedgerEntry
            key={i}
            item={item}
            selected={typeMap.selected}

            onItemSelect={moveItem}
          />
        ))}
      </div>
    </Container>
  );
};

import { currentOrganisationType, hasPermission } from '../auth/redux/authActions';
import moment from 'moment';
const formatDate = (date) => {
  /**
   * convert a given string to a date ISO format
   *  @param:  string: 'DD/MM/YYYY'
   *  @return: string: 'YYYY-MM-DDTHH:MM:SS'
   */
  if (!date) {
    return '';
  }
  const isValid = moment(date).isValid();
  if (!isValid) {
    return moment(date, 'DD/MM/YYYY')
      .utcOffset(0, true)
      .format();
  }
  return date;
};

export const getWlProductInitialValues = (currentCustomer, application, productMedicalQuestions, productOptions, productCode, productId, isMta, nextQuoteReference, policyId) => {

  const options = productOptions.reduce((carry, opt) => {
    carry[opt?.key] = application?.data?.attributes?.metadata?.options?.[opt?.key];
    return carry;

  }, {});

  const adults = application ? application?.data?.attributes?.metadata?.adults : [
    {
      title: currentCustomer?.title ?? '',
      first_name: currentCustomer?.first_name ?? '',
      last_name: currentCustomer?.last_name ?? '',
      dob: formatDate(currentCustomer?.dob) ?? '',
      email: currentCustomer?.email ?? '',
      phone1: currentCustomer?.phone1 ?? '',
      phone2: currentCustomer?.phone2 ?? '',
      conditions_covered: true,
      screening: '',
      metadata: {
        doctor_form_info: '',
      },
      screening_id: '',
      address: {
        line1: currentCustomer?.address?.line1 ?? '',
        line2: currentCustomer?.address?.line2 ?? '',
        line3: currentCustomer?.address?.line3 ?? '',
        town: currentCustomer?.address?.town ?? '',
        county: currentCustomer?.address?.country ?? '',
        postcode: currentCustomer?.address?.postcode ?? ''
      },
      marketing_options: {
        email: undefined,
        post: undefined,
        sms: undefined,
        phone: undefined

      }
    }
  ];
  const children = application?.data?.attributes?.metadata?.adults ?? [];
  const isRenewal = application?.data?.attributes?.quote_type === 'renewal';

  const productMedical = Object.keys(productMedicalQuestions).reduce((carry, medicalKey) => {

    carry[medicalKey] = {
      ...productMedicalQuestions[medicalKey],
      answer: undefined
    };
    return carry;
  }, {});

  const applicationMedical = application?.data?.attributes?.metadata?.medical_declaration_questions;

  const channelKey = currentOrganisationType() === 'Broker' ? 'pjh-b2b' : 'call-centre';

  const applicationMetadata = {
    ...application?.data?.attributes?.metadata,
    options: options,
  };

  const metadata = application ? applicationMetadata : {
    residency: 'uk',
    source: application?.data?.attributes?.metadata?.source ?? '',
    scheme_type: application?.data?.attributes?.metadata?.scheme_type ?? '',
    traveller_group: application?.data?.attributes?.metadata?.traveller_group ?? '',
    region: application?.data?.attributes?.metadata?.region ?? '',
    max_trip_duration: application?.data?.attributes?.metadata?.max_trip_duration ?? '',
    destinations: application?.data?.attributes?.metadata?.destinations ?? [],
    residency_declaration: application?.data?.attributes?.metadata?.residency_declaration ?? '',
    start_date: application?.data?.attributes?.metadata?.start_date ?? '',
    end_date: application?.data?.attributes?.metadata?.end_date ?? '',
    referred_activities: application?.data?.attributes?.metadata?.referred_activities ?? '',
    activities: application?.data?.attributes?.metadata?.activities ?? '',
    adults: adults,
    children: children,
    screening: true,
    options: options,
    overrides: {},
    campaign: isMta ? application?.data?.attributes?.metadata?.campaign : undefined,
    aggregator_quote_date: undefined,
    signposted: false,
    communications: application ? application?.data?.attributes?.metadata?.communications : {
      post: undefined,
      email: undefined
    }
  };


  const relationships = application ? {
    relationships: {
      ...application?.data?.relationships,
      policy: {
        data: {
          id: policyId
        }
      },
      product: {
        data: {
          id: productId
        }
      }

    }
  } ?? {} : {
    relationships: {
      source: {
        id: '',
      },
      schemes: {
        data: [],
      },
      product: {
        data: {
          id: productId
        }
      }
    },
  };
  return {
    data: {
      id: application?.data?.id,
      mta: isMta,
      current_tab: 1,
      type: 'application',

      attributes: {
        quote_reference: nextQuoteReference,
        quote_type: '',

        metadata: {
          ...metadata,
          product_code: productCode,
          sales_channel: channelKey,
          medical_declaration_questions: application && !isRenewal ? applicationMedical : productMedical,


        }
      },
      ...relationships,

    },
    meta: {
      channel_key: channelKey,
      note: ''
    }
  };
};

const wlMapReactSelect = (options) => {
  return options?.map((option) => (
    {
      label: option.value,
      value: option.key
    }));

};

export const getSelectOptions = (metadata) => {
  const {
    regions,
    annual_trip_durations: maxTrip,
    residencies,
    trip_types: tripTypes,
    group_type_trip_single: stGroups,
    group_type_trip_annual: amtGroups
  } = metadata ?? {};

  return {
    regions: wlMapReactSelect(regions),
    maxTripDurations: wlMapReactSelect(maxTrip),
    residencies: wlMapReactSelect(residencies),
    tripTypes: wlMapReactSelect(tripTypes),
    singleGroupTypes: wlMapReactSelect(stGroups),
    annualGroupTypes: wlMapReactSelect(amtGroups)
  };
};

export const getMedicalDeclaration = (content) => {
  const { sections } = content?.attributes ?? {};
  return Object.keys(sections).reduce((carry, questionKey) => {
    carry[questionKey] = {
      ...sections[questionKey],
      answer: undefined
    };
    return carry;
  }, {});

};

export const requiresScreening = (questions) => {
  if (!questions) {
    return false;
  }
  return !!Object.values(questions).find((question) => {
    return question.type === 'boolean' && question.show_medical && question.answer;
  });
};

export const doTravellersHaveConditions = (adults, children) => {
  const allTravellers = adults.concat(children);
  return allTravellers.reduce((carry, traveller) => {
      return carry || !!traveller?.screening?.meta?.conditions?.length;
    },
    false,
  );
};




export const productReferrals = (checkQuotes) => {


  return Object.keys(checkQuotes)?.reduce((carry, schemeId) => {
    const quote = checkQuotes[schemeId];

    const referrals = quote?.meta?.referrals ?? [];
    if (referrals?.length > 0) {

      referrals.forEach((referral) => {
        if (!carry['reasons'].includes(referral.reason)) {
          carry['reasons'].push(referral.reason);
        }
        const maxTeamFactor = carry['maxTeamFactor'];
        if (!maxTeamFactor) {
          carry['maxTeamFactor'] = referral.max_team_factor;
        }
        if (maxTeamFactor < referral.max_team_factor) {
          carry['maxTeamFactor'] = referral.max_team_factor;
        }
      });
    }
    return carry;
  }, {
    reasons: [],
    maxTeamFactor: undefined
  });


};

export const getReferralRequired = (referralQuotes, userMaxRefLimit) => {
  const reasons = referralQuotes.reasons;
  const maxTeamFactor = referralQuotes.maxTeamFactor;

  return reasons.length && userMaxRefLimit < maxTeamFactor;
};

export const getHasWintersSportsExclusion = (adults, children) => {

  const travellers = adults?.concat(children);
  return travellers?.find((traveller) => {
    return traveller?.screening?.meta?.hasWinterSportsExclusion;

  });
};
export const backDateCover = (days) => {
  if (hasPermission('policy.backdate_cover')) {
    return moment()
      .subtract(days, 'days');
  }
  return moment();
};

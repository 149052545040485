import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Box,
  DataTable,
  HorizontalFormControl,
  Button,
  Modal
} from '../../../../common/components';
import _ from 'lodash';
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { FormControl, ControlLabel } from 'react-bootstrap';
import { push } from 'react-router-redux';
import { FormattedDate, FormattedTime } from 'react-intl';
import UploadModalComponent, { UploadModal } from '../../../../common/components/Upload/UploadModal';
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux';
import { formValueSelector, reduxForm, Field } from 'redux-form';
import { clearFiles, removeFile } from '../../../../common/components/Upload/UploadRedux';
import { addNonRenewals } from '../../redux/policyActions';


const FORM_NAME = 'nonRenewalForm';

const validate = (/*values*/) => {
  // const errors = {}
  //
  // if (_.get(values, 'data.relationships.schemes.data', []).length === 0) {
  //   _.set(errors, 'data.relationships.schemes.data', 'At least one scheme must be selected')
  // }
  //
  // if (!_.get(values, 'data.attributes.name', false)) {
  //   _.set(errors, 'data.attributes.name', 'Name is required')
  // }
  //
  // if (!_.get(values, 'data.attributes.from_date', false)) {
  //   _.set(errors, 'data.attributes.from_date', 'Date is required')
  // }
  //
  // return errors
};

class NonRenewalList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    currentBroker: PropTypes.object,
    auth: PropTypes.object.isRequired,
    policy: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleUpload = this.handleUpload.bind(this);
    this.handleUploadComplete = this.handleUploadComplete.bind(this);
  }

  formatDate(cell) {
    return (
      <span><FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/></span>
    );
  }

  handleRowClick(row) {
    // this.props.dispatch(push('/policies/issued/' + row.id))
    if (! row.relationships.lapsed_renewal_policy_application.data.length) {
      return;
    }
    const lapsedRenewalApplicationId = row.relationships.lapsed_renewal_policy_application.data[0].id;
    this.props.dispatch(push('/policies/applications/' + lapsedRenewalApplicationId));
  }

  handleSearchUpdate(event) {
    this.props.dispatch(applyFilter('renewals', 'filter[policy_number]', event.target.value));
  }

  handleUpload() {
    this.props.dispatch(openModal(UploadModal.MODAL_IDENTIFIER));
  }

  handleUploadComplete() {
    const { dispatch, change, files } = this.props;

    if (!files.length) {
      return;
    }

    // Use the last file
    const file = files[files.length - 1];

    dispatch(change('data.relationships.file.data', file));
    dispatch(change('data.attributes.file_name', file.attributes.name));
    dispatch(change('data.attributes.file_url', file.attributes.url));
  }

  handleSubmit(values) {
    return this.props.dispatch(addNonRenewals(values, (dispatch) => {
      dispatch(closeModal('nonRenewalForm'));
    }));
  }

  handleButtonClick() {
    this.props.dispatch(openModal(FORM_NAME));
  }

  render() {
    const { submitting, handleSubmit, formValues } = this.props;

    const title = 'Renewal Management';
    const submitButton = (
      <div>
        <Button
          type="button"
          bsStyle="primary"
          className="pad-right"
          disabled={submitting}
          handleClick={::this.handleUpload}>
          {submitting ? <em className="fa fa-cog fa-spin"/> : <em className="fa fa-file"/> } upload list
        </Button>
        <Button
          type="submit"
          bsStyle="primary"
          isLoading={submitting}
          label="Save Changes"
        />
      </div>
    );

    return (
      <div>
        <Box>
          <div className="row">
            <div className="col-sm-4">
              <ControlLabel className="filter-label">Policy Number</ControlLabel>
              <FormControl onChange={ ::this.handleSearchUpdate } />
            </div>
          </div>
        </Box>

        <Box>
          <div className="row">
            <div className="col-xs-12">
              <DataTable
                source="/policies"
                name="renewals"
                autoFilters={[
                  { type: 'filter[renewal_status]', value: 'lapse' },
                  { type: 'include', value: 'lapsed_renewal_policy_application' },
                ]}
                showTotal={true}
                onRowSelect={this.handleRowClick.bind(this)}>

                <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
                  ID
                </TableHeaderColumn>

                <TableHeaderColumn dataField="policy_number" width={'200px'}>
                  Policy Number
                </TableHeaderColumn>

                <TableHeaderColumn dataField="insured" width={'200px'}>
                  Insured
                </TableHeaderColumn>

                <TableHeaderColumn dataField="user_name" width={'150px'}>
                  User
                </TableHeaderColumn>

                <TableHeaderColumn dataField="expires_at" dataFormat={this.formatDate} dataSort={true} width={'100px'}>
                  Policy Expiring
                </TableHeaderColumn>

              </DataTable>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-offset-10">
              <Button
                type='button'
                bsStyle='primary'
                className='pull-right pad-right'
                handleClick={ ::this.handleButtonClick }
              >
                Upload Policy Numbers
              </Button>
            </div>
          </div>
        </Box>

        <Modal
          name={FORM_NAME}
          title={title}
          close={true}
          handleSubmit={handleSubmit(::this.handleSubmit)}
          footer={submitButton}
        >
          <div className="row form-horizontal edit-document">
            <div className="col-xs-12">
              <Field
                name="data.attributes.name"
                label="Name"
                labelSize={3}
                component={HorizontalFormControl}
              />
            </div>

            {_.get(formValues, 'data.attributes.file_url') && (
              <div>
                <div className="col-sm-3">
                  <label className="control-label">File</label>
                </div>
                <div className="col-sm-9">
                  <a href={_.get(formValues, 'data.attributes.file_url')}
                     target="_blank"
                     className="btn btn-default btn-sm">
                    {_.get(formValues, 'data.attributes.file_name')}
                  </a>
                </div>
              </div>
            )}
          </div>

        </Modal>

        <UploadModalComponent
          onComplete={::this.handleUploadComplete}
          accept="text/csv"
          maxFiles={1}
        />
      </div>
    );
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(NonRenewalList);

const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
  const values = selector(state,
    'data.id',
    'data.relationships.document_type',
    'data.attributes.file_name',
    'data.attributes.file_url',
  );

  return {
    formValues: values,
    files: state.upload.files,
    auth: state.auth,
    policy: state.policy,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({ clearFiles, removeFile, openModal }, dispatch), dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(form);

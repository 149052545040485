import { BROKER_STATUSES } from '../../../../schemas/broker';
import { Label } from 'react-bootstrap';
import displayName from '../../../../helpers/displayName';
import React from 'react';


const getLabelStyle = (brokerStatus, ) => {
  if (brokerStatus === 'Pending' || brokerStatus === 'Awaiting Approval') {
    return 'warning';
  }
  if (brokerStatus === 'Live') {
    return 'success';
  }
  if (
    brokerStatus === 'Declined' ||
    brokerStatus === 'Inactive' ||
    brokerStatus === 'Cancelled'
  ) {
    return 'danger';
  }
};
const BrokerLabel = ({ brokerStatus, name }) => {

  const brokerLabelStatus =
    BROKER_STATUSES[brokerStatus ?? 'applied'];

 return (
   <>
    <h2>{name}
    <Label className="pull-right" bsStyle={getLabelStyle(brokerStatus)}>
      {displayName(brokerLabelStatus)}
    </Label>
    </h2>
   </>
  );

};

export default BrokerLabel;

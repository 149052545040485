import React, { useMemo } from 'react';
import './EmailThumbnail.scss';


const getStyles = (theme) => (
  {
    header: {
      backgroundColor: theme.colors.email.header.backgroundColor,
      color: theme.colors.email.header.color
    },
    text: {
      color: theme.colors.email.text.color
    },
    footer: {
      backgroundColor: theme.colors.email.footer.backgroundColor,
      color: theme.colors.email.footer.color

    }
  }
);

const EmailThumbnail = ({ theme, footer, phone, openingHours, url, displayName }) => {

  const styles = useMemo(() => getStyles(theme), [theme]);


  return (
    <div className="email-whitelabel-preview">
      <div className="wl-thumbnail-container">
        <label>Emails</label>
        <div className="wl-thumbnail">
          <div className="wl-header" style={styles.header}>
            <div className="wl-logo-container wl-email-logo">
              <img
                alt="logo"
                className="wl-logo"
                src={theme.urls.emailLogo || theme.urls.logo}
              />
            </div>
          </div>
          <div className="wl-email-body" style={styles.text}>
            <p className="wl-email-salutation"   >
              Dear Mr Joe Bloggs
            </p>
            <p className="wl-email-text">
              Thank you for obtaining a travel insurance quote from { displayName }.<br/><br/>
              Your quote reference is: <b>NB-UCXSIES></b><br/><br/>
              Your quote is valid for 31 days.<br/><br/>
              PJ Single Trip Super from £12.07<br/>
              PJ Single Trip Super Duper from £13.48<br/>
              PJ Annual Multi-trip Super from £50.89<br/>
              PJ Annual Multi-trip Super Duper from £60.56<br/><br/>
              You can retrieve your quote by clicking here: <u><b>Retrieve your quote</b></u><br/><br/>
              Alternatively, please call us on <u><b>{phone}</b></u> with your quote reference (above), if you would like to proceed with your quote.<br/><br/>
              We are here to help<br/><br/>
              If you have any questions or require any further information before purchasing your policy, please call us on  <b><u>{phone}</u></b>. We are open {openingHours}.<br/><br/>
              We look forward to arranging your travel insurance.<br/><br/>
              Yours sincerely<br/><br/>
              {displayName}.<br/><br/>
              {url}<br/>
            </p>

          </div>
          <div className="wl-footer" style={styles.footer}>

              {footer}
          </div>
        </div>
      </div>
    </div>
  );
};
export default EmailThumbnail;

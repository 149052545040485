export const organisation = {
  data: {
    type: 'organisation',
    attributes: {
      name: '',
      legal_entity_number: '',
      regulatory_authority_number: '',
      legal_entity_type: '',
      phone1: '',
      phone2: '',
      address: {
        line1: '',
        line2: '',
        line3: '',
        town: '',
        county: '',
        postcode: '',
      },
      metadata: {
        appointed_representative: '',
        legal_entity_type: '',
        partners: [],
        proprietor: '',
        parent_company: '',
        website: '',
      },
    },
    relationships: {
      organisation_type: {
        data: {
          id: null,
        },
      },
      legal_entity_type: {
        data: {
          id: null,
        },
      },
      parent: {
        data: {
          id: null,
        },
      },
      principal: {
        data: {
          id: null,
        },
      },
      brokers: {
        data: [],
      },
      users: {
        data: [],
      },
      insurers: {
        data: [
          {
            attributes: {
              code: '',
            },
          },
        ],
      },
    },
  },
};

export const organisationRules = {};

export const organisationStatuses = {
  active: 'active',
  inactive: 'inactive',
};

export default organisation;

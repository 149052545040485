import { Col, Row } from 'react-bootstrap';
import displayName from '../../../../helpers/displayName';
import PremiumBreakdown from '../../../product/components/PremiumBreakdown';
import { get, startCase, toLower } from 'lodash';
import IssueButtonPolicy from '../../../product/components/IssueButton/IssueButtonPolicy';
import { Button } from '../../../../common/components';
import React from 'react';

const formatTransactionSource = (premium) => {
  const transactionSource = toLower(get(premium, 'attributes.transaction_source'));

  if (transactionSource === 'payment gateway') {
    return 'Card';
  }

  return startCase(transactionSource);
};
const PremiumRow = ({
                      logo,
                      schemeDetails,
                      showButtons,
                      showPremium,
                      premium,
                      resource,
                      documents,
                      isBrokerCheck,
                      product,
                      declarationText
                    }) => {
  if (!schemeDetails) {
    return null;
  }
  return (
    <Row className="quoted-scheme">
      <Col sm={2} className="scheme-logo-container">
        <img
          className="img-responsive insurer-logo"
          src={logo}
          alt={schemeDetails.name}
        />
      </Col>
      <Col sm={2} className="text-center">
        <span className="cover-level">
          {displayName(schemeDetails.cover_level)}
        </span>
      </Col>
      <Col sm={2} className="text-center">
        <span className="scheme-type">
          {premium.attributes.scheme.scheme_type}
        </span>
      </Col>

      {!showButtons && (
        <Col sm={3}>&nbsp;</Col>
      )}

      {showPremium && (
        <Col sm={3} className="text-center">
          <PremiumBreakdown
            premium={premium.attributes}
            savedPremium={premium.attributes}
            id={premium.id}
            block
            showIpt={false}
          />
          <span className="small">
            {get(premium, 'attributes.premium_type')}
          </span>
          <span className="small">
            {formatTransactionSource(premium)}
          </span>
        </Col>
      )}

      {showButtons && (
        <Col sm={3}>
          {get(product, 'data.attributes.product_code') !== 'CSP' && (
            <IssueButtonPolicy
              isBrokerCheck={isBrokerCheck}
              bsStyle="primary"
              className="pull-right"
              application={resource}
              schemeType={premium.attributes.scheme.scheme_type}
              coverLevel={schemeDetails.cover_level}
              schemeId={premium.attributes.scheme.id}
              product={product}
              declaration={declarationText}
            />
          )}
          {premium.attributes.quote_document && (
            <a
              href={premium.attributes.quote_document}
              target="_blank"
              className="pull-right"
            >
              <Button size="xs" label="Quotation"/>
            </a>
          )}
          {Array.isArray(documents) &&
          documents.map((document, dk) => {
            return (
              <a
                key={dk}
                href={document.attributes.file_path}
                target="_blank"
                className="pull-right"
              >
                <Button
                  size="xs"
                  label={document.attributes.short_name}
                />
              </a>
            );
          })}
        </Col>
      )}
      {premium.attributes.has_referrals &&
      (resource.status !== 'referral approved' &&
        resource.status !== 'quote') && (
        <Col sm={3} className="text-center">
          <span className="cover-level">
            Referral Approval Required
          </span>
        </Col>
      )}
    </Row>

  );
};
export default PremiumRow;

import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { Button, Col, Row } from 'react-bootstrap';

import {
  ButtonBar,
  HorizontalFormControl,
  Icon
} from '../../../../../../common/components';
import TextField from '../../../../../../common/components/ReduxFormField';

const getCommission = (commissions, type) => {

  const matchedCommission = commissions?.find((commission) => commission.commission_type === type);
  if (matchedCommission) {
    const rate = (matchedCommission?.effective_rate ?? matchedCommission?.rate) * 100;
    return {
      amount: matchedCommission.amount.toFixed(2),
      rate: rate?.toFixed(2)
    };
  }
  return {
    amount: 0,
    rate: 0
  };
};

const OwnerAdjustPremiumForm = ({
  formValues,
  handleSubmit,
  onApplyOverride,
  onGetPremiumOverrideIndication,

}) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const {
    commission_broker: brokerCommissionOverride,
    commission_owner: ownerCommissionOverride,
    commission_rate: commissionRateOverride
  } = formValues || {};

  useEffect(() => {
    if (open) {
      onGetPremiumOverrideIndication();
    }
  }, [open]);
  useEffect(() => {
    if (open) {
      onGetPremiumOverrideIndication('commission_owner', ownerCommissionOverride);

    }
  }, [open, ownerCommissionOverride]);

  useEffect(() => {
    if (open) {
      onGetPremiumOverrideIndication('commission_rate', commissionRateOverride);

    }
  }, [open, commissionRateOverride]);
  useEffect(() => {
    if (open) {
      onGetPremiumOverrideIndication('commission_broker', brokerCommissionOverride);

    }
  }, [open, commissionRateOverride]);


  const {
    isPremiumAdjusting,
    premiumOverrideCalculation
  } = useSelector((state) => state.product);


  const commissions = premiumOverrideCalculation?.meta?.commissions ?? [];
  const rates = premiumOverrideCalculation?.meta?.rates ?? [];
  const commissionRate = rates?.effective_commission_rate ??
    rates?.commission_rate;
  const {
    commission,
    net,
    fees,
    tax,
    gross
  } = premiumOverrideCalculation?.meta?.premium ?? {};
  const apply = (values) => {
    onApplyOverride(values);
  };


  return (
    <div className="premium-adjust">
      <h4
        className="toggle-display"
        onClick={toggleOpen}
      >
        Premium Adjustment
        <div className={open ? 'chevron-icon rotate ' : 'chevron-icon'}>
          <Icon name="chevron-down"/>
        </div>
      </h4>
      <>
        {open && (
          <Form onSubmit={handleSubmit(apply)}>
            <Row className="premium-adjust-form fadein-fields-premium">
              <Col sm={6}>
                <Field
                  name={'commission_broker'}
                  label="Broker %"
                  labelSize={4}
                  percentage
                  placeholder={getCommission(commissions, 'broker',).rate.toString()}
                  monetaryValue={getCommission(commissions, 'broker',).amount.toString()}
                  component={HorizontalFormControl}
                  isCalc={isPremiumAdjusting}
                />
                <Field
                  name={'commission_rate'}
                  label="Total %"
                  labelSize={4}
                  percentage
                  placeholder={commissionRate?.toFixed(2).toString()}
                  monetaryValue={commission.toString()}
                  component={HorizontalFormControl}
                  isCalc={isPremiumAdjusting}
                />
                <Field
                  name={'net'}
                  label="Net"
                  labelSize={4}
                  currency
                  placeholder={net?.toFixed(2).toString()}
                  component={HorizontalFormControl}
                  isCalc={isPremiumAdjusting}
                  className="text-right"
                />
                <Field
                  name={'data.attributes.metadata.fees.admin_fee'}
                  label="Admin Fee"
                  labelSize={4}
                  currency
                  placeholder={fees.toFixed(2).toString()}
                  component={HorizontalFormControl}
                  isCalc={isPremiumAdjusting}
                  className="text-right"
                />
              </Col>

              <Col sm={6}>
                <Field
                  name={'commission_owner'}
                  label="PJH %"
                  labelSize={4}
                  percentage
                  placeholder={getCommission(commissions, 'owner',).rate.toString()}
                  monetaryValue={getCommission(commissions, 'owner',).amount.toString()}
                  component={HorizontalFormControl}
                  isCalc={isPremiumAdjusting}
                />

                <HorizontalFormControl
                  disabled
                  label={'IPT'}
                  labelSize={4}
                  input={{ value: '' }}
                  percentage
                  placeholder={rates?.tax_rate.toFixed(2).toString()}
                  monetaryValue={tax?.toFixed(2).toString()}
                  isCalc={isPremiumAdjusting}
                />
                <div className="total-gross-override">
                  <Field
                    name={'gross_premium'}
                    label={<span>Gross</span>}
                    labelSize={4}
                    currency
                    placeholder={gross?.toFixed(2).toString()}
                    component={HorizontalFormControl}
                    isCalc={isPremiumAdjusting}
                    className="text-right"
                  />
                </div>
                <div className="fix-label-width">
                  <Field
                    name={'agency_fee'}
                    label="Broker Admin Fee"
                    labelSize={4}
                    // mdFieldSize={3}
                    currency
                    placeholder={'0'}
                    //placeholder={get(premiumOverrideCalculation, 'meta.premium.net', 0).toFixed(2).toString()}
                    component={HorizontalFormControl}
                    isCalc={isPremiumAdjusting}
                    className="text-right crazy"
                  />
                </div>
              </Col>
              <Col sm={12}>
                <Field
                  className="premium-textarea"
                  name={'note'}
                  placeholder="Reason for override..."
                  type="textarea"
                  component={TextField}
                />
              </Col>

            </Row>
            <Row>
              <Col sm={12}>
                <ButtonBar>
                  <Button
                    className={'pull-right'}
                    bsStyle={'action'}
                    type={'submit'}>
                    Apply Override
                  </Button>
                </ButtonBar>

              </Col>
            </Row>
          </Form>
        )}
      </>
    </div>
  );
};


const FORM_NAME = 'OwnerAdjustPremiumForm';
export default connect(
  (state) => ({
    formValues: getFormValues(FORM_NAME)(state),
    initialValues: {
      commission_owner: '',
      commission_broker: '',
      commission_rate: ''
    },
  })
)(reduxForm({ form: FORM_NAME })(OwnerAdjustPremiumForm));





import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, ControlLabel } from 'react-bootstrap';
import SimpleDatePicker from '../SimpleDatePicker/SimpleDatePicker';
import moment from 'moment';
import 'moment/locale/en-gb';
import './styles.scss';

class DatePickerDob extends Component {
  static propTypes = {
    label: PropTypes.string,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object,
    fieldSize: PropTypes.string,
    labelSize: PropTypes.number,
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    minDateToday: PropTypes.bool,
    showYear: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(date) {
    if (this.props.input && this.props.input.onChange && date) {
      this.props.input.onChange(date);
    }
  }

  renderDatePicker = () => {
    const {
      id = 'date_picker_dob',
      input,
      minDate,
      maxDate,
      minDateToday,
      showYear,
      showTime,
      timeFormat,
      meta
    } = this.props;
    const error = meta && meta.error;
    const touched = meta && meta.touched;

    return (
      <div>
        <SimpleDatePicker
          id={id}
          dobReadOnly={this.props.dobReadOnly}
          monthsShown={1}
          minDate={minDateToday ? moment().format() : minDate}
          maxDate={maxDate}
          showYear={showYear}
          selected={input.value || null}
          onChange={this.onChange}
          showTime={showTime}
          timeFormat={timeFormat}
        />
        {touched && error && (
          <div>
            <span className="input-error">{error}</span>
          </div>
        )}
      </div>
    );
  };

  render() {
    const {
      name,
      meta,
      label,
      labelSize,
      infoButton,
    } = this.props;

    if (label) {
      const size = labelSize || 2;
      return (
        <FormGroup
          controlId={name || label}
          className={meta && meta.touched && meta.error ? ' has-error' : ''}
        >
          <Col componentClass={ControlLabel} sm={labelSize} className="no-wrap-date">
            {label} {infoButton}
          </Col>
          <Col sm={12 - size}>
            {this.renderDatePicker()}
          </Col>
        </FormGroup>
      );
    }

    return this.renderDatePicker();
  }
}

export default DatePickerDob;

import React, { Component } from 'react';
import { some } from 'lodash';
import CreatableSelect from 'react-select/lib/Creatable';
import selectTheme from '../../../helpers/selectTheme';

class CreatableSelectField extends Component {
  getValue = (value) => {
    const { options } = this.props;
    return options.find((opt) => {
      return opt.value === value;
    });
  };


  render() {
    const {
      input,
      label,
      infoButton,
      options,
      labelSize,
      fieldSize,
      meta,
    } = this.props;
    let labelClass = 'select-field-label',
      wrapperClass = '';

    if (labelSize) {
      labelClass += ' col-xs-' + labelSize;
      wrapperClass += ' col-xs-' + (12 - labelSize);

      if (fieldSize) {
        wrapperClass += ' col-md-' + fieldSize;
      }
    }

    if (input.value && !some(options, ['value', input.value])) {
      options.push({ value: input.value, label: input.value });
    }

    const inputValue = input.value.value ? input.value.value : input.value;

    return (
      <div
        className={
          meta && meta.touched && meta.error
            ? 'react-select-error form-group has-error'
            : 'form-group'
        }
      >
        {label && (
          <div className={labelClass}>
            <div className="label-non-wrap">
              <label className="control-label">{label}</label> {infoButton}
            </div>
          </div>
        )}
        <div className={wrapperClass}>
          <CreatableSelect
            theme={(theme) => selectTheme(theme)}
            //inputValue={input.value}
            value={this.getValue(inputValue)}
            //promptTextCreator={(label) => `${label} `}
            onBlur={() => (input.onBlur ? input.onBlur(input.value) : '')}
            onChange={(data) => input.onChange(data ? data.value : '')}
            {...this.props}
          />

          {meta && meta.touched && meta.error && (
            <span className="input-error">{meta.error}</span>
          )}
        </div>
      </div>
    );
  }
}

export default CreatableSelectField;

import React from 'react';

export const renewal = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95 95">
    <title>Icons</title>
    <path d="M69.74,18.92a6.2,6.2,0,0,1,4.55,1.87,6,6,0,0,1,1.87,4.4v51a6,6,0,0,1-1.87,4.4,6.2,6.2,0,0,1-4.55,1.87H25.26a6.2,6.2,0,0,1-4.55-1.87,6,6,0,0,1-1.87-4.4v-51a6,6,0,0,1,1.87-4.4,6.2,6.2,0,0,1,4.55-1.87H38.54a9.79,9.79,0,0,1,3.51-4.63,9.18,9.18,0,0,1,10.9,0,9.8,9.8,0,0,1,3.51,4.63Zm0,57.31v-51H63.47v9.55H31.53V25.19H25.26v51Zm-20-56.42a3.25,3.25,0,1,0,.9,2.24A3,3,0,0,0,49.74,19.81Z" />
    <path d="M60.83,56a13.32,13.32,0,0,1-22.24,6.8L36.33,65a1.11,1.11,0,0,1-.79.33,1.13,1.13,0,0,1-1.12-1.12V56.43a1.13,1.13,0,0,1,1.12-1.12h7.83a1.13,1.13,0,0,1,1.12,1.12,1.11,1.11,0,0,1-.33.79l-2.4,2.4a8.92,8.92,0,0,0,13.71-1.85,14.18,14.18,0,0,0,.93-2,.54.54,0,0,1,.52-.4h3.36a.56.56,0,0,1,.56.56A.3.3,0,0,1,60.83,56Zm.45-6.28a1.13,1.13,0,0,1-1.12,1.12H52.33a1.13,1.13,0,0,1-1.12-1.12,1.11,1.11,0,0,1,.33-.79L54,46.51a9,9,0,0,0-13.73,1.87,14.18,14.18,0,0,0-.93,2,.54.54,0,0,1-.52.4H35.3a.56.56,0,0,1-.56-.56v-.12A13.35,13.35,0,0,1,47.86,39.64a13.58,13.58,0,0,1,9.25,3.71l2.27-2.26a1.11,1.11,0,0,1,.79-.33,1.13,1.13,0,0,1,1.12,1.12Z" />
  </svg>
);

export default renewal;

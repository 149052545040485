import React, { useEffect } from 'react';

import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import {
  refreshData
} from '../../../../common/components/DataTable/redux/dataTableActions';

import { CreateNoteButton, DataTable, NotesList } from '../../../../common/components';
import {
  InternalButtonBar
} from '../../../layout/components/InternalButtonBar/InternalButtonBar';


const SchemeNotes = ({ refreshData, schemeId, resourceType }) => {
  useEffect(
    () => {
      refreshData('notes');
    },
    [schemeId, refreshData],
  );

  const refreshList = () => {
    refreshData('notes');
  };

  const notableResource = { data: { id: schemeId } };

  return (
    <div className="product-container">
      <h2>Notes</h2>
      <div>
        <DataTable
          source={'/notes'}
          autoFilters={[
            {
              type: 'include',
              value: 'attachments'
            },
            {
              type: 'filter[resource]',
              value: schemeId,
            },
          ]}
          name="notes"
          showTotal
          component={NotesList}
        />

        <Row>
          <Col sm={12}>
            <InternalButtonBar
              buttons={[
                {
                  permissions: ['scheme.edit'],
                  button: (
                    <CreateNoteButton

                      label="Add a Note"
                      className="pull-right"
                      notableResourceType={resourceType}
                      notableResource={notableResource}
                      callback={refreshList}
                    />
                  ),
                },
              ]}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default connect(
  null,
  {
    refreshData: refreshData,
  },
)(SchemeNotes);

import React, { Component } from 'react';
import Select, { components } from 'react-select';
import { get, flow, filter, find } from 'lodash';
import { bindActionCreators } from 'redux';
import { actions as selectMenuActions } from './SelectMenuRedux';
import { connect } from 'react-redux';
import selectTheme, { styles } from '../../../helpers/selectTheme';

class SelectMenu extends Component {
  constructor(props) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.state = {
      initialOption: {},
    };
  }

  componentDidMount() {
    const {
      selectMenuActions,
      source,
      filter,
      sourceKey = '',
      labelPath,
      optionValuePath,
      includes,
      filterUrl,
      override
    } = this.props;
    Promise.resolve(
      selectMenuActions.fetchData(
        source,
        sourceKey,
        filter,
        labelPath,
        optionValuePath,
        false,
        includes,
        filterUrl,
        override
      ),
    ).then(() => {
      this.filterOptions();
    });
  }

  filterOptions() {
    const {
      autoFilters,
      allOptions,
      allData,
      selectMenuActions,
      source,
      sourceKey,
      isObject,
      labelPath,
      optionValuePath,
    } = this.props;

    const matchFilter = (filter, option) => {
      const exactMatch = filter.match
        ? get(option, `attributes.${filter.type}`) === filter.match
        : true;
      const appendMatch = filter.append
        ? get(option, `attributes.${filter.type}`, '')
          .toLowerCase()
          .includes(filter.append)
        : true;
      return exactMatch && appendMatch;
    };

    if (autoFilters && isObject) {
      const filteredOptions = filter(allOptions, (option) => {
        return autoFilters.reduce((carry, filter) => {
          return carry && matchFilter(filter, option.value);
        }, true);
      });
      selectMenuActions.setDisplayOptions(source + sourceKey, filteredOptions);
    }

    if (autoFilters && !isObject) {
      //need to go to original data to get filtered options
      const filteredOptions = allData.reduce((carry, option) => {
        const matchFound = autoFilters.reduce((carry, filter) => {
          return carry && matchFilter(filter, option);
        }, true);
        if (matchFound) {
          carry.push({
            label: get(option, labelPath ? labelPath : 'attributes.name'),
            value: optionValuePath ? get(option, optionValuePath) : option,
          });
        }
        return carry;
      }, []);

      selectMenuActions.setDisplayOptions(source + sourceKey, filteredOptions);
    }
    if (!autoFilters) {
      selectMenuActions.setDisplayOptions(source + sourceKey, allOptions);
    }
  }

  getValue() {
    const { allOptions, input, isObject, selectedValue } = this.props;

    if (!input && !selectedValue) {
      return undefined;
    }

    if (!input || !input.value) {
      return find(allOptions, (opt) => {
        if (isObject) {
          return get(opt.value, 'id') === get(selectedValue, 'id');
        } else {
          return opt.value === selectedValue;
        }
      });
    }

    return find(allOptions, (opt) => {
      const value = input.value.value || input.value;

      if (isObject || get(input,'value.type', '') === 'organisations/insurers') {
        return get(opt.value, 'id') === get(value, 'id');
      } else {
        return opt.value === value;
      }
    });
  }

  handleOnBlur = () => {
    const { input } = this.props;

    if (input && input.onBlur) {
      input.onBlur(input.value);
    }

    if (input && input.onBlur && get(input,'value.type', '') === 'organisations/insurers') {
      input.onBlur(get(input,'value.id', ''));
    }
  };

  handleOnChange = (option) => {
    const { input, allData, optionValuePath, onChangeCallback } = this.props;

    if (!optionValuePath && option) {
      if (onChangeCallback) {
        onChangeCallback(option);
      }
    } else if (get(option,'attributes.type', '') === 'organisations/insurers' && !optionValuePath && option) {
      onChangeCallback(option.id);
    } else {
      const match = find(allData, { [optionValuePath]: option });

      if (onChangeCallback) {
        onChangeCallback(match);
      }
    }

    if (input) {
      input.onChange(option);
    }

    if (input && get(option,'attributes.type', '') === 'organisations/insurers') {
      input.onChange(option.id);
    }
  };

  render() {
    const {
      meta,
      placeholder,
      showError,
      errorMessage,
      displayOptions,
    } = this.props;
    const error = meta && meta.error;
    const touched = meta && meta.touched;
    const showErrorMsg = showError || (touched && meta);
    const errorMsg = errorMessage || error;

    const NoOptionsMessage = (props) => {
      const newProps = {
        ...props,
        children: (
          <div>
            {this.props.isFetching === true ? (
              <span className="loader-dots">
                <i className="fa fa-circle loader-dot"/>
                <i className="fa fa-circle loader-dot"/>
                <i className="fa fa-circle loader-dot"/>
              </span>
            ) : (
              <div> No results found </div>
            )}
          </div>
        ),
      };
      return <components.NoOptionsMessage {...newProps} />;
    };

    return (
      <div className={'select-menu'}>
        <Select
          options={displayOptions}
          components={{ NoOptionsMessage }}
          value={this.getValue()}
          theme={(theme) => selectTheme(theme)}
          styles={styles}
          onBlur={this.handleOnBlur}
          onChange={(option) => this.handleOnChange(option ? option.value : '')}
          placeholder={placeholder || 'Please Select...'}
          {...this.props}
        />
        {(showErrorMsg && errorMsg) && <span className="input-error">{errorMsg}</span>}
      </div>
    );
  }
}

SelectMenu.defaultProps = {
  labelPath: 'attributes.name',
  append: '',
  optionName: 'name',
  sourceKey: '',
};

export default flow([
  connect(
    (state, props) => {
      const sourceKey = props.sourceKey || '';
      return {
        allOptions: get(state, `selects.${props.source}${sourceKey}.options`),
        displayOptions: get(state, `selects.${props.source}${sourceKey}.displayOptions`),
        allData: get(state, `selects.${props.source}${sourceKey}.data`),
        isFetching: get(state, `selects.${props.source}${sourceKey}.isFetching`),
      };
    },
    (dispatch) => ({
      selectMenuActions: bindActionCreators(selectMenuActions, dispatch),
    }),
  ),
])(SelectMenu);

import React from 'react';
import { SidebarSection } from './SidebarSection';
import { Icon } from '../../../../../../../common/components';
import displayCurrency from '../../../../../../../helpers/displayCurrency';

export const Price = ({ isCalculating, gross }) => {


  if (isCalculating) {
    return (
      <div className="quote-info quote-info--pink premium">
        <Icon name="refresh" spin/>
      </div>
    );
  }

  if (!gross) {
    return null;
  }
  return (<>

    <div className="quote-info quote-info--pink premium">
      {displayCurrency(gross)}
    </div>
  </>);

};
export const Premium = ({ premium, isCalculating }) => {
  const hasPremium = premium?.premium?.gross && !premium?.errors?.length && !premium?.declines?.length;
  const taxRate = premium?.rates?.tax_rate;

  const label = taxRate ? `Premium (inc. ${premium?.rates?.tax_rate}% IPT)` : 'Premium';
  if (!hasPremium && !isCalculating) {
    return null;
  }

  return (
    <SidebarSection
      label={label}
      value={<Price isCalculating={isCalculating} gross={premium?.premium?.gross}/>}
    />
  );
};

import React from 'react';

import { connect, useSelector } from 'react-redux';
import {
  patchQuotes,
  saveQuotes,
  saveReferral
} from '../../../../../redux/productActions';
import { Button } from '../../../../../../../common/components';


const QuoteSaveAndExit = ({
  productId,
  hasReferrals,
  selectedScheme,
  isReferralRequired,
  application,
  onSaveReferral,
  onSaveQuotes,
  onPatchQuotes,
}) => {


  const { isSaving } = useSelector((state) => state.product);

  const isReferral = !!selectedScheme?.meta?.referrals?.length;

  const showApprove = isReferral && !isReferralRequired;

  const saveQuotes = () => {
    if (hasReferrals) {
      return onSaveReferral(application, productId);
    }

    return onSaveQuotes(application, productId);
  };

  const approveReferral = () => {
    if (application.data.id) {
      return onPatchQuotes(application);
    }

    return onSaveQuotes(application, productId);
  };

  const save = () => {
    if (showApprove) {
      return approveReferral();
    }
    return saveQuotes();
  };


  return (
    <div className={'save-quote-btn-sidebar'}>
      <Button
        label={showApprove ? 'Approve Quote' : 'Save and Exit'}
        bsStyle={'primary'}
        block
        isLoading={isSaving}
        handleClick={save}
      />
    </div>
  );
};


export default connect(
  null,
  {
    onSaveReferral: saveReferral,
    onSaveQuotes: saveQuotes,
    onPatchQuotes: patchQuotes,
  }
)(QuoteSaveAndExit);

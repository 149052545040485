/**
 * Takes the included relationships and filters through them to find the correct relationship
 * to return.
 *
 * @param resource
 * @param included
 * @param relationship
 */
export default function getIncludedResource(
  resource,
  included,
  relationship,
  resourceType = '',
) {
  let ids = [],
    type = '',
    data = [],
    collection = false;

  if (
    resource &&
    relationship &&
    resource.relationships &&
    resource.relationships[relationship]
  ) {
    const includedRelationship = resource.relationships[relationship];

    if (Array.isArray(includedRelationship.data)) {
      // collection
      collection = true;
      includedRelationship.data.forEach((relation) => {
        ids.push(relation.id);
        type = relation.type;
      });
    } else if (includedRelationship.data) {
      // single
      ids.push(includedRelationship.data.id);
      type = includedRelationship.data.type;
    }
  } else {
    ids.push(resource);
    type = resourceType;
  }

  if (included) {
    included.forEach((relation) => {
      if (relation.type == type && ids.indexOf(relation.id) !== -1) {
        data.push(relation);
      }
    });
  }

  if (!collection) {
    return { data: data[0] || [] };
  }

  return data;
}

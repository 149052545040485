import { Image } from "react-bootstrap";
import React from "react";
import './EditProductIcon.scss';

export const EditProductIcon = ({ logoUrl, inputUrl }) => {

  if (logoUrl) {
    return (
      <Image src={logoUrl} responsive className={'edit-product-form-icon'}/>
    )
  }
  return (
    <Image src={inputUrl} responsive className={'edit-product-form-icon'}/>
  )

}

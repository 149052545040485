import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Icon from '../../../../common/components/Icon/Icon';
import './InternalNotifications.scss';
import Reminders from './Reminders/Reminders';
import Announcements from './Announcements/Announcements';
import Notifications from './Notifications/Notifications';
import {
  fetchAnnouncements,
  fetchReminders,
} from '../../../dashboard/redux/dashboardActions';
import { connect } from 'react-redux';
import { get, cloneDeep, map, size, reduce } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { hasPermission } from '../../../auth/redux/authActions';

class DropDown extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    counter: PropTypes.number.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    // Dropdown block is inactive & hidden by default
    this.state = {
      dropdownIsActive: false,
      dropdownIsVisible: false,
    };

    this._hideDropdown = this._hideDropdown.bind(this);
    this._handleBlur = this._handleBlur.bind(this);
    this._handleFocus = this._handleFocus.bind(this);
    this._openDropdown = this._openDropdown.bind(this);
  }

  componentDidMount() {
    // Hide dropdown block on click outside the block
    window.addEventListener('click', this._hideDropdown, false);
  }

  componentWillUnmount() {
    // Remove click event listener on component unmount
    window.removeEventListener('click', this._hideDropdown, false);
  }

  _stopPropagation(e) {
    // Stop bubbling of click event on click inside the dropdown content
    e.stopPropagation();
  }

  _openDropdown() {
    this.setState({ dropdownIsVisible: true });
  }

  _hideDropdown() {
    const { dropdownIsActive } = this.state;

    if (!dropdownIsActive) {
      this.setState({ dropdownIsVisible: false });
    }
  }

  _handleFocus() {
    // Make active on focus
    this.setState({ dropdownIsActive: true });
  }

  _handleBlur() {
    // Clean up everything on blur
    this.setState({
      dropdownIsVisible: false,
      dropdownIsActive: false,
    });
  }

  render() {
    const { dropdownIsVisible } = this.state;
    return (
      <div onFocus={this._handleFocus} onClick={this._openDropdown}>
        <span className="toggler">
          <Button className="icon-bt">
            <Icon className="notification-icon" name={this.props.content.icon} />
            <span className="badge">{this.props.counter}</span>
          </Button>
        </span>

        {dropdownIsVisible && (
          <div className={'dropdown-' + this.props.content.name}>
            <div>
              <span className="close-area" onClick={this._handleBlur} />
              {this.props.content.dropdown}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const itemNotification = {
  content: {
    name: 'notifications',
    icon: 'commenting',
    dropdown: (
      <div>
        <h3> Notifications </h3>
        <Notifications />
      </div>
    ),
  },
};
const itemReminders = {
  content: {
    name: 'reminders',
    icon: 'bell',
    dropdown: (
      <div>
        <h3> Reminders </h3>
        <Reminders />
      </div>
    ),
  },
};
const itemAnnouncements = {
  content: {
    name: 'announcements',
    icon: 'bullhorn',
    dropdown: (
      <div>
        <h3> Announcements </h3>
        <Announcements />
      </div>
    ),
  },
};

export class InternalNotifications extends Component {
  componentWillMount() {
    this.props.dispatch(fetchAnnouncements());
    this.props.dispatch(fetchReminders());
  }

  render() {
    const { dashboard } = this.props;
    const usersAwaitTotal = get(dashboard.stats, 'meta.users_awaiting_activation', 0);
    const brokersAwaitTotal = get(dashboard.stats, 'meta.broker_applications_awaiting_approval', 0);
    const referralsTotal = get(dashboard.stats, 'meta.referrals_requiring_approval', 0);
    const renewalsAwaitTotal = get(
      dashboard.stats,
      'meta.renewals_awaiting_conversion',
      0,
    );
    const mtasAwaitTotal = get(dashboard.stats, 'meta.mtas_requiring_approval', 0);
    const enquiriesTotal = get(dashboard.stats, 'meta.enquiries', 0);

    const getAnnounData = cloneDeep(dashboard.announcements.data);
    const currentTime = moment().format();
    const announActive = [];
    map(getAnnounData, (reminder) => {
      let to_date = reminder.attributes.to_date;
      let from_date = reminder.attributes.from_date;
      if (
        moment(currentTime).isSameOrBefore(to_date) &&
        moment(currentTime).isSameOrAfter(from_date)
      ) {
        announActive.push(reminder);
      }
    });

    const announcementsTotal = size(announActive);
    const arrayOfNotifications = [
      usersAwaitTotal,
      brokersAwaitTotal,
      referralsTotal,
      renewalsAwaitTotal,
      mtasAwaitTotal,
    ];
    if (hasPermission('system.notes_view_internal')) {
      arrayOfNotifications.push(enquiriesTotal);
    }

    const notificationsTotal = reduce(arrayOfNotifications, (sum, n) => sum + n, 0);
    const remindersTotal = get(dashboard.reminders, 'data.length', 0);

    return (
      <div>
        <div className="notification-bar-notifications">
          <DropDown
            id={1}
            counter={notificationsTotal}
            content={itemNotification.content}
          />
        </div>
        <div className="notification-bar-reminder">
          <DropDown id={2} counter={remindersTotal} content={itemReminders.content} />
        </div>
        <div className="notification-bar-announcements">
          <DropDown
            id={3}
            counter={announcementsTotal}
            content={itemAnnouncements.content}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dashboard: state.dashboard,
  };
}

export default connect(mapStateToProps)(InternalNotifications);

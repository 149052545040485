import React from 'react';
import { connect } from 'react-redux';
import { Form, Modal } from 'react-bootstrap';
import { Field, FieldArray, getFormValues, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';
import { compose } from 'redux';
import { Button, HorizontalFormControl, } from '../../../../../../common/components';
import EditDeclineFieldArray from './EditDeclineFieldArray';
import { EMPTY_RULE, formatDeclines } from '../../../../helpers/declineHelpers';
import { SelectField } from '../../../../../../common/components/ReduxFormField';

const isRequired = required({ message: 'Required' });
const FORM_NAME = 'AddReferralModal';

const EditDeclineForm = ({
  reason,
  formValues,
  regions,
  coverLevels,
  medicalQuestionKeys,
  handleSubmit,
  array,
  onUpdateDecline,
  productType,
}) => {
  const declines = formValues?.declines ?? [];
  const handleAddReferral = (values) => {
    const formattedDecline = formatDeclines(values);
    onUpdateDecline(formattedDecline);
  };

  const addRestriction = () => {
    array.push('declines', EMPTY_RULE);
  };

  return (
    <>
      <Form horizontal>
        <>
          <Modal.Body>
            <h3>Add Rule</h3>
            <Field
              name={'reason'}
              smFieldSize={9}
              label={'Display Message:'}
              labelSize={3}
              component={HorizontalFormControl}
              validate={[isRequired]}
            />
            <Field
              name={'referral_type'}
              label={'Type:'}
              fieldSize={9}
              labelSize={3}
              isClearable
              component={SelectField}
              options={[{
                label: 'Decline',
                value: 'decline',
              },
                {
                  label: 'Referral',
                  value: 'check'
                }]}
              validate={required({ message: 'Required' })}
            />
            <Field
              name={'team_factor'}
              smFieldSize={9}
              label={'Team Factor:'}
              labelSize={3}
              type={'number'}
              component={HorizontalFormControl}
            />
            <FieldArray
              name={'declines'}
              component={EditDeclineFieldArray}
              declines={declines}
              regions={regions}
              coverLevels={coverLevels}
              productType={productType}
              medicalQuestionKeys={medicalQuestionKeys}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-blue" handleClick={addRestriction}>
              Add Another Restriction
            </Button>
            <Button bsStyle="primary" handleClick={handleSubmit(handleAddReferral)}>
              {reason ? 'Update' : 'Add'} Decline
            </Button>
          </Modal.Footer>
        </>
      </Form>
    </>
  );
};

export default compose(
  connect((state, props) => {
    return {
      initialValues: {
        reason: props.reason,
        referral_type: props.referralType,
        team_factor: props.teamFactor,
        declines: props.declines,
      },
      formValues: getFormValues(FORM_NAME)(state),
    };
  }),
  reduxForm({
    form: FORM_NAME,
  }),
)(EditDeclineForm);

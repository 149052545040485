import React from 'react';
import { getAnswerOptions } from '../../redux/screeningActions';
import { Button } from '../../../../common/components/Button/Button';
import { isEmpty } from 'lodash';

export const ConditionQuestionEdit = ({
  selectedAnswers,
  screening,
  dispatch,
  condition,
  question,
  isBMIObject = false,
}) => {
  const handleEditQuestionOptions = () => {
    const declarationNumber = parseInt(
      screening?.declaration?.data.attributes?.declaration_number,
      10,
    );
    dispatch(getAnswerOptions(condition, question, declarationNumber));
  };

  const renderEditButton = () => {
    return (
      <Button
        className="btn-screening"
        isLoading={screening.isSubmitting}
        label="Edit"
        handleClick={handleEditQuestionOptions}
      />
    );
  };

  if (isEmpty(selectedAnswers) || selectedAnswers === undefined) {
    return null;
  }

  if (isBMIObject === true) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ flex: 2 }}>
          {selectedAnswers.isMetricSystem && (
            <>
              <div>{'Height: ' + selectedAnswers.height1 + 'cm'}</div>
              <div>{'Weight: ' + selectedAnswers.weight1 + 'kg'}</div>
            </>
          )}
          {!selectedAnswers.isMetricSystem && (
            <>
              <div>
                {'Height: ' +
                  selectedAnswers.height1 +
                  'ft ' +
                  selectedAnswers.height2 +
                  'in'}
              </div>
              <div>
                {'Weight: ' +
                  selectedAnswers.weight1 +
                  'st ' +
                  selectedAnswers.weight2 +
                  'lb'}
              </div>
            </>
          )}
        </div>
        <div style={{ flex: 1, textAlign: 'right' }}>{renderEditButton()}</div>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ flex: 2 }}>
        {selectedAnswers.map((answer, index) => {
          return <div key={index}>{answer?.attributes?.text}</div>;
        })}
      </div>
      <div style={{ flex: 1, textAlign: 'right' }}>{renderEditButton()}</div>
    </div>
  );
};

import React from 'react';
import {  FormGroup, Col, Row as BootstrapRow, ControlLabel } from 'react-bootstrap';

const FULL_ROW_SIZE = 12;
const Row = ({ labelSize, label, fieldSize, fieldOffset, className, children }) => {
  const smLabelSize = labelSize ? labelSize : 2;
  const smFieldSize = fieldSize ? fieldSize : FULL_ROW_SIZE - smLabelSize;
  return (
    <FormGroup className={className}>
      {label && (
        <Col sm={smLabelSize}>
          <ControlLabel
          >
            {label}
          </ControlLabel>
        </Col>
      )}

      <Col
        sm={smFieldSize}
        smOffset={fieldOffset || 0}
      >
        {children}
      </Col>
    </FormGroup>
  );
};

const LabelRow = ({ labelSize, label, fieldSize, fieldOffset, labelOffset, className, children }) => {
  const smLabelSize = labelSize ? labelSize : 2;
  const smFieldSize = fieldSize ? fieldSize : FULL_ROW_SIZE - smLabelSize;
  return (
    <BootstrapRow className={className}>
      {label && (
        <Col
          sm={smLabelSize}
          smOffset={labelOffset || 0}
        >
          <ControlLabel
          >
            {label}
          </ControlLabel>
        </Col>
      )}
      <Col
        sm={smFieldSize}
        smOffset={fieldOffset || 0}
      >
        <ControlLabel
        >
          {children}
        </ControlLabel>
      </Col>
    </BootstrapRow>);
};

const FullRow = ({ children, className }) => (
  <BootstrapRow className={className}>
    <Col sm={FULL_ROW_SIZE}>
      {children}
    </Col>
  </BootstrapRow>
);


export { Row, LabelRow, FullRow };

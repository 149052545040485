import React from 'react';

export const policy = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95 95">
    <title>Icons</title>
    <path d="M69.74,18.92a6.2,6.2,0,0,1,4.55,1.87,6,6,0,0,1,1.87,4.4v51a6,6,0,0,1-1.87,4.4,6.2,6.2,0,0,1-4.55,1.87H25.26a6.2,6.2,0,0,1-4.55-1.87,6,6,0,0,1-1.87-4.4v-51a6,6,0,0,1,1.87-4.4,6.2,6.2,0,0,1,4.55-1.87H38.54a9.79,9.79,0,0,1,3.51-4.63,9.18,9.18,0,0,1,10.9,0,9.8,9.8,0,0,1,3.51,4.63Zm0,57.31v-51H63.47v9.55H31.53V25.19H25.26v51Zm-20-56.42a3.25,3.25,0,1,0,.9,2.24A3,3,0,0,0,49.74,19.81Z" />
    <path
      class="cls-1"
      d="M63.34,49.19,48.11,64.42l-2.86,2.86a2,2,0,0,1-2.86,0l-2.86-2.86L31.9,56.81a2,2,0,0,1,0-2.86l2.86-2.86a2,2,0,0,1,2.86,0l6.19,6.21,13.8-13.83a2,2,0,0,1,2.86,0l2.86,2.86a2,2,0,0,1,0,2.86Z"
    />
  </svg>
);

export default policy;

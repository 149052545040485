import { Col, Row } from "react-bootstrap";
import Box from "../../../../../../../common/components/Box/Box";
import BenefitsModal from "../../../../BenefitsModal/BenefitsModal";
import React from "react";
import displayCurrency from "../../../../../../../helpers/displayCurrency";

const PremiumBreakdownRow = ({ value, label }) => {
  if (value === undefined) {
    return null;
  }
  return (
    <Row>
      <Col sm={8}>
        {label}
      </Col>
      <Col sm={4} className="sidebar-summary-premium">
        {displayCurrency(value)}
      </Col>
    </Row>
  )
}
export const SchemeSummary = ({ premium, isMta, isSaving }) => {
  //const { product, mta, showBlocker } = this.props;
  if (isMta) {
    return null;
  }
  if (isSaving) {
    return null;
  }
  if (!premium) {
    return null;
  }
  const preAdjustedGross = premium?.premium_before_adjustments?.gross ?? 0;

  const gross = premium?.premium?.gross ?? 0;

  const discount = preAdjustedGross - gross;
  const taxRate = premium?.rates.tax_rate;

  return (
    <Col sm={12} className="schemeSelected">
      <Box>
        <Row>
          <Col sm={12}>
            Chosen Scheme{' '}
            <BenefitsModal
              scheme={premium.scheme}
              benefits={premium.benefits}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>{premium.scheme.name} &nbsp;</Col>
        </Row>

        <PremiumBreakdownRow
          label={'Base Premium'}
          value={premium.premium.principal}
        />
        <PremiumBreakdownRow
          label={`IPT (${taxRate}%)`}
          value={premium.premium.tax}
        />

        <PremiumBreakdownRow
          label={'Admin Fees'}
          value={premium?.premium?.fees}
        />
        <PremiumBreakdownRow
          label={'Total Payable'}
          value={premium.premium?.gross}
        />
        <PremiumBreakdownRow
          label={'Discount'}
          value={discount !== 0 ? discount : undefined}
        />

      </Box>
    </Col>
  );
}

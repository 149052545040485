export const SET_ADDRESS_OPTIONS = 'socrates-online/postcode/SET_ADDRESS_OPTIONS';
export const SELECT_ADDRESS = 'socrates-online/postcode/SELECT_ADDRESS';
export const CLEAR_SELECTION = 'socrates-online/postcode/CLEAR_SELECTION';
export const START_LOOKUP = 'socrates-online/postcode/START_LOOKUP';
export const LOOKUP_QUERY = 'socrates-online/postcode/LOOKUP_QUERY';

const initialState = {
  data: {},
  addresses: {},
  selections: {},
  selection: {},
  isLoading: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_ADDRESS_OPTIONS:
      return {
        ...state,
        addresses: action.addresses,
        isLoading: false,
      };
    case SELECT_ADDRESS:
      return {
        ...state,
        selection: action.data,
        selections: {
          ...state.selections,
          [action.id]: action.address,
        },
        addresses: {},
        isLoading: false,
      };
    case LOOKUP_QUERY:
      return {
        ...state,
        isLoading: action.status,
      };
    case CLEAR_SELECTION:
      return {
        ...state,
        selection: {},
        addresses: {},
      };
    case START_LOOKUP:
      return {
        ...initialState,
        isLoading: true,
      };
    default:
      return state;
  }
}

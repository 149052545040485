import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormControl, FormGroup, InputGroup, Alert } from 'react-bootstrap';
import displayCurrency from '../../../helpers/displayCurrency';
import { Icon } from '../../components';
import './HorizontalFormControl.scss';
import * as Cleave from 'cleave.js/react';

const InputGroupAddon = InputGroup.Addon;

const handleOnChange = (customOnChange, input, currency) => {
  return (event) => {
    if (currency) {
      event.target.value = event.target.value.replace(/[^\d.-]/g, '');
    }

    if (input) {
      input.onChange(event);
    }

    if (customOnChange) {
      customOnChange(event);
    }
  };
};

const filterCurrency = (value) => {
  if (!value) {
    return '';
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const HorizontalFormControl = ({
  displayAlert,
  input,
  meta,
  type,
  labelSize,
  paddingLabelTitle,
  mdFieldSize,
  label,
  infoButton,
  placeholder,
  addonAfter,
  addonBefore,
  monetaryValue,
  rows,
  disabled,
  note,
  currency,
  percentage,
  inputOnly,
  onChange,
  readOnly,
  isCalc,
  className,
  cleaveOptions,
  noLabel,
}) => {
  const size = labelSize ? labelSize : 2;
  const error = meta && meta.error;
  const touched = meta && meta.touched;

  if (!readOnly) readOnly = false;
  if (!isCalc) isCalc = false;

  const formControlComponent =
    addonBefore || addonAfter || monetaryValue || currency || percentage ? (
      <InputGroup>
        {currency && (
          <InputGroupAddon>
            <Icon name={isCalc ? 'spinner' : 'gbp'} spin={isCalc}/>
          </InputGroupAddon>
        )}
        {percentage && (
          <InputGroupAddon>
            <Icon name={isCalc ? 'spinner' : 'percent'} spin={isCalc}/>
          </InputGroupAddon>
        )}
        {addonBefore && <InputGroupAddon>{addonBefore}</InputGroupAddon>}
        <FormControl
          {...input}
          type={type}
          className={className}
          placeholder={currency ? filterCurrency(placeholder) : placeholder}
          value={currency ? filterCurrency(input.value) : input.value}
          rows={rows}
          disabled={disabled}
          onChange={handleOnChange(onChange, input, currency)}
          onBlur={handleOnChange(onChange, input, currency)}
          readOnly={readOnly}
        />
        {monetaryValue ? (
          <InputGroupAddon className="monetary-value">
            {displayCurrency(monetaryValue)}
          </InputGroupAddon>
        ) : (
          ''
        )}
        {addonAfter ? <InputGroupAddon>{addonAfter}</InputGroupAddon> : ''}
      </InputGroup>
    ) : (
      <FormControl
        {...input}
        type={type}
        placeholder={placeholder}
        rows={rows}
        disabled={disabled}
        onChange={handleOnChange(onChange, input, currency)}
        onBlur={handleOnChange(onChange, input, currency)}
        readOnly={readOnly}
      />
    );

  if (inputOnly) {
    return formControlComponent;
  }

  const CleaveComponent = (
    <Cleave
      className={className + ' form-control'}
      placeholder={placeholder}
      options={cleaveOptions}
      {...input}
    />
  );

  const renderFormControl = () => {
    return (
      <div>
        {type === 'cleave' ? CleaveComponent : formControlComponent}
        {note && (<div className="note">{note}</div>)}
        {touched && error && !displayAlert && (
          <span className="input-error">{error}</span>
        )}
        {touched && error && displayAlert && (
          <Alert bsStyle="danger" style={{ marginTop: '5px' }}>
            <span className="input-error-alert">
              {error.title}
              <br/>
              {error.context}
            </span>
          </Alert>
        )}
      </div>
    );
  };

  if (label) {
    return (
      <FormGroup
        controlId={input ? input.name : label}
        className={touched && error ? ' has-error' : ''}
      >
        {noLabel !== 'noLabel' && (
          <Col sm={size}>
            <ControlLabel className={paddingLabelTitle ? 'control-padding' : ''}>
              {label}
            </ControlLabel>{' '}
            {infoButton}
          </Col>
        )}
        <Col sm={12 - size} md={mdFieldSize ? mdFieldSize : 0}>
          {renderFormControl()}
        </Col>
      </FormGroup>
    );
  }

  return renderFormControl();
};

HorizontalFormControl.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  labelSize: PropTypes.number,
  mdFieldSize: PropTypes.number,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  addonAfter: PropTypes.string,
  addonBefore: PropTypes.string,
  currency: PropTypes.bool,
  percentage: PropTypes.bool,
  inputOnly: PropTypes.bool,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  monetaryValue: PropTypes.string,
  note: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  isCalc: PropTypes.bool,
  className: PropTypes.string,
};

export default HorizontalFormControl;

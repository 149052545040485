import React, { Component } from 'react';
import './PostcodeField.scss';

export default class PostCodeField extends Component {
  render() {
    const { input, placeholder, meta, type } = this.props;
    return (
      <div className={meta && meta.submitFailed && meta.error ? 'has-error' : ''}>
        <input
          className="form-control"
          {...input}
          placeholder={placeholder || ''}
          type={type}
        />
        {meta && meta.submitFailed && meta.error && (
          <span className="post-code-error">{meta.error}</span>
        )}
      </div>
    );
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createAccount, updateAccount } from '../redux/accountActions';
import AccountForm from '../components/AccountForm/AccountForm';

export default class AccountFormContainer extends Component {
  static propTypes = {
    resource: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    account: PropTypes.object.isRequired,
  };

  handleSubmit(resource) {
    if (resource.data.id) {
      return this.props.dispatch(updateAccount(resource));
    }

    return this.props.dispatch(createAccount(resource));
  }

  render() {
    return (
      <AccountForm
        {...this.props}
        onSubmit={::this.handleSubmit}
        submitting={this.props.account.isSubmitting}
      />
    );
  }
}

import { Modal } from 'react-bootstrap';
import React from 'react';
import ActivateSchemeForm from './ActivateSchemeForm';

const ActivateSchemeModal = ({ show, onClose, onActivate }) => {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Activate Scheme</Modal.Title>
      </Modal.Header>
      {show && <ActivateSchemeForm onActivate={onActivate} onCancel={onClose}/>}
    </Modal>
  );
};

export default ActivateSchemeModal;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCustomer } from '../redux/customerActions';
import { Button, Loader } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import CustomerDetails from '../components/CustomerDetails';
import CustomerApplications from '../components/CustomerApplications';
import CustomerTransactions from '../components/CustomerTransactions';
import CustomerNotes from '../components/CustomerNotes';
import CustomerAudit from '../components/CustomerAudit';
import InternalTabs from '../../layout/components/InternalTabs/InternalTabs';
import { SET_ACTIVE_TAB } from '../../layout/redux/tabs';

const TAB_NAME = 'customerTabs';

class CustomerShowContainer extends Component {
  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(getCustomer(this.props.params.id));
    dispatch({
      type: SET_ACTIVE_TAB,
      name: TAB_NAME,
      number: 1,
    });
  }

  render() {
    const resource = this.props.customer.resources[this.props.params.id];
    const buttons = (
      <Button bsStyle="default" label="Back" isBackButton leftIcon="caret-left" />
    );

    return (
      <InternalContainer title="Customer" buttons={buttons}>
        {resource ? (
          <InternalTabs
            id={TAB_NAME}
            tabs={[
              {
                permissions: ['customer.view'],
                title: 'Details',
                content: <CustomerDetails {...this.props} resource={resource} />,
              },
              {
                permissions: ['policy.quote'],
                title: 'Quote History',
                content: <CustomerApplications {...this.props} resource={resource} />,
              },
              {
                permissions: ['policy.view'],
                orgTypes: ['owner'],
                title: 'Transactions',
                content: <CustomerTransactions {...this.props} resource={resource} />,
              },
              {
                permissions: ['customer.notes_view'],
                orgTypes: ['owner', 'broker'],
                title: 'Notes',
                content: <CustomerNotes {...this.props} resource={resource} />,
              },
              {
                permissions: ['system.audit_logs_view'],
                orgTypes: ['owner'],
                title: 'Audit',
                content: <CustomerAudit {...this.props} resource={resource} />,
              },
            ]}
          />
        ) : (
          <Loader />
        )}
      </InternalContainer>
    );
  }
}

CustomerShowContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    customer: state.customer,
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(CustomerShowContainer);

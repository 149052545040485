import React, { useMemo } from 'react';
import { Field } from 'redux-form';
import {
  CheckboxMarketingOptions
} from '../../../../../../common/components/ReduxFormField';
import { Col, Row } from 'react-bootstrap';

import './Marketing.scss';

// Marketing options component
const consentToInt = (v) => (v ? 1 : -1);
const consentToBool = (v) => v === 1 || v === 2;

const TITLE = `We may from time to time contact you by email or other electronic means to provide you with information
in relation to special offers, services and events which may be of interest to you.`;

const getIsAllSelected = (marketingValues) => {
  const marketingKeys = Object.keys(marketingValues);
  return marketingKeys.reduce((carry, key) => {
    const marketingValue = marketingValues[key];
    if (!marketingValue || marketingValue === -1) {
      return false;
    }
    return carry;
  }, true)

}

const CheckboxNotRedux = ({ value, onSelectAll }) => {
  const handleClick = () => {
    onSelectAll(!value);
  }
  return (
    <div className={'options-container'}>
      <div className="row">
        <div className="col-sm-12 checkbox-container">
          <div
            className={'options-container inline'}

          >
            <div className="label-container">
              <div
                className="radio-label"
                onClick={handleClick}
              >
                Select All
              </div>
              <div className={'form-radio'}>
                <input
                  type="checkbox"
                  id={'marketing-select-all'}
                  //value={value}
                  checked={value}
                />
                <label htmlFor={'marketing-select-all'} onClick={handleClick}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const MarketingField = ({ input, displayTitle }) => {
  const selectAll = (value) => {

    const selected = value ? 1 : -1;
    const marketingOptions = {
      post: selected,
      sms: selected,
      email: selected,
      phone: selected,
    };
    input.onChange(marketingOptions);
  };

  const marketingValues = input.value;
  const isAllSelected = useMemo(() => getIsAllSelected(marketingValues));


  const name = input.name;
  return (
    <div className="marketing-ip">
      <p className="mkt-title">{displayTitle ? TITLE : ''}</p>
      <Row>
        <Col md={2}>
          <CheckboxNotRedux value={isAllSelected} onSelectAll={selectAll}/>

        </Col>
        <Col md={2}>
          <Field
            name={name + '.post'}
            options={[{ label: 'Post', value: 1, valid: [1, 2] }]}
            component={CheckboxMarketingOptions}
            format={consentToBool}
            normalize={consentToInt}
          />
        </Col>
        <Col md={3}>
          <Field
            name={name + '.email'}
            options={[{ label: 'Email', value: 1, valid: [1, 2] }]}
            component={CheckboxMarketingOptions}
            format={consentToBool}
            normalize={consentToInt}
          />
        </Col>
        <Col md={2}>
          <Field
            name={name + '.sms'}
            options={[{ label: 'SMS', value: 1, valid: [1, 2] }]}
            component={CheckboxMarketingOptions}
            format={consentToBool}
            normalize={consentToInt}
          />
        </Col>
        <Col md={3}>
          <Field
            name={name + '.phone'}
            options={[{ label: 'Phone', value: 1, valid: [1, 2] }]}
            component={CheckboxMarketingOptions}
            format={consentToBool}
            normalize={consentToInt}
          />
        </Col>
      </Row>
    </div>
  );

};

import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { actions as productActions } from '../../productManagement/redux/ProductReducer';
import { actions as documentActions } from '../redux/documentManagementActions';
import { Box, Loader } from '../../../common/components';
import DynamicDocumentList from '../components/DynamicDocumentList/DynamicDocumentList';
import InternalContainer from '../../layout/containers/Internal';
import ProductName
  from '../../productManagement/components/ProductFrontPage/components/ProductName/ProductName';
import ProductNavigation
  from '../../productManagement/components/ProductNavigation/ProductNavigation';


const DynamicDocumentListContainer = ({
  params,
  location,
  onGetProduct,
  onGetDynamicDocuments,
  onUpdateDynamicDocument,
}) => {
  const { productId } = params;

  const { product, } = useSelector((state) => state.productManagement);

  const {
    dynamicDocuments,
    isLoading
  } = useSelector((state) => state.documentManagement);

  const currentProductId = product?.data?.id;

  useEffect(
    () => {
      if (!currentProductId || currentProductId !== productId) {
        onGetProduct(productId);
      }
    },
    [productId, currentProductId, onGetProduct],
  );

  useEffect(
    () => {
      onGetDynamicDocuments(productId);
    },
    [productId, onGetDynamicDocuments],
  );

  const handleUpdateContent = (values) => {
    return Promise.resolve(onUpdateDynamicDocument(values)).then(() => {
      onGetDynamicDocuments(productId);
    });
  };


  const { icon, name, status, metadata } = product?.data?.attributes ?? {};
  const { is_wl_product: isWhitelabel } = metadata ?? {};

  const documentList = dynamicDocuments?.[productId]?.list;

  if (!documentList?.length || isLoading) {
    return (
      <InternalContainer title={'Product Management'}>
        <Loader/>
      </InternalContainer>
    );
  }
  return (
    <InternalContainer title={'Product Management'}>
      <ProductName
        title={'Dynamic Documents'}
        icon={icon}
        name={name}
        status={status}

      >
        <ProductNavigation
          id={productId}
          currentLocation={location.pathname}
          isWhitelabel={isWhitelabel}
        />

      </ProductName>
      <Box>
        <DynamicDocumentList
          dynamicDocuments={documentList}
          onUpdate={handleUpdateContent}
          isLoading={isLoading}
          productId={productId}
        />
      </Box>
    </InternalContainer>
  );
};

export default connect(
  null,
  {
    onGetDynamicDocuments: documentActions.getDynamicDocuments,
    onUpdateDynamicDocument: documentActions.updateDynamicDocument,
    onGetProduct: productActions.getProduct,
  },
)(DynamicDocumentListContainer);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import {
  Button,
  Modal,
  HorizontalFormControl,
  CheckboxControl
} from '../../../../common/components';
import { TextField, SelectField } from '../../../../common/components/ReduxFormField';
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux';
import { ControlLabel } from 'react-bootstrap';
import { cancelPolicy, getDetailsForCancellation } from '../../redux/policyActions';
import TextArea from '../../../content/components/TextArea/TextArea';
import { get, set } from 'lodash';
import './styles.scss';
import { isBroker } from '../../../auth/redux/authActions';

const FORM_NAME = 'cancelForm';

const validate = (values) => {
  const errors = {};

  if (!get(values, 'meta.reason', false) && !get(values, 'meta.note.content', false)) {
    set(errors, 'meta.reason', 'Reason for cancellation is required');
  }

  if (
    parseFloat(get(values, 'meta.refund_amount', 0)) >
    parseFloat(get(values, 'meta.max', 0))
  ) {
    set(errors, 'meta.refund_amount', 'Max refund of £' + get(values, 'meta.max', 0));
  }

  if (get(values, 'meta.refund', true) === false) {
    if (!get(values, 'meta.note.non_refund', false)) {
      set(errors, 'meta.note.non_refund', 'Reason for non refund is required');
    }
  }

  return errors;
};

class CancelButton extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    resource: PropTypes.object.isRequired,
    policy: PropTypes.object.isRequired,
    phoneNumber: PropTypes.string,
    callback: PropTypes.func,
    label: PropTypes.string,
  };

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded, callback } = this.props;
    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('');
      }
      dispatch(closeModal(FORM_NAME));
    }
  }

  handleSubmit(values) {
    return this.props.dispatch(cancelPolicy(values, this.props.resource));
  }

  handleOpen() {
    const { dispatch, change, reset } = this.props;
    dispatch(reset());
    const defaultEndorsement = {
      title: 'Policy Cancelled',
      content:
        'It has been agreed that this policy is cancelled from the effective date shown above.',
      metadata: {
        code: 'CXX',
      },
    };

    dispatch(
      getDetailsForCancellation(this.props.resource, (details) => {
        const maxRefundAmount = Math.abs(
          get(details, 'meta.return_premium.premium.gross', 0.0),
        ).toFixed(2);
        change('meta.max', maxRefundAmount);
        change('meta.gross_return_premium', maxRefundAmount);
        change(
          'meta.endorsement',
          get(details, 'meta.return_premium.information.endorsement', defaultEndorsement),
        );
        dispatch(openModal(FORM_NAME));
      }),
    );
  }

  render() {
    const reasons = [
      {
        label: 'Product unsuitable / misunderstood ',
        value: 'Product unsuitable / misunderstood ',
      },
      { label: 'Product too expensive ', value: 'Product too expensive ' },
      {
        label: 'Alternative product purchased ',
        value: 'Alternative product purchased ',
      },
      {
        label: 'Cover overlapped with pre-existing cover ',
        value: 'Cover overlapped with pre-existing cover ',
      },
      {
        label: 'Product cancelled within cooling off period ',
        value: 'Product cancelled within cooling off period ',
      },
      {
        label: 'Product cancelled by underwriter',
        value: 'Product cancelled by underwriter',
      },
      { label: 'Poor service/complaint ', value: 'Poor service/complaint ' },
      { label: 'Cover no longer required ', value: 'Cover no longer required ' },
      { label: 'Other', value: 'Other' },
      { label: 'Not known', value: 'Not known' },
    ];
    const { handleSubmit, submitting, policy, formValues } = this.props;
    const isPaymentOffline = !get(formValues, 'meta.refund', true);

    const submitButton = (
      <Button
        type="submit"
        bsStyle="primary"
        isLoading={submitting}
        handleClick={handleSubmit(this.handleSubmit.bind(this))}
        label="Cancel Policy"
      />
    );

    return (
      <div>
        <Button
          type="button"
          bsStyle="primary"
          className="pull-right"
          label="Cancel"
          rightIcon="ban"
          isLoading={policy.isCheckingCancel}
          handleClick={this.handleOpen.bind(this)}
        />

        <Modal name={FORM_NAME} title="Cancel Policy" close footer={submitButton}>
          <form onSubmit={handleSubmit(this.handleSubmit.bind(this))}>
            <div className="col-xs-12">
              <ControlLabel className="filter-label">
                Cancellation reason (for internal records)
              </ControlLabel>
              <Field name="meta.reason" options={reasons} component={SelectField} />
              {get(formValues, 'meta.reason') === 'Other' && (
                <div>
                  <ControlLabel className="filter-label">
                    Other reason not listed
                  </ControlLabel>
                  <Field name="meta.note.content" type="textarea" component={TextField} />
                </div>
              )}
            </div>

            <div className="col-xs-12">
              <div className="row">
                <div className="col-xs-12">
                  <ControlLabel className="filter-label">
                    Cancellation Endorsement
                  </ControlLabel>
                  <Field name="meta.endorsement.content" component={TextArea} />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-1">
                  <Field
                    name="meta.refund"
                    options={[{ label: '', value: true }]}
                    component={CheckboxControl}
                  />
                </div>
                <div className="col-sm-11">
                  Refund - The refund will be processed using the original payment method.
                  If you require the refund to be processed via a different payment method
                  please un-tick this box and refer to accounts
                </div>
              </div>
              {isPaymentOffline ? (
                <div className="row">
                  <div className="col-sm-12">
                    <ControlLabel>Reason (lost card etc.) </ControlLabel>
                    <Field
                      name="meta.note.non_refund"
                      type="textarea"
                      readOnly={isBroker()}
                      component={TextField}
                    />
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-sm-12">
                    <Field
                      name="meta.gross_return_premium"
                      label="Refund amount (inc. IPT)"
                      labelSize={8}
                      currency
                      component={HorizontalFormControl}
                    />
                  </div>
                </div>
              )}
              <br />
            </div>
          </form>
        </Modal>
      </div>
    );
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(CancelButton);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state) => {
  const values = selector(state, 'meta.refund', 'meta.reason', 'meta.max', 'meta.refund_amount');

  return {
    policy: state.policy,
    formValues: values,
    initialValues: {
      meta: {
        refund: true,
        max: '0.00',
        endorsement: {},
      },
    },
  };
};

export default connect(mapStateToProps)(form);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';
import MultiSelectMenu from '../SelectMenu/MultiSelectMenu';

export const SELECT_IDENTIFIER = 'products';

class SelectProducts extends Component {
  renderSelectMenu() {
    const source = 'products';
    return (
      <MultiSelectMenu
        source={source}
        sourceKey={'MultiProducts'}
        isClearable
        isObject
        {...this.props}
      />
    );
  }

  render() {
    const { infoButton, meta, label, labelSize } = this.props;

    if (label) {
      return (
        <FormGroup
          controlId={name}
          className={meta && meta.touched && meta.error ? ' has-error' : ''}
        >
          <Col componentClass={ControlLabel} sm={labelSize}>
            {label} {infoButton}
          </Col>
          <Col sm={12 - labelSize}>
            {this.renderSelectMenu()}
            {meta && meta.touched && meta.error && (
              <span className="input-error">{meta.error}</span>
            )}
          </Col>
        </FormGroup>
      );
    }

    return this.renderSelectMenu();
  }
}


SelectProducts.propTypes = {
  dispatch: PropTypes.func,
  label: PropTypes.string,
  labelSize: PropTypes.number,
  input: PropTypes.object.isRequired,
  infoButton: PropTypes.element,
  name: PropTypes.string,
  meta: PropTypes.object,
  labelKeys: PropTypes.array,
  object: PropTypes.bool,
  multi: PropTypes.bool,
  filters: PropTypes.array,
};

export default connect()(SelectProducts);

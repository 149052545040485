import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { getPolicy } from '../redux/policyActions';
import { Loader } from '../../../common/components';
import PolicyNotes from '../components/PolicyNotes';
import PolicyAudit from '../components/PolicyAudit';
import PolicyDetails from '../components/PolicyDetails';
import PolicyDocuments from '../components/PolicyDocuments';
import InternalContainer from '../../layout/containers/Internal';
import PolicyEndorsements from '../components/PolicyEndorsements';
import PolicyAccountsList from '../components/PolicyAccountsList';
import { get } from 'lodash';
import { SET_ACTIVE_TAB } from '../../layout/redux/tabs';
import InternalTabs from '../../layout/components/InternalTabs/InternalTabs';
import './styles/PolicyShow.scss';

const TAB_NAME = 'policyTabs';

class PolicyShowContainer extends Component {
  componentWillMount() {
    const { params, dispatch } = this.props;

    dispatch(getPolicy(params.id, params.type === 'applications'));
    dispatch({
      type: SET_ACTIVE_TAB,
      name: TAB_NAME,
      number: 1,
    });
  }

  render() {
    const { params, policy } = this.props;
    const doctorForm = get(policy, 'doctorFormResponse');
    const isSaving = get(policy, 'isSaving');
    const resource = policy.resources[params.id];

    let policyType = '';

    let ref = '';
    if (resource) {
      if (resource.data.attributes.policy_number) {
        ref =
          'Policy number: ' +
          resource.data.attributes.policy_number +
          (resource.data.attributes.version > 1
            ? ' (v' + resource.data.attributes.version + ')'
            : '');
        policyType = 'Policy';
      } else {
        ref =
          'Quote Reference: ' +
          resource.data.attributes.quote_reference +
          '/' +
          resource.data.attributes.version;
        policyType = 'Quote';

        if (
          resource.data.attributes.metadata.referrals &&
          resource.data.attributes.metadata.referrals.length
        ) {
          ref = 'Referral Reference: ' + resource.data.attributes.quote_reference;
          policyType = 'Referral';
        }

        if (resource.data.attributes.renewing_policy_number) {
          ref += ' - Renewing Policy: ' + resource.data.attributes.renewing_policy_number;
          policyType = 'Renewal';
        }
        if (resource.data.attributes.mta_policy_number) {
          ref += ' - MTA on Policy: ' + resource.data.attributes.mta_policy_number;
          policyType = 'Mid Term Adjustment';
        }
      }
    }

    if (policyType) {
      policyType += ' Details';
    }

    const reference = !resource ? '' : ref;

    return (
      <InternalContainer title={policyType}>
        {resource ? (
          <InternalTabs
            id={TAB_NAME}
            tabs={[
              {
                permissions: ['policy.view'],
                title: 'Details',
                content: (
                  <PolicyDetails
                    {...this.props}
                    resource={resource}
                    isSaving={isSaving}
                    reference={reference}
                    auth={this.props.auth}
                    doctorForm={doctorForm}
                    upload={this.props.upload}
                    quotesHistory={get(policy, 'quotesHistory.data')}
                  />
                ),
              },
              {
                permissions: ['policy.view'],
                title: 'Documents',
                content: (
                  <PolicyDocuments
                    {...this.props}
                    resource={resource}
                    reference={reference}
                    isGeneratingDocs={policy.isGeneratingDocs}
                    isProcessingPrintJobRequest={policy.isProcessingPrintJobRequest}
                  />
                ),
              },
              {
                permissions: ['policy.adjust'],
                orgTypes: ['owner'],
                title: 'Endorsements',
                content: resource.data.attributes.policy_number && (
                  <PolicyEndorsements
                    {...this.props}
                    resource={resource}
                    reference={reference}
                  />
                ),
              },
              {
                permissions: ['policy.notes_view', 'policy.notes_create'],
                title: 'Notes',
                content: (
                  <PolicyNotes
                    {...this.props}
                    resource={resource}
                    reference={reference}
                  />
                ),
              },
              {
                permissions: ['system.audit_logs_view'],
                orgTypes: ['owner'],
                title: 'Audit',
                content: (
                  <PolicyAudit
                    {...this.props}
                    resource={resource}
                    reference={reference}
                  />
                ),
              },
              {
                permissions: ['account.view'],
                orgTypes: ['owner'],
                title: 'Accounts',
                content: resource.data.attributes.policy_number && (
                  <PolicyAccountsList
                    resource={resource}
                    reference={reference}
                  />
                ),
              },
            ]}
          />
        ) : (
          <Loader />
        )}
      </InternalContainer>
    );
  }
}

PolicyShowContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  policy: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    policy: state.policy,
    auth: state.auth,
    upload: state.upload,
  };
}

export default connect(mapStateToProps)(PolicyShowContainer);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { capitalize, join, map, words } from 'lodash';
import './AutoCapsInput.scss';

class AutoCapsInput extends Component {
  static propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    labelSize: PropTypes.number,
    input: PropTypes.object,
    meta: PropTypes.object,
    className: PropTypes.string,
    note: PropTypes.string,
  };

  handleKeyUp(e) {
    const value = e.target.value;
    if (value) {
      e.target.value = join(
        map(words(value, /[^ ]+/g), (val) => {
          return capitalize(val);
        }),
        ' ',
      );
    }
  }

  renderField() {
    const { placeholder, className, disableOnBlur, input } = this.props;

    return (
      <FormControl
        {...input}
        type="text"
        placeholder={placeholder}
        onBlur={!disableOnBlur ? this.handleKeyUp.bind(this) : null}
        className={className}
      />
    );
  }

  render() {
    const { label, labelSize, input, meta, note } = this.props;

    if (label) {
      const calculatedControlSize = 12 - (labelSize || 2);
      return (
        <FormGroup
          controlId={input.name}
          className={meta && meta.touched && meta.error ? ' has-error' : ''}
        >
          <Col sm={labelSize}>
            <ControlLabel>{label}</ControlLabel>
          </Col>
          <Col sm={calculatedControlSize}>
            {this.renderField()}
            {note ? <div className="note">{note}</div> : ''}
            {meta.touched && meta.error && (
              <span className="input-error">{meta.error}</span>
            )}
          </Col>
        </FormGroup>
      );
    }

    return this.renderField();
  }
}

export default AutoCapsInput;

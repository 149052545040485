import React from 'react';
import { Field, FieldArray } from 'redux-form';
import { Col, Row } from 'react-bootstrap';
import { HorizontalFormControl, InfoLabel } from '../../../../common/components';

const SchemeGroupDetailArray = ({ fields, isViewOnly }) => {
  return (
    <>
      <h3>Schemes Names</h3>
      {fields.map((field) => (
        <>
          <Field
            name={`${field}.name`}
            component={isViewOnly ? InfoLabel : HorizontalFormControl}
            labelSize={4}
            label="Scheme Name:"
          />
        </>
      ))}
    </>
  );
};
const SchemeGroupDetails = ({ isViewOnly }) => {
  return (
    <>
      <Row>
        <Col sm={6}>
          <Field
            labelSize={4}
            label="Prefix:"
            disabled={isViewOnly}
            name={'data.attributes.prefix'}
            component={isViewOnly ? InfoLabel : HorizontalFormControl}
          />
        </Col>
      </Row>

      <FieldArray
        name={'data.attributes.scheme_details'}
        component={SchemeGroupDetailArray}
        isViewOnly={isViewOnly}
      />


    </>
  );
};

export default SchemeGroupDetails;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get, set, isEmpty, filter } from 'lodash';
import { CheckboxUnlabeled } from '../../../../common/components/ReduxFormField';
import { Field, FieldArray, getFormValues, reduxForm } from 'redux-form';
import { Form, Row, Col, ControlLabel, FormGroup } from 'react-bootstrap';
import 'moment/locale/en-gb';
import moment from 'moment';
import UploadCropComponent from '../../../../common/components/Upload/UploadCrop';
import { triggerFileUpload } from '../../../../common/components/Upload/UploadRedux';
import {
  Box,
  Button,
  DatePickerDob,
  HorizontalFormControl,
  SelectChannel,
  SelectProduct,
  // SelectBroker,
} from '../../../../common/components';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import SelectField from '../../../../common/components/ReduxFormField/SelectField';
import Select from 'react-select';
import '../CampaignNewForm/styles.scss';
import SelectBrokerFields from '../SelectBrokerCampaigns/SelectBrokerFields';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';

const templateDefaults = {
  id: null,
  type: 'templates',
  attributes: {
    name: '',
    slug: '',
    sections: {},
  },
};

class CampaignEditForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      template: templateDefaults,
      destinationOptions: [],
      familyOptions: [],
      valueRegions: [],
      valueTravellerGroup: [],
      valueCoverLevels: [], // cover Level
      allAges: false,
      allScores: false,
      allDurations: false,
      banner: false,
    };
    this.renderField = this.renderField.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSelectChangeDestinations = this.handleSelectChangeDestinations.bind(this);
    this.familyGroupsSelection = this.familyGroupsSelection.bind(this);
    this.destinationSelection = this.destinationSelection.bind(this);
    this.allAges = this.allAges.bind(this);
    this.allScores = this.allScores.bind(this);
    this.allDurations = this.allDurations.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleUploadComplete = this.handleUploadComplete.bind(this);
    this.coverSelection = this.coverSelection.bind(this);
    this.handleSelectCoverLevels = this.handleSelectCoverLevels.bind(this);
  }

  handleSelectChange(valueTravellerGroup) {
    this.setState({ valueTravellerGroup });
    let valuesArr = valueTravellerGroup.split(',');
    this.props.dispatch(
      this.props.change('data.attributes.criterion.traveller_group.in', valuesArr),
    );
  }

  handleUpload() {
    const { dispatch } = this.props;
    dispatch(triggerFileUpload());
  }

  handleUploadComplete(data) {
    const { dispatch, change } = this.props;
    dispatch(change('data.relationships.avatar.data', data.data));
  }

  componentWillReceiveProps(nextProps) {
    const coverLevelNextProps = get(
      nextProps.formValues,
      'data.attributes.cover_level.equal',
    );
    const coverLevelOldProps = get(
      this.props.formValues,
      'data.attributes.cover_level.equal',
    );

    const getDestinationsSingle = [
      { label: 'UK', value: 'uk' },
      { label: 'Europe Excluding', value: 'europe_excluding' },
      { label: 'Europe', value: 'europe' },
      { label: 'Worldwide Excluding', value: 'worldwide_excluding' },
      { label: 'Worldwide', value: 'worldwide' },
    ];
    const getDestinationsAnnual = [
      { label: 'Europe', value: 'europe' },
      { label: 'Worldwide', value: 'worldwide' },
    ];
    if (coverLevelNextProps !== coverLevelOldProps) {
      if (coverLevelNextProps === 'Annual') {
        this.setState(() => ({
          destinationOptions: getDestinationsAnnual,
        }));
      }
      if (coverLevelNextProps === 'Single') {
        this.setState(() => ({
          destinationOptions: getDestinationsSingle,
        }));
      }
    }
  }

  componentWillMount() {
    const { initialValues } = this.props;
    const getCoverLevelOptions = [
      { label: 'Super', value: 'super' },
      { label: 'Super Duper', value: 'super_duper' },
    ];

    const getFamilyGroup = [
      { label: 'Individual', value: 'individual' },
      { label: 'Couple', value: 'couple' },
      { label: 'Family', value: 'family' },
      { label: 'Single Parent Family', value: 'single_parent_family' },
    ];

    const getDestinations = [
      { label: 'United Kingdom & Isle Of Man', value: 'uk' },
      { label: 'Europe Area 1', value: 'europe_excluding' },
      { label: 'Europe Area 2', value: 'europe' },
      { label: 'Australia & New Zealand', value: 'australia_new_zealand' },
      { label: 'Worldwide Exc USA/Canada/Caribbean', value: 'worldwide_excluding' },
      { label: 'Worldwide Inc USA/Canada/Caribbean', value: 'worldwide' },
    ];

    const destinations = get(initialValues, 'data.attributes.criterion.region.in');
    const coverLevels = get(initialValues, 'data.attributes.criterion.cover_level.in');
    const familyGroup = get(
      initialValues,
      'data.attributes.criterion.traveller_group.in',
    );

    this.setState(() => ({
      familyOptions: getFamilyGroup,
      destinationOptions: getDestinations,
      coverLevelsOptions: getCoverLevelOptions,
      valueRegions: destinations,
      valueTravellerGroup: familyGroup,
      valueCoverLevels: coverLevels,
    }));
  }

  renderField(field) {
    const { input, type, meta } = field;
    const { allAges, allDurations, allScores } = this.state;
    const className =
      meta.submitFailed && meta.touched && meta.error && meta.error.length > 0
        ? 'form-control has-error'
        : 'form-control';
    let placeHolder = field.placeholder;
    switch (field.id) {
      case 'ageStart':
        placeHolder = '-';
        break;
      case 'ageEnd':
        placeHolder = '-';
        break;
      case 'duration':
        placeHolder = '-';
        break;
      case 'scores':
        placeHolder = '-';
        break;
    }
    return (
      <div>
        <input
          disabled={field.disabled}
          {...input}
          className={className}
          type={type}
          placeholder={allAges || allDurations || allScores ? placeHolder : ''}
        />
      </div>
    );
  }

  selectBrokerIfB2B() {
    const { resource: fullResource, formValues, campaign } = this.props;
    const channelIncluded = getIncludedResource(
      fullResource.data,
      fullResource.included,
      'channels',
    );
    const getB2BIncluded = filter(channelIncluded, function(o) {
      return get(o, 'attributes.channel_type') === 'B2B';
    });

    const brokersIncluded = getIncludedResource(
      fullResource.data,
      fullResource.included,
      'brokers',
    );

    const channel = get(formValues, 'data.relationships.channels.data');
    const getB2B = filter(channel, function(o) {
      return get(o, 'attributes.channel_type') === 'B2B';
    });

    if (isEmpty(getB2BIncluded) && isEmpty(getB2B)) {
      return;
    }

    return (
      <div>
        <FieldArray
          name="data.relationships.brokers.data"
          component={SelectBrokerFields}
          campaign={campaign}
          brokers={brokersIncluded}
          formValues={formValues}
          dispatch={this.props.dispatch}
          change={this.props.change}
          labelSize={4}
          initialValues={this.props.initialValues}
          edit
        />
        {/*
       <Field
         name="data.relationships.brokers.data"
         object
         label="Broker"
         labelSize={4}
         component={SelectBroker}
         disabled={submitting}
         multi
        />
        */}
      </div>
    );
  }

  familyGroupsSelection({ input, label }) {
    return (
      <FormGroup>
        <Col xs={4} sm={ControlLabel}>
          {label}
        </Col>
        <Col xs={8}>
          <Select
            {...input}
            multi
            simpleValue
            autoBlur
            value={this.state.valueTravellerGroup}
            options={this.state.familyOptions}
            onChange={this.handleSelectChange}
            dispatch={this.props.dispatch}
            change={this.props.change}
          />
        </Col>
      </FormGroup>
    );
  }

  allAges() {
    const { dispatch, change } = this.props;
    Promise.resolve(dispatch(change('data.attributes.criterion.min_age.min', '')))
      .then(dispatch(change('data.attributes.criterion.max_age.max', '')))
      .then(() =>
        this.setState((prevState) => ({
          allAges: !prevState.allAges,
        })),
      );
  }

  allDurations() {
    const { dispatch, change } = this.props;
    Promise.resolve(dispatch(change('data.attributes.criterion.duration.min', '')))
      .then(dispatch(change('data.attributes.criterion.duration.max', '')))
      .then(() =>
        this.setState((prevState) => ({
          allDurations: !prevState.allDurations,
        })),
      );
  }

  allScores() {
    const { dispatch, change } = this.props;
    Promise.resolve(dispatch(change('data.attributes.criterion.max_score.min', '')))
      .then(dispatch(change('data.attributes.criterion.max_score.max', '')))
      .then(() =>
        this.setState((prevState) => ({
          allScores: !prevState.allScores,
        })),
      );
  }

  handleSelectChangeDestinations(valueRegions) {
    this.setState({ valueRegions });
    let valuesArr = valueRegions.split(',');
    this.props.dispatch(
      this.props.change('data.attributes.criterion.region.in', valuesArr),
    );
  }

  destinationSelection({ input, label }) {
    return (
      <FormGroup className="form-group">
        <Col xs={4}>
          <label className="control-label">{label}</label>
        </Col>
        <Col xs={8}>
          <Select
            {...input}
            autoBlur
            multi
            simpleValue
            disabled={this.state.disabled}
            value={this.state.valueRegions}
            options={this.state.destinationOptions}
            onChange={this.handleSelectChangeDestinations}
            dispatch={this.props.dispatch}
            change={this.props.change}
          />
        </Col>
      </FormGroup>
    );
  }

  /*****************
   * Cover Level
   *****************/
  handleSelectCoverLevels(valueCoverLevels) {
    this.setState({ valueCoverLevels });

    let valuesArr = valueCoverLevels.split(',');
    this.props.dispatch(
      this.props.change('data.attributes.criterion.cover_level.in', valuesArr),
    );
  }

  coverSelection({ input, label }) {
    return (
      <FormGroup className="form-group">
        <Col xs={4}>
          <label className="control-label">{label}</label>
        </Col>
        <Col xs={8}>
          <Select
            {...input}
            autoBlur
            multi
            simpleValue
            disabled={this.state.disabled}
            value={this.state.valueCoverLevels}
            options={this.state.coverLevelsOptions}
            onChange={this.handleSelectCoverLevels}
            dispatch={this.props.dispatch}
            change={this.props.change}
          />
        </Col>
      </FormGroup>
    );
  }

  render() {
    const { handleSubmit, submitting, formValues } = this.props;

    const schemeLevel = [
      { label: 'Annual', value: 'annual' },
      { label: 'Single', value: 'single' },
    ];

    const discountTypes = [
      {
        label: 'Gross Selling Price Discount',
        value: 'Gross Selling Price Discount',
        field: 'data.attributes.discount',
        fieldLabel: 'Discount',
      },
      {
        label: 'Net Discount',
        value: 'Net Discount',
        field: 'data.attributes.discount',
        fieldLabel: 'Discount',
      },
      {
        label: 'Broker Commission Override',
        value: 'Broker Commission Override',
        field: 'data.attributes.premium_overrides.commissions.commission_broker',
        fieldLabel: 'Commission Override',
      },
      {
        label: 'PJH Commission Override',
        value: 'Owner Commission Override',
        field: 'data.attributes.premium_overrides.commissions.commission_owner',
        fieldLabel: 'Commission Override',
      },
      {
        label: 'Total Commission Override',
        value: 'Total Commission Override',
        field: 'data.attributes.premium_overrides.commissions.commission_rate',
        fieldLabel: 'Commission Override',
      },
    ];

    const selectedDiscountType = () => {
      const discountType = get(
        formValues,
        'data.attributes.discount_type',
        'Gross Selling Price Discount',
      );
      for (let i = 0; i < discountTypes.length; i++) {
        if (discountTypes[i].value === discountType) {
          return discountTypes[i].field;
        }
      }

      return 'data.attributes.discount';
    };

    const selectedDiscountLabel = () => {
      const discountType = get(
        formValues,
        'data.attributes.discount_type',
        'Gross Selling Price Discount',
      );
      for (let i = 0; i < discountTypes.length; i++) {
        if (discountTypes[i].value === discountType) {
          return discountTypes[i].fieldLabel;
        }
      }

      return 'data.attributes.discount';
    };

    return (
      <div>
        <Box>
          <h4 className="title-campaign">Edit Campaign</h4>
          <hr id="first-row" />
          <Form onSubmit={handleSubmit} autoComplete="off" className="form-horizontal">
            <Row className="row">
              <Col sm={6}>
                  <Field
                    name="data.attributes.name"
                    label="Name"
                    labelSize={4}
                    component={HorizontalFormControl}
                  />
                  <Field
                    name="data.attributes.code"
                    label="Voucher Code"
                    labelSize={4}
                    component={HorizontalFormControl}
                  />
                  <Field
                    name="data.attributes.exclusive"
                    label={<strong>Exclusive</strong>}
                    component={CheckboxUnlabeled}
                    positionTop
                    inline
                    labelSize={4}
                    options={[{ label: '', value: true }]}
                  />
                  <Field
                    name="data.attributes.has_url"
                    label={<strong>Has url?</strong>}
                    component={CheckboxUnlabeled}
                    positionTop
                    inline
                    labelSize={4}
                    options={[{ label: '', value: true }]}
                  />
              </Col>

              <Col sm={6}>
                  <Field
                    name="data.attributes.discount_type"
                    label="Discount Type"
                    multi
                    object
                    labelSize={4}
                    options={discountTypes}
                    component={SelectField}
                  />
                  <Field
                    name={selectedDiscountType()}
                    label={selectedDiscountLabel()}
                    labelSize={4}
                    object
                    mdFieldSize={4}
                    addonAfter={'%'}
                    component={HorizontalFormControl}
                  />
                  <Field
                    name="data.attributes.from_date"
                    label="Start Date"
                    labelSize={4}
                    mdFieldSize={7}
                    component={DatePickerDob}
                    showTime
                  />
                  <Field
                    name="data.attributes.to_date"
                    label="End Date"
                    labelSize={4}
                    mdFieldSize={7}
                    component={DatePickerDob}
                    showTime
                  />
              </Col>
            </Row>
            <hr id="second-row" />
            <Row>
              <Col sm={6}>
                <div className="form-horizontal">
                  <Field
                    name="data.relationships.channels.data"
                    label="Channels"
                    multi
                    object
                    component={SelectChannel}
                    labelSize={4}
                  />
                  {this.selectBrokerIfB2B()}
                  <Field
                    name="data.relationships.product.data.id"
                    label="Product"
                    labelSize={4}
                    component={SelectProduct}
                  />
                </div>
              </Col>
              <Col sm={6}>
                  <Field
                    name="data.attributes.criterion.scheme_type.equal"
                    label="Scheme Type"
                    labelSize={4}
                    options={schemeLevel}
                    component={SelectField}
                  />
                  <Field
                    label="Cover Levels"
                    name="data.attributes.criterion.cover_level.in"
                    options={getCoverLevelOptions}
                    component={SelectField}
                    isMulti
                    labelSize={4}
                  />
                  {/*<Field*/}
                  {/*label='Cover Levels'*/}
                  {/*name='data.attributes.criterion.cover_level.in'*/}
                  {/*component={this.coverSelection}*/}
                  {/**/}
                  {/*options={this.state.coverLevelOptions}*/}
                  {/*/>*/}
              </Col>
            </Row>
            <hr id="third-row-banner" />
            <Row>
              <Col sm={6}>
                  <Field
                    label="Family Group"
                    labelSize={4}
                    name="data.attributes.criterion.traveller_group.in"
                    component={SelectField}
                    isMulti
                    options={this.state.familyOptions}
                  />
                  <Field
                    label="Destinations"
                    labelSize={4}
                    name="data.attributes.criterion.region.in"
                    component={SelectField}
                    isMulti
                    options={this.state.destinationOptions}
                  />
              </Col>
              <div className="col-sm-6">
                  <FormGroup controlId="age">
                    <Col componentClass={ControlLabel} sm={4}>
                      Target Ages
                    </Col>
                    <Col sm={3}>
                      <Field
                        name="data.attributes.criterion.max_age.min"
                        type="text"
                        id="ageStart"
                        component={this.renderField}
                        disabled={this.state.allAges}
                      />
                    </Col>
                    <Col sm={1}>
                      <p className="text-adjust">to</p>
                    </Col>
                    <Col sm={3}>
                      <Field
                        name="data.attributes.criterion.max_age.max"
                        type="text"
                        id="ageEnd"
                        component={this.renderField}
                        disabled={this.state.allAges}
                      />
                    </Col>
                    <Col sm={1}>
                      <p
                        className={
                          'select-all-link' + (this.state.allAges ? ' all-visited' : '')
                        }
                        onClick={() => this.allAges()}
                      >
                        All
                      </p>
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="range-fields">
                    <Col componentClass={ControlLabel} sm={4}>
                      Target Trip Duration
                    </Col>
                    <Col sm={3}>
                      <Field
                        name="data.attributes.criterion.trip_duration.min"
                        type="text"
                        id="duration"
                        component={this.renderField}
                        disabled={this.state.allDurations}
                      />
                    </Col>
                    <Col sm={1}>
                      <p className="text-adjust">to</p>
                    </Col>
                    <Col sm={3}>
                      <Field
                        name="data.attributes.criterion.trip_duration.max"
                        type="text"
                        id="duration"
                        component={this.renderField}
                        disabled={this.state.allDurations}
                      />
                    </Col>
                    <Col sm={1}>
                      <p
                        className={
                          'select-all-link' +
                          (this.state.allDurations ? ' all-visited' : '')
                        }
                        onClick={() => this.allDurations()}
                      >
                        All
                      </p>
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="">
                    <Col componentClass={ControlLabel} sm={4}>
                      Target Verisk Scores
                    </Col>
                    <Col sm={3}>
                      <Field
                        name="data.attributes.criterion.max_score.min"
                        type="text"
                        disabled={this.state.allScores}
                        id="scores"
                        component={this.renderField}
                      />
                    </Col>
                    <Col sm={1}>
                      <p className="text-adjust">to</p>
                    </Col>
                    <Col sm={3}>
                      <Field
                        name="data.attributes.criterion.max_score.max"
                        type="text"
                        disabled={this.state.allScores}
                        id="scores"
                        component={this.renderField}
                      />
                    </Col>
                    <Col sm={1}>
                      <p
                        className={
                          'select-all-link' + (this.state.allScores ? ' all-visited' : '')
                        }
                        onClick={() => this.allScores()}
                      >
                        All
                      </p>
                    </Col>
                  </FormGroup>
              </div>
            </Row>
            {this.state.banner && (
              <div className="banner-container">
                <hr id="fourth-row-banner" />
                <h4 className="title-campaign">Banner</h4>
                <Row>
                  <Col sm={6}>
                    <div className="form-horizontal">
                      <Field
                        name="data.attributes.banner_enabled"
                        label="Enabled"
                        component={CheckboxUnlabeled}
                        positionTop
                        inline
                        labelSize={4}
                        options={[{ label: '', value: true }]}
                      />
                      <Field
                        name="data.attributes.page"
                        label="Page"
                        labelSize={4}
                        component={HorizontalFormControl}
                      />
                      <Field
                        name="data.attributes.banner"
                        label="Banner Text"
                        labelSize={4}
                        component={HorizontalFormControl}
                      />
                    </div>
                  </Col>
                  <Col sm={6}>
                      <FormGroup controlId="range-fields">
                        <Col sm={4}>
                          <p className="image-upload-text">Image Upload</p>
                        </Col>
                        <Col sm={4}>
                          <Button
                            type="button"
                            bsStyle="primary settings-button"
                            className="pull-right"
                            // disabled={submitting}
                            handleClick={this.handleUpload}
                          >
                            {submitting ? (
                              <i className="fa fa-cog fa-spin" />
                            ) : (
                              <i className="fa" />
                            )}{' '}
                            Select File
                          </Button>
                        </Col>
                      </FormGroup>
                      <Field
                        name="data.attributes.time_idle"
                        label="Time Idle"
                        labelSize={4}
                        mdFieldSize={4}
                        component={HorizontalFormControl}
                      />
                  </Col>
                </Row>
              </div>
            )}
            <Row>
              <Col xs={12}>
                <InternalButtonBar buttons={[
                  {
                    permissions: ['marketing.campaign_create'],
                    button: (
                      <Button
                        size="large"
                        type="submit"
                        className="pull-right pad-right"
                        label="Save New Version"
                        bsStyle="primary"
                      />
                    )
                  }
                ]}/>
              </Col>
            </Row>
          </Form>
        </Box>
        <UploadCropComponent
          onComplete={this.handleUploadComplete}
          width={200}
          height={200}
          bsSize="sm"
          circle={false}
        />
      </div>
    );
  }
}

let getOriginalName;
const validate = (values) => {
  const errors = {};

  const todayDate = moment().format();
  const formDate = get(values, 'data.attributes.to_date');
  const checkDatesDiff = moment(formDate).isBefore(todayDate);

  // End date should be later than current date
  if (checkDatesDiff === true) {
    set(errors, 'data.attributes.to_date', 'Update is required');
  }

  if (getOriginalName === undefined) {
    getOriginalName = get(values, 'data.attributes.name');
  }

  if (get(values, 'data.attributes.name') === getOriginalName) {
    set(errors, 'data.attributes.name', 'Update is required');
  }

  errors._error = errors.data_attributes;
  return errors;
};

const getCoverLevelOptions = [
  { label: 'Super', value: 'super' },
  { label: 'Super Duper', value: 'super_duper' },
];

const FORM_NAME = 'campaignEditForm';
const form = reduxForm({ form: FORM_NAME, validate })(CampaignEditForm);
const mapStateToProps = (state, props) => {
  if (
    Array.isArray(props.resource.data.attributes.metadata) &&
    !props.resource.data.attributes.metadata.length
  ) {
    props.resource.data.attributes.metadata = {};
  }

  return {
    initialValues: props.resource,
    formValues: getFormValues(FORM_NAME)(state),
    campaign: state.campaign,
  };
};

export default connect(mapStateToProps)(form);

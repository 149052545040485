import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { actions as documentActions } from '../../redux/documentManagementActions';
import EditDocumentForm from './EditDocumentForm';

const ACTION_TYPE_EDIT = 'edit';
const ACTION_TYPE_ADD_SUPERSEDING = 'add-superseding';
const ACTION_TYPE_EDIT_SUPERSEDING = 'edit-superseding';
const ACTION_TYPE_VIEW_SUPERSEDING = 'view-superseding';

const EditDocumentModal = ({
  document,
  schemes,
  actionType,
  show,
  onSaveDocument,
  onAddDocument,
  onCloseDocumentModal,
  isAncillarySchemeDocument,
  hideSchemes,
  getAncillaryFormInfo,
}) => {

  const [showModal, setShowModal] = useState(false);
  const name = document?.data?.attributes.name;

  const isSuperseding = [
    ACTION_TYPE_VIEW_SUPERSEDING,
    ACTION_TYPE_EDIT_SUPERSEDING,
    ACTION_TYPE_ADD_SUPERSEDING,
  ].includes(actionType);
  const isAddingSuperseding = ACTION_TYPE_ADD_SUPERSEDING === actionType;

  const modalTile =
    ACTION_TYPE_ADD_SUPERSEDING === actionType
      ? `New Broker Document ${name} `
      : `Edit ${name}`;

  const closeModal = () => {
    onCloseDocumentModal();
  };
  useEffect(() => {
    if (show) {
      showDocumentModal();
    }
  }, [show]);


  const showDocumentModal = () => {
    if (isAncillarySchemeDocument) {
      return Promise.resolve(getAncillaryFormInfo()).then(() => {
        setShowModal(true);
      });
    } else {
      setShowModal(true);
    }
  };
  const handleEditDocument = (formValues) => {
    if (actionType === ACTION_TYPE_EDIT || actionType === ACTION_TYPE_EDIT_SUPERSEDING) {
      return Promise.resolve(onSaveDocument(formValues)).then(() => {

      }).finally(() => {
        closeModal();
      });
    }

    if (actionType === ACTION_TYPE_ADD_SUPERSEDING) {
      return Promise.resolve(onAddDocument(formValues)).then(() => {

      }).finally(() => {
        closeModal();
      });
    }
  };

  return (
    <div>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTile}</Modal.Title>
        </Modal.Header>

        <EditDocumentForm
          document={document}
          schemes={schemes}
          isSuperseding={isSuperseding}
          isAddingSuperseding={isAddingSuperseding}
          isAncillarySchemeDocument={isAncillarySchemeDocument}
          onSaveDocument={handleEditDocument}
          closeModal={closeModal}
          hideSchemes={hideSchemes || isSuperseding}
        />

      </Modal>
    </div>
  );
};

export default connect(
  null,
  {
    onCloseDocumentModal: documentActions.closeEditDocumentModal,
  },
)(EditDocumentModal);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import SideBarContainer from './SideBarContainer';
import Icon from '../../../common/components/Icon/Icon';
import { openMenu } from '../redux/sideNavAction';

class BodyContainer extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    children: PropTypes.object,
    location: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(openMenu(true));
  }

  toggleMenu() {
    this.props.dispatch(openMenu(!this.props.sideNav.menu));
  }

  render() {
    const {
      sideNav: { menu: show },
      auth: { authenticated },
      children,
      location: { pathname }
    } = this.props;

    if (authenticated) {
      return (
        <div className="flex-container-body">
          <div className={show ? 'width-80' : 'width-95'}>
            {React.cloneElement(children, {
              key: pathname,
            })}

            <div style={{ clear: 'both' }}/>
          </div>

          <div className={show ? 'width-20' : 'width-5'}>
            <div className={show ? 'collapsed-sidebar-hidden' : 'collapsed-sidebar'}>
              <span className="collapse-btn" onClick={this.toggleMenu}>
                {show ? (
                  <Icon className="collapse-btn" name="chevron-circle-left"/>
                ) : (
                  <Icon name="chevron-circle-right"/>
                )}
              </span>
              {show ? (
                ''
              ) : (
                <div className="collapsed-menu">
                  <Link to="/dashboard">
                    <Icon name="home"/>
                  </Link>
                </div>
              )}
            </div>
            {show ? <SideBarContainer {...this.props} /> : ''}
          </div>
        </div>
      );
    }

    return (
      <React.Fragment>
        {React.cloneElement(children, { key: pathname })}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    layout: state.layout,
    sideNav: state.sideNav,
  };
}

export default connect(mapStateToProps)(BodyContainer);

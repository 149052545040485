import React, {  useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, Field } from 'redux-form';
import { getPremiumCommissions, recalculateCommissions, reallocateCommissions } from '../../redux/accountActions';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import { ControlLabel, Form, FormGroup, Modal } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';

import { Input, Button } from '../../../../common/components';
import displayName from '../../../../helpers/displayName';
import displayCurrency from '../../../../helpers/displayCurrency';
import { formatNumber, parseNumber } from '../../../../helpers/formHelpers';
import Icon from '../../../../common/components/Icon/Icon';
import { compose } from 'redux';

const FORM_NAME = 'ReallocateCommissionForm';

const LABEL_SIZE = 2;
const FIELD_SIZE = 4;


const ReallocateCommissionsModalNew = ({
                                         premiumId,
                                         formValues,
                                         dispatch,
                                         change,
                                         reset,
                                         handleSubmit,
                                         isCommissionRecalculating,
                                         title,
                                         refresh

                                       }) => {
  const [show, setShow] = useState(false);
  const [commissions, setCommissions] = useState(null);
  const [originalCommissions, setOriginalCommissions] = useState(null);
  const [commissionRate, setCommissionRate] = useState(null);
  const [commissionValue, setCommissionValue] = useState(null);

  const handleReallocateCommissionSubmit = () => {
    Promise.resolve(dispatch(reallocateCommissions(premiumId, formValues))).then(() => {
      refresh();
      setShow(false);
    });
  };

  const rateToPercent = (rate) => {
    // noinspection MagicNumberJS
    return (rate * 100);
  };


  const recalculate = () => {
    const total = Object.values(formValues.meta.commissions)
      .reduce((carry, commissionAmount) => {
        carry += commissionAmount;
        return carry;

      }, 0);

    if (total === commissionRate) {
      Promise.resolve(dispatch(recalculateCommissions(premiumId, formValues))).then((resp) => {
        setCommissions(Object.values(resp.commissions));

      });
    }

  }



  const totalCommissionValue = () => {
    return commissions.reduce((carry, commission) => {
      const commissionAmount = commission.amount || 0;
      carry += parseFloat(commissionAmount);
      return carry
    }, 0);
  };

  const totalCommissionRate = () => {
    return commissions.reduce((carry, commission) => {
      const commissionRate = commission.rate || 0;
      carry += parseFloat(commissionRate) * 100;
      return carry
    }, 0).toFixed(2);

  };


  const handleOpen = () => {
    Promise.resolve(dispatch(getPremiumCommissions(premiumId))).then((commissions) => {
      setCommissionRate(commissions.commission_rate)
      setCommissionValue(commissions.commission_value)
      setOriginalCommissions(commissions.commissions);
      setCommissions(commissions.commissions);
      commissions.commissions.map((commission) => {
        dispatch(change(`meta.commissions.${commission.organisation_id}`, rateToPercent(commission.rate)));
      })
      setShow(true);
    })
  };

  const handleHide = () => {
    dispatch(reset());
    setShow(false);
  };




  return (
    <div>
      <Button
        label="Reallocate Commission"
        variant="primary"
        className="pull-right"
        handleClick={handleOpen}
      />
      <Modal
        show={show}
        onHide={handleHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Commission reallocation
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {commissions && (
            <div>
              <h4>
                {title}
              </h4>
              <Form horizontal onSubmit={handleSubmit(handleReallocateCommissionSubmit)}>
                <Row>
                  <Col
                    sm={FIELD_SIZE}
                    smOffset={LABEL_SIZE}
                  >
                    <strong>Current Allocation</strong>
                  </Col>
                  <Col sm={FIELD_SIZE}>
                    <strong>New Allocation</strong>
                  </Col>
                </Row>
                {originalCommissions.map((originalCommission, i) => {
                  const commission = commissions.find((comm) => comm.organisation_id === originalCommission.organisation_id);

                  return (
                    <FormGroup key={i}>
                      <Col sm={LABEL_SIZE}>

                        <ControlLabel>
                          {displayName(originalCommission.commission_type)}
                        </ControlLabel>
                      </Col>

                      <Col sm={FIELD_SIZE}>
                        <Input
                          disabled
                          addOnAfter={displayCurrency(originalCommission.amount.toString(), 'GBP')}
                          placeholder={rateToPercent(originalCommission.effective_rate ? originalCommission.effective_rate : originalCommission.rate)}
                        />
                      </Col>
                      <Col sm={FIELD_SIZE}>
                        <Field
                          component={Input}
                          name={'meta.commissions.' + commission.organisation_id + ''}
                          handleOnBlur={recalculate}
                          placeholder={rateToPercent(commission.rate)}
                          addOnAfter={isCommissionRecalculating ?
                            (<Icon name={'spinner'} spin/>)
                            :
                            commission.amount.toFixed(2)
                          }
                          isCalc={isCommissionRecalculating}
                          parse={parseNumber}
                          format={formatNumber}
                        />
                      </Col>
                    </FormGroup>
                  )
                })}


                <FormGroup>
                  <Col sm={LABEL_SIZE}>
                    <ControlLabel>
                      Total
                    </ControlLabel>
                  </Col>
                  <Col sm={FIELD_SIZE}>
                    <Input
                      disabled
                      placeholder={commissionRate ? commissionRate.toFixed(2).toString() : ''}
                      addOnAfter={displayCurrency(commissionValue, 'GBP')}

                    />
                  </Col>
                  <Col sm={FIELD_SIZE}>
                    <Input
                      disabled
                      placeholder={totalCommissionRate()}
                      addOnAfter={
                        isCommissionRecalculating ?
                          (<Icon name={'spinner'} spin/>)
                        :
                        displayCurrency(totalCommissionValue(), 'GBP')}
                      isCalc={isCommissionRecalculating}
                    />
                  </Col>
                </FormGroup>
                <Modal.Footer>
                  <InternalButtonBar
                    buttons={
                      [
                        {
                          filter: [{ filterValues: [true], value: !!commissions }],
                          button: (
                            <Button
                              label="Post Commission Adjustment"
                              variant="primary"
                              className="pull-right"
                              type="submit"
                            />)

                        }
                      ]
                    }
                  />
                </Modal.Footer>
              </Form>
            </div>
          )}

        </Modal.Body>
      </Modal>
    </div>);
};

export default compose(
  connect(
    (state) => ({

      formValues: getFormValues(FORM_NAME)(state),
      isCommissionRecalculating: state.account.isCommissionRecalculating,
    }),
  ),
  reduxForm({ form: FORM_NAME })
)(ReallocateCommissionsModalNew);

import React from 'react';
import { Field } from 'redux-form';

import { Col, ControlLabel, Row } from 'react-bootstrap';
import { HorizontalFormControl } from '../../../../common/components';
import TextArea from '../../../content/components/TextArea/TextArea';

import CollapsableBox from '../CollapsableBox/CollapsableBox';

const WhiteLabelContent = () => {

  return (
    <div>
      <CollapsableBox label={'White label content'}>
        <>
          <Row>
            <Col sm={12}>
              <Field
                name="meta.whitelabel.content.display_name"
                label="Display Name"
                component={HorizontalFormControl}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Field
                name="meta.whitelabel.content.telephone_number"
                label="Telephone Number"
                component={HorizontalFormControl}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Field
                name="meta.whitelabel.content.opening_hours"
                label="Opening Hours"
                component={HorizontalFormControl}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <ControlLabel>Footer</ControlLabel>
            </Col>
            <Col sm={12}>
              <Field
                name="meta.whitelabel.content.footer"
                component={TextArea}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Field
                name="meta.whitelabel.content.meta_title"
                label="Meta Title"
                component={HorizontalFormControl}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Field
                name="meta.whitelabel.content.meta_description"
                label="Meta Description"
                component={HorizontalFormControl}
              />
            </Col>
          </Row>

        </>
      </CollapsableBox>
    </div>
  );
};
export default WhiteLabelContent;

import React from 'react';
import { get } from 'lodash';
import { FaCheck, FaClose } from 'react-icons/lib/fa';
import './ButtonGroup.scss';

const ButtonGroup = ({
  input,
  options,
  objectKey,
  inline,
  width,
  showError,
  meta: { touched, error, submitFailed },
}) => {
  return (
    <div
      className={
        touched && error && submitFailed ? 'medical-has-error' : ''
      }
    >
      <div>
        {options.map((option, key) => {
          const value = objectKey ? get(option.value, objectKey) : option.value;

          const onBlur = () => input.onBlur(value);
          const onChange = () => input.onChange(value);
          const complexValueOverrides = { onBlur, onChange };

          return (
            <div
              className={'radio-container-medical' + (inline ? ' inline' : '')}
              key={key}
              style={width ? { width: width } : {}}
            >
              <div className={'form-radio'}>
                <input
                  {...input}
                  {...complexValueOverrides}
                  type="radio"
                  id={input.name + option.label}
                  value={option.value}
                  checked={input.value === value}
                />
                <label htmlFor={input.name + option.label}>
                  <div
                    className="radio-label"
                    onClick={() => input.onChange(option.value)}
                  >
                    {option.label === 'No' ? <FaClose /> : <FaCheck />}
                    {option.label}
                  </div>
                </label>
              </div>

            </div>
          );
        })}

      </div>
      {showError && error && touched && ( <span className="input-error">{error}</span>)}
    </div>
  );
};

export default ButtonGroup;

import { get } from 'lodash';

export function errorResponseToArray(res) {
  const errors = get(res.response, 'data.errors', []);
  const errorArray = [];

  errors.map((error) => {
    if (errorArray.indexOf(error.detail) === -1) {
      errorArray.push(error.detail);
    }
  });

  return errorArray;
}

export default errorResponseToArray;

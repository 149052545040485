import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { TableHeaderColumn } from 'react-bootstrap-table';
import {
  InfoLabel,
  Box,
  Button,
  DataTable,
  DatePickerDob,
  SelectScheme,
  SelectInsurer,
  HorizontalFormControl,
  Modal,
} from '../../../../common/components';
import { refreshData } from '../../../../common/components/DataTable/redux/dataTableActions';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import { updateDocument } from '../../redux/documentActions';
import ContentShow from '../../../content/components/ContentShow/ContentShow';
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux';
import { clearFiles, removeFile } from '../../../../common/components/Upload/UploadRedux';
import UploadModalComponent, {
  UploadModal,
} from '../../../../common/components/Upload/UploadModal';
import { FormattedDate, FormattedTime } from 'react-intl';
import { get, set } from 'lodash';

const FORM_NAME = 'documentForm';

const validate = (values) => {
  const errors = {};

  if (get(values, 'data.relationships.schemes.data', []).length === 0) {
    set(
      errors,
      'data.relationships.schemes.data',
      'At least one scheme must be selected',
    );
  }

  if (!get(values, 'data.attributes.name', false)) {
    set(errors, 'data.attributes.name', 'Name is required');
  }

  if (!get(values, 'data.attributes.from_date', false)) {
    set(errors, 'data.attributes.from_date', 'Date is required');
  }

  return errors;
};

class DocumentDetails extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      documentId: null,
      insurerId: null,
    };
  }

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded } = this.props;

    if (newProps.submitSucceeded && !submitSucceeded) {
      dispatch(refreshData('documents_versions'));
      dispatch(closeModal(FORM_NAME));
    }
  }

  handleSubmit(values) {
    return this.props.dispatch(updateDocument(values, this.props.product));
  }

  resetState() {
    const { actions } = this.props;
    actions.clearFiles();
  }

  handleUpload() {
    const { actions } = this.props;
    actions.openModal(UploadModal.MODAL_IDENTIFIER);
    this.resetState();
  }

  handleUploadComplete() {
    const { dispatch, change, files } = this.props;

    dispatch(change('data.relationships.file.data', files[0]));
    dispatch(change('data.attributes.file_name', files[0].attributes.name));
    dispatch(change('data.attributes.file_url', files[0].attributes.url));
  }

  handleButtonClick(document) {
    const { dispatch, reset, change } = this.props;
    const type = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'document_type',
    );
    dispatch(reset());

    if (document) {
      dispatch(change('data.id', document.id));
      dispatch(change('data.attributes.name', document.name));
      dispatch(change('data.attributes.from_date', document.from_date));
      dispatch(change('data.attributes.file_name', document.name));
      dispatch(change('data.attributes.file_url', document.file_path));
      dispatch(
        change(
          'data.relationships.insurer',
          document.relationships ? document.relationships.insurer : {},
        ),
      );
      dispatch(
        change(
          'data.relationships.schemes',
          document.relationships ? document.relationships.schemes : {},
        ),
      );
      this.setState({ insurerId: get(document, 'relationships.insurer.data.id') });
      this.setState({ documentId: document.id });
    } else {
      this.setState({ insurerId: null });
      this.setState({ documentId: null });
    }

    dispatch(change('data.relationships.document_type', type));
    dispatch(openModal(FORM_NAME));
  }

  handleRowClick(row) {
    this.handleButtonClick(row);
  }

  formatDate(cell) {
    if (!cell) {
      return '-';
    }

    return (
      <span>
        <FormattedDate value={new Date(cell)} /> <FormattedTime value={new Date(cell)} />
      </span>
    );
  }

  render() {
    const { submitting, handleSubmit, formValues } = this.props;
    const resource = this.props.resource.data.attributes;
    const product = this.props.product;
    const template = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'document_template',
    );
    const content = getIncludedResource(
      template.data,
      this.props.resource.included,
      'latest_content',
    );
    const type = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'document_type',
    );

    const title = 'Document Management';
    const submitButton = (
      <div>
        <Button
          type="button"
          bsStyle="primary"
          className="pad-right"
          disabled={submitting}
          handleClick={::this.handleUpload}
        >
          {submitting ? (
            <i className="fa fa-cog fa-spin" />
          ) : (
            <i className="fa fa-file" />
          )}{' '}
          upload document
        </Button>
        <Button
          type="submit"
          bsStyle="primary"
          isLoading={submitting}
          label="Save Changes"
        />
      </div>
    );

    let schemeFilters = [
      { filter: 'filter[product]', value: product.data.id },
      { filter: 'filter[status]', value: 'all' },
    ];

    if (this.state.insurerId) {
      schemeFilters = [
        ...schemeFilters,
        { filter: 'filter[insurer]', value: this.state.insurerId },
      ];
    }

    return (
      <Box>
        <h2 className="resource-name">{resource.name}</h2>

        {!content.data.attributes && (
          <div>
            <p>Tips</p>
            <ul>
              <li>
                Version Documents: select the relevant document from the table below
              </li>
            </ul>
          </div>
        )}

        {content.data.attributes ? (
          <div className="row">
            <div className="row">
              <ContentShow resource={content} />
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-xs-12">
              <DataTable
                source="/documents"
                name="documents_versions"
                showTotal
                autoFilterType="include=schemes,insurer&filter[product]"
                autoFilters={[
                  {
                    type: 'include=schemes,insurer&filter[product]',
                    value: product.data.id,
                  },
                  { type: 'filter[document_type]', value: type.data.id },
                ]}
                onRowSelect={::this.handleRowClick}
              >
                <TableHeaderColumn dataField="id" isKey hidden />
                <TableHeaderColumn dataField="name" width={'330px'} dataSort>
                  Name
                </TableHeaderColumn>
                <TableHeaderColumn dataField="version_number" width={'110px'} dataSort>
                  Version
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="from_date"
                  width={'170px'}
                  dataSort
                  dataFormat={::this.formatDate}
                >
                  Start date
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="to_date"
                  width={'170px'}
                  dataSort
                  dataFormat={::this.formatDate}
                >
                  End date
                </TableHeaderColumn>
              </DataTable>
            </div>
          </div>
        )}

        <Modal
          name={FORM_NAME}
          title={title}
          close
          handleSubmit={handleSubmit(::this.handleSubmit)}
          footer={submitButton}
        >
          <div className="row form-horizontal edit-document">
            <div className="col-xs-12">
              <InfoLabel
                label="Type"
                value={get(
                  formValues,
                  'data.relationships.document_type.data.attributes.name',
                )}
                labelSize={3}
              />
              <Field
                name="data.attributes.name"
                label="Name"
                labelSize={3}
                component={HorizontalFormControl}
              />
              <Field
                name="data.attributes.from_date"
                label="Effective date"
                labelSize={3}
                component={DatePickerDob}
              />
              {this.state.documentId ? (
                <InfoLabel label="Insurer" labelSize={3} value={this.state.insurerId} />
              ) : (
                <Field
                  name="data.relationships.insurer.data.id"
                  label="Insurer"
                  labelSize={3}
                  component={SelectInsurer}
                  change={() =>
                    this.setState({
                      insurerId: get(formValues, 'data.relationships.insurer.data.id'),
                    })
                  }
                />
              )}
              <Field
                name="data.relationships.schemes.data"
                label="Schemes"
                isMulti
                object
                filters={schemeFilters}
                labelFromToDates
                labelSize={3}
                component={SelectScheme}
              />
            </div>

            {get(formValues, 'data.attributes.file_url') && (
              <div>
                <div className="col-sm-3">
                  <label className="control-label">File</label>
                </div>
                <div className="col-sm-9">
                  <a
                    href={get(formValues, 'data.attributes.file_url')}
                    target="_blank"
                    className="btn btn-default btn-sm"
                  >
                    {get(formValues, 'data.attributes.file_name')}
                  </a>
                </div>
              </div>
            )}
          </div>
        </Modal>

        <UploadModalComponent
          onComplete={::this.handleUploadComplete}
          accept="application/pdf"
          maxFiles={1}
        />
      </Box>
    );
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(DocumentDetails);

const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state) => {
  const values = selector(
    state,
    'data.id',
    'data.relationships.document_type',
    'data.attributes.file_name',
    'data.attributes.file_url',
  );

  return {
    formValues: values,
    files: state.upload.files,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ clearFiles, removeFile, openModal }, dispatch),
    dispatch,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(form);

import React from 'react';
import { Col } from 'react-bootstrap';
import { Field } from 'redux-form';
import parseFloatValue from '../../../../../helpers/parseFloatValue';
import {
  HorizontalFormControl,
  Icon,
  InfoLabel,
} from '../../../../../common/components';
import SelectField from '../../../../../common/components/ReduxFormField/SelectField';
import { numericality } from 'redux-form-validators';
import { AutoIncludeField, ViewAutoInclude } from './AutoIncludeField/AutoIncludeField';

const OptionSingleRate = ({ input, isViewOnly, coverLevels }) => {
  const rateType = input.value.rate_type;
  const name = input.name;

  return (
    <>
      <Col sm={5}>
        <Field
          name={`${name}.rate`}
          parse={parseFloatValue}
          normalize={parseFloatValue}
          validate={[numericality({ message: 'Must be a number', allowBlank: true })]}
          label={'Rate:'}
          labelSize={4}
          fieldSize={4}
          component={isViewOnly ? InfoLabel : HorizontalFormControl}
          disabled={isViewOnly}
          addonBefore={
            !isViewOnly && rateType === 'fixed' ? <Icon name={'pound-sign'}/> : undefined
          }
          addonAfter={
            !isViewOnly && rateType === 'loading' ? <Icon name={'percent'}/> : undefined
          }
        />
      </Col>
      <Col sm={3}>
        <Field
          name={`${name}.rate_type`}
          label={'Rate Type:'}
          fieldSize={8}
          labelSize={4}
          component={isViewOnly ? InfoLabel : SelectField}
          isDisabled={isViewOnly}
          //onChange={ this.handleChange }
          options={[
            { value: 'loading', label: 'Loading' },
            { value: 'fixed', label: 'Fixed' },
          ]}
        />
      </Col>
      <Col sm={3}>
        <Field
          name={`${name}.auto_include`}
          label={'Auto-include:'}
          labelSize={4}
          fieldSize={8}
          noFormGroup
          component={isViewOnly ? ViewAutoInclude : AutoIncludeField}
          coverLevels={coverLevels}
          options={[{ label: '', value: true }]}
        />
      </Col>
    </>
  );
};
export default OptionSingleRate;

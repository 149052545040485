export const ADD_RESOURCE = 'socrates-online/breadcrumb/ADD_RESOURCE';

const initialState = {
  resources: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_RESOURCE:
      let resourceList = state.resources;
      resourceList[action.id] = action.name;
      return {
        ...state,
        resources: resourceList,
      };
    default:
      return state;
  }
}

export function addResource(id, name) {
  let resource = {};
  resource[id] = name;
  return (dispatch) => {
    dispatch({
      type: ADD_RESOURCE,
      id: id,
      name: name,
    });
  };
}

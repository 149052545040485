import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux';
import { Button, Modal } from '../../../../common/components';
import { UserContext } from '../../context';
import { Row, Col } from 'react-bootstrap';

const MODAL_NAME = 'RemoveUserOrgButton';

class RemoveUserOrgButton extends Component {
  handleOpen = () => {
    const { dispatch } = this.props;
    dispatch(openModal(MODAL_NAME));
  };

  handleRemove = () => {
    const { context } = this;
    const { dispatch } = this.props;
    context.removeUserFromOrganisation({
      userId: this.props.userId,
      orgId: this.props.organisationId,
    });
    dispatch(closeModal(MODAL_NAME));
  };

  render() {
    const { username, organisationName } = this.props;
    const modalFooter = (
      <div style={{ margin: '20px' }}>
        <Button
          bsStyle="danger"
          className="pull-right"
          rightIcon="warning"
          handleClick={this.handleRemove}
          label="Remove User"
        />
      </div>
    );

    return (
      <div>
        <Button
          className="pull-right"
          {...this.props}
          bsStyle="danger"
          label="Remove User"
          handleClick={this.handleOpen}
        />

        <Modal
          name={'RemoveUserOrgButton'}
          title="Remove User From Organisation"
          close
          footer={modalFooter}
        >
          <Row>
            <Col xs={12}>
              {`Are you sure you want to remove ${username} from ${organisationName}`}?
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

RemoveUserOrgButton.contextType = UserContext;

RemoveUserOrgButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
  organisationId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

export default RemoveUserOrgButton;

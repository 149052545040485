import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CampaignEditForm from '../components/CampaignEditForm/CampaignEditForm';
import { updateCampaign } from '../redux/campaignActions';

export default class CampaignFormContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    //organisation: PropTypes.object.isRequired,
    resource: PropTypes.object,
  };

  handleSubmit(resource) {
    if (resource.data.id) {
      return this.props.dispatch(updateCampaign(resource));
    }
  }

  render() {
    return <CampaignEditForm {...this.props} onSubmit={::this.handleSubmit} />;
  }
}

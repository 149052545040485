import React, { Component } from 'react';
import Select from 'react-select';
import { FormGroup, Col, ControlLabel } from 'react-bootstrap';
import selectTheme, { styles } from '../../../helpers/selectTheme';

class SelectField extends Component {
  getValue = (value) => {
    const { isMulti, options } = this.props;
    if (isMulti) {
      return options.reduce((carry, option) => {
        if (value.indexOf(option.value) >= 0) {
          carry.push(option);
          return carry;
        }
        return carry;
      }, []);
    } else {
      return options.find((opt) => {
        return opt.value === value;
      });
    }
  };

  handleOnSelectFieldChange(option) {
    const { input, isMulti } = this.props;
    if (isMulti) {
      return input.onChange(option.map((o) => o.value));
    } else {
      input.onChange(option ? option.value : '');
    }
  }

  render() {
    const {
      id = 'selectField',
      input,
      label,
      infoButton,
      labelSize,
      smFieldSize,
      meta,
      infoButtonBefore,
      errorMessage,
      showError,
      placeholder,
    } = this.props;

    const showErrorMsg = showError ? showError : meta && meta.touched && meta.error;
    const error = errorMessage
      ? errorMessage
      : meta && meta.error
      ? meta.error
      : 'an error has occurred';

    const labelSmSize = labelSize ? labelSize : 2;
    const menuSize = smFieldSize ? smFieldSize : !label ? 12 : 12 - labelSize;
    const inputValue = input.value.value ? input.value.value : input.value;
    const placeholderText = placeholder ? placeholder : 'Please Select...';

    const renderSelectField = () => {
      return (
        <div>
          <Select
            styles={styles}
            theme={(theme) => selectTheme(theme)}
            id={id}
            onBlur={() => (input.onBlur ? input.onBlur(input.value) : '')}
            value={this.getValue(inputValue)}
            onChange={(option) => this.handleOnSelectFieldChange(option)}
            placeholder={placeholderText}
            {...this.props}
          />

          {showErrorMsg && <span className="input-error">{error}</span>}
        </div>
      );
    };

    if (label) {
      return (
        <FormGroup
          className={
            meta && meta.touched && meta.error ? 'react-select-error has-error' : ''
          }
        >
          {label && (
            <Col sm={labelSmSize} className="select-field-label">
              <ControlLabel>
                {infoButtonBefore}
                {label} {infoButton}
              </ControlLabel>
            </Col>
          )}
          <Col sm={menuSize}>{renderSelectField()}</Col>
        </FormGroup>
      );
    }
    return renderSelectField();
  }
}

export default SelectField;

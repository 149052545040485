import React from 'react';
import { getFormError, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Sticky, StickyContainer } from 'react-sticky';
import { hasPermission, } from '../../../../../auth/redux/authActions';
import { Button, } from '../../../../../../common/components/';
import CommissionAdjust from '../../../CommissionAdjust/CommissionAdjust';
import { FORM_NAME } from '../../WhitelabelTravelForm';
import { Col } from 'react-bootstrap';
import QuoteSaveAndExit from './components/QuoteSaveAndExit';
import { SidebarSection } from './components/SidebarSection';
import { FormErrors } from './components/FormErrors';
import { Referrals } from './components/Referrals';
import { Campaign } from './components/Campaign';
import {
  getCoverDates,
  getDestinations,
  getDisplayName,
  getMaxTripDuration,
  getOptions
} from './helpers';
import { TravellerInfo } from './components/TravellerInfo';
import { SchemeSummary } from './components/SchemeSummary';
import { Premium } from './components/Premium';
import { Premiums } from './components/Premiums';
import Upsell from './components/Upsell';

const Sidebar = ({
  selectedScheme,
  isMultipleSchemeSelected,
  isMta,
  productName,
  productId,
  formErrors,
  submitFailed,
  selectOptions,
  productOptions,
  premium,
  quotes,
  selectedSchemeIds,
  isCalculating,
  isSaving,
  formValues,
  onSave,
  referrals,
  isReferralRequired,
  showSaveButtons,
  showUpsell,
  definitions,
  rules,
  onApplyOverride,
  onConfirmUpsell,
  premiumOverrideCalculation,
  showBrokerPremiumAdjust
}) => {
  const { metadata: formMetadata } = formValues?.data.attributes;
  const hasReferrals = referrals?.reasons?.length;

  const isUpSell = selectedScheme?.meta?.scheme?.quote_type === 'upsell';

  const showUpsellForm = showUpsell && isUpSell && formMetadata?.scheme_type === 'single';

  return (
    <div>
      <StickyContainer className="calculate-quote-sidebar box">
        <Sticky>
          {() => (
            <>
              <div className="col-xs-12">
                <h4>Quote Summary</h4>
              </div>
              <div className="row sticky-inner-container">
                {showUpsellForm && (
                  <Upsell
                    regions={selectOptions?.regions}
                    key={selectedScheme?.meta?.scheme?.id}
                    maxTripDurations={selectOptions?.maxTripDurations}
                    onConfirmUpsell={onConfirmUpsell}
                    definitions={definitions}
                    maxTripDuration={selectedScheme?.meta?.information?.max_trip_duration}
                    region={selectedScheme?.meta?.information?.region}
                    startDate={formMetadata?.start_date}
                    rules={rules}
                  />)}

                <SidebarSection
                  label={'Policy Number:'}
                  value={formValues?.data?.attributes?.policy_number}
                />

                <SidebarSection
                  label={'Reference:'}
                  value={formValues?.data?.attributes?.quote_reference}
                />


                {isMultipleSchemeSelected && (
                  <Col sm={12} className="quote-divider">
                    <div className="error-box text-center">
                      <span>Please select 1 scheme to proceed</span>
                    </div>
                  </Col>
                )}

                <FormErrors show={submitFailed} formErrors={formErrors}/>
                <Referrals
                  show={!submitFailed && hasPermission('policy.handle_all_referrals')}
                  referrals={formMetadata?.referrals ?? []}
                />
                <SidebarSection
                  label={'Product:'}
                  value={productName}
                />
                <Campaign
                  show={!isMta}
                  brokerId={formValues?.data?.relationships?.broker?.data?.id}
                  productId={formValues?.data?.relationships?.broker?.data?.id}
                  //    channelKey={formValues?.data?.relationships?.chan?.data?.id}
                />

                <SidebarSection
                  label={'Type of cover:'}
                  value={getDisplayName(selectOptions?.tripTypes, formMetadata?.scheme_type)}
                />

                <SidebarSection
                  label={'Max trip duration:'}
                  value={getMaxTripDuration(formMetadata?.max_trip_duration, selectedScheme?.meta?.information?.max_trip_duration)}
                />

                <SidebarSection
                  label={'Dates of cover:'}
                  value={getCoverDates(formMetadata?.start_date, formMetadata?.end_date)}
                />

                <SidebarSection
                  label={'Destinations:'}
                  value={getDestinations(formMetadata?.destinations, formMetadata?.region, selectOptions?.regions)}
                />


                <SidebarSection
                  label={'Additional cover:'}
                  value={getOptions(formMetadata?.options, productOptions)}
                />


                <TravellerInfo
                  travellerGroup={formMetadata?.traveller_group}
                  adults={formMetadata?.adults}
                  children={formMetadata?.children}
                  selectOptions={formMetadata.scheme_type === 'annual' ? selectOptions?.annualGroupTypes : selectOptions?.singleGroupTypes}
                />


                <SchemeSummary premium={premium} isMta={isMta} isSaving={isSaving}/>

                <Premium
                  premium={premiumOverrideCalculation ? premiumOverrideCalculation : premium}
                  isCalculating={isCalculating}
                />
                <Premiums
                  show={!premium} selectedSchemeIds={selectedSchemeIds}
                  quotes={quotes} isCalculating={isCalculating}/>


                {showBrokerPremiumAdjust && (
                  <CommissionAdjust
                    // name="data.attributes.metadata.overrides.commission_broker"
                    productId={productId}
                    onApplyOverride={onApplyOverride}

                  />
                )}


                {showSaveButtons && (
                  <>
                    <Col sm={12}>
                      <div className="save-quote-btn-sidebar">
                        <QuoteSaveAndExit
                          hasReferrals={hasReferrals}
                          selectedScheme={selectedScheme}
                          isReferralRequired={isReferralRequired}
                          application={formValues}
                          productId={productId}
                        />
                        <Button
                          label={'Save'}
                          bsStyle={'primary'}
                          block
                          isLoading={isSaving}
                          handleClick={onSave}
                        />
                      </div>

                    </Col>
                  </>
                )}
              </div>
            </>
          )}
        </Sticky>
      </StickyContainer>
    </div>
  );

};
export default connect(
  (state) => {
    return {
      formValues: getFormValues(FORM_NAME)(state),
      formErrors: getFormError(FORM_NAME)(state),
      destroyOnUnmount: false,
      forceUnregisterOnUnmount: true,

    };
  })(reduxForm({
  form: FORM_NAME
})(Sidebar));

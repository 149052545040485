import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { passwordReminder } from '../redux/authActions';
import PasswordReminderForm from '../components/PasswordReminderForm/PasswordReminderForm';

class PasswordReminderContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  handleSubmit(values) {
    return this.props.dispatch(passwordReminder(values.email));
  }

  render() {
    return (
      <div>
        <div className="guest-title">Welcome to the P J Hayman Insurance Portal</div>
        <div className="login-margin-top">
          <PasswordReminderForm onSubmit={this.handleSubmit.bind(this)} />
        </div>
      </div>
    );
  }
}

export default connect()(PasswordReminderContainer);

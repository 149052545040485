import React from 'react';

const Checkbox = ({
  input,
  label,
  checkboxValue,
  showErrorLabel,
  inline,
  meta: { touched, error },
}) => (
  <div className={touched && error ? ' has-error' : ''}>
    <div className={'radio-container' + (inline ? ' inline' : '')}>
      <div className={'form-radio'}>
        <input
          {...input}
          type="checkbox"
          id={input.name + checkboxValue}
          value={checkboxValue}
          checked={input.value === checkboxValue}
        />
        <label htmlFor={input.name + checkboxValue} />
      </div>
      <div
        className="radio-label"
        onClick={() =>
          input.onChange(input.value === checkboxValue ? null : checkboxValue)
        }
      >
        {label}
      </div>
    </div>

    {showErrorLabel && touched && error && <span className="input-error">{error}</span>}
  </div>
);

export default Checkbox;

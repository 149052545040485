import React, { Component } from 'react';
import { Link } from 'react-router';
import Markdown from 'react-markdown';
import { Row, Col } from 'react-bootstrap';
import { get, map, isEmpty, orderBy, size } from 'lodash';
import getStatus from '../../../../../../schemas/policy';
import { InfoLabel } from '../../../../../../common/components';
import { isOwner } from '../../../../../auth/redux/authActions';

export default class History extends Component {
  getStatus(status) {
    const { resource } = this.props;

    if (resource.quote_type === 'mid term adjustment' && status === 'quote') {
      return 'awaiting approval';
    }

    return getStatus(status);
  }

  render() {
    const {
      resource,
      applications,
      mtas,
      policy,
      broker,
      source,
      user,
      quotesHistory,
    } = this.props;
    const originalQuoteUser = get(resource, 'metadata.storage.quote_originator');
    const quoteHistoryOrdered = orderBy(quotesHistory, 'attributes.version', 'desc');

    const displayVersionsCheck = () => {
      if (resource.policy_number || isEmpty(quotesHistory)) {
        return false;
      }

      return !(resource.status === 'referral approved' && !isOwner());
    };

    return (
      <div>
        <Row>
          <Col sm={12} className="section-title">
            <h4>History</h4>
          </Col>
          <Col sm={12}>
            {applications.length > 0 && (
              <div className="form-horizontal">
                <InfoLabel
                  labelSize={2}
                  label="Quotes"
                  value={applications.map((quote, i) => {
                    return (
                      <span key={i}>
                        <Link to={'/policies/applications/' + quote.id}>
                          {quote.attributes.quote_reference}{' '}
                        </Link>
                      </span>
                    );
                  })}
                />
              </div>
            )}
            {mtas.length > 0 && (
              <div className="form-horizontal">
                <Row>
                  <Col sm={12}>
                    <InfoLabel
                      labelSize={2}
                      label="Mid Term Adjustments"
                      value={mtas.map((mta, i) => {
                        return (
                          <span key={i}>
                            <Link to={'/policies/applications/' + mta.id}>
                              {mta.attributes.quote_reference}
                              {', '}
                            </Link>
                          </span>
                        );
                      })}
                    />
                  </Col>
                </Row>
              </div>
            )}
            {get(policy, 'data.attributes') && (
              <div className="form-horizontal">
                <Row>
                  <Col sm={12}>
                    <InfoLabel
                      labelSize={2}
                      label="Policy"
                      value={
                        <Link to={'/policies/issued/' + policy.data.id}>
                          {policy.data.attributes.policy_number +
                            ' (' +
                            this.getStatus(policy.data.attributes.status) +
                            ')'}
                        </Link>
                      }
                    />
                  </Col>
                </Row>
              </div>
            )}

            {resource.quote_type === 'mid term adjustment' && (
              <div className="form-horizontal">
                <InfoLabel
                  labelSize={2}
                  label="Mid Term Adjustment"
                  value={
                    <Markdown
                      source={get(resource, 'metadata.endorsements[0].content', '')}
                    />
                  }
                />
              </div>
            )}
            <div className="form-horizontal">
              <Row>
                <Col sm={6}>
                  <InfoLabel
                    label="Broker"
                    value={
                      <Link to={'/brokers/' + get(broker, 'data.id', '')}>
                        {get(broker, 'data.attributes.name', '')}
                      </Link>
                    }
                    labelSize={4}
                  />
                </Col>
                <Col sm={6}>
                  <InfoLabel
                    label="User"
                    value={
                      <Link to={'/admin/users/' + get(user, 'data.id', '')}>
                        {get(user, 'data.attributes.first_name', '') +
                          ' ' +
                          get(user, 'data.attributes.last_name', '')}
                      </Link>
                    }
                    labelSize={4}
                  />
                </Col>
              </Row>
            </div>
            {get(source, 'data.attributes.name') && (
              <div className="form-horizontal">
                <Row>
                  <Col sm={12}>
                    <InfoLabel
                      label="Source"
                      value={get(source, 'data.attributes.name', '')}
                      labelSize={2}
                    />
                  </Col>
                  {/*if originator set in metadata and user has permission to see users */}
                  {get(resource, 'metadata.storage.quote_originator', false) &&
                    size(user.data) > 0 && (
                      <Col sm={6}>
                        <InfoLabel
                          label="Originating User"
                          value={
                            get(originalQuoteUser, 'first_name', '') +
                            ' ' +
                            get(originalQuoteUser, 'last_name', '')
                          }
                          labelSize={4}
                        />
                      </Col>
                    )}
                </Row>
              </div>
            )}
            {displayVersionsCheck() && (
              <Row>
                <Col sm={12}>
                  <div className="form-horizontal">
                    <InfoLabel
                      labelSize={2}
                      label="Versions"
                      value={map(quoteHistoryOrdered, (quotes, index) => {
                        return (
                          <Link
                            key={index}
                            to={`/policies/applications/${quotes.id}`}
                            style={{ paddingRight: '10px' }}
                          >
                            {`${quotes.attributes.quote_reference}/${
                              quotes.attributes.version
                            }`}
                          </Link>
                        );
                      })}
                    />
                  </div>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <div className="hr" />
      </div>
    );
  }
}

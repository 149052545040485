import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { actions } from '../redux/ProductReducer';
import { Box, Loader } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import BrochureContentForm from '../components/BrochureContent/BrochureContentForm';
import ProductName
  from '../components/ProductFrontPage/components/ProductName/ProductName';
import ProductNavigation from '../components/ProductNavigation/ProductNavigation';

const INCLUDES = ['all_scheme_groups', 'contents'];
const BrochureContentContainer = ({ params, onGetProduct, onUpdateProduct }) => {
  const [showForm, setShowForm] = useState(false);


  const { productId } = params;
  const {
    product,
    isLoading
  } = useSelector((state) => state.productManagement);

  const currentProductId = product?.data?.id;

  useEffect(
    () => {
      if (!currentProductId || currentProductId !== productId) {
        onGetProduct(productId, INCLUDES);
      }
    },
    [productId, currentProductId, onGetProduct],
  );


  const handleToggleForm = () => {
    setShowForm(!showForm);

  };

  const save = (values) => {
    return Promise.resolve(onUpdateProduct(productId, values)).then(() => {
      setShowForm(false);
    });

  };


  if (isLoading || !product) {
    return (
      <div className="admin-area">
        <InternalContainer title="Product management">
          <Loader/>
        </InternalContainer>
      </div>
    );
  }

  const { icon, name, status, metadata } = product?.data?.attributes ?? {};
  const { is_wl_product: isWhitelabel } = metadata ?? {};


  return (
    <InternalContainer title={'Product Management'}>
      <ProductName
        title={'Brochure Content'}
        name={name}
        icon={icon}
        status={status}
      >
        <ProductNavigation
          id={productId}
          currentLocation={location.pathname}
          isWhitelabel={isWhitelabel}
        />

      </ProductName>
      <Box>
        <BrochureContentForm
          showForm={showForm}
          isLoading={isLoading}
          onSave={save}
          resource={product}
          onToggleForm={handleToggleForm}
        />
      </Box>
    </InternalContainer>
  );
};


export default connect(
  null,
  {
    onGetProduct: actions.getProduct,
    onUpdateProduct: actions.updateProduct
  }
)(BrochureContentContainer);


import React, { Component } from 'react'
import { connect } from 'react-redux';
import InternalContainer from '../../layout/containers/Internal';
import SystemHealthList from '../components/SystemHealthList';

class SystemHealthContainer extends Component {

  render() {
    return (
      <InternalContainer title="Admin / System Health">
        <SystemHealthList {...this.props}/>
      </InternalContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(SystemHealthContainer);

import React, { Component } from 'react';
import './styles.scss';
import { get, set, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import PostcodeList from '../../../../../../common/components/Postcode/PostcodeList';
import {
  AddressFields,
  CheckboxUnlabeled,
} from '../../../../../../common/components/ReduxFormField';
import {
  Box,
  HorizontalFormControl,
  InfoLabel,
  SelectOrganisation,
} from '../../../../../../common/components';
import { Col, Row } from 'react-bootstrap';
import { clearSelection as clearAddressFields } from '../../../../../../common/components/Postcode/redux/PostcodeActions';
import InfoButton from '../../../../../../common/components/InfoButton/InfoButton';
import SelectBrokerRegion from '../../../../../../common/components/Selects/SelectBrokerRegion';

const ATTRIBUTES = 'data.attributes';
const RELATIONSHIPS = 'data.relationships';
const EXISTING_ORGANISATION = `${RELATIONSHIPS}.organisation.data`;
const NEW_ORGANISATION = `${ATTRIBUTES}.organisation`;

export class General extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(clearAddressFields());
  }

  organisationPopulate(organisationData) {
    const { dispatch, change } = this.props;

    if (organisationData) {
      dispatch(change(`${NEW_ORGANISATION}`, organisationData));
      return;
    }

    dispatch(change(`${NEW_ORGANISATION}`, null));
  }

  toggleExistingOrganisation(e) {
    const { dispatch, change, formValues } = this.props;
    if (!e.currentTarget.checked) {
      dispatch(change(formValues, `${EXISTING_ORGANISATION}`, null));
      this.organisationPopulate(null);
    }
  }

  componentWillReceiveProps(nextProps) {
    // Selecting the organisation if applicable
    const { dispatch, formValues, change, brokerType, productId } = this.props;
    const isTrackingLink = brokerType === 'tracking';

    if (
      get(nextProps.formValues, `${ATTRIBUTES}.metadata.select_organisation`, false) !==
      true
    ) {
      dispatch(change(`${ATTRIBUTES}.metadata.select_organisation`, false));
      // dispatch(change(`nextProps.formValues.${ATTRIBUTES}.metadata.select_organisation`, false))
    }

    if (
      get(nextProps.formValues, `${EXISTING_ORGANISATION}.id`) !==
      get(formValues, `${EXISTING_ORGANISATION}.id`)
    ) {
      //if organisation has changed update relationship
      const selectOrg = get(nextProps.formValues, `${EXISTING_ORGANISATION}.attributes`);
      if (selectOrg || get(formValues, `${EXISTING_ORGANISATION}.id`)) {
        this.organisationPopulate(selectOrg);
      }
    }

    // duplicate organisation address to the broker address
    if (get(nextProps.formValues, 'data.attributes.metadata.same_address')
      && get(nextProps.formValues, `${NEW_ORGANISATION}.address`) !== get(formValues, `${NEW_ORGANISATION}.address`)
    ) {
      dispatch(
        change(
          `${ATTRIBUTES}.address`,
          get(nextProps.formValues, `${NEW_ORGANISATION}.address`),
        ),
      );
    } else if (
      get(nextProps.formValues, 'data.attributes.metadata.same_address') !==
      get(formValues, 'data.attributes.metadata.same_address')
    ) {
      if (get(nextProps.formValues, 'data.attributes.metadata.same_address')) {
        dispatch(
          change(
            `${ATTRIBUTES}.address`,
            get(nextProps.formValues, `${NEW_ORGANISATION}.address`),
          ),
        );
      } else {
        dispatch(change(`${ATTRIBUTES}.address`, ''));
      }
    }

    if (
      isTrackingLink &&
      get(nextProps.formValues, `${NEW_ORGANISATION}.address`) !==
      get(formValues, `${NEW_ORGANISATION}.address`)
    ) {
      dispatch(
        change(
          `${ATTRIBUTES}.address`,
          get(nextProps.formValues, `${NEW_ORGANISATION}.address`),
        ),
      );
    }

    if (!get(nextProps.formValues, 'data.attributes.metadata.productId', false)) {
      dispatch(change('data.attributes.metadata.productId', productId));
    }
  }

  render() {
    const { formValues, brokerType, auth, form } = this.props;
    const selectOrganisation = get(
      formValues,
      'data.attributes.metadata.select_organisation',
    );
    const duplicateAddress = !!get(formValues, 'data.attributes.metadata.same_address');
    const isTrackingLink = brokerType === 'tracking';

    return (
      <Box>
        <Row>
          <Col sm={12}>
            <h4 className="page-title">General Information</h4>
            <hr/>
          </Col>
          <Col sm={6}>
            <Field
              name={`${ATTRIBUTES}.trading_name`}
              mdFieldSize={8}
              component={HorizontalFormControl}
              labelSize={4}
              infoButton={
                <InfoButton
                  content={'Trading name if different to Organisation Name'}
                />
              }
              label={isTrackingLink ? 'Name' : 'Trading As'}
            />
            {isTrackingLink ? (
              <Field
                name={`${ATTRIBUTES}.metadata.display_phone_number`}
                mdFieldSize={8}
                component={HorizontalFormControl}
                labelSize={4}
                label="Display Phone"
              />
            ) : (
              <Field
                name={`${RELATIONSHIPS}.broker_region.data`}
                mdFieldSize={8}
                component={SelectBrokerRegion}
                labelSize={4}
                isObject
                label="Region"
              />
            )}
            {auth.authenticated && !isTrackingLink && (
              <Field
                name={`${ATTRIBUTES}.reference`}
                mdFieldSize={8}
                component={HorizontalFormControl}
                labelSize={4}
                object
                label="Reference"
              />
            )}
          </Col>
          <Col sm={6}>
            {/*{!isTrackingLink && (*/}
            {/*<Field*/}
            {/*name={`${ATTRIBUTES}.branch_name`}*/}
            {/*mdFieldSize={8}*/}
            {/*component={HorizontalFormControl}*/}
            {/*labelSize={4}*/}
            {/*label='Branch Name'*/}
            {/*/>*/}
            {/*)}*/}
            {auth.authenticated && (
              <Field
                name={`${ATTRIBUTES}.metadata.generate_url`}
                label={'Generate Url?'}
                component={CheckboxUnlabeled}
                inline
                labelSize={11}
                mdFieldSize={1}
                options={[{ label: '', value: true }]}
              />
            )}
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <hr/>
          </Col>
        </Row>
        {auth.authenticated && (
          <Row>
            <Col sm={6}>
              <Field
                name={`${ATTRIBUTES}.metadata.select_organisation`}
                label={
                  isTrackingLink
                    ? 'Is the tracking link for an existing organisation?'
                    : 'Is the broker a branch of an existing organisation?'
                }
                component={CheckboxUnlabeled}
                inline
                labelSize={11}
                mdFieldSize={1}
                onChange={(e) => this.toggleExistingOrganisation(e)}
                options={[{ label: '', value: true }]}
              />
            </Col>
          </Row>
        )}
        {auth.authenticated && (
          <Row>
            <Col sm={6}>
              <div className="adjust-organisation-select">
                {selectOrganisation && (
                  <Field
                    name={`${RELATIONSHIPS}.organisation.data`}
                    mdFieldSize={8}
                    component={SelectOrganisation}
                    include={'legal_entity_type'}
                    filters={[{ filter: 'filter[type]', value: 'Broker' }]}
                    labelSize={4}
                    object
                    label="Organisation"
                    className={selectOrganisation ? 'organisation-section' : ''}
                  />
                )}
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col sm={6}>
            {!auth.authenticated || !selectOrganisation ? (
              <Field
                name={`${NEW_ORGANISATION}.name`}
                mdFieldSize={8}
                component={HorizontalFormControl}
                labelSize={4}
                label="Organisation Name"
                disabled={selectOrganisation}
              />
            ) : (
              <Field
                name={`${ATTRIBUTES}.branch_name`}
                mdFieldSize={8}
                component={HorizontalFormControl}
                labelSize={4}
                label="Branch Name"
              />
            )}
            <Field
              name={`${NEW_ORGANISATION}.phone1`}
              labelSize={4}
              mdFieldSize={8}
              component={HorizontalFormControl}
              label="Contact Number 1"
              disabled={selectOrganisation}
            />
          </Col>
          <Col sm={6}>
            <Field
              name={`${NEW_ORGANISATION}.metadata.website`}
              labelSize={4}
              mdFieldSize={8}
              component={HorizontalFormControl}
              label="Website"
              disabled={selectOrganisation}
            />

            {!isTrackingLink && (
              <Field
                name={`${NEW_ORGANISATION}.fax`}
                labelSize={4}
                mdFieldSize={8}
                component={HorizontalFormControl}
                label="Fax"
                disabled={selectOrganisation}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <span className="registered-title">
              <Field
                name={'test'}
                label={'Registered Address'}
                labelSize={4}
                component={InfoLabel}
              />
            </span>
          </Col>
          <Col sm={6}>
            <Field
              name={'data.attributes.metadata.same_address'}
              label="Is the Trading Address the same as the registered address?"
              labelSize={11}
              component={CheckboxUnlabeled}
              inline
              options={[{ label: '', value: true }]}
            />
          </Col>
          <Col sm={6}>
            <div className="adjust-postcode">
              <div className="adjust-postcode-field">
                <PostcodeList
                  id={'p1'}
                  key={'postcode1'}
                  adjustWidth
                  name={`${NEW_ORGANISATION}.address.postcode`}
                  label="Post Code"
                  labelSizes={{ sm: 4, md: 4, lg: 4 }}
                  fieldSizes={{ sm: 8, md: 8, lg: 8 }}
                  searchBtnSizes={{ sm: 4, md: 4, lg: 4 }}
                  postcodeFieldSizes={{ sm: 4, md: 4, lg: 4 }}
                  change={this.props.change}
                  value={get(formValues, `${NEW_ORGANISATION}.address.postcode`, '')}
                  formName={form}
                  targetField={`${NEW_ORGANISATION}.address`}
                  disabled={selectOrganisation}
                />
              </div>
            </div>
            <AddressFields
              baseName={`${NEW_ORGANISATION}.address`}
              label="Address"
              labelSizes={{ sm: 4, md: 4, lg: 4 }}
              fieldSizes={{ sm: 8, md: 8, lg: 8 }}
              disabled={selectOrganisation}
            />
          </Col>

          {isTrackingLink ? (
            <div/>
          ) : (
            <Col sm={6}>
              <div className="adjust-postcode">
                <div className="adjust-postcode-field">
                  <PostcodeList
                    id={'p2'}
                    key={'postcode2'}
                    adjustWidth
                    name={`${ATTRIBUTES}.address.postcode`}
                    label="Post Code"
                    labelSizes={{ sm: 4, md: 4, lg: 4 }}
                    fieldSizes={{ sm: 8, md: 8, lg: 8 }}
                    searchBtnSizes={{ sm: 4, md: 4, lg: 4 }}
                    postcodeFieldSizes={{ sm: 4, md: 4, lg: 4 }}
                    change={this.props.change}
                    context={'branch'}
                    value={get(formValues, `${ATTRIBUTES}.address.postcode`, '')}
                    formName={form}
                    targetField={`${ATTRIBUTES}.address`}
                    disabled={duplicateAddress}
                  />
                </div>
              </div>
              <AddressFields
                baseName={`${ATTRIBUTES}.address`}
                label="Address"
                labelSizes={{ sm: 4, md: 4, lg: 4 }}
                fieldSizes={{ sm: 8, md: 8, lg: 8 }}
                disabled={duplicateAddress}
              />
            </Col>
          )}
        </Row>
      </Box>
    );
  }
}

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    { field: 'data.attributes.organisation.name', name: 'Organisation Name' },
    { field: 'data.attributes.organisation.address.postcode', name: 'Post code' },
    { field: 'data.attributes.organisation.address.line1', name: 'Address' },
  ];

  requiredFields.map((required) => {
    const isFieldEmpty = isEmpty(get(values, required.field));
    if (isFieldEmpty) {
      set(errors, required.field, required.name + ' is required');
    }
  });

  errors._error = errors.data;
  return errors;
};

const FORM_NAME = 'BrokerApplication';
const form = reduxForm({ form: FORM_NAME, validate })(General);
const mapStateToProps = (state, props) => {
  const values = formValueSelector(props.form)(
    state,
    'data',
    'data.attributes.address.postcode',
  );

  return {
    auth: state.auth,
    initialValues: props.resource,
    form: props.form,
    formValues: values,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    organisation: state.organisation,
    product: state.product,
  };
};

export default connect(mapStateToProps)(form);

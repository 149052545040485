import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Button, InfoLabel, Modal, Loader } from '../../../../common/components';
import { getLedgerEntry } from '../../redux/accountActions';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import { openModal } from '../../../../common/components/Modal/ModalRedux';
import { LedgerEntriesModal } from '../LedgerEntriesModal';
import { get } from 'lodash';

class ReceiptModal extends Component {
  static MODAL_IDENTIFIER = 'receipt-modal';

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    receipt: PropTypes.object,
    ledgerEntry: PropTypes.object,
  };

  componentWillUpdate(newProps) {
    const { receipt, dispatch } = this.props;

    if (newProps.receipt !== receipt) {
      dispatch(
        getLedgerEntry(newProps.receipt.id, [
          'account',
          'batch.ledger_entries.premium.policy',
        ]),
      );
    }
  }

  handleOpen() {
    this.props.dispatch(openModal(LedgerEntriesModal.MODAL_IDENTIFIER));
  }

  render() {
    const { receipt, ledgerEntry } = this.props;
    let id = null;
    let attributes = {};
    let account = {
      data: {
        id: '',
      },
    };
    let batch = {};
    let ledgerEntries = {};
    let policies = [];

    if (get(ledgerEntry, 'data.attributes')) {
      id = ledgerEntry.data.id;
      attributes = ledgerEntry.data.attributes;
    }

    if (get(ledgerEntry, 'included')) {
      account = getIncludedResource(ledgerEntry.data, ledgerEntry.included, 'account');
      batch = getIncludedResource(ledgerEntry.data, ledgerEntry.included, 'batch');
      ledgerEntries = getIncludedResource(
        batch.data,
        ledgerEntry.included,
        'ledger_entries',
        [],
      );
      if (ledgerEntries.length > 0) {
        policies = ledgerEntries.map((matchedLedgerEntry) => {
          const premium = getIncludedResource(
            matchedLedgerEntry,
            ledgerEntry.included,
            'premium',
          );
          return getIncludedResource(premium.data, ledgerEntry.included, 'policy');
        });
      }
    }

    const modalFooter = (
      <div>
        {get(account, 'data') && (
          <Button
            type="button"
            bsStyle="primary"
            className="pull-right"
            link
            to={'accounts/ledger/' + account.data.id}
            label="View Account"
          />
        )}
        {!get(batch, 'data.attributes') && (
          <Button
            type="button"
            bsStyle="primary"
            className="pull-right pad-right"
            link
            to={'accounts/' + account.data.id + '/matching'}
            label="Match"
          />
        )}
        {get(batch, 'data.attributes') && (
          <div>
            <Button
              type="button"
              bsStyle="primary"
              className="pull-right pad-right"
              handleClick={::this.handleOpen}
              label="Un-match"
            />
            <LedgerEntriesModal dispatch={this.props.dispatch} batch={batch.data} />
          </div>
        )}
      </div>
    );

    return (
      <Modal
        name={ReceiptModal.MODAL_IDENTIFIER}
        title={
          'Reference: ' +
          (receipt.accounting_reference || receipt.batch_reference || 'Receipt') +
          ' | ' +
          receipt.currency +
          ' ' +
          receipt.formatted_amount
        }
        footer={modalFooter}
        close
      >
        {!receipt || !get(ledgerEntry, 'data.attributes') || id !== receipt.id ? (
          <Loader />
        ) : (
          <div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-horizontal">
                  <InfoLabel
                    label="Account"
                    labelSize={4}
                    value={attributes.account_name}
                  />
                  <InfoLabel
                    label="Sub Account (Broker)"
                    labelSize={4}
                    value={attributes.broker_name}
                  />
                  <InfoLabel
                    label="Accounting Reference"
                    labelSize={4}
                    value={attributes.accounting_reference}
                  />
                  <InfoLabel
                    label="Payment Reference"
                    labelSize={4}
                    value={attributes.batch_reference}
                  />
                  <InfoLabel label="Date" labelSize={4} date value={attributes.date} />
                  <InfoLabel
                    label="Description"
                    labelSize={4}
                    value={attributes.description}
                  />
                  <InfoLabel
                    label="Amount"
                    labelSize={4}
                    value={attributes.formatted_amount}
                  />
                  <InfoLabel
                    label="Policies"
                    labelSize={4}
                    value={policies.map((policy, i) => {
                      return (
                        <li key={i}>
                          <Link to={'/policies/issued/' + policy.data.id}>
                            {get(
                              policy,
                              'data.attributes.policy_number',
                              'Policy Number Not Found',
                            )}
                          </Link>
                        </li>
                      );
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    ledgerEntry: state.account.ledgerEntry,
  };
}

export default connect(mapStateToProps)(ReceiptModal);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchAnnouncements } from '../../../../dashboard/redux/dashboardActions';
import { openModal } from '../../../../../common/components/Modal/ModalRedux';
import { Button, Modal } from '../../../../../common/components/index';
import Markdown from 'react-remarkable';
import getIncludedResponse from '../../../../../helpers/getIncludedResource';
import moment from 'moment/moment';
import { cloneDeep, map } from 'lodash';

class Announcements extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    dashboard: PropTypes.object.isRequired,
  };

  componentWillMount() {
    this.props.dispatch(fetchAnnouncements());
  }

  openAnnouncementModal(id) {
    this.props.dispatch(openModal(id));
  }

  render() {
    const { dashboard } = this.props;

    // TODO: put this in a filter function
    const getAnnounData = cloneDeep(dashboard.announcements.data);
    const currentTime = moment().format();
    const announActive = [];
    map(getAnnounData, (reminder) => {
      let to_date = reminder.attributes.to_date;
      let from_date = reminder.attributes.from_date;
      if (
        moment(currentTime).isSameOrBefore(to_date) &&
        moment(currentTime).isSameOrAfter(from_date)
      ) {
        announActive.push(reminder);
      }
    });

    return (
      <div>
        <table className="table reminders-list">
          <thead />
          <tbody>
            {announActive &&
              announActive.map((announcement) => {
                let attachments =
                  getIncludedResponse(
                    announcement,
                    dashboard.announcements.included,
                    'files',
                  ) || [];
                return (
                  <tr key={announcement.id}>
                    <td className="reminder-date">{announcement.attributes.name}</td>
                    <td>{announcement.attributes.sections.preview}</td>
                    <td className="reminder-action">
                      <Button
                        size="sm"
                        bsStyle="link"
                        label="more details"
                        handleClick={() => this.openAnnouncementModal(announcement.id)}
                      />
                      <Modal
                        name={announcement.id}
                        title={announcement.attributes.name}
                        close
                      >
                        <div>
                          <Markdown source={announcement.attributes.sections.content} />
                        </div>
                        {attachments.length > 0 ? (
                          <p className="note-attachments">
                            <strong>Attachments: </strong>
                            {attachments.map((data, i) => {
                              return (
                                <span className="attachment-link" key={i}>
                                  <a href={data.attributes.url} target="_blank">
                                    {data.attributes.name}
                                  </a>
                                  {attachments.length - 1 !== i ? (
                                    <span>, &nbsp;</span>
                                  ) : null}
                                </span>
                              );
                            })}
                          </p>
                        ) : null}
                      </Modal>
                    </td>
                  </tr>
                );
              })}

            {announActive && announActive.length === 0 && (
              <tr key={1}>
                <td colSpan="2">You have no announcements</td>
                <td />
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dashboard: state.dashboard,
  };
}

export default connect(mapStateToProps)(Announcements);

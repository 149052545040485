import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { InfoLabel, Box, Button } from '../../../../common/components';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import displayAddress from '../../../../helpers/displayAddress';
import {
  passwordReminder,
  reactivateUser,
  deactivateUser,
} from '../../redux/userActions';
import displayName from '../../../../helpers/displayName';
import { Col, Row } from 'react-bootstrap';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';

export default class UserDetails extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    resource: PropTypes.object.isRequired,
    isInOwnerOrganisation: PropTypes.bool,
  };

  sendPasswordResetEmail() {
    this.props.dispatch(passwordReminder(this.props.resource.data.attributes.email));
  }

  reactivateUser() {
    this.props.dispatch(reactivateUser(this.props.resource.data.id));
  }

  deactivateUser() {
    this.props.dispatch(deactivateUser(this.props.resource.data.id));
  }

  buttons() {
    const resource = this.props.resource.data.attributes;
    const contact = resource.status === 'contact';
    const userButtons = [
      {
        permissions: ['user.edit'],
        button: (
          <Button
            link={true}
            to={
              '/admin/users/' +
              this.props.resource.data.id +
              (contact ? '/edit?contact=true' : '/edit')
            }
            className="pull-right"
            bsStyle="primary"
            rightIcon="pencil-square-o"
            label={'Edit ' + (contact ? 'Contact' : 'User')}
          />
        ),
      },
      {
        statuses: ['active', 'pending'],
        permissions: ['user.view'],
        button: (
          <Button
            className="pull-right"
            bsStyle="primary"
            rightIcon="envelope"
            isLoading={this.props.user.isResetting}
            handleClick={::this.sendPasswordResetEmail}
            label="Send Password Reset Email"
          />
        ),
      },
      {
        statuses: ['inactive'],
        permissions: ['user.edit'],
        button: (
          <Button
            className="pull-right"
            bsStyle="primary"
            rightIcon="check"
            isLoading={this.props.user.isReactivating}
            handleClick={::this.reactivateUser}
            label="Reactivate User"
          />
        ),
      },
      {
        statuses: ['active'],
        permissions: ['user.edit'],
        button: (
          <Button
            className="pull-right"
            bsStyle="primary"
            rightIcon="ban"
            isLoading={this.props.user.isDeactivating}
            handleClick={::this.deactivateUser}
            label="Deactivate User"
          />
        ),
      },
    ];

    return userButtons.filter((button) => {
      return !(button.statuses && button.statuses.indexOf(resource.status) === -1);
    });
  }

  render() {
    const resource = this.props.resource.data.attributes;
    const organisation = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'organisations',
    );
    const team = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'teams',
    );
    // const role = getIncludedResource(
    //   this.props.resource.data,
    //   this.props.resource.included,
    //   'roles',
    // );
    const address = displayAddress(this.props.resource.data.attributes.address);

    let organisations = '';
    if (Array.isArray(organisation)) {
      organisation.map((relation, i) => {
        if (i) {
          organisations += ', ';
        }
        organisations += relation.attributes.name;
      });
    }

    let teams = '';
    if (Array.isArray(team)) {
      team.map((relation, i) => {
        if (i) {
          teams += ', ';
        }
        teams += relation.attributes.name;
      });
    }

    // let roles = '';
    // if (Array.isArray(role)) {
    //   role.map((relation, i) => {
    //     if (i) {
    //       roles += ', ';
    //     }
    //     roles += relation.attributes.name;
    //   });
    // }

    return (
      <Box>
        <h2 className="resource-name">{resource.full_name}</h2>

        <Row>
          <Col sm={6}>
            <div className="form-horizontal">
              <InfoLabel
                label="Name"
                value={resource.first_name + ' ' + resource.last_name}
                labelSize={4}
              />
              <InfoLabel label="Address" value={address} labelSize={4} />
              <InfoLabel label="Email" value={resource.email} labelSize={4} />
              <InfoLabel label="Phone" value={resource.phone1} labelSize={4} />
              <InfoLabel
                label="Alternative Phone"
                value={resource.phone2}
                labelSize={4}
              />
            </div>
          </Col>

          <Col sm={6}>
            <div className="form-horizontal">
              <InfoLabel label="Organisations" value={organisations} labelSize={4} />
              <InfoLabel label="Teams" value={teams} labelSize={4} />
              <InfoLabel label="Position" value={resource.position} labelSize={4} />
              <InfoLabel
                label="Status"
                value={displayName(resource.status)}
                labelSize={4}
              />
              <InfoLabel
                label="Created At"
                date
                value={resource.created_at}
                labelSize={4}
              />
              <InfoLabel
                label="Updated At"
                date
                value={resource.updated_at}
                labelSize={4}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <InternalButtonBar buttons={this.buttons()} />
          </Col>
        </Row>
      </Box>
    );
  }
}

import React, { Component } from 'react';
import Axios from 'axios';
import Markdown from 'react-remarkable';
import CenteredSection from '../components/CenteredSection/CenteredSection';
import { replace } from 'react-router-redux';
import '../brochure.scss';
import Box from '../../../common/components/Box/Box';
import { get } from 'lodash';

export default class Faqs extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      content: '',
    };
  }

  componentWillMount() {
    Axios.get('public/contents?filter[slug]=faqs')
      .then((response) => {
        this.setState({ content: response.data.data[0] });
      })
      .catch(() => {
        this.props.dispatch(replace('/404'));
      });
  }

  render() {
    let content = this.state.content;
    // let content = CONTENT
    if (content && content.attributes) {
      content = get(content, "attributes.sections['body']");
    }
    return (
      <div className="guest-section">
        <div className="guest-title">FAQ's</div>
        <CenteredSection className="page-content">
          {content && content.length && (
            <Box>
              <Markdown source={content} />
            </Box>
          )}
        </CenteredSection>
      </div>
    );
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SimpleDatePicker from '../SimpleDatePicker/SimpleDatePicker';
import moment from 'moment';
import 'moment/locale/en-gb';
import './styles.scss';

class DatePickerLeisure extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(date) {
    if (this.props.input && this.props.input.onChange) {
      this.props.input.onChange(date);
    }
  }

  render() {
    const {
      input,
      minDate,
      maxDate,
      minDateToday,
      showYear,
      meta: { touched, error },
      id = 'datepicker',
    } = this.props;
    const selected = input.value ? input.value : null;
    return (
      <div className={'select-date-input' + ((touched && error) ? ' has-error' : '')}>
        <SimpleDatePicker
          id={id}
          monthsShown={2}
          minDate={minDateToday ? moment().format() : minDate}
          maxDate={maxDate}
          showYear={showYear}
          selected={selected}
          onChange={this.onChange}
        />
        {touched && error && (
          <div>
            <span className="input-error">{error}</span>
          </div>
        )}
      </div>
    );
  }
}

DatePickerLeisure.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
  fieldSize: PropTypes.string,
  labelSize: PropTypes.number,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  maxDate: PropTypes.string,
  minDateToday: PropTypes.bool,
  showYear: PropTypes.bool,
};

export default DatePickerLeisure;

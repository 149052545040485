import React from 'react';
import PropTypes from 'prop-types';
import './Upload.scss';

const UploadArea = ({ onDrop, onDragOver, onClick, isUploading }) => {
  let classNames = ['upload-area'];

  if (isUploading) {
    classNames.push('disabled');
  }

  return (
    <div
      className={classNames.join(' ')}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onClick={onClick}
    >
      <p>
        Drag and Drop files here, or <u>click here</u> to select files to upload.
      </p>
    </div>
  );
};

UploadArea.propTypes = {
  onDrop: PropTypes.func.isRequired,
  onDragOver: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  isUploading: PropTypes.bool.isRequired,
};

export default UploadArea;

import React from 'react';

import { connect } from 'react-redux';

import { Sticky, StickyContainer } from 'react-sticky';
import { getFormValues, reduxForm } from 'redux-form';
import { Loader } from '../../../../../../common/components';
import { Col } from 'react-bootstrap';
import { FORM_NAME } from '../../WhitelabelTravelForm';
import { QuoteRow } from '../QuoteRow/QuoteRow';

import './AvailableQuotes.scss';

const NoSchemes = ({}) => {
  return (
    <div>
      <div>
        <h4>No products are available</h4>
        <p>There are no schemes available based on your quotation criteria.</p>
      </div>
    </div>
  );
};


const hasAnnualScheme = (scheme, quotes) => {

  return Object.values(quotes)?.find((quote) => {
    return (
      quote.meta.scheme.scheme_type === 'annual' &&
      quote.meta.scheme.insurer === scheme.insurer &&
      quote.meta.scheme.cover_level === scheme.cover_level
    );
  });
};


const AvailableQuotes = ({
  isMta,
  availableQuotes,
  isCalculating,
  // productId,
  isReferralRequired,
  onClearPremiumIndication,
  onAddScheme,
  onRemoveScheme,
  selectedSchemes,
  formValues,

}) => {


  const schemeIds = availableQuotes ? Object.keys(availableQuotes) : [];


  const handleSchemeSelect = (schemeId) => {


    if (selectedSchemes?.includes(schemeId)) {
      const index = selectedSchemes?.indexOf(schemeId);
      onRemoveScheme(index);
    } else {
      onClearPremiumIndication();
      onAddScheme(schemeId);
    }
  };


  const schemeType = formValues?.data?.attributes?.metadata?.scheme_type;
  let questionsClassName = 'flex2';
  let lastTitleClassName = questionsClassName;

  const salesChannel = formValues?.data?.attributes?.metadata?.sales_channel;
  const awaitingTestChk = formValues?.data?.attributes?.metadata?.medical_declaration_questions?.['awaiting-tests-with-diagnosis']?.answer;
  const awaitingChk = formValues?.data?.attributes?.metadata?.medical_declaration_questions?.['is-awaiting-surgery']?.answer;
  if ((awaitingTestChk || awaitingChk) && salesChannel === 'call-centre') {
    questionsClassName = 'flex4';
    // lastTitleClassName = '';
  }


  //let declines = false;

  if (isCalculating) {
    return (<div>
      <Loader noShadow/>
    </div>);
  }

  if (isReferralRequired) {
    return null;
  }
  if (schemeIds?.length && !isReferralRequired) {
    return (
      <Col xs={12} className="available-quotes">
        <StickyContainer className="quotes">
          <Sticky>
            {() => (
              <div className="quotes-header wl-available-quotes-table">
                <div className="flex2">Underwriter</div>
                <div className="flex2 hide-query-4rd">Scheme</div>
                <div className="flex2 hide-query-4rd">Medical</div>

                <div className="flex2 hide-query-2nd">Cancellation</div>
                <div className="flex2 hide-query-1st">Baggage</div>
                <div className="flex1">Benefits</div>

                {schemeType === 'single' ? (
                  <div className={questionsClassName}>Single</div>
                ) : (
                  <div className="flex3">Annual</div>
                )}
                {schemeType === 'single' && (
                  <div className={lastTitleClassName}>
                    {schemeType === 'single' && 'Annual'}
                    <br/>
                  </div>
                )}
              </div>
            )}
          </Sticky>


          {schemeIds.map((schemeId) => {
            const quote = availableQuotes[schemeId];
            const isAnnualSelected = schemeType === 'annual';
            const isAnnual = quote.meta.scheme.scheme_type !== 'single';
            const annualQuote =
              isAnnualSelected && isAnnual ? quote :
                hasAnnualScheme(quote.meta.scheme, availableQuotes);
            const quoteSchemeType = quote?.meta?.scheme.scheme_type;


            const re = new RegExp(schemeType, 'i');
            const display = quoteSchemeType.match(re);


            if (quote.meta && display) {
              return (
                <QuoteRow
                  key={quote.meta.scheme.id}
                  isMta={isMta}
                  singleQuote={quote.meta}
                  annualQuote={annualQuote?.meta}
                  isAnnualSelected={isAnnualSelected}
                  onSchemeSelect={handleSchemeSelect}
                  selectedSchemes={selectedSchemes}
                />

              );
            }
          })}
        </StickyContainer>
      </Col>
    );
  }


  if (!schemeIds?.length) {
    return (
      <NoSchemes/>
    );
  }


};


export default connect(
  (state) => ({
    formValues: getFormValues(FORM_NAME)(state),
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })
)(reduxForm({ form: FORM_NAME })(AvailableQuotes));


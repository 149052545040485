import React from 'react';
import { DataTable, Button } from '../../../../common/components';
import { TableHeaderColumn } from 'react-bootstrap-table';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import BrokerAssignUser from '../BrokerAssignUser/BrokerAssignUser';
import {
  refreshData
} from '../../../../common/components/DataTable/redux/dataTableActions';
import { Col, Row } from 'react-bootstrap';
import {
  InternalButtonBar
} from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import formatDateTime from '../../../../helpers/formatDateTime';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';

const BrokerUsers = ({ resource, goTo, refreshData }) => {

  const handleRowClick = (row) => {
    goTo('/admin/users/' + row.id);
  };

  const refreshList = () => {
    refreshData('users');
  };

  const brokerId = resource?.data?.id;

  const organisation = getIncludedResource(
    resource.data,
    resource.included,
    'organisation',
  );
  const organisationId = organisation.data.id;
  return (
    <>

      <DataTable
        source="/users"
        name="users"
        showTotal
        refreshData
        autoFilter={brokerId}
        autoFilterType="filter[broker]"
        onRowSelect={handleRowClick}
      >
        <TableHeaderColumn dataField="id" isKey hidden/>
        <TableHeaderColumn dataField="full_name">
          Name
        </TableHeaderColumn>
        <TableHeaderColumn dataField="email" dataSort>
          Email
        </TableHeaderColumn>
        <TableHeaderColumn dataField="status" dataSort width={'180px'}>
          Status
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="created_at"
          dataFormat={formatDateTime}
          width={'180px'}
          dataSort
        >
          Created
        </TableHeaderColumn>
      </DataTable>

      <Row>
        <Col sm={12}>
          <InternalButtonBar buttons={[
            {
              permissions: ['broker.edit', 'user.edit'],
              button: (
                <BrokerAssignUser
                  className="pull-right"
                  resourceId={brokerId}
                  callback={refreshList}
                />
              ),
            },
            {
              permissions: ['broker.edit', 'user.edit'],
              button: (
                <Button
                  link
                  to={`/admin/users/new/${organisationId}/${brokerId}?organisationTypeName=Broker`}
                  className="pull-right"
                  bsStyle="primary"
                  rightIcon="user"
                  label="add a new user"
                />
              ),
            },
          ]}/>
        </Col>
      </Row>
    </>
  );
};
export default connect(
  null,
  {
    goTo: push,
    refreshData: refreshData
  }
)(BrokerUsers);

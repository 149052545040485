import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { get, has } from 'lodash';
import moment from 'moment';
import { AddressFields } from '../../../../common/components/ReduxFormField';
import PostcodeList from '../../../../common/components/Postcode/PostcodeList';
import { Row, Col, ControlLabel, FormGroup } from 'react-bootstrap';
import {
  HorizontalFormControl,
  NameFields,
  DatePickerDob,
  Button,
  Icon,
} from '../../../../common/components';
import './styles.scss';
import { COL_WIDTHS } from '../LeisureTravelForm/Components/TabContent/TravellersTab02';

const travellerDefaults = {
  title: '',
  first_name: '',
  last_name: '',
  dob: '',
  email: '',
  phone1: '',
  phone2: '',
  conditions_covered: true,
  screening: '',
  screening_id: '',
};

class TravellersAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFields: false,
    };
    this.handleCantFind = this.handleCantFind.bind(this);
  }

  handleCantFind() {
    this.setState((prevState) => ({
      showFields: !prevState.showFields,
    }));
  }

  componentWillReceiveProps(props) {
    const { fields, formValues, travellerGroup, child } = props;

    if (travellerGroup !== this.props.travellerGroup) {
      fields.removeAll();

      if (!child) {
        fields.push(formValues ? formValues[0] : travellerDefaults);

        if (travellerGroup === 'couple' || travellerGroup === 'family') {
          setTimeout(() => {
            fields.push(
              formValues && formValues.length >= 2 ? formValues[1] : travellerDefaults,
            );
          }, 0);
        } else if (travellerGroup === 'family') {
          if (formValues && formValues.length >= 2) {
            fields.push(formValues[1]);
          }
        } else if (travellerGroup === 'group') {
          if (formValues && formValues.length > 0) {
            formValues.forEach((formValue, i) => {
              if (i > 0) fields.push(formValue);
            });
          } else {
            for (let i = 0; i < 4; i++) {
              fields.push(travellerDefaults);
            }
          }
        }
      } else {
        if (
          travellerGroup === 'family' ||
          travellerGroup === 'single_parent_family' ||
          travellerGroup === 'independent_child'
        ) {
          if (formValues && formValues.length > 0) {
            fields.push(formValues ? formValues[0] : travellerDefaults);
            formValues.forEach((formValue, i) => {
              if (i > 0) fields.push(formValue);
            });
          }
        }
      }
    }
  }


  renderDob (insured, travellerType, travellerGroup, ageLimits, productCode) {
    const { currentProduct } = this.props;

    const path = has(ageLimits,
      `${travellerGroup}.${travellerType}`)
      ?
      `${travellerGroup}.${travellerType}`
      :
      `${travellerType}`;

    const getDateForMinAge = (path,  ageLimits) => {
      const defaultMinAge = travellerType === 'children' ? 0  : 23;
      let minAgeLimit = get(ageLimits, `${path}.min_age`, defaultMinAge);

      if (['NPO', 'FPS', 'CSPA', 'CSS'].includes(productCode) && insured === 'adults[0]') {
        minAgeLimit = get(ageLimits, 'adult.lead_traveller.min_age', defaultMinAge);
      }

      return moment().subtract(minAgeLimit , 'years').format();
    };

    const getDateForMaxAge = (path, ageLimits) => {
      const defaultMaxAge = travellerType === 'children' ? 22 : 105;
      let maxAgeLimit = get(ageLimits, `${path}.max_age`, defaultMaxAge);

      if (['NPO', 'FPS', 'CSPA', 'CSS'].includes(productCode) && insured === 'adults[0]') {
        maxAgeLimit = get(ageLimits, 'adult.lead_traveller.max_age', defaultMaxAge);
      }

      // DOB is inclusive.
      if (['FPS', 'FPR', 'NPO', 'NPOR', 'CSPA', 'CSPAR', 'CSS', 'CSSR'].includes(productCode)) {
        return moment().add(1, 'day').subtract(maxAgeLimit, 'years').format();
      }

      if ('BMC' === get(currentProduct, 'data.attributes.product_code', '')) {
        maxAgeLimit = maxAgeLimit + 1;
        const maxAgeDate = moment().subtract(maxAgeLimit, 'years');
        return maxAgeDate.add(1, 'days').format();
      }
      maxAgeLimit = 110;
      return moment().subtract(maxAgeLimit, 'years').format();
    };

    return (
      <Field
        name={insured + '.dob'}
        showYear
        minDate={getDateForMaxAge(path, ageLimits)}
        maxDate={getDateForMinAge(path, ageLimits)}
        component={DatePickerDob}
      />
    );
  }

  render() {
    const {
      ageLimits,
      fields,
      leadAddress,
      formValues,
      child,
      label,
      buttonLabel,
      travellerGroup,
      travellerType,
      noTravellers,
      displayGroups,
      extendedFamily,
      addressSelection,
      currentProduct
    } = this.props;

    const addressSelected = () =>
      get(addressSelection, 'attributes.line1', false) ||
      get(formValues, '[0].address.line1', false) ||
      this.state.showFields;
    const showCantFindLink = () =>
      get(addressSelection, 'attributes.line1', '') === '' &&
      get(formValues, '[0].address.line1', '') === '';

    const productCode = get(currentProduct, 'data.attributes.product_code');

    // Disabling Add button for Review Page
    let showButton = false;

    if (noTravellers) {
      if (travellerGroup === 'couple') {
        showButton = false;
      }

      if (travellerGroup === 'family' || travellerGroup === 'individual') {
        showButton =
          (!child && noTravellers.adults < (extendedFamily ? 4 : 10)) ||
          (child && noTravellers.children > 0);
      }

      if (travellerGroup === 'family' && noTravellers.adults > 1 && !child) {
        showButton = false;
      }

      if (travellerGroup === 'independent_child' && child) {
        showButton = noTravellers.children < 10;
      }

      if ((travellerGroup === 'family' || travellerGroup === 'individual') &&
        (productCode === 'ADV' || productCode === 'PST')) {
        showButton =
          (!child && noTravellers.adults < 50) ||
          (child && noTravellers.children > 0);
      }

      if (travellerGroup === 'independent_child' && child &&
        (productCode === 'ADV' || productCode === 'PST')) {
        showButton = noTravellers.children < 50;
      }
    }

    const bmc = ['BMC', 'CPI', 'FPS', 'FPR', 'NPO', 'NPOR', 'CSPA', 'CSPAR', 'CSS', 'CSSR'].includes(productCode);

    if (bmc) {
      showButton = false;
    }

    // Hide if only set to display for specific groups
    if (displayGroups && displayGroups.indexOf(travellerGroup) === -1) {
      return <div/>;
    }

    return (
      <div className="travellers">
        {child && !fields.length && (
          <Row>
            <Col sm={COL_WIDTHS.labels.sm + COL_WIDTHS.fields.sm} md={COL_WIDTHS.labels.md + COL_WIDTHS.fields.md} lg={COL_WIDTHS.labels.lg + COL_WIDTHS.fields.lg}>
              <Button
                bsStyle="primary"
                size="xs"
                className="add-traveller pull-right"
                label="Add a Child"
                leftIcon={'plus'}
                handleClick={() => fields.push(travellerDefaults)}
              />
            </Col>
          </Row>
        )}
        <div>
          {fields.map((insured, index) => {
            let fieldLabel = label;
            if (!label) {
              if (child || travellerGroup === 'independent_child') {
                fieldLabel = 'Child';
              } else {
                fieldLabel = 'Adult';
              }
            }

            let renderCustomer = !index && leadAddress;
            return (
              <div
                key={index}
                className={
                  'traveller-row-address traveller' +
                  index +
                  (child ? ' child' : '')
                }
              >
                {!index && !child && !label && travellerGroup !== 'independent_child' ? (
                  <h4>
                    {!bmc ? (
                      <strong>Lead Traveller</strong>
                    ) : (
                      <strong>Traveller Details</strong>
                    )}
                  </h4>
                ) : (
                  <div>
                    {this.props.travellerGroup !== 'couple' &&
                    this.props.travellerGroup !== 'family' && (
                      <span
                        //style={{ border: '1px solid blue' }}
                        className="remove-traveller-adult pull-right"
                        onClick={() => fields.remove(index)}
                      >
                          <Icon name="times"/>
                        </span>
                    )}
                    <h4>
                      {fieldLabel} {index + 1}
                    </h4>
                  </div>
                )}

                <FormGroup>
                  <Col sm={COL_WIDTHS.labels.sm} md={COL_WIDTHS.labels.md} lg={COL_WIDTHS.labels.lg} componentClass={ControlLabel}>
                    Name
                  </Col>
                  <Col sm={COL_WIDTHS.fields.sm} md={COL_WIDTHS.fields.md} lg={COL_WIDTHS.fields.lg}>
                    <NameFields
                      baseName={insured}
                      disableOnBlur
                      fieldComponent={Field}
                      child={child}
                    />
                  </Col>
                </FormGroup>

                <FormGroup>
                  <Col sm={COL_WIDTHS.labels.sm} md={COL_WIDTHS.labels.md} lg={COL_WIDTHS.labels.lg} componentClass={ControlLabel}>
                    Date of birth
                  </Col>
                  <Col sm={COL_WIDTHS.fields.sm} md={COL_WIDTHS.fields.md} lg={COL_WIDTHS.fields.lg}>
                    {this.renderDob(insured, travellerType , travellerGroup, ageLimits, productCode)}
                  </Col>
                </FormGroup>

                {renderCustomer && (
                  <div>
                    <FormGroup>
                      <Col sm={COL_WIDTHS.labels.sm} md={COL_WIDTHS.labels.md} lg={COL_WIDTHS.labels.lg} componentClass={ControlLabel}>
                        Email
                      </Col>
                      <Col sm={COL_WIDTHS.fields.sm} md={COL_WIDTHS.fields.md} lg={COL_WIDTHS.fields.lg}>
                        <Field
                          name={insured + '.email'}
                          type="email"
                          component={HorizontalFormControl}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Col sm={COL_WIDTHS.labels.sm} md={COL_WIDTHS.labels.md} lg={COL_WIDTHS.labels.lg} componentClass={ControlLabel}>
                        Telephone
                      </Col>
                      <Col sm={COL_WIDTHS.fields.sm} md={COL_WIDTHS.fields.md} lg={COL_WIDTHS.fields.lg}>
                        <Field
                          name={insured + '.phone1'}
                          component={HorizontalFormControl}
                        />
                      </Col>
                    </FormGroup>
                    <PostcodeList
                      currentProduct={currentProduct}
                      handleCantFind={this.handleCantFind}
                      showFieldsState={this.state.showFields}
                      name={insured + '.address.postcode'}
                      formName="LeisureTravelForm"
                      targetField={'data.attributes.metadata.' + insured + '.address'}
                      showCantFindLink={showCantFindLink}
                      label="Postcode"
                      change={this.props.change}
                      labelSizes={{
                        sm: COL_WIDTHS.labels.sm,
                        md: COL_WIDTHS.labels.md,
                        lg: COL_WIDTHS.labels.lg,
                      }}
                      postcodeFieldSizes={{
                        sm: 6,
                        md: 5,
                        lg: 4,
                      }}
                      searchBtnSizes={{
                        sm: 4,
                        md: 3,
                        lg: 4,
                      }}
                      fieldSizes={{
                        sm: COL_WIDTHS.fields.sm,
                        md: COL_WIDTHS.fields.md,
                        lg: COL_WIDTHS.fields.lg,
                      }}
                      noWrap
                      value={get(formValues, '[' + index + '].address.postcode', '')}
                    />
                    {addressSelected() && (
                      <AddressFields
                        baseName={insured + '.address'}
                        label="Address"
                        labelSizes={{
                          sm: COL_WIDTHS.labels.sm,
                          md: COL_WIDTHS.labels.md,
                          lg: COL_WIDTHS.labels.lg,
                        }}
                        fieldSizes={{
                          sm: COL_WIDTHS.fields.sm,
                          md: COL_WIDTHS.fields.md,
                          lg: COL_WIDTHS.fields.lg,
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        {showButton && (
          <Row className="travellers-address">
            <Col sm={COL_WIDTHS.labels.sm + COL_WIDTHS.fields.sm} md={COL_WIDTHS.labels.md + COL_WIDTHS.fields.md} lg={COL_WIDTHS.labels.lg + COL_WIDTHS.fields.lg}>
              <Button
                bsStyle="primary"
                size="xs"
                className="add-traveller pull-right"
                leftIcon={'plus'}
                label={
                  buttonLabel ? buttonLabel : 'Add ' + (child ? 'a Child' : 'an Adult')
                }
                handleClick={() => fields.push(travellerDefaults)}
              />
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

TravellersAddress.propTypes = {
  fields: PropTypes.object.isRequired,
  formValues: PropTypes.array,
  travellerGroup: PropTypes.string,
  displayGroups: PropTypes.array,
  child: PropTypes.bool,
  label: PropTypes.string,
  buttonLabel: PropTypes.string,
  leadAddress: PropTypes.bool,
  leadContact: PropTypes.bool,
  change: PropTypes.func,
  noTravellers: PropTypes.object,
  dobReadOnly: PropTypes.bool,
};

const mapStateToProps = ({ postcode: { selection } }) => {
  return { addressSelection: selection };
};

export default connect(mapStateToProps)(TravellersAddress);

import { connect, useSelector } from "react-redux";
import UploadModalComponent, {
  UploadModal
} from "../../../../../../common/components/Upload/UploadModal";
import { EditProductIcon } from "../Icon/EditProductIcon";
import { Button } from "../../../../../../common/components/Button/Button";
import React from "react";
import { openModal } from "../../../../../../common/components/Modal/ModalRedux";
import { clearFiles } from "../../../../../../common/components/Upload/UploadRedux";
import './LogoField.scss';

const LogoField = ({ input, onUpload, onClearFiles, isSubmitting }) => {

  const uploadedFile = useSelector((state) => state.upload.files?.[0]);

  const handleUpload = () => {
    onUpload(UploadModal.MODAL_IDENTIFIER);
  }

  const value = input.value;

  const logoUrl = value.attributes?.url;
  const handleUploadComplete = () => {
    input.onChange(uploadedFile)
    onClearFiles();
  };


  return (<div className={'edit-product-details-logo-field'}>
      <EditProductIcon logoUrl={logoUrl} inputUrl={value}/>

      <UploadModalComponent
        onComplete={handleUploadComplete}
        accept="image/svg+xml"
        maxFiles={1}
      />
      <div>


        <Button
          type="button"
          bsStyle="primary"
          className="pull-right pad-right"
          handleClick={handleUpload}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <i className="fa fa-cog fa-spin"/>
          ) : (
            <i className="fa fa-upload"/>
          )}{' '}
          {value ? 'Update Product Icon' : 'Add Product Icon'}
        </Button>
      </div>
    </div>
  );


}

export default connect(
  null,
  {
    onUpload: openModal,
    onClearFiles: clearFiles,
  }
)(LogoField)

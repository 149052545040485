import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, DataTable, Button } from '../../../../common/components';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { push } from 'react-router-redux';
import { FormattedDate, FormattedTime } from 'react-intl';
import { refreshData } from '../../../../common/components/DataTable/redux/dataTableActions';
import { get } from 'lodash';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import OrganisationAssignUser from '../OrganisationAssignUser/OrganisationAssignUser';
import { Col, Row } from 'react-bootstrap';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';

export default class OrganisationUsers extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
  };

  formatDate(cell) {
    return (
      <span>
        <FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/>
      </span>
    );
  }

  refreshList() {
    this.props.dispatch(refreshData('users'));
  }

  handleRowClick(row) {
    this.props.dispatch(push('/admin/users/' + row.id));
  }

  render() {
    const resource = this.props.resource.data.attributes;
    const organisationType = getIncludedResource(
      this.props.resource.data,
      this.props.resource.included,
      'organisation_type',
    );

    return (
      <Box>
        <h2 className="resource-name">{resource.name}</h2>

        <DataTable
          source="/users"
          name="users"
          showTotal
          autoFilter={this.props.resource.data.id}
          autoFilterType="filter[organisation]"
          onRowSelect={this.handleRowClick.bind(this)}
        >
          <TableHeaderColumn dataField="id" isKey hidden/>
          <TableHeaderColumn dataField="first_name">First Name</TableHeaderColumn>
          <TableHeaderColumn dataField="last_name">Last Name</TableHeaderColumn>
          <TableHeaderColumn dataField="email" dataSort>
            Email
          </TableHeaderColumn>
          <TableHeaderColumn dataField="status" dataSort width={'180px'}>
            Status
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="created_at"
            dataFormat={this.formatDate}
            width={'180px'}
            dataSort
          >
            Created
          </TableHeaderColumn>
        </DataTable>

        {get(organisationType, 'data.attributes.name') !== 'Broker' && (
          <Row>
            <Col sm={12}>
              <InternalButtonBar
                buttons={[
                  {
                    permissions: ['user.edit', 'organisation.edit'],
                    button: (
                      <OrganisationAssignUser
                        className="pull-right"
                        resource={this.props.resource}
                        callback={::this.refreshList}
                      />
                    ),
                  },
                  {
                    permissions: ['user.create', 'organisation.edit'],
                    button: (
                      <Button
                        link
                        to={
                          '/admin/users/new/' +
                          this.props.resource.data.id +
                          '?organisationTypeName=' +
                          get(this.props.resource, 'data.attributes.organisation_type_name')
                        }
                        className="pull-right"
                        bsStyle="primary"
                        rightIcon="user"
                        label="Add a New User"
                      />
                    ),
                  },
                ]}
              />
            </Col>
          </Row>
        )}
      </Box>
    );
  }
}

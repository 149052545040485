import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InternalContainer from '../../layout/containers/Internal';
import InternalTabs from '../../layout/components/InternalTabs/InternalTabs';
import ReindexDataTab from '../components/ReindexData/ReindexDataTab';

const TAB_NAME = 'toolsTab';

class ReindexDataContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  render() {
    return (
      <InternalContainer title="Reindex Data">
        <InternalTabs
          id={TAB_NAME}
          tabs={[
            {
              permissions: ['report.bdx'],
              orgTypes: ['owner'],
              title: 'Reindex Data',
              content: <ReindexDataTab {...this.props} />,
            },
          ]}
        />
      </InternalContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    account: state.account,
  };
}

export default connect(mapStateToProps)(ReindexDataContainer);

import React from 'react';

import { connect } from 'react-redux';
import {
  reduxForm,
  Field,
  FieldArray,
  getFormValues
} from 'redux-form';
import { SelectField } from '../../../../common/components/ReduxFormField';
import {
  Box,
  Button,
  DatePickerDob,
  HorizontalFormControl,
} from '../../../../common/components';
import { postJournal } from '../../redux/accountActions';
import { Journal } from '../Journal';

import { required } from 'redux-form-validators';
import './styles.scss';
import { Form, Col, Row } from 'react-bootstrap';
import {
  InternalButtonBar
} from '../../../layout/components/InternalButtonBar/InternalButtonBar';
import { compose } from 'redux';
import { JOURNAL_DEFAULTS } from './helpers';

const JournalForm = ({
  handleSubmit,
  submitting,
  formValues,
  onPostJournal
}) => {


  const post = (values) => {
    return onPostJournal(values);
  };

  return (
    <Box>
      <h2 className="resource-name">Add a manual journal</h2>
      <div className="journal-form">
        <Form onSubmit={handleSubmit(post)}>
          <div className="journal-form-header">
            <Row className="form-horizontal">
              <Col sm={4}>
                <Field
                  name="data.attributes.date"
                  label="Date"
                  labelSize={3}
                  component={DatePickerDob}
                  validate={required({ msg: 'Date is required' })}
                />
              </Col>
              <Col sm={4}>
                <Field
                  name="data.attributes.description"
                  label="Description"
                  labelSize={3}
                  component={HorizontalFormControl}
                  validate={required({ msg: 'Description is required' })}
                />
              </Col>
              <Col sm={4}>
                <Field
                  name="data.attributes.entry_type"
                  label="Type"
                  labelSize={3}
                  options={[
                    { label: 'Adjustment', value: 'adjustment' },
                    { label: 'Premium', value: 'premium' },
                    { label: 'Transaction', value: 'transaction' },
                  ]}
                  defaultValue={{ label: 'Adjustment', value: 'adjustment' }}
                  component={SelectField}
                  validate={required({ msg: 'Entry is required' })}
                />
              </Col>
            </Row>
          </div>

          <Row>
            <Col xs={12}>
              <FieldArray
                name="data.attributes.metadata.ledger_entries"
                component={Journal}
                entryType={formValues?.data?.attributes?.entry_type ?? 'transaction'}
                values={formValues?.data?.attributes?.metadata?.ledger_entries}
              />
            </Col>
          </Row>

          <div className="hr transparent"/>

          <Row>
            <Col sm={12}>
              <InternalButtonBar buttons={[
                {
                  button: (
                    <Button
                      label="Cancel"
                      bsStyle="primary"
                      className="pull-left"
                      link
                      to="/accounts/bookkeeping#journals"
                    />
                  )
                },
                {
                  permissions: ['account_ledger.journal_create'],
                  button: (
                    <Button
                      label="Post Journal"
                      bsStyle="primary"
                      className="pull-right"
                      type="submit"
                      isLoading={submitting}
                    />
                  )
                },
              ]}/>
            </Col>
          </Row>
        </Form>
      </div>
    </Box>
  );

};

const FORM_NAME = 'ManualJournalForm';
export default compose(
  connect(
    (state) => (
      {
        formValues: getFormValues(FORM_NAME)(state),
        initialValues: {
          data: {
            attributes: {
              date: '',
              description: '',
              entry_type: 'adjustment',
              metadata: {
                ledger_entries: [
                  JOURNAL_DEFAULTS,
                  JOURNAL_DEFAULTS
                ]
              }
            }
          },

          //ownProps.resource
        },
      }
    ),
    {
      onPostJournal: postJournal,
    }
  ),
  reduxForm({
    form: FORM_NAME
  })
)(JournalForm);


import React from 'react';
import { Field, FieldArray } from 'redux-form';
import { Col, ControlLabel, FormControl, FormGroup, Row } from 'react-bootstrap';
import { HorizontalFormControl, InfoButton, } from '../../../../../../common/components';
import parseFloatValue from '../../../../../../helpers/parseFloatValue';

const AddOnAfter = ({ onAdd, onRemove, index, showAdd }) => {
  const handleAdd = () => {
    onAdd(index);
  };
  const handleRemove = () => {
    onRemove(index);
  };

  if (showAdd) {
    return (
      <span onClick={handleAdd}>
        &#43;
      </span>
    );
  }
  return (
    <span onClick={handleRemove}>
      &#8211;
    </span>
  );
};

export const Band = ({ input, addComma }) => {
  return (
    <span>
      {input.value}
      {addComma ? ', ' : ''}
    </span>
  );
};

const MaxTripDurationBandsByCoverLevelField = ({ isViewOnly, input }) => {
  const bandsByCoverLevel = input.value;
  const coverLevels = Object.keys(bandsByCoverLevel);

  const value = input.value;
  const handleAdd = () => {
    value.push(0);
    input.onChange(value);

  };
  const handleRemove = (index) => {
    value.splice(index, 1);
    input.onChange(value);


  };
  return (
    <>
      {coverLevels.map((coverLevel, index) => (
        <Row>
          <Col sm={4} className={'pad-bottom'}>
            <ControlLabel>{coverLevel}</ControlLabel>
          </Col>
          <Col sm={6}>
            <FieldArray
              name={`${input.name}.${coverLevel}`}
              component={MaxTripDurationBandsFields}
              isViewOnly={isViewOnly}
              // index={index}
              addonAfter={!isViewOnly ?
                <AddOnAfter
                  index={index}
                  showAdd={coverLevels.length === (index + 1)}
                  onAdd={handleAdd}
                  onRemove={handleRemove}/> : undefined}
            />
          </Col>
        </Row>
      ))}
    </>
  );
};

export const MaxTripDurationBandsFields = ({ isViewOnly, fields }) => {

  const addNewMaxTripDurationBand = () => {
    fields.push();
  };

  const removeNewMaxTripDurationBand = (index) => {
    fields.remove(index);
  };

  if (isViewOnly) {
    return (
      <FormControl.Static>
        {fields?.map((field, index) => {
          return (
            <Field
              name={field}
              component={Band}
              addComma={fields.length > 1 && fields.length !== index + 1}
            />
          );
        })}
      </FormControl.Static>
    );
  }

  return (
    <>
      {fields.map((field, index) => (
        <FormGroup>
          <Col sm={6} className={'pad-bottom'}>
            <Field
              name={field}
              component={HorizontalFormControl}
              parse={parseFloatValue}
              index={index}
              addonAfter={
                !isViewOnly ? (
                  <AddOnAfter
                    index={index}
                    showAdd={fields.length === index + 1}
                    onAdd={addNewMaxTripDurationBand}
                    onRemove={removeNewMaxTripDurationBand}
                  />
                ) : undefined
              }
            />
          </Col>
        </FormGroup>
      ))}
    </>
  );
};

const MaxTripDurationBandMap = ({ input, isViewOnly }) => {
  const hasCoverLevel = !Array.isArray(input.value);

  return (
    <>
      <Col sm={12}>
        <ControlLabel>
          <h4>
            Max Trip Duration Bands: <InfoButton content={'tbc'}/>
          </h4>
        </ControlLabel>
      </Col>
      <Col sm={9}>
        {hasCoverLevel ? (
          <Field
            name="meta.config.parameters.max_trip_duration.bands"
            component={MaxTripDurationBandsByCoverLevelField}
            isViewOnly={isViewOnly}
          />
        ) : (
          <FieldArray
            name="meta.config.parameters.max_trip_duration.bands"
            component={MaxTripDurationBandsFields}
            isViewOnly={isViewOnly}
          />
        )}
      </Col>
    </>
  );
};

const MaxTripDurationBands = ({ show, isViewOnly }) => {
  if (!show) {
    return null;
  }

  return (
    <Field
      name="meta.config.parameters.max_trip_duration.bands"
      component={MaxTripDurationBandMap}
      isViewOnly={isViewOnly}
    />
  );
};

export default MaxTripDurationBands;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import Markdown from 'react-remarkable';
import ContactForm from '../../dashboard/components/ContactForm/ContactForm';
import { replace } from 'react-router-redux';
import CenteredSection from '../components/CenteredSection/CenteredSection';
import { get } from 'lodash';

class ContactContainer extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      content: {
        attributes: {},
      },
    };
  }

  componentWillMount() {
    Axios.get('public/contents?filter[slug]=contact')
      .then((response) => {
        if (response.data.data.length) {
          return this.setState({ content: response.data.data[0] });
        }
      })
      .catch(() => {
        this.props.dispatch(replace('/404'));
      });
  }

  render() {
    let content = this.state.content;
    if (content && content.attributes) {
      content = get(content, "attributes.sections['body']");
    }
    return (
      <div className="guest-section">
        <div className="guest-title">Contact Us</div>
        <CenteredSection className="page-content">
          {content && content.length && (
            <div className="contact-info-box">
              <div className="head">
                <h4>Contact Information</h4>
              </div>
              <div className="box-body">
                <Markdown source={content} />
              </div>
            </div>
          )}
          <div className="contact-info-box">
            <div className="head">
              <h4>Contact Us</h4>
            </div>
            <div className="box-body">
              <ContactForm />
            </div>
          </div>
        </CenteredSection>
      </div>
    );
  }
}

export default connect()(ContactContainer);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, isEmpty, filter, get, cloneDeep, set, isNil } from 'lodash';
import { connect } from 'react-redux';
import { Row, Col, Panel } from 'react-bootstrap';
import { InfoLabel, Button } from '../../../../../../common/components';
import Icon from '../../../../../../common/components/Icon/Icon';
import UploadModalComponent, {
  UploadModal,
} from '../../../../../../common/components/Upload/UploadModal';
import {
  createDoctor,
  getPolicy,
  updatePolicyApplication,
  getDoctorForm,
} from '../../../../redux/policyActions';
import getIncludedResource from '../../../../../../helpers/getIncludedResource';
import { openModal } from '../../../../../../common/components/Modal/ModalRedux';
import {
  clearFiles,
  setUploadAccept,
} from '../../../../../../common/components/Upload/UploadRedux';
import './styles.scss';
import { isOwner } from '../../../../../auth/redux/authActions';

export class Travellers extends Component {
  constructor(props) {
    super(props);
    this.openClose = this.openClose.bind(this);
    this.handleUploadComplete = this.handleUploadComplete.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.afterCreateDoctor = this.afterCreateDoctor.bind(this);
    this.saveApplication = this.saveApplication.bind(this);
    this.getDoctorForm = this.getDoctorForm.bind(this);
    this.state = {
      open: false,
      adultIndex: null,
      childIndex: null,
    };
  }

  getDoctorForm() {
    this.props.dispatch(getDoctorForm()).then((filepath) => {
      window.open(filepath, '_blank');
    });
  }

  handleUpload() {
    const { dispatch } = this.props;
    dispatch(setUploadAccept('application/pdf'));
    dispatch(clearFiles()); // clear Store before new upload
    dispatch(openModal(UploadModal.MODAL_IDENTIFIER));
  }

  handleUploadComplete() {
    const { dispatch } = this.props;
    const fileId = get(this.props.upload, 'files[0].id');
    const customer = getIncludedResource(
      this.props.fullResource.data,
      this.props.fullResource.included,
      'customer',
    );
    const customerId = get(customer, 'data.id');
    const fileName = get(this.props.upload, 'files[0].attributes.name');
    dispatch(createDoctor(fileId, customerId, fileName)) // it will update the store
      .then(() => this.afterCreateDoctor()); // lets update the metadata and save the application
  }

  afterCreateDoctor() {
    // Store is created, ready to update the metadata.adult[index] or metadata.children[index]
    const { fullResource: resource, doctorForm, dispatch } = this.props;
    const localResource = cloneDeep(resource);

    const doctorFormObject = {
      id: doctorForm.data.id,
      url: doctorForm.data.attributes.url,
      filename: doctorForm.data.attributes.name,
      updated_at: doctorForm.data.attributes.updated_at,
    };

    if (!isNil(this.state.adultIndex)) {
      set(
        localResource,
        `data.attributes.metadata.adults[${
          this.state.adultIndex
        }].metadata.doctor_form_info`,
        doctorFormObject,
      );
    }
    if (!isNil(this.state.childIndex)) {
      set(
        localResource,
        `data.attributes.metadata.children[${
          this.state.childIndex
        }].metadata.doctor_form_info`,
        doctorFormObject,
      );
    }

    const schemes = getIncludedResource(resource.data, resource.included, 'schemes');
    const product = getIncludedResource(
      schemes && schemes.length ? schemes[0] : resource.data,
      resource.included,
      'product',
    );
    const applicationId = get(resource, 'data.id');

    dispatch(updatePolicyApplication(localResource, product, applicationId)).then(() =>
      dispatch(
        getPolicy(this.props.params.id, this.props.params.type === 'applications'),
      ),
    );
  }

  saveApplication(index, traveller, type) {
    if (type === 'adult') {
      this.setState(() => ({
        adultIndex: index,
        childIndex: null,
      }));
    }

    if (type === 'child') {
      this.setState(() => ({
        childIndex: index,
        adultIndex: null,
      }));
    }

    this.handleUpload();
  }

  openClose() {
    this.setState((oldState) => ({
      open: !oldState.open,
    }));
  }

  render() {
    const { resource } = this.props;
    const SCORE_REQUIRE_DOCTOR_FORM = 60;
    return (
      <div>
        <UploadModalComponent onComplete={this.handleUploadComplete} />
        <div className="section-title">
          <h4>Travellers</h4>
        </div>
        {get(resource, 'metadata.adults', []).map((traveller, i) => {
          const doctorFormInformation = get(traveller, 'metadata.doctor_form_info');
          const regions = get(traveller, 'screening.meta.regions');
          const productCode = resource.metadata.product_code;
          const isDoctorFormRequired = !isEmpty(
            filter(regions, function(o) {
              return o > SCORE_REQUIRE_DOCTOR_FORM;
            }),
          );
          return (
            <Row key={i} className="traveller-section">
              <div className="form-horizontal">
                <Row>
                  <Col sm={6}>
                    <InfoLabel
                      label={'Adult ' + (i + 1)}
                      value={
                        traveller.title +
                        ' ' +
                        traveller.first_name +
                        ' ' +
                        traveller.last_name
                      }
                      labelSize={4}
                      wrapperClass={'clear-margin'}
                    />
                  </Col>
                  <Col sm={6}>
                    <InfoLabel
                      date
                      label="DOB"
                      value={traveller.dob}
                      labelSize={4}
                      wrapperClass={'clear-margin'}
                    />
                  </Col>
                  {productCode !== 'TUMC' && (
                    <Col sm={6}>
                      <InfoLabel
                        label="Medical Conditions"
                        value={
                          traveller.screening
                            ? get(traveller, 'screening.meta.conditions', []).join(', ')
                            : ''
                        }
                        labelSize={4}
                        wrapperClass={'clear-margin'}
                      />
                      {!get(traveller, 'conditions_covered', false) &&
                        get(traveller, 'screening.meta', false) && (
                          <div className="row">
                            <div className="col-sm-12 col-sm-offset-4">
                              <p className="medical-excluded-title">
                                Medical Conditions Excluded - No Cover.
                              </p>
                            </div>
                          </div>
                        )}
                    </Col>
                  )}
                  {get(traveller, 'screening.meta.regions') && (
                    <Col sm={12}>
                      {isOwner() && (
                        <div className="score-panel">
                          <Panel>
                            <Panel.Heading>
                              <Panel.Toggle componentClass="div" onClick={this.openClose}>
                                <label>Scores</label>
                                <span className="plus-sign">
                                  <Icon
                                    name={!this.state.open ? 'plus rotate' : 'minus'}
                                  />
                                </span>
                              </Panel.Toggle>
                            </Panel.Heading>
                            <Panel.Collapse>
                              <Panel.Body>
                                <div>
                                  {map(
                                    get(traveller, 'screening.meta.regions'),
                                    (item, key) => {
                                      return (
                                        <div key={key} className="list-of-regions">
                                          <p>
                                            Region: {key} Score: {item}
                                          </p>
                                        </div>
                                      );
                                    },
                                  )}
                                </div>
                              </Panel.Body>
                            </Panel.Collapse>
                          </Panel>
                        </div>
                      )}
                      {isDoctorFormRequired && (
                        <div>
                          {get(resource, 'status') !== 'issued' && (
                            <Button
                              bsStyle="primary"
                              label="Upload Doctor Form"
                              className="pad-right"
                              handleClick={() =>
                                this.saveApplication(i, traveller, 'adult')
                              }
                            />
                          )}
                          <Button
                            type="button"
                            bsStyle="primary"
                            handleClick={this.getDoctorForm}
                          >
                            Download Doctors Form
                          </Button>
                        </div>
                      )}
                    </Col>
                  )}
                </Row>
                {doctorFormInformation && (
                  <div>
                    <span className="title-attachments">Attachment: </span>
                    <a
                      href={doctorFormInformation.url}
                      target="_blank"
                      className="btn btn-link bt-alignment"
                      role="button"
                    >
                      {doctorFormInformation.filename}
                    </a>
                  </div>
                )}
              </div>
            </Row>
          );
        })}

        {get(resource, 'metadata.children', []).map((traveller, i) => {
          const doctorFormInformation = get(traveller, 'metadata.doctor_form_info');
          const regions = get(traveller, 'screening.meta.regions');
          const isDoctorFormRequired = !isEmpty(
            filter(regions, function(o) {
              return o > SCORE_REQUIRE_DOCTOR_FORM;
            }),
          );

          return (
            <Row key={i} className="traveller-section">
              <div className="form-horizontal">
                <Row>
                  <Col sm={6}>
                    <InfoLabel
                      label={'Child ' + (i + 1)}
                      value={
                        traveller.title +
                        ' ' +
                        traveller.first_name +
                        ' ' +
                        traveller.last_name
                      }
                      labelSize={4}
                      wrapperClass={'clear-margin'}
                    />
                  </Col>
                  <Col sm={6}>
                    <InfoLabel
                      label="DOB"
                      value={traveller.dob}
                      date
                      labelSize={4}
                      wrapperClass={'clear-margin'}
                    />
                  </Col>
                  <Col sm={6}>
                    <InfoLabel
                      label="Medical Conditions"
                      value={
                        traveller.screening
                          ? get(traveller, 'screening.meta.conditions', []).join(', ')
                          : ''
                      }
                      labelSize={4}
                      wrapperClass={'clear-margin'}
                    />
                    {!traveller.conditions_covered &&
                      get(traveller, 'screening.meta', false) && (
                        <div className="row">
                          <div className="col-sm-12 col-sm-offset-4">
                            <p className="medical-excluded-title">
                              Medical Conditions Excluded - No Cover.
                            </p>
                          </div>
                        </div>
                      )}
                  </Col>
                  {get(traveller, 'screening.meta.regions') && (
                    <Col sm={12}>
                      {isOwner() && (
                        <div className="score-panel">
                          <Panel>
                            <Panel.Heading>
                              <Panel.Toggle componentClass="div" onClick={this.openClose}>
                                <label>Scores</label>
                                <span className="plus-sign">
                                  <Icon
                                    name={!this.state.open ? 'plus rotate' : 'minus'}
                                  />
                                </span>
                              </Panel.Toggle>
                            </Panel.Heading>
                            <Panel.Collapse>
                              <Panel.Body>
                                <div>
                                  {map(
                                    get(traveller, 'screening.meta.regions'),
                                    (item, key) => {
                                      return (
                                        <div key={key} className="list-of-regions">
                                          <p>
                                            Region: {key} Score: {item}
                                          </p>
                                        </div>
                                      );
                                    },
                                  )}
                                </div>
                              </Panel.Body>
                            </Panel.Collapse>
                          </Panel>
                        </div>
                      )}
                      {isDoctorFormRequired && (
                        <div>
                          {get(resource, 'status') !== 'issued' && (
                            <Button
                              bsStyle="primary"
                              label="Upload Doctor Form"
                              className="pad-right"
                              handleClick={() =>
                                this.saveApplication(i, traveller, 'child')
                              }
                            />
                          )}
                          <Button
                            type="button"
                            bsStyle="primary"
                            handleClick={this.getDoctorForm}
                          >
                            Download Doctors Form
                          </Button>
                        </div>
                      )}
                    </Col>
                  )}
                </Row>
                {doctorFormInformation && (
                  <div>
                    <span className="title-attachments">Attachment: </span>
                    <a
                      href={doctorFormInformation.url}
                      target="_blank"
                      className="btn btn-link bt-alignment"
                      role="button"
                    >
                      {doctorFormInformation.filename}
                    </a>
                  </div>
                )}
              </div>
            </Row>
          );
        })}
      </div>
    );
  }
}

Travellers.propTypes = {
  resource: PropTypes.object.isRequired,
  fullResource: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    policyRedux: state.policy,
    upload: state.upload,
  };
}

export default connect(mapStateToProps)(Travellers);

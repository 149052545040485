import React, { useEffect, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { actions } from '../redux/schemeManagementActions';
import InternalContainer from '../../layout/containers/Internal';
import { Row, Tab, Tabs } from 'react-bootstrap';
import { change, destroy } from 'redux-form';
import {
  getInitialValuesForConfigForm,
  getInitialValuesForDetailForm,
} from '../helpers/getInitialFormValues';

import getIncludedResource from '../../../helpers/getIncludedResource';
import SchemeManagementTabContainer from '../components/SchemeManagementTabContainer';
import MainSchemeGroupDetailsForm
  from '../components/MainSchemeGroupDetailsForm/MainSchemeGroupDetailsForm';

import GeneralDetailsForm from '../components/GeneralDetailsForm/GeneralDetailsForm';
import GeneralRules from '../components/GeneralDetailsForm/components/Rules/GeneralRules';
import AgeLimitRules
  from '../components/GeneralDetailsForm/components/Rules/AgeLimitRules';
import AgeRangeRules
  from '../components/GeneralDetailsForm/components/Rules/AgeRangeRules';
import MedicalRules from '../components/GeneralDetailsForm/components/Rules/MedicalRules';
import NumTravellerRules
  from '../components/GeneralDetailsForm/components/Rules/NumTravellerRules';
import MaxTripDurationBands
  from '../components/GeneralDetailsForm/components/Rules/MaxTripDurationBands';
import CommissionForm from '../components/CommissionForm/CommissionForm';
import SchemeGroupExcessForm from '../components/ExcessForm/SchemeGroupExcessForm';
import SignpostThresholdForm
  from '../components/SignpostThresholdForm/SignpostThresholdForm';

import OptionsForm from '../components/OptionsForm/OptionsForm';
import BenefitsForm from '../components/BenefitsForm/BenefitsForm';
import DeclinesForm from '../components/DeclinesForm/DeclinesForm';
import HandlersForm from '../components/HandlersForm/HandlersForm';
import SmsConfirmationMessageForm
  from '../components/SmsConfirmationMessageForm/SmsConfirmationMessageForm';
import SchemeNotes from '../components/SchemeNotes/SchemeNotes';
import SchemeAudit from '../components/SchemeAudit/SchemeAudit';

const CONFIG_FORM = 'ConfigForm';
const DETAIL_FORM = 'DetailForm';

const MAIN_DETAILS_TAB = 1;
const OPTIONS_TAB = 2;
const BENEFITS_TAB = 3;
const DECLINES_TAB = 4;
const HANDLERS_TAB = 5;
const SMS_TAB = 6;
const NOTES_TAB = 7;
const AUDIT_TAB = 8;

const SchemeGroupEditContainer = ({
  params,
  onGetSchemeGroupDetails,
  onClearSchemeGroupDetails,
  onChange,
  onDestroy,
}) => {

  const isViewOnly = true;
  const { schemeId } = params;

  const { schemeGroupDetails } = useSelector(
    (state) => state.schemeManagement,
  );

  const currentSchemeId = schemeGroupDetails?.data?.id;

  const productId = schemeGroupDetails?.data?.relationships?.product?.data?.id;


  const initialConfigValues = useMemo(
    () => getInitialValuesForConfigForm(schemeGroupDetails),
    [schemeGroupDetails],
  );

  const initialDetailsValues = useMemo(
    () => getInitialValuesForDetailForm(schemeGroupDetails, true),
    [schemeGroupDetails],
  );
  useEffect(
    () => {
      if (!currentSchemeId || currentSchemeId !== schemeId) {
        onGetSchemeGroupDetails(schemeId);
      }
    },
    [schemeId, currentSchemeId, onGetSchemeGroupDetails],
  );

  useEffect(
    () => {
      if (initialConfigValues) {
        onChange(CONFIG_FORM, 'meta', initialConfigValues.meta);
      }
    },
    [initialConfigValues, onChange],
  );

  useEffect(
    () => {
      if (initialDetailsValues) {
        onChange(DETAIL_FORM, 'data', initialDetailsValues.data);
        onChange(DETAIL_FORM, 'meta', initialDetailsValues.meta);
      }
    },
    [initialDetailsValues, onChange],
  );

  useEffect(() => {
    return () => {
      onClearSchemeGroupDetails();
      onDestroy(CONFIG_FORM);
      onDestroy(DETAIL_FORM);
    };
  }, [onClearSchemeGroupDetails, onDestroy]);


  const relatedSchemes = getIncludedResource(
    schemeGroupDetails?.data,
    schemeGroupDetails?.included,
    'all_schemes',
  );
  const coverLevels = schemeGroupDetails?.data?.attributes?.scheme_details.map(
    (scheme) => scheme.cover_level,
  );
  const claimsHandler = getIncludedResource(
    relatedSchemes?.[0],
    schemeGroupDetails?.included,
    'claims_handler',
  );
  const assistanceHandler = getIncludedResource(
    relatedSchemes?.[0],
    schemeGroupDetails?.included,
    'assistance_handler',
  );

  const isSingle = schemeGroupDetails?.data?.attributes.group_key === 'single';

  return (
    <InternalContainer title={'Edit Schemes'}>
      <Tabs unmountOnExit defaultActiveKey={1} id="scheme-management">
        <Tab
          eventKey={MAIN_DETAILS_TAB}
          title="Main Details"
          unmountOnExit={false} // keep first tab mounted to allow submit on both forms
        >
          <SchemeManagementTabContainer hasScheme={!!schemeGroupDetails}>
            <>

              <MainSchemeGroupDetailsForm
                relatedSchemes={relatedSchemes}
                isViewOnly={isViewOnly}
                formName={DETAIL_FORM}
              />
              <GeneralDetailsForm
                isViewOnly={isViewOnly}
                formName={CONFIG_FORM}
              >
                <Row>
                  <GeneralRules isViewOnly={isViewOnly}/>
                  <AgeLimitRules isViewOnly={isViewOnly}/>
                  <MedicalRules isViewOnly={isViewOnly}/>
                </Row>
                <Row>
                  <AgeRangeRules isViewOnly={isViewOnly}/>
                </Row>
                <Row>
                  <NumTravellerRules isViewOnly={isViewOnly}/>
                </Row>

                <Row>
                  <MaxTripDurationBands isViewOnly={isViewOnly} show={!isSingle}/>
                </Row>
              </GeneralDetailsForm>
              <CommissionForm
                isViewOnly={isViewOnly}
                formName={DETAIL_FORM}
              />

              <SchemeGroupExcessForm
                isViewOnly={isViewOnly}
                formName={DETAIL_FORM}
                coverLevels={coverLevels}
              />
              <SignpostThresholdForm
                isViewOnly={isViewOnly}
                formName={DETAIL_FORM}
              />
            </>
          </SchemeManagementTabContainer>
        </Tab>
        <Tab eventKey={OPTIONS_TAB} title="Options">
          <SchemeManagementTabContainer hasScheme={!!schemeGroupDetails}>
            <OptionsForm
              productId={productId}
              isViewOnly={isViewOnly}
              formName={CONFIG_FORM}
              coverLevels={coverLevels}
            />
          </SchemeManagementTabContainer>
        </Tab>
        <Tab eventKey={BENEFITS_TAB} title="Benefits">
          <SchemeManagementTabContainer hasScheme={!!schemeGroupDetails}>
            <BenefitsForm
              isViewOnly={isViewOnly}
              formName={CONFIG_FORM}
              coverLevels={coverLevels}
            />
          </SchemeManagementTabContainer>
        </Tab>
        <Tab eventKey={DECLINES_TAB} title="Declines">
          <SchemeManagementTabContainer hasScheme={!!schemeGroupDetails}>
            <DeclinesForm
              isViewOnly={isViewOnly}
              formName={CONFIG_FORM}
              coverLevels={coverLevels}
              productType={
                'Leisure Travel Insurance'
              }
            />
          </SchemeManagementTabContainer>
        </Tab>
        <Tab eventKey={HANDLERS_TAB} title="Handlers">
          <SchemeManagementTabContainer hasScheme={!!schemeGroupDetails}>
            <HandlersForm
              isViewOnly={isViewOnly} // you can always update handlers
              formName={DETAIL_FORM}
              claimsHandler={claimsHandler?.data?.attributes?.name}
              assistanceHandler={assistanceHandler?.data?.attributes?.name}
            />
          </SchemeManagementTabContainer>
        </Tab>
        <Tab eventKey={SMS_TAB} title="SMS">
          <SchemeManagementTabContainer hasScheme={!!schemeGroupDetails}>
            <SmsConfirmationMessageForm
              isViewOnly={isViewOnly}
              formName={DETAIL_FORM}
            />
          </SchemeManagementTabContainer>
        </Tab>
        <Tab eventKey={NOTES_TAB} title="Notes">
          <SchemeManagementTabContainer hasScheme={!!schemeGroupDetails}>
            <SchemeNotes
              schemeId={schemeId}
              resourceType={'scheme-groups'}
            />
          </SchemeManagementTabContainer>
        </Tab>

        <Tab eventKey={AUDIT_TAB} title="Audit">
          <SchemeManagementTabContainer hasScheme={!!schemeGroupDetails}>
            <SchemeAudit schemeId={schemeGroupDetails?.data?.id}/>
          </SchemeManagementTabContainer>
        </Tab>
      </Tabs>

    </InternalContainer>
  );
};

export default connect(
  null,
  {

    onGetSchemeGroupDetails: actions.getSchemeGroupDetails,
    onChange: change,
    onDestroy: destroy,
    onClearSchemeGroupDetails: actions.clearSchemeGroupDetails,
  },
)(SchemeGroupEditContainer);

export const START_SCREENING = 'socrates-online/screening/START_SCREENING';
export const FIND_CONDITION = 'socrates-online/screening/FIND_CONDITION';
export const START_DECLARATION = 'socrates-online/screening/START_DECLARATION';
export const DELETE_DECLARATION = 'socrates-online/screening/DELETE_DECLARATION';
export const SET_ANSWER = 'socrates-online/screening/SET_ANSWER';
export const FINISH_DECLARATION = 'socrates-online/screening/FINISH_DECLARATION';
export const FINISH_SCREENING = 'socrates-online/screening/FINISH_SCREENING';
export const IS_SUBMITTING = 'socrates-online/screening/IS_SUBMITTING';
export const IS_SEARCHING = 'socrates-online/screening/IS_SEARCHING';
export const IS_EDITING = 'socrates-online/screening/IS_EDITING';
export const IS_DELETING = 'socrates-online/screening/IS_DELETING';
export const SET_INDEX = 'socrates-online/screening/SET_INDEX';
export const IS_RECALCULATING_SCORE = 'socrates-online/screening/IS_RECALCULATING_SCORE';
export const SET_ERROR = 'socrates-online/screening/SET_ERROR';

const initialState = {
  isSubmitting: false,
  isSearching: false,
  isEditing: false,
  isDeleting: false,
  isRecalculatingScore: false,
  index: 0,
  token: '',
  complete: false,
  conditions: [],
  declaredConditions: {},
  declaration: {},
  errors: {},
  result: {},
  hasMisspelledConditions: false,
  hasSurgicalProcedure: false,
  isAlreadyDeclared: false,
  service: null,
  existingCondition: null
};

export default function screeningReducer(state = initialState, action) {
  switch (action.type) {
    case SET_INDEX:
      return {
        ...initialState,
        index: action.index,
      };
    case IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.status,
      };
    case IS_RECALCULATING_SCORE:
      return {
        ...state,
        isRecalculatingScore: action.status,
      };
    case IS_SEARCHING:
      return {
        ...state,
        isSearching: action.status,
      };
    case IS_EDITING:
      return {
        ...state,
        isEditing: action.status,
      };
    case IS_DELETING:
      return {
        ...state,
        isDeleting: action.status,
      };
    case START_SCREENING:
      return {
        ...state,
        complete: !!action.conditions,
        token: action.token,
        service: action.service,
        declaredConditions: action.conditions,
      };
    case FIND_CONDITION:
      return {
        ...state,
        conditions: action.data,
        hasMisspelledConditions: action.hasMisspelledConditions,
        hasSurgicalProcedure: action.hasSurgicalProcedure,
        isAlreadyDeclared: action.isAlreadyDeclared,
        existingCondition: action.existingCondition,
      };
    case START_DECLARATION:
      return {
        ...state,
        complete: false,
        declaration: action.declaration,

        hasMisspelledConditions: false,
        hasSurgicalProcedure: false,
        isAlreadyDeclared: false,
        existingCondition: null
      };
    case SET_ANSWER:
      return {
        ...state,
        declaration: action.declaration,
      };
    case FINISH_DECLARATION:
      return {
        ...state,
        complete: true,
        declaration: {},
        declaredConditions: action.conditions,
      };
    case DELETE_DECLARATION:
      return {
        ...state,
        declaredConditions: action.conditions,
      };
    case FINISH_SCREENING:
      return {
        ...state,
        index: 0,
        result: action.data,
      };
    case SET_ERROR:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}

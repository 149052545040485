import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LeisureTravelForm from '../components/LeisureTravelForm';
import { clearData } from '../redux/productActions';
import { clearData as clearCustomer } from '../../customer/redux/customerActions';
import { clearSelection } from '../../../common/components/Postcode/redux/PostcodeActions';
import { clearCustomer as clearSearchCustomer } from '../../dashboard/containers/redux/searchActions';
import moment from 'moment';
import { isBroker } from '../../auth/redux/authActions';
import { get, isEmpty, set, map } from 'lodash';
import { currentOrganisationType } from '../../auth/redux/authActions';
import { openMenu } from '../../layout/redux/sideNavAction';

class LeisureTravelFormContainer extends Component {
  constructor(props) {
    super(props);
    this.formatDate = this.formatDate.bind(this);
  }

  /**
   * Clean out the data when the component unmounts
   */
  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(openMenu(true));
    dispatch(clearData());
    dispatch(clearSelection());
    dispatch(clearCustomer());
    dispatch(clearSearchCustomer());
  }

  formatDate(date) {
    /**
     * convert a given string to a date ISO format
     *  @param:  string: 'DD/MM/YYYY'
     *  @return: string: 'YYYY-MM-DDTHH:MM:SS'
     */
    if (!date) {
      return '';
    }
    let initialDate = date;
    const isValid = moment(initialDate).isValid();
    if (!isValid) {
      initialDate = moment(initialDate, 'DD/MM/YYYY')
        .utcOffset(0, true)
        .format();
    }
    return initialDate;
  }

  render() {
    const {
      product,
      currentProduct,
      screening,
      application,
      mta,
      edit,
      customer,
      location,
      searchResult,
      optionsMta,
      hazMta,
      isExpired
    } = this.props;
    const currentCustomer = get(
      customer,
      `resources.${location.query.customer}.data.attributes`,
    );
    const initialData = (attribute) => {
      const searchedCustomer = get(currentCustomer, attribute);
      const searchedNewCustomer = get(searchResult, `metadata.${attribute}`);
      if (searchedCustomer) {
        return searchedCustomer;
      }
      if (!isEmpty(searchedNewCustomer)) {
        return searchedNewCustomer;
      }
    };

    let adults = [
      {
        title: get(currentCustomer, 'title', ''),
        first_name: initialData('first_name') || '',
        last_name: initialData('last_name') || '',
        dob: this.formatDate(initialData('dob')) || '',
        email: initialData('email') || '',
        phone1: get(currentCustomer, 'phone1', ''),
        phone2: get(currentCustomer, 'phone2', ''),
        conditions_covered: true,
        screening: '',
        metadata: {
          doctor_form_info: '',
        },
        screening_id: get(currentCustomer, 'screening_id', ''),
        address: {
          line1: get(currentCustomer, 'address.line1', ''),
          line2: get(currentCustomer, 'address.line2', ''),
          line3: get(currentCustomer, 'address.line3', ''),
          town: get(currentCustomer, 'address.town', ''),
          county: get(currentCustomer, 'address.county', ''),
          postcode: initialData('address.postcode') || '',
        },
      },
    ];

    let children = [];

    if (application) {
      adults = get(application, 'data.attributes.metadata.adults', adults);
      children = get(application, 'data.attributes.metadata.children', children);
    }

    // Adding answer to Medical Questions Object
    const medicalQuestions = {};
    const _medicalQuestions = get(
      currentProduct,
      'data.attributes.metadata.medical_declaration_questions',
      {},
    );
    map(_medicalQuestions, (declaration, key) => {
      medicalQuestions[key] = {
        ...declaration,
        answer: undefined,
      };
    });

    const screeningProduct = get(
      currentProduct,
      'data.attributes.metadata.screening',
      true,
    );

    const productCode = currentProduct.data.attributes.product_code;
    let quoteTypeDefault = mta ? 'mid term adjustment' : 'new business';

    const resource = {
      data: {
        current_tab: 1,
        mta: '',
        type: 'application',
        attributes: {
          quote_reference: product.nextQuoteReference,
          quote_type: '',
          metadata: {
            product_code: productCode,
            source: '',
            sales_channel: isBroker() ? 'pjh-b2b' : 'call-centre',
            residency: 'uk',
            scheme_type: ['LSB', 'TUMC', 'CPI', 'CPS'].includes(productCode) ? 'single' : (['FPS', 'CSP', 'FPR', 'NPO', 'NPOR', 'CSPA', 'CSPAR', 'CSS', 'CSSR'].includes(productCode) ? 'annual' : ''),
            traveller_group: ['TUMC', 'CPI', 'CPS'].includes(productCode) || ['BMC'].includes(productCode) ? 'individual' : '',
            region: ['TUMC'].includes(productCode) ? 'all' : '',
            destinations: '',
            residency_declaration: '',
            start_date: '',
            end_date: '',
            referred_activities: '',
            activities: [],
            adults: adults,
            children: children,
            insured: [],
            options: {},
            subjectivities: [],
            screeningProduct: screeningProduct,
            medical_declaration_questions: medicalQuestions,
            existing_provider: '',
            underlying_cover: '',
            topup_cover: '',
            total_top_up_cover: '',
            max_holiday_value: '',
            medical_declaration_cpi: false,
            age_vehicle: '',
            vehicle_reg: '',
            overrides: {},
            company_name: '',
            make: '',
            model: '',
            type: '',
            year: '',
            value: '',
            colour: '',
            trans_type: '',
            fuel_type: '',
            four_by_four_drive: '',
            business_only_num: '',
            business_leisure_num: '',
            couple_num: '',
            single_parent_num: '',
            family_num: '',
            total_employees: '',
            quote_type: get(application, 'data.attributes.quote_type', quoteTypeDefault)
          },
        },
        relationships: {
          source: {
            id: '',
          },
          schemes: {
            data: [],
          },
        },
      },
      meta: {
        channel_key: null,
      },
    };

    if (productCode === 'CSP') {
      resource.data.attributes.metadata = {
        product_code: productCode,
        source: '',
        sales_channel: isBroker() ? 'pjh-b2b' : 'call-centre',
        residency: 'uk',
        region: 'worldwide',
        scheme_type: 'annual',
        destinations: '',
        residency_declaration: '',
        start_date: '',
        end_date: '',
        options: {},
        overrides: {},
        business_only_num: '',
        business_leisure_num: '',
        couple_num: '',
        single_parent_num: '',
        family_num: '',
        total_employees: '',
        cover_level: '',
        customer: {
          company_name: '',
          nature_of_business: '',
          address: {
            line1: '',
            line2: '',
            line3: '',
            town: '',
            county: '',
            postcode: '',
          },
        }
      };
    }

    if (application && application.data.attributes.quote_type === 'renewal') {
      set(
        application,
        'data.attributes.metadata.medical_declaration_questions',
        medicalQuestions,
      );
    }

    if (get(application, 'data.attributes.metadata.aggregator_quote_date')) {
      set(application, 'data.attributes.metadata.aggregator_quote_date', null);
    }

    if (
      currentProduct.data.attributes.metadata &&
      currentProduct.data.attributes.metadata.subjectivities
    ) {
      resource.data.attributes.metadata.subjectivities =
        currentProduct.data.attributes.metadata.subjectivities;
    }

    if (currentOrganisationType() === 'Broker') {
      resource.meta.channel_key = 'pjh-b2b';
    } else {
      resource.meta.channel_key = 'call-centre';
    }

    const hazAct = get(application, 'data.attributes.metadata.options.hazardous_activities');
    if (!mta && productCode === 'FST' && !hazAct) {
      set(application, 'data.attributes.metadata.options', {
        'hazardous_activities': 'pack1'
      });
    }
    //set hazact to pack1 if it exis
    if (mta && productCode === 'FST' && hazAct && hazAct === true) {
      set(application, 'data.attributes.metadata.options', {
        'hazardous_activities' : 'pack1'
      });
    }

    // Overrides the property from an empty array to an empty object
    const localOptions = get(application, 'data.attributes.metadata.options');
    if (isEmpty(localOptions)) {
      set(application, 'data.attributes.metadata.options', {});
    }

    return (
      <LeisureTravelForm
        resource={application || resource}
        optionsMta={optionsMta}
        hazMta={hazMta}
        screening={screening}
        product={product}
        mta={mta}
        edit={edit}
        currentProduct={currentProduct}
        isExpired={isExpired}
        productCode={product.productCode}
        quoteType={mta ? 'MTA' : 'NB'}
        doSignposting={['FST', 'TPL', 'BMC', 'DIA', 'CPS', 'LSB', '247', 'VEN'].includes(product.productCode)}
        application={application}
      />
    );
  }
}

LeisureTravelFormContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  screening: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  currentProduct: PropTypes.object.isRequired,
  application: PropTypes.object,
  mta: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    product: state.product,
    screening: state.screening,
    customer: state.customer,
    searchResult: state.searchResult.storeCustomer,
  };
};

export default connect(mapStateToProps)(LeisureTravelFormContainer);

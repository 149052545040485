import React from 'react';
import { Alert, Col, ControlLabel, FormGroup } from 'react-bootstrap';
import { Button } from '../../../../../../../common/components/Button/Button';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { DatePicker, InfoButton } from '../../../../../../../common/components';
import { SelectField } from '../../../../../../../common/components/ReduxFormField';
import { backDateCover } from '../../../../../helpers';
import moment from 'moment/moment';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { required } from 'redux-form-validators';
import SelectRegion from '../../../../LeisureTravelForm/ReduxForm/SelectRegion';

const Upsell = ({
  onConfirmUpsell,
  regions,
  maxTripDurations,
  definitions,
  rules,
  handleSubmit,
  formValues
}) => {

  const region = formValues?.region;
  const regionDefinition = definitions?.[`region_${region}`] ?? definitions?.region;
  const startDateDefinition = definitions?.travel_dates_annual;
  const hasSingleMaxTrip = maxTripDurations?.length === 1;

  const doUpsell = (values) => {
    onConfirmUpsell(values);
  };

  const backdateCoverDate = backDateCover(rules?.backdate_cover).format();
  const annualMaxDate = moment().add(rules?.amt_advance_days, 'days').format();
  return (
    <Col sm={12} className={'quote-divider-top confirm-fields-upsell'}>
      <Form onSubmit={handleSubmit(doUpsell)}>
        <Alert bsStyle={'danger'}>
          <div className="confirm-text">Please confirm the following:</div>
          <FormGroup>
            <Col sm={4} componentClass={ControlLabel}>
              <InfoButton content={regionDefinition}/>Region
            </Col>
            <Col sm={8} className={'select-region'}>
              <Field
                name="region"
                component={SelectRegion}
                options={regions}
                validate={required({ msg: 'Region is required' })}
              />

            </Col>
          </FormGroup><FormGroup>
          <Col sm={4} componentClass={ControlLabel}>
            <InfoButton content={startDateDefinition}/> Start Date
          </Col>
          <Col sm={8}>
            <Field
              name="start_date"
              id="start_date"
              minDate={backdateCoverDate}
              maxDate={annualMaxDate}
              component={DatePicker}
            />
          </Col>
        </FormGroup>

          {!hasSingleMaxTrip && (
            <Field
              label="Max Trip Duration"
              name="max_trip_duration"
              component={SelectField}
              labelSize={4}
              options={maxTripDurations}
            />
          )}
          <div className="apply-button-upsell">
            <Button
              label="Apply"
              type="button"
              bsStyle="primary"
              handleClick={onConfirmUpsell}
            />
          </div>
        </Alert>
      </Form>
    </Col>
  );
};


const FORM_NAME = 'UpsellTravelForm';
export default compose(
  connect(
    (state, ownProps) => ({
      formValues: getFormValues(FORM_NAME)(state),
      initialValues: {
        region: ownProps.region,
        start_date: ownProps.startDate,
        max_trip_duration: ownProps.maxTripDuration
      }

    })
  ),
  reduxForm({
    form: FORM_NAME
  })
)(Upsell);

import { Field } from 'redux-form';
import AdjustmentFieldTitle from '../Fields/AdjustmentFieldTitle/AdjustmentFieldTitle';
import { FormGroup } from 'react-bootstrap';
import TextArea from '../../../../../content/components/TextArea';
import { SelectEndorsement } from '../../../../../../common/components';
import React from 'react';

const Endorsements = ({ handleEndorsementChange, currentProduct }) => (
  <div>
    <div className="endorsements-mta-box">
      <div className="endorsement-row clearfix endorsement">
        <div className="endorsement-form">
          <Field
            name={'data.attributes.metadata.endorsements[0].title'}
            label="Title"
            labelSize={1}
            component={AdjustmentFieldTitle}
          />
          <FormGroup>
            <Field
              name={'data.attributes.metadata.endorsements[0].content'}
              label="Content"
              labelSize={1}
              component={TextArea}
            />
          </FormGroup>
        </div>
      </div>
      <div>
        <Field
          isObject
          name="meta.endorsement_template"
          onChange={handleEndorsementChange}
          // onChange={(val) => {
          //   if (val.attributes) {
          //     change(
          //       'data.attributes.metadata.endorsements[0].title',
          //       val.attributes.sections.title,
          //     );
          //     change(
          //       'data.attributes.metadata.endorsements[0].content',
          //       val.attributes.sections.content,
          //     );
          //   } else {
          //     change(
          //       'data.attributes.metadata.endorsements[0].title',
          //       'Mid-term Adjustment',
          //     );
          //     change(
          //       'data.attributes.metadata.endorsements[0].content',
          //       changesToContent(get(selectedScheme, 'meta.mta.changes', [])),
          //     );
          //   }
          // }}
          label="Template"
          placeholder="Select template or leave blank to keep automatic change list..."
          filters={currentProduct && currentProduct.data.attributes.product_code === 'CSP' ? [{ filter: 'filter[product]', value: currentProduct.data.id }, { filter: 'filter[status]', value: 'active' }] : []}
          labelSize={2}
          component={SelectEndorsement}
        />
      </div>
    </div>
  </div>
);
export default Endorsements;

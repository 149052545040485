import React, { Component } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { getBrokers, searchBrokers } from '../../redux/campaignActions';
import { Col, ControlLabel, FormGroup, InputGroup, Glyphicon } from 'react-bootstrap';
import { get, debounce, find } from 'lodash';
import selectTheme, { styles } from '../../../../helpers/selectTheme';
import './styles.scss';

class SelectBrokerCampaigns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.removeBroker = this.removeBroker.bind(this);
  }

  componentDidMount() {
    this.handleSearch = debounce(this.handleSearch, 350);
    this.props.dispatch(getBrokers());
  }

  componentWillMount() {
    const { broker, edit } = this.props;
    const brokerName = get(broker, 'attributes.name');
    const brokerId = get(broker, 'id');
    if (edit) {
      this.setState({
        value: {
          label: brokerName,
          id: brokerId,
          ...broker,
        },
      });
    }
  }

  handleSearch(searchTerm) {
    this.props.dispatch(searchBrokers(searchTerm));
  }

  handleChange(event) {
    const { change, index } = this.props;
    this.setState({
      value: event,
    });
    this.props.dispatch(change(`data.relationships.brokers.data.[${index}]`, event));
  }

  removeBroker() {
    const { fields, index, removeBroker } = this.props;
    if (index === 0) {
      this.setState(() => ({
        value: '',
      }));
    }
    removeBroker(fields, index);
  }

  render() {
    const {
      campaign,
      disabled,
      input,
      label,
      labelSize,
      name,
      meta,
      formValues,
    } = this.props;
    const getBrokersForm = get(formValues, 'data.relationships.brokers.data');

    const isSelected = (value) =>
      !!find(getBrokersForm, (o) => {
        return o && o.id && o.id === value;
      });

    let options = [];
    if (campaign && campaign.brokers) {
      options =
        campaign.brokers &&
        campaign.brokers.data.map((source) => ({
          label: source.attributes.name,
          value: source.id,
          disabled: isSelected(source.id),
          ...source,
        }));
    }

    return (
      <FormGroup
        controlId={name}
        className={meta && meta.touched && meta.error ? ' has-error' : ''}
      >
        {label && (
          <Col componentClass={ControlLabel} sm={labelSize}>
            {label}
          </Col>
        )}

        <Col sm={12 - labelSize}>
          <InputGroup>
            <Select
              {...input}
              theme={(theme) => selectTheme(theme)}
              styles={styles}
              loading
              options={options}
              placeholder={this.props.placeholder}
              value={this.state.value}
              onChange={this.handleChange}
              onInputChange={this.handleSearch}
              disabled={disabled}
              onBlur={() => input.onBlur(input.value)}
              clearable={false}
            />
            <InputGroup.Addon className="glyph-button" onClick={this.removeBroker}>
              <Glyphicon style={{ fontSize: '11px' }} glyph="remove" />
            </InputGroup.Addon>
          </InputGroup>
        </Col>
      </FormGroup>
    );
  }
}

export default connect()(SelectBrokerCampaigns);

import * as actions from './customerReducer';
import Axios from 'axios';
import { push } from 'react-router-redux';
import queryString from '../../../helpers/queryString';
import handleErrorResponse from '../../../helpers/handleErrorResponse';
import { toastr } from 'react-redux-toastr';
import { ADD_RESOURCE } from '../../layout/redux/breadcrumb';

const defaultIncludes = [];

export function clearData() {
  return {
    type: actions.CLEAR_DATA,
  };
}

export function isSubmitting(status) {
  return {
    type: actions.IS_SUBMITTING,
    status,
  };
}

export function submitSucceeded(status) {
  return {
    type: actions.SUBMIT_SUCCEEDED,
    status,
  };
}

export function getCustomer(id, includes = defaultIncludes) {
  return (dispatch) => {
    let endpoint = 'customers/' + id;
    endpoint += queryString(includes);

    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: actions.SET_CUSTOMER,
          data: response.data,
        });
        dispatch({
          type: ADD_RESOURCE,
          id: response.data.data.id,
          name: response.data.data.attributes.customer_name,
        });
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function createCustomer(resource) {
  return (dispatch) => {
    dispatch(isSubmitting(true));
    return Axios.post('customers', resource)
      .then((response) => {
        dispatch(isSubmitting(false));
        dispatch(push('/customers/' + response.data.data.id));
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'There was an error creating the customer');
      });
  };
}

export function updateCustomer(resource, includes = defaultIncludes) {
  return (dispatch) => {
    dispatch(isSubmitting(true));
    const id = resource.data.id;
    let endpoint = 'customers/' + id;
    endpoint += queryString(includes);

    return Axios.patch(endpoint, resource)
      .then(() => {
        dispatch(isSubmitting(false));
        dispatch(push('/customers/' + id));
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'There was an error updating the customer');
      });
  };
}

export function createCustomerAccount(id) {
  return (dispatch) => {
    dispatch(isSubmitting(true));
    return Axios.post('customers/' + id + ':create-account')
      .then(() => {
        dispatch(isSubmitting(false));
        toastr.success('Success', 'Customer account is setup');
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'There was an error creating a customer account');
      });
  };
}

export function mergeCustomers(id, resource) {
  return (dispatch) => {
    dispatch(isSubmitting(true));
    return Axios.post('customers/' + id + ':merge-accounts', resource)
      .then(() => {
        dispatch(isSubmitting(false));
        toastr.success('Success', 'Customer accounts merged');
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        handleErrorResponse(error, 'There was an error merging the customer accounts');
      });
  };
}

export function refundCustomerTransaction(transactionId, resource) {
  return (dispatch) => {
    dispatch(isSubmitting(true));
    dispatch(submitSucceeded(false));
    return Axios.post('policies/transactions/' + transactionId + ':refund', resource)
      .then(() => {
        toastr.success('Success', 'Transaction Refunded');
        dispatch(isSubmitting(false));
        dispatch(submitSucceeded(true));
        return true;
      })
      .catch((error) => {
        dispatch(isSubmitting(false));
        dispatch(submitSucceeded(false));
        handleErrorResponse(error, 'There was an error refunding transaction');
      });
  };
}

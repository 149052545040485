import React, { useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  cachePremiums,
  cacheTransactions,
  getAccount,
  getPremiums,
  getTransactions,
  manageBulkMatching,
  runBulkMatching
} from '../redux/accountActions';
import {
  Box
} from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';
import { openMenu } from '../../layout/redux/sideNavAction';
import { getFilterQuery } from '../helpers';
import MatchingContainer from '../components/MatchingContainer/MatchingContainer';
import Filters from '../components/Filters/Filters';



const DEFAULT_FILTERS = {
  'date:from': '',
  'date:to': '',
  'amount:from': '',
  'amount:to': ''
};


const AccountMatchingContainer = ({
  params: { id },
  onGetAccount,
  onGetPremiums,
  onCachePremiums,
  onGetTransactions,
  onCacheTransactions,
  // onGetAdjustmentsAndBalancing,
  // onCacheAdjustmentsAndBalancing,
  location,
  onOpenMenu
}) => {
  const { state } = location;


  const locAccountId = state?.accountId;
  const locBrokerId = state?.brokerId;
  const [accountId, setAccountId] = useState(id || locAccountId);
  const [brokerId, setBrokerId] = useState(locBrokerId);
  const [transactionFilters, setTransactionFilters] = useState(DEFAULT_FILTERS);
  const [premiumFilters, setPremiumFilters] = useState(DEFAULT_FILTERS);
  const [premiumType, setPremiumType] = useState('net-premiums');


  useEffect(() => {
    onOpenMenu(false);
    return () => {
      onOpenMenu(true);
    };
  }, [onOpenMenu]);

  useEffect(() => {
    if (accountId) {
      onGetAccount(accountId);
    }
  }, [accountId, onGetAccount]);

  const filterUrls = useMemo(() => getFilterQuery(accountId, brokerId, transactionFilters, premiumFilters), [accountId, brokerId, transactionFilters, premiumFilters]);

  const premiumFilter = filterUrls?.premiumFilter;
  const transactionFilter = filterUrls?.transactionFilter;


  useEffect(() => {
    if (accountId) {
      onCacheTransactions([]);
      onGetTransactions(accountId, `sort=created_at&${transactionFilter}`);
    }

  }, [transactionFilter, accountId, onGetTransactions, onCacheTransactions]);

  useEffect(() => {
    if (accountId) {
      onCachePremiums([]);
      onGetPremiums(accountId, premiumType, `sort=created_at&${premiumFilter}`);

    }
  }, [premiumFilter, premiumType, accountId, onGetPremiums, onCachePremiums]);



  const resetAccount = (accountId) => {

    setTransactionFilters(DEFAULT_FILTERS);
    setPremiumFilters(DEFAULT_FILTERS);

    onGetAccount(accountId);

  };

  const refresh  = (accountId) =>  {
    onCacheTransactions([]);
    onCachePremiums([]);
    onGetAccount(accountId);
    onGetPremiums(accountId, premiumType, `sort=created_at&${premiumFilter}`);
    onGetTransactions(accountId, `sort=created_at&${transactionFilter}`);
  };


  // componentWillMount() {
  //   const { params, actions } = this.props;
  //
  //
  //   if (params) {
  //     if (params.id) {
  //       this.setState({ accountId: params.id });
  //       actions.getAccount(params.id);
  //     }
  //
  //     if (params.accountType) {
  //       this.setState({ accountId: params.accountType });
  //     }
  //   }
  // }
  //
  // componentDidMount() {
  //   const { location, actions } = this.props;
  //   actions.openMenu(false);
  //   if (location && location.state) {
  //     this.setState(() => ({
  //       accountId: location.state.account || '',
  //       brokerId: location.state.broker || '',
  //     }));
  //   }
  // }

  // componentWillUpdate(nextProps, nextState) {
  //   const { accountId, accountType } = this.state;
  //   const { actions } = this.props;
  //
  //   if (nextState.accountId && nextState.accountId !== accountId) {
  //     actions.getAccount(nextState.accountId);
  //   }
  //
  //   const resource = nextProps.account.resources[accountId];
  //   if (resource) {
  //     const resourceType = resource.data.attributes.account_type;
  //     if (resourceType !== accountType) {
  //       this.setState({
  //         accountType: resourceType,
  //         premium: {
  //           ...nextState.premium,
  //           type: resourceType === 'debtor' ? 'net-premiums' : 'premiums',
  //         },
  //       });
  //     }
  //   }
  // }




  const resource = useSelector((state) => state.account.resources?.[accountId]);
  const { transactions, cachedTransactions, premiums, cachedPremiums } = useSelector((state) => state.account);

  const cachedPremiumIds = cachedPremiums?.map((premium) => premium?.id);
  const nonCachedPremiums = premiums?.filter((premium) =>  !cachedPremiumIds?.includes(premium?.id));
  const setAccount = (option) => {
    setAccountId(option?.value);
  };
  const setBroker = (option) => {
    setBrokerId(option?.value);
  };

  const setType = (value) => {
    setPremiumType(value);
  };

  const setTransactionFilter = (name, value) => {
    setTransactionFilters({
      ...transactionFilters,
      [name]: value
    });
  };

  const setPremiumFilter = (name, value) => {
    setPremiumFilters({
      ...premiumFilters,
      [name]: value
    });
  };


  return (
    <InternalContainer title="Matching">
      <Box className="clearfix">
        <Filters
          onSetAccount={setAccount}
          accountId={accountId}
          onSetBroker={setBroker}
          brokerId={brokerId}
          onSetPremiumType={setType}
          premiumType={premiumType}
          onSetTransactionFilter={setTransactionFilter}
          transactionFilters={transactionFilters}
          onSetPremiumFilter={setPremiumFilter}
          premiumFilters={premiumFilters}
        />

        <div className="hr small-bottom-margin"/>

        <MatchingContainer
          accountId={accountId}
          resource={resource}
          transactions={transactions}
          matchedTransactions={cachedTransactions}
          premiums={nonCachedPremiums}
          matchedPremiums={cachedPremiums}
          transactionFilter={transactionFilter}
          premiumFilter={premiumFilter}
          onReset={resetAccount}
          onRefresh={refresh}
        />
        {/*{(!!resource && hasTransactionsAndPremiums) && (*/}
        {/*  <AccountMatching*/}
        {/*    key={'matching' + accountId}*/}
        {/*    resource={resource}*/}
        {/*    transactions={transactions}*/}
        {/*    premiums={premiums}*/}
        {/*  />*/}
        {/*)}*/}
        {/*) : (*/}
        {/*  <div>*/}
        {/*    {accountId ? (*/}
        {/*      <Loader noShadow />*/}
        {/*    ) : (*/}
        {/*      <h1 className="text-center">Select an account to get started</h1>*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*)}*/}

        <div className="hr small-bottom-margin"/>
      </Box>
    </InternalContainer>
  );
};


export default connect(
  null,
  {
    onOpenMenu: openMenu,
    onGetAccount: getAccount,
    onCachePremiums: cachePremiums,
    onGetPremiums: getPremiums,
    onCacheTransactions: cacheTransactions,
    onGetTransactions: getTransactions,
    onManageBulkMatching: manageBulkMatching,
    onRunBulkMatching: runBulkMatching
  },
)(AccountMatchingContainer);

import * as actions from './layoutReducer';
import Axios from 'axios';
import { replace } from 'react-router-redux';

export function getOrganisationTypes() {
  return (dispatch) => {
    let endpoint = 'organisations/types';

    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: actions.SET_ORGANISATION_TYPES,
          data: response.data.data,
        });
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function getBrochureProducts() {
  return (dispatch) => {
    return Axios.get('/brochure/products')
      .then((response) => {
        dispatch({
          type: actions.SET_BROCHURE_PRODUCTS,
          data: response.data.data,
        });
      })
      .catch(() => {
        this.props.dispatch(replace('/404'));
      });
  };
}

export function setActiveBrochureProduct(product) {
  return (dispatch) => {
    dispatch({
      type: actions.SET_ACTIVE_BROCHURE_PRODUCT,
      data: product,
    });
  };
}

export const layoutActions = {
  getBrochureProducts,
  setActiveBrochureProduct,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Icon } from '../../../../common/components';
import { openModal } from '../../../../common/components/Modal/ModalRedux';
import './styles.scss';

class BenefitsModal extends Component {
  render() {
    const { dispatch, scheme, benefits, label } = this.props;
    const documentsToIgnore = ['Miscellaneous'];
    const isIgnored = (document) =>
      documentsToIgnore.find((o) => {
        return o === document;
      });

    const modalButtons = (
      <div>
        {scheme.documents &&
          Object.keys(scheme.documents).map((document, i) => {
            if (isIgnored(document)) {
              return;
            }
            return (
              <a
                key={i}
                href={scheme.documents[document]}
                target="_blank"
                className="btn btn-default"
              >
                {document}
              </a>
            );
          })}
      </div>
    );

    return (
      <div style={{ display: 'inline-block' }}>
        <span className="info" onClick={() => dispatch(openModal(scheme.name))}>
          <Icon name="info-circle" size="fa-lg" /> {label}
        </span>

        <Modal
          {...this.props}
          name={scheme.name}
          title="Full Benefits"
          close
          footer={modalButtons}
          bsSize={'large'}
        >
          <div className="benefits-modal">
            <div className="row">
              <div className="col-xs-12" style={{ padding: '5px 30px' }}>
                <h4>{scheme.name}</h4>
                <div className="row header-benefits">
                  <div className="col-xs-6">
                    <strong>Benefit</strong>
                  </div>
                  <div className="col-xs-4">
                    <strong>Maximum Sum Insured</strong>
                  </div>
                  <div className="col-xs-2 text-center">
                    <strong>Excess</strong>
                  </div>
                </div>
                <div className="full-benefits-list section-border">
                  {benefits &&
                    benefits.map((benefit, key) => {
                      return (
                        <div key={key}>
                          {benefit.display === false ? (
                            <div />
                          ) : (
                            <div key={'benefit' + key}>
                              <div
                                className="row section-border"
                                style={{
                                  borderTop: '1px solid darkgrey',
                                  paddingTop: '5px',
                                  paddingBottom: '3px',
                                }}
                              >
                                <div className="col-xs-6 section-name">
                                  {benefit.name}{' '}{scheme.raw_cover_level !== 'eurp_full_cover' ? '(per person)' : ''}
                                </div>
                                <div className="col-xs-4">{benefit.text}</div>
                                <div className="col-xs-2 text-center">
                                  {benefit.excess}
                                </div>
                              </div>

                              {benefit.sub_benefits && benefit.sub_benefits.length > 0 && (
                                <div className="sub-benefits-container">
                                  {benefit.sub_benefits.map((subBenefit, subKey) => {
                                    return (
                                      <div key={subKey}>
                                        {subBenefit.display === false ? (
                                          <div />
                                        ) : (
                                          <div
                                            key={'subBenefit' + subKey}
                                            className="row sub-benefit-border"
                                          >
                                            <div className="col-xs-6">
                                              - {subBenefit.name}
                                            </div>
                                            <div className="col-xs-4">
                                              {subBenefit.text}
                                            </div>
                                            <div className="col-xs-2 text-center">
                                              {subBenefit.excess}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

BenefitsModal.proTypes = {
  dispatch: PropTypes.func.isRequired,
  scheme: PropTypes.object.isRequired,
  benefits: PropTypes.array,
  label: PropTypes.string,
};

export default connect()(BenefitsModal);

import React, { useState } from 'react';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';
import { Field } from 'redux-form';
import PostcodeList from '../../../../../../../common/components/Postcode/PostcodeList';
import { useSelector } from 'react-redux';
import { HorizontalFormControl } from '../../../../../../../common/components';
import { AddressFields } from '../../../../../../../common/components/ReduxFormField';
import { required } from 'redux-form-validators';

export const COL_WIDTHS = {
  labels: { sm: 2, md: 2, lg: 3 },
  fields: { sm: 10, md: 10, lg: 8 },
};

export const LeadTraveller = ({ input }) => {


  const [showFields, setShowFields] = useState(false);
  const { line1, postcode } = input?.value?.address;
  const addressSelection = useSelector((state) => state.address);
  const showCantFindLink = addressSelection?.attributes.line1 === '' && line1 === '';

  const updateFields = (selectedAddress) => {

    input.onChange({
      ...input.value,
      address: {

        ...selectedAddress,
        postcode,
      }
    });
  };
  const handleCantFind = () => {
    setShowFields(!showFields);
  };
  return (
    <div>
      <FormGroup>
        <Col sm={COL_WIDTHS.labels.sm} md={COL_WIDTHS.labels.md}
             lg={COL_WIDTHS.labels.lg} componentClass={ControlLabel}>
          Email
        </Col>
        <Col sm={COL_WIDTHS.fields.sm} md={COL_WIDTHS.fields.md}
             lg={COL_WIDTHS.fields.lg}>
          <Field
            name={'adults.0.email'}
            type="email"
            component={HorizontalFormControl}
            validate={required({ msg: 'email  is required' })}
          />
        </Col>
      </FormGroup>
      <FormGroup>
        <Col sm={COL_WIDTHS.labels.sm} md={COL_WIDTHS.labels.md}
             lg={COL_WIDTHS.labels.lg} componentClass={ControlLabel}>
          Telephone
        </Col>
        <Col sm={COL_WIDTHS.fields.sm} md={COL_WIDTHS.fields.md}
             lg={COL_WIDTHS.fields.lg}>
          <Field
            name={'adults.0.phone1'}
            component={HorizontalFormControl}
            validate={required({ msg: 'phone is required' })}
          />
        </Col>
      </FormGroup>
      <PostcodeList
        handleCantFind={handleCantFind}
        showFieldsState={showFields}
        name={'adults.0.address.postcode'}
        //formName="LeisureTravelForm"
        onUpdateFields={updateFields}
        //targetField={'data.attributes.metadata.adults.0.address'}
        showCantFindLink={showCantFindLink}
        label="Postcode"
        labelSizes={{
          sm: COL_WIDTHS.labels.sm,
          md: COL_WIDTHS.labels.md,
          lg: COL_WIDTHS.labels.lg,
        }}
        postcodeFieldSizes={{
          sm: 6,
          md: 5,
          lg: 4,
        }}
        searchBtnSizes={{
          sm: 4,
          md: 3,
          lg: 4,
        }}
        fieldSizes={{
          sm: COL_WIDTHS.fields.sm,
          md: COL_WIDTHS.fields.md,
          lg: COL_WIDTHS.fields.lg,
        }}
        noWrap
        value={postcode}
      />
      {!!line1 && (
        <AddressFields
          baseName={'adults.0.address'}
          label="Address"
          labelSizes={{
            sm: COL_WIDTHS.labels.sm,
            md: COL_WIDTHS.labels.md,
            lg: COL_WIDTHS.labels.lg,
          }}
          fieldSizes={{
            sm: COL_WIDTHS.fields.sm,
            md: COL_WIDTHS.fields.md,
            lg: COL_WIDTHS.fields.lg,
          }}
        />
      )}
    </div>
  );
};

export default LeadTraveller;

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Form } from 'react-bootstrap';
import { Button, Modal } from '../../../../common/components';
import AsyncSelectMenu from '../../../../common/components/SelectMenu/AsyncSelectMenu';
import { closeModal, openModal } from '../../../../common/components/Modal/ModalRedux';

const CreateUserButton = ({
  onOpenModal,
  onCloseModal,
  onGoTo
}) => {
  const [orgType, setOrgType] = useState('');
  const [id, setId] = useState('');
  const [brokerId, setBrokerId] = useState('');
  const handleSelectOrganisation = (option) => {
    setOrgType(option.value?.attributes?.organisation_type_name);
    setId(option.value.id);
  };

  const handleSelectBroker = (id) => {

    setBrokerId(id);
  };

  const goToCreateUser = () => {

    const path = `/admin/users/new/${id}`;

    const fullPath = orgType === 'Broker' ? `${path}/${brokerId}/?organisationTypeName=${orgType}` : `${path}/?organisationTypeName=${orgType}`;

    onCloseModal('CreateUserButton');
    onGoTo(fullPath);
  };

  const handleOpen = () => {
    onOpenModal('CreateUserButton');
  };

  return (
    <div>
      <Button
        className="pull-right"

        bsStyle="primary"
        rightIcon="user"
        label="Add a New User"
        handleClick={handleOpen}
      />

      <Modal
        name={'CreateUserButton'}
        title="Add a New User"
        close
        footer={id && (
          <Button
            type="submit"
            bsStyle="primary"
            className="pull-right"
            handleClick={goToCreateUser}
            label="Create User"
          />
        )

        }
      >
        <Form>
          <AsyncSelectMenu
            key={'select-organisation'}
            url={'organisations'}
            onChange={handleSelectOrganisation}
          />
        </Form>
      </Modal>
    </div>
  );
};


export default connect(
  null,
  {

    onOpenModal: openModal,
    onCloseModal: closeModal,
    onGoTo: push,
  }
)(CreateUserButton);

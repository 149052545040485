import React from 'react';

import { connect } from 'react-redux';
import { Button } from '../../../common/components';
import InternalContainer from '../../layout/containers/Internal';

import organisationSchema from '../../../schemas/organisation';
import { createOrganisation } from "../redux/organisationActions";
import OrganisationForm from "../components/OrganisationForm/OrganisationForm";

const OrganisationCreateContainer = ({ location, onCreateOrganisation }) => {

  const organisationType = location?.query?.organisationType
  const organisationTypeName = location?.query?.organisationTypeName
  const create = (values) => {
    if(values?.data?.attributes?.metadata?.is_wl_insurer) {
      const insurerCode = values?.data?.relationships?.insurers?.data?.[0]?.attributes.code;
      const insurer = {
        attributes: {
          code: insurerCode,
          name: values?.data?.attributes?.name
        }

      }
      const valuesWithInsurers =  insurerCode ? {
        data:{
          attributes:{
            ...values?.data.attributes
          },
          relationships: {
            ...values?.data?.relationships,
            insurers:{
              data: [
                insurer
              ]
            }
          }
        }
      }: values;
     return onCreateOrganisation(valuesWithInsurers);
    }
   return onCreateOrganisation(values);
  }


  return (
    <InternalContainer title="Organisation"
                       buttons={<Button bsStyle="default" label="Back" isBackButton
                                        leftIcon="caret-left"/>}>
      <OrganisationForm
        organisationType={organisationType}
        organisationTypeName={organisationTypeName}
        resource={organisationSchema}
        onSave={create}
      />
    </InternalContainer>
  );
}

export default connect(null,
  {
    onCreateOrganisation: createOrganisation,
  }
)(OrganisationCreateContainer);

import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form } from 'react-bootstrap';
import { Field, getFormValues, reduxForm } from 'redux-form';
import {
  Box,
  Button,
  ButtonBar,
} from '../../../common/components';

import { compose } from 'redux';
import TextArea from '../components/TextArea';

const FORM_NAME = 'signpostForm';


const SignpostForm = ({ handleSubmit, submitting, updateSignpost }) => {

  const handleSubmitSignpostForm = (values) => {
    updateSignpost(values);
  };


  return (
    <Box className={'content-form'}>
      <h2>Edit Signpost</h2>
      <Form horizontal onSubmit={handleSubmit(handleSubmitSignpostForm)}>
        <Row>
          <Col sm={12}>
            <Field
              name={'data.attributes.content'}
              labelSize={2}
              type="textarea"
              component={TextArea}
            />

          </Col>

        </Row>


        <ButtonBar>
          <Button
            type="submit"
            bsStyle="primary"
            className="pull-right"
            disabled={submitting}
          >
            {submitting ? <i className="fa fa-cog fa-spin"/> : ''} Save Content
          </Button>
        </ButtonBar>
      </Form>


    </Box>
  );
};
export default compose(
  connect(
    (state, props) => ({
      formValues: getFormValues(FORM_NAME)(state),
      initialValues: props.signpost,
    })
  ),
  reduxForm({ form: FORM_NAME }),
)(SignpostForm);



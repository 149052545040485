import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from '../../../../common/components';
import {
  currentOrganisationId,
  hasPermission,
  isOwner,
} from '../../../auth/redux/authActions';
import Loader from '../../../../common/components/Loader/Loader';
import { Col, Row, ControlLabel } from 'react-bootstrap';
import ButtonBar from '../../../../common/components/ButtonBar/ButtonBar';
import RemoveUserOrgButton from '../RemoveUserOrgButton/RemoveUserOrgButton';
import AddUserToOrganisation from './components/AddUserToOrganisation';
import EditUserRoles from './components/EditUserRoles';

class UserRoles extends Component {
  render() {
    const resource = this.props.resource.data.attributes;
    const organisationRoles = this.props.user.organisationRoles.data.meta;
    const userId = this.props.resource.data.id;

    return (
      <Box>
        <Row>
          <Col xs={12}>
            <h2 className="resource-name">{resource.full_name}</h2>
          </Col>
        </Row>
        {Array.isArray(organisationRoles) ? (
          <div>
            {organisationRoles.map((organisationRole, i) => {
              let roles = '';
              organisationRole.roles.data.map((relation, i) => {
                if (i) {
                  roles += ', ';
                }
                roles += relation.attributes.name;
              });

              if (!isOwner()) {
                if (organisationRole.organisation.data.id !== currentOrganisationId()) {
                  return <div/>;
                }
              }

              return (
                <div key={i}>
                  <Row>
                    <Col xs={12}>
                      <h3>{organisationRole.organisation.data.attributes.name}</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={2} componentClass={ControlLabel}>
                      Roles:
                    </Col>
                    <Col xs={10} componentClass={ControlLabel}>
                       {roles}
                    </Col>
                  </Row>

                  {hasPermission('user.edit') && (
                    <Row>
                      <Col xs={12}>
                        <ButtonBar>
                          <RemoveUserOrgButton
                            userId={userId}
                            username={resource.full_name}
                            organisationId={organisationRole.organisation.data.id}
                            organisationName={
                              organisationRole.organisation.data.attributes.name
                            }
                            dispatch={this.props.dispatch}
                          />

                          <EditUserRoles
                            userId={userId}
                            organisationId={organisationRole.organisation.data.id}
                            organisationType={
                              organisationRole.organisation.data.attributes.organisation_type
                            }
                            organisationRoles={organisationRole.roles.data}
                            dispatch={this.props.dispatch}
                          />
                        </ButtonBar>
                      </Col>
                    </Row>
                  )}
                  <div className="hr"/>
                </div>
              );
            })}
            {hasPermission('organisation.edit') && (
                <AddUserToOrganisation userId={userId}/>
            )}
          </div>
        ) : (
          <Loader noShadow/>
        )}
      </Box>
    );
  }
}

UserRoles.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  resource: PropTypes.object.isRequired,
};

export default UserRoles;

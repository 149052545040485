import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { Col, Row } from 'react-bootstrap';
import {
  Button,
  HorizontalFormControl,
  InfoLabel,
  Modal,
} from '../../../../common/components';
import { closeModal } from '../../../../common/components/Modal/ModalRedux';
import confirm from 'react-confirm2';
import { refundCustomerTransaction } from '../../redux/customerActions';
import { includes } from 'lodash';

const FORM_NAME = 'transactionForm';

class UpdateTransactionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amountSet: false,
    };
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    callback: PropTypes.func,
    label: PropTypes.string,
  };

  componentWillUpdate(newProps) {
    const { dispatch, customer, callback, reset, change } = this.props;
    if (!this.state.amountSet) {
      dispatch(change('meta.amount', newProps.refundTransaction.amount));
      this.setState({ amountSet: true });
    }

    if (newProps.customer.submitSucceeded && !customer.submitSucceeded) {
      if (callback) {
        callback('');
      }
      dispatch(reset(FORM_NAME));
      dispatch(closeModal(FORM_NAME));
      this.setState({ amountSet: false });
    }
  }

  handleSubmit(values) {
    const { refundTransaction } = this.props;

    confirm('Warning', {
      description: 'Are you sure you want to refund £' + values.meta.amount,
      done: () => {
        return this.props.dispatch(
          refundCustomerTransaction(refundTransaction.id, values),
        );
      },
    });
  }

  render() {
    const { label, handleSubmit, submitting, refundTransaction } = this.props;
    const title = label || 'Update Transaction';
    const checkForKey = includes(refundTransaction.reference, 'transactionId');
    const reference = checkForKey
      ? JSON.parse(refundTransaction.reference).transactionId
      : refundTransaction.reference;
    const submitButton = (
      <Button
        type="submit"
        bsStyle="primary"
        disabled={submitting}
        isLoading={submitting}
        handleClick={handleSubmit(::this.handleSubmit)}
        label="refund transaction"
      />
    );

    return (
      <div className="source-btn-wrapper pull-right">
        <Modal name={FORM_NAME} title={title} close={true} footer={submitButton}>
          <form onSubmit={handleSubmit(::this.handleSubmit)}>
            <Row>
              <Col sm={8} xs={12}>
                <InfoLabel
                  label="Reference"
                  value={reference}
                  labelSize={4}
                  wrapperClass={'clear-margin'}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={8} xs={12}>
                <Field
                  name="meta.amount"
                  label="Amount"
                  labelSize={4}
                  currency
                  value={refundTransaction.amount}
                  component={HorizontalFormControl}
                />
              </Col>
            </Row>
          </form>
        </Modal>
      </div>
    );
  }
}

const form = reduxForm({ form: FORM_NAME })(UpdateTransactionModal);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state) => {
  const values = selector(state, 'meta.amount');
  return {
    customer: state.customer,
    formValues: values,
  };
};

export default connect(mapStateToProps)(form);

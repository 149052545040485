import formatSchemesByStatus from './formatSchemesByStatus';

export const schemesForCheckbox = (schemesByInsurer) => {
  const insurerKeys = Object.keys(schemesByInsurer);
  const nodes = insurerKeys.map((insurer) => ({
    value: schemesByInsurer[insurer].insurer.id,
    label: schemesByInsurer[insurer].insurer.attributes.name,
    className: 'insurer checkbox-toggle-container',
    children: formatSchemesByStatus(schemesByInsurer[insurer].schemes, insurer),
  }))

  if (insurerKeys.length > 1) {
    return [
      {
        value: 'all',
        label: 'All Schemes',
        className: 'all-schemes checkbox-toggle-container',
        children: nodes,
      },
    ];
  } else {
    return nodes;
  }
};

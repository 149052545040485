import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';
import AsyncSelectMenu from '../SelectMenu/AsyncSelectMenu';

export const SELECT_IDENTIFIER = 'sources';

class SelectSource extends Component {
  renderSuggest() {
    const { product } = this.props;
    const query = product ? `?include=broker,channel&filter[status]=active&filter[product]=${product}` : '';

    return (
      <AsyncSelectMenu
        url={'/' + SELECT_IDENTIFIER + query}
        {...this.props}
      />
    );
  }

  render() {
    const { infoButton, name, meta, label, labelSize } = this.props;

    if (label) {
      const labelSmSize = labelSize || 2;
      const menuSize = 12 - labelSmSize;

      return (
        <FormGroup
          controlId={name}
          className={meta && meta.touched && meta.error ? ' has-error' : ''}
        >
          <Col sm={labelSmSize}>
            <ControlLabel>
              {label} {infoButton}
            </ControlLabel>
          </Col>
          <Col sm={menuSize}>
            {this.renderSuggest()}
          </Col>
        </FormGroup>
      );
    }

    return this.renderSuggest();
  }
}

export default connect()(SelectSource);

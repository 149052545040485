import React from 'react';
import { Row, Col, FormControl, ControlLabel } from 'react-bootstrap';
import { Box } from '../../../../../../common/components';
import {
  CheckboxGroup,
  TextField,
} from '../../../../../../common/components/ReduxFormField';
import { Field } from 'redux-form';
import SelectField from '../../../../../../common/components/ReduxFormField/SelectField';
import { connect } from 'react-redux';
import { get, map } from 'lodash';

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = { enableAll: true };
  }

  componentWillMount() {
    const { dispatch, change, products } = this.props;

    {
      products &&
        map(products, (product, i) => {
          dispatch(
            change('data.attributes.broker_commission[' + i + '].product', product.id),
          );
        });
    }
  }

  handleCommissionDefault(event) {
    const { formValues, dispatch, change } = this.props;
    get(formValues, 'data.attributes.broker_commission', []).map((commission, i) => {
      dispatch(
        change('data.attributes.broker_commission[' + i + '].rate', event.target.value),
      );
    });
  }

  handleEnableAll() {
    const { formValues, dispatch, change } = this.props;
    this.setState({ enableAll: !this.state.enableAll });

    get(formValues, 'data.attributes.broker_commission', []).map((commission, i) => {
      dispatch(
        change(
          'data.attributes.broker_commission[' + i + '].enabled',
          this.state.enableAll,
        ),
      );
    });
  }

  render() {
    const { products, formValues, dispatch, change } = this.props;
    const commissionDefaults = [
      { label: 'Level one', value: 20 },
      { label: 'Level two', value: 25 },
      { label: 'Level three', value: 30 },
    ];

    return (
      <Box>
        <Row>
          <Col sm={12}>
            <h4 className="page-title">Products</h4>
            <hr />
            <Row>
              <Col sm={3}>
                <label className="control-label">Enable All</label>
              </Col>
              <Col sm={2}>
                <div className="radio-container inline">
                  <div className="form-radio">
                    <input
                      type="checkbox"
                      id="enableAll"
                      onChange={::this.handleEnableAll}
                      checked={!this.state.enableAll}
                    />
                    <label htmlFor="enableAll" />
                  </div>
                  <div className="radio-label" />
                </div>
              </Col>
              <Col sm={3}>
                <label className="control-label">Default Commission Level</label>
              </Col>
              <Col sm={4}>
                <FormControl
                  componentClass="select"
                  type="select"
                  onChange={::this.handleCommissionDefault}
                  placeholder="select"
                >
                  <option value="">Select...</option>
                  {commissionDefaults.map((commissionDefault, key) => {
                    return (
                      <option key={'defaults' + key} value={commissionDefault.value}>
                        {commissionDefault.label}
                      </option>
                    );
                  })}
                </FormControl>
              </Col>
            </Row>
            <hr />

            <div>
              <div className="commission-headings">
                <Row>
                  <Col sm={6}>
                    <label className="control-label">Product</label>
                  </Col>
                  <Col sm={2}>
                    <label className="control-label">Enabled</label>
                  </Col>
                  <Col sm={4}>
                    <label className="control-label">Commission Rate</label>
                  </Col>
                </Row>
              </div>

              {products &&
                map(products, (product, i) => {
                  return (
                    <div className="commission-row" key={i}>
                      <Row>
                        <Col sm={6} componentClass={ControlLabel}>
                          {product.attributes.name}
                        </Col>
                        <Col sm={2}>
                          <Field
                            name={'data.attributes.broker_commission[' + i + '].enabled'}
                            labelSize={1}
                            inline
                            value={get(
                              formValues,
                              'data.attributes.broker_commission[' + i + '].enabled',
                              '',
                            )}
                            onChange={(value) => {
                              dispatch(
                                change(
                                  'data.attributes.broker_commission[' + i + '].enabled',
                                  value,
                                ),
                              );
                            }}
                            options={[{ label: '', value: true }]}
                            component={CheckboxGroup}
                          />
                        </Col>
                        <Col sm={4}>
                          <Row>
                            <Col xs={6}>
                              <SelectField
                                name={'data.attributes.broker_commission[' + i + '].rate'}
                                options={commissionDefaults}
                                input={{
                                  value: get(
                                    formValues,
                                    'data.attributes.broker_commission[' + i + '].rate',
                                    '',
                                  ),
                                  onChange: (value) => {
                                    dispatch(
                                      change(
                                        'data.attributes.broker_commission[' +
                                          i +
                                          '].rate',
                                        value,
                                      ),
                                    );
                                  },
                                }}
                              />
                            </Col>
                            <Col xs={6}>
                              <Field
                                name={'data.attributes.broker_commission[' + i + '].rate'}
                                component={TextField}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
            </div>
          </Col>
        </Row>
      </Box>
    );
  }
}

export default connect()(Products);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, InfoLabel, Modal, Loader } from '../../../../common/components';
import { Row, Col } from 'react-bootstrap';
import { getLedgerEntry } from '../../redux/accountActions';
import displayCurrency from '../../../../helpers/displayCurrency';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import { openModal } from '../../../../common/components/Modal/ModalRedux';
import { FormattedDate } from 'react-intl';
import { LedgerEntriesModal } from '../LedgerEntriesModal';
import { ReallocateCommissionsModal } from '../ReallocateCommissionsModal';
import { get } from 'lodash';
import './styles.scss';

class PremiumModal extends Component {
  static MODAL_IDENTIFIER = 'premium-modal';

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    ledgerPremium: PropTypes.object,
    ledgerEntry: PropTypes.object,
    callback: PropTypes.func,
  };

  componentWillUpdate(newProps) {
    const { ledgerPremium, dispatch } = this.props;

    if (newProps.ledgerPremium !== ledgerPremium) {
      dispatch(
        getLedgerEntry(newProps.ledgerPremium.id, [
          'account',
          'batch',
          'premium',
          'policy.premiums',
          'broker.current_network.organisation',
          'broker.organisation',
        ]),
      );
    }
  }

  handleMatchedLedgerEntriesOpen() {
    this.props.dispatch(openModal(LedgerEntriesModal.MODAL_IDENTIFIER));
  }

  handleReallocateCommissionsOpen() {
    this.props.dispatch(openModal(ReallocateCommissionsModal.MODAL_IDENTIFIER));
  }

  render() {
    const { ledgerPremium, ledgerEntry } = this.props;
    let id = null;
    let attributes = {};
    let account = {
      data: {
        id: '',
      },
    };
    let batch = {};
    let policy = {};
    let premium = {};
    let brokerOrganisationId;
    let networkOrganisationId;
    let policyPremiums = [];

    let totals = {
      gross: 0,
      tax: 0,
      broker: 0,
      owner: 0,
      due: 0,
    };

    if (get(ledgerEntry, 'data.attributes')) {
      id = ledgerEntry.data.id;
      attributes = ledgerEntry.data.attributes;
    }

    if (get(ledgerEntry, 'included')) {
      account = getIncludedResource(ledgerEntry.data, ledgerEntry.included, 'account');
      batch = getIncludedResource(ledgerEntry.data, ledgerEntry.included, 'batch');
      policy = getIncludedResource(ledgerEntry.data, ledgerEntry.included, 'policy');
      premium = getIncludedResource(ledgerEntry.data, ledgerEntry.included, 'premium');
      policyPremiums = getIncludedResource(policy.data, ledgerEntry.included, 'premiums');
      let broker = getIncludedResource(ledgerEntry.data, ledgerEntry.included, 'broker');
      if (broker) {
        let brokerOrg = getIncludedResource(
          broker.data,
          ledgerEntry.included,
          'organisation',
        );
        brokerOrganisationId = get(brokerOrg, 'data.id');
        let network = getIncludedResource(
          broker.data,
          ledgerEntry.included,
          'current_network',
        );
        if (network) {
          let networkOrg = getIncludedResource(
            network.data,
            ledgerEntry.included,
            'organisation',
          );
          networkOrganisationId = get(networkOrg, 'data.id');
        }
      }
      if (Array.isArray(policyPremiums)) {
        policyPremiums.map((policyPremium) => {
          totals.gross = totals.gross + parseFloat(policyPremium.attributes.gross);
          totals.tax = totals.tax + parseFloat(policyPremium.attributes.tax);
          totals.broker =
            totals.broker + parseFloat(policyPremium.attributes.broker_commission);
          totals.owner =
            totals.owner + parseFloat(policyPremium.attributes.owner_commission);
          totals.due =
            totals.due +
            parseFloat(policyPremium.attributes.gross) -
            parseFloat(policyPremium.attributes.broker_commission);
        });
      }
    }

    const premiumTypes = {
      'initial premium': 'IP',
      'additional premium': 'AP',
      'return premium': 'RP',
    };

    const modalFooter = (
      <div>
        {get(account, 'data') && (
          <Button
            type="button"
            bsStyle="primary"
            className="pull-right"
            link
            to={'accounts/ledger/' + account.data.id}
            label="View Account"
          />
        )}
        {!get(batch, 'data.attributes') && get(premium, 'data') && (
          <ReallocateCommissionsModal
            key="commission-modal"
            dispatch={this.props.dispatch}
            policyNumber={get(policy, 'data.attributes.policy_number')}
            premium={premium.data}
            brokerOrganisationId={brokerOrganisationId}
            networkOrganisationId={networkOrganisationId}
          />
        )}
        {!get(batch, 'data.attributes') && (
          <Button
            type="button"
            bsStyle="primary"
            className="pull-right"
            link
            to={'accounts/' + account.data.id + '/matching'}
            label="Match"
          />
        )}
        {get(batch, 'data.attributes') && (
          <div>
            <Button
              type="button"
              bsStyle="primary"
              className="pull-right"
              handleClick={::this.handleMatchedLedgerEntriesOpen}
              label="Un-match"
            />
            <LedgerEntriesModal
              key="ledger-modal"
              dispatch={this.props.dispatch}
              batch={batch.data}
              callback={this.props.callback}
            />
          </div>
        )}
      </div>
    );

    return (
      <Modal
        name={PremiumModal.MODAL_IDENTIFIER}
        title={
          ledgerPremium.description +
          ' | ' +
          ledgerPremium.currency +
          ' ' +
          ledgerPremium.formatted_amount
        }
        footer={modalFooter}
        close
        bsSize={'large'}
      >
        {!ledgerPremium ||
        !get(ledgerEntry, 'data.attributes') ||
        id !== ledgerPremium.id ? (
          <Loader noShadow/>
        ) : (
          <div className="form-horizontal">
            <Row>
              <Col sm={12}>
                <InfoLabel
                  label="Account"
                  labelSize={4}
                  value={attributes.account_name}
                />
                <InfoLabel
                  label="Sub Account (Broker)"
                  labelSize={4}
                  value={attributes.broker_name}
                />
                <InfoLabel
                  label="Reference"
                  labelSize={4}
                  value={attributes.accounting_reference}
                />
                <InfoLabel label="Date" labelSize={4} date value={attributes.date}/>
                <InfoLabel
                  label="Description"
                  labelSize={4}
                  value={attributes.description}
                />
                <InfoLabel
                  label="Amount"
                  labelSize={4}
                  value={attributes.formatted_amount}
                />
                {get(batch, 'data.attributes') && (
                  <div>
                    <InfoLabel
                      label="Matched By"
                      labelSize={4}
                      value={batch.data.attributes.user_name}
                    />
                    <InfoLabel
                      label="Date Matched"
                      labelSize={4}
                      date
                      value={batch.data.attributes.created_at}
                    />
                  </div>
                )}
              </Col>
            </Row>
            <hr/>
            <Row>
              <Col sm={12}>
                <h3>{policy.data.attributes.policy_number} Premiums</h3>
                <table className="premium-table">
                  <thead>
                  <tr>
                    <th className="narrow">Date</th>
                    <th className="amount">Gross</th>
                    <th className="amount">IPT</th>
                    <th className="amount">Broker</th>
                    <th className="amount">PJHayman</th>
                    <th className="amount">Due</th>
                    <th className="center">Matched</th>
                    <th className="narrow center">Type</th>
                  </tr>
                  </thead>
                  <tbody>
                  {policyPremiums &&
                  policyPremiums.map((policyPremium) => {
                    const due =
                      parseFloat(policyPremium.attributes.gross) -
                      parseFloat(policyPremium.attributes.broker_commission);
                    const gross = policyPremium.attributes.outstanding_by_element.filter(
                      (element) => {
                        return element.type === 'gross';
                      },
                    );

                    let matchedDate = '';

                    if (gross && gross[0].matched_date) {
                      matchedDate = <FormattedDate value={gross[0].matched_date}/>;
                    }

                    return (
                      <tr>
                        <td className="narrow">
                          <FormattedDate value={policyPremium.attributes.issued_at}/>
                        </td>
                        <td className="amount">
                          {displayCurrency(
                            policyPremium.attributes.gross,
                            policyPremium.attributes.currency,
                          )}
                        </td>
                        <td className="amount">
                          {displayCurrency(
                            policyPremium.attributes.tax,
                            policyPremium.attributes.currency,
                          )}
                        </td>
                        <td className="amount">
                          {displayCurrency(
                            policyPremium.attributes.broker_commission,
                            policyPremium.attributes.currency,
                          )}
                        </td>
                        <td className="amount">
                          {displayCurrency(
                            policyPremium.attributes.owner_commission,
                            policyPremium.attributes.currency,
                          )}
                        </td>
                        <td className="amount">
                          {displayCurrency(due, policyPremium.attributes.currency)}
                        </td>
                        <td className="center">{matchedDate}</td>
                        <td className="narrow center">
                          {premiumTypes[policyPremium.attributes.premium_type]}
                        </td>
                      </tr>
                    );
                  })}
                  </tbody>
                  <tfoot>
                  <tr>
                    <td className="narrow">&nbsp;</td>
                    <td className="amount">
                      {displayCurrency(totals.gross, ledgerPremium.currency)}
                    </td>
                    <td className="amount">
                      {displayCurrency(totals.tax, ledgerPremium.currency)}
                    </td>
                    <td className="amount">
                      {displayCurrency(totals.broker, ledgerPremium.currency)}
                    </td>
                    <td className="amount">
                      {displayCurrency(totals.owner, ledgerPremium.currency)}
                    </td>
                    <td className="amount">
                      {displayCurrency(totals.due, ledgerPremium.currency)}
                    </td>
                    <td className="center">&nbsp;</td>
                    <td className="narrow center">&nbsp;</td>
                  </tr>
                  </tfoot>
                </table>
              </Col>
            </Row>
            <hr/>
          </div>
        )}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    ledgerEntry: state.account.ledgerEntry,
  };
}

export default connect(mapStateToProps)(PremiumModal);

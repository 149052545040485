import * as actions from './PostcodeReducer';
import Axios from 'axios';

export function isLoading(status) {
  return {
    type: actions.LOOKUP_QUERY,
    status,
  };
}

export function getAddresses(postcode) {
  return (dispatch) => {
    const endpoint = 'public/postcode/' + encodeURIComponent(postcode);

    if (postcode === undefined || postcode.length < 3) {
      return false;
    }

    dispatch({ type: actions.START_LOOKUP });
    dispatch(isLoading(true));

    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: actions.SET_ADDRESS_OPTIONS,
          addresses: response.data,
        });
      })
      .catch((error) => {
        dispatch(isLoading(false));
        console.error(error);
      });
  };
}

export function getAddress(id) {
  return (dispatch) => {
    const endpoint = 'public/address/' + id;

    dispatch(isLoading(true));

    return Axios.get(endpoint)
      .then((response) => {
        dispatch({
          type: actions.SELECT_ADDRESS,
          data: response.data.data,
        });
      })
      .catch((error) => {
        dispatch(isLoading(false));
        console.error(error);
      });
  };
}

export function clearSelection() {
  return (dispatch) => {
    dispatch({ type: actions.CLEAR_SELECTION });
  };
}

import React from 'react';
import CenteredSection from '../../brochure/components/CenteredSection/CenteredSection';
import { Box, HorizontalFormControl, Button, ButtonBar } from '../../../common/components/';
import { Form, Field, reduxForm } from 'redux-form';
import { get, set } from 'lodash';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { activateBroker } from '../redux/brokerApplicationActions';


class CompleteYourApplication extends React.Component {

  handleActivate(resource) {
    const { dispatch,  params: { token } } = this.props;
    dispatch(activateBroker(token, resource));
  }

  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <div className="guest-section">
        <div className="guest-title"> Complete Your Application</div>
        <CenteredSection className="page-content">
          <Box>
            <Row>
              <Col mdPush={2} md={8}>
                <h2>Your application has been approved</h2>
                <p>Create a password below to log in to the system.</p>
                <h2>Create your password</h2>
              </Col>
            </Row>
            <Form onSubmit={handleSubmit(::this.handleActivate)}>
              <Row>
                <Col mdPush={2} md={8}>
                  <Field
                    name="meta.email"
                    label="Your email"
                    type="email"
                    labelSize={4}
                    component={HorizontalFormControl}
                  />
                  <Field
                    name="meta.password"
                    type="password"
                    label="Password"
                    labelSize={4}
                    placeholder="New password"
                    component={HorizontalFormControl}
                  />
                  <Field
                    name="meta.password_confirmation"
                    type="password"
                    label="Confirm password"
                    labelSize={4}
                    placeholder="Confirm new password"
                    component={HorizontalFormControl}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={10}>
                  <ButtonBar>
                    <Button
                      type="submit"
                      size="large"
                      className="pull-right"
                      label={'Complete Application'}
                      bsStyle="primary"
                      disabled={submitting}
                    />
                  </ButtonBar>
                </Col>
              </Row>
            </Form>
          </Box>
        </CenteredSection>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (!get(values, 'meta.password')) {
    set(errors, 'meta.password', 'The new password is required');
  } else if (values.meta.password.length < 8) {
    set(errors, 'meta.password', 'Password must be 8 characters or more');
  }
  if (!get(values, 'meta.password_confirmation')) {
    set(
      errors,
      'meta.password_confirmation',
      'The password must be confirmed',
    );
  }
  if (
    get(values, 'meta.password') !==
    get(values, 'meta.password_confirmation')
  ) {
    set(
      errors,
      'meta.password_confirmation',
      'Password does not match confirmation',
    );
  }

  return errors;
};

const FORM_NAME = 'BrokerActivation';
const form = reduxForm({ form: FORM_NAME, validate })(CompleteYourApplication);
export default connect()(form);

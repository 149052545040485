import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MultiSelectMenu from '../SelectMenu/MultiSelectMenu';
import './styles.scss';

export const SELECT_IDENTIFIER = 'country';

export default class SelectCountries extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    label: PropTypes.string,
    labelSize: PropTypes.number,
    input: PropTypes.object.isRequired,
    infoButton: PropTypes.element,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    meta: PropTypes.object,
    object: PropTypes.bool,
    multi: PropTypes.bool,
    filters: PropTypes.array,
  };

  render() {
    const { meta: { touched, error }, } = this.props;
    let source = 'products/destinations';
    return (
      <div className={(touched && error) ? 'has-error' : ''}>
        <MultiSelectMenu
          source={source}
          sourceKey={'MultiCountries'}
          isisClearable
          {...this.props}
        />
      </div>
    );
  }
}

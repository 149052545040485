import { Field } from 'redux-form';
import { Col, FormControl, FormGroup, InputGroup } from 'react-bootstrap';
import { Icon } from '../../../../../../common/components';
import React from 'react';

import './ProductInfoFieldArray.scss';
const Input = ({ input, index, isLast, type, onAdd, onRemove }) => {
  const remove = () => {
    onRemove(index);
  };

  return (
    <FormGroup>
      <Col sm={6}>
        <div className={'max_trip_durations_field_array--row'}>

          <div className={'max_trip_durations_field_array--input'}>
            <InputGroup>

              <InputGroup.Addon>
                <div className={'max_trip_durations_field_array--remove'}
                     onClick={remove}>
                  <Icon name={'trash'}/>
                </div>
              </InputGroup.Addon>

              <FormControl
                type={type}
                size={'small'}
                {...input}
              />
            </InputGroup>

          </div>

          {isLast && (
            <div className={'max_trip_durations_field_array--add'} onClick={onAdd}>
              <Icon name={'plus'}/>
            </div>
          )}
        </div>

      </Col>
    </FormGroup>
  );
};

const ProductInfoFieldArray = ({ fields, type, fieldSize }) => {

  const addField = () => {
    fields.push(0);
  };
  const removeField = (field) => {
    fields.remove(field);
  };
  return (
    <>
      {fields.map((field, index) => {
        return (
          <Field
            name={`${field}.key`}
            onAdd={addField}
            onRemove={removeField}
            index={index}
            isLast={fields.length === index + 1}
            component={Input}
            type={type}
            fieldSize={fieldSize}
          />
        );
      })}
    </>
  );
};

export default ProductInfoFieldArray;

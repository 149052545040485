import { Box, Loader } from '../../../common/components';
import React from 'react';

const SchemeManagementTabContainer = ({ children, hasScheme }) => {
  if (!hasScheme) {
    return (
      <Box>
        <Loader/>
      </Box>
    );
  }
  return <Box>{children}</Box>;
};

export default SchemeManagementTabContainer;

import React, { useMemo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FieldArray, getFormValues, reduxForm } from 'redux-form';
import { Alert, Form } from 'react-bootstrap';
import BenefitsFieldArray from './components/BenefitsFieldArray';

const getHasExcessWaiver = (options) => {
  return !!options?.find((option) => option.key === 'excess_waiver');
};
const getOptionList = (options) => {
  return options?.map((option) => ({ label: option.name, value: option.key })) ?? [];
};

const BenefitsForm = ({ isViewOnly, handleSubmit, formValues, coverLevels }) => {
  const options = formValues?.meta?.config?.options;

  const hasExcessWaiver = useMemo(() => getHasExcessWaiver(options), [options]);
  const optionList = useMemo(() => getOptionList(options), [options]);
  return (
    <div className="product-container">
      {!isViewOnly && (
        <Alert bsStyle={'info'}>
          Drag and Drop benefits in the order they are to be displayed.<br/>
          Values added in the 'Text' section will be how the benefits are displayed in the
          quote process and in the documents<br/>
          You can select predefined excess amounts of type in the box to create a custom
          one<br/>
          You benefits that display/populate only when additional options are selected use
          the 'Related Additional Options Section'<br/>

        </Alert>
      )}
      <Form horizontal onSubmit={handleSubmit}>
        <h2>Benefits</h2>

        <FieldArray
          name={'meta.config.benefits'}
          component={BenefitsFieldArray}
          isViewOnly={isViewOnly}
          hasExcessWaiver={hasExcessWaiver}
          options={optionList}
          coverLevels={coverLevels}
        />
      </Form>
    </div>
  );
};

export default compose(
  connect((state, props) => {
    return {
      formValues: getFormValues(props.formName)(state),
      destroyOnUnmount: false,
      enableReinitialize: true,
      form: props.formName,
    };
  }),
  reduxForm({}),
)(BenefitsForm);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { first, keys, get } from 'lodash';
import confirm from 'react-confirm2';
import { closeModal, openModal } from '../../../../common/components/Modal/ModalRedux';
import Button from '../../../../common/components/Button/Button';
import Modal from '../../../../common/components/Modal/Modal';
import { mergeCustomers } from '../../redux/customerActions';
import CustomerList from '../../components/CustomerList/CustomerList';
import InfoLabel from '../../../../common/components/InfoLabel/InfoLabel';
import './styles.scss';

const FORM_NAME = 'mergeCustomersForm';

class MergeCustomersButton extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    label: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
  }

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded, callback } = this.props;
    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('');
      }
      dispatch(closeModal(FORM_NAME));
      this.resetState();
    }
  }

  handleRowClick(row) {
    confirm('Warning', {
      description:
        'Are you sure you want to merge customers? This action cannot be reversed.',
      done: () => {
        const customerId = first(keys(get(this.props, 'customer.resources')));

        const newCustomer = {
          data: {
            relationships: {
              customer: {
                data: {
                  id: row.id,
                },
              },
            },
          },
        };

        this.props.dispatch(mergeCustomers(customerId, newCustomer));
      },
    });
  }

  handleOpen() {
    const { dispatch } = this.props;
    dispatch(openModal(FORM_NAME));
  }

  render() {
    const { label } = this.props;
    const title = label || 'Merge Customers';

    const customerId = first(keys(get(this.props, 'customer.resources')));
    const customer = get(
      this.props,
      'customer.resources.' + customerId + '.data.attributes',
    );

    return (
      <div>
        <Button
          bsStyle="primary"
          {...this.props}
          label={title}
          handleClick={this.handleOpen}
        />

        <Modal
          name={FORM_NAME}
          title={title}
          close
          onHide={this.resetState}
          bsSize="large"
        >
          <div className="row merge-customers">
            <div className="col-sm-12">
              <h3>Original Customer</h3>
              <div className="row">
                <div className="col-sm-2" />
                <div className="col-sm-5">
                  <div className="form-horizontal">
                    {customer.company_name && (
                      <InfoLabel
                        label="Company Name"
                        value={customer.company_name}
                        labelSize={4}
                      />
                    )}
                    <InfoLabel
                      label="Name"
                      value={
                        customer.title +
                        ' ' +
                        customer.first_name +
                        ' ' +
                        customer.last_name
                      }
                      labelSize={4}
                    />
                    <InfoLabel
                      label="Date of Birth"
                      value={customer.dob}
                      date
                      labelSize={4}
                    />
                  </div>
                </div>
                <div className="col-sm-5">
                  <div className="form-horizontal">
                    <InfoLabel label="Email" value={customer.email} labelSize={4} />
                    <InfoLabel
                      label="Postcode"
                      value={customer.address.postcode}
                      labelSize={4}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <CustomerList
                    header={''}
                    quotesBox={'customer-box'}
                    quoteHeader={'get-customer-header'}
                    showCreateCustomer={false}
                    internalContainer={false}
                    handleRowClick={this.handleRowClick}
                    searchTitle={'Searching for an Existing Customer to Merge'}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customer: state.customer,
  };
};

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({ mergeCustomers }, dispatch), dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MergeCustomersButton);

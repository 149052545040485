import React from 'react';
import { FormattedDate } from 'react-intl';
import moment from "moment/moment";
const DATE_FORMAT = 'DD/MM/yyyy';
export default function formatDate(cell) {
  if(!cell) {
    return null;
  }
  return (
    <span>
      <FormattedDate value={new Date(cell)} />
    </span>
  );
}


export const formatDateString = (date) => {
  if(!date){
    return ''
  }
  const parsedDate = moment(date, [moment.ISO_8601]);
  return parsedDate?.format(DATE_FORMAT)

}



import React from 'react';

import { Field, FieldArray, FormSection } from 'redux-form';
import { HorizontalFormControl } from '../../../../../../common/components';

import {
  SchemeGroupDetailsFieldArray
} from '../SchemeGroupDetailsFieldArray/SchemeGroupDetailsFieldArray';

const LABEL_SIZE = 2;


export const ProductSchemeGroupField = ({ input, field }) => {
  const schemeDetails = input.value?.scheme_details;
  const schemeType = schemeDetails?.[0]?.scheme_type;
  const quoteType = schemeDetails?.[0]?.quote_type;
  const renews = schemeDetails?.[0]?.renews;
  return (
    <>
      <FormSection name={field}>
        <Field
          name={'name'}
          label={'Group Name'}
          labelSize={LABEL_SIZE}
          component={HorizontalFormControl}
        />

        <Field
          name={'prefix'}
          label={'Group Prefix'}
          labelSize={LABEL_SIZE}
          component={HorizontalFormControl}
        />
      </FormSection>


      <FieldArray
        name={`${field}.scheme_details`}
        component={SchemeGroupDetailsFieldArray}
        schemeType={schemeType}
        quoteType={quoteType}
        renews={renews}/>


    </>
  );
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, DataTable, SelectOrganisation } from '../../../../common/components';
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions';
import { isOwner } from '../../../auth/redux/authActions';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { FormControl, ControlLabel, Col, Row } from 'react-bootstrap';
import { push } from 'react-router-redux';
import { FormattedDate, FormattedTime } from 'react-intl';
import './UserList.scss';
import Select from 'react-select';
import selectTheme, { styles } from '../../../../helpers/selectTheme';
import CreateUserButton from '../CreateUserButton/CreateUserButton';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';

export const TABLE_NAME = 'users';

export default class UserList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
  };

  constructor() {
    super();

    this.state = {
      values: {
        organisation: '',
        status: '',
      },
    };
  }

  componentWillMount() {
    this.setState({
      values: {
        ...this.state.values,
        status: this.props.location.query.status ? this.props.location.query.status : '',
      },
    });
  }

  formatDate(cell) {
    return (
      <span>
        <FormattedDate value={new Date(cell)} /> <FormattedTime value={new Date(cell)} />
      </span>
    );
  }

  formatName(cell, row) {
    return row.title + ' ' + row.first_name + ' ' + row.last_name;
  }

  handleRowClick(row) {
    this.props.dispatch(push('/admin/users/' + row.id));
  }

  handleSearchUpdate(event) {
    this.props.dispatch(applyFilter(TABLE_NAME, 'filter[search]', event.target.value));
  }

  handleOrganisationFilterUpdate(option) {
    const value = option && option.value;
    this.setState({
      values: {
        ...this.state.values,
        organisation: value || '',
      },
    });

    this.props.dispatch(applyFilter(TABLE_NAME, 'filter[organisation]', value));
  }

  handleFilterStatus(option) {
    const value = option && option.value;

    this.setState({
      values: {
        ...this.state.values,
        status: value || '',
      },
    });

    this.props.dispatch(applyFilter(TABLE_NAME, 'filter[status]', value));
  }

  render() {
    return (
      <div>
        <Box>
          <Row>
            <Col sm={5}>
              <ControlLabel className="filter-label">Search</ControlLabel>
              <FormControl onChange={::this.handleSearchUpdate} />
            </Col>

            {isOwner() && (
              <Col sm={5}>
                <ControlLabel className="filter-label">Organisation</ControlLabel>
                <SelectOrganisation
                  id={'select-organisation'}
                  async
                  isClearable
                  onChange={::this.handleOrganisationFilterUpdate}
                  input={{
                    value: this.state.values.organisation,
                  }}
                />
              </Col>
            )}

            <Col sm={2}>
              <ControlLabel className="filter-label">Status</ControlLabel>
              <Select
                theme={(theme) => selectTheme(theme)}
                styles={styles}
                onChange={(option) => this.handleFilterStatus(option)}
                options={[
                  { label: 'Pending', value: 'pending' },
                  { label: 'Active', value: 'active' },
                  { label: 'Suspended', value: 'suspended' },
                  { label: 'Deactivated', value: 'deactivated' },
                ]}
              />
            </Col>
          </Row>
        </Box>

        <Box>
          <DataTable
            source="/users"
            name={TABLE_NAME}
            showTotal
            refreshData
            onRowSelect={this.handleRowClick.bind(this)}
          >
            <TableHeaderColumn dataField="id" isKey hidden />

            <TableHeaderColumn dataField="first_name" dataFormat={::this.formatName}>
              Name
            </TableHeaderColumn>

            <TableHeaderColumn dataField="email" dataSort width={'260px'}>
              Email
            </TableHeaderColumn>

            <TableHeaderColumn dataField="phone1" dataSort width={'260px'}>
              Phone
            </TableHeaderColumn>

            <TableHeaderColumn dataField="status" dataSort width={'100px'}>
              Status
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="created_at"
              dataFormat={this.formatDate}
              width={'180px'}
            >
              Created
            </TableHeaderColumn>
          </DataTable>

          <Row>
            <Col sm={12}>
              <InternalButtonBar
                buttons={[
                  {
                    permissions: ['user.create'],
                    button: <CreateUserButton dispatch={this.props.dispatch} />,
                  },
                ]}
              />
            </Col>
          </Row>
        </Box>
      </div>
    );
  }
}
